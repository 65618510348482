import React from 'react'
import { Avatar, Button, Card, Col, DatePicker, Empty, Popover, Row, Spin, Tag, Tooltip } from 'antd'
import { ArrowLeftOutlined, BookOutlined, CaretLeftOutlined, CaretRightOutlined, CheckOutlined, CloseOutlined, CoffeeOutlined, ReloadOutlined } from '@ant-design/icons'
import { groupBy, orderBy } from 'lodash'
import moment from 'moment'

import { BaseList } from 'src/components'

const { MonthPicker } = DatePicker

const STATES = [
  { key: '', label: 'All' },
  { key: 'request_review', label: 'Request Review', color: 'orange', icon: <CoffeeOutlined /> },
  { key: 'accepted', label: 'Accepted', color: '#87d068', icon: <CheckOutlined /> },
  { key: 'rejected', label: 'Rejected', color: 'red', icon: <CloseOutlined /> },
]

class RequestHistoryContent extends BaseList {
  constructor(props) {
    super(props)

    // params = { user }
    for (const key in props.params)
      this[key] = props.params[key]

    this.limit = 100
    this.stateRequest = STATES[0].key
    this.selectedMonth = moment().format('YYYY-MM')
    // this.range_after = moment().startOf('month').format() // tricky, because range is required
    // this.range_before = moment().endOf('month').format() // tricky, because range is required

    this.urlKey = 'read-userRequestActivities'
    this.keysQueryParam.push(...['range_after', 'range_before', 'user', 'state', 'ordering'])
    // this.ordering = '-created'
    // this.ordering = '-range'
    this.ordering = '-start_time' // belum di api
  }

  // key state is equal with react state, so changed to another key
  isValidParam = (key) => (
    key === 'state' ||
    key === 'range_after' ||
    key === 'range_before' ||
    this[key] !== undefined
  )

  getParam = (key, val) => {
    if (key === 'state') return this.stateRequest
    if (key === 'range_after') return moment(this.selectedMonth).startOf('month').format()
    if (key === 'range_before') return moment(this.selectedMonth).endOf('month').format()
    return val
  }
  // --------------------------------------------------------------

  getStateOpt = (stateRequest) => STATES.find(item => item.key === stateRequest) || STATES[1]

  getStateElem = (stateOpt) => {
    return (
      <Tag color={stateOpt.color} style={{ lineHeight: '14px', paddingBottom: '2px', marginRight: 0 }}>
        {stateOpt.label}
      </Tag>
    )
  }

  convertResponseData = (responseData) => {
    responseData.results = orderBy(responseData.results, 'start_time', 'desc')

    const objGrouped = groupBy(responseData.results, item => moment(item.start_time).format('YYYY-MM-DD'))
    const arrGrouped = []

    for (const key in objGrouped) {
      arrGrouped.push({ key, label: moment(key).format('dddd, D MMMM'), data: objGrouped[key] })
    }

    responseData.results = orderBy(arrGrouped, 'key', 'desc')

    return responseData
  }

  renderListItem = (activity) => {
    const stateOpt = this.getStateOpt(activity.state)

    return (
      <Col key={activity.alias} style={{ backgroundColor: 'white', borderRadius: '4px', padding: '8px' }}>
        <Tooltip placement="left" title={`Reason : ${activity.message || '-'}`} mouseLeaveDelay={0}>
        <Row align='middle' gutter={[6, 6]}>
          <Col>
            <Avatar
              icon={stateOpt.icon}
              shape='square'
              style={{ backgroundColor: stateOpt.color }}
            />
          </Col>
          <Col style={{ width: 'calc(100% - 38px)' }}>
            <Row justify='space-between'>
              <Col className='text-truncate' title={`Task : ${activity.task.name}`} style={{ maxWidth: 'calc(100% - 86px)', fontWeight: 500 }}>
                {activity.task.name}
              </Col>
              <Col>
                {/* <Tooltip placement='left' title={`${moment(activity.start_time).format('dddd, DD MMMM YYYY')}`} mouseLeaveDelay={0}> */}
                  <div className='subtitle' style={{ fontSize: '13px' }}>
                    {moment(activity.start_time).format('HH:mm')} - {moment(activity.finish_time).format('HH:mm')}
                  </div>
                {/* </Tooltip> */}
              </Col>
            </Row>
            <Row justify='space-between'>
              <Col style={{ maxWidth: 'calc(100% - 108px)' }}>
                <div className='text-truncate' style={{ color: '#46ad6b', fontSize: '12px' }}>
                  <BookOutlined style={{ marginRight: '4px' }} />
                  {activity.note ? activity.note.note : '-'}
                </div>
              </Col>
              <Col>
                <div>
                  {this.getStateElem(stateOpt)}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        </Tooltip>
      </Col>
    )
  }

  onChangeDate = (date, dateString) => {
    this.selectedMonth = dateString
    this.reload()
  }

  reload = () => {
    this.page = 1
    this.forceRead()
  }

  nextMonth = () => {
    this.selectedMonth = moment(this.selectedMonth).add(1, 'month').format()
    this.reload()
  }

  prevMonth = () => {
    this.selectedMonth = moment(this.selectedMonth).subtract(1, 'month').format()
    this.reload()
  }

  onScrollContent = (event) => {

  }

  render() {
    return this.state.errorResponse ? this.getErrorComp() : (
      <div>
        {/* Header */}
        <Row justify='space-between' align='middle' gutter={[6, 6]} style={{ marginBottom: '12px' }}>
          <Col>
            <Row align='middle'>
              <Col>
                <div className='ant-page-header-back'>
                  <div className='ant-page-header-back-button' onClick={this.props.onClose}>
                    <ArrowLeftOutlined />
                  </div>
                </div>
              </Col>
              <Col>
                <h1 style={{ marginBottom: 0 }}>
                  History
                </h1>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row align='middle' gutter={[6, 6]}>
              <Col>
                <Button
                  size='small'
                  icon={<ReloadOutlined />}
                  loading={this.state.isLoading}
                  onClick={this.reload}
                />
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Button
                      disabled={this.state.isLoading}
                      size='small'
                      icon={<CaretLeftOutlined />}
                      onClick={this.prevMonth}
                    />
                  </Col>
                  <Col>
                    <MonthPicker
                      disabled={this.state.isLoading}
                      allowClear={false}
                      size='small'
                      value={moment(this.selectedMonth)}
                      style={{ margin: '0 -1px', width: 'calc(100% + 2px)', width: '100px' }}
                      onChange={this.onChangeDate}
                    />
                  </Col>
                  <Col>
                    <Button
                      disabled={this.state.isLoading}
                      size='small'
                      icon={<CaretRightOutlined />}
                      onClick={this.nextMonth}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Content */}
        <Spin spinning={this.state.isLoading}>
          <div style={{ maxHeight: 'calc(100vh - 100px)', overflowY: 'auto' }} onScroll={this.onScrollContent}>
            {this.__list.length > 0 ? (
              <>
                <div>
                  {this.__list.map(obj => (
                    <div key={obj.key} style={{ marginBottom: '4px' }}>
                      <div className="header-history-edit-time">
                        {obj.label}
                      </div>
                      <Row gutter={[0, 12]} style={{ flexDirection: 'column' }}>
                        {obj.data.map(this.renderListItem)}
                      </Row>
                    </div>
                  ))}
                </div>
                {/* <Row gutter={[0, 12]} style={{ flexDirection: 'column' }}>
                  {this.__list.map(this.renderListItem)}
                </Row> */}
                <div style={{ marginTop: '12px', textAlign: 'center' }}>
                  {this.next ? (
                    <Button block loading={this.state.isLoadMore} onClick={this.loadMore}>
                      Load More
                    </Button>
                  ) : `You've seen all the history of month ${moment(this.selectedMonth).format('MMMM YYYY')}`}
                </div>
              </>
            ) : (
              <Card>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description='No History'
                />
              </Card>
            )}
          </div>
        </Spin>
      </div>
    )
  }
}

export default RequestHistoryContent
