import NumberFormat from 'react-number-format'
import { get } from 'lodash'

import BasePoint from './BasePoint'

class AreaByGrade extends BasePoint {
  constructor(props) {
    super(props)

    this.title = 'Area by Grade'
    this.a = 'Average $ / m²'
    this.b = 'Average m² / job'

    this.inputTargetProps = {
      style: {width: '50px'},
      title: 'Target Area',
    }

    this.columns = [
      { dataIndex: 'grade' },
      {
        dataIndex: 'area',
        style: {textAlign: 'right'},
        render: val => (
          <span>
            <NumberFormat thousandSeparator displayType='text' value={Number(val)} />
            &nbsp;m²
          </span>
        )
      },
      {
        dataIndex: 'pct',
        style: {textAlign: 'right'},
        render: val => this.getLabelPct(val)
      },
    ]
  }

  getTotalValue = () => (
    <span>
      <NumberFormat
        thousandSeparator
        displayType='text'
        value={Number(this.totalValue)}
      /> m²
    </span>
  )

  mappingXY = (record) => ({ x: record.grade, y: record.area })

  convertData = (productionJobs, nextProps) => {
    const dataSource = []

    this.totalValue = 0
    this.totalAmount = 0
    for (let i = 0; i < productionJobs.length; i += 1) {
      this.totalValue += Number(productionJobs[i].area)
      this.totalAmount += Number(productionJobs[i].qs_value)

      const foundIndex = dataSource.findIndex(record => record.grade === productionJobs[i].grade)

      const prevDataSource = dataSource[foundIndex]

      dataSource.splice(
        !prevDataSource ? dataSource.length : foundIndex,
        !prevDataSource ? 0 : 1,
        {
          grade: productionJobs[i].grade,
          area: Number(get(prevDataSource, 'area', 0)) + Number(productionJobs[i].area),
        }
      )
    }

    for (let i = 0; i < dataSource.length; i += 1) {
      dataSource[i].pct = this.totalValue ? dataSource[i].area / this.totalValue : dataSource[i].area
    }

    dataSource.sort((a, b) => b.pct - a.pct)

    this.setAverage(productionJobs, nextProps)

    this.setThisState({ dataSource })
  }

  setAverage = (productionJobs) => {
    const prodDays = []
    let totalDays = 0, totalJobs = productionJobs.length

    for (const i in productionJobs) {
      if (prodDays.indexOf(productionJobs[i].prod_day) === -1) {
        totalDays += 1
        prodDays.push(productionJobs[i].prod_day)
      }
    }

    this.averagePerDay = (this.totalAmount / this.totalValue).toFixed(2)
    this.averagePerJob = Math.round(this.totalValue / totalJobs)
  }
}

export default AreaByGrade