import React from 'react'
import { Avatar, Badge, Button, Card, Col, Empty, Popover, Row, Spin, Tag } from 'antd'
import { CalendarOutlined, CloseOutlined, CheckCircleOutlined, UserOutlined, UsbOutlined, CommentOutlined, BookOutlined, CaretRightOutlined, ClockCircleOutlined, RollbackOutlined } from '@ant-design/icons'
import { get, groupBy, startCase } from 'lodash'
import moment from 'moment'

import { BaseComponent } from 'src/components'
import { lib } from 'src/Utils'
import classNames from 'classnames'
// import PopoverDetailRequest from './PopoverDetailRequest'

class BaseList extends BaseComponent {
  constructor(props) {
    super(props)

    // Override this keys
    this.stateKey = ''

    this.state = {
      objCollapsed: {},
    }
  }

  filterActivity = (activities) => activities.state === this.stateKey

  renderEmpty = () => (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  )

  getExtra = (activity) => {
    if (activity.state === 'request_review') {
      return (
        <Button.Group style={{ width: '100%' }}>
          <Button danger type='primary' icon={<CloseOutlined />} style={{ flex: 1 }} onClick={() => this.props.onReject(activity)}>
            Reject
          </Button>
          <Button type='primary' icon={<CheckCircleOutlined />} style={{ flex: 1 }} onClick={() => this.props.onAccept(activity)}>
            Accept
          </Button>
        </Button.Group>
      )
    }

    return (
      <Button danger type="primary" icon={<RollbackOutlined />} onClick={() => this.props.showDeleteActivity(activity)}>
        Undo Action
      </Button>
    )

    return (
      <Tag
        color={activity.state === 'accepted' ? 'green' : 'magenta'}
        style={{ width: '100%', textAlign: 'center' }}
      >
        {startCase(activity.state)}
      </Tag>
    )
  }

  render() {
    const filteredList = this.props.list.filter(this.filterActivity)
    // const groupedList = groupBy(filteredList, activity => lib.getFullName(activity.user))
    const groupedList = groupBy(filteredList, activity => moment(activity.start_time).format('DD MMMM YYYY'))

    return (
      <Spin spinning={this.props.isLoading}>
        {filteredList.length > 0 ? Object.keys(groupedList).map(fullName => (
          <div key={fullName}>
            <Row justify='space-between' className='group-request-time' onClick={() => this.setState({ objCollapsed: { ...this.state.objCollapsed, [fullName]: !this.state.objCollapsed[fullName] } })}>
              <Col>
                <Row align="middle" gutter={[6, 6]}>
                  <CaretRightOutlined
                    rotate={this.state.objCollapsed[fullName] ? 0 : 90}
                    style={{ marginRight: '6px' }}
                  />
                  {fullName}
                  {groupedList[fullName].filter(activity => activity.state === 'request_review').length > 0 && (
                    <Tag color="processing" style={{ marginLeft: '6px' }}>
                      {groupedList[fullName].filter(activity => activity.state === 'request_review').length} waiting
                    </Tag>
                  )}
                  {groupedList[fullName].filter(activity => activity.state === 'accepted').length > 0 && (
                    <Tag color="success" className="group-hoverable-no" style={{ marginLeft: '6px' }}>
                      {groupedList[fullName].filter(activity => activity.state === 'accepted').length} accepted
                    </Tag>
                  )}
                  {groupedList[fullName].filter(activity => activity.state === 'rejected').length > 0 && (
                    <Tag color="error" className="group-hoverable-no" style={{ marginLeft: '6px' }}>
                      {groupedList[fullName].filter(activity => activity.state === 'rejected').length} rejected
                    </Tag>
                  )}
                </Row>
              </Col>
              <Col>
              </Col>
            </Row>
            <div style={{ display: this.state.objCollapsed[fullName] ? 'none' : 'block' }}>
              {groupedList[fullName].map(activity => (
                <div
                  key={activity.alias}
                  className={classNames('activity-list-item', {
                    accepted: activity.state === 'accepted',
                    rejected: activity.state === 'rejected',
                  })}
                >
                  <Row justify='space-between' align='middle' gutter={[12, 12]} wrap={false}>
                    <Col flex="auto">
                      <Row align='middle' wrap={false}>
                        {!this.mutableScreen.isMobile && (
                          <Col flex="none" style={{ marginRight: '12px' }}>
                            <Avatar
                              src={activity.user.photo}
                              icon={<UserOutlined />}
                              size={48}
                            />
                          </Col>
                        )}
                        <Col flex="auto">
                          <div>
                            {/* User Name */}
                            <span className='activity-list-item-title'>
                              <b>{lib.getFullName(activity.user)}</b> request working time

                              {/* Manual Time Working */}
                              <span title='Time Working' style={{ color: '#c3c3c3', fontSize: '13px' }}>
                                <CalendarOutlined style={{ marginLeft: '8px' }} /> {moment(activity.start_time).format('DD MMMM YYYY')}
                                <span style={{ display: 'inline-block', marginLeft: '12px' }}>
                                  {moment(activity.start_time).format('hh:mm A')} - {moment(activity.finish_time).format('hh:mm A')}
                                  &nbsp;
                                  {!this.mutableScreen.isMobile && (
                                    `(${lib.time.getLabel(
                                      lib.time.getDiff({
                                        start_time: activity.start_time,
                                        finish_time: activity.finish_time,
                                      })
                                    )})`
                                  )}
                                </span>
                              </span>
                            </span>
                            {/* </Popover> */}
                          </div>

                          {/* Task Name */}
                          <div style={{ color: '#1890ff', fontSize: '13px' }}>
                            <UsbOutlined /> Task : {activity.task && activity.task.name}
                          </div>

                          {/* Note */}
                          <div className='webkit-box' title={get(activity.note, 'note')} style={{ color: '#46ad6b' }}>
                            <BookOutlined style={{ marginRight: '6px' }} />
                            Note : {get(activity.note, 'note', '-')}
                          </div>

                          {/* Reason */}
                          <div className='webkit-box two-lines2' title={activity.message} style={{ color: '#8e8e8e' }}>
                            <CommentOutlined style={{ marginRight: '6px' }} />
                            Reason : {activity.message || '-'}
                            {/* Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet  */}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col flex="none" className='only-xs-block'>
                      {this.getExtra(activity)}
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </div>
        )) : this.renderEmpty()}
      </Spin>
    )
  }
}

export default BaseList