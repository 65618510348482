import React from 'react'
import PropTypes from 'prop-types'
import Lightbox from 'react-image-lightbox'
import { isEmpty } from 'lodash'

import BaseComponent from './BaseComponent'

class DialogLightbox extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      photos: props.photos,
      currentIndex: -1,
    }
  }

  setCurrentIndex = (currentIndex) => {
    this.setThisState({ currentIndex })
  }

  show = (currentIndex, state) => {
    this.setThisState({ currentIndex, ...state })
  }

  hide = () => {
    this.setCurrentIndex(-1)
  }

  prev = () => {
    if (this.state.currentIndex > 0) {
      this.setCurrentIndex(this.state.currentIndex - 1)
    }
  }

  next = () => {
    if (this.state.currentIndex < this.state.photos.length - 1) {
      this.setCurrentIndex(this.state.currentIndex + 1)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const keys = ['photos']
    const state = {}

    for (const key of keys) {
      if (this.props[key] !== nextProps[key]) {
        state[key] = nextProps[key]
      }
    }

    if (!isEmpty(state))
      this.setThisState(state)
  }

  render() {
    const { getSrc, getTitle } = this.props
    const { currentIndex, photos } = this.state

    return currentIndex > -1 && (
      <Lightbox
        clickOutsideToClose
        mainSrc={getSrc(photos[currentIndex])}
        prevSrc={getSrc(photos[currentIndex - 1])}
        nextSrc={getSrc(photos[currentIndex + 1])}
        onMovePrevRequest={this.prev}
        onMoveNextRequest={this.next}
        onCloseRequest={this.hide}
        imageTitle={getTitle(photos[currentIndex])}
        // imageTitle={
        //   <div>
        //     <CalendarOutlined style={{ marginRight: '6px' }} />
        //     {moment(attendance.attendance_date).format('DD MMM YYYY HH:mm A')} ({attendance.status_display})
        //   </div>
        // }
      />
    )
  }
}

DialogLightbox.propTypes = {
  getSrc: PropTypes.func.isRequired,
  photos: PropTypes.array,
  getTitle: PropTypes.func,
}

DialogLightbox.defaultProps = {
  photos: [],
  getTitle: () => {},
}

export default DialogLightbox