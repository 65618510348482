import React from 'react'
import { Button, Col, Pagination, Row, Spin, Table } from 'antd'
import { ApartmentOutlined, ReloadOutlined } from '@ant-design/icons'

import { BaseList } from 'src/components'
import { lib } from 'src/Utils'

class ManageUsers extends BaseList {
  constructor(props) {
    super(props)

    this.urlKey = 'read-usersManagedMe'

    this.columns = [
      {
        dataIndex: '_1',
        title: 'No.',
        align: 'center',
        width: '75px',
        render: (_, __, i) => i + 1,
      },
      {
        dataIndex: '_2',
        title: 'Name',
        render: (_, record) => lib.getFullName(record),
      },
      {
        dataIndex: 'email',
        title: 'E-Mail',
      },
    ]
  }

  convertResponseData = (responseData) => {
    responseData.results = responseData.results.filter(user => user.email !== lib.ls.getCurrentUser().email)
    return responseData
  }

  render() {
    return (
      <div>
        <Row justify='space-between' align='middle' style={{ marginBottom: '12px' }} gutter={[12, 6]}>
          <Col>
            <div style={{ fontSize: '16px', fontWeight: 500 }}>
              <ApartmentOutlined style={{ marginRight: '8px' }} />
              People Managed by Me
            </div>
          </Col>
          <Col>
            <Button loading={this.state.isLoading} icon={<ReloadOutlined />} onClick={this.read}>
              Refresh
            </Button>
          </Col>
        </Row>
        <Spin spinning={this.state.isLoading}>
          <Table
            bordered
            size='small'
            rowKey='alias'
            pagination={false}
            columns={this.columns}
            dataSource={this.__list}
            scroll={{ x: '930px' }}
          />
          <Row justify='space-between' style={{ margin: '16px 0', padding: '0 12px' }}>
            <Col />
            <Col>
              <Pagination
                {...this.getPaginationConfig()}
              />
            </Col>
          </Row>
        </Spin>
      </div>
    )
  }
}

export default ManageUsers