const FORMAT_DATE = 'YYYY-MM-DD'
const FORMAT_MONTH = 'YYYY-MM'

// https://github.com/lewatmana/timetracking-web/issues/365
const OVERTIME_MIN_HOURS_WEEKDAY = 2
const OVERTIME_MIN_HOURS_WEEKEND = 3

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  F_DATE: FORMAT_DATE,
  F_MONTH: FORMAT_MONTH,
  OVERTIME_MIN_HOURS_WEEKDAY,
  OVERTIME_MIN_HOURS_WEEKEND,
}