import moment from 'moment'

// Internal function -----------------
function getHours(asHours) {
  return Math.floor(Math.abs(asHours))
}
// -----------------------------------

// duration param dapat diisi dengan ms (number), atau object { seconds: number, hours: number }
function getLabel(duration, alt) {
  const data = moment.duration(duration)._data
  // Hours = calculation of hours in days, months and years
  data.hours = getHours(moment.duration(duration).asHours())

  if (data.hours) {
    return `${data.hours}h ${data.minutes}m`
  }

  if (alt && !data.minutes) {
    return `${data.seconds} seconds`
  }

  if (data.seconds) {
    return `${data.minutes}m ${data.seconds}s`
  }

  return `${data.minutes} minutes`
}

// return ms
function getDiff({ start_time, finish_time }) {
  if (
    moment(start_time, ['YYYY-MM-DD', moment.ISO_8601])._isValid &&
    moment(finish_time, ['YYYY-MM-DD', moment.ISO_8601])._isValid
  ) {
	  if (moment(finish_time).diff(moment(start_time)) < 0) return 0
    return moment(finish_time).diff(moment(start_time))
  }

  return 0
}

function getNumberTime(duration = 0, alt = true) {
  const isMinus = duration < 0
  duration = Math.abs(duration)

  const data = moment.duration(duration)._data
  // Hours = calculation of hours in days, months and years
  data.hours = getHours(moment.duration(duration).asHours())

  const keys = ['hours', 'minutes', 'seconds']

  let numberTime = ''
  for (const key of keys) {
    if (alt || data[key]) {
      // When have hour or minutes, show seconds, although 0 second
      alt = true

      // Show time separator
      if (numberTime)
        numberTime += ':'

      // get the time with 2 minimum digits
      numberTime += data[key].toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
    }
  }

  if (isMinus)
    numberTime = '-' + numberTime

  return numberTime
}

function getFloat(ms = 0, fixed = 2) {
  // Hours = calculation of hours in days, months and years
  return moment.duration(ms).asHours().toFixed(fixed)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { getLabel, getDiff, getNumberTime, getFloat }
