import PropTypes from 'prop-types';

import List from 'src/pages/DashboardApp/Submission/AnnualLeave/DialogLeaveDetail/List'
import ListItem from './Item';

class OvertimeList extends List {
  renderList = () => {
    return (
      this.props.userOvertimes.map(userOvertime => (
        <ListItem
          key={userOvertime.pk}
          userOvertime={userOvertime}
          onSelect={this.props.onSelect}
          getLeaveTypeLabel={this.props.getLeaveTypeLabel}
        />
      ))
    )
  }
}

OvertimeList.propTypes = {
  ...OvertimeList.propTypes,
  userOvertimes: PropTypes.array,
}

OvertimeList.defaultProps = {
  ...OvertimeList.defaultProps,
  userOvertimes: [],
}

export default OvertimeList