import React from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Checkbox, Col, DatePicker, Empty, Row } from 'antd'
import { CalendarOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import moment from 'moment'
import classnames from 'classnames'

import { BaseComponent, DialogFullscreen } from 'src/components'
import ListItem from './Item'
import LoadMoreListener from 'src/pages/DashboardApp/Attendance/Photos/LoadMoreListener'

class ContentList extends BaseComponent {
  constructor(props) {
    super(props)

    this.setDefaultLimit()

    this.state = {
      showAll: false,
    }
  }

  onRefDialogFullscreen = (ref) => {
    this._dialogFullscreen = ref
  }

  setDefaultLimit = () => {
    this.limit = 10
  }

  filterAttendances = (att) => (
    moment(att.attendance_date).format('YYYY-MM') === moment(this.props.date).format('YYYY-MM') &&
    (this.state.showAll || att.status === 'validated')
  )

  filterLimit = (_, i) => i < this.limit

  onChangeShowAll = event => {
    this.setDefaultLimit()
    this.setThisState({ showAll: event.target.checked })
  }

  loadMore = () => {
    this.limit += 10
    this.forceUpdate()
  }

  onSelectItem = (attendance) => {
    const filteredAttendances = this.props.userAttendances.filter(this.filterAttendances)
    const foundIndex = filteredAttendances.findIndex(item => item.alias === attendance.alias)
    this._dialogFullscreen.show(foundIndex, {photos: filteredAttendances})
  }

  getSrc = (attendance) => {
    return attendance && attendance.photo.photo
  }

  getTitle = (attendance) => {
    return (
      <div>
        <CalendarOutlined style={{ marginRight: '6px' }} />
        {moment(attendance.attendance_date).format('DD MMM YYYY HH:mm A')} ({attendance.status_display})
      </div>
    )
  }

  prevMonth = () => {
    this.onChangeDate(moment(this.props.date).subtract(1, 'month'))
  }

  nextMonth = () => {
    this.onChangeDate(moment(this.props.date).add(1, 'month'))
  }

  onChangeDate = (date) => {
    this.props.setDate(date)
  }

  render() {
    const { date } = this.props
    const filteredAttendances = this.props.userAttendances.filter(this.filterAttendances)

    return (
      <div>
        <Row justify='space-between' gutter={[12, 12]} style={{ borderBottom: '1px solid #e8e8e8' }}>
          <Col>
            <h1 style={{ marginBottom: 0 }}>
              Ordered Attendance ({filteredAttendances.length}) - {moment(date).format('MMMM YYYY')}
            </h1>
          </Col>
          <Col>
            <Row align='middle' gutter={[6, 6]}>
              <Col>
                <Checkbox checked={this.state.showAll} onChange={this.onChangeShowAll}>
                  Show All Status
                </Checkbox>
              </Col>
              <Col>
                <Button icon={<LeftOutlined />} onClick={this.prevMonth} style={{ marginRight: '-1px' }}>
                  {!this.mutableScreen.isMobile && 'Prev'}
                </Button>
                <DatePicker
                  picker='month'
                  className='calendar-group-center'
                  allowClear={false}
                  value={moment(date)}
                  onChange={this.onChangeDate}
                />
                <Button
                  className={classnames('group-right', { 'ant-btn-icon-only': this.mutableScreen.isMobile })}
                  onClick={this.nextMonth}
                  style={{ marginLeft: '-1px' }}
                >
                  {!this.mutableScreen.isMobile && 'Next'}
                  <RightOutlined />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ padding: '12px' }}>
          <Row gutter={[0, 12]} style={{ flexDirection: 'column' }}>
            {filteredAttendances.filter(this.filterLimit).map(att => (
              <Col key={att.alias}>
                <ListItem attendance={att} onSelect={this.onSelectItem} />
              </Col>
            ))}
            {filteredAttendances.length === 0 && (
              <Card>
                <Empty
                  image={Empty.PRESENTED_IMAGE_DEFAULT}
                  description={`No Attendance ${this.state.showAll ? '' : 'validated'}`}
                />
              </Card>
            )}
          </Row>
        </div>
        {this.props.isFocus && (
          <LoadMoreListener loadMore={this.loadMore} />
        )}

        <DialogFullscreen
          ref={this.onRefDialogFullscreen}
          getSrc={this.getSrc}
          getTitle={this.getTitle}
        />
      </div>
    )
  }
}

ContentList.propTypes = {
  userAttendances: PropTypes.array,
}

ContentList.defaultProps = {
  userAttendances: [],
}

export default ContentList