import moment from 'moment'

import ProductionDetail from '../ProductionDetail'

class JobProgressDetail extends ProductionDetail {
  constructor(props) {
    super(props)

    this.title = 'In-Progress'
    this.showExport = false
    this.resyncFeature = false

    this.initializeColumns()

    const pointIndex = this.columns.findIndex(column => column.dataIndex === 'qs_point')
    if (pointIndex !== -1) this.columns.splice(pointIndex, 1)

    const mustFinishColumn = this.columns.find(column => column.dataIndex === 'must_finish_date')
    if (mustFinishColumn)
      mustFinishColumn.defaultSortOrder = 'ascend'

    // const qa2Index = this.columns.findIndex(column => column.dataIndex === 'qa2_date')
    // if (qa2Index !== -1) {
    //   this.columns.splice(qa2Index + 1, 0, ...[
    //     {
    //       title: 'Must Finish',
    //       dataIndex: 'must_finish_date',
    //       width: '100px',
    //       defaultSortOrder: true,
    //       sorter: (a, b) => lib.sorter.byItem(a, b, 'must_finish_date'),
    //       render: (val) => !val ? '-' : moment(val).format('DD MMM YYYY'),
    //     },
    //   ])
    // }
  }
}

export default JobProgressDetail