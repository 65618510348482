import React from 'react'
import { Button, Form, Input, message } from 'antd'
import { LockOutlined, MailOutlined } from '@ant-design/icons'

import { BaseComponent } from 'src/components'
import appConfig from 'src/config/app'
import { lib, request } from 'src/Utils'

class LoginContent extends BaseComponent {
  state = {
    isLoading: false,
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.onLogin()
    }
  }

  onLogin = () => {
    this._form.validateFields()
      .then(values => {
        if (this.state.isLoading) return

        this.setThisState({ isLoading: true }, async () => {
          const data = new FormData()
          data.set('grant_type', 'password')
          data.set('client_id', appConfig.oauth.client_id)
          data.set('client_secret', appConfig.oauth.client_secret)
          data.set('username', values.email)
          data.set('password', values.password)

          await request({
            method: 'post',
            urlKey: 'oauth-token',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', },
            data,
            onSuccess: this.loginSuccess,
            onFailed: this.onFailed,
          })
        })
      })
      .catch(e => {})
  }

  loginSuccess = (response) => {
    lib.ls.setUserToken(response.data)
    this.readCompanies()
  }

  readCompanies = () => {
    request({
      urlKey: 'read-companies',
      onSuccess: this.readCompaniesSuccess,
      onFailed: this.onFailed,
    })
  }

  readCompaniesSuccess = (response) => {
    if (!response.data.length) {
      message.error('You are not in any company. Contact your company to invite you to this application.', 3)
      this.onFailed()
      return
    }

    lib.ls.setCompanies(response.data)

    this.readMyAccount()
  }

  readMyAccount = () => {
    request({
      urlKey: 'read-myAccount',
      onSuccess: this.readAccountSuccess,
      onFailed: this.onFailed,
    })
  }

  readAccountSuccess = (response) => {
    lib.ls.setAccount(response.data)
    this.readMe()
  }

  readMe = () => {
    request({
      urlKey: 'read-myUser',
      onSuccess: this.readMeSuccess,
      onFailed: this.onFailed,
    })
  }

  readMeSuccess = (response) => {
    lib.ls.setCurrentUser(response.data)

    const next = lib.getObjParam(this.props.history.location.search).next || '/'

    this.props.history.push(next)
  }

  onFailed = (error) => {
    lib.ls.removeUserToken()

    if (error && error.response) {
      if (error.response.data.error === "invalid_grant") {
        if (error.response.data.error_description === "Invalid credentials given.") {
          message.error("Wrong Username or Password!", 3);
        } else {
          message.error(error.response.data.error_description, 3);
          // if (error.response.data.error_description === 'You do not have authority to access this application.') {
          //   this.setThisState({ visibleDrawer: true })
          // }
        }
      } else if (error.response.data.error) {
        message.error('Error message: ' + error.response.data.error)
      } else {
        message.error('Error message: ' + error.response.data.detail)
      }
    }

    this.setThisState({ isLoading: false })
  }

  render() {
    return (
      <div>
        <h2 style={{ textAlign: 'center' }}>
          TimeWise.id
        </h2>
        <Form ref={ref => {this._form = ref}} layout='vertical'>
          <Form.Item
            name='email'
            label='E-Mail'
            rules={[
              {required: true, message: 'Please enter your E-Mail!'}
            ]}
          >
            <Input
              autoFocus
              autoCapitalize='off'
              autoComplete='false'
              placeholder='E-Mail'
              prefix={<MailOutlined className='site-form-item-icon' />}
              type='email'
              onKeyDown={this.onKeyDown}
            />
          </Form.Item>
          <Form.Item
            name='password'
            label='Password'
            rules={[
              {required: true, message: 'Please enter your Password!'}
            ]}
          >
            <Input.Password
              autoComplete='false'
              placeholder='Password'
              prefix={<LockOutlined className='site-form-item-icon' />}
              onKeyDown={this.onKeyDown}
            />
          </Form.Item>
          <Button block loading={this.state.isLoading} type='primary' onClick={this.onLogin}>
            Login
          </Button>
        </Form>
      </div>
    )
  }
}

export default LoginContent