import React from 'react'
import Lightbox from 'react-image-lightbox'

import { BaseComponent } from 'src/components'

class PhotoFullscreen extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      currentIndex: -1,
    }
  }

  show = (currentIndex) => {
    this.setCurrentIndex(currentIndex)
  }

  hide = () => {
    this.setCurrentIndex(-1)
  }

  setCurrentIndex = (currentIndex) => {
    this.setThisState({ currentIndex })
  }

  prev = () => {
    // this.setThisState(prevState => ({ currentIndex: (prevState.currentIndex + this.props.photos.length - 1) % this.props.photos.length }))
    this.setThisState(prevState => ({ currentIndex: prevState.currentIndex && prevState.currentIndex - 1 }))
  }

  next = () => {
    // this.setThisState(prevState => ({ currentIndex: (prevState.currentIndex + 1) % this.props.photos.length }))
    this.setThisState(prevState => ({ currentIndex: prevState.currentIndex + 1 < this.props.photos.length ? prevState.currentIndex + 1 : prevState.currentIndex }))
  }

  render() {
    const { photos } = this.props
    const { currentIndex } = this.state

    return currentIndex === -1 ? null : (
      <Lightbox
        clickOutsideToClose
        imageTitle={photos[currentIndex].photo.split('/').pop()}
        mainSrc={photos[currentIndex].photo}
        onCloseRequest={() => this.setThisState({ currentIndex: -1 })}
        // nextSrc={photos[(currentIndex + 1) % photos.length].photo}
        // prevSrc={photos[(currentIndex + photos.length - 1) % photos.length].photo}
        prevSrc={photos[currentIndex - 1]}
        nextSrc={photos[currentIndex + 1]}
        // prevSrc={undefined}
        // nextSrc={null}
        onMovePrevRequest={this.prev}
        onMoveNextRequest={this.next}
        onCloseRequest={this.hide}
      />
    )
  }
}

export default PhotoFullscreen