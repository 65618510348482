import PropTypes from 'prop-types';
import { Button, Spin } from 'antd';
import { FrownOutlined } from '@ant-design/icons';
import moment from 'moment';

import { BaseList, BaseListActivity } from 'src/components';
import { lib } from 'src/Utils';
import _h from 'src/pages/DashboardApp/Submission/helper';

class FieldHoursTimewise extends BaseList {
  constructor(props) {
    super(props)

    this.pagination = false
    this.urlKey = 'v2-read-activities'
    this.keysQueryParam = ['range_before', 'range_after', 'user']
  }

  getParam = (key, val) => {
    if (key === 'user')
      return this.props.userId
    if (key === 'range_before')
      return moment(this.props.date).startOf('month').format()
    if (key === 'range_after')
      return moment(this.props.date).endOf('month').format()

    return val
  }

  beforeRead = () => {
    this.props.setTotalMs(this.props.date, 0)
  }

  readSuccess = (response, extra) => {
    this.props.setTotalMs(this.props.date,
      response.data.reduce((acc, activity) => acc + lib.time.getDiff(activity), 0))
    this.setState({ isLoading: false })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(
      'CWRP',
      this.props.date, '->', nextProps.date,
      ',',
      this.props.ms, '->', nextProps.ms,
    )
  }

  render() {
    console.log('Rendered', this.props.date, this.props.ms, this)
    if (this.state.errorResponse && !this.state.isLoading) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Button danger size='small' icon={<FrownOutlined />} onClick={this.reload}>
            Reload
          </Button>
        </div>
      )
    }

    return (
      <Spin spinning={this.state.isLoading}>
        {/* {lib.time.getLabel(this.props.ms)} */}
        {this.props.ms
          ? _h._overtime.renderDuration(this.props.ms)
          : '-'}
      </Spin>
    )
  }
}

FieldHoursTimewise.propTypes = {
  ...FieldHoursTimewise.propTypes,
  date: PropTypes.string.isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  ms: PropTypes.number,
  setTotalMs: PropTypes.func,
}

FieldHoursTimewise.defaultProps = {
  ...FieldHoursTimewise.defaultProps,
  ms: 0,
  setTotalMs: () => null,
}

export default FieldHoursTimewise