import { request } from 'src/Utils'
import ls from './localStorage'

let teams, members, page, limit
let managed, pageManaged, limitManaged

function clearData() {
  teams = []
  members = []
  page = 1
  limit = 25
  // ---------
  managed = []
  pageManaged = 1
  limitManaged = 100
}

function appendMembers(newMembers = []) {
  for (const member of newMembers) {
    if (members.findIndex(val => val.email === member.email) === -1) {
      members.push(member)
    }
  }
}

async function loadAllMembers(myProfile) {
  clearData()

  if (!myProfile)
    myProfile = ls.getCurrentUser()

  // Append my profile to set first sequence of list
  appendMembers([myProfile])

  return new Promise(async resolve => {
    let res = null
    // No request teams when owner
    if (!myProfile.is_owner)
      res = await loadTeams()

    const resManaged = await loadManaged()

    if (res && res.data) {
      for (let i = 0; i < res.data.length; i += 1) {
        if (res.data[i].manager.email === myProfile.email) {
          const tempMembers = [res.data[i].manager, ...res.data[i].users]
          // appendMembers(tempMembers.filter(user => members.findIndex(usr => usr.email === user.email) === -1))
          appendMembers(tempMembers)
        } else {
          for (let j = 0; j < res.data[i].users.length; j += 1) {
            if (res.data[i].users[j].email === myProfile.email) {
              appendMembers([res.data[i].users[j]])
              break
            }
          }
        }
      }
    }

    if (resManaged.data)
      appendMembers(resManaged.data)
      // appendMembers(resManaged.data.filter(user => members.findIndex(usr => usr.email === user.email) === -1))

    resolve({ members, error: (res && res.error) || resManaged.error })
  })
}

async function loadTeams(firstResolve) {
  return new Promise(async resolve => {
    request({
      urlKey: 'read-userTeams',
      data: { page, limit },
      onSuccess: loadTeamsSuccess,
      onFailed: loadTeamsFailed,
      extra: { resolve: firstResolve || resolve },
    })
  })
}

function loadTeamsSuccess(response, extra) {
  teams = teams.concat(response.data.results)

  if (!response.data.next)
    extra.resolve({ data: teams })
  else {
    page += 1
    loadTeams(extra.resolve)
  }
}

function loadTeamsFailed(error, extra) {
  extra.resolve({ error })
}

function loadManaged(firstResolve) {
  return new Promise(resolve => {
    request({
      urlKey: 'read-usersManagedMe',
      data: { page: pageManaged, limit: limitManaged },
      onSuccess: loadManagedSuccess,
      onFailed: loadManagedFailed,
      extra: { resolve: firstResolve || resolve }
    })
  })
}

function loadManagedSuccess(response, extra) {
  managed = managed.concat(response.data.results)

  if (!response.data.next)
    extra.resolve({ data: managed })
  else {
    page += 1
    loadManaged(extra.resolve)
  }
}

function loadManagedFailed(error, extra) {
  extra.resolve({ error })
}

export default loadAllMembers