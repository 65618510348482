import PropTypes from 'prop-types';
import { Button, Card, Col, DatePicker, Row, Segmented, Select, Spin, Tabs } from 'antd';
import { AppstoreOutlined, ArrowLeftOutlined, BarsOutlined, CalendarOutlined, CaretLeftOutlined, CaretRightOutlined, CloseOutlined, FilterOutlined, MinusSquareOutlined, PlusSquareOutlined, ReloadOutlined, RobotOutlined, SmileOutlined } from '@ant-design/icons';
import { get, orderBy } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import { BaseComponent, BaseList } from 'src/components';
import ListAll from './All';
import { lib, request } from 'src/Utils';
import ListWaiting from './Waiting';
import ListApproved from './Approved';
import ListRejected from './Rejected';
// import helper from '../helper';
import example from '../../example';

class AllSubmissions extends BaseList {
  constructor(props) {
    super(props)

    window._uuidv4 = uuidv4

    this.title = 'Approval Staff Leave'
    this.limit = 25
    this.urlKey = 'leave-leaves'
    this.urlKeyConfirm = 'leave-leaves-detail-confirm'
    // this.pagination = false

    this.source_type = 'user_apply'
    this.ordering = '-start_date'
    this.status = 'pending'
    this.statuses = [
      { key: '', label: 'All' },
      { key: 'approved', label: 'Approved' },
      { key: 'rejected', label: 'Rejected' },
      { key: 'pending', label: 'Pending' },
      { key: 'cancelled', label: 'Cancelled' },
    ]

    this.keysQueryParam.push('source_type', 'status', 'ordering')

    this.groupOpts = [
      // ada bug UI/UX saat loadmore dan group by user
      {
        label: 'User',
        value: 'user',
        icon: <RobotOutlined />,
      },
      // {
      //   label: 'Date',
      //   value: 'date',
      //   icon: <CalendarOutlined />,
      // },
    ]

    this.state = {
      ...this.state,
      groupBy: 'start_date', // 'user',
      allExpanded: true,
      selectedDate: moment().format(),
    }
  }

  // convertResponseData = () => {
  //   return orderBy(example.userLeaves, 'date')
  // }

  prevMonth = () => {
    this.onChangeDate(moment(this.state.selectedDate).subtract(1, 'month').format())
  }
  
  nextMonth = () => {
    this.onChangeDate(moment(this.state.selectedDate).add(1, 'month').format())
  }

  onChangeDate = (date) => {
    this.setState({ selectedDate: moment(date).format() })
  }

  onChangeGroupBy = (groupBy) => {
    this.setState({ groupBy })
  }

  onChangeStatus = (status) => {
    this.status = status
    // this.forceRead()
    this.reload()
  }

  onAccept = (userLeave) => {
    this.setState({ isLoading: true }, () => {
      setTimeout(() => {
        this.patchList([{ ...userLeave, status: 'approved' }], 'alias')
        this.setState({ isLoading: false })
      }, 1000)
    })
  }

  onReject = (userLeave) => {
    this.setState({ isLoading: true }, () => {
      setTimeout(() => {
        this.patchList([{ ...userLeave, status: 'rejected' }], 'alias')
        this.setState({ isLoading: false })
      }, 1000)
    })
  }

  approve = (userLeave) => {
    this.confirm(userLeave, { status: 'approved' })
  }

  reject = (userLeave) => {
    this.confirm(userLeave, { status: 'rejected' })
  }

  confirm = (userLeave, data) => {
    this.setState({ isLoading: true }, () => {
      request({
        method: 'post',
        urlKey: this.urlKeyConfirm,
        args: [userLeave.pk],
        data: { confirmed_note: '', ...data },
        onSuccess: this.confirmSuccess,
        onFailed: this.confirmFailed,
        extra: { userLeave },
      })
    })
  }

  confirmSuccess = (res, { userLeave }) => {
    // this.forceRead()
    this.patchList([{ ...userLeave, ...res.data }], 'pk')
    this.setState({ isLoading: false })
  }

  confirmFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.setState({ isLoading: false })
  }

  onScroll = (e) => {
    if ((e.target.scrollTop + e.target.clientHeight) >= e.target.scrollHeight) {
      this.loadMore()
    }
  }

  renderList = () => {
    return (
      <ListAll
        list={this.__list}
        isLoading={this.state.isLoading}
        groupBy={this.state.groupBy}
        allExpanded={this.state.allExpanded}
        // onAccept={this.onAccept}
        // onReject={this.onReject}
        onAccept={this.approve}
        onReject={this.reject}
        // showDeleteActivity={this.props.showDeleteActivity}
      />
    )
  }

  render() {
    return (
      <div style={{ padding: '24px' }}>
        {/* ---------------- Header ---------------- */}
        <div>
          <Row justify='space-between' gutter={[12, 12]} style={{ marginBottom: '12px' }}>
            <Col>
              <Row align='middle'>
                <Col>
                  <div className='ant-page-header-back'>
                    <div className='ant-page-header-back-button' onClick={this.props.onClose} title='Go back'>
                      <ArrowLeftOutlined />
                    </div>
                  </div>
                </Col>
                <Col>
                  <h1 style={{ marginBottom: 0 }}>
                    {this.title}
                  </h1>
                </Col>
              </Row>
            </Col>
            <Col>
              <Spin spinning={this.state.isLoading}>
              <Row align='middle' gutter={[12, 12]}>
                <Col>
                  <Button loading={this.state.isLoading} icon={<ReloadOutlined />} onClick={() => {this.updateList([]); this.reload()}}>
                    Reload
                  </Button>
                </Col>
                <Col>
                  <Button icon={this.state.allExpanded ? <MinusSquareOutlined /> : <PlusSquareOutlined />} onClick={() => this.setState({ allExpanded: !this.state.allExpanded })}>
                    {this.state.allExpanded ? 'Collapse' : 'Expand'}
                  </Button>
                </Col>
                {/* <Col>
                  <Segmented
                    value={this.state.groupBy}
                    options={this.groupOpts}
                    onChange={this.onChangeGroupBy}
                  />
                </Col> */}
                <Col>
                  <Select
                    defaultValue={this.status}
                    style={{ width: '120px' }}
                    suffixIcon={<FilterOutlined />}
                    menuItemSelectedIcon={<FilterOutlined />}
                    onChange={this.onChangeStatus}
                  >
                    {this.statuses.map(status => (
                      <Select.Option key={status.label} value={status.key}>
                        {status.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                {/* <Col>
                  <Row>
                    <Col>
                      <Button icon={<CaretLeftOutlined />} onClick={this.prevMonth} />
                    </Col>
                    <Col style={{ margin: '0 -1px', flex: 1 }}>
                      <DatePicker
                        allowClear={false}
                        picker='month'
                        value={moment(this.state.selectedDate)}
                        onChange={this.onChangeDate}
                      />
                    </Col>
                    <Col>
                      <Button icon={<CaretRightOutlined />} onClick={this.nextMonth} />
                    </Col>
                  </Row>
                </Col> */}
              </Row>
              </Spin>
            </Col>
          </Row>
        </div>

        {/* ---------------- Content ---------------- */}
        {this.state.errorResponse ? this.getErrorComp() : (
          <div>
            <Tabs type='card' className='tabs-pnt' defaultActiveKey='review'>
              <Tabs.TabPane key='all' tab={this.statuses.find(status => status.key === this.status).label}>
                <Card className={classNames('card-all-submission', {mobile: this.mutableScreen.isMobile})} onScroll={this.onScroll}>
                  <Spin spinning={this.state.isLoading}>
                    {this.renderList()}
                    {this.state.isLoadMore && (
                      <div style={{ padding: '12px' , textAlign: 'center' }}>
                        <Spin />
                      </div>
                    )}
                  </Spin>
                </Card>
              </Tabs.TabPane>
              {/* <Tabs.TabPane key='waiting' tab='Waiting'>
                <Card className={classNames('card-all-submission', {mobile: this.mutableScreen.isMobile})}>
                  <Spin spinning={this.state.isLoading}>
                    <ListWaiting
                      list={this.__list}
                      isLoading={this.state.isLoading}
                      groupBy={this.state.groupBy}
                      allExpanded={this.state.allExpanded}
                      onAccept={this.onAccept}
                      onReject={this.onReject}
                      showDeleteActivity={this.props.showDeleteActivity}
                    />
                  </Spin>
                </Card>
              </Tabs.TabPane>
              <Tabs.TabPane key='approved' tab='Approved'>
                <Card className={classNames('card-all-submission', {mobile: this.mutableScreen.isMobile})}>
                  <Spin spinning={this.state.isLoading}>
                    <ListApproved
                      list={this.__list}
                      isLoading={this.state.isLoading}
                      groupBy={this.state.groupBy}
                      allExpanded={this.state.allExpanded}
                      showDeleteActivity={this.props.showDeleteActivity}
                    />
                  </Spin>
                </Card>
              </Tabs.TabPane>
              <Tabs.TabPane key='rejected' tab='Rejected'>
                <Card className={classNames('card-all-submission', {mobile: this.mutableScreen.isMobile})}>
                  <Spin spinning={this.state.isLoading}>
                    <ListRejected
                      list={this.__list}
                      isLoading={this.state.isLoading}
                      groupBy={this.state.groupBy}
                      allExpanded={this.state.allExpanded}
                      showDeleteActivity={this.props.showDeleteActivity}
                    />
                  </Spin>
                </Card>
              </Tabs.TabPane> */}
            </Tabs>
          </div>
        )}
      </div>
    )
  }
}

AllSubmissions.propTypes = {
  ...AllSubmissions.propTypes,
  onClose: PropTypes.func,
  // reload: PropTypes.func,
}

AllSubmissions.defaultProps = {
  ...AllSubmissions.defaultProps,
  onClose: () => {},
  // reload: () => {},
}

export default AllSubmissions