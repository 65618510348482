import PropTypes from 'prop-types'
import { DatePicker, Input, Spin, Tooltip } from 'antd'
import moment from 'moment'
import classNames from 'classnames'

import BaseField from './Base'
import { WarningOutlined } from '@ant-design/icons'

const DATE_FORMAT = 'DD MMM YYYY'

class FieldsInternalDueDate extends BaseField {
  getDefaultValue = (props = this.props) => props.value ? moment(props.value) : undefined

  getUniqueValue = (value) => value && moment(value).format(DATE_FORMAT)

  getValueFromEvent = (date) => date

  getPatchData = () => ({ target_due_date: this.state.value ? moment(this.state.value).utc().format() : null })

  renderText = () => !this.state.value ? this.props.emptyText : (
    // <Tooltip color="orange" overlayInnerStyle={{ width: '230px' }} title={moment(this.state.value).format() < this.props.finishedDate && <div><div style={{ textAlign: 'center', fontWeight: 500 }}><WarningOutlined /> OVERDUE <WarningOutlined /></div>Completed on <b>{moment(this.props.finishedDate).format('DD MMMM YYYY')}</b></div>} mouseLeaveDelay={0}>
      // <span className={classNames({ 'overdue-production': moment(this.state.value).format() < this.props.finishedDate })}>
      <span>
        {moment(this.state.value).format(DATE_FORMAT)}
      </span>
    // </Tooltip>
  )

  renderInput = () => (
    <DatePicker
      {...this.getDefaultInputProps()}
      allowClear={false}
      showToday={false}
      format={DATE_FORMAT}
    />
  )
}

FieldsInternalDueDate.propTypes = {
  ...FieldsInternalDueDate.propTypes,
  // finishedDate: PropTypes.string.isRequired,
  value: PropTypes.string,
}

FieldsInternalDueDate.defaultProps = {
  ...FieldsInternalDueDate.defaultProps,
  value: null,
}

export default FieldsInternalDueDate