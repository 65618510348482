import PropTypes from 'prop-types'
import { message } from 'antd'
import moment from 'moment'

import BaseList from './BaseList'
import { lib } from 'src/Utils'

class BaseListActivity extends BaseList {
  constructor(props) {
    super(props)

    this.RANGE_TYPE = 'day'
    this.DEFAULT_RANGE_TYPE = 'week'
    this.DEFAULT_RANGE_FORMAT = 'YYYY-MM-DD'
    this.MIN_TOTAL = 28800000 // 8 hours
    this.maxTotal = this.MIN_TOTAL

    this.allowedParams = ['start_date', 'end_date', 'id']
    this.updateObjParam(props.location)
    this.setRangeDate({
      start_date: this.objParam.start_date || null,
      end_date: this.objParam.end_date || null,
    })

    this.keysQueryParam = ['range_before', 'range_after', 'id']

    this.pagination = false
    this.urlKey = 'read-activities'

    this.rangeMessageError = 'Cannot display for more than 1 month'

    this.didMountStatements = this.didMountStatements.bind(this)
  }

  updateObjParam = (location) => {
    this.objParam = lib.getObjParam(location.search)
  }

  setDefaultMaxTotal = () => {
    this.maxTotal = this.MIN_TOTAL
  }

  // arr = [{ total: number }, { total: number }]
  // tidak diberi default value, supaya tau saat salah mengirim parameter
  updateMaxTotal = (arr, reset = true) => {
    if (reset)
      this.setDefaultMaxTotal()

    for (let i = 0; i < arr.length; i += 1) {
      if (this.maxTotal < arr[i].total) {
        this.maxTotal = arr[i].total
      }
    }
  }

  // getMaxTotal = (arr, maxTotal = this.maxTotal) => {
  //   for (let i = 0; i < arr.length; i += 1) {
  //     if (maxTotal < arr[i].total) {
  //       maxTotal = arr[i].total
  //     }
  //   }

  //   return maxTotal
  // }

  setRangeDate = ({ start_date, end_date }) => {
    try {
      // don't parsing "null" to moment parameter
      if (start_date !== null)
        this.range_before = moment(start_date).startOf(this.RANGE_TYPE).format()
      if (end_date !== null)
        this.range_after = moment(end_date).endOf(this.RANGE_TYPE).format()
    } catch(e) {}
  }

  onChangeId = (id) => {}
  getPrevParams = () => null
  // getPrevParams = () => lib.getObjParam(this.props.location.search)

  onChangeFilter = ({ start_date, end_date }) => {
    if (this.checkRange(start_date, end_date)) {
      const prevParams = this.getPrevParams()
      this.props.history.replace(this.props.location.pathname + lib.getStrParam({ ...prevParams, start_date, end_date }))
    } else {
      message.info(this.rangeMessageError)
      this.setThisState({ [this.keyIsLoading]: false })
    }
  }

  // default filter is This Week
  changeToDefaultFilter = () => {
    this.onChangeFilter({
      start_date: moment().startOf(this.DEFAULT_RANGE_TYPE).format(this.DEFAULT_RANGE_FORMAT),
      end_date: moment().endOf(this.DEFAULT_RANGE_TYPE).format(this.DEFAULT_RANGE_FORMAT),
    })
  }

  checkParam = (objParam) => !(
    !objParam.start_date ||
    !objParam.end_date ||
    !this.isValidFormatDate(objParam.start_date) ||
    !this.isValidFormatDate(objParam.end_date) ||
    !this.checkRange(objParam.start_date, objParam.end_date)
  )

  checkRange = (startDate, endDate) => (
    moment(endDate).diff(moment(startDate)) < 2678400000 // max range 31 days
  )

  isValidFormatDate(str) {
    return moment(str, ['YYYY-MM-DD', moment.ISO_8601])._isValid
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentParam = lib.getObjParam(this.props.location.search)
    const nextParam = lib.getObjParam(nextProps.location.search)
    this.updateObjParam(nextProps.location)
    if (
      currentParam.start_date !== nextParam.start_date ||
      currentParam.end_date !== nextParam.end_date
      // moment(this.range_before).format('YYYY-MM-DD') !== nextParam.start_date ||
      // moment(this.range_after).format('YYYY-MM-DD') !== nextParam.end_date
    ) {
      this.setRangeDate(nextParam)
      this.forceRead()
    } else if (currentParam.id !== nextParam.id) {
      this.onChangeId(nextParam.id)
    }
  }

  didMountStatements() {}

  // When you need to insert statements, like event listener, don't override this function, but create function called "didMountStatements"
  didMount() {
    if (!this.checkParam({ start_date: this.range_before, end_date: this.range_after })) {
      this.changeToDefaultFilter()
    } else {
      this.forceRead()
    }

    this.didMountStatements()
  }

  // and when you need to remove event listener, use unmount (see in class BaseList)
}

BaseListActivity.propTypes = {
  location: PropTypes.object.isRequired,
}

export default BaseListActivity