import React from 'react'
import { Col, Input, message, Radio, Row } from 'antd'
import { FrownTwoTone } from '@ant-design/icons'

import { BaseModal } from 'src/components'
import { lib } from 'src/Utils'

class DeactivateWarn extends BaseModal {
  constructor(props) {
    super(props)

    this.title = 'Why did you deactivate this user?'

    this.options = [
      { message: 'Prefer not to say' },
      { message: 'They Quit' },
      { message: 'They were fired' },
    ]

    this.radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    }

    this.staticModalProps = {
      okButtonProps: {danger: true},
    }

    this.defaultState = {
      selectedOpt: this.options[0].message,
      customMessage: '',
    }

    this.state = {
      ...this.state,
      ...this.defaultState,
    }
  }

  hide = (state, callback) => {
    this.setThisState({
      ...state,
      visible: false,
      selectedOpt: this.options[0].message,
      customMessage: ''
    }, callback)
  }

  onOk = () => {
    if (!this.getMessage()) {
      message.info('Please input message!')
      return
    }

    this.props.deactivate(this.state.user.alias, this.props.user, this.getMessage())
    this.hide()
  }

  onChange = (event) => {
    this.setThisState({ selectedOpt: event.target.value, customMessage: '' })
  }

  onChangeCustom = (event) => {
    this.setThisState({ customMessage: event.target.value })
  }

  getMessage = () => {
    if (this.state.selectedOpt === 'custom') {
      return this.state.customMessage
    }

    return this.state.selectedOpt
  }

  renderContent = () => {
    this.title = `Why did you deactivate ${lib.getFullName(this.state.user)}?`
    return (
      <Row>
        <Col xs={24} sm={10}>
          <Radio.Group onChange={this.onChange} value={this.state.selectedOpt}>
            {this.options.map(opt => (
              <Radio key={opt.message} value={opt.message} style={this.radioStyle}>
                {opt.message}
              </Radio>
            ))}
            <Radio value='custom'>
              Other ...
            </Radio>
          </Radio.Group>
        </Col>
        <Col xs={0} sm={14}>
          <Row justify='center'>
            <FrownTwoTone
              twoToneColor='#ff4d4f'
              style={{ fontSize: '108px' }}
            />
          </Row>
        </Col>
        {this.state.selectedOpt === 'custom' && (
          <Input.TextArea
            autoFocus
            autoSize={{ minRows: 2, maxRows: 6 }}
            value={this.state.customMessage}
            placeholder='Why this user deactivate?'
            style={{ marginTop: '8px' }}
            onChange={this.onChangeCustom}
          />
        )}
      </Row>
    )
  }
}

export default DeactivateWarn