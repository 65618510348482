import React from 'react'
import { Avatar, Button, Card, Col, Dropdown, Empty, Menu, Modal, Popover, Row, Spin } from 'antd'
import { ArrowDownOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, MoreOutlined, PlusOutlined, ReloadOutlined, UserOutlined } from '@ant-design/icons'
import { get, orderBy } from 'lodash'

import { BaseList } from 'src/components'
// import FormTeam from './FormTeam'
import FormTeam from './FormTeam/index.v2'
import PopoverContentUser from './PopoverContentUser'
import { lib, request } from 'src/Utils'

const MAX_USER_ICON = 5

class ManageTeams extends BaseList {
  constructor(props) {
    super(props)

    this.urlKey = 'read-companyTeams'
    this.limit = 10

    this.state = {
      ...this.state,
      isEditing: false,
      selectedTeam: null,
    }
  }

  beforeRead = (keyLoading) => {
    if (keyLoading === this.keyIsLoading) {
      this.page = 1
    }

    return false
  }

  convertResponseData = (responseData) => {
    for (const i in responseData.results) {
      responseData.results[i].description = `1 Manager, ${responseData.results[i].users.length} Members`
      responseData.results[i].users = orderBy(responseData.results[i].users, 'first_name')
      responseData.results[i].allUsers = [
        { ...responseData.results[i].manager, isManager: true },
        ...responseData.results[i].users,
      ]
    }

    return responseData
  }

  getMoreMenu = (team) => {
    return (
      <Menu>
        <Menu.Item
          key='edit'
          icon={<EditOutlined />}
          onClick={({ domEvent }) => {
            domEvent.stopPropagation()
            this.onSelectTeam(team)
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Item danger key='delete' icon={<DeleteOutlined />} team={team} onClick={this.onDelete}>
          Delete
        </Menu.Item>
      </Menu>
    )
  }

  onDelete = ({ domEvent, item, ...props }) => {
    domEvent.stopPropagation()

    Modal.confirm({
      maskClosable: true,
      title: (
        <span>
          Are you sure want to delete Team <b>"{item.props.team.name}"</b>?
        </span>
      ),
      content: <i>*Note: This action cannot be undone.</i>,
      okButtonProps: { danger: true },
      okText: 'Delete',
      icon: <DeleteOutlined style={{ color: '#ff4d4f' }} />,
      onOk: () => this.deleteTeam(item.props.team),
    })
  }

  deleteTeam = (team) => {
    return new Promise((resolve, reject) => {
      request({
        method: 'delete',
        urlKey: 'change-companyTeams',
        args: [team.alias],
        onSuccess: this.deleteTeamSuccess,
        onFailed: this.deleteTeamFailed,
        extra: { resolve, reject, team },
      })
    })
  }

  deleteTeamSuccess = (response, { team, resolve }) => {
    lib.responseMessage.success(
      <span>Team <b>"{team.name}"</b> has been deleted.</span>,
      'Delete success',
      { stringOnly: false }
    )
    this.updateList(this.__list.filter(t => t.alias !== team.alias))
    resolve()
  }

  deleteTeamFailed = (error, { team, resolve, reject }) => {
    if (get(error.response, ['data', 'detail']) === 'Not found.') {
      this.deleteTeamSuccess(undefined, { team, resolve })
    } else {
      lib.responseMessage.error(error.response)
      reject()
    }
  }

  onSelectTeam = (selectedTeam) => {
    this.setThisState({ isEditing: true, selectedTeam })
  }

  onCloseForm = () => {
    this.setThisState({ isEditing: false })
  }

  updateTeam = (team) => {
    if (!team)
      return

    this.patchList([team])
  }

  updateList = (list, state) => {
    const convertedResponseData = this.convertResponseData({ results: list })
    this.setThisState({ isEditing: false, ...state, responseData: convertedResponseData })
  }

  render() {
    return (
      <div style={{ padding: '24px' }}>
        {this.state.isEditing ? (
          <FormTeam
            team={this.state.selectedTeam}
            onClose={this.onCloseForm}
            updateTeam={this.updateTeam}
          />
        ) : (
          <div>
            <Row justify='space-between' style={{ marginBottom: '24px' }}>
              <Col>
                <h1 style={{ marginBottom: 0 }}>
                  Manage Teams
                </h1>
              </Col>
              <Col>
                <Button
                  icon={<ReloadOutlined />}
                  loading={this.state[this.keyIsLoading]}
                  onClick={this.forceRead}
                >
                  Refresh
                </Button>
              </Col>
            </Row>
            {this.state.errorResponse ? this.getErrorComp() : (
              <Spin spinning={this.state[this.keyIsLoading]}>
                <Row gutter={[12, 24]} style={{ flexDirection: 'column' }}>
                  <Col>
                    <Button
                      ghost
                      type='dashed'
                      size='large'
                      icon={<PlusOutlined style={{ fontSize: '20px' }} />}
                      style={{ borderColor: '#9c9c9c', color: '#9c9c9c', width: '100%' }}
                      onClick={() => this.onSelectTeam()}
                    />
                  </Col>
                  {this.__list.length > 0 ? this.__list.map(team => (
                    <Col key={team.alias}>
                      <Card className='app-card' style={{ cursor: 'pointer' }} onClick={() => this.onSelectTeam(team)}>
                        <Row justify='space-between' gutter={[12, 12]}>
                          <Col>
                            <div>
                              <b>{team.name}</b>
                            </div>
                            <div className='subtitle'>
                              {team.description}
                            </div>
                          </Col>
                          <Col>
                            <Row>
                              {!this.mutableScreen.isMobile && (
                                <Col style={{ marginRight: '32px' }}>
                                  <Row>
                                    {team.allUsers.filter((_, i) => i < MAX_USER_ICON).map(user => (
                                      <Col key={user.alias} style={{ width: '24px' }}>
                                        <Popover
                                          overlayClassName='ant-popover-profile'
                                          placement='left'
                                          mouseLeaveDelay={0}
                                          content={<PopoverContentUser user={user} />}
                                        >
                                          <Avatar
                                            size={36}
                                            src={user.photo}
                                            icon={<UserOutlined />}
                                            className='list-user-icon'
                                          />
                                        </Popover>
                                      </Col>
                                    ))}
                                    {team.allUsers.length > MAX_USER_ICON && (
                                      <Col style={{ width: '24px' }}>
                                        <EllipsisOutlined
                                          className='subtitle'
                                          title={`${team.allUsers.length - MAX_USER_ICON} more`}
                                          style={{
                                            fontSize: '24px',
                                            padding: '6px',
                                            marginLeft: '8px',
                                            opacity: 0.3,
                                          }}
                                        />
                                      </Col>
                                    )}
                                  </Row>
                                </Col>
                              )}
                              <Col>
                                <Dropdown
                                  arrow
                                  placement='bottomRight'
                                  trigger={['click']}
                                  overlay={this.getMoreMenu(team)}
                                  overlayStyle={{ minWidth: '150px' }}
                                >
                                  <MoreOutlined
                                    className='subtitle btn-more-icon'
                                    onClick={e => {e.stopPropagation()}}
                                  />
                                </Dropdown>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  )) : (
                    <Card>
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description='No Team'
                      />
                    </Card>
                  )}
                  {this.next && (
                    <Col style={{ textAlign: 'center' }}>
                      <Button loading={this.state[this.keyIsLoadMore]} icon={<ArrowDownOutlined />} onClick={this.loadMore}>
                        Load More
                      </Button>
                    </Col>
                  )}
                </Row>
              </Spin>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default ManageTeams