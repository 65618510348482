import React from 'react'
import { Collapse, Row, Table, Tooltip } from 'antd'
import { CreditCardOutlined } from '@ant-design/icons'

import { BaseReport } from '../components'
import { BarAnim } from 'src/components'
import { lib } from 'src/Utils'

const { Panel } = Collapse

const UNKNOWN_WINDOW = {
  alias: "No App",
  name: "No App",
  title: "No App",
}

class AppUsageReport extends BaseReport {
  constructor(props) {
    super(props)

    // this.id = undefined
    // this.keysQueryParam.push(...['id'])

    this.keysQueryParam.push('related', 'prefetch')
    // this.related = 'task,note'
    this.prefetch = 'windows'
    this.topApps = []

    this.title = 'App Usage Report'

    this.columns = [
      {
        dataIndex: '.',
        title: 'No.',
        width: '40px',
        align: 'center',
        render: (_, __, i) => i + 1,
      },
      {
        dataIndex: 'name',
        title: 'App Name',
        width: '200px',
        ellipsis: true,
        sorter: (a, b) => a.name > b.name ? 1 : -1,
        render: (val) => (
          <>
            <CreditCardOutlined style={{ color: 'green', marginRight: '6px' }} />
            {val}
          </>
        )
      },
      {
        dataIndex: 'total',
        title: 'Total Time',
        width: '110px',
        align: 'right',
        sorter: (a, b) => a.total - b.total,
        render: (val) => lib.time.getLabel(val, true),
      },
      {
        dataIndex: '_',
        title: '',
        width: '150px',
        render: (_, record) => {
          return (
            <Tooltip
              placement='left'
              overlay={`${record.name} - ${lib.time.getLabel(record.total, true)}`}
            >
              <div>
                <BarAnim
                  className=''
                  width={this.getBarWidth(record.total)}
                />
              </div>
            </Tooltip>
          )
        },
      }
    ]
  }

  convertResponseData = (userActivities) => {
    this.setDefaultMaxTotal()
    // for (const i in userActivities) {
      // const { activities } = userActivities[i]

      // Filter window with created in range of activity
      for (const j in userActivities) {
        for (let k = userActivities[j].windows.length - 1; k >= 0; k -= 1) {
          const { actual_created } = userActivities[j].windows[k]
          const fromLeft = lib.time.getDiff({ start_time: userActivities[j].start_time, finish_time: actual_created })
          const fromRight = lib.time.getDiff({ start_time: actual_created, finish_time: userActivities[j].finish_time })
          const validWindowCreated = fromLeft > 0 && fromRight > 0
          if (!validWindowCreated) {
            userActivities[j].windows.splice(k, 1)
          }
        }
      }
      // -----------------------------------------------

      const apps = []
      for (const j in userActivities) {
        let startTime = userActivities[j].start_time

        if (userActivities[j].windows.length) {
          // catatan, for in, itu string, bukan number
          // dan juga hati-hati, tidak boleh range time sampai minus
          for (let k = 0; k < userActivities[j].windows.length; k += 1) {
            if (!startTime)
              startTime = userActivities[j].windows[k].actual_created

            const endTime = userActivities[j].windows[k + 1] ? userActivities[j].windows[k + 1].actual_created : userActivities[j].finish_time
            apps.push({
              ...userActivities[j].windows[k],
              start_time: startTime,
              finish_time: endTime,
            })

            startTime = null
          }
        } else {
          // No windows in activity
          apps.push({
            ...UNKNOWN_WINDOW,
            start_time: startTime,
            finish_time: userActivities[j].finish_time,
          })
        }
      }

      const KEY = 'name'
      const objTopApps = {}
      for (let j = 0; j < apps.length; j += 1) {
        if (!objTopApps[apps[j][KEY]])
          objTopApps[apps[j][KEY]] = { ...apps[j], key: apps[j][KEY], total: 0 }

        objTopApps[apps[j][KEY]].total += lib.time.getDiff(apps[j])
      }

      this.totalWorked = 0

      const topApps = []
      for (const key in objTopApps) {
        topApps.push(objTopApps[key])

        this.totalWorked += objTopApps[key].total
      }

      topApps.sort((a, b) => b.total - a.total)

      this.topApps = topApps

      this.updateMaxTotal(topApps, false)
    // }

    return userActivities
  }

  // getTopApps = (activities) => {
  //   const apps = []
  //   for (const i in activities) {
  //     let startTime = activities[i].start_time

  //     // catatan, for in, itu string, bukan number
  //     // dan juga hati-hati, tidak boleh range time sampai minus
  //     for (let j = 0; j < activities[i].windows.length; j += 1) {
  //       if (!startTime)
  //         startTime = activities[i].windows[j].actual_created

  //       const endTime = activities[i].windows[j + 1] ? activities[i].windows[j + 1].actual_created : activities[i].finish_time
  //       apps.push({
  //         ...activities[i].windows[j],
  //         start_time: startTime,
  //         finish_time: endTime,
  //       })

  //       startTime = null
  //     }
  //   }

  //   const KEY = 'name'
  //   const objTopApps = {}
  //   for (let i = 0; i < apps.length; i += 1) {
  //     if (!objTopApps[apps[i][KEY]])
  //       objTopApps[apps[i][KEY]] = { ...apps[i], key: apps[i][KEY], total: 0 }

  //     objTopApps[apps[i][KEY]].total += lib.time.getDiff(apps[i])
  //   }

  //   const topApps = []
  //   for (const key in objTopApps) {
  //     topApps.push(objTopApps[key])
  //   }

  //   topApps.sort((a, b) => b.total - a.total)

  //   return topApps.filter((_, i) => i < 5)
  // }

  getBodyContent = () => {
    const selectedUsers = this.getSelectedUsers()

    return (
    <div>
      {selectedUsers.length > 0 && (
          <Collapse activeKey={selectedUsers.map(user => user.alias)}>
            {selectedUsers.map(user => (
              <Panel key={user.alias} header={this.getUserTitle(user)} className='panel-report'>
                <Row justify='space-between'>
                  <div style={{ fontSize: '20px', padding: '12px' }}>
                    Total Worked: <b>{lib.time.getLabel(this.totalWorked, true)}</b>
                  </div>
                  {!this.mutableScreen.isMobile && this.getDateRangeContent()}
                </Row>
                <Table
                  bordered
                  size='small'
                  columns={this.columns}
                  dataSource={this.topApps}
                  pagination={false}
                  scroll={this.scrollConfig}
                />
              </Panel>
            ))}
          </Collapse>
        )}
    </div>
  )
  }
}

export default AppUsageReport