import { Affix } from 'antd'

import { BaseComponent } from 'src/components'

class AffixHeader extends BaseComponent {
  render() {
    return this.mutableScreen.isMobile ? this.props.children : (
      <Affix>
        {this.props.children}
      </Affix>
    )
  }
}

export default AffixHeader