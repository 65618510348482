import PropTypes from 'prop-types'
import { Tabs } from 'antd'

import { BaseComponent } from 'src/components'
import ProductionDetailFinished from './Finished'
import ProductionDetailInProgress from './InProgress'

class UserProductionDetail extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      ...this.getSplittedProductionJobs(props.productionJobs),
    }
  }

  getSplittedProductionJobs = (productionJobs) => ({
    progressProductionJobs: productionJobs.filter(job => job.status === 'in_progress'),
    finishedProductionJobs: productionJobs.filter(job => job.status === 'finished'),
  })

  resetData = (productionJobs) => {
    this.setState(this.getSplittedProductionJobs(productionJobs))
  }

  changeProductionJobHandler = (newProductionJob) => {
    const foundIndex = this.state.progressProductionJobs.findIndex(productionJob => productionJob.id === newProductionJob.id)

    if (foundIndex !== -1) {
      // memperbarui item
      this.state.progressProductionJobs[foundIndex] = {
        ...this.state.progressProductionJobs[foundIndex],
        ...newProductionJob,
      }

      // merubah instance object melalui setState supaya child component ter-render dan ter-trigger di nextProps listener
      this.setState({ progressProductionJobs: [...this.state.progressProductionJobs] })
    }

    this.forceUpdate()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.productionJobs !== nextProps.productionJobs) {
      this.resetData(nextProps.productionJobs)
    }
  }

  render() {
    return (
      <div className='production-detail-container' style={{ marginTop: '24px' }}>
        <Tabs type='card'>
          <Tabs.TabPane tabKey='finished' tab='Production Detail'>
            <ProductionDetailFinished
              // ref={this.onRefDetail}
              loading={this.props.loading}
              // dateString={this.getFinishedDate()}
              // productionJobs={this.props.productionJobs.filter(job => job.status === 'finished')}
              productionJobs={this.state.finishedProductionJobs}

              // reload={this.forceRead}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key='in-progress' tab='In-Progress'>
            <ProductionDetailInProgress
              loading={this.props.loading}
              // dateString={this.getFinishedDate()}
              // productionJobs={this.props.productionJobs.filter(job => job.status === 'in_progress')}
              productionJobs={this.state.progressProductionJobs}
              onChangeItem={this.changeProductionJobHandler}

              // reload={this.forceRead}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    )
  }
}

UserProductionDetail.propTypes = {
  ...UserProductionDetail.propTypes,
  productionJobs: PropTypes.array,
}

UserProductionDetail.defaultProps = {
  ...UserProductionDetail.defaultProps,
  productionJobs: [],
}

export default UserProductionDetail