import React from 'react'
import { Button, Card, Col, DatePicker, Popover, Row, Spin, message } from 'antd'
import { CalendarOutlined, CaretLeftOutlined, CaretRightOutlined, InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons'
import { get, startCase } from 'lodash'
import moment from 'moment'

import { lib, permissions, request } from 'src/Utils'
import { BaseComponent, PageError } from 'src/components'
// import Point from 'src/pages/DashboardApp/DashboardManager/ChartProduction/Point'
import PointUser from 'src/pages/DashboardApp/DashboardManager/ChartProduction/PointUser'
import AmountPct from './AmountPct'
// import AmountByGrade from 'src/pages/DashboardApp/DashboardManager/ChartProduction/AmountByGrade'
// import AreaByGrade from 'src/pages/DashboardApp/DashboardManager/ChartProduction/AreaByGrade'
import PointByGrade from './PointByGrade';
import AreaByGrade from './AreaByGrade'
import JobByGrade from './JobByGrade'
// import JobByGrade from 'src/pages/DashboardApp/DashboardManager/ChartProduction/JobByGrade'
// import PointByGrade from 'src/pages/DashboardApp/DashboardManager/ChartProduction/PointByGrade'
import UserProductionDetail from './ProductionDetail'
import { mutableScreen } from 'src/mutable'
import typeCodes from 'src/Utils/static/typeCodes'

const { MonthPicker } = DatePicker
const HOURS_IN_DAY = 8 // 8 is static from Pak Hendry

class UserProduction extends BaseComponent {
  constructor(props) {
    super(props)

    this.title = 'Production'
    this.readOnly = true
    this.userId = this.props.userId

    this.selectedDate = props.dateString || moment().format('YYYY-MM')

    this.state = {
      isLoading: true,
      isLoadingActivity: false,
      errorResponse: null,
      members: [],
      target: undefined, // default must undefined, because AmountPct has defaultProps
      prevTarget: undefined, // default must undefined, because AmountPct has defaultProps
      prodJobsCompany: undefined, // default must undefined, because AmountPct has defaultProps
      // prevProdJobsCompany: undefined, // default must undefined, because AmountPct has defaultProps
      // userActivities: [], // not used
      totalWorkingHours: 0,
      totalWorkingDays: 0,
      userTarget: undefined,
      prevUserTarget: undefined,
      usersProductionJobs: undefined,
      prevUsersProductionJobs: undefined,
    }
  }

  // onRefPoint = (ref) => {
  //   this._point = ref
  // }

  // onRefPointUser = (ref) => {
  //   this._pointUser = ref
  // }

  // onChangeFilter = (e) => {
  //   this.read()
  // }

  // createTargets = async () => {
  //   return await request({
  //     method: 'post',
  //     urlKey: 'read-productionTargets',
  //     data: {
  //       date: moment(this.selectedDate).startOf('month').format('YYYY-MM-DD'),
  //       working_days: 0,
  //       area: 0,
  //       amount: 0,
  //       total_job: 0,
  //     },
  //   })
  // }

  readTargets = async (date) => {
    return await request({
      // urlKey: 'read-productionTargets-byDate',
      urlKey: 'read-productionTargetsDashboard',
      data: {
        date: moment(date || this.selectedDate).startOf('month').format('YYYY-MM-DD'),
      },
    })
  }

  readUserTargets = async (date) => {
    return await request({
      urlKey: 'read-productionTargetsUsers',
      data: {
        date: moment(date || this.selectedDate).startOf('month').format('YYYY-MM-DD'),
        id: this.userId,
      },
    })
  }
  
  readUsersProductionJobs = async (date) => {
    return await request({
      urlKey: 'read-usersProductionJobs',
      data: {
        finished_date_after: moment(date || this.selectedDate).startOf('month').format(),
        finished_date_before: moment(date || this.selectedDate).endOf('month').format(),
        id: this.userId,
        // status: 'finished',
      },
    })
  }

  readPrevUsersProductionJobs = async () => {
    return await request({
      urlKey: 'read-usersProductionJobs',
      data: {
        finished_date_after: moment(this.selectedDate).subtract(1, 'month').startOf('month').format(),
        finished_date_before: moment(this.selectedDate).subtract(1, 'month').endOf('month').format(),
        id: this.userId,
      }
    })
  }

  readProdJobsCompany = async () => {
    return await request({
      // urlKey: 'read-productionJobs',
      urlKey: 'read-productionJobsDashboard',
      data: {
        finished_date_after: moment(this.selectedDate).startOf('month').format(),
        finished_date_before: moment(this.selectedDate).endOf('month').format(),
        status: 'finished',
      }
    })
  }

  readPrevProdJobsCompany = async () => {
    return await request({
      urlKey: 'read-productionJobs',
      data: {
        finished_date_after: moment(this.selectedDate).subtract(1, 'month').startOf('month').format(),
        finished_date_before: moment(this.selectedDate).subtract(1, 'month').endOf('month').format(),
        status: 'finished',
      }
    })
  }

  readEffectiveExchange = () => {
    return new Promise((resolve, reject) => {
      request({
        urlKey: 'read-effectiveExchange',
        data: { date: moment(this.selectedDate).format('YYYY-MM-DD') },
        onSuccess: res => resolve(res),
        onFailed: err => reject(err),
      })
    })
  }

  readPrevEffectiveExchange = () => {
    return new Promise((resolve, reject) => {
      request({
        urlKey: 'read-effectiveExchange',
        data: { date: moment(this.selectedDate).subtract(1, 'month').format('YYYY-MM-DD') },
        onSuccess: res => resolve(res),
        onFailed: err => reject(err),
      })
    })
  }

  readUserTypes = (date) => {
    return new Promise((resolve, reject) => {
      request({
        urlKey: 'read-userPointTypes',
        data: {
          id: this.userId,
          // date: moment(this.selectedDate).startOf('month').format('YYYY-MM-DD'),
          date: moment(date || this.selectedDate).startOf('month').format('YYYY-MM-DD'),
        },
        onSuccess: res => resolve(res),
        onFailed: err => reject(err),
      })
    })
  }

  readUserActivitiesOld = async (date) => {
    return new Promise(resolve => {
      request({
        urlKey: 'read-activities',
        data: {
          range_before: moment(date || this.selectedDate).startOf('month').format(),
          range_after: moment(date || this.selectedDate).endOf('month').format(),
          id: this.userId,
        },
        onSuccess: (res) => resolve(res.data[0].activities),
        onFailed: () => resolve(),
      })
    })
  }

  readUserActivities = async (date) => {
    this.setState({ isLoadingActivity: true }, () => {
      request({
        urlKey: 'read-activities',
        data: {
          range_before: moment(date || this.selectedDate).startOf('month').format(),
          range_after: moment(date || this.selectedDate).endOf('month').format(),
          id: this.userId,
        },
        onSuccess: (res) => {
          const totalWorkingHours = res.data[0].activities.reduce((acc, activity) => acc + moment(activity.finish_time).diff(moment(activity.start_time)), 0) / (1000 * 60 * 60)
          const totalWorkingDays = Math.ceil(totalWorkingHours / HOURS_IN_DAY)
          this.setState({ isLoadingActivity: false, totalWorkingDays, totalWorkingHours })
        },
        onFailed: (error) => {
          lib.responseMessage.error(error.response)
          this.setState({ isLoadingActivity: false })
        },
      })
    })
  }
  
  read = async () => {
    this.setThisState({ isLoading: true }, async () => {
      const prevDate = moment(this.selectedDate).subtract(1, 'month')
      // Reload QS Production Jobs
      // this.props.setDate(this.selectedDate)

      // Reload production on previous month
      // if (this._point && typeof this._point.readProductionJobs === 'function')
      //   this._point.readProductionJobs(this.selectedDate)

      const loadedMember = await lib.loadAllMembers() // { members, error }
      if (loadedMember.error) {
        this.setThisState({ isLoading: false, errorResponse: loadedMember.error.response })
        return
      }

      const prevResUserTypes = await this.readUserTypes(prevDate).catch(() => this.setThisState({ isLoading: false, errorResponse: true }))
      const resUserTypes = await this.readUserTypes().catch(() => this.setThisState({ isLoading: false, errorResponse: true }))
      if (!prevResUserTypes || !resUserTypes) return

      const prevUserType = prevResUserTypes.data.find(user => String(user.id) === String(this.userId))
      this.userType = resUserTypes.data.find(user => String(user.id) === String(this.userId))
      this.prevUserTypeQS = get(prevUserType, 'point_types', []).find(type => typeCodes.QS_CODES.includes(type.code))
      this.userTypeQS = get(this.userType, 'point_types', []).find(type => typeCodes.QS_CODES.includes(type.code))

      let resPrevUserTargets = await this.readUserTargets(prevDate)
      let resPrevTargets = await this.readTargets(prevDate)
      if (!resPrevTargets)
        resPrevTargets = { data: { amount: 0, area: 0, total_job: 0, working_days: 0 } }

      // Buat ada konfirmasi bahwa belum ada target pada bulan tersebut
      // if (!resTargets)
      //   resTargets = await this.createTargets()
      let resUserTargets = await this.readUserTargets()
      let resTargets = await this.readTargets()
      if (!resTargets)
        resTargets = { data: { amount: 0, area: 0, total_job: 0, working_days: 0 } }

      // resPrevTargets.data.amount = lib.prod.getUserAmount(get(resPrevUserTargets, ['data', 0, 'target', 'amount']))
      // resTargets.data.amount = lib.prod.getUserAmount(get(resUserTargets, ['data', 0, 'target', 'amount']))

      // if (!resTargets)
      //   return this.onError('Error when collecting target data')

      // if (!resTargets || !resTargets.data)
      //   return this.onError('Error when create default target data')
      let exchange = null, prevExchange = null

      // if (this.props.user.point_reward.is_bonus) {
      if (get(this.userType, 'is_bonus')) {
        const resExchange = await this.readEffectiveExchange().catch(err => this.setThisState({ isLoading: false, errorResponse: true }))
        if (!resExchange) return

        // const resPrevExchange = await this.readPrevEffectiveExchange().catch(err => this.setThisState({ isLoading: false, errorResponse: true }))
        // if (!resPrevExchange) return

        exchange = resExchange.data
        // prevExchange = resPrevExchange.data
      }

      // production of current user on selected month
      const usersProductionJobs = get(await this.readUsersProductionJobs(), 'data')
      let userActivities = []
      // let totalWorkingHours = 0, totalWorkingDays = 0
      if (get(usersProductionJobs, [0])) {
        const isQS = get(this.userType, 'point_types', []).find(type => typeCodes.QS_CODES.includes(type.code))
        const isQA = get(this.userType, 'point_types', []).find(type => typeCodes.QA_CODES.includes(type.code))

        // --- job tetap ditampilkan meskipun tidak memiliki point type ---
        // if (!isQS) usersProductionJobs[0].qs_production_jobs = []
        // if (!isQA) usersProductionJobs[0].qa1_production_jobs = []

        usersProductionJobs[0].qs_production_jobs.sort((a, b) => a.prod_day - b.prod_day)
        usersProductionJobs[0].qa1_production_jobs.sort((a, b) => a.prod_day - b.prod_day)

        this.readUserActivities()

        // // if (usersProductionJobs[0].qs_production_jobs.concat(usersProductionJobs[0].qa1_production_jobs).length) {
        //   // read user activities in selected month
        //   userActivities = await this.readUserActivitiesOld()
        //   totalWorkingHours = userActivities.reduce((acc, activity) => acc + moment(activity.finish_time).diff(moment(activity.start_time)), 0) / (1000 * 60 * 60)
        //   totalWorkingDays = Math.ceil(totalWorkingHours / HOURS_IN_DAY)
        //   console.log('userActivities', userActivities)
        // // }
      }

      // production of current user on previous selected month
      const prevUsersProductionJobs = get(await this.readUsersProductionJobs(prevDate), 'data')
      if (get(prevUsersProductionJobs, [0])) {
        const isQS = get(this.userType, 'point_types', []).find(type => typeCodes.QS_CODES.includes(type.code))
        const isQA = get(this.userType, 'point_types', []).find(type => typeCodes.QA_CODES.includes(type.code))

        if (!isQS) prevUsersProductionJobs[0].qs_production_jobs = []
        if (!isQA) prevUsersProductionJobs[0].qa1_production_jobs = []
      }

      // let totalPoint = usersProductionJobs ? (
      //   usersProductionJobs[0].qa1_production_jobs.reduce((prev, val) => prev + Number(val.qa1_point), 0) +
      //   usersProductionJobs[0].qs_production_jobs.reduce((prev, val) => prev + Number(val.qs_point), 0)
      // ) : 0

      // production on previous selected month
      // const prevUserProductionJobs = get(await this.readPrevUsersProductionJobs(), 'data')
      // let prevTotalPoint = prevUserProductionJobs ? (
      //   prevUserProductionJobs[0].qa1_production_jobs.reduce((prev, val) => prev + Number(val.qa1_point), 0) +
      //   prevUserProductionJobs[0].qs_production_jobs.reduce((prev, val) => prev + Number(val.qs_point), 0)
      // ) : 0

      // production on the company
      const prodJobsCompany = get(await this.readProdJobsCompany(), 'data')

      this.setThisState({
        isLoading: false,
        errorResponse: false,
        members: loadedMember.members,
        prevTarget: resPrevTargets.data,
        target: resTargets.data,
        // productionJobs,
        usersProductionJobs,
        prevUsersProductionJobs,
        userTarget: get(resUserTargets, ['data', 0, 'target']),
        prevUserTarget: get(resPrevUserTargets, ['data', 0, 'target']),
        userActivities,
        // totalWorkingHours,
        // totalWorkingDays,

        // totalPoint: Math.round(totalPoint * 100) / 100,
        // prevTotalPoint: Math.round(prevTotalPoint * 100) / 100,
        prodJobsCompany,
        // prevProdJobsCompany,

        exchange,
        // prevExchange,
      })
    })
  }

  onError = (strError) => {
    const error = {
      response: {
        status: strError,
        // data: { detail: strError }
      }
    }

    this.setThisState({ isLoading: false, errorResponse: error.response })
  }

  // patchTarget = (data, callback) => {
  //   request({
  //     method: 'patch',
  //     urlKey: 'change-productionTargets',
  //     args: [this.state.target.alias],
  //     data,
  //     onSuccess: this.patchTargetSuccess,
  //     onFailed: this.patchTargetFailed,
  //     extra: { callback },
  //   })
  // }

  // patchTargetSuccess = (response, extra) => {
  //   this.setThisState({ target: response.data })
  //   extra.callback()
  // }

  // patchTargetFailed = (error, extra) => {
  //   lib.responseMessage.error(error.response)
  //   extra.callback()
  // }

  // syncTargets = async (targetAlias) => {
  //   return await request({
  //     method: 'post',
  //     urlKey: 'sync-productionTargets',
  //     args: [targetAlias],
  //   })
  // }

  // getAllowSync = () => {
  //   const allowedState = ['new', 'failed', 'synced']

  //   return allowedState.includes(get(this.state.target, 'sync_state'))
  // }

  // manualSync = async () => {
  //   if (this.state.syncing)
  //     return

  //   this.setThisState({ syncing: true }, () => {
  //     request({
  //       method: 'post',
  //       urlKey: 'sync-productionTargets',
  //       args: [this.state.target.alias],
  //       onSuccess: this.syncSuccess,
  //       onFailed: this.syncFailed,
  //     })
  //   })
  // }

  // syncSuccess = (response) => {
  //   this.setThisState({ syncing: false, target: { ...this.state.target, ...response.data } })
  // }

  // syncFailed = (error) => {
  //   lib.responseMessage.error(error.response)
  //   this.setThisState({ syncing: false })
  // }

  prevMonth = () => {
    if (this.state.isLoading)
      return

    this.selectedDate = moment(this.selectedDate).subtract(1, 'month').format('YYYY-MM')
    this.read()
  }

  nextMonth = () => {
    if (this.state.isLoading)
      return

    this.selectedDate = moment(this.selectedDate).add(1, 'month').format('YYYY-MM')
    this.read()
  }

  getSyncTitle = () => {
    if (!this.state.target)
      return null

    const { sync_state } = this.state.target
    const colors = { new: '#1890ff', syncing: '#fcda55', failed: '#ff4d4f', synced: '#10bd10' }

    return (
      <div style={{ minWidth: '240px', }} onClick={e => {e.stopPropagation()}}>
        <Row justify='space-between'>
          <Col>Sync Status:</Col>
          <Col style={{ color: colors[sync_state] || 'black' }}>
            {startCase(sync_state)}
          </Col>
        </Row>
      </div>
    )
  }

  getSyncInfoContent = () => {
    const { target } = this.state

    if (!target)
      return null

    // Auto last sync time
    if (target.last_sync_time) {
      let useDailySync = true
      if (target.last_manual_sync_time) {
        // range from end time to start time (not absolute)
        useDailySync = moment(target.last_manual_sync_time).diff(moment(target.last_sync_time)) < -(1000 * 60) // 1 minute
      }

      if (useDailySync) {
        return (
          <div>
            Last sync on {moment(target.last_sync_time).format('DD MMM YYYY hh:mm:ss A')}
          </div>
        )
      }
    }

    // Manual sync time
    if (target.last_manual_sync_time) {
      return (
        <div>
          Last sync by <b>{lib.getFullName(target.last_manual_sync_by)}</b> on {moment(target.last_manual_sync_time).format('DD MMM YYYY hh:mm:ss A')}
        </div>
      )
    }

    // No sync for the month
    return (
      <div>
        No sync yet for the month
      </div>
    )
  }

  getProductionJobs = (statuses = ['in_progress', 'finished']) => {
    return get(this.state.usersProductionJobs, [0, 'qa1_production_jobs'], [])
      .concat(get(this.state.usersProductionJobs, [0, 'qs_production_jobs'], []))
      .filter((job, i, arr) => statuses.includes(job.status) && arr.findIndex(item => item.job_id === job.job_id) === i)
      .sort((a, b) => a.prod_day - b.prod_day)
  }

  getQsFinishedJobs = (userProductionJobs) => {
    return get(userProductionJobs, ['qs_production_jobs'], [])
      .filter(job => job.status === 'finished')
  }

  getQsFinishedUserProductionJobs = (userProductionJobs) => {
    if (!userProductionJobs) return userProductionJobs

    return {
      ...userProductionJobs,
      qs_production_jobs: this.getQsFinishedJobs(userProductionJobs),
      qa1_production_jobs: [], // ?
    }
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.userId !== nextProps.userId) {
      this.userId = nextProps.userId
      this.read()
    }
  }

  didMount() {
    this.read()
  }

  render() {
    window.konsole.log('Rendered', this)
    const canViewProdDashboard = permissions.canViewProdDashboard(this.myProfile, this.props.user)
    const canViewProdDetail = (canViewProdDashboard && permissions.canViewReward(this.userType)) ||
      // this.state.members.some(member => member.id === get(this.userType, 'id')) // userType tidak ditemukan karena Leader Team tidak memiliki permission Team Production 
      this.state.members.some(member => String(member.id) === String(this.userId))

    return (canViewProdDashboard || canViewProdDetail) && (
      <div>
        <Row justify='space-between' gutter={[12, 6]} style={{ marginBottom: '12px' }}>
          <Col>
            <h2 style={{ marginBottom: 0 }}>
              {this.title}
              <span style={{ marginLeft: '12px', fontSize: '14px', color: '#a1a1a1' }}>
                <CalendarOutlined style={{ marginRight: '4px' }} />
                {moment(this.selectedDate).format('MMMM YYYY')}
              </span>
            </h2>
          </Col>
          
          <Col>
            <Row gutter={[6, 6]}>
              <Col className='only-xs-block'>
                <Button block icon={<ReloadOutlined />} loading={this.state.isLoading} onClick={this.read}>
                  Refresh
                </Button>
              </Col>
              <Col style={{ flex: 1, minWidth: '175px' }}>
                <Row>
                  <Col>
                    <Button icon={<CaretLeftOutlined />} onClick={this.prevMonth} />
                  </Col>
                  <Col style={{ flex: 1 }}>
                    <MonthPicker
                      allowClear={false}
                      value={moment(this.selectedDate)}
                      style={{ margin: '0 -1px', width: 'calc(100% + 2px)' }}
                      onChange={(date, dateString) => {this.selectedDate = dateString; this.read();}}
                    />
                  </Col>
                  <Col>
                    <Button icon={<CaretRightOutlined />} onClick={this.nextMonth} />
                  </Col>
                </Row>
              </Col>
              {/* {(!this.state.errorResponse || this.state.isLoading) && (
                <Col style={mutableScreen.isMobile && { flex: 1 }}>
                  <Button.Group style={{ width: '100%' }}>
                    <Popover
                      placement='bottomRight'
                      trigger='click'
                      title={this.getSyncTitle()}
                      content={this.getSyncInfoContent()}
                    >
                      <Button
                        style={{ width: '36px' }}
                        icon={<InfoCircleOutlined />}
                      />
                    </Popover>
                  </Button.Group>
                </Col>
              )} */}
            </Row>
          </Col>
        </Row>
        {(!this.state.errorResponse || this.state.isLoading) ? (
          <Row gutter={[12, 12]}>
            {canViewProdDashboard && (
              <>
                {this.props.canViewPointReward && (
                  <>
                    <Col xs={24} sm={24} lg={12} xxl={6}>
                      <Card className='app-card' bodyStyle={{ padding: '12px' }} style={{ height: '100%' }}>
                        <Spin spinning={this.state.isLoading}>
                          <AmountPct
                            readOnly
                            userTypeQS={this.userTypeQS}
                            prevUserTypeQS={this.prevUserTypeQS}
                            date={this.selectedDate}
                            target={this.state.target}
                            prevTarget={this.state.prevTarget}
                            prodJobsCompany={this.state.prodJobsCompany}
                            // prevProdJobsCompany={this.state.prevProdJobsCompany}

                            userTarget={this.state.userTarget}
                            prevUserTarget={this.state.prevUserTarget}
                            userProductionJobs={get(this.state.usersProductionJobs, [0])}
                            prevUserProductionJobs={get(this.state.prevUsersProductionJobs, [0])}
                            // productionJobs={this.getQsFinishedJobs(get(this.state.usersProductionJobs, [0]))}
                            // prevProductionJobs={this.getQsFinishedJobs(get(this.state.prevUsersProductionJobs, [0]))}
                            // userProductionJobs={this.getQsFinishedUserProductionJobs(get(this.state.usersProductionJobs, [0]))}
                            // prevUserProductionJobs={this.getQsFinishedUserProductionJobs(get(this.state.prevUsersProductionJobs, [0]))}
                          />
                          {/* <PointUser
                            readOnly
                            date={this.selectedDate}
                            point={this.state.totalPoint}
                            prevPoint={this.state.prevTotalPoint}
                            effectiveExchange={this.state.exchange}
                            effectiveExchange={this.state.exchange}
                          /> */}
                        </Spin>
                      </Card>
                    </Col>
                    <Col xs={24} sm={24} lg={12} xxl={6}>
                      <Card className='app-card' bodyStyle={{ padding: '12px' }} style={{ height: '100%' }}>
                        <Spin spinning={this.state.isLoading || this.state.isLoadingActivity}>
                          {this.userType && (
                            <PointByGrade
                              readOnly
                              dateString={this.selectedDate}
                              // userProductionJobs={get(this.state.usersProductionJobs, [0])}
                              userProductionJobs={this.getQsFinishedUserProductionJobs(get(this.state.usersProductionJobs, [0]))}

                              // userId={this.userId}
                              // user={this.props.user}
                              user={this.userType}
                              effectiveExchange={this.state.exchange}
                              // totalWorkingHours={this.state.totalWorkingHours}
                              totalWorkingDays={this.state.totalWorkingDays}
                            />
                          )}
                        </Spin>
                      </Card>
                    </Col>
                  </>
                )}
                <Col xs={24} sm={24} lg={12} xxl={this.props.canViewPointReward ? 6 : 12}>
                  <Card className='app-card' bodyStyle={{ padding: '12px' }} style={{ height: '100%' }}>
                    <Spin spinning={this.state.isLoading || this.state.isLoadingActivity}>
                      <AreaByGrade
                        readOnly
                        target={Number(get(this.state.target, 'area', 0))}
                        productionJobs={this.getProductionJobs(['finished'])}
                        totalWorkingHours={this.state.totalWorkingHours}
                      />
                    </Spin>
                  </Card>
                </Col>
                <Col xs={24} sm={24} lg={12} xxl={this.props.canViewPointReward ? 6 : 12}>
                  <Card className='app-card' bodyStyle={{ padding: '12px' }} style={{ height: '100%' }}>
                    <Spin spinning={this.state.isLoading || this.state.isLoadingActivity}>
                      <JobByGrade
                        readOnly
                        target={Number(get(this.state.target, 'total_job', 0))}
                        productionJobs={this.getProductionJobs(['finished'])}
                        // totalWorkingHours={this.state.totalWorkingHours}
                        totalWorkingDays={this.state.totalWorkingDays}
                      />
                    </Spin>
                  </Card>
                </Col>
              </>
            )}
            {/* {((canViewProdDashboard && permissions.canViewReward(this.userType)) || this.state.members.some(member => member.id === get(this.userType, 'id'))) && ( */}
            {canViewProdDetail && (
              <Col span={24}>
                <Spin spinning={this.state.isLoading}>
                  <UserProductionDetail
                    loading={this.state.isLoading}
                    productionJobs={this.getProductionJobs()}
                  />
                </Spin>
              </Col>
            )}
          </Row>
        ) : (
          <PageError
            errorResponse={this.state.errorResponse}
            onReload={this.read}
          />
        )}
      </div>
    )
  }
}

UserProduction.defaultProps = {
  setProductionJobs: () => {},
  setDate: () => {},
}

export default UserProduction