import { notification } from 'antd'
import { capitalize, get } from 'lodash'

import getAllMessage from './getAllMessage'

const types = ['success', 'info', 'warning', 'warn', 'open']

const objs = types.reduce((acc, curr) => ({ ...acc, [curr]: (msg, title, opts) => responseMessage(curr, msg, title, opts) }), {})

function errorMessageHandler(message, title, options) {
  if (`${get(message, 'status')}` === '404') {
    message = `Invalid URL Request, maybe the URL API doesn't exist or has been moved.`
  }

  if (get(message, 'data')) {
    message = message.data
  }

  responseMessage('error', message, title, { key: 'error', ...options })
}

function responseMessage(type, message, title = null, options = {}) {
  options = {
    stringOnly: true,
    ...options,
  };

  notification[type]({
    message: !title ? (type === 'error' ? 'Failed!' : capitalize(type) + '!') : title,
    description: options.stringOnly ? getAllMessage(message) : message,
    key: 'responseNotification',
    ...options,
  })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  error: errorMessageHandler,
  ...objs,
}