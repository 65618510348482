import React from 'react'
import { Avatar, Button, Card, Col, Dropdown, Menu, Row, Tag } from 'antd'
import { CloseOutlined, EditOutlined, MoreOutlined, RiseOutlined, UserOutlined } from '@ant-design/icons'

import { BaseComponent } from 'src/components'
import { lib } from 'src/Utils'

class CardUser extends BaseComponent {
  onClose = () => {
    this.props.onRemove(this.props.user)
  }

  onEdit = () => {
    this.props.onEdit(this.props.user)
  }

  render() {
    const { isMySelf, isManager, user} = this.props

    return (
      <Card
        className='app-card'
        bodyStyle={{ padding: '6px 6px 12px 6px' }}
        style={{
          border: isMySelf && '1px solid #1890ff',
          height: '160px',
          position: 'relative',
        }}
      >
        <div style={{ textAlign: 'right', marginBottom: '-12px', height: '30px' }}>
          {!isManager ? (
            <CloseOutlined
              className='subtitle btn-icon-only danger'
              style={{ fontSize: '14px' }}
              onClick={this.onClose}
            />
          ) : (
            <EditOutlined
              className='subtitle btn-icon-only'
              style={{ fontSize: '14px' }}
              onClick={this.onEdit}
            />
          )}
        </div>
        <Row justify='center' align='middle' gutter={[6, 6]} style={{ flexDirection: 'column' }}>
          <Col>
            <Avatar
              src={user.photo}
              icon={<UserOutlined />}
              size={64}
            />
          </Col>
          <Col className='text-truncate' style={{ fontWeight: 500 }}>
            {lib.getFullName(user)} {isMySelf && <span style={{ color: '#1890ff' }}>(you)</span>}
          </Col>
          <Col>
            {isManager ? (
              <Tag color='blue' style={{ marginRight: 0 }}>
                Manager
              </Tag>
            ) : (
              <Tag color='green' style={{ marginRight: 0 }}>
                Member
              </Tag>
            )}
          </Col>
        </Row>
      </Card>
    )
  }
}

export default CardUser