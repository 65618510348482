import PropTypes from 'prop-types';
import { Button, Col, DatePicker, Form, Input, Radio, Row, Select, Slider, Spin } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';

import { BaseComponent, SelectQuery } from 'src/components';
import { lib, request } from 'src/Utils';
import _h from 'src/pages/DashboardApp/Submission/helper';

class LeaveForm extends BaseComponent {
  constructor(props) {
    super(props)

    this.myProfile = lib.ls.getCurrentUser()
  }

  applyLeaveHandler = () => {
    this.props.getForm().validateFields().then(values => {
      this.props.setLoading(true, () => {
        values.half_day = values.day_type === 'half_day'
        request({
          method: 'post',
          urlKey: 'leave-user-leaves',
          data: {
            leave_type: values.type.value,
            day_type: values.day_type,
            duration: this.getDurationByMinutes(values.day_minutes),
            start_date: values.range_date[0].format('YYYY-MM-DD'),
            finish_date: values.range_date[1].format('YYYY-MM-DD'),
            note: values.note,
            user: this.props.userId,
          },
          onSuccess: this.applyLeaveSuccess,
          onFailed: this.applyLeaveFailed,
          extra: { values },
        })
      })
    }).catch(() => {})
  }

  applyLeaveSuccess = (res, extra) => {
    this.props.hide(null, () => this.props.onSavedLeave(res.data), true)
  }

  applyLeaveFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.props.setLoading(false)
  }

  getDurationByMinutes = (minutes) => {
    return lib.time.getNumberTime(minutes * 60 * 1000) // .slice(0, 6) + '00'
  }

  getMsByDuration = (strDuration) => {
    const [strHours, strMinutes] = strDuration.split(':')
    const totalMinutes = Number(strHours) * 60 + Number(strMinutes)
    return totalMinutes * 60 * 1000
  }

  onChangeDayType = (e) => {
    const dayCount = e.target.value === 'full' ? 1 : 0.5
    this.props.getForm().setFieldsValue({ day_minutes: dayCount * lib.company.getWorkHour(this.myProfile) * 60 })

    return e.target.value
  }

  renderFooter = () => {
    return (
      <Row justify="space-between">
        <Col />
        <Col>
          <Row gutter={[6, 6]}>
            <Col>
              <Button onClick={this.props.hide}>
                Cancel
              </Button>
            </Col>
            {/* {this.form} */}
            <Col>
              <Form.Item noStyle shouldUpdate>
                {(form) => (
                  <Button type="primary" icon={<SaveOutlined />} onClick={this.applyLeaveHandler}>
                    Apply for {get(form.getFieldValue('type'), 'label')}
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  renderTotalLeave = (rangeDate) => {
    let totalLeave = 0
    // const rangeDays = rangeDate[1].diff(rangeDate[0], 'day')
    const startDate = rangeDate[0].clone()
    const endDate = rangeDate[1].format('YYYY-MM-DD')

    do {
      if (![0, 6].includes(startDate.day()))
        totalLeave += 1

      startDate.add(1, 'day')
    } while(startDate.format('YYYY-MM-DD') <= endDate)

    return (
      <div style={{ color: 'rgba(0, 0, 0, 0.4)', padding: '4px' }}>
        <span>
          Total leave :
        </span>
        <span style={{ fontWeight: 500, marginLeft: '4px' }}>
          {totalLeave} days
        </span>
      </div>
    )
  }

  render() {
    const { selectedLeave } = this.props

    return (
      <div>
        <Form
          ref={this.props.onRefForm}
          layout="vertical"
          initialValues={{
            day_type: 'full',
            day_minutes: lib.company.getWorkHour(this.myProfile) * 60, // mengikuti day_type
            range_date: this.props.defaultDate && [moment(this.props.defaultDate), moment(this.props.defaultDate)],
            ...(selectedLeave && {
              range_date: [moment(selectedLeave.start_date), moment(selectedLeave.finish_date)],
              type: { value: selectedLeave.leave_type.pk, label: selectedLeave.leave_type.name },
              day_type: selectedLeave.day_type,
              note: selectedLeave.note,
              // day_minutes: this.getMsByDuration(selectedLeave.duration) / (60 * 1000),
              day_minutes: (selectedLeave.day_type === 'full' ? 8 : 4) * 60,
            }),
          }}
        >
        <div className="ant-modal-body">
          <Row gutter={[12, 12]} style={{ marginBottom: '12px' }}>
            <Col span={24}>
              <Form.Item
                name="range_date"
                label="Leave Date"
                style={{ marginBottom: 0 }}
                rules={[
                  { required: true },
                  ({ getFieldValue }) => ({
                    validator(_, dates) {
                      console.log('validator', dates[0].format('YYYY-MM-DD'), dates[1].format('YYYY-MM-DD'), _)
                      if (dates && dates[0] && dates[1] && dates[0].format('YYYY-MM') !== dates[1].format('YYYY-MM'))
                        return Promise.reject(new Error('Please select within the same month'))

                      return Promise.resolve()
                    }
                  })
                ]}
              >
                <DatePicker.RangePicker
                  allowClear={false}
                  popupClassName="leave-date-picker"
                  style={{ width: '100%' }}
                  disabledDate={currentDate => (
                    // currentDate.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ||
                    [0, 6].includes(currentDate.day()) // 0 = sunday, 6 = saturday
                  )}
                  dateRender={currentDate => {
                    return (
                      <div className={classNames("ant-picker-cell-inner", { weekend: [0, 6].includes(currentDate.day()) })}>
                        {currentDate.date()}
                      </div>
                    )
                  }}
                />
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {form => this.renderTotalLeave(form.getFieldValue('range_date'))}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={8}>
              <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                <SelectQuery
                  urlKey="leave-types-autocomplete"
                  placeholder="Leave Type"
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item name="day_type" label="Day Type" rules={[{ required: true }]} getValueFromEvent={this.onChangeDayType}>
                <Radio.Group className="w-100" style={{ display: 'flex' }}>
                  <Radio value="full" className="box-radio">Full Day</Radio>
                  <Radio value="half" className="box-radio">Half Day</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item noStyle shouldUpdate>
                {(form) => {
                  const dayType = form.getFieldValue('day_type')
                  const dayMinutes = form.getFieldValue('day_minutes')

                  return (
                    <div>
                      <div style={{ marginBottom: '6px' }}>
                        <div>
                          Calculated Hours :
                        </div>
                        <div>
                          <span style={{ fontWeight: 700, marginLeft: '4px' }}>
                            {_h._overtime.renderDuration(dayMinutes * 60 * 1000)}
                          </span>
                        </div>
                      </div>
                      <div style={{ padding: '0 4px' }}>
                        <Form.Item name="day_minutes" noStyle>
                          <Slider
                            step={15}
                            min={0}
                            max={lib.company.getWorkHour(this.myProfile) * 60}
                            disabled={dayType === 'full'}
                            style={{ margin: 0 }}
                            tooltip={{
                              formatter: (valMinute) => {
                                const hours = Math.floor(valMinute / 60)
                                const minutes = valMinute - (hours * 60)
                                return `${hours} Hours` + (!minutes ? '' : ` ${minutes} Minutes`)
                              }
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )
                }}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="note" label="Reason" rules={[{ required: true }]}>
            <Input.TextArea
              showCount
              maxLength={200}
              placeholder="Reason"
              autoSize={{ minRows: 2, maxRows: 2 }}
            />
          </Form.Item>
        </div>
        <div>
          <div className="ant-modal-footer">
            {this.renderFooter()}
          </div>
        </div>
        </Form>
      </div>
    )
  }
}

LeaveForm.propTypes = {
  ...LeaveForm.propTypes,
  defaultDate: PropTypes.string.isRequired,
  getForm: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  selectedLeave: PropTypes.object,
  hide: PropTypes.func,
  onRefForm: PropTypes.func,
  onSavedLeave: PropTypes.func,
}

LeaveForm.defaultProps = {
  ...LeaveForm.defaultProps,
  selectedLeave: null,
  hide: () => {},
  onRefForm: () => {},
  onSavedLeave: () => {},
}

export default LeaveForm