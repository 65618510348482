import React from 'react'
import { Calendar, Card, Col, Row, Tabs } from 'antd'
import { BarsOutlined, CalendarOutlined } from '@ant-design/icons'

import { BaseComponent } from 'src/components'
import ContentCalendar from './Calendar'
import ContentList from './List'

class ContentAttendance extends BaseComponent {
  constructor(props) {
    super(props)

    this.hasListMode = true

    this.state = {
      activeTab: 'calendar',
    }
  }

  getTabCalendar = () => this.getTabTitle('Calendar', <CalendarOutlined />)

  getTabList = () => this.getTabTitle('List', <BarsOutlined />)

  getTabTitle = (str, icon) => (
    <div>
      {icon}{str}
    </div>
  )

  onChangeTab = (activeTab) => {
    this.setThisState({ activeTab })
  }

  renderContentCalendar() {
    return (
      <ContentCalendar
        userAttendances={this.props.userAttendances}
        date={this.props.date}
        setDate={this.props.setDate}
      />
    )
  }

  renderContentList() {
    return (
      <ContentList
        user={this.props.user}
        userAttendances={this.props.userAttendances}
        isFocus={this.state.activeTab === 'list'}
        date={this.props.date}
        setDate={this.props.setDate}
      />
    )
  }

  render() {
    return (
      <div style={{ marginTop: '12px', borderTop: '1px solid #e8e8e8' }}>
        <Tabs activeKey={this.state.activeTab} onChange={this.onChangeTab}>
          <Tabs.TabPane key='calendar' tab={this.getTabCalendar()}>
            {this.renderContentCalendar()}
          </Tabs.TabPane>
          {this.hasListMode && (
            <Tabs.TabPane key='list' tab={this.getTabList()}>
              {this.renderContentList()}
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    )
  }
}

export default ContentAttendance