import React from 'react'
import { Avatar, Col, Form, Input, Row, Tag, Upload } from 'antd'
import { AuditOutlined, CameraFilled, UserOutlined } from '@ant-design/icons'
import { get } from 'lodash'
import moment from 'moment'

import { BaseComponent } from 'src/components'

class EditUserContent extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      thumbnail: props.user.photo,
    }
  }

  onRefForm = (ref) => {
    this._form = ref
  }

  getLevel = () => {
    if (this.props.user.is_owner) {
      return (
        <Tag color='success' icon={<AuditOutlined />}>
          Owner
        </Tag>
      )
    }

    return 'Regular User'
  }

  getInitialDataPhoto = () => {
    if (!this.props.user.photo) return undefined

    return [{
      uid: new Date().getTime(),
      name: this.props.user.photo,
      status: 'done',
      url: this.props.user.photo,
    }]
  }

  onChangeThumbnail = (e) => {
    this.reader = new FileReader()
    this.reader.onloadend = this.onLoadReader
    const file = get(e.fileList[e.fileList.length - 1], 'originFileObj')
    if (file) this.reader.readAsDataURL(file)
    else {
      this.reader = null
      this.onLoadReader()
    }

    return e && e.fileList
  }

  onLoadReader = () => {
    this.setThisState({ thumbnail: get(this.reader, 'result') })
  }

  render() {
    return (
      <Row>
        <Form ref={this.onRefForm}>
          <Row>
            <Col xs={24} md={10}>
              <Row justify='center' style={{ marginBottom: '24px', padding: '12px' }}>
                <Col style={{ position: 'relative' }}>
                  <Avatar
                    icon={<UserOutlined />}
                    src={this.state.thumbnail}// || 'https://i.pinimg.com/originals/9d/06/87/9d0687e6f4f0ebaf3e6595e0ab070051.png'}
                    size={154}
                    style={{ border: '1px solid #e8e8e8'  }}
                  />
                  <Form.Item
                    name='thumbnail'
                    className='form-item-photo'
                    valuePropName='fileList'
                    getValueFromEvent={this.onChangeThumbnail}
                    initialValue={this.getInitialDataPhoto()}
                  >
                    <Upload beforeUpload={() => false} maxCount={1} itemRender={() => null} accept='image/*'>
                      <Row justify='center' align='middle' className='btn-take-photo'>
                        <CameraFilled />
                      </Row>
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row justify='center' style={{ fontSize: '18px', fontWeight: 500, fontStyle: 'italic', marginTop: '12px' }} title='My E-Mail account'>
                    {this.props.user.email}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={14}>
              <div style={{ color: 'rgba(0, 0, 0, 0.65)', marginBottom: '12px' }}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ padding: '2px 8px 2px 0' }}>
                        Level
                      </td>
                      <td>:</td>
                      <td style={{ fontWeight: 500, paddingLeft: '4px' }}>
                        {this.getLevel()}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '2px 8px 2px 0' }}>
                        Date Joined
                      </td>
                      <td>:</td>
                      <td style={{ fontWeight: 500, paddingLeft: '4px' }}>
                        {moment(this.props.user.date_joined).format('dddd, DD MMMM YYYY')}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Form.Item name='first_name' initialValue={this.props.user.first_name} rules={[{ required: true, message: 'Please enter First Name!' }]}>
                    <Input placeholder='First Name' onPressEnter={this.props.onSave} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name='last_name' initialValue={this.props.user.last_name}>
                    <Input placeholder='Last Name' onPressEnter={this.props.onSave} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name='employee_id' initialValue={this.props.user.employee_id}>
                <Input placeholder='Employee ID' onPressEnter={this.props.onSave} />
              </Form.Item>
              {/* <Form.Item name='address1' initialValue={this.props.user.address1}>
                <Input placeholder='Address 1' />
              </Form.Item>
              <Form.Item name='address2' initialValue={this.props.user.address2}>
                <Input placeholder='Address 2' />
              </Form.Item> */}
            </Col>
          </Row>
        </Form>
      </Row>
    )
  }
}

export default EditUserContent