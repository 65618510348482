import PropTypes from 'prop-types';
import { Card, Col, Row } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { orderBy } from 'lodash';
import moment from 'moment';

import { BaseDrawer } from 'src/components';
import PopoverContentUser from './PopoverContentUser';
import { lib } from 'src/Utils';

class DialogListUser extends BaseDrawer {
  constructor(props) {
    super(props)

    this.title = 'Staff Leave by date'

    this.initDefaultState({ date: null, usersLeave: [] })
    this.initState()
  }

  getDialogProps = () => ({
    // centered: true,
    // footer: null,
    width: '460px',
  })

  getData = () => this.state.usersLeave
  renderContentUser = (userLeave) => <PopoverContentUser
    userLeave={userLeave}
    style={{ maxWidth: '100%' }}
  />

  renderContent = () => {
    console.log('Rendered', this)
    return (
      <div>
        {this.state.date && (
          <div style={{ fontSize: '16px', fontWeight: 500, marginBottom: '12px', textAlign: 'right' }}>
            <CalendarOutlined style={{ marginRight: '6px' }} />
            {moment(this.state.date).format('DD MMM YYYY')}
          </div>
        )}
        <Row gutter={[0, 12]} style={{ flexDirection: 'column' }}>
          {orderBy(this.getData(), item => lib.getFullName(item.user)).map(userLeave => (
            <Col key={userLeave.pk}>
              <Card
                hoverable
                bodyStyle={{ padding: 0 }}
                onClick={() => this.props.showDetail(userLeave)}
              >
                {this.renderContentUser(userLeave)}
                {/* <PopoverContentUser
                  userLeave={userLeave}
                /> */}
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    )
  }
}

DialogListUser.propTypes = {
  ...DialogListUser.propTypes,
  showDetail: PropTypes.func,
}

DialogListUser.defaultProps = {
  ...DialogListUser.defaultProps,
  showDetail: () => {},
}

export default DialogListUser