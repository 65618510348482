import React from 'react'
import { Collapse, Row, Table, Tooltip } from 'antd'
import { get } from 'lodash'

import { BaseReport } from '../components'
import { BarAnim } from 'src/components'
import { lib } from 'src/Utils'

const { Panel } = Collapse

class ActivityNotesReport extends BaseReport {
  constructor(props) {
    super(props)

    this.title = 'Activity Notes Report'

    this.notes = []
    this.keysQueryParam.push('related')
    this.related = 'task,note'

    this.columns = [
      {
        dataIndex: '_',
        title: 'No.',
        width: '60px',
        align: 'center',
        render: (_, record, i) => record.children ? i + 1 : '',
      },
      {
        dataIndex: 'name',
        title: 'Note',
        width: '200px',
        ellipsis: true,
        sorter: (a, b) => a.name > b.name ? 1 : -1,
        onCell: (record) => ({ title: (record.notes ? '' : 'Task: ') + record.name })
      },
      {
        dataIndex: 'total',
        title: 'Total Time',
        width: '110px',
        align: 'right',
        sorter: (a, b) => a.total - b.total,
        render: (val) => lib.time.getLabel(val, true),
      },
      {
        dataIndex: '_',
        title: '',
        width: '150px',
        render: (_, record) => {
          return (
            <Tooltip
              placement='left'
              overlay={`${record.name} - ${lib.time.getLabel(record.total, true)}`}
            >
              <div>
                <BarAnim
                  className=''
                  width={this.getBarWidth(record.total)}
                />
              </div>
            </Tooltip>
          )
        }
      }
    ]
  }

  convertResponseData = (userActivities) => {
    this.setDefaultMaxTotal()

    // for (const i in userActivities) {
      // const { activities } = userActivities[i]

      this.totalWorked = 0
      this.notes = []

      for (const j in userActivities) {
        this.totalWorked += lib.time.getDiff(userActivities[j])

        const note = userActivities[j].note ? userActivities[j].note.note : 'No Note'
        const noteIndex = this.notes.findIndex(n => n.name === note)
        const task = { ...userActivities[j].task, total: lib.time.getDiff(userActivities[j]) }
        const tasks = get(this.notes[noteIndex], 'children', [])
        const taskIndex = tasks.findIndex(t => t.alias === task.alias)

        tasks.splice(
          taskIndex !== -1 ? taskIndex : tasks.length,
          taskIndex !== -1 ? 1 : 0,
          {
            ...task,
            total: lib.time.getDiff(userActivities[j]) + get(tasks[taskIndex], 'total', 0)
          }
        )
        tasks.sort((a, b) => b.total - a.total)

        this.notes.splice(
          noteIndex !== -1 ? noteIndex : this.notes.length,
          noteIndex !== -1 ? 1 : 0,
          {
            alias: get(userActivities[j].note, 'note'),
            name: note,
            total: lib.time.getDiff(userActivities[j]) + get(this.notes[noteIndex], 'total', 0),
            children: tasks,
          }
        )
      }

      this.notes.sort((a, b) => b.total - a.total)
      this.updateMaxTotal(this.notes, false)
    // }

    return userActivities
  }

  getBodyContent = () => {
    const selectedUsers = this.getSelectedUsers()

    return (
    <div>
      <div>
        {selectedUsers.length > 0 && (
          <Collapse activeKey={selectedUsers.map(user => user.alias)}>
            {selectedUsers.map(user => (
              <Panel key={user.alias} header={this.getUserTitle(user)} className='panel-report'>
                <Row justify='space-between'>
                  <div style={{ fontSize: '20px', padding: '12px' }}>
                    Total Worked: <b>{lib.time.getLabel(this.totalWorked, true)}</b>
                  </div>
                  {!this.mutableScreen.isMobile && this.getDateRangeContent()}
                </Row>
                <Table
                  bordered
                  // expandRowByClick
                  expandable={{ expandRowByClick: true }}
                  size='small'
                  columns={this.columns}
                  dataSource={this.notes}
                  pagination={false}
                  rowKey={record => record.alias || 'No Note'}
                  scroll={this.scrollConfig}
                  onRow={(record) => record.children ? { style: {cursor: 'pointer', fontWeight: 500, backgroundColor: '#fbfbfb'} } : null}
                />
              </Panel>
            ))}
          </Collapse>
        )}
      </div>
    </div>
  )
  }
}

export default ActivityNotesReport