import React from 'react'
import { Avatar, Button, Card, Col, Dropdown, Menu, Row, Tag } from 'antd'
import { CheckCircleFilled, CloseOutlined, EditOutlined, MoreOutlined, RiseOutlined, UserOutlined } from '@ant-design/icons'

import { BaseComponent } from 'src/components'
import { lib } from 'src/Utils'

class SelectableCardUser extends BaseComponent {
  render() {
    const { isMySelf, isManager, selected, user } = this.props

    return (
      <Card
        hoverable
        bodyStyle={{ padding: '6px 6px 12px 6px' }}
        style={{
          border: isMySelf && '1px solid #1890ff',
          height: '160px',
          position: 'relative',
          border: selected ? '3px solid #52c41a' : '',
        }}
        onClick={() => this.props.onClickUser(user)}
      >
        <div style={{ textAlign: 'right', marginBottom: '-12px', height: '30px' }}>
          {selected ? (
            <CheckCircleFilled style={{ fontSize: '21px', color: '#52c41a' }} />
          ) : (
            <span
              style={{
                display: 'inline-block',
                border: '1px solid #cccccc',
                borderRadius: '50%',
                width: '21px',
                height: '21px',
              }}
            />
          )}
        </div>
        <Row justify='center' align='middle' gutter={[6, 6]} style={{ flexDirection: 'column' }}>
          <Col>
            <Avatar
              src={user.photo}
              icon={<UserOutlined />}
              size={64}
            />
          </Col>
          <Col className='text-truncate' style={{ fontWeight: 500 }}>
            {lib.getFullName(user)} {isMySelf && <span style={{ color: '#1890ff' }}>(you)</span>}
          </Col>
          {selected && (
          <Col>
            {isManager ? (
              <Tag color='blue' style={{ marginRight: 0 }}>
                Manager
              </Tag>
            ) : (
              <Tag color='green' style={{ marginRight: 0 }}>
                Member
              </Tag>
            )}
          </Col>
          )}
        </Row>
      </Card>
    )
  }
}

export default SelectableCardUser