import React from 'react'
import { Col, Row } from 'antd'

import TopUsageTask from './Task'
import TopUsageApps from './Apps'

class TopUsage extends React.PureComponent {
  render() {
    return (
      <Row justify='space-between' gutter={[12, 12]}>
        <Col xs={24} sm={24} xl={12}>
          <TopUsageTask
            // startDate={this.props.startDate}
            // endDate={this.props.endDate}
            // userId={this.props.userId}
            {...this.props}
            // Need props: isLoading, errorResponse, startDate, endDate, userId
        />
        </Col>
        <Col xs={24} sm={24} xl={12}>
          <TopUsageApps
            // startDate={this.props.startDate}
            // endDate={this.props.endDate}
            // userId={this.props.userId}
            {...this.props}
            // Need props: isLoading, errorResponse, startDate, endDate, userId
          />
        </Col>
      </Row>
    )
  }
}

export default TopUsage