import permissions from './staticPermissions'
import { lib } from 'src/Utils'

function canViewRewardReport(currentUser) {
  const user = currentUser || lib.ls.getCurrentUser()

  return user.is_owner ||
    user.permissions.findIndex(perm => perm === permissions.VIEW_MONTHLY_PRODUCTION_REWARD) !== -1
}

export default canViewRewardReport