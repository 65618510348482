import canApproveLeave from './canApproveLeave'
import canApproveOvertime from './canApproveOvertime'

import canManageProject from './canManageProject'
import canManageTeam from './manageTeam'
import canReview from './canReview'
import canViewPointReward from './canViewPointReward'
import canViewReward from './canViewReward'
import canViewProdDashboard from './canViewProdDashboard'
import canViewProdDetailReport from './canViewProdDetail'
import canViewRewardReport from './canViewRewardReport'
import hasAttendanceModule from './hasAttendanceModule'
import hasLeaveModule from './hasLeaveModule'
import hasManagerProd from './managerProd'
import hasOvertimeModule from './hasOvertimeModule'
import hasPointRewardModule from './hasPointRewardModule'
import useGpsAttend from './useGpsAttend'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  canApproveLeave,
  canApproveOvertime,

  canManageProject,
  canManageTeam,
  canReview,
  canViewPointReward,
  canViewReward,
  canViewProdDashboard,
  canViewProdDetailReport,
  canViewRewardReport,
  hasAttendanceModule,
  hasLeaveModule,
  hasManagerProd,
  hasOvertimeModule,
  hasPointRewardModule,
  useGpsAttend,
}