function toFile(url, filename, mimeType) {
  return (fetch(url)
    .then(function(res){return res.arrayBuffer();})
    .then(function(buf){return new File([buf], filename,{type:mimeType});})
  )
}

// function dataURLtoFile(dataurl, filename) {
//   const arr = dataurl.split(','),
//     mime = arr[0].match(/:(.*?);/)[1],
//     bstr = atob(arr[1]), 
//     n = bstr.length, 
//     u8arr = new Uint8Array(n);
      
//   while(n--) {
//     u8arr[n] = bstr.charCodeAt(n);
//   }
  
//   return new File([u8arr], filename, {type:mime});
// }

export default toFile