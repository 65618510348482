import PropTypes from 'prop-types';
import moment from 'moment';

import { BaseComponent } from 'src/components';
import { lib, request } from 'src/Utils';
import _h from 'src/pages/DashboardApp/Submission/helper';

class UserBalance extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      userBalance: null,
    }
  }

  readBalances = () => {
    request({
      urlKey: 'leave-user-balances',
      data: { user: this.props.userId },
      onSuccess: res => {
        this.setState({ userBalance: res.data[0] })
      },
      onFailed: error => {
        lib.responseMessage.error(error.response)
      },
    })
  }

  didMount() {
    this.readBalances()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.userId !== nextProps.userId) {
      this.readBalances()
    }
  }

  render() {
    return (
      <div>
        <div style={{ fontSize: '12px', color: '#cdcdcd' }}>
          Remaining leaves including all future approved leaves
        </div>
        <div>
          <span style={{ fontSize: '18px', fontWeight: 500 }}>
            {_h._leave.getLeaveBalance(this.state.userBalance) || 0} days
          </span>
        </div>
      </div>
    )
  }
}

UserBalance.propTypes = {
  ...UserBalance.propTypes,
}

UserBalance.defaultProps = {
  ...UserBalance.defaultProps,
}

export default UserBalance