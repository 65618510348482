import axios from 'axios';
import { notification } from 'antd';
import queryString from "query-string";

import urls from 'src/config/urls';
import appConfig from 'src/config/app';
import lib from './lib';
import refreshToken from './refreshToken'
import { get } from 'lodash';

const CancelToken = axios.CancelToken;
let defOpt;

export default function request(opt = {}) {
  // let controller = null,
  //   timeoutId = null;
  let api = ''

  defOpt = {
    method: 'get',
    urlKey: '',
    args: [],
    init: [],
    toJSON: true,
    state: null,
    data: undefined,
    params: "",
    headers: {
      Authorization: "Bearer " + lib.ls.getUserToken().access_token,
      "X-Company-Token": lib.ls.getUserToken().selectedCompany,
      'X-Client-Offset': new Date().getTimezoneOffset(),
      "Content-Type": "application/json",
      ...opt.headers
    },
    extra: {},
    getCancel: () => {},
    onSuccess: r => {},
    onFailed: e => {},
    onCancel: () => {},
    // onBoth: (isSuccess, response) => null,
    // onBefore: (controller, requestOptions) => null,
  };

  const tempArgs = { ...arguments[0] }

  if (opt.clearHeaders) {
    defOpt.headers = {}
    axios.defaults.headers.common = {}
  }

  opt = { ...defOpt, ...opt };

  // if (typeof opt.headers !== 'object') throw 'Invalid headers, headers must be an object';
  if (typeof opt.headers !== 'object')
    opt.headers = null

  if (opt.data instanceof FormData) opt.toJSON = null;
  else if (typeof opt.data === 'object') defOpt.headers['Content-Type'] = 'application/json';

  opt.headers = { ...defOpt.headers, ...opt.headers };

  for (let header in opt.headers)
    axios.defaults.headers.common[header] = opt.headers[header];

  // axios.defaults.headers.common = {}; how to clear header jika terlanjur dimasukkan ke object common
  // if (typeof opt.onSuccess !== 'function') throw 'Invalid onSuccess, onSuccess must be a function';
  if (typeof opt.onSuccess !== 'function')
    opt.onSuccess = () => {}

  // if (typeof opt.onFailed !== 'function') throw 'Invalid onFailed, onFailed must be a function';
  if (typeof opt.onFailed !== 'function')
    opt.onFailed = () => {}

  if (!urls[opt.urlKey]) {
    if (!opt.config || !opt.config.url)
      opt.onFailed({
        response: { data: { detail: "No related URL found" } }
      }, opt.extra);
  } else {
    api = urls[opt.urlKey];
  }

  if (opt.method.toLowerCase() === "get" && opt.data) opt.data = { params: opt.data };

  // if (!Array.isArray(opt.args)) throw 'Invalid arguments, data must be an array';
  if (!Array.isArray(opt.args))
    opt.args = []

  for (const arg of opt.args)
    api = api.replace('{}', arg)

  if (opt.params) opt.params = `?${queryString.stringify(opt.params)}`

  if (!(opt.data instanceof FormData)) {
    opt.data = {
      ...opt.data,
      cancelToken: new CancelToken(function executor(c) {
        opt.getCancel(c);
      })
    }
  }

  const strParams = (opt.method.toLowerCase() === 'get' && get(opt.data, 'params')) ? lib.getStrParam(opt.data.params) : ''

  return axios({
    method: opt.method,
    url: `${appConfig.url.api}/${api}${opt.params}${strParams}`,
    // data: opt.data,
    ...opt.config,
  })
  .then(function(response) {
    opt.onSuccess(response, opt.extra);
    return response
  })
  .catch(async function(error) {
    const errorStatus = get(error, ['response', 'status'])
    if (errorStatus === 404 || errorStatus === 500) {
      opt.onFailed(error, opt.extra)
      return
    }

    // if(error && error.response && error.response.status && error.response.status === 404) {
    //   // alert('Sorry, Response from API is 404 not found');
    //   opt.onFailed(error, opt.extra)
    //   return;
    // }
    // if(error && error.response && error.response.status && error.response.status === 500) {
    //   opt.onFailed(error, opt.extra)
    //   return;
    // }

    if (typeof error !== "undefined") {
      if (typeof error.response !== "undefined") {
        if (error.response.status === 401) {
          // need refreshing token
          const res = await refreshToken()
          if (res) request(tempArgs)
          else lib.logout()
          return
        }

        opt.onFailed(error, opt.extra)
        return
        /* === */
        // if (typeof error.response.data !== "undefined") {
        //   if (typeof error.response.data === "object" || opt.extra.isRefreshToken) {
        //     opt.onFailed(error, opt.extra);
        //   } else {
        //     message.error("wrong return from server!");
        //   }
        // }
      } else if(error.toString() === "Cancel"){
        // opt.onFailed({
        //   response: {
        //     data: {
        //       detail: error.message
        //         ? error.message
        //         : "Operation canceled!"
        //     }
        //   }
        // }, opt.extra);
        opt.onCancel(error.message, opt.extra);
      } else {
        //Debug.isDevMode && console.log(error);
        notification.error({
          key: "netError",
          message: "Error!",
          description: "Network connection refused. Please check your connection!",
        });
        opt.onFailed({
          response: { data: { detail: "Connection to Server Failed!" } }
        }, opt.extra);
      }
    }
  })
}
