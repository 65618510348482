import PropTypes from 'prop-types';
import { Card } from 'antd';

import ContentAttendance from 'src/pages/DashboardApp/Attendance/Daily/Content';
import BaseContentLeave from 'src/pages/DashboardApp/Submission/AnnualLeave/Content';
import ContentCalendar from './Calendar';

class SubmissionAnnualLeaveContent extends BaseContentLeave {
  hasListMode = false

  renderContentCalendar() {
    return (
      <ContentCalendar
        userAttendances={this.props.userAttendances}
        date={this.props.date}
        setDate={this.props.setDate}
        userOvertimes={this.props.userOvertimes}
        userActivities={this.props.userActivities}
      />
    )
  }

  // renderContentList() {
  //   return (
  //     <Card title="In Progress">
  //     </Card>
  //   )
  // }
}

SubmissionAnnualLeaveContent.propTypes = {
  ...SubmissionAnnualLeaveContent.propTypes,
}

SubmissionAnnualLeaveContent.defaultProps = {
  ...SubmissionAnnualLeaveContent.defaultProps,
}

export default SubmissionAnnualLeaveContent