/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    name: 'first_name',
    label: 'First Name',
    type: 'input',
    initialValue: '',
    formProps: {
      rules: [
        { required: true, message: 'Please enter your First Name!' },
      ]
    },
  },
  {
    name: 'last_name',
    label: 'Last Name',
    initialValue: '',
    type: 'input',
  },
  {
    name: 'employee_id',
    label: 'Employee ID',
    type: 'input',
    initialValue: '',
  },
  {
    name: 'address1',
    label: 'Address',
    placeholder: 'Address 1',
    type: 'input',
    initialValue: '',
  },
  {
    name: 'address2',
    label: '  ',
    placeholder: 'Address 2',
    type: 'input',
    initialValue: '',
    formProps: { colon: false },
  },
]