import React from 'react'
import { Input, Spin, Tooltip } from 'antd'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highcharts'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import NumberFormat from 'react-number-format'

import BasePoint from './BasePoint'
import { request } from 'src/Utils'
import { CardHeader } from 'src/pages/DashboardApp/Components'
import tmpOpts from './pointOpts'

const options = cloneDeep(tmpOpts)

class Point extends BasePoint {
  constructor(props) {
    super(props)

    Highcharts.setOptions({ lang: {thousandsSep: ','} })

    options.xAxis.categories[2] = 'Target'
    options.xAxis.categories[3] = 'Prediction'
    options.xAxis.categories[4] = 'Estimation'

    this.state = {
      ...this.state,

      totalNow: 0,
      totalLast: 0,
      hlNow: 0,
      hlLast: 0,
    }
  }

  getTitle = (total) => (
    <span>
      Production: {'$ '}
      <NumberFormat
        thousandSeparator
        displayType='text'
        value={Number(total).toFixed(2)}
      />
    </span>
  )

  getMonthLabel = (date, useLabel = false, dateFormat = 'MMMM YYYY') => {
    if (useLabel && moment(date).format('YYYY-MM') === moment().format('YYYY-MM'))
      return 'This Month'

    if (useLabel && moment(date).format('YYYY-MM') === moment().subtract(1, 'month').format('YYYY-MM'))
      return 'Last Month'

    return moment(date).format(dateFormat)
  }

  // Read previous month
  readProductionJobs = (date) => {
    if (this.state.localLoading)
      return

    this.setThisState({ localLoading: true }, () => {
      request({
        urlKey: 'read-productionJobs',
        data: {
          finished_date_after: moment(date).subtract(1, 'month').startOf('month').format(),
          finished_date_before: moment(date).subtract(1, 'month').endOf('month').format(),
        },
        onSuccess: this.readProductionSuccess,
        onFailed: this.readProductionFailed,
        extra: { date },
      })
    })
  }

  readProductionSuccess = (response, extra) => {
    let total = 0, totalHl = 0
    for (let i = 0; i < response.data.length; i += 1) {
      total += Number(response.data[i].qs_value)
      totalHl += Number(response.data[i].highlighted_total_charge)
    }

    const isLastMonth = moment(extra.date).subtract(1, 'month').format('YYYY-MM') === moment().subtract(1, 'month').format('YYYY-MM')
    options.xAxis.categories[0] = this.getMonthLabel(moment(extra.date).subtract(1, 'month'), isLastMonth)
    this.setThisState({
      localLoading: false,
      totalLast: total,
      hlLast: totalHl,
      reRender: Math.random(),
    })
  }

  readProductionFailed = (error) => {
    this.setThisState({ localLoading: false })
  }

  setPrediction = (productionJobs) => {
    const prodDays = []
    const totalValue = productionJobs.reduce((acc, val) => acc + Number(val.qs_value), 0)
    let totalDays = 0, totalJobs = productionJobs.length

    for (const i in productionJobs) {
      if (prodDays.indexOf(productionJobs[i].prod_day) === -1) {
        totalDays += 1
        prodDays.push(productionJobs[i].prod_day)
      }
    }

    this.setY({
      indexData: 3,
      y: totalDays ? Math.round((totalValue / totalDays * this.props.workingDays) * 100) / 100 : 0,
      target: this.props.target,
    })
  }

  // indexData: 0 = Last Month, 1 = This Month, 2 = Target, 3 = Prediction
  setY = ({ indexData, y, hl, target }) => {
    options.series[0].data[indexData].y = y

    if ((hl || hl === 0) && options.series[1].data[indexData]) {
      options.series[1].data[indexData].y = hl
    }

    if (target) {
      options.series[0].data[indexData].extra = `${Math.floor(y / target * 100).toFixed(0)}% -`
      options.series[0].data[indexData].prefixTarget = `<span style=\"color:{point.color}\">\u25CF</span><span> Target : </span>`
      options.series[0].data[indexData].target = target
    } else {
      options.series[0].data[indexData].extra = ''
      options.series[0].data[indexData].prefixTarget = ''
      options.series[0].data[indexData].target = ''
    }
  }

  childComponentWillReceiveProps(nextProps) {
    if (this.props.productionJobs !== nextProps.productionJobs) {
      this.convertData(nextProps.productionJobs)
    }

    if (this.props.prevProductionJobs) {
      if (this.props.prevProductionJobs !== nextProps.prevProductionJobs) {
        this.readProductionSuccess({ data: nextProps.prevProductionJobs }, { date: nextProps.date })
      }
    } else if (this.props.date !== nextProps.date) {
      this.readProductionJobs(nextProps.date)
    }
  }

  didMount() {
    if (this.props.prevProductionJobs) {
      this.readProductionSuccess({ data: this.props.prevProductionJobs }, { date: this.props.date })
    } else {
      this.readProductionJobs(this.props.date)
    }

    if (this.props.productionJobs)
      this.convertData(this.props.productionJobs)
  }

  render() {
    let total = 0, totalHl = 0
    for (let i = 0; i < this.props.productionJobs.length; i += 1) {
      total += Number(this.props.productionJobs[i].qs_value)
      totalHl += Number(this.props.productionJobs[i].highlighted_total_charge)
    }

    this.setY({
      indexData: 0,
      y: this.state.totalLast,
      hl: this.state.hlLast,
      target: this.props.prevTarget,
    })

    this.setY({
      indexData: 1,
      y: Math.round(Number(total) * 100) / 100,
      hl: totalHl,
      target: this.props.target,
    })

    this.setY({ indexData: 2, y: this.props.target })

    let totalAll = 0, totalHlAll = 0
    for (const job of this.props.allProductionJobs) {
      totalAll += Number(job.qs_value)
      totalHlAll += Number(job.highlighted_total_charge)
    }
    this.setY({
      indexData: 4,
      y: Math.round(Number(totalAll) * 100) / 100,
      hl: totalHlAll,
      target: this.props.target,
    })

    options.yAxis.title.text = `Target: ${this.props.target}`

    const isThisMonth = moment(this.props.date).format('YYYY-MM') === moment().format('YYYY-MM')
    options.xAxis.categories[1] = this.getMonthLabel(this.props.date, isThisMonth)

    this.setPrediction(this.props.productionJobs)

    let max = 0
    if (max < this.state.totalLast) max = this.state.totalLast
    if (max < total) max = total
    if (max < this.props.target) max = this.props.target
    if (max < options.series[0].data[3].y) max = options.series[0].data[3].y
    options.yAxis.max = max + 11000

    return (
      <Spin spinning={this.state.localLoading}>
        <CardHeader
          title={this.getTitle(total + totalHl)}
          extra={
            <div title='Target'>
              {this.props.readOnly ? this.props.target :
                <Tooltip title='Target Production Company' trigger={['focus']}>
                <Input
                  size='small'
                  type='number'
                  value={this.state.localTarget}
                  style={{
                    border: 'none',
                    width: '60px',
                    textAlign: 'right',
                    paddingRight: '2px',
                  }}
                  onChange={this.onChangeLocalTarget}
                  onBlur={this.onSaveTarget}
                  onPressEnter={this.onSaveTarget}
                  onKeyDown={this.onKeyDownLocalTarget}
                />
                </Tooltip>
              }
            </div>
          }
        />
        <HighchartsReact
          highcharts={Highcharts}
          options={{ ...options }}
        />
      </Spin>
    )
  }
}

Point.defaultProps = {
  productionJobs: [],
}

export default Point