const options = {
  "chart": {
    "type": "bar",
    "height": 200
  },
  "title": {
    "text": " "
  },
  "xAxis": {
    "categories": [
      "Last Month",
      "This Month",
    ],
    "title": {
      "text": null
    }
  },
  "yAxis": {
    "min": 0,
    "title": {
      // "text": "Min. Level 800",
      "text": " ",
      "align": "high"
    },
    "labels": {
      "overflow": "justify"
    }
  },
  // "tooltip": {
  //   "valueSuffix": " millions"
  // },
  "plotOptions": {
    "bar": {
      "dataLabels": {
        "enabled": true
      }
    }
  },
  "credits": {
    "enabled": false
  },
  "legend": {
    "enabled": false
  },
  "series": [
    {
      "name": "Point",
      "data": [0, 0]
    },
    // {
    //   "name": "Highlight",
    //   "data": [0, 0, 0]
    // },
  ]
}

export default options