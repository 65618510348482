import DashboardProductionDetailFinished from './Finished'

class ProductionDetailInProgress extends DashboardProductionDetailFinished {
  constructor(props) {
    super(props)

    this.title = 'In-Progress'

    const mustFinishColumn = this.columns.find(column => column.dataIndex === 'must_finish_date')
    if (mustFinishColumn)
      mustFinishColumn.defaultSortOrder = 'ascend'
  }
}

ProductionDetailInProgress.propTypes = {
  ...ProductionDetailInProgress.propTypes
}

ProductionDetailInProgress.defaultProps = {
  ...ProductionDetailInProgress.defaultProps,
}

export default ProductionDetailInProgress