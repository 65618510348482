import React from 'react'
import { Avatar, Button, Col, Row } from 'antd'
import { FullscreenOutlined, UserOutlined } from '@ant-design/icons'
import { get } from 'lodash'
import moment from 'moment'
import Lightbox from 'react-image-lightbox'

import { BaseModal } from 'src/components'
import { lib } from 'src/Utils'

class DetailScreenshot extends BaseModal {
  constructor(props) {
    super(props)

    this.currentProfile = lib.ls.getCurrentUser()

    this.staticModalProps = {
      closable: false,
      width: 1024,
      bodyStyle: { padding: 0 },
      footer: (
        <Button block type='primary' onClick={this.hide}>
          Close
        </Button>
      )
    }

    this.state = {
      ...this.state,
      currentIndex: -1,
    }
  }

  showFull = (state) => {
    const currentIndex = state.screenshots.findIndex(ss => ss.alias === state.screenshot.alias)
    this.show({ ...state, currentIndex })
  }

  onFullScreen = () => {
    this.setThisState({
      currentIndex: this.state.screenshots.findIndex(ss => ss.alias === this.state.screenshot.alias)
    })
  }

  renderContent = () => {
    const { currentIndex, screenshots } = this.state

    return this.state.screenshot && (
      <div>
        <div className='ss-container-dialog' style={{ position: 'relative', padding: '0 0' }}>
          <FullscreenOutlined
            className='btn-fullscreen'
            onClick={this.onFullScreen}
          />
          <img
            src={this.state.screenshot.image}
            alt='screenshot'
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ padding: '24px' }}>
          <Row justify='space-between' align='middle' gutter={[12, 12]}>
            <Col>
              <Row>
                <Col style={{ marginRight: '12px' }}>
                  <Avatar
                    size={48}
                    src={this.currentProfile.photo}
                    icon={<UserOutlined />}
                  />
                </Col>
                <Col style={{ maxWidth: 'calc(100% - 48px - 12px)' }}>
                  <div className='text-truncate' style={{ fontSize: '16px', fontWeight: 500 }}>
                    {lib.ls.getFullName()}
                  </div>
                  <div className='text-truncate'>
                    {moment(this.state.screenshot.actual_created).format('llll')}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col style={{ fontSize: '24px' }}>
              {this.state.screenshot.task.name}
            </Col>
          </Row>
        </div>
        {currentIndex !== -1 && (
          <Lightbox
            imageTitle={get(screenshots[currentIndex].activity, ['task', 'name'], '') + ' - ' + moment(screenshots[currentIndex].actual_created).format('dddd, DD MMMM YYYY, hh:mm A')}
            mainSrc={screenshots[currentIndex].image}
            nextSrc={screenshots[(currentIndex + 1) % screenshots.length].image}
            prevSrc={screenshots[(currentIndex + screenshots.length - 1) % screenshots.length].image}
            onCloseRequest={() => this.setThisState({ currentIndex: -1 })}
            onMovePrevRequest={() => {
              this.setThisState(prevState => ({ currentIndex: (prevState.currentIndex + screenshots.length - 1) % screenshots.length }))
            }}
            onMoveNextRequest={() => {
              this.setThisState({ currentIndex: (currentIndex + 1) % screenshots.length })
            }}
          />
        )}
      </div>
    )
  }

  render() {
    const { currentIndex, screenshots } = this.state

    return (
      <div>
        {currentIndex !== -1 && (
          <Lightbox
            imageTitle={get(screenshots[currentIndex].activity, ['task', 'name'], '') + ' - ' + moment(screenshots[currentIndex].actual_created).format('dddd, DD MMMM YYYY, hh:mm A')}
            mainSrc={screenshots[currentIndex].image}
            nextSrc={screenshots[(currentIndex + 1) % screenshots.length].image}
            prevSrc={screenshots[(currentIndex + screenshots.length - 1) % screenshots.length].image}
            onCloseRequest={() => this.setThisState({ currentIndex: -1 })}
            onMovePrevRequest={() => {
              this.setThisState(prevState => ({ currentIndex: (prevState.currentIndex + screenshots.length - 1) % screenshots.length }))
            }}
            onMoveNextRequest={() => {
              this.setThisState({ currentIndex: (currentIndex + 1) % screenshots.length })
            }}
          />
        )}
      </div>
    )
  }
}

export default DetailScreenshot