import React from 'react'
import { Tag } from 'antd'
import { CloudUploadOutlined, CoffeeOutlined } from '@ant-design/icons'
import moment from 'moment'

function getIsActive(activities) {
  const maxRange = 5 // minutes
  
  const lastActivity = activities.slice(-1)[0]
  const finishTime = lastActivity ? lastActivity.finish_time : null
  const rangeMinutes = finishTime ? moment.duration(moment().diff(moment(finishTime))).asMinutes() : -1
  const isActive = (rangeMinutes >= 0 && rangeMinutes <= maxRange)

  return isActive
}

function getStatusComp(isActive, useLabel = true) {
  return isActive ? (
    <Tag color='success' icon={<CloudUploadOutlined />}>
      {useLabel && 'Online'}
    </Tag>
  ) : (
    <Tag icon={<CoffeeOutlined />}>
      {useLabel && 'Offline'}
    </Tag>
  )
}

function getTaskActive(activities) {
  if (!getIsActive(activities))
    return null

  const lastActivity = activities.slice(-1)[0]
  return lastActivity
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getIsActive,
  getStatusComp,
  getTaskActive,
}