import React from 'react'
import { Avatar, Button, Card, Col, Dropdown, Menu, Row, Tag } from 'antd'
import { MoreOutlined, RiseOutlined, UserOutlined } from '@ant-design/icons'

import { BaseComponent, PointBonus } from 'src/components'
import { lib } from 'src/Utils'
import NumberFormat from 'react-number-format'

class CardMember extends BaseComponent {
  getMoreMenu = () => {
    return (
      <Menu>
        <Menu.Item key='view-dashboard' onClick={this.viewDashboard}>
          View Dashboard
          <RiseOutlined style={{ marginLeft: '12px' }} />
        </Menu.Item>
      </Menu>
    )
  }


  viewDashboard = () => {
    this.props.viewDashboard(this.props.user.id)
  }

  render() {
    const { isMySelf, isManager, user, userType, productionSummary } = this.props

    return (
      <Card className='app-card' bodyStyle={{ padding: '6px 6px 12px 6px' }} style={{ height: '100%', border: isMySelf && '1px solid #1890ff' }}>
        <div style={{ textAlign: 'right', marginBottom: '-12px', height: '30px' }}>
          {!isManager && (
            <Dropdown trigger={['click']} placement='bottomRight' overlay={this.getMoreMenu()}>
              <MoreOutlined
                className='subtitle btn-more-icon'
                style={{ fontSize: '18px' }}
              />
            </Dropdown>
          )}
        </div>
        <Row justify='center' align='middle' gutter={[6, 6]} style={{ flexDirection: 'column' }}>
          <Col>
            <Avatar
              src={user.photo}
              icon={<UserOutlined />}
              size={64}
            />
          </Col>
          <Col className='text-truncate' style={{ fontWeight: 500 }}>
            {lib.getFullName(user)} {isMySelf && <span style={{ color: '#1890ff' }}>(you)</span>}
          </Col>
          <Col>
            {isManager ? (
              <Tag color='blue' style={{ marginRight: 0 }}>
                Manager
              </Tag>
            ) : (
              <Tag color='green' style={{ marginRight: 0 }}>
                Member
              </Tag>
            )}
          </Col>
        </Row>
        {(!isManager || isMySelf) && productionSummary && (
          <div>
            <Row style={{ marginTop: '12px', backgroundColor: '#f0f2f5', padding: '6px 0' }}>
              <Col span={12} className='subtitle' style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '18px', fontWeight: 500 }}>
                  {productionSummary.summary.totalJob}
                </div>
                <div>
                  Jobs
                </div>
              </Col>
              <Col span={12} className='subtitle' style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '18px', fontWeight: 500 }}>
                  {/* {productionSummary.summary.totalArea} */}
                  <NumberFormat
                    thousandSeparator
                    displayType='text'
                    value={productionSummary.summary.totalArea}
                  />
                </div>
                <div>
                  Area
                </div>
              </Col>
              {/* <Col span={8} className='subtitle' style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '18px', fontWeight: 500 }}>
                  <PointBonus
                    prodMonth={this.props.selectedDate}
                    point={productionSummary.summary.totalPoint}
                    user={{ ...userType, point_types: userType.point_types.map(pointType => ({ code_display: pointType.code_display })) }}
                    effectiveExchange={this.props.effectiveExchange}
                    typeExchangeFactor={productionSummary.summary.summaryExchangeFactor}
                  />
                </div>
                <div>
                  Point
                </div>
              </Col> */}
            </Row>
          </div>
        )}
        {(!isManager || isMySelf) && (
          <div style={{ marginTop: '12px', color: '#999' }}>
            <Button block size='small' onClick={this.viewDashboard}>
              View Dashboard
            </Button>
          </div>
        )}
      </Card>
    )
  }
}

export default CardMember