const options = {
  "chart": {
    "type": "bar",
    "height": 200
  },
  "title": {
    "text": " "
  },
  "xAxis": {
    "categories": [
      "Last Month",
      "This Month",
    ],
    "title": {
      "text": null
    }
  },
  "yAxis": {
    "min": 0,
    max: 35000,
    "title": {
      // "text": "Min. Level 800",
      "text": "Target: 0",
      "align": "high"
    },
    "labels": {
      "overflow": "justify"
    }
  },
  "tooltip": {
    // "valueSuffix": " millions",
    "pointFormat": "<span style=\"color:{point.color}\">\u25CF</span><span> {series.name} : {point.extra} </span><b>${point.y:,.2f}</b><br><span>{point.prefixTarget}</span><b>{point.target:,.2f}</b>"
  },
  "plotOptions": {
    "bar": {
      "dataLabels": {
        "enabled": true,
        // "inside": false,
        // crop: false,
        // overflow: 'allow',
        // y: -15,
        // x: 150,
        // verticalAlign: 'top',
        // align: 'right',
        // rotation: -90,
        // allowOverlap: true,
        // padding: 15,
        // position: 'center',
        // useHTML: true,
        // style: {
        //   top: '15px',
        //   // position: 'absolute',
        //   // fontWeight: 700,
        // },

        "format": "{point.extra} ${point.y:,.2f}"
      }
    }
  },
  "credits": {
    "enabled": false
  },
  "legend": {
    "enabled": false
  },
  "series": [
    {
      "name": "Amount",
      "color": "#7cb5ec",
      "dataLabels": {
        x: 25000
      },
      "data": [
        { y: 0, extra: '' },
        { y: 0, extra: '' },
        { y: 0 },
        { y: 0, extra: '' },
        { y: 0, extra: '' },
      ]
    },
    {
      "name": "Highlight",
      "color": "#1890ff",
      "data": [
        { y: 0 },
        { y: 0 },
        {  },
        {  },
        { y: 0 },
      ]
    },
  ]
}

export default options