import React from 'react'

import BaseComponent from './BaseComponent'

class BarAnim extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      width: 0,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.width !== nextProps.width) {
      this.setThisState({ width: nextProps.width })
    }
  }

  componentDidMount() {
    this.setMounted()

    setTimeout(() => {
      this.setThisState({ width: this.props.width })
    }, 100)
  }

  render() {
    return (
      <div className={this.props.className}>
        <div className='bar-inside-worked' style={{ width: this.state.width }}/>
        {this.props.value}
      </div>
    )
  }
}

BarAnim.defaultProps = {
  className: 'bar-outside',
  value: null
}

export default BarAnim