import PropTypes from 'prop-types';
import { Button, Card, Col, DatePicker, Row, Segmented, Select, Spin, Tabs } from 'antd';
import { AppstoreOutlined, ArrowLeftOutlined, BarsOutlined, CalendarOutlined, CaretLeftOutlined, CaretRightOutlined, CloseOutlined, MinusSquareOutlined, PlusSquareOutlined, ReloadOutlined, RobotOutlined, SmileOutlined } from '@ant-design/icons';
import { orderBy } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import AllSubmissionsLeave from 'src/pages/DashboardApp/Submission/AnnualLeave/AllSubmissions';
// import { BaseComponent, BaseList } from 'src/components';
import ListAll from './All';
// import ListWaiting from './Waiting';
// import ListApproved from './Approved';
// import ListRejected from './Rejected';
// import helper from '../../helper';

class AllSubmissions extends AllSubmissionsLeave {
  constructor(props) {
    super(props)

    this.title = 'Approval Overtime'
    this.urlKey = 'overtime-overtimes'
    this.urlKeyConfirm = 'overtime-overtimes-detail-confirm'
    window._uuidv4 = uuidv4

    this.ordering = '-date'
    this.keysQueryParam.push('ordering')

    // this.status = ''
    // this.statuses = [
    //   { key: '', label: 'All' },
    //   { key: 'approved', label: 'Approved' },
    //   { key: 'rejected', label: 'Rejected' },
    //   { key: 'pending', label: 'Pending' },
    //   { key: 'cancelled', label: 'Cancelled' },
    // ]

    // this.keysQueryParam.push('status')

    this.groupOpts = [
      // sementara group by user di disable karena ada issue load more
      // {
      //   label: 'User',
      //   value: 'user',
      //   icon: <RobotOutlined />,
      // },
      {
        label: 'Date',
        value: 'date',
        icon: <CalendarOutlined />,
      },
    ]

    // test
    // this.limit = 5
    // this.status = ''

    this.state = {
      ...this.state,
      groupBy: 'date',
      allExpanded: true,
      selectedDate: moment().format(),
    }
  }

  // convertResponseData = () => {
  //   // return orderBy(helper.example.userLeaves, 'date')
  //   return []
  // }

  // prevMonth = () => {
  //   this.onChangeDate(moment(this.state.selectedDate).subtract(1, 'month').format())
  // }
  
  // nextMonth = () => {
  //   this.onChangeDate(moment(this.state.selectedDate).add(1, 'month').format())
  // }

  // onChangeDate = (date) => {
  //   this.setState({ selectedDate: moment(date).format() })
  // }

  // onChangeGroupBy = (groupBy) => {
  //   this.setState({ groupBy })
  // }

  onAccept = (userLeave) => {
    this.setState({ isLoading: true }, () => {
      setTimeout(() => {
        this.patchList([{ ...userLeave, status: 'approved' }], 'alias')
        this.setState({ isLoading: false })
      }, 1000)
    })
  }

  onReject = (userLeave) => {
    this.setState({ isLoading: true }, () => {
      setTimeout(() => {
        this.patchList([{ ...userLeave, status: 'rejected' }], 'alias')
        this.setState({ isLoading: false })
      }, 1000)
    })
  }

  renderList = () => {
    return (
      <ListAll
        list={this.__list}
        isLoading={this.state.isLoading}
        groupBy={this.state.groupBy}
        allExpanded={this.state.allExpanded}
        // onAccept={this.onAccept}
        // onReject={this.onReject}
        onAccept={this.approve}
        onReject={this.reject}
        // showDeleteActivity={this.props.showDeleteActivity}
      />
    )
  }

  render_zzzzzzzzzzzzzzzzzzzzzzzzz() {
    return (
      <div style={{ padding: '24px' }}>
        {/* ---------------- Header ---------------- */}
        <div>
          <Row justify='space-between' gutter={[12, 12]} style={{ marginBottom: '12px' }}>
            <Col>
              <Row align='middle'>
                <Col>
                  <div className='ant-page-header-back'>
                    <div className='ant-page-header-back-button' onClick={this.props.onClose} title='Go back'>
                      <ArrowLeftOutlined />
                    </div>
                  </div>
                </Col>
                <Col>
                  <h1 style={{ marginBottom: 0 }}>
                    Approval Staff Overtime
                  </h1>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row align='middle' gutter={[12, 12]}>
                <Col>
                  <Button icon={<ReloadOutlined />} onClick={this.forceRead}>
                    Reload
                  </Button>
                </Col>
                <Col>
                  <Button icon={this.state.allExpanded ? <MinusSquareOutlined /> : <PlusSquareOutlined />} onClick={() => this.setState({ allExpanded: !this.state.allExpanded })}>
                    {this.state.allExpanded ? 'Collapse' : 'Expand'}
                  </Button>
                </Col>
                <Col>
                  <Segmented
                    value={this.state.groupBy}
                    options={[
                      {
                        label: 'User',
                        value: 'user',
                        icon: <RobotOutlined />,
                      },
                      {
                        label: 'Date',
                        value: 'date',
                        icon: <CalendarOutlined />,
                      },
                    ]}
                    onChange={this.onChangeGroupBy}
                  />
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <Button icon={<CaretLeftOutlined />} onClick={this.prevMonth} />
                    </Col>
                    <Col style={{ margin: '0 -1px', flex: 1 }}>
                      <DatePicker
                        allowClear={false}
                        picker='month'
                        value={moment(this.state.selectedDate)}
                        onChange={this.onChangeDate}
                      />
                    </Col>
                    <Col>
                      <Button icon={<CaretRightOutlined />} onClick={this.nextMonth} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        {/* ---------------- Content ---------------- */}
        {this.state.errorResponse ? this.getErrorComp() : (
          <div>
            <Tabs type='card' className='tabs-pnt' defaultActiveKey='review'>
              <Tabs.TabPane key='all' tab='All'>
                <Card className={classNames('card-all-submission', {mobile: this.mutableScreen.isMobile})}>
                  <Spin spinning={this.state.isLoading}>
                    <ListAll
                      list={this.__list}
                      isLoading={this.state.isLoading}
                      groupBy={this.state.groupBy}
                      allExpanded={this.state.allExpanded}
                      onAccept={this.onAccept}
                      onReject={this.onReject}
                      showDeleteActivity={this.props.showDeleteActivity}
                    />
                  </Spin>
                </Card>
              </Tabs.TabPane>
              {/* <Tabs.TabPane key='waiting' tab='Waiting'>
                <Card className={classNames('card-all-submission', {mobile: this.mutableScreen.isMobile})}>
                  <Spin spinning={this.state.isLoading}>
                    <ListWaiting
                      list={this.__list}
                      isLoading={this.state.isLoading}
                      groupBy={this.state.groupBy}
                      allExpanded={this.state.allExpanded}
                      onAccept={this.onAccept}
                      onReject={this.onReject}
                      showDeleteActivity={this.props.showDeleteActivity}
                    />
                  </Spin>
                </Card>
              </Tabs.TabPane>
              <Tabs.TabPane key='approved' tab='Approved'>
                <Card className={classNames('card-all-submission', {mobile: this.mutableScreen.isMobile})}>
                  <Spin spinning={this.state.isLoading}>
                    <ListApproved
                      list={this.__list}
                      isLoading={this.state.isLoading}
                      groupBy={this.state.groupBy}
                      allExpanded={this.state.allExpanded}
                      showDeleteActivity={this.props.showDeleteActivity}
                    />
                  </Spin>
                </Card>
              </Tabs.TabPane>
              <Tabs.TabPane key='rejected' tab='Rejected'>
                <Card className={classNames('card-all-submission', {mobile: this.mutableScreen.isMobile})}>
                  <Spin spinning={this.state.isLoading}>
                    <ListRejected
                      list={this.__list}
                      isLoading={this.state.isLoading}
                      groupBy={this.state.groupBy}
                      allExpanded={this.state.allExpanded}
                      showDeleteActivity={this.props.showDeleteActivity}
                    />
                  </Spin>
                </Card>
              </Tabs.TabPane> */}
            </Tabs>
          </div>
        )}
      </div>
    )
  }
}

AllSubmissions.propTypes = {
  ...AllSubmissions.propTypes,
  onClose: PropTypes.func,
}

AllSubmissions.defaultProps = {
  ...AllSubmissions.defaultProps,
  onClose: () => {},
}

export default AllSubmissions