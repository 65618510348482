import React from 'react'
import { Card, Col, Row } from 'antd'
import { UnlockOutlined } from '@ant-design/icons'

import { BaseComponent } from 'src/components'

class Roles extends BaseComponent {
  render() {
    return (
      <div>
        <Row>
          <div style={{ fontSize: '21px', fontWeight: 500 }}>
            Roles
          </div>
        </Row>
        <div style={{ marginTop: '12px' }}>
          <Row type='flex' gutter={[12, 12]} style={{ marginBottom: '24px' }}>
            {this.props.roles.map(role => (
              <Col key={role.alias} xs={24} sm={24} xl={12}>
                <Card
                  bodyStyle={{
                    backgroundColor: '#1890ff',
                    color: 'white',
                    cursor: 'not-allowed',
                    padding: '12px',
                  }}
                >
                  <Row align='middle' gutter={[12, 6]}>
                    <Col>
                      <UnlockOutlined style={{ fontSize: '21px' }} />
                    </Col>
                    <Col className='text-truncate' style={{ fontSize: '18px', fontWeight: 500, flex: 1 }}>
                      {role.name}
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    )
  }
}

export default Roles