import React from 'react'
import { Col, Row } from 'antd'

import { BaseComponent } from 'src/components'
import SelectableCardUser from './SelectableCardUser'

class ContentUsers extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      objSelectedUser: {}, // { [alias]: Boolean }
    }
  }

  // Select or deselect
  onSelectUser = (user) => {
    this.setThisState({
      objSelectedUser: {
        ...(this.props.multiple ? this.state.objSelectedUser : {}),
        [user.alias]: !this.state.objSelectedUser[user.alias],
      }
    })
  }

  getValues = () => {
    const values = []

    for (const userAlias in this.state.objSelectedUser) {
      if (this.state.objSelectedUser[userAlias]) {
        values.push(userAlias)
      }
    }

    return values
  }

  render() {
    return (
      <div>
        <Row gutter={[12, 12]}>
          {this.props.users.map(user => (
            <Col key={user.alias} xs={24} sm={24} md={24} xxl={8}>
              <SelectableCardUser
                user={user}
                isManager={this.props.isManager}
                selected={!!this.state.objSelectedUser[user.alias]}
                onClickUser={this.onSelectUser}
              />
            </Col>
          ))}
        </Row>
      </div>
    )
  }
}

ContentUsers.defaultProps = {
  multiple: true,
}

export default ContentUsers