import UserSettings from './UserSettings'
import CompanySettings from './CompanySettings'
import ProjectTasks from './ProjectTasks'
import ManageUsers from './ManageUsers'
import ManageTeams from './ManageTeams'

import { permissions } from 'src/Utils'

const routes = [
  {
    path: '/projects-tasks/',
    component: ProjectTasks,
    name: 'Projects & Permanent Tasks',
  },
  {
    path: '/user-settings/',
    component: UserSettings,
    name: 'User Settings',
  },
  {
    path: '/company-settings/',
    component: CompanySettings,
    name: 'Company Settings',
    auth: user => user.is_owner,
    redirect: '/',
  },
  {
    path: '/manage-users/',
    component: ManageUsers,
    auth: user => user.is_owner,
    redirect: '/',
    name: 'Manage Users',
  },
  {
    path: '/manage-teams/',
    component: ManageTeams,
    auth: user => permissions.canManageTeam(user),
    redirect: '/',
    name: 'Manage Teams',
  },
]

export default routes