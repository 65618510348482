import React from 'react'
import { Avatar, Button, Card, Col, Empty, Popover, Row, Spin } from 'antd'
import { ArrowDownOutlined, EllipsisOutlined, ReloadOutlined, UserOutlined } from '@ant-design/icons'
import { get } from 'lodash';

import { BaseList } from 'src/components'
import { lib } from 'src/Utils'
import PopoverContentUser from 'src/pages/DashboardApp/ManageTeams/PopoverContentUser'
// import DetailTeam from './DetailTeam'
import DetailTeam from './DetailTeam/ListVersion'

const MAX_USER_ICON = 5

class ManageTeams extends BaseList {
  constructor(props) {
    super(props)

    this.urlKey = 'read-userTeams'
    this.limit = 10

    this.myProfile = lib.ls.getCurrentUser()
  }

  beforeRead = (keyLoading) => {
    if (keyLoading === this.keyIsLoading) {
      this.page = 1
    }

    return false
  }

  convertResponseData = (responseData) => {
    for (const i in responseData.results) {
      responseData.results[i].description = `1 Manager, ${responseData.results[i].users.length} Members`
      responseData.results[i].allUsers = [
        { ...responseData.results[i].manager, isManager: true },
        ...responseData.results[i].users,
      ]
    }

    return responseData
  }

  onCloseDetailTeam = () => {
    this.onSelectTeam(null)
  }

  onSelectTeam = (selectedTeam) => {
    // Why using push? supaya jika ingin kembali ke list team, dapat dengan menggunakan fungsi back browser
    this.props.history.push(
      '/team/' +
      lib.getStrParam({ alias: selectedTeam && selectedTeam.alias })
    )
  }

  render() {
    const selectedTeamAlias = lib.getObjParam(this.props.location.search).alias

    let selectedTeam = null
    if (selectedTeamAlias)
      selectedTeam = this.__list.find(team => team.alias === selectedTeamAlias)

    return (
      <div style={{ padding: '24px' }}>
        {selectedTeam ? (
          <DetailTeam
            team={selectedTeam}
            onClose={this.onCloseDetailTeam}
            history={this.props.history}
            location={this.props.location}
          />
        ) : (
          <div>
            <Row justify='space-between' style={{ marginBottom: '24px' }}>
              <Col>
                <h1 style={{ marginBottom: 0 }}>
                  My Team
                </h1>
              </Col>
              <Col>
                <Button
                  icon={<ReloadOutlined />}
                  loading={this.state[this.keyIsLoading]}
                  onClick={this.forceRead}
                >
                  Refresh
                </Button>
              </Col>
            </Row>
            {this.state.errorResponse ? this.getErrorComp() : (
              <Spin spinning={this.state[this.keyIsLoading]}>
                <Row gutter={[12, 24]} style={{ flexDirection: 'column' }}>
                  {this.__list.length > 0 ? this.__list.map(team => (
                    <Col key={team.alias}>
                      <Card className='app-card' style={{ cursor: 'pointer', border: get(team.manager, 'email') === this.myProfile.email ? '1px solid #1890ff' : '' }} onClick={() => this.onSelectTeam(team)}>
                        <Row justify='space-between' gutter={[12, 12]}>
                          <Col>
                            <div>
                              <b>{team.name}</b>
                            </div>
                            <div className='subtitle'>
                              {team.description}
                            </div>
                          </Col>
                          <Col>
                            <Row>
                              {!this.mutableScreen.isMobile && (
                                <Col style={{ marginRight: '32px' }}>
                                  <Row>
                                    {team.allUsers.filter((_, i) => i < MAX_USER_ICON).map(user => (
                                      <Col key={user.alias} style={{ width: '24px' }}>
                                        <Popover
                                          overlayClassName='ant-popover-profile'
                                          placement='left'
                                          mouseLeaveDelay={0}
                                          content={<PopoverContentUser user={user} />}
                                        >
                                          <Avatar
                                            size={36}
                                            src={user.photo}
                                            icon={<UserOutlined />}
                                            className='list-user-icon'
                                          />
                                        </Popover>
                                      </Col>
                                    ))}
                                    {team.allUsers.length > MAX_USER_ICON && (
                                      <Col style={{ width: '24px' }}>
                                        <EllipsisOutlined
                                          className='subtitle'
                                          title={`${team.allUsers.length - MAX_USER_ICON} more members`}
                                          style={{
                                            fontSize: '24px',
                                            padding: '6px',
                                            marginLeft: '8px',
                                            opacity: 0.3,
                                          }}
                                        />
                                      </Col>
                                    )}
                                  </Row>
                                </Col>
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  )) : (
                    <Card>
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description='No Team'
                      />
                    </Card>
                  )}
                  {this.next && (
                    <Col style={{ textAlign: 'center' }}>
                      <Button loading={this.state[this.keyIsLoadMore]} icon={<ArrowDownOutlined />} onClick={this.loadMore}>
                        Load More
                      </Button>
                    </Col>
                  )}
                </Row>
              </Spin>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default ManageTeams