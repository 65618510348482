import PropTypes from 'prop-types';
import { Drawer } from 'antd';

import BaseDialog from './';

class BaseDrawer extends BaseDialog {
  render() {
    return (
      <Drawer
        visible={this.state.visible}
        title={this.title}
        contentWrapperStyle={{ maxWidth: '100%' }}
        onClose={this.hide}
        {...this.staticDialogProps}
        {...this.getDialogProps()}
      >
        {this.renderContent()}
      </Drawer>
    )
  }
}

BaseDrawer.propTypes = {
  ...BaseDrawer.propTypes
}

BaseDrawer.defaultProps = {
  ...BaseDrawer.defaultProps,
}

export default BaseDrawer