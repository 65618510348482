import React from 'react'
import { Button, message } from 'antd'
import yaml from 'yaml'

import { BaseComponent } from 'src/components'
import { requestHelper } from 'src/Utils'
import appConfig from 'src/config/app'

class BaseButton extends BaseComponent {
  constructor(props) {
    super(props)

    this.ymlName = '' // required

    const self = this
    Object.defineProperties(this, {
      __version: {
        get() {
          return self.yml ? `v${self.yml.version}` : ''
        }
      },
      __path: {
        get() {
          return self.getPath(self.yml)
        }
      },
    })

    this.state = {
      isLoading: false,
    }

    this.renderText = this.renderText.bind(this)
  }

  getButtonProps = () => null
  getPath = (yml) => yml.path

  readYaml = () => {
    this.setThisState({ isLoading: true }, () => {
      requestHelper({
        config: {
          url: `${appConfig.url.desktop}/${this.ymlName}`,
        },
        clearHeaders: true,
        onSuccess: this.readYamlSuccess,
        onFailed: this.readYamlFailed,
      })
    })
  }

  readYamlSuccess = (response) => {
    this.setYml(yaml.parse(response.data))
    this.setThisState({ isLoading: false })
  }

  setYml = (yml) => {
    this.yml = yml
  }

  readYamlFailed = (error) => {
    this.setThisState({ isLoading: false }, () => {
      // setTimeout(this.readYaml, 5000)
    })
  }

  beforeDownload = () => {
    if (!this.__version)
      this.readYaml()
    else
      this.onDownload()
  }

  onDownload = () => {
    if (this.__path)
      window.open(`${appConfig.url.desktop}/${encodeURIComponent(this.__path)}`)
    else
      message.info('Cannot download, please ask the application to management')
  }

  didMount() {
    this.readYaml()
  }

  renderText() {}

  render() {
    return (
      <Button
        block
        type='primary'
        loading={this.state.isLoading}
        onClick={this.beforeDownload}
        style={{ minWidth: '48px' }}
        {...this.getButtonProps()}
      >
        {this.__version
          ? this.renderText()
          : 'Preparing error, click to reload...'}
      </Button>
    )
  }
}

export default BaseButton