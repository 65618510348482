import React from 'react'
import { Avatar, Button, Card, Col, Form, Input, Row, Spin, Upload } from 'antd'
import { BlockOutlined, CameraFilled, SaveOutlined, UserOutlined } from '@ant-design/icons'
import { get } from 'lodash'

import { BaseComponent } from 'src/components'
import { lib, request } from 'src/Utils'
import CompanyPermissions from './Permissions'

class CompanySettings extends BaseComponent {
  constructor(props) {
    super(props)

    this.myProfile = lib.ls.getCurrentUser()
    this.formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        lg: { span: 24 },
        xl: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        lg: { span: 24 },
        xl: { span: 12 },
      },
    }

    const self = this
    Object.defineProperties(this, {
      __company: {
        get() {
          return self.myProfile.company
        }
      }
    })

    this.state = {
      isLoading: false,
      thumbnail: this.__company.photo,
    }
  }

  onRefForm = (ref) => {
    this._form = ref
  }

  onRefPermissions = (ref) => {
    this._permissions = ref
  }

  getInitialDataPhoto = () => {
    if (!this.__company.photo) return undefined

    return [{
      uid: new Date().getTime(),
      name: this.__company.photo,
      status: 'done',
      url: this.__company.photo,
    }]
  }

  onLoadReader = () => {
    this.setThisState({ thumbnail: get(this.reader, 'result') })
  }

  onChangeThumbnail = (e) => {
    this.reader = new FileReader()
    this.reader.onloadend = this.onLoadReader
    const file = get(e.fileList[e.fileList.length - 1], 'originFileObj')
    if (file) this.reader.readAsDataURL(file)
    else {
      this.reader = null
      this.onLoadReader()
    }

    return e && e.fileList
  }

  onSave = () => {
    this._permissions.getFieldsValue().then(modules => {
      this.setThisState({ isLoading: true }, () => {
        request({
          method: 'put',
          urlKey: 'change-companies',
          args: [this.myProfile.company.alias],
          data: { modules },
          onSuccess: this.saveSuccess,
          onFailed: this.saveFailed,
        })
      })
    })

    // this._form.validateFields().then(values => {
    //   this.setThisState({ isLoading: true }, () => {
    //     const data = new FormData()
    //     data.append('name', values.name)
    //     if (get(values.thumbnail, [0, 'originFileObj']))
    //       data.append('photo', values.thumbnail[0].originFileObj)

    //     request({
    //       method: 'put',
    //       // urlKey: 'change-companyUsers',
    //       data,
    //       onSuccess: this.saveSuccess,
    //       onFailed: this.saveFailed,
    //     })
    //   })
    // })
  }

  saveSuccess = (response) => {
    this.setThisState({ isLoading: false })
  }

  saveFailed = (error) => {
    this.setThisState({ isLoading: false })
  }

  render() {
    return (
      <div style={{ margin: '24px' }}>
        <Row justify='space-between' gutter={[12, 12]} style={{ marginBottom: '12px' }}>
          <Col>
            <h1 style={{ marginBottom: 0 }}>
              Company Settings
            </h1>
          </Col>
          <Col>
            <Row align='middle' gutter={[6, 6]}>
              <Col>
                <Button type='primary' loading={this.state.isLoading} icon={<SaveOutlined />} onClick={this.onSave}>
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col xs={24} sm={24} xl={12}>
            <Card className='app-card' style={{ height: '100%' }}>
              <div>
                <Form
                  ref={this.onRefForm}
                  {...this.formItemLayout}
                >
                  <Row justify='center' style={{ marginBottom: '24px', padding: '12px' }}>
                    <Col style={{ position: 'relative' }}>
                      <Avatar
                        icon={<BlockOutlined />}
                        src={this.state.thumbnail}
                        size={154}
                        style={{ border: '1px solid #e8e8e8' }}
                      />
                      {/* <Form.Item
                        name='thumbnail'
                        className='form-item-photo'
                        valuePropName='fileList'
                        getValueFromEvent={this.onChangeThumbnail}
                        initialValue={this.getInitialDataPhoto()}
                      >
                        <Upload beforeUpload={() => false} maxCount={1} itemRender={() => null} accept='image/*'>
                          <Row justify='center' align='middle' className='btn-take-photo'>
                            <CameraFilled />
                          </Row>
                        </Upload>
                      </Form.Item> */}
                    </Col>
                    <Col span={24}>
                      <Row justify='center' style={{ fontSize: '18px', fontWeight: 500, fontStyle: 'italic', marginTop: '12px' }}>
                        {this.__company.name}
                      </Row>
                    </Col>
                  </Row>
                  {/* <Form.Item
                    name='name'
                    label='Name'
                    initialValue={this.__company.name}
                    rules={[
                      { required: true, message: 'Please enter Company Name!' }
                    ]}
                  >
                    <Input placeholder='Name' />
                  </Form.Item> */}
                </Form>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} xl={12}>
            <Card className='app-card' style={{ height: '100%' }}>
              <CompanyPermissions
                ref={this.onRefPermissions}
              />
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default CompanySettings