import { ApartmentOutlined, CalendarOutlined, CameraOutlined, CarryOutOutlined, ClusterOutlined, DashboardOutlined, DesktopOutlined, EditOutlined, FieldTimeOutlined, FileOutlined, FileProtectOutlined, ScheduleOutlined, TeamOutlined } from '@ant-design/icons'

import Dashboard from './Dashboard'
import DashboardCompany from './DashboardCompany'
import DashboardManager from './DashboardManager'
import TeamProduction from './TeamProduction'
import MyTeam from './MyTeam'
import EditTime from './EditTime'
import Screenshots from './Screenshots'
import { AttendancePhotos, AttendanceDaily } from './Attendance'
import { SubmissionAnnualLeave, SubmissionOvertime, ReportOvertimeTimesheet, ReportYearlyStaffLeave } from './Submission'
import { ActivityNotesReport, AppUsageReport, MonthlyProductionReport, ProductionDetailReport, TimelineReport, MonthlyTimesheet, TimeUseReport, WorkingSheetReport, YearlyReport } from './Reports'
import Downloads from './Downloads'
import { permissions } from 'src/Utils'

const routes = [
  {
    // route
    exact: true,
    path: '/',
    component: Dashboard,
    // menu
    name: 'Dashboard',
    icon: <DashboardOutlined />
  },
  {
    path: '/manager/',
    component: DashboardManager,
    name: 'Manager',
    icon: <ClusterOutlined />,
    auth: user => permissions.hasManagerProd(user),
    redirect: '/',
  },
  {
    path: '/manager-team/',
    component: TeamProduction,
    name: 'Manager Team',
    icon: <ClusterOutlined />,
    auth: user => permissions.canViewProdDashboard(user),
    // auth: user => user.email === 'dev@onehub.net',
    redirect: '/',
  },
  {
    path: '/company/',
    component: DashboardCompany,
    name: 'My Company',
    icon: <ApartmentOutlined />,
  },
  {
    path: '/team/',
    component: MyTeam,
    name: 'My Team',
    icon: <TeamOutlined />,
  },
  {
    name: 'HRD',
    icon: <CalendarOutlined />,
    redirect: '/',
    menu: [
      {
        path: '/submission/overtime/',
        component: SubmissionOvertime,
        name: 'Apply Overtime',
        icon: <FieldTimeOutlined />,
        auth: user => permissions.hasOvertimeModule(user),
      },
      {
        path: '/report/overtime-timesheet/',
        component: ReportOvertimeTimesheet,
        name: 'Overtime Sheet',
        icon: <FieldTimeOutlined />,
        auth: user => permissions.hasOvertimeModule(user),
      },
      {
        path: '/submission/leave/',
        component: SubmissionAnnualLeave,
        name: 'Apply Leave',
        icon: <CalendarOutlined />,
        auth: user => permissions.hasLeaveModule(user),
      },
      {
        path: '/report/yearly-staff-leave/',
        component: ReportYearlyStaffLeave,
        name: 'Staff Leave',
        icon: <CalendarOutlined />,
        auth: user => permissions.hasLeaveModule(user),
      },
      {
        path: '/report/monthly-timesheet/',
        component: MonthlyTimesheet,
        name: 'Monthly Timesheet',
        icon: <CarryOutOutlined />,
      },
    ]
  },
  {
    path: '/edit-time/',
    component: EditTime,
    name: 'Edit Time',
    icon: <EditOutlined />,
  },
  {
    path: '/screenshots/',
    component: Screenshots,
    name: 'Screenshots',
    icon: <DesktopOutlined />,
  },
  {
    name: 'Attendance',
    icon: <CarryOutOutlined />,
    auth: user => permissions.hasAttendanceModule(user),
    redirect: '/',
    menu: [
      {
        path: '/attendance/facial-photo/',
        component: AttendancePhotos,
        name: 'Facial Photo',
        icon: <CameraOutlined />,
      },
      {
        path: '/attendance/daily/',
        component: AttendanceDaily,
        name: 'Attendance Daily',
        icon: <ScheduleOutlined />,
      },
    ]
  },
  {
    name: 'Reports',
    icon: <FileOutlined />,
    menu: [
      // {
      //   path: '/report/time-sheet/',
      //   render: () => 'Timesheet',
      //   name: 'Timesheet',
      // },
      {
        path: '/report/time-use/',
        component: TimeUseReport,
        name: 'Time Use',
      },
      {
        path: '/report/timeline/',
        component: TimelineReport,
        name: 'Timeline Daily',
      },
      {
        path: '/report/app-usage/',
        component: AppUsageReport,
        name: 'App Usage',
      },
      {
        path: '/report/notes/',
        component: ActivityNotesReport,
        name: 'Notes',
      },
      {
        // https://github.com/lewatmana/timetracking-web/issues/238
        path: '/report/monthly-production/',
        component: MonthlyProductionReport,
        name: 'Monthly Production',
        auth: user => permissions.hasManagerProd(user),
        // auth: user => user.is_owner,
        redirect: '/',
      },
      {
        // https://github.com/lewatmana/timetracking-web/issues/373
        path: '/report/production-detail/',
        component: ProductionDetailReport,
        name: 'Production Detail',
        auth: user => permissions.canViewProdDetailReport(user),
        redirect: '/',
      },
      {
        path: '/report/working-sheet/',
        component: WorkingSheetReport,
        name: 'Working Sheets',
        auth: user => user.is_owner,
        redirect: '/',
      },
      {
        path: '/report/yearly-report/',
        component: YearlyReport,
        name: 'Yearly Report',
        auth: user => permissions.canViewProdDashboard(user),
        redirect: '/',
      },
    ]
  },
  {
    path: '/downloads/',
    component: Downloads,
    // name: 'Screenshots',
    // icon: <DesktopOutlined />,
  },
]

export default routes