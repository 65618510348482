import PropTypes from 'prop-types';
import { Col, Row, Tooltip } from 'antd';
import { CommentOutlined, ExperimentOutlined, PieChartOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import moment from 'moment';

import BaseContentCalendar from 'src/pages/DashboardApp/Attendance/Daily/Content/Calendar';
// import leaveHelper from '../../helper';

class ContentCalendar extends BaseContentCalendar {
  constructor(props) {
    super(props)

    // this.note = 'Using dummy data, when you change the date selection, the new data will be lost'
    this.note = 'Click a date on the calendar to show or apply leave'
  }

  renderCellDate = (date) => {
    const { userAttendances } = this.props
    const currentAttendances = userAttendances
      // .filter(att => att.status === 'validated')
      .filter(att => moment(att.attendance_date).format('YYYY-MM-DD') === date.format('YYYY-MM-DD'))

    const isPresent = currentAttendances.length > 0

    const checkinAttendances = currentAttendances.filter(att => att.attend_type === 'checkin')
    const checkoutAttendances = currentAttendances.filter(att => att.attend_type === 'checkout')

    const isPresentIn = checkinAttendances.find(att => att.status === 'validated')
    const isPresentOut = checkoutAttendances.find(att => att.status === 'validated')

    const currentMonth = date.format('YYYY-MM') === moment(this.props.date).format('YYYY-MM')


    // const currentLeave = this.props.userLeaves.find(userLeave => moment(userLeave.date).format('YYYY-MM-DD') === date.format('YYYY-MM-DD'))
    // const currentLeaves = this.props.userLeaves.filter(userLeave => moment(userLeave.date).format('YYYY-MM-DD') === date.format('YYYY-MM-DD'))

    // const currentLeaves = this.props.userLeaves.filter(userLeave => moment(userLeave.start_date).format('YYYY-MM-DD') === date.format('YYYY-MM-DD'))
    // const currentLeave = currentLeaves[currentLeaves.length - 1]

    const currentLeaves = this.props.userLeaves.filter(userLeave =>
      moment(userLeave.start_date).format('YYYY-MM-DD') <= date.format('YYYY-MM-DD')
      && moment(userLeave.finish_date).format('YYYY-MM-DD') >= date.format('YYYY-MM-DD'))
    const currentLeave = currentLeaves[currentLeaves.length - 1]

    

    return (
      <div
        className={classnames('ant-picker-cell-inner ant-picker-calendar-date', {
          'ant-picker-calendar-date-today': currentMonth && date.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'),
          'leave-approved': currentLeave && currentLeave.status === 'approved',
          'leave-waiting': currentLeave && currentLeave.status === 'pending',
          'leave-rejected': currentLeave && (currentLeave.status === 'rejected' || currentLeave.status === 'cancelled'),
          // 'date-present-warning': true,
          // 'date-present-success': isPresent,
        })}
      >
        <div className='ant-picker-calendar-date-value'>
          <Row justify="space-between">
            <Col>
              {currentLeave && currentLeave.day_type === 'half' && (
                <Tooltip title={currentLeave.day_type_display} mouseLeaveDelay={0}>
                  <PieChartOutlined className="half-indicator" />
                  {/* <ExperimentOutlined style={{ color: 'rgba(0, 0, 0, 0.4)', fontSize: '12px' }} /> */}
                </Tooltip>
              )}
            </Col>
            <Col>
              {date.format('DD')}
            </Col>
          </Row>
        </div>
        <div className='ant-picker-calendar-date-content' style={{ overflowY: 'hidden' }}>
          {/* {isPresent && ( */}
            <div className='date-content-leave' style={{ opacity: currentMonth ? 1 : 0.5 }}>
              {currentLeave && (
                <div style={{ marginTop: '-4px' }}>
                  <div className="text-truncate" title={currentLeave.leave_type.name}>
                    {/* {leaveHelper.getTypeLabel(currentLeave.type)} */}
                    {currentLeave.leave_type.name}
                  </div>
                  <div className="webkit-box two-lines" style={{ fontSize: '11px' }} title={currentLeave.note}>
                    <CommentOutlined style={{ marginRight: '4px' }} />
                    {currentLeave.note}
                  </div>
                </div>
              )}
              {/* {!this.mutableScreen.isMobile && currentAttendances.length > 0 && (
                <Row justify='space-around' style={{ textAlign: 'center' }}>
                  <Col style={{ color: isPresentIn ? '#52c41a' : '#faad14' }}>
                    {checkinAttendances.length > 0 && (
                      <div>
                        {this.getStatusIcon(isPresentIn)}
                        <div>in</div>
                      </div>
                    )}
                  </Col>
                  <Col style={{ color: isPresentOut ? '#52c41a' : '#faad14' }}>
                    {checkoutAttendances.length > 0 && (
                      <div>
                        {this.getStatusIcon(isPresentOut)}
                        <div>out</div>
                      </div>
                    )}
                  </Col>
                </Row>
              )} */}
            </div>
          {/* )} */}
        </div>
      </div>
    )
  }
}

ContentCalendar.propTypes = {
  ...ContentCalendar.propTypes,
  userLeaves: PropTypes.array,
}

ContentCalendar.defaultProps = {
  ...ContentCalendar.defaultProps,
  userLeaves: [],
}

export default ContentCalendar