import { request } from '../'
import appConfig from 'src/config/app'
import ls from './localStorage'

// history is required param
export default function logout(history, callback) {
  if (typeof callback !== 'function') callback = () => {}

  const data = new FormData()
  data.set('token', ls.getUserToken().access_token)
  data.set('client_id', appConfig.oauth.client_id)
  data.set('client_secret', appConfig.oauth.client_secret)

  request({
    method: 'post',
    urlKey: 'oauth-revoke',
    data,
    onSuccess: () => {
      callback(true)
      backToLogin(history)
    },
    onFailed: () => {
      callback(false)
      backToLogin(history)
    },
  })
}

function backToLogin(history) {
  ls.removeUserToken()
  const targetPathname = `/login?next=${window.location.pathname}`
  if (history && typeof history.replace === 'function') {
    history.replace(targetPathname)
  } else {
    // not recommended
    window.location.replace(targetPathname)
  }
}