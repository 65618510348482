import React from 'react'
import { Button, Col, Row, Spin } from 'antd'
import { AuditOutlined, ReloadOutlined } from '@ant-design/icons'
import moment from 'moment'

import { BaseList, VisibilityContainer } from 'src/components'
import { lib } from 'src/Utils'
import HeaderAttendance from './Header'
import ContentAttendance from './Content'
import AttendDialog from './Attend'

// class ini di extends beserta komponent kontennya di bagian Leave and Overtime
// apabila ada perubahan, cek ulang di bagian yang menggunakan class ini
class AttendanceDaily extends BaseList {
  constructor(props) {
    super(props)

    this.title = 'Attendance Daily'
    this.urlKey = 'read-userAttendance'
    this.pagination = false

    this.date = moment().format()

    this.keysQueryParam = ['user', 'date_after', 'date_before', 'status', 'is_verified', 'is_register']

    this.myProfile = lib.ls.getCurrentUser()

    this.users = []

    this.state = {
      ...this.state,
      visibleType: 'general',
    }
  }

  isValidParam = (key) => (
    key === 'date_after' ||
    key === 'date_before' ||
    this[key] !== undefined
  )

  getParam = (key, val) => {
    // request dengan parameter tanggal yang tampil di Calendar
    if (key === 'date_after')
      return moment(this.date).startOf('month').startOf('week').format('YYYY-MM-DD')
    if (key === 'date_before')
      return moment(this.date).endOf('month').add(1, 'week').endOf('week').format('YYYY-MM-DD')
    
    return val
  }

  onRefAttendDialog = (ref) => {
    this._attendDialog = ref
  }

  loadAllMembers = async () => lib.loadAllMembers()

  getDefaultSelectedUser = (paramUser) => {
    let selectedUser = this.users.find(user => `${user.id}` === paramUser)

    if (!selectedUser)
      selectedUser = this.users.find(user => user.email === this.myProfile.email)

    if (!selectedUser && this.users.length)
      selectedUser = this.users[0]

    return selectedUser
  }

  beforeRead = async (keyLoading, callback) => {
    return new Promise((resolve) => {
      this.setThisState({ [keyLoading]: true }, async () => {
        const resMembers = await this.loadAllMembers()

        if (resMembers.error) {
          this.setThisState({ [keyLoading]: false, errorResponse: resMembers.error.response }, callback)
          resolve(true)
          return
        }

        this.users = lib.sorter.byArray(resMembers.members, 'first_name')

        const prevParams = lib.getObjParam(this.props.location.search)

        const selectedUser = this.getDefaultSelectedUser(prevParams.user)
        if (selectedUser)
          this.props.history.replace(this.props.location.pathname + lib.getStrParam({ ...prevParams, user: selectedUser.id }))

        // let selectedUser = this.users.find(user => `${user.id}` === prevParams.user)

        // if (!selectedUser) {
        //   selectedUser = this.users.find(user => user.email === this.myProfile.email)

        //   if (!selectedUser && this.users.length)
        //     selectedUser = this.users[0]

        //   if (selectedUser)
        //     this.props.history.replace(this.props.location.pathname + lib.getStrParam({ ...prevParams, user: selectedUser.id }))
        // }

        // don't remove, for param
        if (selectedUser)
          this.user = selectedUser.id
        else
          delete this.user

        resolve()
      })
    })
  }

  onChangeUser = (user) => {
    const prevParams = lib.getObjParam(this.props.location.search)
    this.props.history.replace(this.props.location.pathname + lib.getStrParam({ ...prevParams, user: user.id }))
  }

  showAttend = () => {
    this._attendDialog.show()
  }

  getSelectedUser = () => {
    const prevParams = lib.getObjParam(this.props.location.search)
    let selectedUser = this.users.find(user => `${user.id}` === prevParams.user)
    if (!selectedUser)
      selectedUser = this.users.find(user => user.email === this.myProfile.email) ||
        this.myProfile

    return selectedUser
  }

  setDate = (date) => {
    const prevMonth = moment(this.date).format('YYYY-MM')
    this.date = moment(date).format()

    if (moment(date).format('YYYY-MM') !== prevMonth)
      this.forceRead()
    else
      this.forceUpdate()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentParam = lib.getObjParam(this.props.location.search)
    const nextParam = lib.getObjParam(nextProps.location.search)

    if (currentParam.user !== nextParam.user) {
      if (!nextParam.user) {
        let selectedUser = this.users.find(user => user.email === this.myProfile.email)

        if (!selectedUser && this.users.length)
          selectedUser = this.users[0]

        if (selectedUser)
          nextProps.history.replace(nextProps.location.pathname + lib.getStrParam({ ...nextParam, user: selectedUser.id }))
      } else if (currentParam.user) {
        this.forceRead()
      }
    }
  }

  getVisibleContainer = () => this.state.visibleType === 'general'

  renderAnotherContainers() {
    return null
  }

  // renderContainer() {
  //   return (
  //     <div>
  //       <Spin spinning={this.state.isLoading}>
  //         {this.renderHeader()}
  //       </Spin>
  //       <div>
  //         <Spin spinning={this.state.isLoading}>
  //           {this.renderContent()}
  //         </Spin>
  //       </div>
  //     </div>
  //   )
  // }

  renderRightContent() {
    return (
      <Row align='middle' gutter={[6, 6]}>
        <Col>
          <Button loading={this.state.isLoading} icon={<ReloadOutlined />} onClick={this.forceRead}>
            Reload
          </Button>
        </Col>
        <Col>
          <Button type='primary' icon={<AuditOutlined />} onClick={this.showAttend}>
            Attend
          </Button>
        </Col>
      </Row>
    )
  }

  renderHeader() {
    return (
      <HeaderAttendance
        user={this._selectedUser}
        users={this.users} // this value for listening read activity, be careful
        onSelectUser={this.onChangeUser}
        attendances={this.__list}
        date={this.date}
      />
    )
  }

  renderContent() {
    return (
      <ContentAttendance
        user={this._selectedUser}
        userAttendances={this.__list}
        date={this.date}
        setDate={this.setDate}
      />
    )
  }

  render() {
    // const selectedUser = this.getSelectedUser()
    this._selectedUser = this.getSelectedUser()

    return (
      <div>
        <VisibilityContainer style={{ padding: '24px' }} visible={this.getVisibleContainer()}>
          <Row justify='space-between' gutter={[12, 12]} style={{ marginBottom: '12px' }}>
            <Col>
              <Row align='middle'>
                <Col>
                  <h1 style={{ marginBottom: 0 }}>
                    {this.title}
                  </h1>
                </Col>
              </Row>
            </Col>
            <Col>
              {this.renderRightContent()}
            </Col>
          </Row>

          {this.state.errorResponse ? this.getErrorComp() : (
            <div>
              <Spin spinning={this.state.isLoading}>
                {this.renderHeader()}
              </Spin>
              <div>
                <Spin spinning={this.state.isLoading}>
                  {this.renderContent()}
                </Spin>
              </div>
            </div>
          )}
        </VisibilityContainer>
        {this.renderAnotherContainers()}

        {/* Attend for my user */}
        <AttendDialog
          ref={this.onRefAttendDialog}
          user={this._selectedUser}
          reload={this.forceRead}
        />
      </div>
    )
  }
}

export default AttendanceDaily