import PropTypes from 'prop-types';

import { BaseModal } from 'src/components';
import StaffLeaveView from 'src/pages/DashboardApp/Submission/AnnualLeave/DialogLeaveDetail/View';

class DialogLeaveDetail extends BaseModal {
  constructor(props) {
    super(props)

    this.title = 'Staff Leave'

    this.state = {
      ...this.state,
      // user: null,
      userLeave: null,
    }
  }

  getModalProps = () => ({
    centered: true,
    destroyOnClose: true,
    bodyStyle: { padding: 0 },
    width: 640,
    footer: null,
  })

  onChangedLeave = () => this.props.reload()

  setLoading = (isLoading, callback) => {
    this.setState({ isLoading }, callback)
  }

  renderContent = () => {
    return (
      <StaffLeaveView
        // readOnly
        // user={this.state.user}
        userLeave={this.state.userLeave}
        hide={this.hide}
        setLoading={this.setLoading}
        onChangedLeave={this.onChangedLeave}
      />
    )
  }
}

DialogLeaveDetail.propTypes = {
  ...DialogLeaveDetail.propTypes,
  reload: PropTypes.func,
}

DialogLeaveDetail.defaultProps = {
  ...DialogLeaveDetail.defaultProps,
  reload: () => {},
}

export default DialogLeaveDetail