import PropTypes from 'prop-types';
import { Card, Col, Row } from 'antd';

import BaseDialogListUser from '../YearlyStaffLeave/DialogListUser'
import PopoverContentUser from './PopoverContentUser';

class DialogListUser extends BaseDialogListUser {
  constructor(props) {
    super(props)

    this.title = 'Staff Overtime by date'

    this.initDefaultState({ usersOvertime: [] })
    this.initState()
  }

  getData = () => this.state.usersOvertime
  renderContentUser = (userOvertime) => <PopoverContentUser
    userOvertime={userOvertime}
    style={{ maxWidth: '100%' }}
  />

  // renderContent = () => {
  //   console.log('Rendered', this)
  //   return (
  //     <div>
  //       <Row gutter={[0, 12]} style={{ flexDirection: 'column' }}>
  //         {this.state.usersOvertime.map(userOvertime => (
  //           <Col key={userOvertime.pk}>
  //             <Card
  //               hoverable
  //               bodyStyle={{ padding: 0 }}
  //               onClick={() => this.props.showDetail(userOvertime)}
  //             >
  //               <PopoverContentUser
  //                 userOvertime={userOvertime}
  //               />
  //             </Card>
  //           </Col>
  //         ))}
  //       </Row>
  //     </div>
  //   )
  // }
}

DialogListUser.propTypes = {
  ...DialogListUser.propTypes,
  // showDetail: PropTypes.func,
}

DialogListUser.defaultProps = {
  ...DialogListUser.defaultProps,
  // showDetail: () => {},
}

export default DialogListUser