import React from 'react'
import { Button, Card, Col, DatePicker, Row, Spin } from 'antd'
import { ArrowLeftOutlined, CaretLeftOutlined, CaretRightOutlined, ReloadOutlined } from '@ant-design/icons'
import { get } from 'lodash';
import moment from 'moment'

import { BaseList, BaseListActivity } from 'src/components'
import { lib, permissions } from 'src/Utils'
import { TimelineSection } from 'src/pages/DashboardApp/DashboardCompany/Sections'
import UserProduction from './UserProduction'

const { RangePicker } = DatePicker

class DetailTeamListVersion extends BaseListActivity {
  constructor(props) {
    super(props)

    this.keysQueryParam.push(...['id'])

    this.myProfile = lib.ls.getCurrentUser()
    this.isManagerMySelf = get(this.props.team.manager, 'email') === this.myProfile.email

    this.id = this.props.team.users.map(user => user.id).join(',')
    if (this.isManagerMySelf)
      this.id += ',' + this.props.team.manager.id

    this.rangeMessageError = 'Cannot display for more than 7 days'
  }

  getPrevParams = () => lib.getObjParam(this.props.location.search)

  checkRange = (startDate, endDate) => (
    moment(endDate).diff(moment(startDate)) > 0 &&
    moment(endDate).diff(moment(startDate)) < 1000 * 60 * 60 * 24 * 7 // max range 7 days
  )

  prevDays = () => {
    if (moment(this.range_before).subtract(1, 'day').format('YYYY-MM-DD') === moment(this.range_before).subtract(1, 'day').endOf('week').format('YYYY-MM-DD')) {
      const startDate = moment(this.range_before).subtract(1, 'weeks').format('YYYY-MM-DD')
      const endDate = moment(this.range_after).subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')
      this.onChangeFilter({ start_date: startDate, end_date: endDate })
    } else {
      const startDate = moment(this.range_before).startOf('week').format('YYYY-MM-DD')
      const endDate = moment(this.range_before).subtract(1, 'day').format('YYYY-MM-DD')
      this.onChangeFilter({ start_date: startDate, end_date: endDate })
    }
  }

  nextDays = () => {
    if (moment(this.range_after).add(1, 'day').format('YYYY-DD-MM') === moment(this.range_after).add(1, 'day').startOf('week').format('YYYY-DD-MM')) {
      const startDate = moment(this.range_before).add(1, 'weeks').startOf('week').format('YYYY-MM-DD')
      const endDate = moment(this.range_after).add(1, 'weeks').format('YYYY-MM-DD')
      this.onChangeFilter({ start_date: startDate, end_date: endDate })
    } else {
      const startDate = moment(this.range_after).add(1, 'day').format('YYYY-MM-DD')
      const endDate = moment(this.range_after).endOf('week').format('YYYY-MM-DD')
      this.onChangeFilter({ start_date: startDate, end_date: endDate })
    }
  }

  render() {
    return (
      <div>
        <div>
          <Row justify='space-between' gutter={[12, 12]} style={{ marginBottom: '12px' }}>
            <Col>
              <Row align='middle'>
                <Col>
                  <div className='ant-page-header-back'>
                    <div className='ant-page-header-back-button' onClick={this.props.onClose}>
                      <ArrowLeftOutlined />
                    </div>
                  </div>
                </Col>
                <Col style={{ maxWidth: 'calc(100% - 32px)' }}>
                  <h1 className='text-truncate' style={{ marginBottom: 0 }} title={`The team at ${this.myProfile.company.name}`}>
                    {this.props.team.name}
                  </h1>
                </Col>
              </Row>
            </Col>
            {/* Be careful using minWidth */}
            <Col style={{ flex: 1, minWidth: '280px' }}>
              <Row justify='end' align='middle' gutter={[6, 6]}>
                <Col className='only-xs-block'>
                  <Button block loading={this.state.isLoading} icon={<ReloadOutlined />} onClick={this.read}>
                    Refresh
                  </Button>
                </Col>
                <Col className='only-xs-block'>
                  <Row>
                    <Col>
                      <Button icon={<CaretLeftOutlined />} onClick={this.prevDays} />
                    </Col>
                    <Col style={{ flex: 1, margin: '0 -1px', maxWidth: 'calc(100% - 32px - 32px)' }}>
                      <RangePicker
                        allowClear={false}
                        format='YYYY-MM-DD'
                        ranges={{
                          'Today': [moment(), moment()],
                          'This Week': [moment().startOf('week'), moment().endOf('week')],
                          'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                        }}
                        value={[moment(this.range_before), moment(this.range_after)]}
                        style={{ width: '100%' }}
                        onChange={(dates, dateStrings) => {
                          this.tempDateStrings = dateStrings
                        }}
                        onOpenChange={(opened) => {
                          if (!opened) {
                            if (this.tempDateStrings[0] !== this.range_before || this.tempDateStrings[1] !== this.range_after)
                              this.onChangeFilter({ start_date: this.tempDateStrings[0], end_date: this.tempDateStrings[1] })
                          } else {
                            this.tempDateStrings = [this.range_before, this.range_after]
                          }
                        }}
                      />
                    </Col>
                    <Col>
                      <Button icon={<CaretRightOutlined />} onClick={this.nextDays} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Spin spinning={this.state.isLoading}>
            {this.state.errorResponse ? this.getErrorComp() : (
              <Card className='app-card' bodyStyle={{ padding: 0 }}>
                <TimelineSection
                  activities={this.__list}
                  startTime={this.range_before}
                  endTime={this.range_after}
                />
              </Card>
            )}
          </Spin>
          {permissions.canViewProdDashboard(this.myProfile, this.myProfile) && (
            <UserProduction
              id={this.id}
              selectedDate={this.range_after}
              team={this.props.team}
              history={this.props.history}
            />
          )}
        </div>
      </div>
    )
  }
}

export default DetailTeamListVersion