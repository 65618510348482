import PropTypes from 'prop-types';
import { Button, Col, Row, Spin } from 'antd';
import { get } from 'lodash';

import { BaseModal } from 'src/components';
import OvertimeView from 'src/pages/DashboardApp/Submission/Overtime/DialogOvertimeDetail/View';
import { CheckOutlined } from '@ant-design/icons';

class DialogOvertimeDetail extends BaseModal {
  constructor(props) {
    super(props)

    this.title = 'Overtime'

    this.initState({
      userOvertime: null,
    })
  }

  getModalProps = () => ({
    centered: true,
    destroyOnClose: true,
    bodyStyle: { padding: 0 },
    width: 640,
    footer: null,
  })

  onChangedOvertime = () => this.props.reload()

  setLoading = (isLoading, callback) => {
    this.setState({ isLoading }, callback)
  }

  renderContent = () => {
    return (
      <Spin spinning={this.state.isLoading}>
        <OvertimeView
          // readOnly
          // user={this.state.user}
          userOvertime={this.state.userOvertime}
          actualOvertime={get(this.state.userOvertime, '_working')}
          actualMeal={get(this.state.userOvertime, '_meal')}
          hide={this.hide}
          setLoading={this.setLoading}
          onChangedOvertime={this.onChangedOvertime}
        />
        {/* <div className="ant-modal-footer">
          <Row gutter={[12, 12]}>
            <Col>
              <Button type="primary" icon={<CheckOutlined />}>
                Valid
              </Button>
            </Col>
          </Row>
        </div> */}
      </Spin>
    )
  }
}

DialogOvertimeDetail.propTypes = {
  ...DialogOvertimeDetail.propTypes,
  reload: PropTypes.func,
}

DialogOvertimeDetail.defaultProps = {
  ...DialogOvertimeDetail.defaultProps,
  reload: () => {},
}

export default DialogOvertimeDetail