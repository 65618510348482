import { Space, Tag } from 'antd'
import moment from 'moment'

import _leave from './leave'
import _overtime from './overtime'
import _s from './static'
import { lib } from 'src/Utils'

const STATUS_PENDING = 'pending'
const STATUS_APPROVED = 'approved'
const STATUS_REJECTED = 'rejected'
const STATUS_CANCELLED = 'cancelled'

const COLORS = {
  BLACK: 'gray',
  RED: '#ff4d4f',
  GRAY: '#E8E8E8',
  GREEN: '#2ecc71',
}

function isWaiting(leaveOvertime) {
  return [STATUS_PENDING].includes(leaveOvertime.status)
}

function isApproved(leaveOvertime) {
  return [STATUS_APPROVED].includes(leaveOvertime.status)
}

function isRejected(leaveOvertime) {
  return [STATUS_REJECTED].includes(leaveOvertime.status)
}

function isCancelled(leaveOvertime) {
  return [STATUS_CANCELLED].includes(leaveOvertime.status)
}

function isAborted(leaveOvertime) {
  return [STATUS_REJECTED, STATUS_CANCELLED].includes(leaveOvertime.status)
}

function isWeekend(date) {
  return [0, 6].includes(moment(date).day())
}

function allowRevert(leaveOvertime, user) {
  if (!user) user = lib.ls.getCurrentUser()

  return (
    user.is_owner &&
    isApproved(leaveOvertime)
  )
}

function renderStatus(leaveOvertime, opts) {
  opts = { prefix: '', ...opts }

  return (
    <Tag
      style={{ marginRight: 0 }}
      color={isAborted(leaveOvertime)
        ? 'error'
        : isApproved(leaveOvertime)
          ? 'success' : 'warning'}
    >
      {opts.prefix}
      {leaveOvertime.status.toUpperCase()}
    </Tag>
  )
}

function renderStatuses(userLeaves) {
  const waitingLeaves = userLeaves.filter(leaveOvertime => isWaiting(leaveOvertime))
  const approvedLeaves = userLeaves.filter(leaveOvertime => isApproved(leaveOvertime))
  const cancelledLeaves = userLeaves.filter(leaveOvertime => isCancelled(leaveOvertime))
  const rejectedLeaves = userLeaves.filter(leaveOvertime => isRejected(leaveOvertime))

  return (
    <span style={{ marginLeft: '12px' }}>
      <Space>
        {[waitingLeaves, approvedLeaves, cancelledLeaves, rejectedLeaves].filter(item => item.length).map(items => (
          <span key={items[0].pk}>
            {renderStatus(items[0], { prefix: `${items.length} ` })}
          </span>
        ))}
      </Space>
    </span>
  )
}

function getColor(status) {
  switch(status) {
    case STATUS_PENDING:
      return COLORS.GRAY
    case STATUS_APPROVED:
      return COLORS.GREEN
    case STATUS_REJECTED:
      return COLORS.RED
    // case STATUS_CANCELLED:
    //   return COLORS.BLACK
    default:
      return COLORS.BLACK
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  _leave,
  _overtime,
  _s,

  allowRevert,
  getColor,
  isWeekend,
  isWaiting,
  isApproved,
  isRejected,
  isCancelled,
  isAborted,
  renderStatus,
  renderStatuses,
}