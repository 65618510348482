import NumberFormat from 'react-number-format'
import { get } from 'lodash'

import { PointBonus } from 'src/components'
import BasePoint from './BasePoint'
import typeCodes from 'src/Utils/static/typeCodes'
import staticProd from 'src/Utils/static/productionJobs'
import { lib } from 'src/Utils'

class PointByGrade extends BasePoint {
  constructor(props) {
    super(props)

    this.myProfile = lib.ls.getCurrentUser()
    this.title = 'Point by Grade'
    this.a = 'Avg Point / day'
    this.b = 'Avg Point / job'
    this.typeExchangeFactor = 1

    this.columns = [
      { dataIndex: 'grade' },
      {
        dataIndex: 'point',
        style: {textAlign: 'right'},
        render: val => (
          <span>
            <NumberFormat
              thousandSeparator
              displayType='text'
              value={Number(val).toFixed(2)}
            />
          </span>
        )
      },
      {
        dataIndex: 'pct',
        style: {textAlign: 'right'},
        render: val => this.getLabelPct(val)
      },
    ]
  }

  getTotalValue = () => {
    if (this.props.effectiveExchange) {
      const user = { ...this.props.user, point_types: this.props.user.point_types.map(pointType => ({ code_display: pointType.code_display })) }
      const allowReadReward = this.myProfile.is_owner || String(this.myProfile.id) === String(user.id)

      return !allowReadReward ? Number(Number(this.totalValue).toFixed(2)) : (
        <PointBonus
          prodMonth={this.props.dateString}
          // point={Math.floor(Number(this.totalValue) * 100) / 100}
          point={Number(Number(this.totalValue).toFixed(2))}
          typeExchangeFactor={this.typeExchangeFactor}
          effectiveExchange={this.props.effectiveExchange}
          user={user}
        />
      )
    }

    return Math.floor(Number(this.totalValue) * 100) / 100
  }

  mappingXY = (record) => ({ x: record.grade, y: record.point })

  convertData = (userProductionJobs, nextProps = this.props) => {
    if (!userProductionJobs) {
      this.setState({ dataSource: [] })
      return
    }

    const { user } = nextProps
    const qsExchangeFactor = get(user.point_types.find(type => typeCodes.QS_CODES.includes(type.code)), 'exchange_factor', staticProd.DEFAULT_EXCHANGE_FACTOR)
    const qaExchangeFactor = get(user.point_types.find(type => typeCodes.QA_CODES.includes(type.code)), 'exchange_factor', staticProd.DEFAULT_EXCHANGE_FACTOR)
    const trainerExchangeFactor = get(user.point_types.find(type => typeCodes.TRAINER_CODES.includes(type.code)), 'exchange_factor', staticProd.DEFAULT_EXCHANGE_FACTOR)
    const managerExchangeFactor = get(user.point_types.find(type => typeCodes.MANAGER_CODES.includes(type.code)), 'exchange_factor', staticProd.DEFAULT_EXCHANGE_FACTOR)
    const { qs_production_jobs, qa1_production_jobs } = userProductionJobs

    let pointQS = 0, pointQA = 0, pointTrainer = 0, pointManager = 0, jobs = [] // jobs = qa1_production_jobs.concat(qs_production_jobs)

    const trainerType = user.point_types.find(type => typeCodes.TRAINER_CODES.includes(type.code))
    if (trainerType) {
      pointTrainer = trainerType.denominator
    }

    const managerType = user.point_types.find(type => typeCodes.MANAGER_CODES.includes(type.code))
    if (managerType) {
      pointManager = managerType.denominator
    }

    if (qsExchangeFactor) {
      for (const job of qs_production_jobs) {
        pointQS += Number(job.qs_point)
        // jika QS dan QA user yang sama, di ambil salah satu atau dihitung 2 ?
        if (!jobs.find(item => item.job_id === job.job_id))
          jobs.push(job)
      }
    }

    if (qaExchangeFactor) {
      for (const job of qa1_production_jobs) {
        pointQA += Number(job.qa1_point)
        if (!jobs.find(item => item.job_id === job.job_id))
          jobs.push(job)
      }
    }

    this.totalValue = pointQS + pointQA + pointTrainer + pointManager
    this.typeExchangeFactor = (
      (pointQS * qsExchangeFactor) + (pointQA * qaExchangeFactor) + (pointTrainer * trainerExchangeFactor) + (pointManager * managerExchangeFactor)) /
      (pointQS + pointQA + pointTrainer + pointManager)

    const setDataSource = (dataSource, jobs, keyPoint) => {
      for (const job of jobs) {
        const foundIndex = dataSource.findIndex(item => item.grade === job.grade)
        const prevDataSource = dataSource[foundIndex]
        dataSource.splice(
          !prevDataSource ? dataSource.length : foundIndex,
          !prevDataSource ? 0 : 1,
          {
            grade: job.grade,
            point: Number(get(prevDataSource, 'point', 0)) + Number(job[keyPoint])
          }
        )
      }
    }

    const dataSource = []
    setDataSource(dataSource, qs_production_jobs, 'qs_point')
    setDataSource(dataSource, qa1_production_jobs, 'qa1_point')

    for (const data of dataSource)
      data.pct = this.totalValue ? data.point / this.totalValue : data.point

    dataSource.sort((a, b) => b.pct - a.pct)

    this.setAverage(jobs, nextProps)

    this.setThisState({ dataSource })
  }

  setAverage = (productionJobs) => {
    const prodDays = []
    let totalDays = 0, totalJobs = productionJobs.length

    for (const i in productionJobs) {
      if (prodDays.indexOf(productionJobs[i].prod_day) === -1) {
        totalDays += 1
        prodDays.push(productionJobs[i].prod_day)
      }
    }

    this.averagePerDay = Math.round(this.totalValue / (totalDays || 1) * 100) / 100
    this.averagePerJob = Math.round(this.totalValue / (totalJobs || 1) * 100) / 100
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.userProductionJobs !== nextProps.userProductionJobs) {
      this.convertData(nextProps.userProductionJobs, nextProps)
    }

    this.childComponentWillReceiveProps(nextProps)
  }

  didMount() {
    if (this.props.userProductionJobs)
      this.convertData(this.props.userProductionJobs)
  }
}

export default PointByGrade
