import PropTypes from 'prop-types';
import { Avatar, Badge } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { get } from 'lodash';

import { BaseComponent } from 'src/components';
import _h from 'src/pages/DashboardApp/Submission/helper';

class UserPhotoStatus extends BaseComponent {
  render() {
    // console.log('Rendered', this)
    const { allowText, user, status, size, onClick, ...p } = this.props

    return (
      <Badge
        dot
        color={_h.getColor(status)}
        offset={[-5, size - 7]}
        style={{ width: '12px', height: '12px' }}
        {...p} // for supporting Tooltip props
      >
        <Avatar
          // size={42}
          size={size}
          src={get(user, 'photo')}
          icon={!allowText && <UserOutlined />}
          className='list-user-icon'
          style={{ color: 'white' }}
          onClick={onClick}
        >
          {get(user, 'first_name', '').slice(0, 5)}
        </Avatar>
      </Badge>
    )
  }
}

UserPhotoStatus.propTypes = {
  ...UserPhotoStatus.propTypes,
  user: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  allowText: PropTypes.bool,
  size: PropTypes.number,
  onClick: PropTypes.func,
}

UserPhotoStatus.defaultProps = {
  ...UserPhotoStatus.defaultProps,
  allowText: false,
  size: 42,
  onClick: () => {},
}

export default UserPhotoStatus

