import React from 'react'
import { Col, Row, Tooltip } from 'antd'
import moment from 'moment'

import { BaseComponent } from 'src/components'
import { lib } from 'src/Utils'

const MS_DAY = 1000 * 60 * 60 * 24

class Bar extends BaseComponent {
  render() {
    return (
      <div>
        <Row
          style={{
            position: 'relative',
            height: '16px',
            backgroundColor: '#f0f2f5',
            boxShadow: 'inset 0px 0px 0px 1px #e8e8e8',
            borderRadius: '8px',
            overflow: 'hidden',
          }}
        >
          {this.props.timelines.map(timeline => {
            const ms = lib.time.getDiff(timeline)
            let widthPct = ms / MS_DAY * 100
            if (widthPct && widthPct < 0.05)
              widthPct = 0.05

            const start_time = moment(timeline.start_time).startOf('day').format()
            const leftMs = lib.time.getDiff({ start_time, finish_time: timeline.start_time })
            const leftPct = leftMs / MS_DAY * 100

            return (
              <Tooltip
                mouseLeaveDelay={0}
                title={`${moment(timeline.start_time).format('hh:mm:ss')} - ${moment(timeline.finish_time).format('hh:mm:ss')} (${lib.time.getLabel(ms, true)})`}
              >
              <Col
                key={timeline.alias}
                className={`${timeline.start_time} # ${timeline.finish_time}`}
                style={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: `${leftPct}%`,
                  width: `${widthPct}%`,
                  backgroundColor: timeline.is_manual_time ? 'orange' : '#1890ff',
                  boxShadow: timeline.is_manual_time ? 'inset 0px 0px 0px 1px #d48900' : 'inset 0px 0px 0px 1px #0484fb',
                }}
              />
              </Tooltip>
            )
          })}
        </Row>
      </div>
    )
  }
}

Bar.defaultProps = {
  timelines: [],
}

export default Bar