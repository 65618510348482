import PropTypes from 'prop-types';
import { Col, Row, Tooltip } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import classnames from 'classnames';

import { BarAnim } from 'src/components'
import HeaderAttendance from 'src/pages/DashboardApp/Attendance/Daily/Header';
import helper from '../../helper';

class SubmissionAnnualLeaveHeader extends HeaderAttendance {
  renderTitle() {
    return 'Leave Summary'
  }

  // renderCardUser() {
  //   return null
  // }

  renderCardDetail() {
    const todayCheckin = false,
      todayCheckout = false,
      checkinAttendances = [],
      checkoutAttendances = [],
      totalTime = '-'

    const calculatedDate = get(this.props.userBalance, 'calculated_date')
    const activeBalance = get(this.props.userBalance, 'active_balance', 0)
    // const diffMonth = moment(calculatedDate).format('YYYY-MM') < moment(this.props.date).format('YYYY-MM')
    //   ? moment(this.props.date).startOf('month').diff(moment(calculatedDate).startOf('month'), 'month')
    //   : 0
    const diffMonth = moment(this.props.date).startOf('month').diff(moment(calculatedDate).startOf('month'), 'month')

    const userLeavesMonth = helper._leave.getLeaves(this.props.date, this.props.userLeaves, 'YYYY-MM') // this.props.userLeaves.filter(userLeave => moment(userLeave.start_date).format('YYYY-MM') === moment(this.props.date).format('YYYY-MM'))
    const totalWaiting = userLeavesMonth.filter(item => item.status === 'pending').reduce((acc, val) => acc + helper._leave.nums(val, this.props.date), 0)
    const totalConfirmed = userLeavesMonth.filter(item => item.status === 'approved').length

    // const totalLeaveAllowance = this.props.templateLeave.allowance_leave,
      // totalWaiting = this.props.userLeaves.filter(item => item.status === 'pending').length,
      // totalConfirmed = this.props.userLeaves.filter(item => item.status === 'approved').length,
      // totalRemain = totalLeaveAllowance - totalConfirmed - totalWaiting

    // const totalLeaveAllowance = 5,
    //   totalWaiting = 1,
    //   totalConfirmed = 2,
    //   totalRemain = totalLeaveAllowance - totalConfirmed - totalWaiting

    return (
      <div>
        <Row justify='space-between' style={{ textAlign: 'center' }}>
          <Col
            className={classnames('daily-checkin-summary', {
              'has-photos': !!todayCheckin,
              'unvalidated': todayCheckin && todayCheckin.status !== 'validated'
            })}
            onClick={() => this.showPhotos(todayCheckin, checkinAttendances)}
          >
            <div style={{ padding: '12px' }}>
              <div style={{ fontSize: '28px', fontWeight: 500 }}>
                {/* {this.getAttendanceTime(todayCheckin)} */}
                {totalWaiting || '-'}
              </div>
              <div className='subtitle' style={{ fontSize: '16px' }}>
                Waiting Approval
              </div>
            </div>
          </Col>
          <Col
            className={classnames('daily-checkin-summary', {
              'has-photos': !!todayCheckout,
              'unvalidated': todayCheckout && todayCheckout.status !== 'validated'
            })}
            onClick={() => this.showPhotos(todayCheckout, checkoutAttendances)}
          >
            <div style={{ padding: '12px' }}>
              <div style={{ fontSize: '28px', fontWeight: 500 }}>
                {/* {this.getAttendanceTime(todayCheckout)} */}
                {totalConfirmed || '-'}
              </div>
              <div className='subtitle' style={{ fontSize: '16px' }}>
                Approved
              </div>
            </div>
          </Col>
          <Col className='daily-checkin-summary'>
            <div style={{ padding: '12px' }}>
              <div style={{ fontSize: '28px', fontWeight: 500 }}>
                {/* {diffMonth < 0 ? '-' : (activeBalance + (diffMonth * 1.25)) || '-'} */}
                {helper._leave.getLeaveBalance(this.props.userBalance, this.props.date) || '-'}
                {/* {(activeBalance + (diffMonth * 1.25)) || '-'} */}
              </div>
              <div className='subtitle' style={{ fontSize: '16px' }}>
                Remaining
              </div>
            </div>
          </Col>
        </Row>
        {/* {this.renderTotalBar((totalWaiting + totalConfirmed) / totalLeaveAllowance)} */}
        {this.renderTotalBar(Math.min((totalWaiting + totalConfirmed) / (activeBalance + (totalWaiting + totalConfirmed)), 1))}
      </div>
    )
  }
}

SubmissionAnnualLeaveHeader.propTypes = {
  ...SubmissionAnnualLeaveHeader.propTypes,
  userLeaves: PropTypes.array,
  userBalance: PropTypes.object,
}

SubmissionAnnualLeaveHeader.defaultProps = {
  ...SubmissionAnnualLeaveHeader.defaultProps,
  userLeaves: [],
  userBalance: null,
}

export default SubmissionAnnualLeaveHeader