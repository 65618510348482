import { isArray, isEmpty, isObject } from 'lodash'

export default function getAllMessage(obj) {
  let str = null;
  if (!isEmpty(obj)) {
    if (isArray(obj)) {
      str = [];
      for (let i = 0; i < obj.length; i++) str.push(getAllMessage(obj[i]));
      str = str.join('\n');
    } else if (isObject(obj)) {
      str = [];
      for (let key in obj) str.push(`${key}: \n${getAllMessage(obj[key])}`);
      str = str.join('\n');
    } else {
      str = obj;
    }
    return str;
  } else {
    return ''
  }
}