import React from 'react'
import PropTypes from 'prop-types'
import { Card, Col, Row, Spin } from 'antd'
import { DesktopOutlined } from '@ant-design/icons'
import classnames from 'classnames'
import moment from 'moment'

import { BaseListActivity } from 'src/components'
import { lib } from 'src/Utils'

let types = []

class DashboardSummary extends BaseListActivity {
  constructor(props) {
    super(props)

    this.init()
    this.keysQueryParam.push(...['id'])

    this.myProfile = lib.ls.getCurrentUser()

    this.setRangeDate({
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().endOf('month').format('YYYY-MM-DD'),
    })

    this.state = {
      ...this.state,
      isLoading: true,
    }
  }

  init = () => {
    types = [
      {
        key: 'today',
        label: 'Today',
        time: '-',
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
      },
      {
        key: 'this-week',
        label: 'This Week',
        time: '-',
        start_date: moment().startOf('week').format('YYYY-MM-DD'),
        end_date: moment().endOf('week').format('YYYY-MM-DD'),
      },
      {
        key: 'last-week',
        label: 'Last Week',
        time: '-',
        start_date: moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'),
        end_date: moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD'),
      },
      {
        key: 'this-month',
        label: 'This Month',
        time: '-',
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
      },
    ]
  }

  // pengganti read failed
  setCancel = (cancel) => {
    this.cancelToken = cancel

    this.props.setUserIsActive(false)
  }

  convertResponseData = (responseData) => {
    let userActivity = this.props.userId
      ? responseData.find(user => `${user.id}` === `${this.props.userId}`)
      : responseData.find(user => user.email === this.myProfile.email)

    if (!userActivity) {
      this.props.setCurrentUser(null)
      return
    }

    this.props.setCurrentUser(userActivity)

    this.setTotalToday(userActivity.activities)

    this.setTotalThisWeek(userActivity.activities)

    this.setTotalLastWeek(userActivity.activities)

    this.setTotalMonth(userActivity.activities)

    // const finishTime = get(userActivity.activities.slice(-1), [0, 'finish_time'])
    // const isActive = finishTime ? moment.duration(moment().diff(moment(finishTime))).asMinutes() <= 5 : false
    this.props.setUserIsActive(lib.activity.getIsActive(userActivity.activities))

    return responseData
  }

  setTotalToday = (activities) => {
    const startDate = moment().format('YYYY-MM-DD')
    const endDate = startDate
    let totalMs = 0
    for (const i in activities) {
      if (moment(activities[i].start_time).format('YYYY-MM-DD') >= startDate && moment(activities[i].finish_time).format('YYYY-MM-DD') <= endDate) {
        totalMs += moment(activities[i].finish_time).diff(moment(activities[i].start_time))
      }
    }
    types[0].time = lib.time.getLabel(totalMs)
  }

  setTotalThisWeek = (activities) => {
    const startDate = moment().startOf('week').format('YYYY-MM-DD')
    const endDate = moment().endOf('week').format('YYYY-MM-DD')
    let totalMs = 0
    for (const i in activities) {
      if (moment(activities[i].start_time).format('YYYY-MM-DD') >= startDate && moment(activities[i].finish_time).format('YYYY-MM-DD') <= endDate) {
        totalMs += moment(activities[i].finish_time).diff(moment(activities[i].start_time))
      }
    }
    types[1].time = lib.time.getLabel(totalMs)
  }

  setTotalLastWeek = (activities) => {
    const startDate = moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD')
    const endDate = moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD')
    let totalMs = 0
    for (const i in activities) {
      if (moment(activities[i].start_time).format('YYYY-MM-DD') >= startDate && moment(activities[i].finish_time).format('YYYY-MM-DD') <= endDate) {
        totalMs += moment(activities[i].finish_time).diff(moment(activities[i].start_time))
      }
    }
    types[2].time = lib.time.getLabel(totalMs)
  }

  setTotalMonth = (activities) => {
    let totalMs = 0
    for (const i in activities) {
      totalMs += moment(activities[i].finish_time).diff(moment(activities[i].start_time))
    }
    types[3].time = lib.time.getLabel(totalMs)
  }

  changeToDefaultFilter = () => {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    // const currentParam = lib.getObjParam(this.props.location.search)
    // const nextParam = lib.getObjParam(nextProps.location.search)
    if (this.props.userId !== nextProps.userId) {
      this.id = nextProps.userId
      this.forceRead()
    }
  }

  didMount = () => {
    this.forceRead()

    this._intervalList = setInterval(this.forceRead, 1000 * 60 * 4) // every 4 minutes
  }

  unmount = () => {
    clearInterval(this._intervalList)
    this.cancel()
  }

  render() {
    // "this.id" digunakan untuk request activity yang di ambil dari keysQueryParam
    this.id = this.props.userId

    return (
      <Spin spinning={this.state.isLoading}>
        <Row gutter={[12, 12]}>
          {types.map((val, i) => 
            <Col key={val.key} xs={24} md={12} xl={6}>
              <Card
                className={classnames(
                  'app-card app-card-selection',
                  { active: this.props.startDate === val.start_date && this.props.endDate === val.end_date }
                )}
                onClick={() => this.props.onChangeFilter({ start_date: val.start_date, end_date: val.end_date })}
              >
                <Row justify='space-between' align='middle' gutter={[12, 12]}>
                  <Col xs={24} md={18} style={{ minWidth: '1px', textAlign: 'center' }}>
                    <div style={{ fontSize: '24px', fontWeight: 500 }}>
                      {val.time}
                    </div>
                    <div className='subtitle' style={{ fontSize: '16px', lineHeight: '20px' }}>
                      Total {val.label}
                    </div>
                  </Col>
                  <Col xs={0} md={6} style={{ textAlign: 'center' }}>
                    <DesktopOutlined style={{ fontSize: '32px' }} />
                  </Col>
                </Row>
              </Card>
            </Col>
          )}
        </Row>
      </Spin>
    )
  }
}

DashboardSummary.propTypes = {
  setCurrentUser: PropTypes.func,
  setUserIsActive: PropTypes.func,
}

DashboardSummary.defaultProps = {
  setCurrentUser: () => {},
  setUserIsActive: () => {},
}

export default DashboardSummary