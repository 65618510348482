import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { CommentOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import moment from 'moment';

// import BaseContentCalendar from 'src/pages/DashboardApp/Attendance/Daily/Content/Calendar';
import BaseContentCalendar from 'src/pages/DashboardApp/Submission/AnnualLeave/Content/Calendar';
// import leaveHelper from 'src/pages/DashboardApp/Submission/AnnualLeave/helper';
// import example from 'src/pages/DashboardApp/Submission/example';
import _h from 'src/pages/DashboardApp/Submission/helper';

class ContentCalendar extends BaseContentCalendar {
  renderCellDate = (date) => {
    const { userAttendances } = this.props
    const currentAttendances = userAttendances
      // .filter(att => att.status === 'validated')
      .filter(att => moment(att.attendance_date).format('YYYY-MM-DD') === date.format('YYYY-MM-DD'))

    const isPresent = currentAttendances.length > 0

    const checkinAttendances = currentAttendances.filter(att => att.attend_type === 'checkin')
    const checkoutAttendances = currentAttendances.filter(att => att.attend_type === 'checkout')

    const isPresentIn = checkinAttendances.find(att => att.status === 'validated')
    const isPresentOut = checkoutAttendances.find(att => att.status === 'validated')

    const currentMonth = date.format('YYYY-MM') === moment(this.props.date).format('YYYY-MM')

    const currentOvertimes = this.props.userOvertimes.filter(userOvertime => moment(userOvertime.date).format('YYYY-MM-DD') === date.format('YYYY-MM-DD'))
    const currentOvertime = currentOvertimes[currentOvertimes.length - 1]
    const duration = currentOvertime && moment.duration(moment(currentOvertime.finish_time, 'HH:mm').diff(moment(currentOvertime.start_time, 'HH:mm')))


    const currentLeave = this.props.userOvertimes.find(userOvertime => moment(userOvertime.date).format('YYYY-MM-DD') === date.format('YYYY-MM-DD'))
    const currentActivities = this.props.userActivities.filter(userActivity => moment(userActivity.start_time).format('YYYY-MM-DD') === date.format('YYYY-MM-DD'))
    const totalDiff = currentActivities.reduce((acc, val) => acc + moment(val.finish_time).diff(moment(val.start_time)), 0)
    const isVerifiedOvertime = currentLeave &&
      totalDiff >= moment(currentLeave.finish_time).diff(moment(currentLeave.start_time))

    return (
      <div
        className={classnames('ant-picker-cell-inner ant-picker-calendar-date', {
          'ant-picker-calendar-date-today': currentMonth && date.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'),
          'leave-approved': currentOvertime && ['approved'].includes(currentOvertime.status), // && isVerifiedOvertime,
          'leave-waiting': currentOvertime && ['pending'].includes(currentOvertime.status),
          'leave-rejected': currentOvertime && ['rejected', 'cancelled'].includes(currentOvertime.status), // && !isVerifiedOvertime,
          // 'date-present-warning': true,
          // 'date-present-success': isPresent,
        })}
      >
        <div className='ant-picker-calendar-date-value'>
          {date.format('DD')}
        </div>
        <div className='ant-picker-calendar-date-content' style={{ overflowY: 'hidden' }}>
          <div className='date-content-leave' style={{ opacity: currentMonth ? 1 : 0.5 }}>
            {currentOvertime && (
              <div style={{ marginTop: '-4px' }}>
                <div className="text-truncate" title={`${currentOvertime.start_time} - ${currentOvertime.finish_time}`}>
                  {_h._overtime.renderDurationByItem(currentOvertime)}
                  {/* {duration.hours() > 0 && `${duration.hours()} Hours`}
                  {duration.minutes() > 0 && `, ${duration.minutes()} Minutes`} */}
                </div>
                <div className="webkit-box two-lines" style={{ fontSize: '11px' }} title={currentLeave.note}>
                  <CommentOutlined style={{ marginRight: '4px' }} />
                  {currentOvertime.note}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

ContentCalendar.propTypes = {
  ...ContentCalendar.propTypes,
  userOvertimes: PropTypes.array,
  userActivities: PropTypes.array,
}

ContentCalendar.defaultProps = {
  ...ContentCalendar.defaultProps,
  userOvertimes: [],
  userActivities: [],
}

export default ContentCalendar