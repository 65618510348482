import TemplateCard from './TemplateCard'
import { lib } from 'src/Utils'

class TopUsageTask extends TemplateCard {
  constructor(props) {
    super(props)

    this.header = {
      title: 'Most Task Usage',
      link: '/report/time-use/',
      labelLink: 'Time Use Report',
    }
  }

  convertData = (activities) => {
    this.setThisState({ data: this.getTopTasks(activities) })
  }

  getTopTasks = (activities) => {
    const objTopTasks = {}
    for (const i in activities) {
      if (!objTopTasks[activities[i].task.alias])
        objTopTasks[activities[i].task.alias] = { ...activities[i].task, key: activities[i].task.alias, total: 0 }

      objTopTasks[activities[i].task.alias].total += lib.time.getDiff(activities[i])
    }

    const topTasks = []
    for (const key in objTopTasks) {
      topTasks.push(objTopTasks[key])
    }

    topTasks.sort((a, b) => b.total - a.total)

    return topTasks.filter((_, i) => i < 5)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.activities !== nextProps.activities) {
      this.convertData(nextProps.activities)
    }
  }

  // componentDidMount() {
  //   this.setMounted()

  //   this.convertData(this.props.activities)
  // }
}

export default TopUsageTask