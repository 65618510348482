import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col, DatePicker, Dropdown, Menu, Radio, Row, Spin, Table, Tooltip } from 'antd'
import { CaretLeftOutlined, CaretRightOutlined, DownOutlined, PartitionOutlined, QuestionCircleOutlined, ReloadOutlined } from '@ant-design/icons'
import moment from 'moment'

import { BaseList } from 'src/components'
import ProductionDetailTable from './Table'
import { lib } from 'src/Utils'

const KEY_NO_GROUP = 'no_group'
const KEY_GROUP_TAKEOFF = 'takeoff'
const KEY_GROUP_QS = 'qs'
const KEY_GROUP_QS_DUE = 'qs_due'
const KEY_GROUP_RATING = 'rating'

class ProductionDetailReport extends BaseList {
  constructor(props) {
    super(props)

    this.availablePickerMode = ['month', 'year']

    this.urlKey = 'read-productionJobs' // 'read-productionJobsQS'
    this.pagination = false

    this.myProfile = lib.ls.getCurrentUser()
    this.selectedDate = moment().format()
    this.status = 'finished'

    this.keysQueryParam = ['finished_date_after', 'finished_date_before', 'status']

    this.groupOpts = [
      { key: KEY_NO_GROUP, label: '-' },
      { key: KEY_GROUP_TAKEOFF, label: 'Takeoff', icon: <PartitionOutlined /> },
      { key: KEY_GROUP_QS, label: 'QS', icon: <PartitionOutlined /> },
      { key: KEY_GROUP_QS_DUE, label: 'QS Due', icon: <PartitionOutlined /> },
      { key: KEY_GROUP_RATING, label: 'Rating', icon: <PartitionOutlined /> },
    ]

    this.state = {
      ...this.state,
      keyGrouping: this.groupOpts[0].key,
      pickerMode: this.availablePickerMode[0],
    }
  }

  onRefTable = (ref) => this._table = ref

  isValidParam = (key) => true

  getParam = (key, val) => {
    if (key === 'finished_date_after')
      return moment(this.selectedDate).startOf(this.state.pickerMode).format()

    if (key === 'finished_date_before')
      return moment(this.selectedDate).endOf(this.state.pickerMode).format()

    return val
  }

  getGroupingMenu = () => (
    <Menu onClick={this.onGroupClick}>
      {this.groupOpts.map(group => (
        <Menu.Item key={group.key} icon={group.icon}>
          {group.label}
        </Menu.Item>
      ))}
    </Menu>
  )

  onGroupClick = ({ key }) => {
    if (this._table)
      this.setState({ keyGrouping: key }, () => this._table.setGroupedJobs(key))
  }

  render() {
    return this.state.errorResponse ? this.getErrorComp() : (
      <div style={{ padding: '24px' }}>
        <Row justify='space-between' gutter={[6, 6]} style={{ marginBottom: '12px' }}>
          <Col>
            <h2 style={{ marginBottom: 0 }}>
              Production Detail Report
            </h2>
          </Col>
          <Col>
            <Row gutter={[6, 6]}>
              <Col>
                <Button loading={this.state.isLoading} icon={<ReloadOutlined />} onClick={this.forceRead}>
                  Refresh
                </Button>
              </Col>
              <Col>
                <Dropdown
                  disabled={this.state.isLoading}
                  placement='bottomRight'
                  trigger={['click']}
                  overlay={this.getGroupingMenu()}
                >
                  <Button block loading={this.state.downloading} icon={<DownOutlined />} style={{ minWidth: '180px', textAlign: 'left' }}>
                    Grouping by<b> {this.groupOpts.find(opt => opt.key === this.state.keyGrouping).label}</b>
                  </Button>
                </Dropdown>
              </Col>
              <Col style={{ flex: 1, minWidth: '175px' }}>
                <Row>
                  <Col>
                    <Button
                      disabled={this.state.isLoading}
                      icon={<CaretLeftOutlined />}
                      onClick={() => {
                        this.selectedDate = moment(this.selectedDate).subtract(1, this.state.pickerMode).format()
                        this.read()
                      }}
                    />
                  </Col>
                  <Col style={{ flex: 1 }}>
                    <DatePicker
                      disabled={this.state.isLoading}
                      picker={this.state.pickerMode}
                      allowClear={false}
                      value={moment(this.selectedDate)}
                      style={{ margin: '0 -1px', width: 'calc(100% + 2px)' }}
                      onChange={(date, dateString) => {
                        this.selectedDate = dateString
                        this.read()
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      disabled={this.state.isLoading}
                      icon={<CaretRightOutlined />}
                      onClick={() => {
                        this.selectedDate = moment(this.selectedDate).add(1, this.state.pickerMode).format()
                        this.read()
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Spin spinning={this.state.isLoading}>
          <div className='app-card'>
            <ProductionDetailTable
              ref={this.onRefTable}
              productionJobs={this.__list}
            />
          </div>
        </Spin>
      </div>
    )
  }
}

ProductionDetailReport.propTypes = {
  ...ProductionDetailReport.propTypes,
}

ProductionDetailReport.defaultProps = {
  ...ProductionDetailReport.defaultProps,
}

export default ProductionDetailReport