// import modules from './staticModules'
import permissions from './staticPermissions'
import { lib } from 'src/Utils'

function canApproveOvertime(currentUser) {
  const user = currentUser || lib.ls.getCurrentUser()

  return (
    user.is_owner ||
    user.permissions.findIndex(perm => perm === permissions.APPROVAL_OVERTIME) !== -1
  )
}

export default canApproveOvertime