import PropTypes from 'prop-types';
import { Button, Col, Modal, Row, Spin, message } from 'antd';
import { AuditOutlined, ReloadOutlined } from '@ant-design/icons';
import { get, orderBy } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { VisibilityContainer } from 'src/components';
import AttendanceDaily from '../../Attendance/Daily';
import SubmissionAnnualLeaveHeader from './Header';
import SubmissionAnnualLeaveContent from './Content';
import DialogLeaveDetail from './DialogLeaveDetail';
import AllSubmissions from './AllSubmissions';
import helper from '../helper';
// import example from '../example';
import { lib, permissions, request } from 'src/Utils';

class SubmissionAnnualLeave extends AttendanceDaily {
  constructor(props) {
    super(props)

    window._leave = helper
    window._moment = moment

    this.title = 'Apply Leave'
    this.urlKey = 'leave-user-leaves'
    this.view_mode = 'calendar'
    this.related = 'user'
    this.source_type = 'user_apply'

    this.keysQueryParam.push('view_mode', 'related', 'source_type')

    this.state = {
      ...this.state,
      userBalance: null,
    }
  }

  getIsApproval = () => permissions.canApproveLeave()

  convertResponseData = (userLeaves) => {
    request({
      urlKey: 'leave-user-balances',
      data: { user: this.user },
      onSuccess: res => {
        this.setState({ userBalance: res.data[0] })
      },
      onFailed: error => {
        lib.responseMessage.error(error.response)
      },
    })

    return orderBy(userLeaves, 'created')
  }

  onRefDialogLeaveDetail = (ref) => this._dialogLeaveDetail = ref

  setDate = (date) => {
    const prevMonth = moment(this.date).format('YYYY-MM')
    this.date = moment(date).format()

    if (moment(date).format('YYYY-MM') !== prevMonth)
      this.forceRead()
    else {
      this.forceUpdate()

      const userLeaves = helper._leave.getLeaves(date, this.__list)
      const isAllowedDate = helper._leave.allowSubmission(date, userLeaves)
      const isOwn = this.user === this.myProfile.id

      if (((this.myProfile.is_owner || isOwn) && isAllowedDate) || userLeaves.length) {
        // if (this.user === this.myProfile.id && !helper.leave.allowSubmission(date, userLeaves))
        //   return message.info('Cannot apply for leave')

        const totalAllowance = Infinity // example.getTotalAllowanceLeave(this.user, this.date)
        if (totalAllowance > this.__list.length)
          this._dialogLeaveDetail.show({ defaultDate: date, userLeaves })
        // else
        //   message.info('Your leave entitlement for this month is vacant')
      } else if (!isOwn) {
        message.warning(`Cannot apply for another user`)
      } else {
        message.warning(`Cannot apply for leave on ${moment(date).format('DD MMMM YYYY')}`)
      }
    }
  }

  savedLeaveHandler = (userLeave) => {
    this.patchList([userLeave], 'pk')
  }

  acceptHandler = (userLeave) => {
    this.patchUserLeave(userLeave, { status: 'approved', confirmed_date: moment().format(), confirmed_by: this.myProfile })
  }

  rejectHandler = (userLeave) => {
    this.patchUserLeave(userLeave, { status: 'rejected', confirmed_date: moment().format(), confirmed_by: this.myProfile })
  }

  patchUserLeave = (userLeave, patchData) => {
    const foundIndex = this.__list.findIndex(item => item.alias === userLeave.alias)
    if (foundIndex !== -1) {
      this.patchList([{ ...this.__list[foundIndex], ...patchData }], 'pk')
      if (this._dialogLeaveDetail) this._dialogLeaveDetail.hide(null, undefined, true)

      // this.state.exampleUserLeaves[foundIndex] = { ...this.state.exampleUserLeaves[foundIndex], ...patchData }
      // this.setState({ exampleUserLeaves: [...this.state.exampleUserLeaves] }, () => {
      //   if (this._dialogLeaveDetail) this._dialogLeaveDetail.hide(null, undefined, true)
      // })
    }
  }

  pushParams = (newParams, type = 'push') => {
    const allowedTypes = ['replace', 'push']
    if (!allowedTypes.includes(type))
      type = allowedTypes[0]

    this.props.history[type](this.props.location.pathname + lib.getStrParam({
      ...lib.getObjParam(this.props.location.search),
      ...newParams,
    }))
  }

  showAllUsers = () => {
    this.pushParams({ type: 'all' })
  }

  renderRightContent() {
    return (
      <Row align='middle' gutter={[6, 6]}>
        <Col>
          <Button loading={this.state.isLoading} icon={<ReloadOutlined />} onClick={this.forceRead}>
            Reload
          </Button>
        </Col>
        {this.getIsApproval() && (
          <Col>
            <Button icon={<AuditOutlined />} onClick={this.showAllUsers}>
              Approval
            </Button>
          </Col>
        )}
      </Row>
    )
  }

  // loadAllMembers = () => ({ members: example.users })

  getDefaultSelectedUser = (paramUser) => {
    let selectedUser = this.users.find(user => `${user.id}` === paramUser)

    if (!selectedUser)
      // selectedUser = this.users.find(user => user.id === 99)
      selectedUser = this.users.find(user => user.email === this.myProfile.email)

    if (!selectedUser && this.users.length)
      selectedUser = this.users[0]

    return selectedUser
  }

  // convertResponseData = () => {
  //   console.log('convertResponseData', this._selectedUser)
  //   // window._helper = helper
  //   return orderBy(example.getUserLeaves(this.user, this.date), 'date')
  // }

  renderHeader() {
    return (
      <SubmissionAnnualLeaveHeader
        user={this._selectedUser}
        users={this.users} // this value for listening read activity, be careful
        attendances={this.__list}
        date={this.date}
        userBalance={this.state.userBalance}
        // templateLeave={moment(this.date).format('YYYY-MM') > moment().format('YYYY-MM')
        //   ? { ...this.state.exampleProfileCurrentMonth, allowance_leave: this.state.exampleProfileCurrentMonth.allowance_leave - list.filter(userLeave => userLeave.status !== 'rejected').length }
        //   : {
        //     ...this.state.exampleProfileCurrentMonth,
        //     allowance_leave: this.state.exampleProfileCurrentMonth.allowance_leave - Math.round(moment.duration(moment().startOf('month').diff(moment(this.date).startOf('month'))).asMonths())
        //   }}
        // templateLeave={{ allowance_leave: example.getTotalAllowanceLeave(this.user, this.date) }}

        userLeaves={this.__list}
        onSelectUser={this.onChangeUser}
      />
    )
  }

  renderDialogs =  () => (
    <DialogLeaveDetail
      ref={this.onRefDialogLeaveDetail}
      user={this._selectedUser}
      onSavedLeave={this.savedLeaveHandler}
      onChangeLeave={this.changedLeaveHandler}
      // onAccept={this.acceptHandler}
      // onReject={this.rejectHandler}
    />
  )

  renderContent() {
    return (
      <div>
        {this.renderDialogs()}

        <SubmissionAnnualLeaveContent
          user={this._selectedUser}
          userAttendances={this.__list}
          date={this.date}
          // userLeaves={this.state.exampleUserLeaves.filter(item => moment(item.date).format('YYYY-MM') === moment(this.date).format('YYYY-MM'))}
          userLeaves={this.__list}
          setDate={this.setDate}
        />
      </div>
    )
  }

  getVisibleContainer = () => get(lib.getObjParam(this.props.location.search), 'type', 'calendar') === 'calendar'

  renderAnotherContainers() {
    return (
      <div>
        <VisibilityContainer visible={get(lib.getObjParam(this.props.location.search), 'type') === 'all'}>
          <AllSubmissions
            onClose={() => {
              this.forceRead()
              this.pushParams({ type: 'calendar' })
            }}
          />
        </VisibilityContainer>
      </div>
    )
  }
}

SubmissionAnnualLeave.propTypes = {
  ...SubmissionAnnualLeave.propTypes,
}

SubmissionAnnualLeave.defaultProps = {
  ...SubmissionAnnualLeave.defaultProps,
}

export default SubmissionAnnualLeave