import React from 'react'
import { Button, Card, Col, DatePicker, Divider, Empty, Input, Popover, Progress, Row, Spin } from 'antd'
import { ReloadOutlined, SearchOutlined, UserOutlined, RobotOutlined, SelectOutlined } from '@ant-design/icons'
import moment from 'moment'
import { get, groupBy } from 'lodash'

import { BaseListActivity } from 'src/components'
import { lib, request } from 'src/Utils'
import DetailScreenshot from './DetailScreenshot'
import Avatar from 'antd/lib/avatar/avatar'
import classNames from 'classnames'
import getFullName from 'src/Utils/internal/getFullName'
import Image from './Image'

const { RangePicker } = DatePicker

class ScreenshotsPage extends BaseListActivity {
  constructor(props) {
    super(props)

    this.users = []
    this.pageUser = 1
    this.limitUser = 100
    this.nextPageUser = null

    this.keysQueryParam.push(...['id'])
    this.obj = {}
    this.arr = []
    this.screenshots = []
    this.fullActivity = {
      click: 100,
      type: 200,
    }

    this.myProfile = lib.ls.getCurrentUser()

    this.state = {
      ...this.state,
      showData: [],
      loading: false,
      selectedUser: null,
      searchValue: '',
    }
  }

  cancelAllRequest = () => {
    this.cancelUsers()
    this.cancel()
  }

  beforeRead = async (keyLoading, callback) => {
    return new Promise((resolve) => {
      this.pageUser = 1
      this.users = []
      this.readUsers(keyLoading, resolve, callback)
    })
  }

  readUsers = (keyLoading = 'isLoading', resolve = () => {}, callback) => {
    delete this.id

    this.cancelAllRequest()
    this.setThisState({ [keyLoading]: true }, () => {
      request({
        urlKey: 'read-readableUsersScreenshot',
        data: { page: this.pageUser, limit: this.limitUser },
        extra: { resolve, callback },
        getCancel: this.setCancelUsers,
        onSuccess: this.readUsersSuccess,
        onFailed: this.readUsersFailed,
      })
    })
  }

  setCancelUsers = (cancel) => {
    this.cancelTokenUsers = cancel
  }

  cancelUsers = () => {
    if (typeof this.cancelTokenUsers === 'function') {
      this.cancelTokenUsers()
    }
  }

  readUsersSuccess = (response, extra) => {
    this.nextPageUser = response.data.next

    this.users = lib.sorter.byArray(this.users.concat(response.data.results), 'first_name')
    const myUserIndex = this.users.findIndex(user => user.id === this.myProfile.id)
    if (myUserIndex !== -1) {
      const myUser = this.users.splice(myUserIndex, 1)[0]
      this.users.unshift(myUser)
    }

    if (response.data.next) {
      this.pageUser += 1
      this.readUsers(undefined, extra.resolve)
    } else {
      let user = null
      if (!this.state.selectedUser) {
        user = { id: lib.getObjParam(this.props.location.search).id }
        if (!user.id)
          user = this.users.find(user => user.email === this.myProfile.email)
      } else {
        user = this.users.find(user => user.alias === this.state.selectedUser)
      }

      if (user) this.id = user.id
      else if (response.data.results.length) {
        this.id = response.data.results[0].id
      }

      extra.resolve()
    }
  }

  readUsersFailed = (error, extra) => {
    lib.responseMessage.error(error.response)

    this.setThisState({ isLoading: false, errorResponse: error.response })
    extra.resolve(true)
    if (extra.callback) extra.callback()
  }

  readSuccess = (response, extra) => {
    let userActivity = response.data.find(user => user.alias === this.state.selectedUser)
    if (!userActivity) {
      userActivity = response.data[0]
    }

    this.splitByHours(userActivity.activities)

    this.setObj(userActivity.activities)

    this.setScreenshots(userActivity.activities)

    this.setThisState({
      [extra.keyLoading]: false,
      errorResponse: null,
      responseData: response.data,
      selectedUser: userActivity.alias,
    })
  }

  splitByHours = (activities) => {
    for (const i in activities) {
      const nextHour = moment(activities[i].start_time).endOf('hours').add(1, 'seconds').format()
      if (moment(activities[i].finish_time).format() > nextHour) {
        const temp = activities[i].finish_time
        activities[i].finish_time = nextHour // ini sudah berubah timezone
        activities.splice(i + 1, 0, {
          ...activities[i],
          start_time: nextHour,
          finish_time: temp,
          screenshots: [],
        })
      }
    }
  }

  setObj = (activities) => {
    const obj = {}
    for (const i in activities) {
      const date = moment(activities[i].start_time).format('YYYY-MM-DD')
      const time = moment(activities[i].start_time).format('HH[:00]')

      if (!obj[date]) obj[date] = {}

      if (!obj[date][time]) obj[date][time] = {}

      obj[date][time][activities[i].alias] = activities[i]
    }

    this.arr = []
    for (const key in obj) {
      this.arr.push({ key, objHours: obj[key] })
    }
    
    this.arr.sort((a, b) => a.key > b.key ? -1 : 1)

    this.setThisState({ showData: this.arr.slice(0, 1)})
  }

  setScreenshots = (activities) => {
    this.screenshots = []
    for (let i = 0; i < activities.length; i += 1) {
      for (let j = 0; j < activities[i].screenshots.length; j += 1) {
        this.screenshots.push({
          ...activities[i].screenshots[j],
          activity: activities[i],
        })
      }
    }
  }

  renderScreenshots = () => {
    const { showData } = this.state
    return showData.length ? showData.map(obj => (
      <div key={obj.key} style={{ margin: '12px 0' }}>
        <Row justify='center' style={{ marginBottom: '6px' }}>
          {moment(obj.key).format('MMMM D, YYYY')}
        </Row>
        <Card className='app-card'>
          {Object.keys(obj.objHours).sort((a, b) => a > b ? -1 : 1).map(time => (
            <div key={time} style={{ margin: '12px 0' }}>
              <div style={{ fontSize: '21px', fontWeight: 500 }}>
                {time}
              </div>
              <Row style={{ position: 'relative', height: '16px', backgroundColor: '#f0f2f5', boxShadow: 'inset 0px 0px 0px 1px #e8e8e8', borderRadius: '6px', overflow: 'hidden' }}>
                {this.renderBar(obj.objHours[time])}
              </Row>
              <div style={{ marginTop: '6px' }}>
                <Row gutter={[12, 12]}>
                  {this.renderImages(obj.objHours[time])}
                </Row>
              </div>
            </div>
          ))}
        </Card>
      </div>
    )) : (
      <Card className='app-card'>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={(
            <div>
              No Screenshots
              {get(this.__list, [0])
                ? <span> for <b>{getFullName(this.__list[0])}</b></span>
                : ''
              }
            </div>
          )}
        />
      </Card>
    )
  }

  renderContent = (data) =>{
    return(
      <div>
        {/* <div>{task.name}</div> */}
        <b>Time</b>
        <div style={{ marginBottom: '2px' }}>{moment(data.start_time).format('HH:mm')} - {moment(data.finish_time).format('HH:mm')}</div>
          <Divider style={{ marginBottom: '2px', marginTop: '2px' }} />
        <b>Activity</b>
        <div style={{ margin: '5px' }}>
          <Progress 
            width={80} 
            style={{ margin: '2px' }} 
            type="circle" 
            percent={data.click_count*100/this.fullActivity.click} 
            format={()=><span style={{ fontSize: '12px', fontWeight: 'bold', margin: '3px', color: '#00000073' }}>{data.click_count} clicks</span>} 
          />
          <Progress 
            width={80} 
            style={{ margin: '2px' }} 
            type="circle" 
            percent={data.type_count*100/this.fullActivity.type} 
            format={() => <span style={{ fontSize: '12px', fontWeight: 'bold', margin: '3px', color: '#00000073' }}>{data.type_count} types</span>} 
          />
        </div>
      </div>
    )
  }

  renderBar = (objActivities) => {
    return Object.keys(objActivities).map(activityId => {

      const startTime = moment(moment(objActivities[activityId].start_time).format('HH:mm:ss'), 'HH:mm:ss')
      const left = startTime.diff(moment(moment(objActivities[activityId].start_time).format('HH[:00:00]'), 'HH:mm:ss')) / 1000 / 3600 * 100

      let width = moment(objActivities[activityId].finish_time).diff(moment(objActivities[activityId].start_time)) / 1000 / 3600 * 100
      if (width < 0.5) width = 0.5

      return (
        <Popover
          key={activityId}
          // title={`Activity`}
          content={this.renderContent(objActivities[activityId])}
        >
          <Col
            className={activityId}
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: `${left}%`,
              width: `${width}%`,
              backgroundColor: objActivities[activityId].is_manual_time ? 'orange' : '#1890ff',
              // border: '1px solid #f00',
              boxShadow: `inset 0px 0px 0px 1px ${objActivities[activityId].is_manual_time ? '#e09100' : '#0484fb'}`,
            }}
          />
        </Popover>
    )})
  }

  objToArray=(data)=>{
    const res = []
    for(const i in data){
      res.push({
        name: i,
        data: data[i],
        is_dual: data[i].length > 1
      })
    }
    return res
  }

  renderImages = (objActivities) => {
    const screenshots = []
    for (const key of Object.keys(objActivities)) {
      for (const i in objActivities[key].screenshots) {
        screenshots.push({
          ...objActivities[key].screenshots[i],
          task: objActivities[key].task,
          activity: objActivities[key],
        })
      }
    }
    screenshots.sort((a, b) => a.actual_created > b.actual_created ? -1 : 1)
    const g = groupBy(screenshots, (e)=> moment(e.actual_created).format('DD MM YYYY hh:mm'))
    const conveted = this.objToArray(g)
    
    return conveted.map((li,id) => (
      <Col key={id} xs={24} sm={24} md={12} xl={li.is_dual ? 6 : 4}>
      {/* <Col key={li.name} xs={24} sm={24} md={12} lg={8} xl={4} xxl={3}> */}
        <Card
          hoverable
          cover={
            <div style={{ whiteSpace: 'nowrap' }}>
              {li.data.map((e,i)=>
                ( 
                  <span key={i}>
                    <Image
                      alt='screenshot'
                      key={e.image}
                      style={{ width: li.is_dual ? '50%' : '100%' }}
                      src={e.image}
                    />
                    { li.is_dual && <span className="bottom-text-over-image">{i+1}</span> }
                  </span>
                )
              )}
            </div>
          }
          bodyStyle={{ padding: '12px' }}
          onClick={() => this._detail.showFull({ screenshot: li.data[0], screenshots: this.screenshots })}
        >
          <Card.Meta
            title={li.data[0].task.name}
            description={
              <div style={{ fontSize: '10px' }}>
              <SelectOutlined title='clicks' /> <Progress percent={li.data[0].activity.click_count*100/this.fullActivity.click} steps={li.is_dual ? 10 : 6 } strokeColor="#52c41a" format={()=><span style={{ color: '00000073', fontSize: '10px' }}>{li.data[0].activity.click_count}</span>} />
              <br/>
              <RobotOutlined title='types' /> <Progress percent={li.data[0].activity.type_count*100/this.fullActivity.type} steps={li.is_dual ? 10 : 6 } strokeColor="#52c41a" format={()=><span style={{ color: '00000073', fontSize: '10px' }}>{li.data[0].activity.type_count}</span>} />
              <br/>
                Created at <b>{moment(li.data[0].actual_created).format('MMM DD, HH:mm a')}</b>
              </div>
            }
          />
        </Card>
      </Col>
    ))
  }

  
  autoload=()=>{
    if (this.state.showData.length !== this.arr.length && window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {
      this.loadMore(this.state.showData.length)
    }
  }

  onChangeSelectedUser = (user) => {
    // break when current selected user equal with clicked user
    if (this.state.selectedUser === user.alias)
      return

    window.stop() // stop all request

    this.id = user.id
    this.setThisState({ selectedUser: user.alias })
    this.doRead(this.keyIsLoading, () => {})

    const obj = lib.getObjParam(this.props.location.search)
    this.props.history.replace(this.props.location.pathname + lib.getStrParam({ ...obj, id: user.id }))
  }

  getDisplay = (user) => {
    if (getFullName(user).toLowerCase().indexOf(this.state.searchValue.trim().toLowerCase()) !== -1)
      return 'block'

    if (user.email.toLowerCase().indexOf(this.state.searchValue.trim().toLowerCase()) !== -1)
      return 'block'

    return 'none'
  }

  onChangeSearch = (e) => {
    clearTimeout(this._timeoutSearch)

    this._timeoutSearch = setTimeout(() => {
      this.setThisState({ searchValue: e.target.value })
    }, 350)
  }

  didMountStatements() {
    window.addEventListener('scroll', this.autoload);
  }

  unmount() {
    // cancel request activities, but request users need to handle manual
    this.cancelAllRequest()

    window.removeEventListener('scroll', this.autoload);
  }

  loadMore=(index)=>{
    const { showData } = this.state
    this.setThisState({ showData: [...showData, ...[this.arr[index]]] })
  }

  render() {
    return (
      <Spin spinning={this.state.isLoading}>
        <div style={{ padding: '24px' }}>
          <Row justify='space-between' style={{ marginBottom: '12px' }}>
            <Col>
              <h1 style={{ marginBottom: 0 }}>
                Screenshots
              </h1>
            </Col>
            <Col>
              <Row align='middle' gutter={[6, 6]}>
                {this.users.length > 1 && (
                  <Col style={{ flex: 1 }}>
                    <Input
                      placeholder='Search user...'
                      prefix={<SearchOutlined className='site-form-item-icon' />}
                      onChange={this.onChangeSearch}
                    />
                  </Col>
                )}
                <Col>
                  <Button loading={this.state.isLoading} icon={<ReloadOutlined />} onClick={this.read}>
                    Refresh
                  </Button>
                </Col>
                {/* <Col style={{ fontWeight: 500 }}>
                  Ranges Date :
                </Col> */}
                <Col className='only-xs-block'>
                  <RangePicker
                    allowClear={false}
                    format='YYYY-MM-DD'
                    ranges={{
                      'Today': [moment(), moment()],
                      'This Week': [moment().startOf('week'), moment().endOf('week')],
                      'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                    }}
                    style={{ width: '100%' }}
                    value={[moment(this.range_before), moment(this.range_after)]}
                    onChange={(dates, dateStrings) => {
                      this.tempDateStrings = dateStrings
                    }}
                    onOpenChange={(opened) => {
                      if (!opened) {
                        if (this.tempDateStrings[0] !== this.range_before || this.tempDateStrings[1] !== this.range_after)
                          this.onChangeFilter({ start_date: this.tempDateStrings[0], end_date: this.tempDateStrings[1] })
                      } else {
                        this.tempDateStrings = [this.range_before, this.range_after]
                      }
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {this.users.length > 1 && (
            <div>
              <Row gutter={[12, 12]} style={{ flexFlow: 'nowrap', overflowX: 'auto' }}>
                {this.users.map((user, i) => (
                  <Col key={i} style={{ display: this.getDisplay(user) }}>
                    <Card
                      className={classNames(
                        'app-card app-card-selection',
                        {active: this.state.selectedUser === user.alias}
                      )}
                      bodyStyle={{ padding: '12px' }}
                      style={{ cursor: 'pointer', width: '250px' }}
                      onClick={() => this.onChangeSelectedUser(user)}
                    >
                      <Row justify='center'>
                        <Avatar
                          src={user.photo}
                          icon={<UserOutlined />}
                          size={72}
                          style={{
                            color: '#fff',
                            // border: '1px solid #e8e8e8',
                            boxShadow: '0 6px 16px -8px RGB(0 0 0/8%)',
                          }}
                        />
                      </Row>
                      <div
                        className='webkit-box two-lines'
                        style={{ height: '44px', textAlign: 'center', marginTop: '12px' }}
                      >
                        {lib.getFullName(user)}
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          )}
          <div style={{ marginTop: '12px' }}>
            {this.state.errorResponse ? this.getErrorComp() : (
              this.renderScreenshots()
            )}
          </div>
          { this.state.showData.length !== this.arr.length &&
            <center>
              <Button
                loading={this.state.loading}
                onClick={()=>this.loadMore(this.state.showData.length)}
              >
                Load more ...
              </Button>
            </center>
          }
        </div>
        <DetailScreenshot
          ref={ref => {this._detail = ref}}
        />
      </Spin>
    )
  }
}

export default ScreenshotsPage