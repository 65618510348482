import { v4 as uuidv4 } from 'uuid'
import { get } from 'lodash'
import moment from 'moment'

import users from './users';

const leaveTemplate = {
  addition: [
    { effectiveDate: null, addition: 1.25 },
    { effectiveDate: '2023-06-01', addition: 1.25 },
  ],
}

const usersRemain = [
  {
    user: users.objUsers['01.Rudy'],
    date: '2023-06-01',
    remain: 12,
  },
  {
    user: users.objUsers['02.Wilhelmina'],
    date: '2023-06-01',
    remain: 15,
  },
  {
    user: users.objUsers['03.Dina'],
    date: '2023-06-01',
    remain: 6,
  },
  {
    user: users.objUsers['04.Nita'],
    date: '2023-06-01',
    remain: 11,
  },
  {
    user: users.objUsers['05.Sintia'],
    date: '2023-06-01',
    remain: 7,
  },
]

function getTotalAllowanceLeave(userId, strMonth) {
  const addition = leaveTemplate.addition.find(item => item.effectiveDate <= moment(strMonth).format('YYYY-MM-DD'))
    // || leaveTemplate.addition.find(item => !item.effectiveDate)
  const userRemain = usersRemain
    .filter(item => item.user.id === userId && item.date <= moment(strMonth).format('YYYY-MM-DD'))
    .sort((a, b) => a.date > b.date ? 1 : -1)[0]

  const remainingLeave = get(userRemain, 'remain', 0)
  const additionLeave = get(addition, 'addition', 0)
  const diffMonth = moment(strMonth).startOf('month').diff(moment().startOf('month'), 'month')

  return remainingLeave + (additionLeave * diffMonth)
}

const userLeaves = [
  {
    user: users.objUsers['01.Rudy'],
    date: '2023-06-21',
    created: '2023-06-14T08:00:00+07:00',
    type: 'holiday',
    half_day: false,
    reason: 'Holiday',
    status: 'waiting',
    confirmed_date: null,
    confirmed_by: null,
  },
  {
    user: users.objUsers['02.Wilhelmina'],
    date: '2023-06-02',
    created: '2023-06-02T07:35:00+07:00',
    type: 'holiday',
    half_day: true,
    reason: 'Holiday',
    status: 'rejected',
    confirmed_date: '2023-06-02T08:00:00+07:00',
    confirmed_by: users.objUsers['99.Developer'],
  },
  {
    user: users.objUsers['02.Wilhelmina'],
    date: '2023-06-12',
    created: '2023-06-12T06:00:00+07:00',
    type: 'sick',
    half_day: false,
    reason: 'Sakit',
    status: 'approved',
    confirmed_date: '2023-06-12T08:00:00+07:00',
    confirmed_by: users.objUsers['99.Developer'],
  },
  {
    user: users.objUsers['02.Wilhelmina'],
    date: '2023-06-15',
    created: '2023-06-14T08:00:00+07:00',
    type: 'holiday',
    half_day: false,
    reason: 'Holiday',
    status: 'waiting',
    confirmed_date: null,
    confirmed_by: null,
  },
  {
    user: users.objUsers['02.Wilhelmina'],
    date: '2023-06-29',
    created: '2023-06-21T10:00:00+07:00',
    type: 'holiday',
    half_day: false,
    reason: 'Berlibur',
    status: 'waiting',
    confirmed_date: null,
    confirmed_by: null,
  },
  {
    user: users.objUsers['02.Wilhelmina'],
    date: '2023-06-30',
    created: '2023-06-13T12:11:52+07:00',
    type: 'holiday',
    half_day: false,
    reason: 'Test reason',
    status: 'rejected',
    confirmed_date: '2023-06-14T12:30:00+07:00',
    confirmed_by: users.objUsers['99.Developer'],
  },
  {
    user: users.objUsers['02.Wilhelmina'],
    date: '2023-06-30',
    created: '2023-06-13T13:11:52+07:00',
    type: 'holiday',
    half_day: false,
    reason: 'Coba mengajukan cuti lagi',
    status: 'rejected',
    confirmed_date: '2023-06-14T13:15:00+07:00',
    confirmed_by: users.objUsers['99.Developer'],
  },
  {
    user: users.objUsers['02.Wilhelmina'],
    date: '2023-06-30',
    created: '2023-06-13T13:41:20+07:00',
    type: 'holiday',
    half_day: false,
    reason: 'Cuti pulang kampung',
    status: 'approved',
    confirmed_date: '2023-06-13T13:45:00+07:00',
    confirmed_by: users.objUsers['99.Developer'],
  },
  {
    user: users.objUsers['03.Dina'],
    date: '2023-06-11',
    created: '2023-06-07T08:00:00+07:00',
    type: 'bereavement',
    half_day: false,
    reason: 'Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum',
    status: 'approved',
    confirmed_date: '2023-06-08T08:00:00+07:00',
    confirmed_by: users.objUsers['99.Developer'],
  },
  {
    user: users.objUsers['03.Dina'],
    date: '2023-06-15',
    created: '2023-06-15T08:00:00+07:00',
    type: 'holiday',
    half_day: false,
    reason: 'Acara keluarga',
    status: 'waiting',
    confirmed_date: null,
    confirmed_by: null,
  },
  {
    user: users.objUsers['05.Sintia'],
    date: '2023-06-23',
    created: '2023-06-23T07:00:00+07:00',
    type: 'sick',
    half_day: false,
    reason: 'Meriang',
    status: 'approved',
    confirmed_date: '2023-06-23T08:00:00+07:00',
    confirmed_by: users.objUsers['99.Developer'],
  },
  {
    user: users.objUsers['09.Ary'],
    date: '2023-06-31',
    created: '2023-06-14T08:00:00+07:00',
    type: 'holiday',
    half_day: false,
    reason: 'Berlibur 2',
    status: 'waiting',
    confirmed_date: null,
    confirmed_by: null,
  },
].map(item => ({ ...item, alias: uuidv4() }))

function getUserLeaves(userId, strDate) {
  const monthFormat = 'YYYY-MM'

  return userLeaves.filter(userLeave => (
    userLeave.user.id === userId &&
    moment(strDate).format(monthFormat) === moment(userLeave.date).format(monthFormat)
  ))
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  leaveTemplate,
  usersRemain,
  userLeaves,
  getUserLeaves,
  getTotalAllowanceLeave,
}