import moment from 'moment'

import { request } from 'src/Utils'
import getMonths from './getMonths'

async function readPointExchange(startDate, endDate) {
  return new Promise(resolve => {
    const pointExchange = {}

    const months = getMonths(startDate, endDate)
    let requestedCount = 0, errorResponse = null

    const returnWhenFinish = () => {
      if (requestedCount === months.length) {
        resolve({ pointExchange, errorResponse })
      }
    }

    for (let i = 0; i < months.length; i += 1) {
      request({
        urlKey: 'read-effectiveExchange',
        data: { date: moment(months[i]).startOf('month').format('YYYY-MM-DD') },
        onSuccess: response => {
          requestedCount += 1
          pointExchange[months[i]] = response.data

          returnWhenFinish()
        },
        onFailed: error => {
          requestedCount += 1
          errorResponse = error.response

          returnWhenFinish()
        },
      })
    }
  })
}

export default readPointExchange