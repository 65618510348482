import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Card, Col, Row, Tooltip } from 'antd'
import { CheckCircleFilled, CheckCircleOutlined, CalendarOutlined, EnvironmentOutlined, UserOutlined, WarningFilled, WarningOutlined } from '@ant-design/icons'
import moment from 'moment'

import { BaseComponent } from 'src/components'
import { lib } from 'src/Utils'

class ListItem extends BaseComponent {
  openMaps = (e) => {
    e.stopPropagation()

    lib.openMaps(this.props.attendance.latitude, this.props.attendance.longitude)
  }

  render() {
    const { attendance } = this.props
    const validated = attendance.status === 'validated'

    return (
      <Card
        hoverable
        style={{ borderRadius: '8px', overflow: 'hidden' }}
        bodyStyle={{ padding: '0' }}
        onClick={() => this.props.onSelect(attendance)}
      >
        <Row justify='space-between'>
          <Col style={{ padding: '12px' }}>
            <Row gutter={[12, 12]}>
              <Col>
                <Avatar
                  size={48}
                  src={attendance.photo && attendance.photo.photo}
                  icon={<UserOutlined />}
                />
              </Col>
              <Col>
                <div style={{ fontWeight: 'bold' }}>
                  {/* {attendance.status_display} */}
                  {attendance.attend_type_display}
                </div>
                <div style={{ color: '#9c9c9c' }}>
                  <CalendarOutlined style={{ marginRight: '6px' }} />
                  {attendance.attendance_date
                    ? `${moment(attendance.attendance_date).format('DD MMM YYYY HH:mm a')}`
                    : 'No Present Date'
                  }
                </div>
              </Col>
            </Row>
          </Col>
          <Col className='only-xs-block'>
            <Row className='full-height'>
              {attendance.latitude && (
                <Tooltip title='Open Google Maps' placement='left'>
                  <Col flex='auto' style={{ backgroundColor: '#ff4d4f' }} onClick={this.openMaps}>
                    <Row className='full-height' justify='center' align='middle' style={{ padding: '6px 18px' }}>
                      <EnvironmentOutlined
                        style={{
                          fontSize: '24px',
                          // color: validated ? '#52c41a' : '#faad14',
                          color: 'white',
                        }}
                      />
                    </Row>
                  </Col>
                </Tooltip>
              )}
              <Tooltip title={attendance.status_display} placement='left'>
                <Col
                  flex='auto'
                  style={{
                    padding: '6px 18px',
                    // width: this.mutableScreen.isMobile ? '100%' : '64px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: validated ? '#52c41a' : '#faad14',
                  }}
                >
                  {validated
                    ? <CheckCircleOutlined style={{ color: 'white', fontSize: '24px' }} />
                    : <WarningOutlined style={{ color: 'white', fontSize: '24px' }} />
                  }
                </Col>
              </Tooltip>
            </Row>
          </Col>
        </Row>
      </Card>
    )
  }
}

export default ListItem