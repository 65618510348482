import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { CalendarOutlined, ClockCircleOutlined, FieldTimeOutlined, MessageOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';

import { BaseModal } from 'src/components';
import { lib, request } from 'src/Utils';
import _h from 'src/pages/DashboardApp/Submission/helper';

class DialogRevertConvert extends BaseModal {
  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      // ----- required fields -----
      convertedWorking: null,
      // ---------------------------
    }
  }

  getDialogProps = () => ({
    title: 'Revert Convert Working',
    destroyOnClose: true,
    width: 720,
    okText: 'Revert',
    okButtonProps: { danger: true },
    onOk: this.revertConvert,
  })

  revertConvert = () => {
    this.setState({ isLoading: true }, () => {
      request({
        method: 'delete',
        urlKey: 'office-working-duration-detail',
        args: [this.state.convertedWorking.pk],
        onSuccess: this.revertConvertSuccess,
        onFailed: this.revertConvertFailed,
      })
    })
  }

  revertConvertSuccess = (res) => {
    this.hide(null, this.props.onReverted, true)
  }

  revertConvertFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.setState({ isLoading: false })
  }

  renderContent = () => {
    if (!this.state.convertedWorking) return

    const { convertedWorking } = this.state
    const minHours = Number(convertedWorking.minimum_duration)
    const actualHours = Number(convertedWorking.actual_duration)
    const convertedHours = Number(convertedWorking.convert_duration)

    return (
      <Spin spinning={this.state.isLoading}>
        {/* {convert_duration} */}
        <div>
          <div style={{ padding: '0 8px' }}>
            <div style={{ fontSize: '14px' }}>
              <table className="dialog-user-leave-table">
                <colgroup>
                  <col style={{ width: '140px' }} />
                  <col style={{ width: '30px' }} />
                  <col style={{ width: '420px' }} />
                </colgroup>
                <tbody>
                  <tr className="row-reason hover-smooth">
                    <td className="cell-label">
                      <CalendarOutlined style={{ marginRight: '12px' }} />
                      Date
                    </td>
                    <td>:</td>
                    <td className="cell-value">
                      {moment(convertedWorking.date).format('MMMM YYYY')}
                    </td>
                  </tr>
                  <tr className="row-reason hover-smooth">
                    <td className="cell-label">
                      <ClockCircleOutlined style={{ marginRight: '12px' }} />
                      Min. Hours
                    </td>
                    <td>:</td>
                    <td className="cell-value">
                      {_h._overtime.renderDuration(minHours * 1000)}
                    </td>
                  </tr>
                  <tr className="row-reason hover-smooth">
                    <td className="cell-label">
                      <FieldTimeOutlined style={{ marginRight: '12px' }} />
                      Hours (TW)
                    </td>
                    <td>:</td>
                    <td className="cell-value">
                      <span
                        className={classNames({
                          'c-error': actualHours < minHours,
                          'c-limegreen': actualHours > minHours,
                        })}
                      >
                        {_h._overtime.renderDuration(actualHours * 1000) || '-'}
                      </span>
                    </td>
                  </tr>
                  <tr className="row-reason hover-smooth">
                    <td className="cell-label">
                      <MessageOutlined style={{ marginRight: '12px' }} />
                      Note
                    </td>
                    <td>:</td>
                    <td className="cell-value">
                      {convertedWorking.note || '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="ant-modal-body" style={{ paddingTop: 0, marginTop: '12px' }}>
            <div
              className={classNames({ 'c-limegreen': convertedHours > 0, 'c-error': convertedHours < 0 })}
              style={{ fontSize: '24px', textAlign: 'center' }}
            >
              {_h._overtime.renderDuration(convertedHours * 1000)}
            </div>
            <div style={{ color: 'rgba(0, 0, 0, 0.5)', fontWeight: 500, marginTop: '12px', textAlign: 'center' }}>
              The time above will be revert from <b>Leave Balance</b>.
            </div>
          </div>
        </div>
      </Spin>
    )
  }
}

DialogRevertConvert.propTypes = {
  ...DialogRevertConvert.propTypes,
  onReverted: PropTypes.func,
}

DialogRevertConvert.defaultProps = {
  ...DialogRevertConvert.defaultProps,
  onReverted: () => null,
}

export default DialogRevertConvert