import { get } from 'lodash'

import { lib } from 'src/Utils'
import hasManagerProd from './managerProd'

// tidak cek apakah memiliki permission point, karena sudah di handle di render sebelum memanggil fungsi ini
function canViewReward(userReward, currentUser) {
  const user = currentUser || lib.ls.getCurrentUser()

  return String(get(userReward, 'id')) === String(user.id) || hasManagerProd(user)
}

export default canViewReward