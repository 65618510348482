import PropTypes from 'prop-types';
import { Col, Row, Tooltip } from 'antd';
import { CoffeeOutlined, CommentOutlined, FieldTimeOutlined } from '@ant-design/icons';
import moment from 'moment';
import classNames from 'classnames';

import ListItem from 'src/pages/DashboardApp/Submission/AnnualLeave/DialogLeaveDetail/List/Item'
import _h from 'src/pages/DashboardApp/Submission/helper'

class OvertimeListItem extends ListItem {
  render() {
    const { userOvertime } = this.props

    return (
      <div
        className={classNames('leave-list-item', {
          // rejected: userOvertime.status === 'rejected',
          // approved: userOvertime.status === 'approved',
          // waiting: userOvertime.status === 'waiting',
        })}
        onClick={() => this.props.onSelect(userOvertime)}
      >
        <Row wrap={false} justify="space-between" align="middle" style={{ marginBottom: '6px' }}>
          <Col>
            <div className="leave-title">
              <FieldTimeOutlined style={{ marginRight: '12px' }} />
              {/* {this.props.getLeaveTypeLabel(userOvertime.type)} */}
              {/* {moment(userOvertime.start_time, 'HH:mm').format('hh:mm A')} - {moment(userOvertime.finish_time, 'HH:mm').format('hh:mm A')} */}
              {_h._overtime.renderRange(userOvertime)}
            </div>
          </Col>
          {_h._overtime.isExpSubmission(userOvertime) && (
            <Col style={{ color: '#ff4d4f', fontSize: '16px', fontStyle: 'italic', fontWeight: 500 }}>
              Expired
            </Col>
          )}
          <Col>
            {/* <Tooltip title={userOvertime.status !== 'waiting' && `${userOvertime.status.toUpperCase()} by ${lib.getFullName(userOvertime.confirmed_by)}`} mouseLeaveDelay={0}> */}
              <div
                className={classNames('leave-item-status', {
                  // rejected: _h.isAborted(userOvertime),
                  // approved: _h.isApproved(userOvertime),
                })}
              >
                {_h.renderStatus(userOvertime)}
              </div>
            {/* </Tooltip> */}
          </Col>
        </Row>
        <div>
          <div className="leave-subtitle">
            {/* <CommentOutlined style={{ marginRight: '4px' }} /> */}
            {userOvertime.note}
          </div>
        </div>
      </div>
    )
  }
}

export default OvertimeListItem