import PropTypes from 'prop-types';
import { Button, Col, Divider, Row } from 'antd';
import { BookOutlined, CalendarOutlined, ClockCircleOutlined, CloseOutlined, CommentOutlined, RollbackOutlined, UsbOutlined, WarningFilled, WarningOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import moment from 'moment';

import { BaseModal } from 'src/components';
import { lib, request } from 'src/Utils';

class DialogDeleteActivity extends BaseModal {
  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      activity: null,
    }
  }
  
  getModalProps = () => ({
    title: 'Undo Approved/Rejected Activity',
    width: '640px',
    footer: (
      <Row justify="space-between">
        <Col />
        <Col>
          <Row gutter={[6, 6]}>
            <Col>
              <Button onClick={this.hide}>
                Cancel
              </Button>
              <Button danger type="primary" icon={<RollbackOutlined />} onClick={this.deleteActivity}>
                Undo Action
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    ),
  })

  deleteActivity = () => {
    this.setState({ isLoading: true }, () => {
      request({
        method: 'post',
        urlKey: 'cancel-requestActivities',
        args: [get(this.state.activity.request_activity, 'alias', this.state.activity.alias)],
        onSuccess: this.deleteActivitySuccess,
        onFailed: this.deleteActivityFailed,
      })
    })
  }

  deleteActivitySuccess = () => {
    this.hide(undefined, this.props.reload, true)
    // this.setState({ isLoading: false }, this.props.reload)
  }

  deleteActivityFailed = () => {
    this.setState({ isLoading: false })
  }

  renderContent = () => {
    if (!this.state.activity) return null

    const { activity } = this.state
    // activity bisa jadi "activity time" / "request time" --------------------------
    const requestState = get(activity.request_activity, 'state', activity.state)
    const requestReason = get(activity.request_activity, 'message', activity.message)
    // ------------------------------------------------------------------------------

    return (
      <div>
        {/* {requestState === 'accepted' && ( */}
          <div style={{ padding: '6px 12px', backgroundColor: '#ffeaea', color: '#ff4242', marginBottom: '12px' }}>
            <span style={{ marginRight: '8px' }}>
              <WarningOutlined />
            </span>
            The user activity will be permanently deleted and you can review again
          </div>
        {/* )} */}
        <div style={{ padding: '0 8px' }}>
          <div style={{ fontSize: '14px' }}>
            <table className="dialog-cancel-activity-table">
              <colgroup>
                <col style={{ width: '60px' }} />
                <col style={{ width: '20px' }} />
                <col style={{ width: '250px' }} />
              </colgroup>
              <tbody>
                <tr className="row-task">
                  <td className="cell-label">
                    <UsbOutlined style={{ marginRight: '12px' }} />
                    Task Name
                  </td>
                  <td>:</td>
                  <td className="cell-value">{activity.task && activity.task.name}</td>
                </tr>
                <tr className="row-note">
                  <td className="cell-label">
                    <BookOutlined style={{ marginRight: '12px' }} />
                    Note
                  </td>
                  <td>:</td>
                  <td className="cell-value">{get(activity.note, 'note', '-')}</td>
                </tr>
                <tr className="row-reason">
                  <td className="cell-label">
                    <CommentOutlined style={{ marginRight: '12px' }} />
                    Reason
                  </td>
                  <td>:</td>
                  <td className="cell-value">{requestReason || '-'}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <Divider style={{ margin: '12px 0' }} />
          <div style={{ color: '#9a9a9a', fontSize: '16px', textAlign: 'center' }}>
            <div title='Time Working'>
              <CalendarOutlined style={{ marginRight: '12px' }} />
              {moment(activity.start_time).format('DD MMMM YYYY')}
            </div>
            <div>
              <ClockCircleOutlined style={{ marginRight: '12px' }} />
              {moment(activity.start_time).format('hh:mm A')} - {moment(activity.finish_time).format('hh:mm A')}
              {' '}
              {!this.mutableScreen.isMobile && (
                `(${lib.time.getLabel(
                  lib.time.getDiff({
                    start_time: activity.start_time,
                    finish_time: activity.finish_time,
                  })
                )})`
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

DialogDeleteActivity.propTypes = {
  ...DialogDeleteActivity.propTypes,
}

DialogDeleteActivity.defaultProps = {
  ...DialogDeleteActivity.defaultProps,
}

export default DialogDeleteActivity