import { get } from 'lodash';

const DEFAULT_OPTS = {
  valKey: 'pk',
  valLabel: ['name'],
  separator: ' ',
}

function getSelectQueryValue(item, opts) {
  opts = { ...DEFAULT_OPTS, ...opts }

  return item && {
    ...item,
    // key: get(item, opts.valKey),
    value: get(item, opts.valKey),
    label: Array.isArray(opts.valLabel)
      ? opts.valLabel.map(key => get(item, key)).join(opts.separator)
      : get(item, opts.valLabel),
  }
}

export default getSelectQueryValue