import React from 'react'
import { Button } from 'antd'

import ButtonStatus from './ButtonStatus'

class ButtonActivated extends ButtonStatus {
  renderInputOld = () => {
    return this.state.hovered ? (
      <Button danger type='primary' size='small' {...this.buttonProps} onClick={this.props.deactivate}>
        Deactivate
      </Button>
    ) : (
      <Button type='primary' size='small' {...this.buttonProps}>
        Activated
      </Button>
    )
  }

  renderInput = () => {
    return (
      <Button.Group>
        <Button type='primary' size='small' style={{ ...this.buttonStyle, cursor: 'default' }}>
          Activated
        </Button>
        {!this.props.disabled && (
          <Button size='small' style={this.buttonStyle} onClick={this.props.deactivate}>
            Deactivate
          </Button>
        )}
      </Button.Group>
    )
  }
}

export default ButtonActivated