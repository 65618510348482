import modules from './staticModules'
import { lib } from 'src/Utils'

function hasPointRewardModule(currentUser) {
  const user = currentUser || lib.ls.getCurrentUser()

  // This is required, no module no display
  return user.company.modules.findIndex(mod => mod.codename === modules.POINT_REWARD) !== -1
}

export default hasPointRewardModule