import React from 'react'
import { Col, Row } from 'antd'

import ButtonActivated from './internal/ButtonActivated'
import ButtonDeactivated from './internal/ButtonDeactivated'

class CellStatus extends React.PureComponent {
  getInput = () => {
    const { isOwner, isRegistered, isActive } = this.props

    if (!isRegistered) return 'Registration Incomplete'

    if (!isActive) {
      return (
        <ButtonDeactivated
          disabled={isOwner}
          activate={this.activate}
        />
      )
    }

    return (
      <ButtonActivated
        disabled={isOwner}
        deactivate={this.deactivate}
      />
    )
  }

  activate = () => {
    this.props.activate(this.props.alias, this.props.user)
  }

  deactivate = () => {
    this.props.deactivate(this.props.alias, this.props.user)
  }

  render() {
    return (
      <Row justify='center'>
        <Col>
          {this.getInput()}
        </Col>
      </Row>
    )
  }
}

export default CellStatus