

import activity from './internal/activity'
import company from './internal/company'
import dialogDiscardChanges from './internal/dialogDiscardChanges'
import generateUuid from './internal/generateUuid'
import getFilenameDisposition from './internal/getFilenameDisposition'
import getFullName from './internal/getFullName'
import getHasBonus from './internal/getHasBonus'
import getHref from './internal/getHref'
import getLevel from './internal/getLevel'
import getObjParam from './internal/getObjParam'
import getStrParam from './internal/getStrParam'
import getSelectQueryValue from './internal/getSelectQueryValue'
import time from './internal/getTime'
import loadAllMembers from './internal/loadMembers'
import ls from './internal/localStorage'
import logout from './internal/logout'
import openMaps from './internal/openMaps'
import prod from './internal/production';
import responseMessage from './internal/responseMessage'
import sorter from './internal/sorter'
import toFile from './internal/toFile'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  activity,
  company,
  dialogDiscardChanges,
  generateUuid,
  getFilenameDisposition,
  getFullName,
  getHasBonus,
  getHref,
  getLevel,
  getObjParam,
  getStrParam,
  getSelectQueryValue,
  time,
  loadAllMembers,
  ls,
  logout,
  openMaps,
  prod,
  responseMessage,
  sorter,
  toFile,
}
