import PropTypes from 'prop-types';

import BaseJobByGrade from 'src/pages/DashboardApp/DashboardManager/ChartProduction/JobByGrade';
import { lib } from 'src/Utils';

class JobByGrade extends BaseJobByGrade {
  setAverage = (productionJobs, nextProps = this.props) => {
    const prodDays = [], qs = []
    let totalDays = 0, totalQS = 0

    for (const i in productionJobs) {
      if (prodDays.indexOf(productionJobs[i].prod_day) === -1) {
        // totalDays += 1
        prodDays.push(productionJobs[i].prod_day)
      }

      if (qs.indexOf(lib.getFullName(productionJobs[i].user)) === -1) {
        totalQS += 1
        qs.push(lib.getFullName(productionJobs[i].user))
      }
    }

    this.averagePerDay = nextProps.totalWorkingDays
      ? Number((this.totalValue / nextProps.totalWorkingDays).toFixed(2))
      : 'N/A'
    this.averagePerJob = Math.round(this.totalValue / totalQS)
  }

  childComponentWillReceiveProps(nextProps) {
    if (this.props.totalWorkingDays !== nextProps.totalWorkingDays) {
      this.convertData(nextProps.productionJobs, nextProps)
    }
  }
}

JobByGrade.propTypes = {
  ...JobByGrade.propTypes,
  totalWorkingDays: PropTypes.number.isRequired,
}

JobByGrade.defaultProps = {
  ...JobByGrade.defaultProps,
}

export default JobByGrade