import React from 'react'
import { Col, Row } from 'antd'
import Particles from 'react-particles-js'

import LoginContent from './Content'
// import logo from 'src/assets/logo_banner_white.png'
// import logo from 'src/assets/logo2_banner_white.png'
import logo from 'src/assets/logo3_banner_white.png'

class Login extends React.PureComponent {
  render() {
    return (
      <Row
        justify='center'
        align='middle'
        style={{
          background: 'linear-gradient(45deg, rgb(45, 54, 76) 0%, rgb(37, 45, 63) 100%)',
          height: '100vh',
          position: 'relative',
        }}
      >
        <Particles
          className='bg-login-particle'
          params={{
            "particles": {
              color: {
                value: "#fff"
              },
              "number": {
                "value": 60,
                "density": {
                  "enable": true,
                  "value_area": 1500
                }
              },
              "line_linked": {
                "enable": true,
                "opacity": 0.02
              },
              "move": {
                "direction": "right",
                "speed": 0.05
              },
              "size": {
                "value": 1
              },
              "opacity": {
                "anim": {
                  "enable": true,
                  "speed": 1,
                  "opacity_min": 0.05
                }
              }
            },
            "interactivity": {
              "events": {
                "onclick": {
                  "enable": true,
                  "mode": "push"
                }
              },
              "modes": {
                "push": {
                  "particles_nb": 1
                }
              }
            },
            "retina_detect": true
          }}
        />
        <Col className='login-form'>
          <Row align='middle' style={{ flexDirection: 'column' }}>
            <div style={{ padding: '24px' }}>
              <img src={logo} className='logo' />
            </div>
            <div className='user-login-main'>
              <LoginContent history={this.props.history} />
            </div>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default Login