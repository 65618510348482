import React from 'react'
import { Col } from 'antd'

import { BaseComponent } from 'src/components'

class ButtonStatus extends BaseComponent {
  constructor(props) {
    super(props)

    this.buttonStyle = { width: '100px' }

    this.state = {
      hovered: false,
    }
  }

  onMouseEnter = () => {
    if (!this.props.disabled) {
      this.setThisState({ hovered: true })
    }
  }

  onMouseLeave = () => {
    this.setThisState({ hovered: false })
  }

  renderInput = () => null

  render() {
    return (
      <Col onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        {this.renderInput()}
      </Col>
    )
  }
}

export default ButtonStatus