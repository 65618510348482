import React from 'react'
import { Avatar, Badge, Button, Card, Col, Collapse, DatePicker, Descriptions, Form, Input, message, Modal, Popconfirm, Popover, Row, Select, Space, Spin, Table, Tag } from 'antd'
import { CaretDownOutlined, CaretLeftOutlined, CaretRightOutlined, CheckCircleFilled, CheckOutlined, CloseOutlined, EditOutlined, ReloadOutlined, RollbackOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons'
import moment from 'moment'
import { get } from 'lodash'
import classNames from 'classnames'

import { BaseListActivity, SelectQuery, VisibilityContainer } from 'src/components'
import { lib, permissions, request } from 'src/Utils'
import EditableCell from './EditableCell'
import RequestTimeReview from './Review'
import RequestHistory from './History'
import DialogDeleteActivity from './DialogDeleteActivity'

const { Option } = Select
const { Panel } = Collapse

const TIME_FORMAT = 'HH:mm A'

class EditTime extends BaseListActivity {
  constructor(props) {
    super(props)

    this.keysQueryParam.push('rel_fields')

    // user config
    this.users = []
    this.pageUser = 1
    this.limitUser = 100
    this.nextPageUser = null
    this.rel_fields = 'request_activity'

    // activity config
    // this.id = undefined (must undefined, don't declare or initial with another value)
    this.id = this.objParam.id
    this.MIN_TOTAL = 1
    this.totalWorked = 0
    this.msTolerance = 1500
  
    this.title = ''
    this.columns = []
    this.scrollConfig = { x: '350px' }
    this.containerProps = null
    this.myProfile = lib.ls.getCurrentUser()
    this.canReview = permissions.canReview(this.myProfile)

    this.columns = [
      {
        title: 'Time Started',
        dataIndex: 'start_time',
        width: '100px',
        onCell: record => ({
          dataIndex: 'start_time',
          editing: this.isEditing(record),
          formProps: {
            getValueFromEvent: (val) => {
              const diff = lib.time.getDiff({ start_time: val, finish_time: this._form.getFieldValue('finish_time') })
              if (diff <= 0) {
                const a = record.timeOptions.find(opt => moment(opt.start_time).format() === moment(val).format())
                if (a) {
                  this._form.setFieldsValue({ finish_time: a.finish_time })
                }
              }
              return val
            }
          },
          inputComponent: this.isEditing(record) && (
            <Select size='small' listHeight={360}>
              {this.renderTimeOption(record.timeOptions, 'start_time')}
            </Select>
          )
        }),
        render: val => moment(val).format(TIME_FORMAT),
      },
      {
        title: 'Time Ended',
        dataIndex: 'finish_time',
        width: '100px',
        onCell: record => ({
          dataIndex: 'finish_time',
          editing: this.isEditing(record),
          formProps: {
            getValueFromEvent: (val) => {
              const diff = lib.time.getDiff({ start_time: this._form.getFieldValue('start_time'), finish_time: val })
              if (diff <= 0) {
                const a = record.timeOptions.find(opt => moment(opt.finish_time).format() === moment(val).format())
                if (a) {
                  this._form.setFieldsValue({ start_time: a.start_time })
                }
              }
              return val
            }
          },
          inputComponent: this.isEditing(record) && (
            <Select size='small' listHeight={360}>
              {this.renderTimeOption(record.timeOptions, 'finish_time')}
            </Select>
          )
        }),
        render: val => moment(val).format(TIME_FORMAT),
      },
      {
        title: 'Total Time',
        dataIndex: 'total_time',
        width: '120px',
        onCell: record => ({
          // dataIndex: 'total_time',
          editing: this.isEditing(record),
          formProps: { shouldUpdate: true, noStyle: true },
          inputComponent: ({ getFieldValue }) => (
            lib.time.getLabel(
              lib.time.getDiff({
                start_time: getFieldValue('start_time'),
                finish_time: getFieldValue('finish_time'),
              })
            )
          ),
        }),
        render: (_, record) => this.isEditing(record) ? 'test' : lib.time.getLabel(lib.time.getDiff(record))
      },
      {
        title: 'Project',
        dataIndex: 'project',
        // render: (val) => val || 'Not Working'
        width: '110px',
        onCell: record => ({
          dataIndex: 'project',
          editing: this.isEditing(record),
          formProps: {
            // shouldUpdate: true,
            initialValue: undefined,
            getValueFromEvent: (val) => {
              this._task.project = val.key
              this._task.forceRead()

              this._form.setFieldsValue({ task: undefined })

              return val
            }
          },
          inputComponent: (
            <SelectQuery
              // ref={ref => {}}
              urlKey='read-projects-autocomplete'
              valKey='alias'
              inputProps={{ dropdownMatchSelectWidth: false, size: 'small', placeholder: 'Task Filter' }}
              initialValues={{ pagination: false }}
              params={{ archived: false }}
            />
          )
        }),
      },
      {
        title: 'Task',
        dataIndex: 'task',
        width: '170px',
        onCell: record => ({
          dataIndex: 'task', editing: this.isEditing(record),
          formProps: {
            rules: [
              { required: true, message: 'Task is required!' },
            ]
          },
          inputComponent: (
            <SelectQuery
              ref={ref => {this._task = ref}}
              urlKey='read-permanentTasks'
              valKey='alias'
              inputProps={{ dropdownMatchSelectWidth: false, size: 'small', placeholder: 'Working Task' }}
              params={{ project: null }}
            />
          )
        }),
        render: (val) => val ? val.name : 'Not Working'
      },
      {
        title: 'Note',
        dataIndex: ['note', 'note'],
        width: '150px',
        onCell: record => ({
          dataIndex: 'note',
          editing: this.isEditing(record),
          title: get(record.note, 'note'),
          className: 'webkit-box two-lines',
          inputComponent: (
            <Input size='small' placeholder='ex. #200000' maxLength={100} />
          )
        }),
      },
      {
        title: 'Reason',
        // dataIndex: 'message',
        dataIndex: ['request_activity', 'message'],
        width: '180px',
        // ellipsis: true,
        onCell: record => ({
          dataIndex: 'message',
          editing: this.isEditing(record),
          // title: get(record.request_activity, 'message'),
          className: 'webkit-box two-lines',
          formProps: {
            rules: [
              { required: true, message: 'Reason is required!' },
            ],
          },
          inputComponent: (
            <Input size='small' placeholder='ex. Meeting Offline with Manager' maxLength={100} />
          )
        }),
        render: (val, record, i) => {
          const message = get(record, ['request_activity', 'message'], record.message)
          return <span title={message}>{message}</span>
        },
      },
      {
        title: 'Action',
        dataIndex: 'action',
        className: 'edit-time-action',
        width: '180px',
        onCell: record => ({
          editing: this.isEditing(record),
          inputComponent: (
            <Space>
              <Button
                size='small'
                icon={<CloseOutlined />}
                onClick={() => {
                  if (this._form.isFieldsTouched()) {
                    Modal.confirm({
                      title: 'Your unsaved changes will be lost, continue?',
                      okButtonProps: { danger: true, type: 'primary' },
                      onOk: () => {
                        this.setThisState({ editingKey: null }, this._form.resetFields)
                      }
                    })
                  } else {
                    this.setThisState({ editingKey: null }, this._form.resetFields)
                  }
                }}
              />
              <Button
                size='small'
                icon={<CheckCircleFilled />}
                onClick={this.onSave}
              />
            </Space>
          )
        }),
        render: (val, record) => {
          return (
            <div>
              {!this.isEditing(record) && (
                record.state === 'request_review' ? !this.canReview && <Tag color='default'>Waiting for review</Tag> : !record.alias &&
                <Button
                  disabled={record.alias}
                  size='small'
                  icon={<EditOutlined />}
                  onClick={() => {
                    this._form.setFieldsValue({
                      // ...record,
                      start_time: moment(record.start_time).format(),
                      finish_time: moment(record.finish_time).format(),
                    })
                    this.setThisState({ editingKey: `${record.start_time} # ${record.finish_time}` })
                  }}
                />
              )}
              {record.is_manual_time && (
                <Space>
                  <Tag color="orange" style={{ height: '24px' }}>
                    Approved
                  </Tag>
                  {this.canReview && (
                    <Button danger type="primary" size="small" icon={<RollbackOutlined />} onClick={() => this.showDeleteActivity(record)}>
                      Undo
                    </Button>
                  )}
                </Space>
              )}
              {this.canReview && record.state === 'request_review' && (
                <div>
                  {/* <Popconfirm okText="Accept" title="Accept the request?" onConfirm={() => this._review.onAccept(record)}> */}
                    <Button
                      type="primary"
                      size="small"
                      icon={<CheckOutlined />}
                      onClick={() => {
                        this.setState({ isLoading: true }, async () => {
                          this._review.onAccept(record).then(isSuccess => {
                            this.setState({ isLoading: false })
                          })
                        })
                      }}
                    >
                      Accept
                    </Button>
                  {/* </Popconfirm> */}
                  {/* <Popconfirm okButtonProps={{danger: true}} okText="Reject" title="Reject the request?" onConfirm={() => this._review.onReject(record)}> */}
                    <Button
                      danger
                      type="primary"
                      size="small"
                      icon={<CloseOutlined />}
                      style={{ marginLeft: '4px' }}
                      // onClick={() => this.onReject(record)}
                      onClick={() => {
                        this.setState({ isLoading: true }, async () => {
                          this._review.onReject(record).then(isSuccess => {
                            this.setState({ isLoading: false })
                          })
                        })
                      }}
                    >
                      Reject
                    </Button>
                  {/* </Popconfirm> */}
                </div>
              )}
              {/* {record.alias && (
                <Button
                  danger
                  disabled={record.alias}
                  type='primary'
                  size='small'
                  icon={<DeleteOutlined />}
                />
              )} */}
            </div>
          )
        }
      },
    ]

    this.state = {
      ...this.state,
      searchValue: '',
      reviewOpened: false,
      countReviewPending: 0,
    }
  }

  onRefDialogDeleteActivity = (ref) => this._dialogDeleteActivity = ref

  isEditing = (record) => {
    if (record.alias)
      return record.alias === this.state.editingKey

    return this.state.editingKey === `${record.start_time} # ${record.finish_time}`
  }

  getTimeOption = (activity) => {
    let startTime = moment(activity.start_time).format()
    const finishTime = moment(activity.finish_time).format()

    const opts = []

    let a = moment(startTime)

    a.set('s', 0)

    if (lib.time.getDiff(activity) > 0) {
      // Be careful infinite loop
      while(true) {
        a.add(1, 'minute')
        const isEnded = a.format(TIME_FORMAT) === moment(finishTime).format(TIME_FORMAT)
        if (a.minute() % 5 === 0 || isEnded) {
          if (isEnded)
            a = moment(finishTime)

          opts.push({
            start_time: startTime,
            finish_time: a.format()
          })

          startTime = a.format()
        }

        if (isEnded)
          break
      }
    }

    return opts
  }

  // key = start_time or finish_time
  renderTimeOption = (opts, key) => {
    return opts.map(val => (
      <Option key={val[key]} value={val[key]}>
        {moment(val[key]).format(TIME_FORMAT)}
      </Option>
    ))
  }

  beforeRead = async (keyLoading, callback) => {
    return new Promise((resolve) => {
      this.pageUser = 1
      this.users = [this.myProfile]
      this.readUsers(keyLoading, resolve, callback)
      this.readPendingActivities()
    })
  }

  readUsers = (keyLoading = 'isLoading', resolve = () => {}, callback = () => {}) => {
    // Cancel request users and user activities
    this.cancelUsers()
    this.cancel()

    this.setThisState({ [keyLoading]: true }, () => {
      request({
        urlKey: 'read-usersManagedMe',
        data: { page: this.pageUser, limit: this.limitUser },
        extra: { resolve, callback },
        getCancel: this.setCancelUsers,
        onSuccess: this.readUsersSuccess,
        onFailed: this.readUsersFailed,
      })
    })
  }

  setCancelUsers = (cancel) => {
    this.cancelTokenUsers = cancel
  }

  cancelUsers = () => {
    if (typeof this.cancelTokenUsers === 'function') {
      this.cancelTokenUsers()
    }
  }

  readUsersSuccess = (response, extra) => {
    this.nextPageUser = response.data.next

    this.users = this.users.concat(
      lib.sorter.byArray(
        response.data.results.filter(result => this.users.findIndex(user => user.email === result.email) === -1),
        'first_name'
      )
    )

    if (response.data.next) {
      this.pageUser += 1
      this.readUsers(undefined, extra.resolve)
    } else {
      let user = null
      if (!this.id) {
        user = this.users.find(user => user.email === this.myProfile.email)
      } else {
        user = this.users.find(user => `${user.id}` === `${this.id}`)
      }

      // this.setThisState({ selectedUser: user ? user.id : response.data.results[0].id }, () => {
        if (user) this.id = user.id
        else if (response.data.results.length) {
          this.id = response.data.results[0].id
        }

        extra.resolve()
      // })
    }
  }

  readUsersFailed = (error, extra) => {
    lib.responseMessage.error(error.response)

    this.setThisState({ isLoading: false, errorResponse: error.response })
    extra.resolve(true)
    if (extra.callback) extra.callback()
  }

  // Max range date
  checkRange = (startDate, endDate) => moment(startDate).format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD')

  // default filter is Today
  changeToDefaultFilter = () => {
    this.onChangeFilter({
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
    })
  }

  getFilterInput = () => (
    <Col className='only-xs-block'>
      <Row>
        <Col>
          <Button icon={<CaretLeftOutlined />} onClick={this.prevDays} />
        </Col>
        <Col style={{ margin: '0 -1px', flex: 1 }}>
          <DatePicker
            allowClear={false}
            format='YYYY-MM-DD'
            value={moment(this.range_before)}
            style={{ width: '100%' }}
            onChange={(selectedDate) => {
              this.onChangeFilter({
                start_date: selectedDate.format('YYYY-MM-DD'),
                end_date: selectedDate.format('YYYY-MM-DD'),
              })
            }}
          />
        </Col>
        <Col>
          <Button icon={<CaretRightOutlined />} onClick={this.nextDays} />
        </Col>
      </Row>
    </Col>
  )

  nextDays = () => {
    const date = moment(this.range_before).add(1, 'days').format('YYYY-MM-DD')
    this.onChangeFilter({ start_date: date, end_date: date })
  }

  prevDays = () => {
    const date = moment(this.range_before).subtract(1, 'days').format('YYYY-MM-DD')
    this.onChangeFilter({ start_date: date, end_date: date })
  }

  getTimelines = (activities) => {
    const timelines = []

    for (let j = 0; j < activities.length; j += 1) {
      let newPart = true
      for (let k = 0; k < timelines.length; k += 1) {
        const diff = Math.abs(moment(activities[j].start_time).diff(moment(timelines[k].finish_time)))
        if (
          activities[j].state === timelines[k].state &&
          activities[j].is_manual_time === timelines[k].is_manual_time &&
          diff <= this.msTolerance &&
          timelines[k].task.alias === activities[j].task.alias &&
          get(timelines[k].note, 'note') === get(activities[j].note, 'note') &&
          get(timelines[k].request_activity, 'alias') === get(activities[j].request_activity, 'alias') && // dibuat alias karena yang dari edit time tidak boleh di merge, sebab bisa di undo dan membutuhkan alias activity
          activities[j].state !== 'request_review'
        ) {
          timelines[k].finish_time = activities[j].finish_time
          newPart = false
          break
        }
      }

      if (newPart) {
        timelines.push(activities[j])
      }

      // userActivities[i].timelines = timelines

      // userActivities[i].totalWorked += moment(activities[j].finish_time).diff(moment(activities[j].start_time))
    }

    this.insertNotWorkingActivities(timelines)

    return timelines
  }

  insertNotWorkingActivities = (timelines) => {
    const maxStartTime = moment(this.range_before).hours(8).minutes(0).seconds(0).format()
    const maxEndTime = moment(this.range_after).hours(18).minutes(0).seconds(0).format()
    if (!timelines.length) {
      const activity = {
        // start_time: moment(this.range_before).startOf('day').format(),
        // finish_time: moment(this.range_after).endOf('day').format()
        start_time: maxStartTime,
        finish_time: maxEndTime,
      }

      timelines.splice(0, 0, {
        // alias: Math.random(),
        ...activity,
        total_time: 0,
        // project: null,
        // task: null,
        isBlank: true,
        timeOptions: this.getTimeOption(activity),
      })

      return
    }

    // const startTime = moment(this.range_before).startOf('day').format()
    // let endTime = moment(this.range_after).add(1, 'day').startOf('day').format()
    const startTime = maxStartTime
    let endTime = maxEndTime

    for (let i = timelines.length - 1; i >= 0; i -= 1) {
      const diff = lib.time.getDiff({
        start_time: timelines[i].finish_time,
        finish_time: endTime
      })

      if (diff > 60 * 1000) {
        const activity = {
          // karena app menggunakan toISOString, sehingga bisa jadi ada millisecond yang tidak di response oleh API yang mengakibatkan conflict time activity jika tidak di tambah 1 second
          start_time: moment(timelines[i].finish_time).add(1, 'seconds'),
          finish_time: endTime,
        }

        timelines.splice(i + 1, 0, {
          // alias: Math.random(),
          ...activity,
          // start_time: timelines[i].finish_time,
          // finish_time: endTime,
          total_time: 0,
          // project: null,
          // task: null,
          isBlank: true,
          timeOptions: this.getTimeOption(activity),
        })
      }

      endTime = timelines[i].start_time
    }

    const diff = lib.time.getDiff({
      start_time: startTime,
      finish_time: timelines[0].start_time
    })
    if (diff > 0) {
      const activity = {
        start_time: startTime,
        finish_time: timelines[0].start_time,
      }

      timelines.splice(0, 0, {
        // alias: Math.random(),
        ...activity,
        // start_time: startTime,
        // finish_time: timelines[0].start_time,
        total_time: 0,
        // project: null,
        // task: null,
        isBlank: true,
        timeOptions: this.getTimeOption(activity),
      })
    }
  }

  convertResponseData = (userActivities) => {
    return new Promise(async resolve => {
      this.readCountPending()

      const resPending = await this.readPendingActivities()
      // When request pending activities is failed, need to be handled

      const pendingActivities = get(resPending, 'data', [])

      for (const i in userActivities) {
        const activities = [...userActivities[i].activities, ...pendingActivities]
          .sort((a, b) => a.start_time > b.start_time ? 1 : -1)

        userActivities[i].totalWorked = userActivities[i].activities.reduce((acc, val) => lib.time.getDiff(val) + acc, 0)
        userActivities[i].totalManual = userActivities[i].activities.filter(activity => activity.is_manual_time).reduce((acc, val) => lib.time.getDiff(val) + acc, 0)
        userActivities[i].totalPending = pendingActivities.reduce((acc, val) => lib.time.getDiff(val) + acc, 0)

        userActivities[i].activities = this.getTimelines(activities)
      }

      resolve(userActivities)
    })
  }

  readPendingActivities = () => {
    return request({
      urlKey: 'read-userRequestActivities',
      data: {
        range_after: this.range_after,
        range_before: this.range_before,
        user: this.id,
        state: 'request_review',
        paginate: false,
      },
    })
  }

  readPendingSuccess = () => {

  }

  readPendingFailed = () => {

  }

  readCountPending = async () => {
    request({
      urlKey: 'read-requestActivities',
      data: { state: 'request_review', limit: 1 },
      onSuccess: (res) => this.setCountReviewPending(res.data.count),
      // onFailed: (error) => resolve(0),
    })
  }

  onChangeSearch = (e) => {
    clearTimeout(this._timeoutSearch)

    this._timeoutSearch = setTimeout(() => {
      this.setThisState({ searchValue: e.target.value })
    }, 350)
  }

  onSave = () => {
    if (this.state.isLoading)
      return

    this._form.validateFields().then(values => {
      this.setThisState({ isLoading: true }, () => {
        // tidak tau kenapa jika user dirinya sendiri dan mengirim "user", maka object tidak ditemukan, jadinya FE tidak mengirim jika dirinya sendiri
        const userId = this.myProfile.id === this.id ? undefined : this.id

        request({
          method: 'post',
          urlKey: 'read-userRequestActivities',
          data: {
            user: userId,
            task: get(values.task, 'key', null),
            start_time: values.start_time,
            finish_time: values.finish_time,
            message: values.message,
            note: values.note,
          },
          onSuccess: this.saveSuccess,
          onFailed: this.saveFailed,
        })
      })
    })
  }

  saveSuccess = (response) => {
    message.success('Request manual time has been saved, wait until the owner accept it')
    this.setThisState({ editingKey: null }, this.forceRead)
    this._form.resetFields()
  }

  saveFailed = (error) => {
    const fieldsError = []

    const errorData = get(error, ['response', 'data'])
    if (typeof errorData === 'object') {
      for (const key in errorData) {
        if (this.columns.findIndex(col => col.dataIndex === key) !== -1) {
          fieldsError.push({
            name: key, errors: errorData[key]
          })
        }
      }
    }

    this._form.setFields(fieldsError)

    lib.responseMessage.error(error.response)

    this.setThisState({ isLoading: false })
  }

  gerUserDisplay = (user) => {
    if (lib.getFullName(user).toLowerCase().indexOf(this.state.searchValue.trim().toLowerCase()) !== -1)
      return 'block'

    if (user.email.toLowerCase().indexOf(this.state.searchValue.trim().toLowerCase()) !== -1)
      return 'block'

    return 'none'
  }

  onChangeSelectedUser = (user) => {
    // break when current selected user equal with clicked user
    if (`${this.id}` === `${user.id}`)
      return

    this.cancel()
    this.onChangeFilterNew({ id: user.id })
  }

  onChangeFilterNew = (objParams) => {
    const mergeObjParams = { ...this.objParam, ...objParams }
    const filteredObjParams = {}
    for (const key in mergeObjParams) {
      if (this.allowedParams.findIndex(param => param === key) !== -1)
        filteredObjParams[key] = mergeObjParams[key]
    }

    this.props.history.replace(this.props.location.pathname + lib.getStrParam(filteredObjParams))
  }

  onChangeId = (id) => {
    this.id = id
    this.doRead(this.keyIsLoading, () => {})
  }

  setReviewOpened = (reviewOpened) => {
    this.setThisState({ reviewOpened })
  }

  setCountReviewPending = (countReviewPending) => {
    this.setThisState({ countReviewPending })
  }

  onRefHistory = (ref) => {
    this._history = ref
  }

  onShowHistory = () => {
    this._history.show({
      user: this.id,
    })
  }

  getUserTitle = (user) => {
    return (
      <Row align='middle'>
        <Col>
          <Avatar
            icon={<UserOutlined />}
            src={user.photo}
            size={28}
            style={{ marginRight: '8px' }}
          />
        </Col>
        <Col style={{ fontSize: '18px', fontWeight: 500  }}>
          {lib.getFullName(user)}
        </Col>
      </Row>
    )
  }

  unmount() {
    this.cancel()
    this.cancelUsers()
  }

  renderTooltipTotalWorked = (user) => {
    return (
      <Descriptions
        bordered
        column={1}
        size="small"
        labelStyle={{ color: 'rgba(0, 0, 0, 0.5)' }}
        contentStyle={{ fontWeight: 500 }}
      >
        <Descriptions.Item label="Working Time">
          {lib.time.getLabel(user.totalWorked - user.totalManual)}
        </Descriptions.Item>
        <Descriptions.Item label="Manual Time">
          {lib.time.getLabel(user.totalManual)}
        </Descriptions.Item>
      </Descriptions>
    )
  }

  showDeleteActivity = (activity) => {
    this._dialogDeleteActivity.show({ activity })
  }

  render() {
    return (
      <div style={{ padding: '24px' }}>
        {this.canReview && (
          <DialogDeleteActivity
            ref={this.onRefDialogDeleteActivity}
            user={this.__list[0]}
            reload={() => {
              if (this._review) this._review.reload()
              this.forceRead()
            }}
          />
        )}

        {/* {!this.state.reviewOpened && ( */}
        <VisibilityContainer visible={!this.state.reviewOpened}>
          <div>
            {/* Header */}
            <div>
              <Row justify='space-between' gutter={[12, 12]} style={{ marginBottom: '12px' }}>
                <Col>
                  <Row align='middle'>
                    <Col>
                      <h1 style={{ marginBottom: 0 }}>
                        Edit Time
                      </h1>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row align='middle' gutter={[6, 6]}>
                    {this.users.length > 1 && (
                      <Col style={{ flex: 1 }}>
                        <Input
                          placeholder='Search user...'
                          prefix={<SearchOutlined className='site-form-item-icon' />}
                          onChange={this.onChangeSearch}
                        />
                      </Col>
                    )}
                    <Col>
                      <Button loading={this.state.isLoading} icon={<ReloadOutlined />} onClick={this.read}>
                        Refresh
                      </Button>
                    </Col>
                    <Col className='only-xs-block'>
                      <Row>
                        <Col>
                          <Button icon={<CaretLeftOutlined />} onClick={this.prevDays} />
                        </Col>
                        <Col style={{ margin: '0 -1px', flex: 1 }}>
                          <DatePicker
                            allowClear={false}
                            format='YYYY-MM-DD'
                            value={moment(this.range_before)}
                            style={{ width: '100%' }}
                            onChange={(selectedDate) => {
                              this.onChangeFilter({
                                start_date: selectedDate.format('YYYY-MM-DD'),
                                end_date: selectedDate.format('YYYY-MM-DD'),
                              })
                            }}
                          />
                        </Col>
                        <Col>
                          <Button icon={<CaretRightOutlined />} onClick={this.nextDays} />
                        </Col>
                      </Row>
                    </Col>
                    <Col className='only-xs-block'>
                      <Button block onClick={this.onShowHistory}>
                        History
                      </Button>
                    </Col>

                    {/* Button Review Request Activity */}
                    {this.canReview && (
                      <Col className='only-xs-block container-badge-block'>
                        <Badge count={this.state.countReviewPending}>
                          <Button
                            block
                            {...{ danger: !!this.state.countReviewPending }}
                            onClick={() => this.setReviewOpened(true)}
                          >
                            Review
                          </Button>
                        </Badge>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </div>

            {/* Users */}
            {this.users.length > 1 && (
              <div style={{ paddingBottom: '12px', backgroundColor: '#f0f2f5' }}>
                <Row gutter={[12, 12]} style={{ flexFlow: 'nowrap', overflowX: 'auto' }}>
                  {this.users.map((user, i) => (
                    <Col key={i} style={{ display: this.gerUserDisplay(user) }}>
                      <Card
                        className={classNames(
                          'app-card app-card-selection',
                          {active: `${this.id}` === `${user.id}`}
                        )}
                        bodyStyle={{ padding: '12px' }}
                        style={{ cursor: 'pointer', width: '250px' }}
                        onClick={() => this.onChangeSelectedUser(user)}
                      >
                        <Row justify='center'>
                          <Avatar
                            src={user.photo}
                            icon={<UserOutlined />}
                            size={72}
                            style={{
                              color: '#fff',
                              // border: '1px solid #e8e8e8',
                              boxShadow: '0 6px 16px -8px RGB(0 0 0/8%)',
                            }}
                          />
                        </Row>
                        <div
                          className='webkit-box two-lines'
                          style={{ height: '44px', textAlign: 'center', marginTop: '12px' }}
                        >
                          {lib.getFullName(user)}
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            )}

            <Spin spinning={this.state.isLoading}>
              {this.state.errorResponse ? this.getErrorComp() : (
                this.__list.length > 0 && (
                  <Collapse activeKey={this.__list.map(user => user.alias)} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                    {this.__list.map(user => (
                      <Panel key={user.alias} header={this.getUserTitle(user)} className='panel-report'>
                        <Row justify='space-between' gutter={[12, 12]} style={{ textAlign: 'center' }}>
                          <Col>
                            <Popover title="Total Worked" content={this.renderTooltipTotalWorked(user)}>
                              <div style={{ padding: '12px', cursor: 'pointer' }}>
                                <div style={{ fontSize: '28px', fontWeight: 500 }}>
                                  {lib.time.getLabel(user.totalWorked)}
                                  <CaretDownOutlined style={{ color: '#c1c1c1', fontSize: '16px', marginLeft: '8px' }} />
                                </div>
                                <div className='subtitle' style={{ fontSize: '16px' }}>
                                  Time Worked
                                </div>
                              </div>
                            </Popover>
                          </Col>
                          {!this.mutableScreen.isMobile && (
                            <>
                              <Col>
                                <div style={{ padding: '12px' }}>
                                  <div style={{ fontSize: '28px', fontWeight: 500, color: 'orange' }}>
                                    {lib.time.getLabel(user.totalPending)}
                                  </div>
                                  <div className='subtitle' style={{ fontSize: '16px' }}>
                                    Pending Time
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div style={{ padding: '12px' }}>
                                  <div style={{ fontSize: '28px', fontWeight: 500 }}>
                                    {moment(this.range_before).format('DD MMM YYYY')}
                                  </div>
                                  <div className='subtitle' style={{ fontSize: '16px' }}>
                                    Selected Date
                                  </div>
                                </div>
                              </Col>
                            </>
                          )}
                        </Row>
                        <div>
                          <Form
                            ref={ref => {this._form = ref}}
                            initialValues={{
                              project: { key: null, value: null },
                              task: undefined,
                              note: '',
                              message: '',
                            }}
                          >
                            <Table
                              // bordered
                              size='small'
                              // rowKey='alias'
                              rowKey={record => record.alias || `${record.start_time} # ${record.finish_time}`}
                              columns={this.columns}
                              dataSource={get(this.__list, [0, 'activities'], [])}
                              pagination={false}
                              onRow={(record) => ({
                                // className: record.submission && 'submission-time',
                                className: classNames({
                                  // 'submission-time': !!record.submission,
                                  'submission-time': record.state === 'request_review',
                                  'manual-time': !!record.is_manual_time,
                                }),
                                // style: record.isBlank ? { color: '#dadada', fontWeight: 500 } : { fontWeight: 500 },
                                style: record.isBlank ? { color: '#dadada', fontWeight: 400 } : { fontWeight: 400 },
                              })}
                              components={{
                                body: {
                                  cell: EditableCell,
                                }
                              }}
                              scroll={{
                                x: 1024,
                                // Jika memiliki managed user, maka menampilkan list user di header dan butuh tambahan offset top untuk max height table content
                                y: this.users.length > 1 ? 'calc(100vh - 320px)' : 'calc(100vh - 150px)'
                              }}
                            />
                          </Form>
                        </div>
                      </Panel>
                    ))}
                  </Collapse>
                )
              )}
            </Spin>

            {/* Content */}
            {/* <Spin spinning={this.state.isLoading}>
              <Card className='app-card' bodyStyle={{ padding: 0 }}>
                {this.state.errorResponse ? this.getErrorComp() : (
                  <div>
                    <Form
                      ref={ref => {this._form = ref}}
                      initialValues={{
                        project: { key: null, value: null },
                        task: undefined,
                        note: '',
                        message: '',
                      }}
                    >
                      <Table
                        // bordered
                        size='small'
                        // rowKey='alias'
                        rowKey={record => record.alias || `${record.start_time} # ${record.finish_time}`}
                        columns={this.columns}
                        dataSource={get(this.__list, [0, 'activities'], [])}
                        pagination={false}
                        onRow={(record) => ({
                          className: record.submission && 'submission-time',
                          // style: record.isBlank ? { color: '#dadada', fontWeight: 500 } : { fontWeight: 500 },
                          style: record.isBlank ? { color: '#dadada', fontWeight: 400 } : { fontWeight: 400 },
                        })}
                        components={{
                          body: {
                            cell: EditableCell,
                          }
                        }}
                        scroll={{
                          x: 1024,
                          // Jika memiliki managed user, maka menampilkan list user di header dan butuh tambahan offset top untuk max height table content
                          y: this.users.length > 1 ? 'calc(100vh - 320px)' : 'calc(100vh - 150px)'
                        }}
                      />
                    </Form>
                  </div>
                )}
              </Card>
            </Spin> */}
          </div>
        </VisibilityContainer>
        {/* )} */}

        {this.canReview && (
          <VisibilityContainer forceRender visible={this.state.reviewOpened}>
            <RequestTimeReview
              ref={ref => this._review = ref}
              opened={this.state.reviewOpened}
              setCountReview={this.setCountReviewPending}
              onClose={() => this.setReviewOpened(false)}
              reload={this.forceRead}
              showDeleteActivity={this.showDeleteActivity}
            />
          </VisibilityContainer>
        )}
        <RequestHistory
          ref={this.onRefHistory}
        />
      </div>
    )
  }
}

export default EditTime