import PropTypes from 'prop-types';
import { Input, Spin } from 'antd';
import { CalendarOutlined, ClockCircleOutlined, FieldTimeOutlined, MessageOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';

import { BaseModal } from 'src/components';
import { lib, request } from 'src/Utils';
import _h from 'src/pages/DashboardApp/Submission/helper';

class DialogConvertDelta extends BaseModal {
  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      // ----- required fields -----
      date: null,
      user: null,
      msMin: 0,
      msWorking: 0,
      // ---------------------------
    }
  }

  onRefNote = (ref) => {
    this._note = ref
    this._timeout = setTimeout(() => this._note && this._note.focus(), 250)
  }

  getDialogProps = () => ({
    title: 'Convert Working at ' + moment(this.state.date).format('MMM YYYY'),
    destroyOnClose: true,
    width: 720,
    okText: 'Convert',
    onOk: this.convert,
  })

  getMsDelta = () => this.state.msWorking - this.state.msMin

  convert = () => {
    this.setState({ isLoading: true }, () => {
      request({
        method: 'post',
        urlKey: 'office-working-duration',
        data: {
          date: this.state.date,
          user: this.state.user,
          actual_duration: lib.time.getNumberTime(this.state.msWorking),
          convert_duration: lib.time.getNumberTime(this.getMsDelta()),
          minimum_duration: lib.time.getNumberTime(this.state.msMin),
          note: get(this._note, ['resizableTextArea', 'textArea', 'value'], ''),
        },
        onSuccess: this.convertSuccess,
        onFailed: this.convertFailed,
      })
    })
  }

  convertSuccess = () => {
    this.hide(null, this.props.onConverted, true)
  }

  convertFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.setState({ isLoading: false })
  }

  renderContent = () => {
    const msDelta = this.getMsDelta()

    return (
      <Spin spinning={this.state.isLoading}>
        <div>
          <div style={{ padding: '0 8px' }}>
            <div style={{ fontSize: '14px' }}>
              <table className="dialog-user-leave-table">
                <colgroup>
                  <col style={{ width: '140px' }} />
                  <col style={{ width: '30px' }} />
                  <col style={{ width: '420px' }} />
                </colgroup>
                <tbody>
                  <tr className="row-reason hover-smooth">
                    <td className="cell-label">
                      <CalendarOutlined style={{ marginRight: '12px' }} />
                      Date
                    </td>
                    <td>:</td>
                    <td className="cell-value">
                      {moment(this.state.date).format('MMMM YYYY')}
                    </td>
                  </tr>
                  <tr className="row-reason hover-smooth">
                    <td className="cell-label">
                      <ClockCircleOutlined style={{ marginRight: '12px' }} />
                      Min. Hours
                    </td>
                    <td>:</td>
                    <td className="cell-value">
                      {_h._overtime.renderDuration(this.state.msMin)}
                    </td>
                  </tr>
                  <tr className="row-reason hover-smooth">
                    <td className="cell-label">
                      <FieldTimeOutlined style={{ marginRight: '12px' }} />
                      Hours (TW)
                    </td>
                    <td>:</td>
                    <td className="cell-value">
                      <span
                        className={classNames({
                          'c-error': this.state.msWorking < this.state.msMin,
                          'c-limegreen': this.state.msWorking > this.state.msMin,
                        })}
                      >
                        {_h._overtime.renderDuration(this.state.msWorking) || '-'}
                      </span>
                    </td>
                  </tr>
                  <tr className="row-reason hover-smooth">
                    <td className="cell-label">
                      <MessageOutlined style={{ marginRight: '12px' }} />
                      Note
                    </td>
                    <td>:</td>
                    <td className="cell-value">
                      <Input.TextArea
                        ref={this.onRefNote}
                        autoFocus
                        showCount
                        spellCheck={false}
                        maxLength={200}
                        placeholder="Confirm Note"
                        autoSize={{ minRows: 2, maxRows: 2 }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="ant-modal-body" style={{ paddingTop: 0, marginTop: '12px' }}>
            <div
              className={classNames({ 'c-limegreen': msDelta > 0, 'c-error': msDelta < 0 })}
              style={{ fontSize: '24px', textAlign: 'center' }}
            >
              {_h._overtime.renderDuration(msDelta)}
            </div>
            <div style={{ color: 'rgba(0, 0, 0, 0.5)', fontWeight: 500, marginTop: '12px', textAlign: 'center' }}>
              The time above will be converted to <b>Leave Balance</b>.
            </div>
          </div>
        </div>
      </Spin>
    )
  }
}

DialogConvertDelta.propTypes = {
  ...DialogConvertDelta.propTypes,
  onConverted: PropTypes.func,
}

DialogConvertDelta.defaultProps = {
  ...DialogConvertDelta.defaultProps,
  onConverted: () => null,
}

export default DialogConvertDelta