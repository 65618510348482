import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom'
import { message, Row, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { BaseComponent, PageError } from 'src/components'
import { Admin, DashboardApp, Login } from 'src/pages'
import { lib, permissions, request } from 'src/Utils'
import _h from 'src/pages/DashboardApp/Submission/helper';

class Routes extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: !!lib.ls.getUserToken().access_token,
      errorResponse: null,
    }

    window.lib = lib
    window.permissions = permissions
	  window._h = _h
  }

  readCompanies = () => {
    this.setThisState({ isLoading: true }, async () => {
      await request({
        urlKey: 'read-companies',
        onSuccess: this.readCompaniesSuccess,
        onFailed: this.onFailed,
      })
    })
  }

  readCompaniesSuccess = (response) => {
    if (!response.data.length) {
      message.error('You are not in any company. Contact your company to invite you to this application.', 3)
      this.onFailed()
      return
    }

    lib.ls.setCompanies(response.data)

    this.readMe()
  }

  readMe = () => {
    request({
      urlKey: 'read-myUser',
      onSuccess: this.readMeSuccess,
      onFailed: this.onFailed,
    })
  }

  readMeSuccess = (response) => {
    lib.ls.setCurrentUser(response.data)
    this.setThisState({ isLoading: false, errorResponse: null })
  }

  onFailed = (error) => {
    // lib.ls.removeUserToken()
    this.setThisState({ isLoading: false, errorResponse: error.response })
  }

  didMount() {
    if (this.state.isLoading) {
      this.readCompanies()
    }
  }

  render() {
    if (this.state.isLoading) {
      return (
        <Row justify='center' align='middle' style={{ height: '100vh' }}>
          <Spin spinning indicator={<LoadingOutlined style={{ fontSize: '64px' }} />} />
        </Row>
      )
    }

    if (this.state.errorResponse) {
      return (
        <PageError
          errorResponse={this.state.errorResponse}
          onReload={this.readCompanies}
          strGoBack="Sign out"
          onGoBack={() => {
            lib.ls.removeUserToken()
            window.location.reload()
          }}
        />
      )
    }

    return (
      <Switch>
        <Route
          exact
          path='/login'
          // component={Login}
          render={routeProps => lib.ls.getUserToken().access_token ? (
            <Redirect to='/' />
          ) : (
            <Login {...routeProps} />
          )}
        />
        <Route exact path='/admin' component={Admin} />
        <Route
          path='/'
          render={routeProps => lib.ls.getUserToken().access_token ? (
            <DashboardApp {...routeProps} />
          ) : (
            <Redirect to={`/login?next=${routeProps.location.pathname}`} />
          )}
        />
        <Route path='*' render={() => 'tidak ditemukan'} />
      </Switch>
    )
  }
}

export default Routes
