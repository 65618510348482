import React from 'react'
import PropTypes from 'prop-types'
import { Result, Row, Col, Button, message } from 'antd'
import { get, isEmpty } from 'lodash'

import BaseComponent from './BaseComponent'

const STATUSES = ['404', '403', '500']
const UNKNOWN_MESSAGE = 'Unknown error message, please try again later.'

class PageError extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      ...this.getInitialState(props),
      isReloading: false,
    }
  }

  // param "props" must be object
  getInitialState = (props) => {
    // objReturn with default value
    const objReturn = { status: STATUSES[0], title: this.props.defaultTitle, subTitle: this.props.defaultSubTitle }
    let errorStatus = `${get(props.errorResponse, ['status'], '')}` // default must be string kosong
    if (errorStatus) {
      objReturn.title = errorStatus
      if (STATUSES.some(status => status === errorStatus)) {
        objReturn.status = errorStatus
      }
    }
    const data = get(props.errorResponse, ['data'])
    if (typeof data === 'object') {
      if (!isEmpty(data)) {
        if (data.detail) {
          if (data.detail === 'Connection to Server Failed!') {
            objReturn.title = data.detail
            objReturn.subTitle = 'Network connection refused. Please check your connection!'
          } else {
            objReturn.subTitle = data.detail
          }
        }
      }
    } else {
      if (errorStatus) {
        switch (errorStatus) {
          case '404': {
            objReturn.subTitle = `Invalid URL Request, maybe the URL API doesn't exist or has been moved.`
            break
          }
          case '500': {
            objReturn.subTitle = 'Internal server error, please try again later.'
            break
          }
          default: break
        }
      }
    }
    if (this.props.withErrorMessage) {
      message.error({
        key: 'read-error',
        content: `Error with status ${objReturn.status}, detail: ${objReturn.subTitle}`,
        duration: 1.5,
      })
    }

    return objReturn
  }

  getGoBackElem = () => {
    const { strGoBack, history, onGoBack } = this.props
    if (history || onGoBack) {
      return (
        <Col>
          <Button onClick={this.onGoBack}>
            {strGoBack}
          </Button>
        </Col>
      )
    }
    return null
  }

  onGoBack = () => {
    if (this.props.onGoBack) {
      this.props.onGoBack()
    } else {
      // mungkin akan tidak sesuai harapan, lebih baik kirim props onGoBack
      this.props.history.goBack()
    }
  }

  onPreReload = () => {
    this.setThisState({ isReloading: true }, () => {
      this.props.onReload('isReloading', (isSuccess) => {
        const willState = { isReloading: false }
        if (!isSuccess) {
          const obj = this.getInitialState(this.props)
          for (const key in obj) {
            willState[key] = obj[key]
          }
        }

        this.setThisState(willState)
      })
    })
  }

  render() {
    const { className, extra, onReload } = this.props
    const { status, title, subTitle, isReloading } = this.state

    return (
      <Result
        className={className}
        status={status} // must be string
        title={title}
        subTitle={subTitle}
        extra={
          extra || (
            <Row type='flex' justify='center' gutter={[12, 12]}>
              {this.getGoBackElem()}
              {onReload && (
                <Col>
                  <Button type='primary' loading={isReloading} onClick={this.onPreReload}>
                    Try Again
                  </Button>
                </Col>
              )}
            </Row>
          )
        }
      />
    )
  }
}

PageError.propTypes = {
  errorResponse: PropTypes.object,
  defaultTitle: PropTypes.node,
  defaultSubTitle: PropTypes.node,
  history: PropTypes.object,
  className: PropTypes.string,
  extra: PropTypes.node,
  withErrorMessage: PropTypes.bool,
  strGoBack: PropTypes.string,
  onGoBack: PropTypes.func,
  onReload: PropTypes.func, // must have callback parameter
}

PageError.defaultProps = {
  errorResponse: null,
  defaultTitle: 'Error',
  defaultSubTitle: UNKNOWN_MESSAGE,
  className: '',
  extra: null,
  withErrorMessage: true,
  strGoBack: 'Go Back',
  onGoBack: null,
  onReload: null,
}

export default PageError