import React from 'react'
import { Avatar, Button, Card, Col, Form, Input, message, Row, Upload } from 'antd'
import { ArrowLeftOutlined, CameraFilled, SaveOutlined, UserOutlined } from '@ant-design/icons'
import { get } from 'lodash'

import { BaseComponent } from 'src/components'
import fields from '../../UserSettings/fields'
import Roles from './Roles'
import { lib, request } from 'src/Utils'

class UserDetail extends BaseComponent {
  constructor(props) {
    super(props)

    this.formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        lg: { span: 24 },
        xl: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        lg: { span: 24 },
        xl: { span: 12 },
      },
    }

    this.myProfile = lib.ls.getCurrentUser()

    this.state = {
      isSaving: false,
      thumbnail: props.user.photo,
    }
  }

  getInitialValue = (field) => {
    switch(field.type) {
      case 'upload': {
        return undefined
      }
      default: {
        return this.props.user[field.name] || field.initialValue
      }
    }
  }

  getInput = (field) => {
    switch(field.type) {
      case 'input': {
        return (
          <Input
            placeholder={field.placeholder || field.label}
            onPressEnter={this.onSave}
            {...field.inputProps}
          />
        )
      }
      default: {
        return null
      }
    }
  }

  getInitialDataPhoto = () => {
    if (!this.props.user.photo) return undefined

    return [{
      uid: new Date().getTime(),
      name: this.props.user.photo,
      status: 'done',
      url: this.props.user.photo,
    }]
  }

  onLoadReader = () => {
    this.setThisState({ thumbnail: get(this.reader, 'result') })
  }

  onChangeThumbnail = (e) => {
    this.reader = new FileReader()
    this.reader.onloadend = this.onLoadReader
    const file = get(e.fileList[e.fileList.length - 1], 'originFileObj')
    if (file) this.reader.readAsDataURL(file)
    else {
      this.reader = null
      this.onLoadReader()
    }

    return e && e.fileList
  }

  onSave = async () => {
    const basicValues = await this._basicForm.validateFields()

    const permissionValues = await this._roles._permissionForm.validateFields()

    const data = new FormData()
    data.set('first_name', basicValues.first_name)
    data.set('last_name', basicValues.last_name)
    data.set('employee_id', basicValues.employee_id)
    data.set('address1', basicValues.address1)
    data.set('address2', basicValues.address2)
    data.set('is_admin', basicValues.is_admin)
    if (Array.isArray(basicValues.thumbnail) && basicValues.thumbnail.length > 0 && basicValues.thumbnail[0].originFileObj) {
      data.set('photo', basicValues.thumbnail[0].originFileObj)
    }

    // // not working with form data
    // // Append role values
    // for (const roleAlias in permissionValues) {
    //   if (permissionValues[roleAlias]) {
    //     data.append('roles', roleAlias)
    //   }
    // }

    // // When no roles, send role key with blank string
    // if (!data.get('roles'))
    //   data.set('roles', '')

    const rolesData = []
    for (const roleAlias in permissionValues) {
      if (permissionValues[roleAlias]) {
        rolesData.push(roleAlias)
      }
    }

    this.setThisState({ isSaving: true }, () => {
      request({
        method: 'patch',
        urlKey: 'change-companyUsers',
        args: [this.props.user.alias],
        data,
        onSuccess: this.saveSuccess,
        onFailed: this.saveFailed,
      })

      if (this._roles._permissionForm.isFieldsTouched()) {
        request({
          method: 'patch',
          urlKey: 'change-companyUsers',
          args: [this.props.user.alias],
          data: {roles: rolesData},
          onSuccess: this.saveSuccess,
          onFailed: this.saveFailed,
        })
      }
    })
  }

  saveSuccess = (response) => {
    message.success({ key: 'patch-user-success', content: 'User data has been saved' })

    if (response.data.email === this.myProfile.email) {
      this.myProfile = { ...this.myProfile, ...response.data }
      lib.ls.setCurrentUser(this.myProfile)
    }

    this.props.updateUser(response.data)
    this.props.onClose()
  }

  saveFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.setThisState({ isSaving: false })
  }

  didMount() {
    setTimeout(() => this.forceUpdate(), 1000)
  }

  render() {
    return (
      <div style={{ padding: '24px' }}>
        <Row justify='space-between' gutter={[12, 12]} style={{ marginBottom: '24px' }}>
          <Col>
            <Row align='middle'>
              <Col>
                <div className='ant-page-header-back'>
                  <div className='ant-page-header-back-button' onClick={() => this.props.onClose()}>
                    <ArrowLeftOutlined />
                  </div>
                </div>
              </Col>
              <Col style={{ fontSize: '21px', fontWeight: 500 }}>
                Setting Managed User
              </Col>
            </Row>
          </Col>
          <Col>
            <Row gutter={[12, 12]}>
              <Col>
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  loading={this.state.isSaving}
                  onClick={this.onSave}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col xs={24} sm={24} lg={12}>
            <Card className='app-card' style={{ height: '100%' }}>
              <div style={{ marginTop: '12px' }}>
                <Form
                  ref={ref => {this._basicForm = ref}}
                  name='basicForm'
                  {...this.formItemLayout}
                  onValuesChange={this.onFieldsTouched}
                  initialValues={{
                    is_admin: this.props.user.is_admin,
                  }}
                >
                  <Row justify='center' style={{ marginBottom: '24px', padding: '12px' }}>
                    <Col style={{ position: 'relative' }}>
                      <Avatar
                        icon={<UserOutlined />}
                        src={this.state.thumbnail}
                        size={154}
                        style={{ border: '1px solid #e8e8e8'  }}
                      />
                      <Form.Item
                        name='thumbnail'
                        className='form-item-photo'
                        valuePropName='fileList'
                        getValueFromEvent={this.onChangeThumbnail}
                        initialValue={this.getInitialDataPhoto()}
                      >
                        <Upload beforeUpload={() => false} maxCount={1} itemRender={() => null} accept='image/*'>
                          <Row justify='center' align='middle' className='btn-take-photo'>
                            <CameraFilled />
                          </Row>
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Row justify='center' style={{ fontSize: '18px', fontWeight: 500, fontStyle: 'italic', marginTop: '12px' }}>
                        {this.props.user.email}
                      </Row>
                    </Col>
                  </Row>

                  {fields.map(field => (
                    <Form.Item
                      key={field.name}
                      name={field.name}
                      label={field.label}
                      {...field.formProps}
                      initialValue={this.getInitialValue(field)}
                    >
                      {this.getInput(field)}
                    </Form.Item>
                  ))}
                  <Form.Item shouldUpdate noStyle name='is_admin'>
                    {() => null}
                  </Form.Item>
                </Form>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <Card className='app-card' style={{ height: '100%' }}>
              <Roles
                ref={ref => {this._roles = ref}}
                _basicForm={this._basicForm}
                forceUpdate={() => this.forceUpdate()}
                isOwner={this.myProfile.is_owner}
                user={this.props.user}
              />
              {/* <Form ref={ref => {this._permissionForm = ref}} name='permissionForm'>
                <Row justify='space-between'>
                  <div style={{ fontSize: '21px', fontWeight: 500 }}>
                    Roles (Dummy)
                  </div>
                  <Col>
                    <Form.Item shouldUpdate noStyle>
                      {(form) => (
                        <Form.Item name='temp_is_admin' initialValue={false} noStyle>
                          <this.ButtonAdmin />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <div style={{ marginTop: '12px' }}>
                  {exampleHelper.map(module => (
                    <div key={module.codename}>
                      <div>
                        <div
                          style={{ borderLeft: '3px solid #1890ff', borderBottom: '1px solid #1890ff', backgroundColor: '#d9ecfd', padding: '8px', cursor: 'pointer' }}
                          // onClick={() => this.onCollapse(!collapsed)}
                        >
                          <Row align='middle'>
                            <RightOutlined rotate={90} style={{ marginRight: '12px' }} />
                            <b>{module.name}</b>
                          </Row>
                        </div>
                      </div>
                      <Row type='flex' gutter={[12, 12]} style={{ marginBottom: '24px' }}>
                        {module.roles.map(role => (
                          <Col key={role.name} span={24} style={{ marginBottom: '12px' }}>
                            <Divider style={{ margin: '6px 0' }}>
                              {role.name}
                            </Divider>
                            <Row type='flex' justify='center' gutter={[12, 12]} style={{ marginTop: 0, padding: '0 12px' }}>
                              {role.permissions.map(permission => (
                                <Col key={permission.codename}>
                                  <Form.Item noStyle name={permission.codename} initialValue={false} valuePropName='checked'>
                                    <this.ButtonPermission permission={permission} />
                                  </Form.Item>
                                </Col>
                              ))}
                            </Row>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  ))}
                </div>
              </Form> */}
            </Card>
          </Col>
          {/* <Col span={24}>
            <Card className='app-card'>
              <ManageUsers />
            </Card>
          </Col> */}
        </Row>
      </div>
    )
  }
}

UserDetail.defaultProps = {
  onClose: () => {},
  updateUser: () => {},
}

export default UserDetail