import PropTypes from 'prop-types';
import { Avatar, Col, Row } from 'antd'
import { CoffeeOutlined, CommentOutlined, IdcardOutlined, UserOutlined } from '@ant-design/icons'
import classNames from 'classnames';

import BasePopoverContentUser from '../YearlyStaffLeave/PopoverContentUser';
import UserPhotoStatus from '../Base/UserPhoto';
import { lib } from 'src/Utils'
import _h from 'src/pages/DashboardApp/Submission/helper';

class PopoverContentUser extends BasePopoverContentUser {
  render() {
    const { userOvertime } = this.props

    return (
      <div style={{ maxWidth: '360px', ...this.props.style }}>
        <div>
          <Row wrap={false} gutter={[12, 12]} style={{ padding: '8px 16px 12px 12px' }}>
            <Col flex="none">
              <UserPhotoStatus
                size={64}
                user={userOvertime.user}
                status={userOvertime.status}
              />
              {/* <Avatar
                size={64}
                // icon={<UserOutlined />}
                src={userOvertime.user.photo}
                style={{ border: '1px solid #d5d5d5' }}
              >{userOvertime.user.first_name}</Avatar> */}
            </Col>
            <Col flex="auto">
              <Row wrap={false} style={{ fontWeight: 500 }}>
                <Col flex='auto' className='text-truncate'>
                  {lib.getFullName(userOvertime.user)}
                </Col>
                <Col flex='none'>
                  <span
                    style={{ marginLeft: '6px' }}
                    className={classNames({
                      'approved-note': _h.isApproved(userOvertime),
                      'c-error': _h.isAborted(userOvertime),
                      'c-gray': _h.isWaiting(userOvertime),
                    })}
                  >
                    ({userOvertime.status_display})
                  </span>
                </Col>
              </Row>
              <div style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                <div style={{ fontWeight: 500 }}>
                  {_h._overtime.renderRange(userOvertime)}
                </div>
                <div className='webkit-box two-lines'>
                  <CommentOutlined style={{ marginRight: '6px' }} />
                  {userOvertime.note}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

PopoverContentUser.propTypes = {
  // ...PopoverContentUser.propTypes,
  userOvertime: PropTypes.object.isRequired,
}

PopoverContentUser.defaultProps = {
  ...PopoverContentUser.defaultProps,
}

export default PopoverContentUser