import PropTypes from 'prop-types'
import { Input, Spin } from 'antd'

import BaseField from './Base'

class FieldNotes extends BaseField {
  getPatchData = () => ({ notes: this.state.value })

  // getValueFromEvent = (value) => value

  renderInput = () => (
    <Input.TextArea
      {...this.getDefaultInputProps()}
    />
  )
}

FieldNotes.propTypes = {
  ...FieldNotes.propTypes,
  value: PropTypes.string,
}

FieldNotes.defaultProps = {
  ...FieldNotes.defaultProps,
  value: null,
}

export default FieldNotes