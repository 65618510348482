import React from 'react'
import { Avatar, Col, Row } from 'antd'
import { IdcardOutlined, UserOutlined } from '@ant-design/icons'

import { BaseComponent } from 'src/components'
import { lib } from 'src/Utils'

class PopoverContentUser extends BaseComponent {
  render() {
    const { user } = this.props

    return (
      <div onClick={e => {e.stopPropagation()}}>
        <div>
          <Row style={{ padding: '8px 16px 12px 12px' }}>
            <Col>
              <Avatar
                size={64}
                icon={<UserOutlined />}
                src={user.photo}
                style={{ border: '1px solid #d5d5d5' }}
              />
            </Col>
            <Col style={{ marginLeft: '12px' }}>
              <div style={{ fontWeight: 500 }}>
                {lib.getFullName(this.props.user)}
              </div>
              <div style={{ color: user.isManager ? '#1890ff' : '#46ad6b' }}>
                <IdcardOutlined style={{ marginRight: '6px' }} />
                {user.isManager ? 'Manager' : 'Member'}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default PopoverContentUser