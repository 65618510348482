import PropTypes from 'prop-types';

import BaseAreaByGrade from 'src/pages/DashboardApp/DashboardManager/ChartProduction/AreaByGrade';

class AreaByGrade extends BaseAreaByGrade {
  constructor(props) {
    super(props)

    // this.title = 'Area by Grade'
    this.a = 'KPI m² / hour'
    this.b = 'Average m² / job'
  }

  setAverage = (productionJobs, nextProps = this.props) => {
    // const prodDays = []
    let totalDays = 0, totalJobs = productionJobs.length

    // for (const i in productionJobs) {
    //   if (prodDays.indexOf(productionJobs[i].prod_day) === -1) {
    //     totalDays += 1
    //     prodDays.push(productionJobs[i].prod_day)
    //   }
    // }

    this.averagePerDay = nextProps.totalWorkingHours
      ? Math.round(this.totalValue / nextProps.totalWorkingHours)
      : 'N/A'
    this.averagePerJob = Math.round(this.totalValue / totalJobs)
  }

  childComponentWillReceiveProps(nextProps) {
    if (this.props.totalWorkingDays !== nextProps.totalWorkingDays) {
      this.convertData(nextProps.productionJobs, nextProps)
    }
  }
}

AreaByGrade.propTypes = {
  ...AreaByGrade.propTypes,
  totalWorkingHours: PropTypes.number.isRequired,
}

AreaByGrade.defaultProps = {
  ...AreaByGrade.defaultProps,
}

export default AreaByGrade