import React from 'react'
import { Link, Redirect, Route, Switch } from 'react-router-dom'
import { Avatar, BackTop, Button, Col, Divider, Layout, Menu, Row } from 'antd'
import { BlockOutlined, CaretUpOutlined, HomeFilled, VerticalAlignTopOutlined } from '@ant-design/icons'

import { BaseComponent, PageError } from 'src/components'
import allRoutes from './routes'
import routesMenu from './routesMenu'
import routesMenuCompany from './routesMenuCompany'
import { MobileMenu } from './Components'
import { lib } from 'src/Utils'
// import logoBanner from 'src/assets/logo_banner_white.png'
// import logoBanner from 'src/assets/logo2_banner_white.png'
// import logoBanner from 'src/assets/logo3_banner_white.png'
import logoBanner from 'src/assets/no_logo_white.png'
import logoBanner2 from 'src/assets/no_logo.png'

const { Content, Sider } = Layout

const ALLOWED_ROUTE_PROPS = ['exact', 'path', 'component', 'redirect', 'render']

class DashboardApp extends BaseComponent {
  constructor(props) {
    super(props)

    const userToken = lib.ls.getUserToken()
    this.myAccount = userToken.account

    this.myProfile = lib.ls.getCurrentUser()
    this.state = {
      collapsed: false,
      collapsedCompany: false,
      isMobile: this.getIsMobile(),
    }
  }

  getIsMobile = () => this.mutableScreen.isMobile = window.innerWidth < 768

  getCompanyPhoto = () => {
    try {
      return lib.ls.getObjCompanies()[lib.ls.getUserToken().selectedCompany].photo
    } catch(e) {}

    return null
  }

  getFullName = () => {
    let fullName = ''
    let firstName = this.myProfile.first_name
    let lastName = this.myProfile.last_name
    if (!firstName && !lastName) {
      firstName = this.myAccount.first_name
      lastName = this.myAccount.last_name

      if (!firstName && !lastName) {
        firstName = this.myAccount.email
      }
    }

    fullName = firstName
    if (lastName) fullName = `${firstName} ${lastName}`

    return fullName
  }

  onResize = () => {
    this.setThisState({ isMobile: this.getIsMobile() })
  }

  onCollapse = (collapsed) => {
    this.setThisState({ collapsed })
  }

  renderMenus = (menus) => {
    return menus.map(this.renderMenu)
  }

  renderMenu = (menu) => {
    const isShowing = menu.name && (menu.auth ? menu.auth(this.myProfile) : true)

    if (menu.menu) {
      return isShowing ? (
        <Menu.SubMenu key={menu.name} title={menu.name} icon={menu.icon}>
          {this.renderMenus(menu.menu)}
        </Menu.SubMenu>
      ) : null
    }

    return isShowing ? (
      <Menu.Item key={menu.path} icon={menu.icon}>
        <Link to={menu.path}>
          {menu.name}
        </Link>
      </Menu.Item>
    ) : null
  }

  renderRoute = (route) => {
    const isAuth = route.auth ? route.auth(this.myProfile) : true

    if (!isAuth) {
      const getRedirect = (r) => (
        <Redirect
          key={r.path}
          from={r.path}
          to={r.redirect}
        />
      )

      return !route.redirect ? null : (
        route.menu ? route.menu.map(r => getRedirect({ ...r, redirect: route.redirect })) : getRedirect(route)
      )
    }

    if (route.menu) {
      return route.menu.map(this.renderRoute)
    }

    const routeProps = {}
    for (const key in route) {
      if (ALLOWED_ROUTE_PROPS.includes(key)) {
        routeProps[key] = route[key]
      }
    }

    return <Route key={route.path} {...routeProps} />
  }

  getSelectedKey = () => {
    for (const menu of allRoutes) {
      if (menu.menu) {
        for (const j in menu.menu) {
          if (this.props.location.pathname === menu.menu[j].path) {
            return this.getMenuKey(menu.menu[j])
          }
          if (`${this.props.location.pathname}/` === menu.menu[j].path) {
            return this.getMenuKey(menu.menu[j])
          }
        }
      } else if (this.props.location.pathname === menu.path) {
        return this.getMenuKey(menu)
      }
    }

    return null
  }

  getMenuKey = (menu) => menu.menuKey || menu.path

  getSelectedKeys = () => {
    return [this.getSelectedKey()]
  }

  onMenuClick = ({ key }) => {
    this.props.history.push(key)
  }

  toggleSider = () => {
    this.setThisState({ collapsed: !this.state.collapsed })
  }

  onCollapseCompany = () => {
    let nextCollapsedCompany = !this.state.collapsedCompany
    if (this.state.collapsed) {
      if (nextCollapsedCompany) nextCollapsedCompany = false
    }

    this.setThisState({
      collapsedCompany: nextCollapsedCompany,
      collapsed: this.state.collapsed && nextCollapsedCompany,
    })
  }

  renderMenuCompany = (company) => {
    return (
      <Menu.Item
        {...this.getMenuProps(company)}
        key={company.alias}
        icon={<HomeFilled style={{ visibility: company.alias === lib.ls.getUserToken().selectedCompany ? 'visible': 'hidden' }} />}
        onClick={() => {this.switchCompany(company)}}
        title={`Click to view your activity in company "${company.name}"`}
      >
        {company.name}
      </Menu.Item>
    )
  }

  getMenuProps = (company) => {
    if (!company.user_active) {
      return {
        disabled: true
      }
    }

    if (!company.user_registered) {
      return {
        disabled: true,
        onMouseEnter: () => {
          // console.log('onMouseEnter', company)
        }
      }
    }
  }

  switchCompany = (company) => {
    // When select selected company, do nothing
    if (this.myProfile.company.alias === company.alias)
      return

    if (company.user_registered && company.user_active) {
      const userToken = lib.ls.getUserToken()
      userToken.selectedCompany = company.alias
      lib.ls.setUserToken(userToken)
      window.location.replace('/')
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      if (this.state.isMobile) {
        this.setThisState({ collapsed: true })
      }
    }
  }

  didMount = () => {
    window.addEventListener('resize', this.onResize)
  }

  unmount = () => {
    window.removeEventListener('resize', this.onResize)
  }

  render() {
    const { collapsed, isMobile } = this.state
    const selectedKeys = this.getSelectedKeys()

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          className={collapsed && 'only-xs-hide only-sm-hide'}
          collapsible
          breakpoint='lg'
          collapsed={collapsed}
          onCollapse={this.onCollapse}
          // width={250}
          width={isMobile ? '100vw' : 250}
          collapsedWidth={isMobile ? 1 : 80}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            zIndex: 1000,
          }}
        >
          <div
            style={{ display: collapsed ? 'none' : 'block', cursor: 'pointer' }}
            onClick={() => {
              if (this.props.location.pathname !== '/')
                this.props.history.push('/')
            }}
          >
            <Row className='container-no-logo' style={{ padding: '8px 24px' }}>
              <img
                className='logo-opacity'
                src={logoBanner}
                style={{ width: '100%' }}
              />
              <img
                className='no-logo-opacity'
                src={logoBanner2}
              />
            </Row>
            <Divider
              type='horizontal'
              style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', margin: '4px 0' }}
            />
          </div>
          <Row className='collapse-menu-company' onClick={this.onCollapseCompany}>
            <Col>
              <Avatar
                size={40}
                // icon={<UserOutlined />}
                icon={<BlockOutlined />}
                // src='https://s3.amazonaws.com/tds3_avatars/453021_logo.jpg'
                // src={this.getCompanyPhoto()}
                src={this.myProfile.company.photo}
              />
            </Col>
            {!collapsed && (
              <Col style={{ color: '#fff', width: 'calc(100% - 12px - 40px)', marginLeft: '12px' }}>
                <Row align='middle'>
                  <Col style={{ width: 'calc(100% - 18px)', marginRight: '4px' }}>
                    <div className='text-truncate' style={{ fontWeight: 500 }} title={this.myProfile.company.name}>
                      {this.myProfile.company.name}
                    </div>
                    <div className='text-truncate' title={this.getFullName()}>
                      {this.getFullName()}
                    </div>
                  </Col>
                  <Col>
                    <CaretUpOutlined rotate={this.state.collapsedCompany ? 180 : 0} />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          <Menu theme='dark' mode='inline' selectedKeys={selectedKeys} style={{ paddingBottom: '48px' }}>
            {!this.state.collapsedCompany && !collapsed && (
              this.renderMenus(routesMenuCompany)
            )}
            {!this.state.collapsedCompany && !collapsed && (
              <>
                {/* <Menu.Item key='/projects-tasks/'>
                  <Link to='/projects-tasks/'>
                    Projects & Permanent Tasks
                  </Link>
                </Menu.Item>
                <Menu.Item key='/user-settings/'>
                  <Link to='/user-settings/'>
                    User Settings
                  </Link>
                </Menu.Item>
                {this.myProfile.is_owner && (
                  <>
                    <Menu.Item key='/manage-users/'>
                      <Link to='/manage-users/'>
                        Manage Users
                      </Link>
                    </Menu.Item>
                    <Menu.Item key='/manage-teams/'>
                      <Link to='/manage-teams/'>
                        Manage Teams
                      </Link>
                    </Menu.Item>
                  </>
                )} */}
                {/* <Menu.Item>
                  Payoneer Settings
                </Menu.Item>
                <Menu.Item>
                  Work Schedules
                </Menu.Item> */}
                <div style={{ padding: '0 8px' }}>
                  <Divider
                    type='horizontal'
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', margin: '12px 0' }}
                  />
                </div>
                {lib.ls.getUserToken().companies.map(this.renderMenuCompany)}
                <div style={{ padding: '0 8px' }}>
                  <Divider
                    type='horizontal'
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', margin: '12px 0' }}
                  />
                </div>
                <Menu.Item onClick={() => lib.logout(this.props.history)}>
                  Sign out
                </Menu.Item>
                <div style={{ padding: '0 8px' }}>
                  <Divider
                    type='horizontal'
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', margin: '12px 0' }}
                  />
                </div>
              </>
            )}
            {this.renderMenus(routesMenu)}
          </Menu>
        </Sider>
        { isMobile && (
          <MobileMenu
            selectedKey={this.getSelectedKey()}
            onMenuClick={this.onMenuClick}
            toggleSider={this.toggleSider}
          />
        )}
        <Layout
          style={{
            marginLeft: isMobile ? 0 : collapsed ? '80px' : '250px', // 80px & 200px = sider menu width
            marginBottom: isMobile ? '46px' : 0, // 46px = mobile menu height
          }}
        >
          <Content>
            <Switch>
              {allRoutes.map(this.renderRoute)}
              <Route
                path='*'
                render={() => (
                  <PageError
                    status='404'
                    withErrorMessage={false}
                    defaultTitle='Page Not Found'
                    defaultSubTitle='Check and make sure your path URL is correct!'
                  />
                )}
                // render={() => '404'}
              />
            </Switch>
          </Content>
        </Layout>
        <BackTop visibilityHeight={300} style={{ right: '50px', bottom: '80px' }}>
          <div className="ant-back-top-content ant-back-top-content-hovered">
            <div className='ant-back-top-icon'>
              <VerticalAlignTopOutlined />
            </div>
          </div>
        </BackTop>
      </Layout>
    )
  }
}

export default DashboardApp