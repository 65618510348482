import React from 'react'
import { Avatar, Col, Empty, Row } from 'antd'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import moment from 'moment'

import { BaseModal, DialogFullscreen } from 'src/components'
import ListItem from './ListDailyItem'
// import PhotoFullscreen from '../List/Fullscreen'

class ListDailyDialog extends BaseModal {
  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      date: moment().format(),
    }
  }

  // onRefFullscreen = (ref) => {
  //   this._fullscreen = ref
  // }

  onRefDialogFullscreen = (ref) => {
    this._dialogFullscreen = ref
  }

  getSrc = (attendance) => {
    return attendance && attendance.photo.photo
  }

  getTitle = (attendance) => {
    return `${attendance.attend_type_display} - ${moment(attendance.attendance_date).format('ddd, DD MMM YYYY HH:mm a')} (${attendance.status_display})`
  }

  getModalProps = () => ({
    title: `${moment(this.state.date).format('ddd, DD MMMM YYYY')}`,
    width: '820px',
    footer: null,
    style: this.mutableScreen.isMobile && {top: 20},
  })

  onSelectItem = (attendance, attendances) => {
    const attIndex = attendances.findIndex(att => att.alias === attendance.alias)
    this._dialogFullscreen.show(attIndex, { photos: attendances })
  }

  renderAttendances = (attendances, label) => {
    return (
      <div>
        <h2 style={{ textAlign: 'center' }}>{label}</h2>
        {attendances.length === 0
          ? <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description={`No ${label}`} />
          : attendances.map(att => (
            <ListItem key={att.alias} attendance={att} onSelect={att => this.onSelectItem(att, attendances)} />
          ))
        }
      </div>
    )
  }

  prevDate = () => {
    this.setThisState({ date: moment(this.state.date).subtract(1, 'day') })
  }

  nextDate = () => {
    this.setThisState({ date: moment(this.state.date).add(1, 'day') })
  }

  renderContent = () => {
    const { userAttendances } = this.props
    const { date } = this.state

    const currentAttendances = userAttendances.filter(att => att.date === moment(date).format('YYYY-MM-DD'))

    const checkinAttendances = currentAttendances.filter(att => att.attend_type === 'checkin')
    const checkoutAttendances = currentAttendances.filter(att => att.attend_type === 'checkout')

    return (
      <div style={{ position: 'relative' }}>
        {/* Go to Previous Date */}
        <div
          className='modal-arrow modal-arrow-left app-card'
          onClick={this.prevDate}
          style={this.mutableScreen.isMobile
            ? {left: '-45px', color: '#989898'}
            : {}
          }
        >
          <CaretLeftOutlined />
        </div>

        <Row gutter={[12, 12]}>
          <Col xs={24} sm={24} md={12} style={{ borderRight: '1px solid #e8e8e8', borderBottom: '1px solid #e8e8e8' }}>
            {this.renderAttendances(checkinAttendances, 'Check-In')}
          </Col>
          <Col xs={24} sm={24} md={12}>
            {this.renderAttendances(checkoutAttendances, 'Check-Out')}
          </Col>
        </Row>

        {/* Go to Next Date */}
        <div
          className='modal-arrow modal-arrow-right app-card'
          onClick={this.nextDate}
          style={this.mutableScreen.isMobile
            ? {right: '-45px', color: '#989898'}
            : {}
          }
        >
          <CaretRightOutlined />
        </div>

        <DialogFullscreen
          ref={this.onRefDialogFullscreen}
          getSrc={this.getSrc}
          getTitle={this.getTitle}
        />
      </div>
    )
  }
}

ListDailyDialog.defaultProps = {
  userAttendances: [],
}

export default ListDailyDialog