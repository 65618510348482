import React from 'react'
import { BackTop, Spin, Tabs } from 'antd'
import moment from 'moment'

import { BaseList } from 'src/components'
import ChartProduction from './ChartProduction'
import { lib, request } from 'src/Utils'
import QSProductionDetail from './QSProductionDetail'
import QAProductionDetail from './QAProductionDetail'
import ProductionDetail from './ProductionDetail'
import JobProgressDetail from './JobProgressDetail'
import ScrollEnd from './ScrollEnd'

class DashboardManager extends BaseList {
  constructor(props) {
    super(props)

    this.urlKey = 'read-productionJobs'
    this.pagination = false
    this.keysQueryParam = ['finished_date_after', 'finished_date_before']
  }

  // --------- override helper ---------
  getReadTargetOpts = () => null
  // -----------------------------------

  isValidParam = (key) => {
    if (key === 'finished_date_after' || key === 'finished_date_before')
      return this.isValidDate(this.objParam.finished_date)

    return this[key] !== undefined
  }

  getParam = (key, val) => {
    if (key === 'finished_date_after')
      return this.getFinishedDate({ startOf: 'month', format: undefined })

    if (key === 'finished_date_before')
      return this.getFinishedDate({ endOf: 'month', format: undefined })

    return val
  }

  beforeRead = async (keyLoading, callback) => {
    return new Promise(resolve => {
      this.setThisState({ [this.keyIsLoading]: true }, async () => {
        const prevDate = this.getFinishedDate({ subtract: [1, 'month'], startOf: 'month', format: 'YYYY-MM-DD' })
        const resPrevTargets = await this.readTargets(prevDate).catch(err => this.setThisState({ [this.keyIsLoading]: false, errorResponse: err.response }, callback))
        if (!resPrevTargets) return

        const resTargets = await this.readTargets().catch(err => this.setThisState({ [this.keyIsLoading]: false, errorResponse: err.response }, callback))
        if (!resTargets) return

        const resUserTypes = await this.readUserTypes().catch(err => this.setThisState({ [this.keyIsLoading]: false, errorResponse: err.response }, callback))
        if (!resUserTypes) return

        const resExchange = await this.readEffectiveExchange().catch(err => this.setThisState({ [this.keyIsLoading]: false, errorResponse: err.response }, callback))
        if (!resExchange) return

        const resPrevProd = await this.readProdJobs({ subtract: [1, 'month'] }).catch(err => this.setThisState({ [this.keyIsLoading]: false, errorResponse: err.response }, callback))
        if (!resPrevProd) return

        this.setThisState({
          prevTargets: resPrevTargets.data,
          targets: resTargets.data,
          prevProductionJobs: resPrevProd.data,
          effectiveExchange: resExchange.data,
          userTypes: resUserTypes.data,
        })
        resolve()
      })
    })
  }

  readUserTypes = () => {
    return new Promise((resolve, reject) => {
      request({
        urlKey: 'read-userPointTypes',
        data: { date: this.getFinishedDate({ startOf: 'month', format: 'YYYY-MM-DD' }) },
        onSuccess: res => resolve(res),
        onFailed: err => reject(err),
      })
    })
  }

  readEffectiveExchange = () => {
    return new Promise((resolve, reject) => {
      request({
        urlKey: 'read-effectiveExchange',
        data: { date: this.getFinishedDate({ startOf: 'month', format: 'YYYY-MM-DD' }) },
        onSuccess: res => resolve(res),
        onFailed: err => reject(err),
      })
    })
  }

  readTargets = async (date) => {
    return new Promise((resolve, reject) => {
      request({
        urlKey: 'read-productionTargets-byDate',
        data: {
          date: date || this.getFinishedDate({ startOf: 'month', format: 'YYYY-MM-DD' }),
        },
        onSuccess: res => resolve(res),
        onFailed: async err => {
          let success = false
          if (String(err.response.status) === `404`)
            success = await this.createTargets(date)

          if (success)
            resolve(success)
          else
            reject(err)
        },
        ...this.getReadTargetOpts({ resolve, reject }),
      })
    })
  }

  createTargets = async (date) => {
    return await request({
      method: 'post',
      urlKey: 'read-productionTargets',
      data: {
        date: date || this.getFinishedDate({ startOf: 'month', format: 'YYYY-MM-DD' }),
        working_days: 0,
        area: 0,
        amount: 0,
        total_job: 0,
      },
    })
  }

  readProdJobs = async (opts) => {
    return new Promise((resolve, reject) => {
      request({
        urlKey: 'read-productionJobs',
        data: {
          finished_date_after: this.getFinishedDate({ ...opts, startOf: 'month', format: undefined }),
          finished_date_before: this.getFinishedDate({ ...opts, endOf: 'month', format: undefined }),
          status: 'finished',
        },
        onSuccess: res => resolve(res),
        onFailed: err => reject(err),
      })
    })
  }

  // createTargets = async () => {
  //   return await request({
  //     method: 'post',
  //     urlKey: 'read-productionTargets',
  //     data: {
  //       date: moment(this.selectedDate).startOf('month').format('YYYY-MM-DD'),
  //       working_days: 0,
  //       area: 0,
  //       amount: 0,
  //       total_job: 0,
  //     },
  //   })
  // }

  // readTargets = async () => {
  //   return await request({
  //     urlKey: 'read-productionTargets',
  //     data: {
  //       date: moment(this.selectedDate).startOf('month').format('YYYY-MM-DD'),
  //     },
  //   })
  // }

  // syncTargets = async (targetAlias) => {
  //   return await request({
  //     method: 'post',
  //     urlKey: 'sync-productionTargets',
  //     args: [targetAlias],
  //     // data: {
  //     //   date: moment(this.selectedDate).startOf('month').format('YYYY-MM-DD'),
  //     // },
  //   })
  // }
  
  // readProductionJobs = async () => {
  //   await request({
  //     urlKey: 'read-productionJobs',
  //     data: {
  //       finished_date_after: moment(this.selectedDate).startOf('month').format(),
  //       finished_date_before: moment(this.selectedDate).endOf('month').format(),
  //     },
  //   })
  // }

  // readProduction = async (targetAlias) => {
  //   return await request({
  //     urlKey: 'retrieve-productionJobs',
  //     args: [targetAlias],
  //     data: {
  //       finished_date_after: moment(this.selectedDate).startOf('month').format(),
  //       finished_date_before: moment(this.selectedDate).endOf('month').format(),
  //     },
  //   })
  // }

  
  // read = async () => {
  //   let resTargets = await this.readTargets()

  //   console.log('resTargets', resTargets)

  //   if (!resTargets)
  //     return

  //   // Buat ada konfirmasi bahwa belum ada target pada bulan tersebut
  //   // if (!resTargets.data.results[0])
  //   //   resTargets = await this.createTargets()

  //   const currentTarget = resTargets.data.results[0]
  //   console.log('currentTarget', currentTarget)

  //   const productionJobs = await this.readProductionJobs()

  //   this.setThisState({ targets: resTargets.data.results, productionJobs })
  // }

  // componentDidMount() {
  //   this.read()
  // }

  isValidDate = (date) => {
    return moment(date, ['YYYY-MM', moment.ISO_8601])._isValid
  }

  isValidFinishedDate = (strUrlParam = this.props.location.search) => {
    this.objParam = lib.getObjParam(strUrlParam)

    return this.isValidDate(this.objParam.finished_date)
  }

  getFinishedDate = (objFunc = { format: ['YYYY-MM'] }) => {
    let data = moment(this.objParam.finished_date)

    for (const keyFunc in objFunc)
      data = data[keyFunc](...(
        Array.isArray(objFunc[keyFunc])
          ? objFunc[keyFunc]
          : [objFunc[keyFunc]]
      ))

    return data
  }

  onChangeDate = (date) => {
    this.props.history.replace(this.props.location.pathname + lib.getStrParam({ finished_date: moment(date).format('YYYY-MM') }))
  }

  prevMonth = () => {
    this.onChangeDate(
      moment(this.objParam.finished_date).subtract(1, 'month').format('YYYY-MM')
    )
  }

  nextMonth = () => {
    this.onChangeDate(
      moment(this.objParam.finished_date).add(1, 'month').format('YYYY-MM')
    )
  }

  didMount() {
    if (this.isValidFinishedDate())
      this.forceRead()
    else
      this.props.history.replace(this.props.location.pathname + lib.getStrParam({ finished_date: moment().format('YYYY-MM') }))
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentParam = lib.getObjParam(this.props.location.search)
    const nextParam = lib.getObjParam(nextProps.location.search)
    if (currentParam.finished_date !== nextParam.finished_date) {
      this.isValidFinishedDate(nextProps.location.search)
      this.forceRead()
    }
  }

  renderChartProduction() {
    return (
      <ChartProduction
        // setProductionJobs={this.setProductionJobs}
        // setDate={this.setDate}
        loading={this.state[this.keyIsLoading]}
        dateString={this.getFinishedDate()}

        targets={this.state.targets}
        prevTargets={this.state.prevTargets}
        productionJobs={this.__list.filter(job => job.status === 'finished')}
        prevProductionJobs={this.state.prevProductionJobs}
        allProductionJobs={this.__list}

        reload={this.forceRead}
        onChangeDate={this.onChangeDate}
        prevMonth={this.prevMonth}
        nextMonth={this.nextMonth}
        setTargets={targets => {this.setThisState({ targets })}}
      />
    )
  }

  renderQSProductionDetail() {
    return (
      <div style={{ marginTop: '24px' }}>
        <QSProductionDetail
          ref={this.onRefQS}
          loading={this.state[this.keyIsLoading]}
          dateString={this.getFinishedDate()}
          productionJobs={this.__list.filter(job => job.status === 'finished')}
          effectiveExchange={this.state.effectiveExchange}
          userTypes={this.state.userTypes}
        />
      </div>
    )
  }

  renderQAProductionDetail() {
    return (
      <div style={{ marginTop: '24px' }}>
        <QAProductionDetail
          loading={this.state[this.keyIsLoading]}
          dateString={this.getFinishedDate()}
          productionJobs={this.__list.filter(job => job.status === 'finished')}
          effectiveExchange={this.state.effectiveExchange}
          userTypes={this.state.userTypes}
        />
      </div>
    )
  }

  renderProductionDetail() {
    return (
      <div className='production-detail-container' style={{ marginTop: '24px' }}>
        <Tabs type='card'>
          <Tabs.TabPane tabKey='finished' tab='Production Detail'>
            <ProductionDetail
              ref={this.onRefDetail}
              loading={this.state[this.keyIsLoading]}
              dateString={this.getFinishedDate()}
              productionJobs={this.__list.filter(job => job.status === 'finished')}

              reload={this.forceRead}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key='in-progress' tab='In-Progress'>
            <JobProgressDetail
              loading={this.state[this.keyIsLoading]}
              dateString={this.getFinishedDate()}
              productionJobs={this.__list.filter(job => job.status === 'in_progress')}

              reload={this.forceRead}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    )
  }

  render() {
    console.log('Rendered', this)
    // this.state.responseData may null, don't use this.__list, because default is empty array
    if (this.state[this.keyIsLoading] && !this.state.responseData) {
      return (
        <div style={{ height: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spin />
        </div>
      )
    }

    const filterdFinished = this.__list.filter(job => job.status === 'finished')

    return this.state.errorResponse ? this.getErrorComp() : (
      <div style={{ padding: '24px' }}>
        {/* <div> */}
          {/* <ChartProduction
            // setProductionJobs={this.setProductionJobs}
            // setDate={this.setDate}
            loading={this.state[this.keyIsLoading]}
            dateString={this.getFinishedDate()}

            targets={this.state.targets}
            prevTargets={this.state.prevTargets}
            productionJobs={filterdFinished}
            prevProductionJobs={this.state.prevProductionJobs}
            allProductionJobs={this.__list}

            reload={this.forceRead}
            onChangeDate={this.onChangeDate}
            prevMonth={this.prevMonth}
            nextMonth={this.nextMonth}
            setTargets={targets => {this.setThisState({ targets })}}
          /> */}
        {/* </div> */}
        {this.renderChartProduction()}
        {this.renderQSProductionDetail()}
        {this.renderQAProductionDetail()}
        {this.renderProductionDetail()}
        {/* <div className='production-detail-container' style={{ marginTop: '24px' }}>
          <Tabs type='card'>
            <Tabs.TabPane tabKey='finished' tab='Production Detail'>
              <ProductionDetail
                ref={this.onRefDetail}
                loading={this.state[this.keyIsLoading]}
                dateString={this.getFinishedDate()}
                productionJobs={filterdFinished}

                reload={this.forceRead}
              />
            </Tabs.TabPane>
            <Tabs.TabPane key='in-progress' tab='In-Progress'>
              <JobProgressDetail
                loading={this.state[this.keyIsLoading]}
                dateString={this.getFinishedDate()}
                productionJobs={this.__list.filter(job => job.status === 'in_progress')}

                reload={this.forceRead}
              />
            </Tabs.TabPane>
          </Tabs>
        </div> */}
        {/* <div style={{ marginTop: '24px' }}>
          <ProductionDetail
            ref={this.onRefDetail}
            loading={this.state[this.keyIsLoading]}
            dateString={this.getFinishedDate()}
            productionJobs={filterdFinished}

            reload={this.forceRead}
          />
        </div>
        <div style={{ marginTop: '24px' }}>
          <JobProgressDetail
            loading={this.state[this.keyIsLoading]}
            dateString={this.getFinishedDate()}
            productionJobs={this.__list.filter(job => job.status === 'in_progress')}

            reload={this.forceRead}
          />
        </div> */}
        <ScrollEnd />
      </div>
    )
  }
}

export default DashboardManager