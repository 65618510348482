import { Button, Card, Col, DatePicker, Popover, Row, Select, Spin, Tooltip } from 'antd'
import { CaretLeftOutlined, CaretRightOutlined, InfoCircleOutlined, ReloadOutlined, TeamOutlined } from '@ant-design/icons'

import { get, orderBy } from 'lodash'
import moment from 'moment'

import { BaseList, PageError } from 'src/components'
import ManagerProduction from '../DashboardManager'
import ScrollEnd from '../DashboardManager/ScrollEnd'
import AmountPct from 'src/pages/DashboardApp/Dashboard/Report/UserProductionJobs/AmountPct'
// import PointByGrade from 'src/pages/DashboardApp/Dashboard/Report/UserProductionJobs/PointByGrade'
import PointPrediction from './PointPrediction'
import PointByGrade from './PointByGrade'
// import AreaByGrade from 'src/pages/DashboardApp/Dashboard/Report/UserProductionJobs/AreaByGrade'
import AreaByGrade from './AreaByGrade'
import JobByGrade from 'src/pages/DashboardApp/Dashboard/Report/UserProductionJobs/JobByGrade'
import QSProductionDetail from './QSProductionDetail'
import QAProductionDetail from './QAProductionDetail'
import TeamProductionDetail from './ProductionDetail'
import { lib, request } from 'src/Utils'

// ========= ATTENTION =========
// ubah request 'read-productionJobs' ke "read-usersProductionJobs"
// karena nanti tujuannya team leader bisa membuka tanpa ada permission Production Detail Report
// =============================
class TeamProduction extends ManagerProduction {
  constructor(props) {
    super(props)

    this.urlKey = '' // 'read-productionJobs'
    // this.keysQueryParam = ['page', 'limit']

    this.myProfile = lib.ls.getCurrentUser()

    this.state = {
      ...this.state,
      leaderTeams: [],
      selectedTeam: 'all',
      productionJobs: [],
      // qsProductionJobs: [],
      // qaProductionJobsFinished: [],
    }
  }

  getReadTargetOpts = ({ resolve, reject }) => ({
    urlKey: 'read-productionTargetsDashboard',
    onFailed: reject,
    onSuccess: res => resolve({
      ...res,
      data: {
        ...res.data,
        amount: lib.prod.getUserAmount(res.data.amount),
      },
    }),
  })

  getUserIds = (leaderTeams = this.state.leaderTeams, selectedTeam = this.state.selectedTeam) => {
    return leaderTeams
      .filter((leaderTeam) => selectedTeam === 'all' || leaderTeam.alias === selectedTeam)
      .reduce((acc, val) => acc.concat(val.manager).concat(val.users), [])
      .filter((val, i, arr) => arr.findIndex(val2 => val2.id === val.id) === i)
      .map(val => val.id)
  }

  setProductionJobs = (productionJobs) => {
    const userIds = this.getUserIds()
    const finishedProductionJobs = productionJobs.filter(job => job.status === 'finished')

    this.setState({
      productionJobs,
      finishedProductionJobs,
      finishedProductionJobsQS: finishedProductionJobs.filter(job => userIds.includes(job.user.id)),
      finishedProductionJobsQA: finishedProductionJobs.filter(job => userIds.includes(get(job.user_qa, 'id'))),
    })
  }

  doRead = (keyLoading, callback, state) => {
    this.setThisState({
      [keyLoading]: false,
      [this.keyIsLoading]: false,
      [this.keyIsLoadMore]: false,
      responseData: this.state.productionJobs,
      errorResponse: null,
    }, callback)
  }

  beforeRead = async (keyLoading, callback) => {
    return new Promise(resolve => {
      this.setThisState({ [this.keyIsLoading]: true }, async () => {
        const leaderTeams = await this.readLeaderTeams().catch(err => this.setThisState({ [this.keyIsLoading]: false, errorResponse: err.response }, callback))
        if (!leaderTeams) return

        const prevDate = this.getFinishedDate({ subtract: [1, 'month'], startOf: 'month', format: 'YYYY-MM-DD' })
        const resPrevTargets = await this.readTargets(prevDate).catch(err => this.setThisState({ [this.keyIsLoading]: false, errorResponse: err.response }, callback))
        if (!resPrevTargets) return

        const resTargets = await this.readTargets().catch(err => this.setThisState({ [this.keyIsLoading]: false, errorResponse: err.response }, callback))
        if (!resTargets) return

        const resUserTypes = await this.readUserTypes().catch(err => this.setThisState({ [this.keyIsLoading]: false, errorResponse: err.response }, callback))
        if (!resUserTypes) return

        const resExchange = await this.readEffectiveExchange().catch(err => this.setThisState({ [this.keyIsLoading]: false, errorResponse: err.response }, callback))
        if (!resExchange) return

        // const resPrevProd = await this.readProdJobs({ subtract: [1, 'month'] }).catch(err => this.setThisState({ [this.keyIsLoading]: false, errorResponse: err.response }, callback))
        // if (!resPrevProd) return

        // const resProd = await this.readProdJobs().catch(err => this.setThisState({ [this.keyIsLoading]: false, errorResponse: err.response }, callback))
        // if (!resProd) return

        const userIds = this.getUserIds(leaderTeams, 'all')
        const resPrevUserProd = await this.readUserProdJobs(userIds, { subtract: [1, 'month'] })
        const resUserProd = await this.readUserProdJobs(userIds)

        console.log('here', {
          leaderTeams,
          // resPrevProd,
          prevTargets: resPrevTargets.data,
          targets: resTargets.data,
          userTypes: resUserTypes.data,
          effectiveExchange: resExchange.data,
          // prevProductionJobs: resPrevProd.data,
          // productionJobs: resProd.data,

          prevProductionJobs: resPrevUserProd,
          productionJobs: resUserProd,
        })
        this.setThisState({
          leaderTeams,
          prevTargets: resPrevTargets.data,
          targets: resTargets.data,
          userTypes: resUserTypes.data,
          effectiveExchange: resExchange.data,
          // prevProductionJobs: resPrevProd.data,
          // productionJobs: resProd.data,

          prevProductionJobs: resPrevUserProd,
          productionJobs: resUserProd,
        }, resolve)
      })
    })
  }

  readLeaderTeams = async () => {
    return new Promise((resolve, reject) => {
      request({
        urlKey: 'read-userTeams',
        onFailed: reject,
        onSuccess: res => {
          resolve(res.data.results
            .filter(team => team.manager.email === this.myProfile.email)
            .map(team => ({ ...team, users: orderBy(team.users, user => lib.getFullName(user)) })))
        },
      })
    })
  }

  readUserProdJobs = async (userIds = [], dateOpts) => {
    return new Promise((resolve, reject) => {
      request({
        urlKey: 'read-usersProductionJobs',
        data: {
          finished_date_after: this.getFinishedDate({ ...dateOpts, startOf: 'month', format: undefined }),
          finished_date_before: this.getFinishedDate({ ...dateOpts, endOf: 'month', format: undefined }),
          // status: 'finished',
          id: userIds.join(','),
        },
        onFailed: reject,
        onSuccess: res => {
          const productionJobs = []

          for (const userProduction of res.data) {
            const user = { id: userProduction.id, first_name: userProduction.first_name, last_name: userProduction.last_name }

            for (const qsProductionJob of userProduction.qs_production_jobs) {
              let foundIndex = productionJobs.findIndex(productionJob => productionJob.id === qsProductionJob.id)
              if (foundIndex === -1)
                foundIndex = productionJobs.push({ ...qsProductionJob }) - 1

              productionJobs[foundIndex].user = user
            }

            for (const qaProductionJob of userProduction.qa1_production_jobs) {
              let foundIndex = productionJobs.findIndex(productionJob => productionJob.id === qaProductionJob.id)
              if (foundIndex === -1)
                foundIndex = productionJobs.push({ ...qaProductionJob }) - 1

              productionJobs[foundIndex].user_qa = user
            }
          }

          resolve(orderBy(productionJobs, 'prod_day'))
        },
      })
    })
  }

  convertResponseData = (responseData) => {
    console.log('convertResponseData', responseData)

    return responseData
    // return responseData.filter(job => userIds.includes(job.user.id) || userIds.includes(get(job.user_qa1, 'id')))
  }

  // didMount() {
  //   if (this.isValidFinishedDate())
  //     this.forceRead()
  //   else
  //     this.props.history.replace(this.props.location.pathname + lib.getStrParam({ finished_date: moment('2023-01').format('YYYY-MM') }))
  // }

  renderQSProductionDetail() {
    return (
      <div style={{ marginTop: '24px' }}>
        <QSProductionDetail
          ref={this.onRefQS}
          loading={this.state[this.keyIsLoading]}
          dateString={this.getFinishedDate()}
          userIds={this.getUserIds()}
          // productionJobs={this.__list.filter(job => job.status === 'finished')}
          productionJobs={this.state.productionJobs.filter(job => job.status === 'finished')}
          effectiveExchange={this.state.effectiveExchange}
          userTypes={this.state.userTypes}
        />
      </div>
    )
  }

  renderQAProductionDetail() {
    return (
      <div style={{ marginTop: '24px' }}>
        <QAProductionDetail
          loading={this.state[this.keyIsLoading]}
          dateString={this.getFinishedDate()}
          userIds={this.getUserIds()}
          // productionJobs={this.__list.filter(job => job.status === 'finished')}
          productionJobs={this.state.productionJobs.filter(job => job.status === 'finished')}
          effectiveExchange={this.state.effectiveExchange}
          userTypes={this.state.userTypes}
        />
      </div>
    )
  }

  renderProductionDetail() {
    const userIds = this.getUserIds()

    return (
      <TeamProductionDetail
        // productionJobs={this.__list.filter(job => userIds.includes(job.user.id))}
        loading={this.state.isLoading}
        productionJobs={this.state.productionJobs.filter(job => userIds.includes(get(job.user, 'id')))}
      />
    )
  }

  renderChartProduction() {
    const canViewPointReward = true
    const userIds = this.getUserIds()

    return (
      <>
        {/* header */}
        <Row justify='space-between' gutter={[12, 6]} className='sticky-toolbar'>
          <Col>
            <h2 style={{ marginBottom: 0 }}>
              Performance
            </h2>
          </Col>
          
          <Col>
            <Row gutter={[6, 6]}>
              <Col className='only-xs-block'>
                <Button block icon={<ReloadOutlined />} loading={this.state.isLoading} onClick={this.forceRead}>
                  Refresh
                </Button>
              </Col>
              <Col style={{ flex: 1, minWidth: '175px' }}>
                <Row>
                  <Col>
                    <Button
                      disabled={this.state.isLoading}
                      icon={<CaretLeftOutlined />}
                      onClick={this.prevMonth}
                    />
                  </Col>
                  <Col style={{ flex: 1 }}>
                    <DatePicker.MonthPicker
                      disabled={this.props.loading}
                      allowClear={false}
                      // value={moment(this.props.dateString)}
                      value={moment(this.getFinishedDate())}
                      style={{ margin: '0 -1px', width: 'calc(100% + 2px)' }}
                      // onChange={(date, dateString) => {this.selectedDate = dateString; this.read();}}
                      onChange={(date, dateString) => {
                        this.onChangeDate(dateString)
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      disabled={this.state.isLoading}
                      icon={<CaretRightOutlined />}
                      onClick={this.nextMonth}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Tooltip mouseLeaveDelay={0} placement="left" title="Selected Team">
                <Row>
                  <Col>
                      <Button
                        disabled
                        icon={<TeamOutlined />}
                        style={{ marginRight: '-1px' }}
                      />
                  </Col>
                  <Col>
                    <Select
                      value={this.state.selectedTeam}
                      style={{ width: '180px' }}
                      // suffixIcon={<InfoCircleOutlined />}
                      onChange={val => this.setState({ selectedTeam: val })}
                    >
                      <Select.Option value="all">
                        All Managed Teams
                      </Select.Option>
                      {this.state.leaderTeams.map(leaderTeam => (
                        <Select.Option key={leaderTeam.alias} value={leaderTeam.alias}>
                          <Popover
                            placement="leftTop"
                            mouseLeaveDelay={0}
                            content={(
                              <div>
                                <div>
                                  Manager : {lib.getFullName(leaderTeam.manager)}
                                </div>
                                <div>
                                  <div>Members :</div>
                                  <div>
                                    <ul style={{ marginBottom: 0 }}>
                                      {leaderTeam.users.map(user => (
                                        <li key={user.id}>
                                          {lib.getFullName(user)}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            )}
                          >
                            <div>
                              {leaderTeam.name}
                            </div>
                          </Popover>
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* chart */}
        <div>
          <Row gutter={[12, 12]}>
            {canViewPointReward && (
              <>
                <Col xs={24} sm={24} lg={12} xxl={6}>
                  <Spin spinning={this.state.isLoading}>
                    <Card className='app-card' bodyStyle={{ padding: '12px' }} style={{ height: '100%' }}>
                      <PointPrediction
                        readOnly
                        userTypeQS={this.userTypeQS}
                        prevUserTypeQS={this.prevUserTypeQS}
                        date={this.getFinishedDate()}
                        // target={this.state.target}
                        // prevTarget={this.state.prevTarget}
                        prodJobsCompany={this.state.prodJobsCompany}
                        // prevProdJobsCompany={this.state.prevProdJobsCompany}

                        userTarget={this.state.userTarget}
                        prevUserTarget={this.state.prevUserTarget}
                        // userProductionJobs={get(this.state.usersProductionJobs, [0])}
                        // prevUserProductionJobs={get(this.state.prevUsersProductionJobs, [0])}

                        targetWorkingDays={get(this.state.targets, 'working_days')}
                        prevTargetAmount={get(this.state.prevTargets, 'amount', 0)}
                        targetAmount={get(this.state.targets, 'amount', 0)}
                        prevUserProductionJobs={{
                          qs_production_jobs: this.state.prevProductionJobs.filter(job => job.status === 'finished' && userIds.includes(get(job.user, 'id'))),
                          qa1_production_jobs: [],
                        }}
                        userProductionJobs={{
                          qs_production_jobs: this.state.productionJobs.filter(job => job.status === 'finished' && userIds.includes(get(job.user, 'id'))),
                          qa1_production_jobs: [],
                        }}
                      />
                    </Card>
                  </Spin>
                </Col>
                <Col xs={24} sm={24} lg={12} xxl={6}>
                  <Spin spinning={this.state.isLoading}>
                    <Card className='app-card' bodyStyle={{ padding: '12px' }} style={{ height: '100%' }}>
                      {/* {this.userType && ( */}
                        <PointByGrade
                          readOnly
                          dateString={this.selectedDate}
                          // userProductionJobs={this.getQsFinishedUserProductionJobs(get(this.state.usersProductionJobs, [0]))}
                          userProductionJobs={{
                            qs_production_jobs: this.state.productionJobs.filter(job => job.status === 'finished' && userIds.includes(get(job.user, 'id'))),
                            qa1_production_jobs: [],
                          }}
                          user={{ point_types: [] }}
                          prefixTarget={
                            <span style={{ fontWeight: 500, marginRight: '8px' }}>
                              Days
                            </span>
                          }
                          labelTarget='Target Working Days'
                          target={get(this.state.targets, 'working_days')}

                          // user={this.userType}
                          effectiveExchange={this.state.exchange}
                          totalWorkingDays={get(this.state.targets, 'working_days')}
                        />
                      {/* )} */}
                    </Card>
                  </Spin>
                </Col>
              </>
            )}
            <Col xs={24} sm={24} lg={12} xxl={canViewPointReward ? 6 : 12}>
              <Spin spinning={this.state.isLoading}>
                <Card className='app-card' bodyStyle={{ padding: '12px' }} style={{ height: '100%' }}>
                  <AreaByGrade
                    readOnly
                    labelTarget='Target Area'
                    target={Number(get(this.state.targets, 'area', 0))}
                    productionJobs={this.state.productionJobs.filter(job => job.status === 'finished' && userIds.includes(get(job.user, 'id')))}
                    // totalWorkingHours={this.state.totalWorkingHours}
                    totalWorkingDays={get(this.state.targets, 'working_days')}
                  />
                </Card>
              </Spin>
            </Col>
            <Col xs={24} sm={24} lg={12} xxl={canViewPointReward ? 6 : 12}>
              <Spin spinning={this.state.isLoading}>
                <Card className='app-card' bodyStyle={{ padding: '12px' }} style={{ height: '100%' }}>
                  <JobByGrade
                    readOnly
                    labelTarget='Target Jobs'
                    target={Number(get(this.state.targets, 'total_job', 0))}
                    productionJobs={this.state.productionJobs.filter(job => job.status === 'finished' && userIds.includes(get(job.user, 'id')))}
                    // totalWorkingHours={this.state.totalWorkingHours}
                    // totalWorkingDays={this.state.totalWorkingDays}
                    totalWorkingDays={get(this.state.targets, 'working_days')}
                  />
                </Card>
              </Spin>
            </Col>
          </Row>
        </div>
      </>
    )
  }

  render() {
    console.log('Rendered', this)
    // this.state.responseData may null, don't use this.__list, because default is empty array
    if (this.state[this.keyIsLoading] && !this.state.responseData) {
      return (
        <div style={{ height: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spin />
        </div>
      )
    }

    if (this.state.errorResponse) {
      return (
        <PageError
          errorResponse={this.state.errorResponse}
          onReload={() => this.forceRead()}
        />
      )
    }

    if (!this.state.leaderTeams.length) {
      return (
        <PageError
          withErrorMessage={false}
          errorResponse={{
            status: 403,
            data: { detail: `You do not have permission to access this page, only for team leaders.` },
          }}
        />
      )
    }

    return this.state.errorResponse ? this.getErrorComp() : (
      <div style={{ padding: '24px' }}>
        {this.renderChartProduction()}
        {this.renderQSProductionDetail()}
        {this.renderQAProductionDetail()}
        {this.renderProductionDetail()}
        <ScrollEnd />
      </div>
    )
  }
}

export default TeamProduction