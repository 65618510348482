import React from 'react'
import { Button, Card, Col, Form, message, Radio, Row, Spin, Upload } from 'antd'
import { BorderOutlined, CameraOutlined, CheckSquareFilled, InboxOutlined, LaptopOutlined, PlaySquareOutlined } from '@ant-design/icons'
import Webcam from 'react-webcam'
import moment from 'moment'

import { BaseModal } from 'src/components'
import { lib, request } from 'src/Utils'

const accepted = ['.jpg', '.jpeg', '.png']

class RegisterPhoto extends BaseModal {
  constructor(props) {
    super(props)

    this.title = 'Register Photo'

    this.defaultState = { user: null, username: null, isLoading: false, srcPhoto: null }
  }

  onRefWebcam = (ref) => {
    this._webcam = ref
  }

  onRefForm = (ref) => {
    this._form = ref
  }

  getModalProps = () => ({
    destroyOnClose: true,
    okText: 'Save Photo',
    okButtonProps: { loading: this.state.isLoading },
  })

  onUpload = (e) => {
    if (e.fileList.length > 1) e.fileList.shift()

    this.setThisState({ srcPhoto: URL.createObjectURL(e.fileList[0].originFileObj) })

    return e.fileList
  }

  onOk = () => {
    this._form.validateFields().then(values => {
      this.setThisState({ isLoading: true }, () => {
        const data = new FormData()
        data.append('user', this.state.user)
        data.append('photo', values.photo[0].originFileObj)

        request({
          method: 'post',
          urlKey: 'read-attendancePhotos',
          data,
          onSuccess: this.registerSuccess,
          onFailed: this.registerFailed,
        })
      })
    })
  }

  registerSuccess = () => {
    message.success('Photo has been registering, wait and check your photo status')
    this.hide(undefined, undefined, true)
    this.props.reload()
  }

  registerFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.setThisState({ isLoading: false })
  }

  test = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, 1000)
    })
  }

  onCapture = async () => {
    const imgSrc = this._webcam.getScreenshot()

    if (imgSrc) {
      const name = this.state.username || 'jarvis-photos'
      const filename = `${name}_${moment().format('YYYYMMDD_HHmmss')}.jpg`
      const photo = await lib.toFile(imgSrc, filename, 'image/jpeg')

      this._form.setFieldsValue({ photo: [{ name: filename, originFileObj: photo }] })

      this.setThisState({ srcPhoto: imgSrc, isCamera: false })
    } else {
      message.info('Camera not active, please allow camera on your browser')
    }
  }

  renderContent = () => {
    return (
      <Spin spinning={this.state.isLoading}>
        <Row>
          <Col span={24}>
            <Card
              bodyStyle={{ padding: '6px' }}
              style={{ margin: '0 auto 12px auto' }}
            >
              <div style={{ width: '100%', height: '280px', border: '1px solid #e8e8e8', backgroundColor: '#8c939e' }}>
                {this.state.isCamera ? (
                  <Webcam
                    ref={this.onRefWebcam}
                    mirrored
                    forceScreenshotSourceSize
                    screenshotFormat='image/jpeg'
                    width='100%'
                    height='100%'
                    videoConstraints={{
                      width: window.screen.width,
                      height: window.screen.height,
                      facingMode: 'user',
                    }}
                  />
                ) : this.state.srcPhoto ? (
                  <img
                    src={this.state.srcPhoto}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                ) : (
                  <Row justify='center' align='middle' style={{ width: '100%', height: '100%' }}>
                    <Col style={{ textAlign: 'center' }}>
                      <div>
                        <CameraOutlined style={{ fontSize: '64px', color: '#e8e8e8' }} />
                      </div>
                      <h3 style={{ color: '#e8e8e8', userSelect: 'none' }}>
                        Image Preview
                      </h3>
                    </Col>
                  </Row>
                )}
              </div>
            </Card>
          </Col>
          <Col>
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Form ref={this.onRefForm}>
                  <Form.Item
                    name='photo'
                    valuePropName='fileList'
                    getValueFromEvent={this.onUpload}
                    rules={[
                      {
                        validator: (_, fileList) => {
                          if (!fileList || !fileList.length)
                            return Promise.reject(new Error('Image is required, please select photo!'))

                          if (fileList[0].size > 2 * 1024 * 1024)
                            return Promise.reject(new Error('File must smaller than 2MB!'))

                          if (!accepted.includes(`.${fileList[0].name.split('.').pop()}`))
                            return Promise.reject(new Error('Invalid file type!'))

                          return Promise.resolve()
                        }
                      }
                    ]}
                  >
                    <Upload.Dragger
                      accept={accepted.join()}
                      showUploadList={{ showRemoveIcon: false }}
                      beforeUpload={() => false}
                      disabled={this.state.isCamera}
                      style={{ opacity: this.state.isCamera ? 0.5 : 1 }}
                    >
                      <p className='ant-upload-drag-icon' style={{ marginBottom: '8px' }}>
                        <InboxOutlined style={{ fontSize: '64px'}} />
                      </p>
                      <p className='ant-upload-text'>
                        Click or drag file to this area
                      </p>
                      <p className='ant-upload-hint'>Accept following file types : {accepted.map(val => `*${val}`).join(', ')} and max size 2MB</p>
                    </Upload.Dragger>
                  </Form.Item>
                </Form>
              </Col>
              <Col span={12}>
                <div>
                  <Row gutter={[12, 12]} style={{ flexDirection: 'column' }}>
                    <Col>
                      <Button
                        block
                        icon={this.state.isCamera
                          ? <CheckSquareFilled style={{ color: '#1890ff' }} />
                          : <BorderOutlined />}
                        onClick={() => this.setThisState({ isCamera: !this.state.isCamera })}
                        style={!this.state.isCamera ? {} : {color: '#1890ff', borderColor: '#1890ff'}}
                      >
                        Use Camera
                      </Button>
                    </Col>
                    {this.state.isCamera && (
                      <Col>
                        <Button block icon={<PlaySquareOutlined />} onClick={this.onCapture}>
                          Capture
                        </Button>
                      </Col>
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    )
  }
}

export default RegisterPhoto