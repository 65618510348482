import React from 'react'
import { Button, Col, Row, Spin } from 'antd'
import { get } from 'lodash'
import moment from 'moment'

import { BaseList } from 'src/components'
import { lib, request } from 'src/Utils'
import CardMember from '../CardMember'
import typeCodes from 'src/Utils/static/typeCodes'
import { ReloadOutlined } from '@ant-design/icons'

class UserProduction extends BaseList {
  constructor(props) {
    super(props)

    this.myProfile = lib.ls.getCurrentUser()

    this.urlKey = 'read-usersProductionJobs'

    this.pagination = false
    this.keysQueryParam = ['finished_date_after', 'finished_date_before', 'id', 'status']

    this.userTypes = []
    this.id = this.props.id
    this.status = 'finished'
  }

  isValidParam = (key) => true

  getParam = (key, val) => {
    if (key === 'finished_date_after')
      return moment(this.props.selectedDate).startOf('month').format()

    if (key === 'finished_date_before')
      return moment(this.props.selectedDate).endOf('month').format()

    return val
  }

  beforeRead = async (keyLoading, callback) => {
    return new Promise(async resolve => {
      this.userTypes = await this.readUserTypes().catch(error => {this.onBeforeReadError(error, { error, keyLoading, callback })})
      if (!this.userTypes)
        return this.userTypes = []
      else
        this.userTypes = this.userTypes.data

      this.setThisState({ [keyLoading]: true }, () => {
        request({
          urlKey: 'read-effectiveExchange',
          data: { date: moment(this.props.selectedDate).format('YYYY-MM-DD') },
          onSuccess: res => {
            this.exchange = res.data
            resolve()
          },
          onFailed: error => {this.onBeforeReadError(error, { error, keyLoading, callback })},
        })
      })
    })
  }

  onBeforeReadError = (error, extra) => {
    this.setThisState({ [extra.keyLoading]: false, errorResponse: error.response })

    extra.resolve(true) // break
    extra.callback() // set loading false
  }

  readUserTypes = () => {
    return new Promise((resolve, reject) => {
      request({
        urlKey: 'read-userPointTypes',
        onSuccess: res => resolve(res),
        onFailed: err => reject(err),
      })
    })
  }

  convertResponseData = (responseData) => {
    for (let i = 0; i < responseData.length; i += 1) {
      const foundIndex = this.props.team.users.findIndex(user => user.id === responseData[i].id)
      if (foundIndex !== -1 || this.isManagerMySelf) {
        const userType = this.userTypes.find(userType => userType.id === responseData[i].id)
        const qsType = userType.point_types.find(pointType => typeCodes.QS_CODES.includes(pointType.code))
        const qaType = userType.point_types.find(pointType => typeCodes.QA_CODES.includes(pointType.code))
        const trainerType = userType.point_types.find(pointType => typeCodes.TRAINER_CODES.includes(pointType.code))
        const managerType = userType.point_types.find(pointType => typeCodes.MANAGER_CODES.includes(pointType.code))

        const qsProductionJobs = qsType ? responseData[i].qs_production_jobs : []
        const qaProductionJobs = qaType ? responseData[i].qa1_production_jobs : []

        const qsPoint = this.getTotalPoint(qsProductionJobs, 'qs_point')
        const qaPoint = this.getTotalPoint(qaProductionJobs, 'qa1_point')
        const trainerPoint = get(trainerType, 'denominator', 0)
        const managerPoint = get(managerType, 'denominator', 0)

        responseData[i].summary = {
          totalJob: qsProductionJobs.length + qaProductionJobs.length,
          totalArea: this.getTotalArea(qsProductionJobs.concat(qaProductionJobs)),
          totalPoint: qsPoint + qaPoint + trainerPoint + managerPoint,
          summaryExchangeFactor: (
            (qsPoint * get(qsType, 'exchange_factor', 0)) +
            (qaPoint * get(qaType, 'exchange_factor', 0)) +
            (trainerPoint * get(trainerType, 'exchange_factor', 0)) +
            (managerPoint * get(managerType, 'exchange_factor', 0))
          ) /
          (qsPoint + qaPoint + trainerPoint + managerPoint),
        }
      }
    }

    return responseData
  }

  getTotalPoint = (productionJobs, pointKey) => {
    return productionJobs.reduce((prev, val) => prev + Number(val[pointKey]), 0)
  }

  getTotalArea = (productionJobs) => {
    return productionJobs.reduce((prev, val) => prev + Number(val.area), 0)
  }

  viewDashboard = (userId) => {
    this.props.history.push(
      '/' +
      lib.getStrParam({
        start_date: moment(this.props.selectedDate).startOf('month').format('YYYY-MM-DD'),
        end_date: moment(this.props.selectedDate).endOf('month').format('YYYY-MM-DD'),
        id: userId,
      })
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const prevMonth = moment(this.props.selectedDate).format('YYYY-MM')
    const nextMonth = moment(nextProps.selectedDate).format('YYYY-MM')
    if (prevMonth !== nextMonth) {
      this.forceRead()
    }
  }

  render() {
    this.isManagerMySelf = get(this.props.team.manager, 'id') === lib.ls.getCurrentUser().id

    return (
      <div>
        {this.state.errorResponse && this.getErrorComp()}
        <div style={{ marginTop: '24px' }}>
          <Row justify='space-between' style={{ marginBottom: '12px' }}>
            <Col>
              <div style={{ fontSize: '21px' }}>
                <span style={{ fontWeight: 500 }}>
                  Team Production
                </span>
                {' '}
                <span>
                  - {moment(this.props.selectedDate).format('MMMM YYYY')}
                </span>
              </div>
            </Col>
            <Col>
              <Button icon={<ReloadOutlined />} onClick={this.forceRead}>
                Reload
              </Button>
            </Col>
          {/* <h2>
            Team Production - <span style={{ fontWeight: 400 }}>{moment(this.props.selectedDate).format('MMMM YYYY')}</span>
          </h2> */}
          </Row>
            <Spin spinning={this.state[this.keyIsLoading]}>
              {!this.state.errorResponse && (
                <Row gutter={[12, 12]}>
                  <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
                    {this.props.team.manager && (
                    <CardMember
                      isManager
                      isMySelf={this.isManagerMySelf}
                      user={this.props.team.manager}
                      productionSummary={this.__list.find(u => u.id === this.props.team.manager.id)}
                      userType={this.userTypes.find(u => u.id === this.props.team.manager.id)}
                      viewDashboard={this.viewDashboard}
                      effectiveExchange={this.exchange}
                      selectedDate={this.props.selectedDate}
                    />
                    )}
                  </Col>
                  {this.props.team.users.map(user => (
                    <Col key={user.alias} xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
                      <CardMember
                        isMySelf={this.myProfile.email === user.email}
                        user={user}
                        userType={this.userTypes.find(u => u.id === user.id)}
                        productionSummary={this.__list.find(u => u.id === user.id)}
                        viewDashboard={this.viewDashboard}
                        effectiveExchange={this.exchange}
                        selectedDate={this.props.selectedDate}
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </Spin>
        </div>
      </div>
    )
  }
}

export default UserProduction
