import React from 'react'
import { Affix, Avatar, Button, Col, DatePicker, Dropdown, Menu, Row, Spin, Tag, Tooltip } from 'antd'
import { CaretDownOutlined, CaretLeftOutlined, CaretRightOutlined, CloudUploadOutlined, CoffeeOutlined, DownloadOutlined, LogoutOutlined, ReloadOutlined, RiseOutlined, UserOutlined } from '@ant-design/icons'
import moment from 'moment'
import { get } from 'lodash'

import { BaseComponent, PageError } from 'src/components'
import { lib, request } from 'src/Utils'
import Summary from './Summary'
import Report from './Report'
import AffixHeader from './AffixHeader'

class Dashboard extends BaseComponent {
  constructor(props) {
    super(props)

    this.allowedParams = ['start_date', 'end_date', 'id']

    this.limitUser = 25

    // this.userToken = lib.ls.getUserToken()
    // this.myProfile = this.userToken.objCompanies[this.userToken.selectedCompany].user

    this.myProfile = lib.ls.getCurrentUser()

    this.users = []

    this.state = {
      // isLoading: !this.checkParam(this.objParam),
      isLoading: true,
      errorResponse: null,
    }
  }

  readUsers = () => {
    this.setThisState({ isLoading: true }, () => {
      request({
        urlKey: 'read-readableUsers',
        data: { paginate: false },
        getCancel: this.setCancelManaged,
        onSuccess: this.readUsersSuccess,
        onFailed: this.readUsersFailed,
      })
    })
  }

  setCancelManaged = (cancel) => {
    this.cancelTokenManaged = cancel
  }

  cancelManaged = () => {
    if (typeof this.cancelTokenManaged === 'function') {
      this.cancelTokenManaged()
    }
  }

  readUsersSuccess = (response) => {
    this.users = response.data.sort((a, b) => {
      if (a.first_name > b.first_name) return 1
      if (a.first_name < b.first_name) return -1
      return 0
    })

    if (!this.checkParam(this.objParam)) {
      this.changeToDefaultFilter()
    } else
      this.setThisState({ isLoading: false, errorResponse: null })
  }

  readUsersFailed = (error) => {
    lib.responseMessage.error(error.response)

    this.setThisState({ isLoading: false, errorResponse: error.response })
  }

  onChangeFilter = ({ start_date, end_date }) => {
    // if (!Number(this.objParam.id))
    //   delete this.objParam.id

    this.props.history.replace(this.props.location.pathname + lib.getStrParam({ start_date, end_date, id: this.objParam.id }))
  }

  onChangeFilterNew = (objParams) => {
    const mergeObjParams = { ...this.objParam, ...objParams }
    const filteredObjParams = {}
    for (const key in mergeObjParams) {
      if (this.allowedParams.findIndex(param => param === key) !== -1)
        filteredObjParams[key] = mergeObjParams[key]
    }

    this.props.history.replace(this.props.location.pathname + lib.getStrParam(filteredObjParams))
  }

  // update location param to default filter
  changeToDefaultFilter = () => {
    this.onChangeFilter({
      start_date: moment().startOf('week').format('YYYY-MM-DD'),
      end_date: moment().endOf('week').format('YYYY-MM-DD'),
    })
  }

  checkParam = (objParam) => !(
    !objParam.start_date ||
    !objParam.end_date ||
    !this.isValidFormatDate(objParam.start_date) ||
    !this.isValidFormatDate(objParam.end_date) ||
    !this.checkRange(objParam.start_date, objParam.end_date)
  )

  checkRange = (startDate, endDate) => (
    moment(endDate).diff(moment(startDate)) < 2678400000 // max range 31 days
  )

  isValidFormatDate(str) {
    return moment(str, ['YYYY-MM-DD', moment.ISO_8601])._isValid
  }

  getName = (selectedUser) => {
    const ContainerName = ({ children, useDropdown }) => !useDropdown ? children : (
      <Dropdown
        trigger={['click']}
        overlay={this.getUserList(selectedUser)}
        overlayClassName='app-card'
        overlayStyle={{ maxHeight: '350px', overflowY: 'auto' }}
      >
        {children}
      </Dropdown>
    )

    const hasManaged = this.users.length > 1

    // const i = this.users.findIndex(user => user.email === (selectedUser && selectedUser.email))
    // if (i !== -1)
    //   this.users.unshift(...this.users.splice(i, 1))

    return (
      <ContainerName useDropdown={hasManaged}>
        <Col style={{ cursor: hasManaged ? 'pointer' : 'default' }}>
          {lib.getFullName(selectedUser)}
          {hasManaged && <CaretDownOutlined style={{ fontSize: '14px', marginLeft: '6px' }} />}
        </Col>
      </ContainerName>
    )
  }

  getUserList = (selectedUser) => (
    <Menu selectedKeys={[`${selectedUser && selectedUser.email}`]}>
      {this.users.map(user => (
        <Menu.Item key={user.email} user={user} onClick={this.onChangeUser}>
          <Avatar
            size={24}
            src={user.photo}
            icon={<UserOutlined style={{ fontSize: '14px', margin: 0 }} />}
            style={{ marginRight: '6px' }}
          />
          <span style={{ fontWeight: 500 }}>
            {lib.getFullName(user)}
          </span>
          {this.myProfile.email === user.email && ' (you)'}
        </Menu.Item>
      ))}
      <Menu.Item
        key='dashboard-company'
        onClick={this.onClickMenuCompany}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span style={{ fontWeight: 500 }}>
          Open Dashboard Company
        </span>
        <RiseOutlined style={{ color: '#1890ff', marginLeft: '6px' }} />
      </Menu.Item>
    </Menu>
  )

  onChangeUser = ({ item, key }) => {
    // Make sure Menu.Item have props user
    this.onChangeFilterNew({ id: item.props.user.id })
  }

  onClickMenuCompany = ({ domEvent }) => {
    this.props.history.push(`/company/${lib.getStrParam({ start_date: this.objParam.start_date, end_date: this.objParam.end_date })}`)
  }

  updateObjParam = (location) => {
    if (!location) return

    this.objParam = lib.getObjParam(location.search)
    if (!Number(this.objParam.id))
      delete this.objParam.id
  }

  setCurrentUser = (selectedUser) => {
    // When selectedUser is None, set to error page
    if (!selectedUser) {
      this.setThisState({ errorResponse: {} })
      return
    }

    const userIndex = this.users.findIndex(user => user.email === selectedUser.email)
    if (userIndex === -1)
      this.users.splice(
        userIndex === -1 ? this.users.length : userIndex,
        userIndex === -1 ? 0 : 1,
        selectedUser
      )

    this.forceUpdate()
  }

  setUserIsActive = (isActive) => {
    this.setThisState({ isActive })
  }

  onLogout = () => {
    this.setThisState({ isLogout: true }, () => {
      lib.logout(this.props.history)
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextParam = lib.getObjParam(nextProps.location.search)
    if (
      this.objParam.start_date !== nextParam.start_date ||
      this.objParam.end_date !== nextParam.end_date
    ) {
      this.objParam = { ...this.objParam, start_date: nextParam.start_date, end_date: nextParam.end_date }
      this.setThisState({ isLoading: false, errorResponse: null })
    }
  }

  didMount() {
    this.readUsers()
  }

  unmount() {
    this.cancelManaged()
  }

  render() {
    // Set this.objParam with url param
    this.updateObjParam(this.props.location)

    if (this.state.isLoading) {
      return (
        <Row justify='center' align='middle' style={{ height: '80vh' }}>
          <Spin spinning />
        </Row>
      )
    }

    const myId = get(this.users.find(user => user.email === this.myProfile.email), 'id')
    if (!this.objParam.id && myId)
      this.objParam.id = myId

    let selectedUser = this.users.find(user => `${user.id}` === `${this.objParam.id}`)
    if (!selectedUser) {
      this.objParam.id = myId
      selectedUser = this.users.find(user => user.email === this.myProfile.email)
    }

    return (
      <div>
        {(!this.state.errorResponse) ? (
          <div>
            <AffixHeader>
              <Row justify='space-between' gutter={[0, 12]} style={{ padding: '12px', backgroundColor: '#f0f2f5', borderBottom: '1px solid #e7e9ec' }}>
                <Col>
                  {selectedUser && (
                    <Row align='middle'>
                      <Avatar
                        size={64}
                        icon={<UserOutlined />}
                        src={selectedUser.photo}
                        style={{ border: '1px solid #d5d5d5', marginRight: '12px' }}
                      />
                      <Col style={{ maxWidth: 'calc(100% - 64px - 12px)' }}>
                        <Row>
                          <Col>
                            <div style={{ fontSize: '24px', fontWeight: 500, lineHeight: '24px' }}>
                              <Row>
                                <Tooltip placement='right' title={selectedUser.email}>
                                  <div>
                                    {this.getName(selectedUser)}
                                  </div>
                                </Tooltip>
                                <Col style={{ marginLeft: '12px', marginTop: '-2px' }}>
                                  {this.state.isActive ? (
                                    <Tag color='success' icon={<CloudUploadOutlined />}>
                                      Online
                                    </Tag>
                                  ) : (
                                    <Tag icon={<CoffeeOutlined />}>
                                      Offline
                                    </Tag>
                                  )}
                                </Col>
                              </Row>
                            </div>
                            <div className='subtitle' style={{ fontSize: '16px' }}>
                              {this.myProfile.company.name}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col className='only-xs-block'>
                  <Row gutter={[6, 6]}>
                    {/* <Col>
                      <Button icon={<ReloadOutlined />} onClick={() => this.onChangeFilter(this.objParam)}>
                        Reload
                      </Button>
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <Button icon={<CaretLeftOutlined />} onClick={this.prevMonth} />
                        </Col>
                        <Col style={{ margin: '0 -1px', flex: 1 }}>
                          <DatePicker
                            allowClear={false}
                            picker='month'
                            value={moment(this.state.selectedDate)}
                            onChange={this.onChangeDate}
                          />
                        </Col>
                        <Col>
                          <Button icon={<CaretRightOutlined />} onClick={this.nextMonth} />
                        </Col>
                      </Row>
                    </Col> */}
                    <Col>
                      <Button
                        icon={<DownloadOutlined />}
                        onClick={() => this.props.history.push('/downloads/')}
                      >
                        Downloads
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        block
                        icon={<LogoutOutlined />}
                        loading={this.state.isLogout}
                        onClick={this.onLogout}
                      >
                        Sign out
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </AffixHeader>
            <div style={{ margin: '24px' }}>
              <Row gutter={[12, 12]} style={{ flexDirection: 'column' }}>
                <Col style={{ width: '100%' }}>
                  <Summary
                    location={this.props.location}
                    startDate={this.objParam.start_date}
                    endDate={this.objParam.end_date}
                    userId={this.objParam.id}
                    onChangeFilter={this.onChangeFilter}
                    setCurrentUser={this.setCurrentUser}
                    setUserIsActive={this.setUserIsActive}
                  />
                </Col>
                <Col style={{ width: '100%' }}>
                  <Report
                    location={this.props.location}
                    startDate={this.objParam.start_date}
                    endDate={this.objParam.end_date}
                    userId={this.objParam.id}
                    user={selectedUser}
                    checkRange={this.checkRange}
                    // getHistory={this.getHistory}
                    onChangeFilter={this.onChangeFilter}
                  />
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <PageError
            errorResponse={this.state.errorResponse}
            onReload={this.readUsers}
            defaultTitle='User Not Found'
            defaultSubTitle={`Cannot search for user with ID: ${this.objParam.id}. Please check your input ID or your internet connection.`}
            // defaultSubTitle={
            //   <div>
            //     Cannot search for user with ID: <b>{this.objParam.id}</b>. Please check your input ID or your internet connection.
            //   </div>
            // }
          />
        )}
      </div>
    )
  }
}

export default Dashboard