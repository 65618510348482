import React from 'react'
import { Drawer, Modal } from 'antd'

import { BaseComponent } from 'src/components'
import Content from './Content'

class RequestHistoryContainer extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
    }
  }

  show = (params) => {
    this.setThisState({ visible: true, params })
  }

  hide = () => {
    this.setThisState({ visible: false })
  }

  render() {
    return (
      <Drawer
        destroyOnClose
        visible={this.state.visible}
        closable={false}
        width={380}
        mask={!this.mutableScreen.isMobile} // https://github.com/ant-design/ant-design/issues/25870#issuecomment-854674355

        bodyStyle={{ backgroundColor: '#e8e8e8' }}
        onClose={this.hide}
      >
        <Content
          params={this.state.params}
          onClose={this.hide}
        />
      </Drawer>
    )
  }
}

export default RequestHistoryContainer