import moment from 'moment'

function getMonths(startDate, endDate) {
  const momentStartDate = moment(startDate)
  const momentEndDate = moment(endDate)
  const months = [], MONTH_FORMAT = 'YYYY-MM'

  if (momentEndDate.diff(momentStartDate) >= 0) {
    do {
      months.push(momentStartDate.format(MONTH_FORMAT))
      momentStartDate.add(1, 'month')
    } while(momentStartDate.format(MONTH_FORMAT) <= momentEndDate.format(MONTH_FORMAT))
  }

  return months
}

export default getMonths