import React from 'react'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { BaseComponent } from 'src/components'
import CardUser from './CardUser'

class FieldManager extends BaseComponent {
  onEdit = (user) => {
    this.props.showSelectUser({
      fieldKey: 'manager',
      excludeUsers: user ? [user.alias] : [],
      contentProps: {
        multiple: false,
        isManager: true,
      },
    })
  }

  render() {
    const { users } = this.props
    const manager = users.find(user => user.alias === this.props.value)

    return (
      <div>
        {manager ? (
          <CardUser
            isManager
            user={manager}
            onEdit={this.onEdit}
          />
        ) : (
          <Button
            ghost
            type='dashed'
            size='large'
            icon={<PlusOutlined style={{ fontSize: '20px' }} />}
            style={{ borderColor: '#9c9c9c', color: '#9c9c9c', width: '100%', height: '160px' }}
            onClick={() => this.onEdit()}
          />
        )}
      </div>
    )
  }
}

export default FieldManager