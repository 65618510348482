export default function getFullName(profile) {
  let fullName = null

  if (profile) {
    fullName = profile.first_name || ''
    if (profile.last_name) {
      fullName += ' ' + profile.last_name
    }

    if (!fullName) {
      fullName = profile.email
    }
  }

  return fullName
}