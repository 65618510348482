import { Col, Row } from 'antd'
import NumberFormat from 'react-number-format'
import { get } from 'lodash'

import BasePoint from './BasePoint'

class AmountByGrade extends BasePoint {
  constructor(props) {
    super(props)

    this.title = 'Amount by Grade'
    this.a = 'Avg $ / day'
    this.b = 'Avg $ / job'

    this.headerClassName = 'box-amount-by-grade'
    this.inputTargetProps = {
      addonBefore: 'Days',
      className: 'input-wkdays',
      title: 'Target Working Days',
    }

    this.columns = [
      { dataIndex: 'grade' },
      {
        dataIndex: 'qs_value',
        style: { textAlign: 'right' },
        render: val => (
          <Row justify='space-between'>
            <Col>{`$ `}</Col>
            <Col>
              <NumberFormat
                thousandSeparator
                displayType='text'
                value={Number(val).toFixed(2)}
              />
            </Col>
          </Row>
        )
      },
      {
        dataIndex: 'pct',
        style: {textAlign: 'right'},
        render: val => this.getLabelPct(val)
      },
    ]
  }

  getTotalValue = () => (
    <span>
      {'$ '}
      <NumberFormat
        thousandSeparator
        displayType='text'
        value={Number(this.totalValue).toFixed(2)}
      />
    </span>
  )

  mappingXY = (record) => ({ x: record.grade, y: record.qs_value })

  getProgressPct = () => {
    let progressPct = this.props.targetAmount ? this.totalValue / this.props.targetAmount : 0
    if (progressPct > 1)
      progressPct = 1

    return progressPct
  }

  convertData = (productionJobs) => {
    const dataSource = []

    this.totalValue = 0
    for (let i = 0; i < productionJobs.length; i += 1) {
      this.totalValue += Number(productionJobs[i].qs_value)
      const foundIndex = dataSource.findIndex(item => item.grade === productionJobs[i].grade)

      const prevDataSource = dataSource[foundIndex]

      dataSource.splice(
        !prevDataSource ? dataSource.length : foundIndex,
        !prevDataSource ? 0 : 1,
        {
          grade: productionJobs[i].grade,
          qs_value: Number(get(prevDataSource, 'qs_value', 0)) + Number(productionJobs[i].qs_value),
        }
      )
    }

    for (let i = 0; i < dataSource.length; i += 1)
      dataSource[i].pct = this.totalValue ? dataSource[i].qs_value / this.totalValue : dataSource[i].qs_value

    dataSource.sort((a, b) => b.pct - a.pct)

    this.setAverage(productionJobs)

    this.setThisState({ dataSource })
  }

  setAverage = (productionJobs) => {
    const prodDays = []
    let totalDays = 0, totalJobs = productionJobs.length

    for (const i in productionJobs) {
      if (prodDays.indexOf(productionJobs[i].prod_day) === -1) {
        totalDays += 1
        prodDays.push(productionJobs[i].prod_day)
      }
    }

    this.averagePerDay = Math.round(this.totalValue / totalDays)
    this.averagePerJob = Math.round(this.totalValue / totalJobs)
  }
}

export default AmountByGrade