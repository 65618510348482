import PropTypes from 'prop-types';
import { Badge, Col, Row, Tag, Tooltip } from 'antd';
import { CalendarOutlined, CoffeeOutlined, CommentOutlined } from '@ant-design/icons';
import moment from 'moment';
import classNames from 'classnames';

import { BaseComponent } from 'src/components';
import { lib } from 'src/Utils';
import helper from 'src/pages/DashboardApp/Submission/helper';

class LeaveListItem extends BaseComponent {
  render() {
    const { userLeave } = this.props

    return (
      // <Badge.Ribbon text={'Expired'} color="red" style={{ fontWeight: 500 }} className='test'>
      <div
        className={classNames('leave-list-item', {
          // rejected: userLeave.status === 'rejected',
          // approved: userLeave.status === 'approved',
          // waiting: userLeave.status === 'waiting',
        })}
        onClick={() => this.props.onSelect(userLeave)}
      >
        <Row wrap={false} justify="space-between" align="middle" style={{ marginBottom: '6px' }}>
          <Col>
            <div className="leave-title">
              <CoffeeOutlined style={{ marginRight: '12px' }} />
              {userLeave.leave_type.name}
            </div>
          </Col>
          {helper._leave.isExpSubmission(userLeave) && (
            <Col style={{ color: '#ff4d4f', fontSize: '16px', fontStyle: 'italic', fontWeight: 500 }}>
              Expired
            </Col>
          )}
          <Col>
            <div style={{ color: 'rgba(0, 0, 0, 0.4)', fontSize: '11px', fontStyle: 'italic', fontWeight: 500 }}>
              <CalendarOutlined style={{ marginRight: '6px' }} />
              <span>
                {moment(userLeave.start_date).format('DD MMM YYYY')} - {moment(userLeave.finish_date).format('DD MMM YYYY')}
              </span>
            </div>
          </Col>
        </Row>
        <Row wrap={false}>
          <Col flex="auto">
            <div className="leave-subtitle">
              {userLeave.note}
            </div>
          </Col>
          <Col flex="none">
            <div
              style={{ fontWeight: 500 }}
              // className={classNames('leave-item-status', {
              //   rejected: ['rejected', 'cancelled'].includes(userLeave.status),
              //   approved: userLeave.status === 'approved',
              // })}
            >
              {helper.renderStatus(userLeave)}
            </div>
          </Col>
        </Row>
      </div>
      // </Badge.Ribbon>
    )
  }
}

LeaveListItem.propTypes = {
  ...LeaveListItem.propTypes,
  userLeaves: PropTypes.array,
  onSelect: PropTypes.func,
}

LeaveListItem.defaultProps = {
  ...LeaveListItem.defaultProps,
  userLeaves: [],
  onSelect: () => {},
}

export default LeaveListItem