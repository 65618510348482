import PropTypes from 'prop-types'
import { Avatar, Badge, Button, Card, Col, Empty, Popover, Row, Spin, Tag } from 'antd'
import { CalendarOutlined, CloseOutlined, CheckCircleOutlined, UserOutlined, UsbOutlined, CommentOutlined, BookOutlined, CaretRightOutlined, ClockCircleOutlined, RollbackOutlined, CopyOutlined, RobotOutlined, TagOutlined, WarningOutlined } from '@ant-design/icons'
import { get, groupBy, startCase } from 'lodash'
import moment from 'moment'
import classNames from 'classnames'

import { BaseComponent } from 'src/components'
import { lib } from 'src/Utils'
import helper from 'src/pages/DashboardApp/Submission/helper'
// import PopoverDetailRequest from './PopoverDetailRequest'

class BaseListSubmissions extends BaseComponent {
  constructor(props) {
    super(props)

    // Override this keys
    this.filterKey = ''

    // this._s = helper.states

    this.state = {
      objCollapsed: {},
    }
  }

  filterList = (activities) => activities.status === this.filterKey

  renderExtra = (activity) => {
    if (helper._leave.allowConfirm(activity)) {
      return (
        <Button.Group style={{ width: '100%' }}>
          <Button danger type='primary' icon={<CloseOutlined />} style={{ flex: 1 }} onClick={() => this.props.onReject(activity)}>
            Reject
          </Button>
          <Button type='primary' icon={<CheckCircleOutlined />} style={{ flex: 1 }} onClick={() => this.props.onAccept(activity)}>
            Approve
          </Button>
        </Button.Group>
      )
    }

    return null

    return (
      <Button danger type="primary" icon={<RollbackOutlined />} onClick={() => this.props.showDeleteActivity(activity)}>
        Undo Action
      </Button>
    )
  }

  renderEmpty = () => (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  )

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.allExpanded !== nextProps.allExpanded) {
      if (nextProps.allExpanded)
        this.setState({ objCollapsed: false })
      else
        this.setState({ objCollapsed: groupBy(this.props.list, item => this.props.groupBy === 'user' ? lib.getFullName(item.user) : moment(item[nextProps.groupBy]).format('DD MMMM YYYY')) })
    }
  }

  render() {
    const filteredList = this.props.list.filter(this.filterList)
    const groupedList = groupBy(filteredList, item => this.props.groupBy === 'user'
      ? lib.getFullName(item.user)
      : moment(item[this.props.groupBy]).format('DD MMMM YYYY'))
    const keys = Object.keys(groupedList).sort((a, b) => a > b ? 1 : -1)
    if (this.props.groupBy !== 'user')
      keys.sort((a, b) => moment(a, 'DD MMMM YYYY').format('YYYY-MM-DD') > moment(b, 'DD MMMM YYYY').format('YYYY-MM-DD') ? -1 : 1)

    return (
      <div>
        {filteredList.length ? keys.map(fullName => (
          <div key={fullName}>
            <Row justify='space-between' className='group-request-time' onClick={() => this.setState({ objCollapsed: { ...this.state.objCollapsed, [fullName]: !this.state.objCollapsed[fullName] } })}>
              <Col>
                <Row align="middle" gutter={[6, 6]}>
                  <CaretRightOutlined
                    rotate={this.state.objCollapsed[fullName] ? 0 : 90}
                    style={{ marginRight: '6px' }}
                  />
                  {fullName}
                  {helper.renderStatuses(groupedList[fullName])}
                  {/* {groupedList[fullName].filter(item => item.status === this._s.keys.WAITING).length > 0 && (
                    <Tag color="processing" style={{ marginLeft: '6px' }}>
                      {groupedList[fullName].filter(item => item.status === this._s.keys.WAITING).length} {this._s.getItem(this._s.keys.WAITING).label}
                    </Tag>
                  )}
                  {groupedList[fullName].filter(item => item.status === this._s.keys.APPROVED).length > 0 && (
                    <Tag color="success" className="group-hoverable" style={{ marginLeft: '6px' }}>
                      {groupedList[fullName].filter(item => item.status === this._s.keys.APPROVED).length} {this._s.getItem(this._s.keys.APPROVED).label}
                    </Tag>
                  )}
                  {groupedList[fullName].filter(item => item.status === this._s.keys.REJECTED).length > 0 && (
                    <Tag color="error" className="group-hoverable" style={{ marginLeft: '6px' }}>
                      {groupedList[fullName].filter(item => item.status === this._s.keys.REJECTED).length} {this._s.getItem(this._s.keys.REJECTED).label}
                    </Tag>
                  )} */}
                </Row>
              </Col>
              <Col>
              </Col>
            </Row>
            <div style={{ display: (this.state.objCollapsed[fullName]) ? 'none' : 'block' }}>
              {groupedList[fullName].map(activity => (
                <div
                  key={activity.alias}
                  className={classNames('activity-list-item', {
                    accepted: helper.isApproved(activity),
                    rejected: helper.isAborted(activity),
                  })}
                >
                  <Row justify='space-between' align='middle' gutter={[12, 12]}>
                    <Col xs={24} sm={10}>
                      <Row align='middle' wrap={false}>
                        {!this.mutableScreen.isMobile && (
                          <Col flex="none" style={{ marginRight: '12px' }}>
                            <Avatar
                              src={activity.user.photo}
                              icon={<UserOutlined />}
                              size={48}
                            />
                          </Col>
                        )}
                        <Col flex="auto">
                          <div style={{ padding: '0 6px' }}>
                            {/* User Name */}
                            <span className='activity-list-item-title'>
                              {/* <RobotOutlined style={{ marginRight: '6px' }} /> */}
                              <b>{lib.getFullName(activity.user)}</b>

                              {/* Manual Time Working */}
                              {/* <span title='Time Working' style={{ color: '#c3c3c3', fontSize: '13px' }}>
                                <CalendarOutlined style={{ marginLeft: '8px' }} /> {moment(activity.start_time).format('DD MMMM YYYY')}
                                <span style={{ display: 'inline-block', marginLeft: '12px' }}>
                                  {moment(activity.start_time).format('hh:mm A')} - {moment(activity.finish_time).format('hh:mm A')}
                                  &nbsp;
                                  {!this.mutableScreen.isMobile && (
                                    `(${lib.time.getLabel(
                                      lib.time.getDiff({
                                        start_time: activity.start_time,
                                        finish_time: activity.finish_time,
                                      })
                                    )})`
                                  )}
                                </span>
                              </span> */}
                            </span>
                            {/* <div> */}
                              {helper._leave.isExpSubmission(activity) && (
                                <span style={{ color: '#ff4d4f', fontStyle: 'italic', marginLeft: '12px' }}>
                                  {/* <ApiOutlined style={{ marginRight: '6px' }} /> */}
                                  <WarningOutlined style={{ marginRight: '6px' }} />
                                  This date has passed
                                </span>
                              )}
                            {/* </div> */}
                          </div>

                          <div style={{ marginTop: '4px' }}>
                            <table className="all-submission-table">
                              {/* <colgroup>
                                <col style={{ width: '60px' }} />
                                <col style={{ width: '20px' }} />
                                <col style={{ width: '250px' }} />
                              </colgroup> */}
                              <tbody>
                                <tr className="row-task">
                                  <td className="cell-label">
                                    <CopyOutlined style={{ marginRight: '12px' }} />
                                    Type
                                  </td>
                                  <td>:</td>
                                  <td className="cell-value">{activity.leave_type.name}</td>
                                </tr>
                                <tr className="row-reason">
                                  <td className="cell-label">
                                    <CommentOutlined style={{ marginRight: '12px' }} />
                                    Reason
                                  </td>
                                  <td>:</td>
                                  <td className="cell-value webkit-box two-lines">{activity.note}</td>
                                </tr>
                                <tr className="row-reason">
                                  <td className="cell-label">
                                    <CalendarOutlined style={{ marginRight: '12px' }} />
                                    Submit date
                                  </td>
                                  <td>:</td>
                                  <td className="cell-value">{moment(activity.created).format('DD MMM YYYY hh:mm A')}</td>
                                </tr>
                                {/* <span style={{ color: 'rgba(0, 0, 0, 0.4)', fontSize: '12px', fontStyle: 'italic', fontWeight: 400 }}>
                                  Submitted on {moment(userLeave.created).format('DD MMM YYYY hh:mm A')}
                                </span> */}
                                {this.type === 'all' && (
                                  <tr className="row-reason">
                                    <td className="cell-label">
                                      <TagOutlined style={{ marginRight: '12px' }} />
                                      Status
                                    </td>
                                    <td>:</td>
                                    <td
                                      className={classNames('cell-value', {
                                        approved: helper.isApproved(activity), // activity.status === this._s.keys.APPROVED,
                                        rejected: helper.isAborted(activity), // activity.status === this._s.keys.REJECTED,
                                      })}
                                    >
                                      {activity.status_display}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>

                          {/* Task Name */}
                          {/* <div style={{ color: '#1890ff', fontSize: '13px' }}>
                            <CopyOutlined style={{ marginRight: '6px' }} />
                            Type : {helper.getTypeLabel(activity.type)}
                          </div> */}

                          {/* Reason */}
                          {/* <div className='webkit-box two-lines2' title={activity.message} style={{ color: '#8e8e8e' }}>
                            <CommentOutlined style={{ marginRight: '6px' }} />
                            Reason : {activity.reason}
                          </div> */}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} sm={8} style={{ color: '#8e8e8e', fontSize: '18px', textAlign: 'center' }}>
                      <div className={classNames({ 'c-error': helper._leave.isExpSubmission(activity) })}>
                        <CalendarOutlined style={{ marginRight: '6px' }} />
                        {moment(activity.start_date).format('DD MMM YYYY')} - {moment(activity.finish_date).format('DD MMM YYYY')}
                      </div>
                    </Col>
                    <Col xs={24} sm={6} className='only-xs-block'>
                      <Row justify='end'>
                        <Col>
                          {this.renderExtra(activity)}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </div>
        )) : this.renderEmpty()}
      </div>
    )
  }
}

BaseListSubmissions.propTypes = {
  ...BaseListSubmissions.propTypes,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
}

BaseListSubmissions.defaultProps = {
  ...BaseListSubmissions.defaultProps,
  onAccept: () => {},
  onReject: () => {},
}

export default BaseListSubmissions