import React from 'react'
import { Form, Input } from 'antd'

import { BaseComponent, SelectQuery } from 'src/components'

class EditableCell extends BaseComponent {
  render() {
    const {
      editing,
      dataIndex,
      title,
      className,
      inputType,
      record,
      index,
      children,
      formProps,
      inputComponent,
      ...restProps } = this.props

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            {...formProps}
            // initialValue={'asdf'}
            style={{ marginBottom: 0 }}
          >
            {/* {inputComponent ? (typeof inputComponent === 'function' ? (form) => inputComponent(form) : inputComponent ) : <Input />} */}
            {/* {dataIndex === 'project' ? (
              <SelectQuery
                urlKey='read-projects-autocomplete'
                inputProps={{ dropdownMatchSelectWidth: false }}
                initialValues={{ pagination: false }}
                params={{ archived: false }}
              />
            ) : (
              inputComponent || <Input />
            )} */}
            {inputComponent || <Input />}
          </Form.Item>
        ) : <div title={title} className={className}>{children}</div>}
      </td>
    )
  }
}

export default EditableCell