import appConfig from 'src/config/app'

const secureLS = require('secure-ls')
const ls = new secureLS({
  encodingType: 'aes',
  encryptionSecret: appConfig.oauth.client_secret.substr(0, 32),
  isCompression: false,
})

const TOKEN_KEY = 'timewise.session'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get(key) {
    try {
      return ls.get(key)
    } catch(e) {}

    return null
  },

  save(key, data) {
    ls.set(key, data)
  },

  remove(key) {
    ls.remove(key)
  },

  getUserToken() {
    let credential = null

    try {
      credential = ls.get(TOKEN_KEY)
    } catch(e) {}

    return credential || {
      username: null,
      access_token: null,
      refresh_token: null,
      profile: {},
    }
  },

  setUserToken(data) {
    this.save(TOKEN_KEY, data)
  },

  removeUserToken() {
    this.remove(TOKEN_KEY)
  },

  updateUserToken(data) {
    this.setUserToken({ ...this.getUserToken(), ...data })
  },

  setCompanies(companies, withSetSelectedCompany = true) {
    const userToken = this.getUserToken()
    userToken.companies = companies

    if (withSetSelectedCompany) {
      if (Array.isArray(companies)) {
        let selectedIndex = -1
        if (userToken.selectedCompany) {
          selectedIndex = companies.findIndex(company => company.alias === userToken.selectedCompany)
        }

        if (selectedIndex === -1) {
          selectedIndex = companies.findIndex(company => company.user_registered && company.user_active)
        }
        if (selectedIndex === -1) {
          selectedIndex = companies.findIndex(company => company.user_active)
        }
        if (selectedIndex === -1) {
          selectedIndex = companies.findIndex(company => company.user_registered)
        }

        if (selectedIndex !== -1) {
          userToken.selectedCompany = companies[selectedIndex].alias
        }
      }
    }

    this.setUserToken(userToken)
  },

  getObjCompanies() {
    const userToken = this.getUserToken()
    let objCompanies = null
    if (Array.isArray(userToken.companies)) {
      // objCompanies = userToken.companies.reduce((accumulator, current) => ({ ...accumulator, [current.alias]: current }), {})
    }

    return objCompanies
  },

  setAccount(account) {
    const userToken = this.getUserToken()
    userToken.account = account
    this.setUserToken(userToken)
  },

  setCurrentUser(user) {
    const userToken = this.getUserToken()
    if (userToken.selectedCompany && Array.isArray(userToken.companies)) {
      const indexCurrentCompany = userToken.companies.findIndex(company => company.alias === userToken.selectedCompany)
      if (indexCurrentCompany !== -1) {
        userToken.companies[indexCurrentCompany].user = user
        this.setUserToken(userToken)
      }
    }
  },

  getCurrentUser() {
    const userToken = this.getUserToken()
    let currentUser = null
    if (userToken.selectedCompany && Array.isArray(userToken.companies)) {
      const currentCompany = userToken.companies.find(company => company.alias === userToken.selectedCompany)
      if (currentCompany) {
        currentUser = currentCompany.user
        Object.defineProperties(currentUser, {
          company: {
            get() {
              return currentCompany
            }
          }
        })
      }
    }

    return currentUser
  },

  getFullName() {
    let fullName = null

    const user = this.getCurrentUser()
    if (user) {
      fullName = user.first_name || ''
      if (user.last_name) {
        fullName += ' ' + user.last_name
      }

      if (!fullName) {
        const account = this.getUserToken().account
        if (account) {
          fullName = account.first_name || ''
          if (account.last_name) {
            fullName += ' ' + account.last_name
          }

          if (!fullName) fullName = account.email
        }
      }
    }

    return fullName
  },
}