/* eslint-disable import/no-anonymous-default-export */
import ls from './localStorage';
import staticCompany from '../static/company';

function getWorkHour(currentUser) {
  const user = currentUser || ls.getCurrentUser()

  return user.company.config.work_hour || staticCompany.DEFAULT_WORK_HOUR
}

export default {
  getWorkHour,
}