import { get, orderBy } from 'lodash'

function sorterByArray(arr, key) {
  if (!Array.isArray(arr)) return arr

  return new Array(...arr)
    .sort((a, b) => sorterByItem(a, b, key))
}

function sorterByItem(a, b, key) {
  if (key) {
    a = get(a, key)
    b = get(b, key)
  }

  if (a === null) return 1
  if (b === null) return -1

  if (a > b) return 1
  if (a < b) return -1
  return 0
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  byArray: sorterByArray,
  byItem: sorterByItem,
}