import React from 'react'
import { Button, Col, DatePicker, message, Row } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import moment from 'moment'

import { BaseList } from 'src/components'
import Timeline from './Timeline'
import TopUsage from './TopUsage'
// import UserProduction from './UserProduction'
import UserProductionJobs from './UserProductionJobs'
// import ChartProduction from 'src/pages/DashboardApp/DashboardManager/ChartProduction'
import { lib, permissions } from 'src/Utils'

const { RangePicker } = DatePicker

// Tidak menggunakan BaseListActivity karena tidak menggunakan fitur replace query param
class ReportComponent extends BaseList {
  // Harusnya ini base list activity
  // kemudian di index dashboard, tidak perlu cek param.
  constructor(props) {
    super(props)

    this.urlKey = 'read-activities'
    this.range_before = moment(props.startDate).startOf('day').format()
    this.range_after = moment(props.endDate).endOf('day').format()

    this.keysQueryParam = ['range_before', 'range_after', 'id']
    this.pagination = false

    this.objParam = {}

    this.myProfile = lib.ls.getCurrentUser()
    this.myActivities = []
  }

  onChangeFilter = ({ start_date, end_date }) => {
    if (this.props.checkRange(start_date, end_date)) {
      // this.props.getHistory().replace(this.props.getHistory().location.pathname + lib.getStrParam({ start_date, end_date }))
      this.props.onChangeFilter({ start_date, end_date })
    } else {
      message.info('Cannot display for more than 1 month')
    }
  }

  beforeRead = () => {
    this.cancel()
  }

  convertResponseData = (responseData) => {
    this.myActivities = []
    for (const i in responseData) {
      // When has id in URL param, check id, but when no id, use email for select activities
      if (this.props.userId) {
        if (`${responseData[i].id}` === `${this.props.userId}`) {
          this.myActivities = responseData[i].activities
        }
      } else {
        if (responseData[i].email === this.myProfile.email) {
          this.myActivities = responseData[i].activities
        }
      }
    }

    return responseData
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.startDate !== nextProps.startDate || this.props.endDate !== nextProps.endDate || this.props.userId !== nextProps.userId) {
      this.range_before = moment(nextProps.startDate).startOf('day').format()
      this.range_after = moment(nextProps.endDate).endOf('day').format()
      this.forceRead()
    }
  }

  render() {
    // "this.id" digunakan untuk request activity yang di ambil dari keysQueryParam
    this.id = this.props.userId
  
    return (
      <Row gutter={[12, 12]} style={{ flexDirection: 'column' }}>
        <Col>
          <Row justify='space-between'>
            <Col>
              <h2 style={{ marginBottom: 0 }}>
                Reports
              </h2>
            </Col>
            <Col>
              <Row gutter={[12, 12]}>
                <Col>
                  <Button loading={this.state.isLoading} icon={<ReloadOutlined />} onClick={this.read}>
                    Refresh
                  </Button>
                </Col>
                <Col>
                  <RangePicker
                    allowClear={false}
                    format='YYYY-MM-DD'
                    ranges={{
                      'Today': [moment(), moment()],
                      'This Week': [moment().startOf('week'), moment().endOf('week')],
                      'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                    }}
                    value={[moment(this.range_before), moment(this.range_after)]}
                    onChange={(dates, dateStrings) => {
                      this.tempDateStrings = dateStrings
                    }}
                    onOpenChange={(opened) => {
                      if (!opened) {
                        if (this.tempDateStrings[0] !== this.range_before || this.tempDateStrings[1] !== this.range_after)
                          this.onChangeFilter({ start_date: this.tempDateStrings[0], end_date: this.tempDateStrings[1] })
                      } else {
                        this.tempDateStrings = [this.range_before, this.range_after]
                      }
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col>
          <Timeline
            isLoading={this.state.isLoading}
            // activities={this.state.responseData}
            activities={this.myActivities}
            startDate={this.range_before}
            endDate={this.range_after}
            userId={this.id}
            isManaged={this.props.user.user_as === 'managed'}
            errorResponse={this.state.errorResponse}
          />
        </Col>
        <Col>
          <TopUsage
            isLoading={this.state.isLoading}
            // activities={this.state.responseData}
            activities={this.myActivities}
            startDate={this.range_before}
            endDate={this.range_after}
            userId={this.id}
            isManaged={this.props.user.user_as === 'managed'}
            errorResponse={this.state.errorResponse}
          />
        </Col>
        {permissions.hasPointRewardModule(this.myProfile) && (
        // {permissions.canViewProdDashboard(this.myProfile, this.props.user) && (
          <Col style={{ width: '100%' }}>
            <UserProductionJobs
              isGeneral
              userId={this.id}
              user={this.props.user}
              canViewPointReward={permissions.canViewPointReward(this.myProfile)}
            />
            {/* <UserProduction
              isGeneral
              userId={this.id}
              user={this.props.user}
            /> */}
          </Col>
        )}
      </Row>
    )
  }
}

export default ReportComponent