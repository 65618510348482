import React from 'react'
import { Button, Card, Col, Collapse, Dropdown, Empty, Input, Menu, Modal, Row, Spin } from 'antd'
import { PlusOutlined, ReloadOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons'

import { BaseList } from 'src/components'
import { lib, request } from 'src/Utils'
import Tasks from './Tasks'

const { Panel } = Collapse

class ContentProjects extends BaseList {
  constructor(props) {
    super(props)

    this._tasks = {}

    this.archived = props.archived

    this.keysQueryParam = ['archived']
    this.pagination = false

    this.urlKey = 'read-projects'

    this.state = {
      ...this.state,
      searchValue: '',
    }
  }

  getTypeLabel = () => this.props.archived ? 'Archived' : 'Active'

  convertResponseData = (responseData) => {
    const results = this.getResults(responseData)
    for (let i = 0; i < results.length; i += 1) {
      // results[i].key = `${new Date().getTime()}-${results[i].alias}`
      this.setKey(results[i])
    }

    return responseData
  }

  setKey = (record) => {
    record.key = `${new Date().getTime()}-${record.alias}`
  }

  getExtra = (projectId, project) => {
    return !this.props.readOnly && (
      <Dropdown trigger={['click']} overlay={this.getOptsMenu(projectId, project)}>
        <SettingOutlined onClick={e => {e.stopPropagation()}} />
      </Dropdown>
    )
  }

  getOptsMenu = (projectId, project) => {
    return (
      <Menu onClick={e => {e.domEvent.stopPropagation()}}>
        <Menu.Item onClick={() => this.onEdit(project)}>
          Edit
        </Menu.Item>
        <Menu.Item onClick={() => this.archivedChange(projectId)}>
          {this.props.archived ? 'Activate This Project' : 'Archive This Project'}
        </Menu.Item>
      </Menu>
    )
  }

  onRefTasks = (ref, projectId) => {
    this._tasks[projectId] = ref
  }

  onRefreshTasks = (projectId) => {
    this._tasks[projectId].read()
  }

  archivedChange = (projectId) => {
    if (this.state.isLoading) return

    this.setThisState({ isLoading: true }, () => {
      request({
        method: 'patch',
        urlKey: 'change-projects',
        data: {
          is_archived: !this.props.archived,
        },
        params: { archived: this.props.archived },
        args: [projectId],
        extra: { projectId },
        onSuccess: this.archivedChangeSuccess,
        onFailed: this.archivedChangeFailed,
      })
    })
  }

  archivedChangeSuccess = (response, extra) => {
    this.updateList(
      this.__list.filter(project => project.alias !== extra.projectId),
      { isLoading: false }
    )

    this.setKey(response.data)
    this.props.switchArchived(response.data, this.props.archived)
  }

  archivedChangeFailed = (error) => {
    this.setThisState({ isLoading: false })
  }

  onAddProject = () => {
    let name = ''

    Modal.confirm({
      title: 'Create Project',
      content: (
        <div>
          <Input
            ref={ref => {
              setTimeout(() => {
                if (ref) ref.focus()
              }, 150)
            }}
            defaultValue={name}
            placeholder='Project Name'
            onChange={e => {name = e.target.value}}
          />
        </div>
      ),
      onOk: () => this.addProject({ name }),
    })
  }

  addProject = (data) => {
    return new Promise((resolve, reject) => {
      request({
        method: 'post',
        urlKey: 'read-projects',
        data: {
          ...data,
          is_archived: this.props.archived,
          assign_to_all: true,
        },
        params: { archived: this.props.archived },
        extra: { resolve, reject },
        onSuccess: this.addProjectSuccess,
        onFailed: this.addProjectFailed,
      })
    })
  }

  addProjectSuccess = (response, extra) => {
    this.patchList([{ tasks: [], ...response.data }])
    extra.resolve()
  }

  addProjectFailed = (error, extra) => {
    lib.responseMessage.error(error.response)
    extra.reject()
  }

  onEdit = (project) => {
    let name = project.name

    Modal.confirm({
      title: 'Edit Project Name',
      content: (
        <div>
          <Input
            ref={ref => {
              setTimeout(() => {
                if (ref) ref.focus()
              }, 150)
            }}
            defaultValue={name}
            placeholder='Project Name'
            onChange={e => {name = e.target.value}}
          />
        </div>
      ),
      onOk: () => this.edit({ ...project, name }),
    })
  }

  edit = (data) => {
    return new Promise((resolve, reject) => {
      request({
        method: 'patch',
        urlKey: 'change-projects',
        data: {
          name: data.name,
        },
        args: [data.alias],
        params: { archived: this.props.archived },
        extra: { resolve, reject },
        onSuccess: this.editSuccess,
        onFailed: this.editFailed,
      })
    })
  }

  editSuccess = (response, extra) => {
    this.patchList([response.data])
    extra.resolve()
  }

  editFailed = (error, extra) => {
    lib.responseMessage.error(error.response)
    extra.reject()
  }

  onSearch = (e) => {
    this.setThisState({ searchValue: e.target.value })
  }

  render() {
    if (this.state.errorResponse && !this.state.isLoading) {
      return this.getErrorComp()
    }

    const isEmptySearch = this.__list.length > 0 && this.__list.filter(project => project.name.toLowerCase().indexOf(this.state.searchValue.trim().toLowerCase()) !== -1).length === 0

    return (
      <Card className='app-card'>
        <Spin spinning={this.state.isLoading}>
          <Row justify='space-between' gutter={[12, 12]} style={{ marginBottom: '8px' }}>
            <Col style={{ fontSize: '21px', fontWeight: 500 }}>
              Projects {this.getTypeLabel()}
            </Col>
            <Col style={{ flex: 1 }}>
              <Row justify='end' gutter={[12, 12]}>
                {!this.props.readOnly && (
                  <Col className='only-xs-block'>
                    <Button block icon={<PlusOutlined />} onClick={this.onAddProject}>
                      Add Project
                    </Button>
                  </Col>
                )}
                <Col className='only-xs-block'>
                  <Button block loading={this.state.isLoading} icon={<ReloadOutlined />} onClick={this.read}>
                    Refresh
                  </Button>
                </Col>
                <Col className='only-xs-block'>
                  <Input
                    allowClear
                    placeholder='Search Projects'
                    prefix={<SearchOutlined className='site-form-item-icon' style={{ marginRight: '4px' }} />}
                    value={this.state.searchValue}
                    onChange={this.onSearch}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <div>
            {this.__list.length > 0 && (
              <Collapse>
                {this.__list.map(project => (
                  <Panel
                    // key={project.key}
                    key={project.alias}
                    className='custom-pnt-panel'
                    header={project.name}
                    extra={this.getExtra(project.alias, project)}
                    style={{
                      display:
                        project.name.toLowerCase().indexOf(this.state.searchValue.trim().toLowerCase()) !== -1
                        ? 'block'
                        : 'none',
                    }}
                  >
                    <Tasks
                      ref={ref => {this.onRefTasks(ref, project.alias)}}
                      readOnly={this.props.readOnly}
                      project={project}
                    />
                  </Panel>
                ))}
              </Collapse>
            )}
            {(!this.__list.length || isEmptySearch) && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={`No Projects ${this.getTypeLabel()}`}
              />
            )}
            {this.next && (
              <Row justify='center' style={{ marginTop: '12px' }}>
                <Button loading={this.state.isLoadMore} onClick={this.loadMore}>
                  Load More
                </Button>
              </Row>
            )}
          </div>
        </Spin>
      </Card>
    )
  }
}

export default ContentProjects