import NumberFormat from 'react-number-format';

export default function NumberColor({ target, realization }) {
  let className = ''
  if (target && target !== realization)
    className = target > realization ? 'statistic-down' : 'statistic-up'

  return (
    <span className={className}>
      {!realization ? '-' : (
        <NumberFormat
          thousandSeparator
          value={Math.round((Number(realization) || 0) * 100) / 100}
          displayType='text'
        />
      )}
    </span>
  )
}