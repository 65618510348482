import React from 'react'
import { Avatar, Card, Col, Row, Tooltip } from 'antd'
import { CalendarOutlined, CheckCircleOutlined, EnvironmentOutlined, UserOutlined, WarningOutlined } from '@ant-design/icons'
import moment from 'moment'

import { BaseComponent } from 'src/components'

class ListDailyItem extends BaseComponent {
  render() {
    const { attendance } = this.props

    return (
      <div style={{ marginBottom: '8px' }}>
        <Card
          hoverable
          style={{ borderRadius: '8px', overflow: 'hidden' }}
          bodyStyle={{ padding: '0' }}
          onClick={() => this.props.onSelect(attendance)}
        >
          <Row justify='space-between'>
            <Col style={{ padding: this.mutableScreen.isMobile ? '12px' : '6px' }}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Avatar
                    size={48}
                    src={attendance.photo && attendance.photo.photo}
                    icon={<UserOutlined />}
                  />
                </Col>
                <Col>
                  <div style={{ fontWeight: 'bold' }}>
                    {attendance.status_display}
                  </div>
                  <div style={{ color: '#9c9c9c' }}>
                    <CalendarOutlined style={{ marginRight: '6px' }} />
                    {attendance.attendance_date
                      ? `${moment(attendance.attendance_date).format('ddd, HH:mm a')}`
                      : 'No Present Date'
                    }
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className='only-xs-block'>
              <Row className='full-height'>
                {attendance.latitude && (
                  <Tooltip title='Open Google Maps' placement='left'>
                    <Col flex='auto' style={{ backgroundColor: '#ff4d4f' }} onClick={this.openMaps}>
                      <Row className='full-height' justify='center' align='middle' style={{ padding: '6px 12px' }}>
                        <EnvironmentOutlined
                          style={{
                            fontSize: '21px',
                            // color: validated ? '#52c41a' : '#faad14',
                            color: 'white',
                          }}
                        />
                      </Row>
                    </Col>
                  </Tooltip>
                )}
                <Tooltip title={attendance.status_display} placement='left'>
                  <Col
                    flex='auto'
                    style={{
                      // padding: '12px',
                      padding: '6px 12px',
                      // width: this.mutableScreen.isMobile ? '38px' : '64px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: attendance.status === 'validated' ? '#52c41a' : '#faad14',
                    }}
                  >
                    {attendance.status === 'validated'
                      ? <CheckCircleOutlined style={{ color: 'white', fontSize: '21px' }} />
                      : <WarningOutlined style={{ color: 'white', fontSize: '21px' }} />
                    }
                  </Col>
                </Tooltip>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>
    )
  }
}

export default ListDailyItem