import PropTypes from 'prop-types';
import { Button, Col, DatePicker, Form, Input, Row, Select, Spin } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import moment from 'moment';

import { BaseModal, VisibilityContainer } from 'src/components';
import { lib, request } from 'src/Utils';
import leaveHelper from '../../helper';
import helper from '../../helper';
import OvertimeList from './List';
import FormContent from './Form';
import ViewContent from './View';
import DialogLeaveDetail from '../../AnnualLeave/DialogLeaveDetail';

class DialogOvertimeDetail extends DialogLeaveDetail {
  constructor(props) {
    super(props)

    this.title = 'Staff Overtime'
    window._moment = moment

    this.state = {
      ...this.state,
      defaultDate: null,
      // userLeave: null,
      selectedOvertime: null,
      userOvertimes: [],
      // userActivities: [], // current day
    }
  }

  onRefForm = (ref) => this._form = ref
  getForm = () => this._form
  getLeaveOvertime = () => this.state.selectedOvertime
  // getLeaveOvertimes = () => this.state.userOvertimes
  allowSubmission = () => helper._overtime.allowSubmission(this.state.defaultDate, this.state.userOvertimes, get(this.props.user, 'id'))

  // getModalProps = () => ({
  //   destroyOnClose: true,
  //   bodyStyle: { padding: 0 },
  //   width: 640,
  //   footer: null,
  // })

  onVisibleChanged = (visible) => {
    if (!visible && this._form)
      this._form.resetFields()

    // if (visible)
    this.setState({
      viewType: !this.state.userOvertimes.length ? 'form' : 'view',
      // selectedOvertime: this.state.userOvertimes.length === 1 ? this.state.userOvertimes[0] : null,
      selectedOvertime: this.state.userOvertimes.slice(-1)[0],
    })
  }

  selectUserOvertime = (userOvertime) => {
    this.setState({ selectedOvertime: userOvertime })
  }

  onSave = () => {
    this._form.validateFields().then(values => {
      this.setState({ isLoading: true }, () => {
        request({
          method: 'post',
          urlKey: 'overtime-user-overtimes',
          data: {
            user: get(this.props.user, 'id'),
            date: values.date.format('YYYY-MM-DD'),
            start_time: values.range_time[0].format('HH:mm'),
            finish_time: values.range_time[1].format('HH:mm'),
            note: values.note,
            refer_leave: get(values.staff_leave, 'value', null),
            is_holiday: values.is_holiday,
            timezone: values.timezone.value,
          },
          onSuccess: this.onSaveSuccess,
          onFailed: this.onSaveFailed,
        })
      })
      // this.setState({ isLoading: true }, () => {
      //   request({
      //     urlKey: 'read-displayModule',
      //     data: values,
      //     onSuccess: this.applyLeaveSuccess,
      //     onFailed: this.applyLeaveFailed,
      //     extra: { values },
      //   })
      // })
    }).catch((e) => {console.log(e)})
  }

  onSaveSuccess = (res, extra) => {
    this.hide(null, () => this.props.onSavedOvertime(res.data), true)
  }

  onSaveFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.setState({ isLoading: false })
  }

  didMount() {
  }

  renderList = () => {
    return (
      <OvertimeList
        date={this.state.defaultDate}
        // userLeaves={this.state.userLeaves}
        userOvertimes={this.state.userOvertimes}
        canAddAnother={this.canAddAnother}
        // getLeaveTypeLabel={leaveHelper.getTypeLabel}
        onSelect={this.selectUserOvertime}
        onAddAnother={() => this.setState({ viewType: 'form', selectedOvertime: null })}
      />
    )
  }

  renderForm = () => {
    const { selectedOvertime } = this.state
    return (
      <Form
        ref={this.onRefForm}
        layout="vertical"
        initialValues={{
          date: this.state.defaultDate && moment(this.state.defaultDate),
          is_holiday: helper.isWeekend(this.state.defaultDate),
          ...(selectedOvertime && {
            is_holiday: selectedOvertime.is_holiday,
            timezone: lib.getSelectQueryValue(selectedOvertime.timezone, { valKey: 'id', valLabel: ['gmt_repr', 'name'] }),
            // timezone: {
            //   ...selectedOvertime.timezone,
            //   value: selectedOvertime.timezone.id,
            //   label: selectedOvertime.timezone.gmt_repr + ' ' + selectedOvertime.timezone.name,
            // },
            range_time: [helper._overtime._time(selectedOvertime.start_time), helper._overtime._time(selectedOvertime.finish_time)],
            note: selectedOvertime.note,
            staff_leave: selectedOvertime.refer_leave && {
              ...selectedOvertime.refer_leave,
              value: selectedOvertime.refer_leave.pk,
              label: helper._leave.renderSelect(selectedOvertime.refer_leave),
            },
          }),
          // ...this.state.selectedOvertime,
        }}
      >
        <FormContent
          user={this.props.user}
          defaultDate={this.state.defaultDate}
          // leaveTypes={leaveHelper.leaveTypes}
          hide={this.hide}
          // getLeaveTypeLabel={leaveHelper.getTypeLabel}
          getForm={() => this._form}
          onSave={this.onSave}
        />
      </Form>
    )
  }

  renderView = () => {
    return (
      <ViewContent
        user={this.props.user}
        userOvertime={this.state.selectedOvertime}

        // userActivities={this.state.userActivities}
        // getLeaveTypeLabel={leaveHelper.getTypeLabel}
        onAccept={this.props.onAccept}
        onReject={this.props.onReject}
        canAddAnother={this.canAddAnother}
        // onAccept={this.props.onAccept}
        // onReject={this.props.onReject}
        onAddAnother={() => {
          this.setState({ viewType: 'form' })
        }}
        goBack={() => this.setState({ viewType: 'view', selectedOvertime: null })}

        setLoading={this.setLoading}
        hide={this.hide}
        onChangedOvertime={this.props.onSavedOvertime}
      />
    )
  }

  // renderContent = () => {
  //   console.log('Rendered', this)
  //   const canAddAnother = moment(this.state.defaultDate).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') && this.state.userLeaves.filter(userLeave => userLeave.status !== 'rejected').length === 0

  //   return (
  //     <div>
  //       <Spin spinning={this.state.isLoading}>
  //         {/* {this.isShowView() ? (
  //           <ViewContent
  //             user={this.props.user}
  //             userLeave={this.state.userLeave}
  //             userActivities={this.state.userActivities}
  //             getLeaveTypeLabel={leaveHelper.getTypeLabel}
  //             onAccept={this.props.onAccept}
  //             onReject={this.props.onReject}
  //           />
  //         ) : (
  //           <Form
  //             ref={this.onRefForm}
  //             layout="vertical"
  //             initialValues={{
  //               date: this.state.defaultDate && moment(this.state.defaultDate),
  //               // time: [moment().startOf('day').hours(8), moment().endOf('day').hours(21)],
  //             }}
  //           >
  //             <FormContent
  //               user={this.props.user}
  //               defaultDate={this.state.defaultDate}
  //               leaveTypes={leaveHelper.leaveTypes}
  //               hide={this.hide}
  //               getLeaveTypeLabel={leaveHelper.getTypeLabel}
  //               getForm={() => this._form}
  //               onSave={this.onSave}
  //             />
  //           </Form>
  //         )} */}
  //         {/* <Form ref={this.onRefForm} layout="vertical" initialValues={{ date: this.state.defaultDate && moment(this.state.defaultDate) }}>
  //           <div className="ant-modal-body">
  //             <Form.Item name="date" label="Date" rules={[{ required: true }]}>
  //               <DatePicker
  //                 autoFocus
  //                 allowClear={false}
  //                 style={{ width: '100%' }}
  //                 disabledDate={currentDate => currentDate.format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')}
  //               />
  //             </Form.Item>
  //             <Form.Item name="type" label="Type" rules={[{ required: true }]}>
  //               <Select placeholder="Leave Type">
  //                 {this.leaveTypes.map(leaveType => (
  //                   <Select.Option key={leaveType.key} value={leaveType.key}>
  //                     {leaveType.label}
  //                   </Select.Option>
  //                 ))}
  //               </Select>
  //             </Form.Item>
  //             <Form.Item name="reason" label="Reason" rules={[{ required: true }]}>
  //               <Input.TextArea placeholder="Reason" />
  //             </Form.Item>
  //           </div>
  //           <div>
  //             <div className="ant-modal-footer">
  //               {this.renderFooter()}
  //             </div>
  //           </div>
  //         </Form> */}
  //       </Spin>
  //     </div>
  //   )
  // }
}

DialogOvertimeDetail.propTypes = {
  ...DialogOvertimeDetail.propTypes,
  user: PropTypes.object.isRequired,
  onSaveLeave: PropTypes.func,
}

DialogOvertimeDetail.defaultProps = {
  ...DialogOvertimeDetail.defaultProps,
  onSaveLeave: () => {},
}

export default DialogOvertimeDetail