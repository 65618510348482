import React from 'react';
import { Button, Col, DatePicker, Row, Space, Table, Tooltip, Badge, Typography, Descriptions } from 'antd';
import {
  CalendarFilled,
  CalendarOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { get, isEmpty, groupBy, sortBy, sumBy } from 'lodash';
import classNames from 'classnames';
import {BaseReport} from '../components';
import { lib, permissions, request } from 'src/Utils';
import { BarAnim } from 'src/components'
import _h from 'src/pages/DashboardApp/Submission/helper';

class MonthlyTimesheet extends BaseReport {
  constructor(props) {
    super(props)

    this.RANGE_TYPE = 'month'
    this.DEFAULT_RANGE_TYPE = 'month'
    this.DEFAULT_RANGE_FORMAT = 'YYYY-MM'
    this.title = 'Monthly Timesheet'
    this.scrollConfig = { x: 1024 }

    this.setRangeDate({
      start_date: this.objParam.start_date || null,
      end_date: this.objParam.end_date || null,
    })

    this.state = {
      searchValue: '',
      responseData: [],
      objUserOvertimes: {},
    }
  }

  columns = [
    {
      title: 'Week',
      key: 'week',
      dataIndex: 'week',
      width: '100px',
      // sorter: (a, b) => moment(get(a, 'week.start_date')) - moment(get(b, 'week.start_date')),
      render: (e) => {
        return(
          <Space direction="vertical">
            {/* <small style={{ fontWeight: 'bold' }}>Week {e} of {obj.month}</small> */}
            <Typography.Text type="secondary" strong> <CalendarOutlined /> {moment(e && e.start_date).format('DD')} - {moment(e && e.end_date).format('DD')}</Typography.Text>
          </Space>
        )
      }
    },
    {
      title: 'Total Worked',
      key: 'totalWorked',
      dataIndex: 'totalWorked',
      width: '100px',
      align: 'right',
      // sorter: (a, b) => a.totalWorked - b.totalWorked,
      render: (e)=>{
        return(<Typography.Text strong type="secondary">{lib.time.getLabel(e, true)}</Typography.Text>)
      }
    },
    {
      title: 'Sunday',
      key: 'Sunday',
      dataIndex: 'Sunday',
      width: '100px',
      // onCell: (record) => ({ className: classNames({ 'overtime-working-weekend': this.isValidOvertime(record['Sunday']) }) }),
      onCell: (record) => ({ className: this.getCellClassName(record['Sunday']) }),
      render: (e)=>this.renderBar(e)
    },
    {
      title: 'Monday',
      key: 'Monday',
      dataIndex: 'Monday',
      width: '100px',
      onCell: (record) => ({ className: classNames({ 'overtime-working-weekday': this.isValidOvertime(record['Monday']) }) }),
      render: (e)=>this.renderBar(e)
    },
    {
      title: 'Tuesday',
      key: 'Tuesday',
      dataIndex: 'Tuesday',
      width: '100px',
      onCell: (record) => ({ className: classNames({ 'overtime-working-weekday': this.isValidOvertime(record['Tuesday']) }) }),
      render: (e)=>this.renderBar(e)
    },
    {
      title: 'Wednesday',
      key: 'Wednesday',
      dataIndex: 'Wednesday',
      width: '100px',
      onCell: (record) => ({ className: classNames({ 'overtime-working-weekday': this.isValidOvertime(record['Wednesday']) }) }),
      render: (e)=>this.renderBar(e)
    },
    {
      title: 'Thursday',
      key: 'Thursday',
      dataIndex: 'Thursday',
      width: '100px',
      onCell: (record) => ({ className: classNames({ 'overtime-working-weekday': this.isValidOvertime(record['Thursday']) }) }),
      render: (e)=>this.renderBar(e)
    },
    {
      title: 'Friday',
      key: 'Friday',
      dataIndex: 'Friday',
      width: '100px',
      onCell: (record) => ({ className: classNames({ 'overtime-working-weekday': this.isValidOvertime(record['Friday']) }) }),
      render: (e)=>this.renderBar(e)
    },
    {
      title: 'Saturday',
      key: 'Saturday',
      dataIndex: 'Saturday',
      width: '100px',
      onCell: (record) => ({ className: classNames({ 'overtime-working-weekend': this.isValidOvertime(record['Saturday']) }) }),
      render: (e)=>this.renderBar(e)
    },
  ];

  isValidOvertime = (item) => {
    return item && get(this.state.objUserOvertimes[item.date], 'isValid')
  }

  getCellClassName = (item) => {
    if (item && get(this.state.objUserOvertimes[item.date], 'isValid')) {
      return this.state.objUserOvertimes[item.date].userOvertime.is_holiday
        ? 'overtime-working-weekend'
        : 'overtime-working-weekday'
    }
  }

  checkRange = (startDate, endDate) => moment(startDate).format('YYYY-MM') === moment(endDate).format('YYYY-MM')

  getRangeDate = (date) => ({
    start_date: moment(date).format('YYYY-MM'),
    end_date: moment(date).format('YYYY-MM'),
  })

  nextMonth = () => {
    const date = moment(this.range_before).add(1, 'month')
    this.onChangeFilter(this.getRangeDate(date))
  }

  prevMonth = () => {
    const date = moment(this.range_before).subtract(1, 'month')
    this.onChangeFilter(this.getRangeDate(date))
  }

  renderBar=(obj) => {
    const data = {...obj}
    if(isEmpty(data)) return null

    const objUserOvertime = this.state.objUserOvertimes[data.date]
    return(
      <Row align="middle" gutter={[12, 12]}>
        <Col>
          <Tooltip
            placement='top'
            overlay={`${lib.time.getNumberTime(data.totalWorked)}`}
            mouseLeaveDelay={0}
          >
            <Space direction="vertical">
              <Typography.Text type="secondary" style={{ fontSize: '11px' }}>
                <CalendarOutlined /> {data.date}
              </Typography.Text>
              <div>
                <BarAnim
                  className='bar-monthly-report'
                  value={
                    <center
                      style={{
                        fontSize: '10px',
                        fontWeight: 'bold',
                        marginLeft: '13%',
                        color: 'white',
                        position: 'absolute',
                        marginTop: '-15.5px'
                      }}
                    >
                      {lib.time.getNumberTime(data.totalWorked)}
                    </center>}
                  width={this.getBarWidth(data.totalWorked)}
                />
              </div>
            </Space>
          </Tooltip>
        </Col>
        {!!get(objUserOvertime, 'ms') && (
          <Col>
            <div>
              <Tooltip
                color="#fff"
                mouseLeaveDelay={0}
                title={this.renderOvertimeTooltip(objUserOvertime)}
                overlayStyle={{ maxWidth: '320px' }}
              >
                <Typography.Text type="secondary" className="font-weight-bold" style={{ textAlign: 'center' }}>
                  <FieldTimeOutlined style={{ marginRight: '4px' }} />
                  {_h._overtime.renderDuration(objUserOvertime.ms, { lblHour: 'h', lblMinute: 'm' })}
                </Typography.Text>
              </Tooltip>
            </div>
          </Col>
        )}
      </Row>
    )
  }

  renderOvertimeTooltip = (objUserOvertime) => {
    return (
      <div>
        <Descriptions bordered column={1} style={{ background: 'white' }} labelStyle={{ padding: '12px' }} contentStyle={{ padding: '12px', fontWeight: 500 }}>
          <Descriptions.Item label="Requested">
            {_h._overtime.renderDurationByItem(objUserOvertime.userOvertime)}
          </Descriptions.Item>
          <Descriptions.Item label="Overtime">
            {_h._overtime.renderDuration(objUserOvertime.ms)}
          </Descriptions.Item>
        </Descriptions>
      </div>
    )
  }

  generateDays=(data)=>{
    const days = groupBy(data, (e)=>moment(e.finish_time).format('dddd'))
    const w = {}
    for(const i in days){
      let date
      if(!isEmpty(days[i])) date = moment(days[i][0].finish_time).format('YYYY-MM-DD') // DD/MM/YYYY

      let totalWorked = 0
      for(const x of days[i]){
        const diff = moment(x.finish_time).diff(moment(x.start_time), 'milliseconds')
        totalWorked+=diff
      }
      w[i] = {totalWorked, date}
    }
    return w
  }

  allDays(year, month) {
    var monthIndex = month - 1; // 0..11 instead of 1..12
    var names = [ 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat' ];
    var date = new Date(year, monthIndex, 1);
    var result = [];
    while (date.getMonth() == monthIndex) {
      result.push({date: new Date(year, monthIndex, date.getDate()), number: date.getDate()});
      date.setDate(date.getDate() + 1);
    }
    return result;
  }

  FDays=(obj)=>{
    const month = []
    if(!isEmpty(obj)){
      const date = moment(this.range_before)
      const data = this.allDays(date.format('YYYY'), date.format('MM'))
      
      for(const i of data){
        let val = { date: i.date }
        if(obj[i.number]) val = { date: i.date, data: obj[i.number] }
        month.push(val)
      }
    }
    return month
  }

  readUserOvertimes = async (extraRequest) => {
    return !permissions.hasOvertimeModule() ? null :
    new Promise(resolve => {
      request({
        urlKey: 'overtime-user-overtimes',
        data: {
          user: this.id,
          view_mode: 'calendar',
          status: 'approved',
          ordering: 'created',
          date_after: moment(this.range_before).format('YYYY-MM-DD'),
          date_before: moment(this.range_after).format('YYYY-MM-DD'),
        },
        onSuccess: res => resolve(res.data),
        onFailed: this.readFailed,
        extra: extraRequest,
      })
    })
  }

  readSuccess = async (res, e) => {
    if(isEmpty(res.data)) {
      this.setThisState({
        [e.keyLoading]: false,
        errorResponse: null,
        responseData: [],
      })
    }else{
      const userOvertimes = await this.readUserOvertimes(e)

      const objUserOvertimes = {}
      for (const userOvertime of (userOvertimes || []))
        objUserOvertimes[userOvertime.date] = { userOvertime, ..._h._overtime.getDiffActivities(userOvertime, res.data) }

      const dayGroups = groupBy(res.data, a => moment(a.finish_time).format('D'))
      const generate = this.FDays(dayGroups)
      
      const groups = groupBy(generate, (e)=>moment(e.date).week())
      const data = []
      for(const i in groups){
        const d = sortBy(groups[i], 'date')
        let week= { week: { start_date: d[0].date, end_date: d[d.length-1].date } }
        let totalWeekWorked = 0
        for(const j of groups[i]){
          // console.log(j)
          let key = moment(j.date).format('dddd')
          week[key] = { date: moment(j.date).format('YYYY-MM-DD')} // DD/MM/YYYY
          week = { ...week, ...this.generateDays(j.data) }
        }
        for(const k in week) if(week[k].totalWorked) totalWeekWorked+= week[k].totalWorked
        week.totalWorked = totalWeekWorked
        data.push(week)
      }
      this.setThisState({
        objUserOvertimes,
        [e.keyLoading]: false,
        responseData: data,
        errorResponse: null
      })
    }
  }

  getFilterInput = () => (
    <Col className='only-xs-block'>
      <Row>
        <Col>
          <Button icon={<CaretLeftOutlined />} onClick={this.prevMonth} />
        </Col>
        <Col style={{ margin: '0 -1px', flex: 1 }}>
          <DatePicker
            allowClear={false}
            picker="month"
            // defaultValue={moment()}
            value={moment(this.range_before)}
            onChange={(selectedDate, dateStrings) => {
              this.onChangeFilter(this.getRangeDate(selectedDate))
            }}
          />
        </Col>
        <Col>
          <Button icon={<CaretRightOutlined />} onClick={this.nextMonth} />
        </Col>
      </Row>
    </Col>
  )

  getBodyContent = () => {
    const selectedUser = this.users.find(user => `${user.id}` === `${this.id}`) || this.myProfile
    const [s] = [this.state]
    return(
      <>
        <Badge.Ribbon
          color="red"
          
          size="default"
          text={lib.time.getLabel(sumBy(s.responseData, 'totalWorked'), true)}
        >
          <div style={{ margin: '15px', maxWidth: 'calc(100% - 100px)' }}>
            <Typography.Title level={3} type="secondary">
              <CalendarFilled /> {moment(this.range_before).format('MMMM')} {selectedUser && `- ${lib.getFullName(selectedUser)}`}
            </Typography.Title>
          </div>
        </Badge.Ribbon>

        <Table
          size="middle"
          pagination={false}
          rowKey="week.start_date"
          columns={this.columns}
          dataSource={s.responseData}
          scroll={this.scrollConfig}
        />
      </>
    )
  }

}
export default MonthlyTimesheet