import React from 'react';

class Admin extends React.PureComponent {
  render() {
    return (
      'Admin'
    )
  }
}

export default Admin