const options = {
  "chart": {
    "type": "bar",
    "height": 200
  },
  "title": {
    "text": " "
  },
  "xAxis": {
    "categories": [
      "Last Month",
      "This Month",
      "Target",
      "Prediction"
    ],
    "title": {
      "text": null
    }
  },
  "yAxis": {
    "min": 0,
    "title": {
      "text": " ",
      "align": "high"
    },
    "labels": {
      "overflow": "justify"
    }
  },
  "tooltip": {
    // "valueSuffix": " millions",
    "pointFormat": "<span style=\"color:{point.color}\">\u25CF</span><span> {series.name} : </span><b>{point.y:,.2f}%</b>"
  },
  "plotOptions": {
    "bar": {
      "dataLabels": {
        "enabled": true,
        "format": "{point.y:,.2f}%"
      }
    }
  },
  "credits": {
    "enabled": false
  },
  "legend": {
    "enabled": false
  },
  "series": [
    {
      "name": "Amount",
      "color": "#7cb5ec",
      // "dataLabels": {
      //   x: 25000
      // },
      "data": [
        { y: 0, extra: '' },
        { y: 0, extra: '' },
        { y: 100 },
        { y: 0, extra: '' },
      ]
    },
    // {
    //   "name": "Highlight",
    //   "color": "#1890ff",
    //   "data": [
    //     { y: 0 },
    //     { y: 0 },
    //   ]
    // },
  ]
}

export default options