import PropTypes from 'prop-types';

import BasePointByGrade from 'src/pages/DashboardApp/DashboardManager/ChartProduction/PointByGrade';
import { lib } from 'src/Utils';

class PointByGrade extends BasePointByGrade {
  setAverage = (productionJobs, nextProps = this.props) => {
    // const prodDays = []
    let totalDays = 0, totalJobs = productionJobs.length

    // for (const i in productionJobs) {
    //   if (prodDays.indexOf(productionJobs[i].prod_day) === -1) {
    //     totalDays += 1
    //     prodDays.push(productionJobs[i].prod_day)
    //   }
    // }

    this.averagePerDay = nextProps.totalWorkingDays
      ? (this.totalValue / nextProps.totalWorkingDays).toFixed(2)
      : 'N/A'
    this.averagePerJob = (this.totalValue / (totalJobs || 1)).toFixed(2)
  }

  childComponentWillReceiveProps(nextProps) {
    if (this.props.totalWorkingDays !== nextProps.totalWorkingDays) {
      this.convertData(nextProps.userProductionJobs, nextProps)
    }
  }
}

PointByGrade.propTypes = {
  ...PointByGrade.propTypes,
  totalWorkingDays: PropTypes.number.isRequired,
}

PointByGrade.defaultProps = {
  ...PointByGrade.defaultProps,
}

export default PointByGrade