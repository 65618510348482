import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Card, Col, Row, Spin, Tooltip } from 'antd'
import { CheckCircleOutlined, CheckOutlined, EnvironmentOutlined, RetweetOutlined, UserOutlined, WarningOutlined } from '@ant-design/icons'
import moment from 'moment'
import classnames from 'classnames'

import { BaseComponent, BaseList, BarAnim, DialogFullscreen } from 'src/components'
import ProfileContainer from './ProfileContainer'
import { lib } from 'src/Utils'

class HeaderAttendance extends BaseComponent {
  constructor(props) {
    super(props)

    this.urlKey = 'read-activities'
    this.pagination = false

    this.keysQueryParam = ['range_before', 'range_after', 'id']

    this.range_before = moment().startOf('month').format()
    this.range_after = moment().endOf('month').format()

    this.myProfile = lib.ls.getCurrentUser()
    this.totalWorking = 0
    this.totalEdited = 0

    this.state = {
      ...this.state,
      isLoading: false,
    }
  }

  onRefPhotos = (ref) => {
    this._photos = ref
  }

  hasManaged = () => this.props.users.length > 1

  convertResponseData = (responseData) => {
    const { activities } = responseData[0]

    this.totalWorking = 0
    this.totalEdited = 0
    for (const i in activities) {
      if (activities[i].is_manual_time)
        this.totalEdited += lib.time.getDiff(activities[i])
      else
        this.totalWorking += lib.time.getDiff(activities[i])
    }
  }

  getAttendanceTime = (attendance) => {
    const validated = (attendance && attendance.status === 'validated')
    return !attendance ? '-' : (
      <Row justify='center' align='middle' style={{ color: !validated && '#faad14' }}>
        <Col style={{ position: 'relative' }}>
          {attendance.latitude && (
            <div style={{ position: 'absolute', top: 0, left: '-35px' }}>
              <Tooltip title='Open Google Maps'>
                <EnvironmentOutlined
                  style={{ fontSize: '24px', color: validated ? '#52c41a' : '#faad14' }}
                  onClick={(e) => {
                    e.stopPropagation()

                    lib.openMaps(attendance.latitude, attendance.longitude)
                  }}
                />
              </Tooltip>
            </div>
          )}
          {moment(attendance.attendance_date).format('HH : mm : ss')}
          <div style={{ position: 'absolute', top: 0, right: '-36px' }}>
            {validated
              // ? <CheckCircleOutlined style={{ color: '#52c41a', fontSize: '24px' }} />
              ? <CheckOutlined style={{ color: '#52c41a', fontSize: '24px' }} />
              : <WarningOutlined style={{ color: '#faad14', fontSize: '24px' }} />
            }
          </div>
        </Col>
      </Row>
    )
  }

  showPhotos = (attendance, attendances) => {
    if (attendance) {
      const attendanceIndex = attendances.findIndex(att => att.alias === attendance.alias)
      this._photos.show(attendanceIndex, { photos: attendances })
    }
  }

  getSrc = (attendance) => {
    return attendance && attendance.photo.photo
  }

  getTitle = (attendance) => {
    return `${attendance.attend_type_display} - ${moment(attendance.attendance_date).format('ddd, DD MMM YYYY HH:mm a')} (${attendance.status_display})`
  }

  renderTitle() {
    return moment().format('YYYY-MM-DD') === moment(this.props.date).format('YYYY-MM-DD')
      ? `Today's Working Time`
      : `Working Time - ${moment(this.props.date).format('DD MMM YYYY')}`
  }

  renderCardUser() {
    return (
      <ProfileContainer
        selectedUser={this.props.user}
        users={this.props.users}
        onSelectUser={this.props.onSelectUser}
      >
        <Card
          hoverable={this.hasManaged()}
          style={{ borderRadius: '8px', width: '200px', height: '100%', position: 'relative' }}
        >
          {this.hasManaged() && (
            <RetweetOutlined
              style={{
                position: 'absolute',
                top: '8px',
                right: '8px',
                color: '#bbbbbb',
                fontSize: '20px',
              }}
            />
          )}

          <Row justify='center' align='middle' style={{ flexDirection: 'column', textAlign: 'center' }}>
            <Avatar
              src={this.props.user && this.props.user.photo}
              icon={<UserOutlined />}
              size={72}
              style={{
                color: 'white',
                marginBottom: '12px',
                boxShadow: '0 6px 16px -8px RGB(0 0 0/8%)',
              }}
            />
            <h3 className='text-truncate' style={{ width: '100%' }}>
              {lib.getFullName(this.props.user)}
            </h3>
            <p className='text-truncate' style={{ marginBottom: 0, width: '100%' }}>
              {this.myProfile.company.name}
            </p>
          </Row>
        </Card>
      </ProfileContainer>
    )
  }

  renderCardDetail() {
    const todayAttendances = this.props.attendances
      .filter(att => moment(att.attendance_date).format('YYYY-MM-DD') === moment(this.props.date).format('YYYY-MM-DD'))
      .sort((a, b) => a.attendance_date > b.attendance_date ? 1 : -1)
    const checkinAttendances = todayAttendances.filter(att => att.attend_type === 'checkin')
    const todayCheckin =
      checkinAttendances.find(att => att.status === 'validated') ||
      checkinAttendances[0]

    const checkoutAttendances = todayAttendances.filter(att => att.attend_type === 'checkout')
    const validatedCheckout = checkoutAttendances.filter(att => att.status === 'validated')
    const todayCheckout =
      validatedCheckout[validatedCheckout.length - 1] ||
      checkoutAttendances[checkoutAttendances.length - 1]

    const hours = 10
    const max = hours * 1000 * 60 * 60
    let totalTime = '-', msToday = 0
    if (todayCheckin && todayCheckout) {
      totalTime = lib.time.getLabel(
        msToday = lib.time.getDiff({
          start_time: todayCheckin.attendance_date,
          finish_time: todayCheckout.attendance_date
        })
      )
    }

    const barPct = msToday / max > 1 ? 1 : msToday / max

    return (
      <div>
        <Row justify='space-between' style={{ textAlign: 'center' }}>
          <Col
            className={classnames('daily-checkin-summary', {
              'has-photos': !!todayCheckin,
              'unvalidated': todayCheckin && todayCheckin.status !== 'validated'
            })}
            onClick={() => this.showPhotos(todayCheckin, checkinAttendances)}
          >
            <div style={{ padding: '12px' }}>
              <div style={{ fontSize: '28px', fontWeight: 500 }}>
                {this.getAttendanceTime(todayCheckin)}
              </div>
              <div className='subtitle' style={{ fontSize: '16px' }}>
                Check-In Time
              </div>
            </div>
          </Col>
          <Col
            className={classnames('daily-checkin-summary', {
              'has-photos': !!todayCheckout,
              'unvalidated': todayCheckout && todayCheckout.status !== 'validated'
            })}
            onClick={() => this.showPhotos(todayCheckout, checkoutAttendances)}
          >
            <div style={{ padding: '12px' }}>
              <div style={{ fontSize: '28px', fontWeight: 500 }}>
                {this.getAttendanceTime(todayCheckout)}
              </div>
              <div className='subtitle' style={{ fontSize: '16px' }}>
                Check-Out Time
              </div>
            </div>
          </Col>
          <Col className='daily-checkin-summary'>
            <div style={{ padding: '12px' }}>
              <div style={{ fontSize: '28px', fontWeight: 500 }}>
                {totalTime}
              </div>
              <div className='subtitle' style={{ fontSize: '16px' }}>
                Total Time
              </div>
            </div>
          </Col>
        </Row>
        {this.renderTotalBar(
          barPct,
          { title: `Max progress bar is ${hours} hours` },
        )}
      </div>
    )
  }

  renderTotalBar(barPct, tooltipProps) {
    return !this.mutableScreen.isMobile && (
      <Tooltip placement='left' {...tooltipProps}>
        <div style={{ marginTop: '18px' }}>
          <BarAnim width={`${barPct * 100}%`} />
        </div>
      </Tooltip>
    )
  }

  render() {
    // const { user, attendances, date } = this.props

    // const hasManaged = this.props.users.length > 1

    // const todayAttendances = attendances
    //   .filter(att => moment(att.attendance_date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))
    //   .sort((a, b) => a.attendance_date > b.attendance_date ? 1 : -1)
    // const checkinAttendances = todayAttendances.filter(att => att.attend_type === 'checkin')
    // const todayCheckin =
    //   checkinAttendances.find(att => att.status === 'validated') ||
    //   checkinAttendances[0]

    // const checkoutAttendances = todayAttendances.filter(att => att.attend_type === 'checkout')
    // const validatedCheckout = checkoutAttendances.filter(att => att.status === 'validated')
    // const todayCheckout =
    //   validatedCheckout[validatedCheckout.length - 1] ||
    //   checkoutAttendances[checkoutAttendances.length - 1]

    // const hours = 10
    // const max = hours * 1000 * 60 * 60
    // let totalTime = '-', msToday = 0
    // if (todayCheckin && todayCheckout) {
    //   totalTime = lib.time.getLabel(
    //     msToday = lib.time.getDiff({
    //       start_time: todayCheckin.attendance_date,
    //       finish_time: todayCheckout.attendance_date
    //     })
    //   )
    // }

    // const barPct = msToday / max > 1 ? 1 : msToday / max

    return (
      <Spin spinning={this.state.isLoading}>
        <Row gutter={[12, 12]} style={{ paddingTop: '12px' }}>
          <Col flex="none">
            {this.renderCardUser()}
            {/* <ProfileContainer users={this.props.users} onSelectUser={this.props.onSelectUser}>
              <Card
                hoverable={hasManaged}
                style={{ borderRadius: '8px', width: '200px', height: '100%', position: 'relative' }}
              >
                {hasManaged && (
                  <RetweetOutlined
                    style={{
                      position: 'absolute',
                      top: '8px',
                      right: '8px',
                      color: '#bbbbbb',
                      fontSize: '20px',
                    }}
                  />
                )}

                <Row justify='center' align='middle' style={{ flexDirection: 'column', textAlign: 'center' }}>
                  <Avatar
                    src={user && user.photo}
                    icon={<UserOutlined />}
                    size={72}
                    style={{
                      color: 'white',
                      marginBottom: '12px',
                      boxShadow: '0 6px 16px -8px RGB(0 0 0/8%)',
                    }}
                  />
                  <h3 className='text-truncate' style={{ width: '100%' }}>
                    {lib.getFullName(user)}
                  </h3>
                  <p className='text-truncate' style={{ marginBottom: 0, width: '100%' }}>
                    {this.myProfile.company.name}
                  </p>
                </Row>
              </Card>
            </ProfileContainer> */}
          </Col>
          <Col flex="auto" className='only-xs-block'>
            <h2>
              {this.renderTitle()}
              {/* {moment().format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')
                ? `Today's Working Time`
                : `Working Time - ${moment(date).format('DD MMM YYYY')}`
              } */}
            </h2>
            {this.renderCardDetail()}
            {/* <Row justify='space-between' style={{ textAlign: 'center' }}>
              <Col
                className={classnames('daily-checkin-summary', {
                  'has-photos': !!todayCheckin,
                  'unvalidated': todayCheckin && todayCheckin.status !== 'validated'
                })}
                onClick={() => this.showPhotos(todayCheckin, checkinAttendances)}
              >
                <div style={{ padding: '12px' }}>
                  <div style={{ fontSize: '28px', fontWeight: 500 }}>
                    {this.getAttendanceTime(todayCheckin)}
                  </div>
                  <div className='subtitle' style={{ fontSize: '16px' }}>
                    Check-In Time
                  </div>
                </div>
              </Col>
              <Col
                className={classnames('daily-checkin-summary', {
                  'has-photos': !!todayCheckout,
                  'unvalidated': todayCheckout && todayCheckout.status !== 'validated'
                })}
                onClick={() => this.showPhotos(todayCheckout, checkoutAttendances)}
              >
                <div style={{ padding: '12px' }}>
                  <div style={{ fontSize: '28px', fontWeight: 500 }}>
                    {this.getAttendanceTime(todayCheckout)}
                  </div>
                  <div className='subtitle' style={{ fontSize: '16px' }}>
                    Check-Out Time
                  </div>
                </div>
              </Col>
              <Col className='daily-checkin-summary'>
                <div style={{ padding: '12px' }}>
                  <div style={{ fontSize: '28px', fontWeight: 500 }}>
                    {totalTime}
                  </div>
                  <div className='subtitle' style={{ fontSize: '16px' }}>
                    Total Time
                  </div>
                </div>
              </Col>
            </Row> */}
            {/* {!this.mutableScreen.isMobile && (
              <Tooltip placement='left' title={`Max progress bar is ${hours} hours`}>
                <div style={{ marginTop: '18px' }}>
                  <BarAnim width={`${barPct * 100}%`} />
                </div>
              </Tooltip>
            )} */}
          </Col>
        </Row>

        {/* Fullscreen photos */}
        <DialogFullscreen
          ref={this.onRefPhotos}
          getSrc={this.getSrc}
          getTitle={this.getTitle}
        />
      </Spin>
    )
  }
}

HeaderAttendance.propTypes = {
  user: PropTypes.object,
  users: PropTypes.array,
  attendances: PropTypes.array,
  onSelectUser: PropTypes.func,
}

HeaderAttendance.defaultProps = {
  user: undefined,
  users: [],
  attendances: [],
  onSelectUser: () => {},
}

export default HeaderAttendance