import PropTypes from 'prop-types';
import { Button, Col, Divider, Input, Popconfirm, Row, Tag } from 'antd';
import { ApiOutlined, ArrowLeftOutlined, AuditOutlined, BookOutlined, CalendarOutlined, CheckOutlined, ChromeOutlined, ClockCircleOutlined, CloseOutlined, CoffeeOutlined, CommentOutlined, DeleteOutlined, FireOutlined, FrownOutlined, HourglassOutlined, MessageOutlined, NotificationOutlined, PieChartOutlined, PlusOutlined, PoweroffOutlined, RestOutlined, SaveOutlined, ScissorOutlined, SmileOutlined, StopOutlined, UsbOutlined, WarningOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';

import { BaseComponent } from 'src/components';
import { lib, request } from 'src/Utils';
import helper from 'src/pages/DashboardApp/Submission/helper';

class LeaveView extends BaseComponent {
  // constructor(props) {
  //   super(props)

  //   this.myProfile = lib.ls.getCurrentUser()
  // }

  onRefNote = (ref) => {
    this._note = ref
    this._timeout = setTimeout(this.focusNote, 250)
  }

  focusNote = () => this._note && this._note.focus()

  approve = () => {
    this.confirm('approved')
  }

  reject = () => {
    this.confirm('rejected')
  }

  confirm = (status) => {
    this.props.setLoading(true, () => {
      request({
        method: 'post',
        urlKey: 'leave-leaves-detail-confirm',
        args: [this.props.userLeave.pk],
        data: {
          status,
          confirmed_note: get(this._note, ['resizableTextArea', 'textArea', 'value'], ''),
        },
        extra: { status },
        onSuccess: this.confirmSuccess,
        onFailed: this.confirmFailed,
      })
    })
  }

  confirmSuccess = (res) => {
    this.props.hide(
      null,
      () => this.props.onChangedLeave({ ...this.props.userLeave, ...res.data }),
      true
    )
  }

  confirmFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.props.setLoading(false)
  }

  cancel = () => {
    this.props.setLoading(true, () => {
      request({
        method: 'post',
        urlKey: 'leave-user-leaves-detail-cancel',
        args: [this.props.userLeave.pk],
        onSuccess: this.cancelSuccess,
        onFailed: this.cancelFailed,
      })
    })
  }

  cancelSuccess = (res) => {
    this.props.hide(
      null,
      () => this.props.onChangedLeave({ ...this.props.userLeave, ...res.data }),
      true
    )
  }

  cancelFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.props.setLoading(false)
  }

  revert = () => {
    this.props.setLoading(true, () => {
      request({
        method: 'post',
        urlKey: 'leave-leaves-detail-revert-confirm',
        args: [this.props.userLeave.pk],
        onSuccess: this.revertSuccess,
        onFailed: this.revertFailed,
      })
    })
  }

  revertSuccess = (res) => {
    this.props.hide(
      null,
      () => this.props.onChangedLeave({ ...this.props.userLeave, ...res.data }),
      true
    )
  }

  revertFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.props.setLoading(false)
  }

  render() {
    const { userLeave } = this.props

    return userLeave && (
      <div>
        <div className="ant-modal-body">
          <div>
            {userLeave.status === 'approved' ? (
              <div style={{ padding: '6px 12px', backgroundColor: '#0eb60e', color: 'white', marginBottom: '12px', fontSize: '16px', fontWeight: 500, textAlign: 'center' }}>
                <span style={{ marginRight: '8px' }}>
                  <CheckOutlined />
                </span>
                Leave application approved
              </div>
            ) : userLeave.status === 'pending' ? (
              <div style={{ padding: '6px 12px', backgroundColor: '#d3d3d3', color: 'rgba(0, 0, 0, 0.6)', marginBottom: '12px', fontSize: '16px', fontWeight: 500, textAlign: 'center' }}>
                <span style={{ marginRight: '8px' }}>
                  <HourglassOutlined />
                </span>
                Waiting for review . . .
              </div>
            ) : (
              <div style={{ padding: '6px 12px', backgroundColor: '#ffeaea', color: '#ff4242', marginBottom: '12px', fontSize: '16px', fontWeight: 500, textAlign: 'center' }}>
                <span style={{ marginRight: '8px' }}>
                  <FrownOutlined />
                </span>
                Application for leave was {userLeave.status_display}
              </div>
            )}
            <div style={{ padding: '0 8px' }}>
              <div style={{ fontSize: '14px' }}>
                <table className="dialog-user-leave-table">
                  <colgroup>
                    <col style={{ width: '140px' }} />
                    <col style={{ width: '30px' }} />
                    <col style={{ width: '420px' }} />
                  </colgroup>
                  <tbody>
                    <tr className="row-reason">
                      <td className="cell-label">
                        <SmileOutlined style={{ marginRight: '12px' }} />
                        User
                      </td>
                      <td>:</td>
                      <td className="cell-value">
                        {lib.getFullName(userLeave.user)}
                      </td>
                    </tr>
                    <tr className="row-reason">
                      <td className="cell-label">
                        <CalendarOutlined style={{ marginRight: '12px' }} />
                        Leave from
                      </td>
                      <td>:</td>
                      <td className="cell-value">
                        {moment(userLeave.start_date).format('DD MMM YYYY')} - {moment(userLeave.finish_date).format('DD MMM YYYY')}
                        <span style={{ marginLeft: '12px', fontSize: '12px', fontStyle: 'italic', fontWeight: 400 }}>
                          ({helper._leave.nums(userLeave)} days)
                        </span>
                      </td>
                    </tr>
                    <tr className="row-reason">
                      <td className="cell-label">
                        <CoffeeOutlined style={{ marginRight: '12px' }} />
                        Type
                      </td>
                      <td>:</td>
                      <td className="cell-value">
                        {userLeave.leave_type.name}
                        <span style={{ marginLeft: '12px', fontSize: '12px', fontStyle: 'italic', fontWeight: 400 }}>
                          {userLeave.day_type === 'half' ? <PieChartOutlined /> : <ChromeOutlined />}
                          <span style={{ marginLeft: '6px' }}>
                            {userLeave.day_type_display}
                          </span>
                          {userLeave.day_type === 'half' && (
                            <span style={{ marginLeft: '6px' }}>
                              ({helper._leave.renderHoursDay(userLeave)})
                            </span>
                          )}
                        </span>
                      </td>
                    </tr>
                    <tr className="row-reason">
                      <td className="cell-label">
                        <CommentOutlined style={{ marginRight: '12px' }} />
                        {/* <MessageOutlined style={{ marginRight: '12px' }} /> */}
                        Reason
                      </td>
                      <td>:</td>
                      <td className="cell-value">{userLeave.note}</td>
                    </tr>
                    {userLeave.confirmed_by && (
                      <tr className="row-reason">
                        <td className="cell-label">
                          <AuditOutlined style={{ marginRight: '12px' }} />
                          {userLeave.status === 'approved' ? 'Approved' : 'Rejected'} by
                        </td>
                        <td>:</td>
                        <td className="cell-value">
                          <span>
                            {lib.getFullName(userLeave.confirmed_by)}
                          </span>
                          <span style={{ marginLeft: '12px', fontSize: '13px', fontWeight: 400 }}>
                            <ClockCircleOutlined style={{ marginRight: '6px' }} />
                            {moment(userLeave.confirmed_date).format('DD MMMM YYYY hh:mm A')}
                          </span>
                        </td>
                      </tr>
                    )}
                    {/* {userLeave.confirmed_by && ( */}
                      <tr className="row-reason">
                        <td className="cell-label">
                          <MessageOutlined style={{ marginRight: '12px' }} />
                          Confirm Note
                        </td>
                        <td>:</td>
                        <td
                          className={classNames('cell-value', {
                            'c-error': userLeave.status === 'rejected',
                            'approved-note': userLeave.status === 'approved',
                          })}
                        >
                          {(!this.props.readOnly && helper._leave.allowConfirm(userLeave)) ? (
                            <Input.TextArea
                              ref={this.onRefNote}
                              autoFocus
                              showCount
                              spellCheck={false}
                              maxLength={200}
                              placeholder="Confirm Note"
                              autoSize={{ minRows: 2, maxRows: 2 }}
                            />
                          ) : (
                            userLeave.confirmed_note || '-'
                          )}
                        </td>
                      </tr>
                    {/* )} */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Row justify="space-between" style={{ padding: '0 8px' }}>
            <Col>
              {helper._leave.isExpSubmission(userLeave) && (
                <span style={{ color: '#ff4d4f', fontStyle: 'italic' }}>
                  {/* <ApiOutlined style={{ marginRight: '6px' }} /> */}
                  <WarningOutlined style={{ marginRight: '6px' }} />
                  This date has passed
                </span>
              )}
            </Col>
            <Col>
              <span style={{ color: 'rgba(0, 0, 0, 0.4)', fontSize: '12px', fontStyle: 'italic', fontWeight: 400 }}>
                Submitted on {moment(userLeave.created).format('DD MMM YYYY hh:mm A')}
              </span>
            </Col>
          </Row>
        </div>

        {!this.props.readOnly && (
          <div className="ant-modal-footer">
            <div>
              {/* <Divider style={{ margin: '12px 0' }} /> */}
              <div style={{ color: '#9a9a9a', fontSize: '16px', textAlign: 'center' }}>
                <Row justify='space-between' gutter={[12, 12]}>
                  <Col>
                    {typeof this.props.goBack === 'function' && (
                      <Col>
                        <Button icon={<ArrowLeftOutlined />} onClick={this.props.goBack}>
                          Go back
                        </Button>
                      </Col>
                    )}
                  </Col>
                  <Col>
                    <Row gutter={[12, 12]}>
                      {this.props.canAddAnother && (
                        <Col>
                          <Button type="primary" icon={<PlusOutlined />} onClick={() => this.props.onAddAnother(userLeave)}>
                            Request again
                          </Button>
                        </Col>
                      )}
                      {helper._leave.allowCancel(userLeave) && (
                        <Col>
                          <Popconfirm
                            icon={<ScissorOutlined className="c-error"  />}
                            okType="danger"
                            okText="Yes"
                            title="Are you sure cancel the user leave application?"
                            onConfirm={this.cancel}
                          >
                            <Button danger type="primary" icon={<ScissorOutlined />}>
                              Cancel Request
                            </Button>
                          </Popconfirm>
                        </Col>
                      )}
                      {helper._leave.allowConfirm(userLeave) && (
                        <>
                          <Col>
                            <Button danger type="primary" icon={<CloseOutlined />} onClick={this.reject}>
                              Reject
                            </Button>
                          </Col>
                          {/* {!helper._leave.isExpSubmission(userLeave) && ( */}
                            <Col>
                              <Button type="primary" icon={<CheckOutlined />} onClick={this.approve}>
                                Approve
                              </Button>
                            </Col>
                          {/* )} */}
                        </>
                      )}
                      {helper.allowRevert(userLeave) && (
                        <Col>
                          <Popconfirm
                            icon={<ScissorOutlined className="c-error"  />}
                            okType="danger"
                            okText="Yes"
                            title="Are you sure revert the user leave application?"
                            onConfirm={this.revert}
                          >
                            <Button danger type="primary" icon={<ScissorOutlined />}>
                              Revert Confirm
                            </Button>
                          </Popconfirm>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

LeaveView.propTypes = {
  ...LeaveView.propTypes,
  userLeave: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  canAddAnother: PropTypes.bool,
  // user: PropTypes.object,
  goBack: PropTypes.func,
  onAddAnother: PropTypes.func,
  onChangedLeave: PropTypes.func,
  setLoading: PropTypes.func,
}

LeaveView.defaultProps = {
  ...LeaveView.defaultProps,
  readOnly: false,
  canAddAnother: false,
  // user: null,
  goBack: null,
  onAddAnother: () => {},
  onChangedLeave: () => {},
  setLoading: (isLoading, callback = () => {}) => callback(),
}

export default LeaveView