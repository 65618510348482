import React from 'react'
import { Button, Col, Empty, message, Modal, Row, Spin } from 'antd'
import { PlusOutlined, RollbackOutlined, SaveOutlined } from '@ant-design/icons'
import { Prompt } from 'react-router-dom'
import { get } from 'lodash'

import { BaseComponent } from 'src/components'
import TaskItem from './Item'
import { lib, request } from 'src/Utils'

const ALLOWED_KEYS = { alias: 1, name: 1 }

class Tasks extends BaseComponent {
  constructor(props) {
    super(props)

    this._tasks = {}

    this.state = {
      isLoading: true,
      tasks: this.props.project.tasks,
      isFieldsTouched: false,
    }
  }

  onRefTask = (ref, task) => {
    this._tasks[task.alias || task.identifier] = ref
  }

  onFieldTouched = () => {
    this.setTouched(true)
  }

  checkFieldTouched = () => {
    let isFieldsTouched = false
    for (const key in this._tasks) {
      if (this._tasks[key] && this._tasks[key].state.isTouched) {
        isFieldsTouched = true
        break
      }
    }
    this.setTouched(isFieldsTouched)
  }

  setTouched = (isFieldsTouched) => {
    this.setThisState({ isFieldsTouched })
  }

  createTask = () => {
    this.setThisState({
      tasks: this.state.tasks.concat({ name: '', identifier: lib.generateUuid() }),
      isFieldsTouched: true,
    })
  }

  revertToSaved = () => {
    const newTasks = [...this.state.tasks]
    const deletedTasks = []

    for (const key in this._tasks) {
      if (this._tasks[key]) {
        if (!this._tasks[key].props.task.alias) {
          deletedTasks.push(
            newTasks.findIndex(
              task => task.identifier === this._tasks[key].props.task.identifier
            )
          )
        } else {
          this._tasks[key].reset()
        }
      }
    }

    for (let i = deletedTasks.length - 1; i >= 0; i -= 1) {
      newTasks.splice(deletedTasks[i], 1)
    }

    this.setThisState({ isFieldsTouched: false, tasks: newTasks })
  }

  validateFields = () => {
    const records = []
    for (const key in this._tasks) {
      if (this._tasks[key] && this._tasks[key].state.isTouched) {
        records.push(this._tasks[key].validateFields())
      }
    }

    return records
  }

  onSave = () => {
    let records = this.validateFields()
    this.waitingCount = records.length
    if (!records.length) {
      message.info('No unsaved changes')
      return
    }

    for (const i in records) {
      this.onSaveTask(records[i])
    }
  }

  onSaveTask = (task) => {
    let opts = null
    const record = {}
    for (const key in task) {
      if (ALLOWED_KEYS[key]) {
        record[key] = task[key]
      }
    }

    if (task.alias) {
      opts = {
        method: 'patch',
        urlKey: 'change-permanentTasks',
        data: record,
        args: [task.alias],
      }
    }

    this.setThisState({ isLoading: true }, () => {
      request({
        method: 'post',
        urlKey: 'read-permanentTasks',
        data: {
          ...record,
          project: this.props.project.alias,
        },
        ...opts,
        extra: task,
        onSuccess: this.saveTaskSuccess,
        onFailed: this.saveTaskFailed,
      })
    })
  }

  saveTaskSuccess = (response, extra) => {
    this.waitingCount -= 1
    const currentIndex = this.state.tasks.findIndex(
      task => (
        task.alias === response.data.alias ||
        (get(extra, 'identifier') && task.identifier === extra.identifier)
      )
    )

    const newTasks = [...this.state.tasks]
    if (currentIndex !== -1) {
      newTasks.splice(currentIndex, 1, { ...newTasks[currentIndex], ...response.data })
    } else {
      // something wrong
    }

    this.setThisState({
      isLoading: this.waitingCount > 0 && this.state.isLoading,
      tasks: newTasks,
    })
  }

  saveTaskFailed = (error) => {
    this.waitingCount -= 1
    lib.responseMessage.error(error.response)
    this.setThisState({ isLoading: this.waitingCount > 0 && this.state.isLoading })
  }

  onDeleteTask = (task) => {
    if (task.alias) {
      Modal.confirm({
        title: 'Delete Permanent Task',
        content: (
          <div>
            Are you sure want to delete task "<b>{task.name}</b>" ? This action cannot be undone.
          </div>
        ),
        okButtonProps: {danger: true},
        okText: 'Yes',
        onOk: () => new Promise((resolve, reject) => {
          request({
            method: 'delete',
            urlKey: 'change-permanentTasks',
            args: [task.alias],
            onSuccess: () => this.deleteSuccess(task, {resolve}),
            onFailed: err => this.deleteFailed(err, {reject}),
          })
        }),
      })
    } else {
      this.deleteSuccess(task, { resolve: () => {} })
      return
    }
  }

  deleteSuccess = (task, { resolve }) => {
    const currentIndex = this.state.tasks.findIndex(t => t.alias === task.alias)
    const remainTasks = [...this.state.tasks]
    remainTasks.splice(currentIndex, 1)
    this.setThisState({ tasks: remainTasks }, resolve)
  }

  deleteFailed = (error, { reject }) => {
    lib.responseMessage.error(error.response)
    reject()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.project !== nextProps.project) {
      this.setThisState({ tasks: nextProps.project.tasks })
    }
  }

  componentDidMount() {
    this.setMounted()

    setTimeout(() => {
      this.setThisState({ isLoading: false })
    }, 750)
  }

  render() {
    return (
      <Spin spinning={this.state.isLoading}>
        <Prompt
          when={this.state.isFieldsTouched}
          message='You will lose any unsaved changes. Continue?'
        />

        <div>
          {!this.props.readOnly && (
            <Row justify='space-between' gutter={[6, 6]} style={{ padding: '0 12px' }}>
              <Col className='only-xs-block'>
                <Button block icon={<PlusOutlined />} onClick={this.createTask}>
                  Create Permanent Task
                </Button>
              </Col>
              <Col className='only-xs-block'>
                <Row gutter={[6, 6]}>
                  {this.state.isFieldsTouched && (
                    <Col style={{ flex: 1 }}>
                      <Button block icon={<RollbackOutlined />} onClick={this.revertToSaved}>
                        Revert
                      </Button>
                    </Col>
                  )}
                  <Col style={{ flex: 1 }}>
                    <Button block disabled={!this.state.isFieldsTouched} type='primary' icon={<SaveOutlined />} onClick={this.onSave}>
                      Save Tasks
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {this.state.tasks.length ? (
            this.state.tasks.map((task, index) => (
              <TaskItem
                onRef={this.onRefTask}
                readOnly={this.props.readOnly}
                key={task.identifier || task.alias}
                task={task}
                onSave={this.onSaveTask}
                onFieldTouched={this.onFieldTouched}
                onReset={this.checkFieldTouched}
                onDelete={this.onDeleteTask}
              />



              // <div key={task.name} className='task-item'>
              //   <div style={{ margin: '0 12px' }}>
              //     <Row style={{ borderBottom: '1px solid #e8e8e8' }}>
              //       <Col xs={24} sm={8} lg={6} xl={4} className='task-name text-truncate' title={task.name} style={{ minHeight: '36px' }}>
              //         {task.name}
              //       </Col>
              //       <Col xs={24} sm={16} lg={18} xl={20} style={{ background: '#e8e8e8', border: '1px solid #c3c3c3', borderTopRightRadius: '4px', borderBottomRightRadius: '4px', padding: '6px 12px', marginLeft: '-1px' }}>
              //         <Row align='middle' style={{ height: '100%' }}>
              //           {/* <div className='label-total-worked'>
              //             {moment.duration({ seconds: task.total_worked })._data.hours} Hours {moment.duration({ seconds: task.total_worked })._data.minutes} Minutes
              //           </div>
              //           <div className='bar-total-worked' style={{ backgroundColor: '#fff', border: '1px solid #fff', borderRadius: '4px', width: '100%' }}>
              //             <Bar width={`${task.total_worked / 69420 * 100}%`} />
              //           </div> */}
              //         </Row>
              //       </Col>
              //     </Row>
              //   </div>
              // </div>
            ))
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description='No permanent tasks found for this project.'
            />
          )}
        </div>
      </Spin>
    )
  }
}

export default Tasks