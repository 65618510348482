import PropTypes from 'prop-types';
import { Modal } from 'antd';

import BaseDialog from './';

class BaseModal extends BaseDialog {
  staticModalProps = null

  getModalProps = () => null

  render() {
    return (
      <Modal
        visible={this.state.visible}
        title={this.title}
        onCancel={this.hide}
        onOk={this.onOk}
        style={{ maxWidth: '90%' }}
        {...this.staticDialogProps}
        {...this.getDialogProps()}

        // Deprecated
        {...this.staticModalProps}
        {...this.getModalProps()}
      >
        {this.renderContent()}
      </Modal>
    )
  }
}

BaseModal.propTypes = {
  ...BaseModal.propTypes
}

BaseModal.defaultProps = {
  ...BaseModal.defaultProps,
}

export default BaseModal