import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Pagination, Popover, Row, Table, Tooltip } from 'antd'
import { get, lowerCase } from 'lodash'
import moment from 'moment'

import { BarAnim, BaseComponent } from 'src/components'
import { lib } from 'src/Utils'
import PopoverProfile from './PopoverProfile'

const MIN_TOTAL = 28800000

class TimelineSection extends BaseComponent {
  constructor(props) {
    super(props)

    this.resetMaxTotal()

    this.staticColumns = [
      {
        dataIndex: '_',
        title: 'Name',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => lowerCase(lib.getFullName(a)) > lowerCase(lib.getFullName(b)) ? 1 : -1,
        // fixed: 'left',
        width: '210px',
        onCell: () => ({ style: { maxWidth: '30vw' } }),
        render: (_, record) => (
          <Link
            to={
              `/${lib.getStrParam({
                start_date: moment(this.props.startTime).format('YYYY-MM-DD'),
                end_date: moment(this.props.endTime).format('YYYY-MM-DD'),
                id: record.id,
              })}`
            }
          >
            <Tooltip placement='left' mouseLeaveDelay={0} title={lib.activity.getStatusComp(record.onlineStatus)}>
              <span>
                {lib.activity.getStatusComp(record.onlineStatus, false)}
              </span>
            </Tooltip>
            <Popover
              overlayClassName='ant-popover-profile'
              placement='right'
              content={
                <PopoverProfile
                  activeActivity={record.activeActivity}
                  user={record}
                />
              }
            >
              {lib.getFullName(record)}
            </Popover>
          </Link>
        ),
      },
      {
        dataIndex: '_2',
        title: 'Time Worked',
        sorter: (a, b) => a.total - b.total,
        sortDirections: ['descend', 'ascend'],
        // fixed: 'left',
        width: '175px',
        align: 'right',
        render: (_, record) => record.total ? lib.time.getLabel(record.total, true) : '-',
      },
    ]

    this.resetColumns()

    this.state = {
      userNotRegister: [],
      userActivities: [],
    }
  }

  resetMaxTotal = () => {
    this.maxTotal = MIN_TOTAL
  }

  resetColumns = () => {
    this.columns = [...this.staticColumns]
  }

  // Add total activity per user and per daily
  summarize = (userActivities, opts) => {
    if (!opts)
      opts = { startTime: this.props.startTime, endTime: this.props.endTime}

    // Update daily columns, but not re-render (set state), so in the last statement in this function, need to set state
    this.updateColumns(opts)

    this.resetMaxTotal()

    for (const i in userActivities) {
      userActivities[i].onlineStatus = lib.activity.getIsActive(userActivities[i].activities)
      userActivities[i].activeActivity = lib.activity.getTaskActive(userActivities[i].activities)

      userActivities[i].total = 0

      // Initialization object daily key for each User Activity
      if (!userActivities[i].daily)
        userActivities[i].daily = {}

      for (const j in userActivities[i].activities) {
        const date = moment(userActivities[i].activities[j].start_time).format('YYYY-MM-DD')
        if (!userActivities[i].daily[date])
          userActivities[i].daily[date] = 0

        // difference start_time to finish_time per activity in milliseconds
        const diff = moment(userActivities[i].activities[j].finish_time).diff(userActivities[i].activities[j].start_time)

        // Sum total worked (ms) per daily
        userActivities[i].daily[date] += diff

        // Check and update max width (total) of daily cell
        if (this.maxTotal < userActivities[i].daily[date])
          this.maxTotal = userActivities[i].daily[date]

        // Sum total worked (ms) all days (by filter) per user
        userActivities[i].total += diff
      }
    }

    this.setThisState({ userActivities })
  }

  // combine = (users, activities, opts) => {
  //   if (!opts)
  //     opts = { startTime: this.props.startTime, endTime: this.props.endTime }
    
  //   this.updateColumns(opts)

  //   const userActivities = []

  //   this.maxTotal = MIN_TOTAL
  //   const reuseActivities = [...activities]
  //   for (const i in users) {
  //     // set default user activity
  //     userActivities.push({ ...users[i], activities: [], daily: {}, total: 0 })

  //     for (let j = reuseActivities.length - 1; j >= 0; j -= 1) {
  //       if (users[i].alias === reuseActivities[j].user.alias) {
  //         const idx = userActivities.findIndex(user => user.alias === users[i].alias)
  //         userActivities[idx].activities.push(reuseActivities[j])
  //         userActivities[idx].total += moment(reuseActivities[j].finish_time).diff(moment(reuseActivities[j].start_time))

  //         if (!userActivities[idx].daily[moment(reuseActivities[j].start_time).format('YYYY-MM-DD')])
  //           userActivities[idx].daily[moment(reuseActivities[j].start_time).format('YYYY-MM-DD')] = 0

  //         userActivities[idx].daily[moment(reuseActivities[j].start_time).format('YYYY-MM-DD')] += moment(reuseActivities[j].finish_time).diff(moment(reuseActivities[j].start_time))

  //         // Check Max Total (ms)
  //         if (this.maxTotal < userActivities[idx].daily[moment(reuseActivities[j].start_time).format('YYYY-MM-DD')])
  //           this.maxTotal = userActivities[idx].daily[moment(reuseActivities[j].start_time).format('YYYY-MM-DD')]

  //         // Menghapus activity yang sudah dimasukkan ke data user
  //         reuseActivities.splice(j, 1)
  //       }
  //     }
  //   }

  //   this.setThisState({ userActivities })
  // }

  updateColumns = ({ startTime, endTime }) => {
    startTime = moment(startTime).format('YYYY-MM-DD')
    endTime = moment(endTime).format('YYYY-MM-DD')

    if (startTime > endTime) return

    this.resetColumns()
    for (let date = startTime; date <= endTime; date = moment(date).add(1, 'day').format('YYYY-MM-DD')) {
      this.columns.push({
        dataIndex: '_3',
        title: moment(date).format('dddd, DD/MM/YYYY'),
        align: 'center',
        onCell: (record) => ({ className: record.daily[date] ? 'cell-timeline' : 'cell-timeline-zero' }),
        render: (_, record) => (
          <Tooltip mouseLeaveDelay={0} title={record.daily[date] && lib.time.getLabel(record.daily[date], true)}>
            <div>
              <BarAnim width={this.getBarWidth(record.daily[date])} />
            </div>
          </Tooltip>
        )
      })
    }
  }

  getBarWidth = (totaltime = 0) => {
    let pct = totaltime / this.maxTotal

    if (pct > 1) pct = 1
    else if (pct && pct < 0.01) pct = 0.01

    return `${pct * 100}%`
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.activities !== nextProps.activities) {
      this.summarize(
        nextProps.activities,
        { startTime: nextProps.startTime, endTime: nextProps.endTime }
      )
      // this.combine(
      //   nextProps.users,
      //   nextProps.activities,
      //   { startTime: nextProps.startTime, endTime: nextProps.endTime }
      // )
    }
  }

  didMount() {
    if (this.props.activities.length) {
      this.summarize(
        this.props.activities,
        { startTime: this.props.startTime, endTime: this.props.endTime }
      )
    }
  }

  render() {
    return (
      <div>
        <Table
          bordered
          size='small'
          rowKey='alias'
          columns={this.columns}
          dataSource={this.state.userActivities}
          scroll={{ x: '930px' }}
          pagination={false}
        />
        {/* <Row justify='space-between' style={{ margin: '16px 0', padding: '0 12px' }}>
          <Col />
          <Col>
            <Pagination
              current={this.props.page}
              pageSize={this.props.limit}
              total={get(this.props.usersData, 'count', 0)}
              onChange={this.props.onPageChange}
            />
          </Col>
        </Row> */}
      </div>
    )
  }
}

TimelineSection.defaultProps = {
  activities: [],
}

export default TimelineSection