// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // recommendation format:
  // for company -> COMPANY_[MODULE]_DESCRIPTION
  // for permisson -> [VIEW/CHANGE/DELETE]_DESCRIPTION

  VIEW_PRODUCTION_REPORT: 'reward.view_production_detail_report',
  VIEW_MANAGER_PRODUCTION: 'reward.view_pw_manager_production',
  VIEW_TEAM_PRODUCTION: 'reward.view_pw_team_production',
  VIEW_OWN_PRODUCTION: 'reward.view_pw_own_production',
  VIEW_MONTHLY_PRODUCTION_REWARD: 'reward.view_monthly_production_reward',
  COMPANY_SHOW_POINT: 'reward.production_job_show_point',
  COMPANY_GPS_ATTEND: 'attendance.company_attend_gps_required',
  USER_GPS_ATTEND: 'attendance.user_attend_gps_required',

  // leave & overtime
  APPROVAL_LEAVE: 'leave.view_approval_leave',
  APPROVAL_OVERTIME: 'overtime.view_approval_overtime',
}