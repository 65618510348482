// const ENV = process.env;

// eslint-disable-next-line import/no-anonymous-default-export
const appConfig = {
  app: {
    // version: ENV.REACT_APP_APP_VERSION
  },
  url: {
    api: process.env.REACT_APP_URL_API,
    desktop: process.env.REACT_APP_URL_DESKTOP_INSTALLER,
  },
  oauth: {
    app: {
      client_id : 'xa1noLi1wfbTUOwIqjFE5lpADb94cPFdp8bvu0YJ',
      client_secret : 'UlUnwZny4xbz99nk2atuWSIVymJs1U591TrAQq7ZRcruIOT2glb13EZlpeVmodjStxEnCrsdla3XbZST1aeMr4kEji2i8EqLrMCT22YRUxM6oVVW1rlkynYklrRR1t3C',
    },
    dashboard: {
      client_id: 'sqd2UGD42VyXB2FdwKOslaMnYXDmyUMLgihxwTO0',
      client_secret: 'DJVWswuxUwymXminyH4WvuEE3Av1Epir7u2BAXjlx4STu9CXtNlHwV89Z4y50giq9MmcMch8a3KLccNQpsTLNLhNMSIYW9ZUy3sEt1CG2YIDXDjimq9ZiOu5n5LPXNcc',
    },
    master: {
      client_id: 'pRCfyn7HGT8KQxKdzYQehWVd9F94twTZqSbjRdbI',
      client_secret: 'yaGBevYz4god4d8jvPcY0t3edFHxI9PhN7El173WB1T62Rv5aXRlg2RQGy0G7stdM1LD8UxeLSdq0z3TG4GXVg8U5JRRDYotT69tVDm8XfYYJVjjALreRC1XEMtH4hnT',
    },
    // id: 'nk7AjTSE8W7kX8MZEmm5qQroEBTwL6rOQpLc2JIa',
    // secret: 'gb2MbP0ztOPlkphRcIBIn6kq0AlUHuNlvcISqWuP1yShiuvZX2hXDNehHDwNUt0diz6urw4re75lgTqrmfcQhmNbRgeWBEQ96AB254jrpugqZGu8oK2ZJ1D3Sg4j3WNp'
  },
}

// default
appConfig.oauth.client_id = appConfig.oauth.dashboard.client_id
appConfig.oauth.client_secret = appConfig.oauth.dashboard.client_secret

export default appConfig