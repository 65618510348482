import React from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highcharts'
import { cloneDeep } from 'lodash'
import moment from 'moment'

import { BaseComponent } from 'src/components'
import { CardHeader } from 'src/pages/DashboardApp/Components'
import tmpOpts from './pointOptsUser'

class Point extends BaseComponent {
  constructor(props) {
    super(props)

    this.title = 'Point'
    this.options = cloneDeep(tmpOpts)
  }

  render() {
    const isThisMonth = moment(this.props.date).format('YYYY-MM') === moment().format('YYYY-MM')

    this.options.xAxis.categories[0] = isThisMonth ? 'Last Month' : moment(this.props.date).subtract(1, 'month').format('YYYY MMMM')
    this.options.xAxis.categories[1] = isThisMonth ? 'This Month' : moment(this.props.date).format('YYYY MMMM')

    this.options.series[0].data[0] = this.props.prevPoint
    this.options.series[0].data[1] = this.props.point

    return (
      <div>
        <CardHeader title={this.title} />
        <HighchartsReact
          highcharts={Highcharts}
          options={{ ...this.options }}
        />
      </div>
    )
  }
}

Point.defaultProps = {
  // date,
  prevPoint: 0,
  point: 0,
}

export default Point