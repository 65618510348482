import modules from './staticModules'
import permissions from './staticPermissions'
import { lib } from 'src/Utils'

// View Point.
// on Dashboard, can view card "Point" and "Point by Grade"
// on Manager page, can view column "Point" on table QS/QA Production Detail
function canViewPointReward(currentUser) {
  const user = currentUser || lib.ls.getCurrentUser()

  const modulePointReward = user.company.modules.find(mod => mod.codename === modules.POINT_REWARD)
  const showPoint = modulePointReward && modulePointReward.permissions.some(permission => permission.code === permissions.COMPANY_SHOW_POINT)

  return showPoint
}

export default canViewPointReward