import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'antd'

class CardHeader extends React.PureComponent {
  getExtraLink = () => (
    <h3>
      <Link to={this.props.link + this.props.params}>
        {this.props.labelLink} {this.props.iconLink}
      </Link>
    </h3>
  )

  render() {
    return (
      <Row justify='space-between' gutter={[4, 4]} className={this.props.className}>
        <Col>
          <h3>{this.props.title}</h3>
        </Col>
        <Col>
          {this.props.extra ||
            (this.props.link && this.getExtraLink())}
        </Col>
      </Row>
    )
  }
}

CardHeader.defaultProps = {
  params: '',
}

export default CardHeader