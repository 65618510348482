import React from 'react'
import { Tag } from 'antd'
import { AuditOutlined, RobotOutlined } from '@ant-design/icons'

function getLevel(user) {
  if (user) {
    if (user.is_owner) return <Tag color='success' icon={<AuditOutlined />}>Owner</Tag>
    if (user.is_admin) return <Tag color='processing' icon={<RobotOutlined />}>Admin</Tag>
  }

  return null
}

export default getLevel