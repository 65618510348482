import { message, Modal } from 'antd';
import { get } from 'lodash';

import BaseComponent from 'src/components/BaseComponent';

class BaseDialog extends BaseComponent {
  constructor(props) {
    super(props)

    this.title = ''
    this.staticModalProps = null
    this.staticDialogProps = null

    // Jangan membuat state "target"
    // this.state = {
    //   ...this.defaultState,
    //   visible: props.visible,
    // }
    this.initState({
      visible: props.visible,
    })
  }

  show = (state, callback) => {
    if (get(state, 'stopPropagation') || get(state, 'target')) state = null

    this.setThisState({ ...state, visible: true }, () => {
      this.onVisibleChanged(true)
      if (typeof callback === 'function') callback()
    })
  }

  isStateLoading = () => this.state.isLoading
  getIsTouched = () => this._form && this._form.isFieldsTouched()

  getPreventMessage = async () => {
    if (this.isStateLoading())
      return 'Cannot close window while a process is in progress'

    if (this.getIsTouched())
      return await this.confirmDiscardChanges()
  }

  confirmDiscardChanges = async () => {
    return new Promise(resolve => {
      Modal.confirm({
        centered: true,
        destroyOnClose: true,
        title: 'You have unsaved changes, discard the changes?',
        okType: 'danger',
        okText: 'Yes, discard',
        okButtonProps: { type: 'primary' },
        onOk: () => resolve(),
        onCancel: () => resolve(true),
      })
    })
  }

  hide = async (state, callback, force) => {
    const preventMessage = !force && await this.getPreventMessage()
    if (preventMessage) {
      if (typeof preventMessage === 'string') message.info(preventMessage)
      return
    }

    // if (this.state.isLoading && !force) {
    //   message.info('Cannot close window while a process is in progress')
    //   return
    // }

    if (get(state, 'stopPropagation') || get(state, 'target')) state = null

    this.setThisState({ ...this.defaultState, ...state, visible: false }, () => {
      this.onVisibleChanged(false)
      if (typeof callback === 'function') callback()
    })
  }

  // -----------------------
  getDialogProps = () => null
  onOk = () => this.hide()
  onVisibleChanged = (visible) => !visible && this._form && this._form.resetFields()
  renderTitle = () => this.title
  renderContent = () => this.props.children
  // -----------------------
}

BaseDialog.propTypes = {
  ...BaseDialog.propTypes,
}

BaseDialog.defaultProps = {
  ...BaseDialog.defaultProps,
}

export default BaseDialog