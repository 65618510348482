import React from 'react'
import { Affix, Avatar, Button, Card, Col, Collapse, Dropdown, Empty, Menu, Row, Spin } from 'antd'
import { CloseCircleOutlined, DownOutlined, PlusOutlined, ReloadOutlined, RetweetOutlined, UserOutlined } from '@ant-design/icons'
import { get } from 'lodash'
import classNames from 'classnames'

import { BaseList } from 'src/components'
import { lib, request } from 'src/Utils'
import Photo from './Photo'
import Fullscreen from './Fullscreen'
import RegisterPhoto from './RegisterPhoto'
import LoadMoreListener from './LoadMoreListener'

const { Panel } = Collapse

class AttendancePhotos extends BaseList {
  constructor(props) {
    super(props)

    this.myProfile = lib.ls.getCurrentUser()
    this.selectedEmail = this.myProfile.email

    this.registerStates = [
      { key:'registered', label: 'Registered' },
      ...(!this.myProfile.is_owner ? [] : [
        { key: 'unregister', label: 'Unregister' },
        { key: 'failed', label: 'Failed' },
      ]),
    ]

    this.urlKey = 'read-attendancePhotos'
    this.limit = 12
    this.keysQueryParam.push(...['user'])

    this.users = []

    this.state = {
      ...this.state,
      objSelectedPhotos: {},
    }
  }

  onRefFullscreen = (ref) => {
    this._fullscreen = ref
  }

  onRefRegisterPhoto = (ref) => {
    this._registerPhoto = ref
  }

  beforeRead = async (keyLoading, callback) => {
    return new Promise((resolve) => {
      if (keyLoading === 'isLoadMore')
        return resolve()

      let objSelectedPhotos = this.state.objSelectedPhotos
      if (keyLoading === 'isLoading') {
        this.page = 1
        objSelectedPhotos = {}
        // window.stop() error when run this code
      }

      this.setThisState({ [keyLoading]: true, objSelectedPhotos }, async () => {
        const resMembers = await lib.loadAllMembers()
        this.users = resMembers.members

        if (resMembers.error) {
          resolve(true)
          this.setThisState({ errorResponse: resMembers.error.response, [keyLoading]: false }, callback)
        } else {
          if (!this.users.find(user => user.email === this.selectedEmail))
            if (this.users.length)
              this.selectedEmail = this.users[0].email

          this.user = get(this.users.find(user => user.email === this.selectedEmail), 'id')
          resolve()
        }
      })
    })
  }

  getUserTitle = (user) => {
    return (
      <Row align='middle'>
        <Col>
          <Avatar
            icon={<UserOutlined />}
            src={user.photo}
            size={28}
            style={{ marginRight: '8px' }}
          />
        </Col>
        <Col style={{ fontSize: '18px', fontWeight: 500  }}>
          {lib.getFullName(user)}
        </Col>
      </Row>
    )
  }

  onSelectPhoto = (photo) => {
    this.setThisState({
      objSelectedPhotos: {
        ...this.state.objSelectedPhotos,
        [photo.alias]: !this.state.objSelectedPhotos[photo.alias],
      }
    })
  }

  deselectPhotos = () => {
    this.setThisState({ objSelectedPhotos: {} })
  }

  onZoomPhoto = (photo) => {
    this._fullscreen.show(this.__list.findIndex(val => val.alias === photo.alias))
  }

  resend = () => {
    this.setThisState({ isLoading: true }, () => {
      const aliases = Object.keys(this.state.objSelectedPhotos).filter(key => this.state.objSelectedPhotos[key])
      request({
        method: 'post',
        urlKey: 'bulk-create-attendancePhotos',
        data: { aliases },
        onSuccess: this.resendSuccess,
        onFailed: this.resendFailed,
      })
    })
  }

  resendSuccess = () => {
    this.setThisState({ isLoading: false }, this.forceRead)
  }

  resendFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.setThisState({ isLoading: false })
  }

  render() {
    const { objSelectedPhotos } = this.state
    const selectedUser = !this.users.length ? this.myProfile : this.users.find(user => user.email === this.selectedEmail)

    return (
      <div style={{ padding: '24px' }}>
        <Row justify='space-between' gutter={[12, 12]} style={{ marginBottom: '12px' }}>
          <Col>
            <Row align='middle'>
              <Col>
                <h1 style={{ marginBottom: 0 }}>
                  Facial Photo
                </h1>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row align='middle' gutter={[6, 6]}>
              <Col>
                <Button loading={this.state.isLoading} icon={<ReloadOutlined />} onClick={this.forceRead}>
                  Refresh
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Users */}
        {this.users.length > 1 && (
          <Spin spinning={this.state.isLoading}>
            <div style={{ paddingBottom: '12px', backgroundColor: '#f0f2f5' }}>
              <Row gutter={[12, 12]} style={{ flexFlow: 'nowrap', overflowX: 'auto' }}>
                {this.users.map((user, i) => (
                  <Col key={user.alias}>
                    <Card
                      className={classNames(
                        'app-card app-card-selection',
                        {active: `${this.selectedEmail}` === `${user.email}`}
                      )}
                      bodyStyle={{ padding: '12px' }}
                      style={{ cursor: 'pointer', width: '250px' }}
                      onClick={() => {
                        if (this.selectedEmail === user.email)
                          return

                        this.selectedEmail = user.email
                        this.read()
                      }}
                    >
                      <Row justify='center'>
                        <Avatar
                          src={user.photo}
                          icon={<UserOutlined />}
                          size={72}
                          style={{
                            color: '#fff',
                            // border: '1px solid #e8e8e8',
                            boxShadow: '0 6px 16px -8px RGB(0 0 0/8%)',
                          }}
                        />
                      </Row>
                      <div
                        className='webkit-box two-lines'
                        style={{ height: '44px', textAlign: 'center', marginTop: '12px' }}
                      >
                        {lib.getFullName(user)}
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Spin>
        )}

        {/* Content */}
        <Spin spinning={this.state.isLoading}>
          <Card className='app-card' bodyStyle={{ padding: 0 }}>
            {this.state.errorResponse ? this.getErrorComp() : (
              <Collapse activeKey='key'>
                <Panel key='key' header={this.getUserTitle(selectedUser)} className='panel-attendance-photos'>
                  <Affix>
                    <Row justify='space-between' gutter={[12, 12]} style={{ backgroundColor: 'white', padding: '12px 0' }}>
                      <Col>
                        <Row gutter={[6, 6]}>
                          <Col>
                            {Object.keys(objSelectedPhotos).filter(key => objSelectedPhotos[key]).length > 0 && (
                              <Button.Group>
                                <Button type='primary' icon={<RetweetOutlined />} onClick={this.resend}>
                                  Resend
                                </Button>
                                <Button danger type='primary' icon={<CloseCircleOutlined />} onClick={this.deselectPhotos}>
                                  Cancel
                                </Button>
                              </Button.Group>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row gutter={[6, 6]}>
                          <Col>
                            <Button icon={<PlusOutlined />} onClick={() => this._registerPhoto.show({ user: selectedUser.id, username: lib.getFullName(selectedUser) })}>
                              Add Photo
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Affix>
                  {this.__list.length > 0 ? (
                    <Row gutter={[12, 12]}>
                      {this.__list.map(photo => (
                        <Col key={photo.alias} xs={24} sm={12} lg={8} xl={6} xxl={4}>
                          <Photo
                            photo={photo}
                            onSelect={this.onSelectPhoto}
                            onZoom={this.onZoomPhoto}
                          />
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={(
                        <div>
                          No Photos
                          {selectedUser
                            ? <span> for <b>{lib.getFullName(selectedUser)}</b></span>
                            : ''
                          }
                        </div>
                      )}
                    />
                  )}

                  {this.next && (
                    <Row justify='center' style={{ marginTop: '12px' }}>
                      <Button loading={this.state.isLoadMore} onClick={this.loadMore}>
                        Load More
                      </Button>

                      {/* Listener for scrolling window */}
                      <LoadMoreListener
                        loadMore={this.loadMore}
                      />
                    </Row>
                  )}
                </Panel>
              </Collapse>
            )}
          </Card>

          {/* Lighbox Image Fullscreen */}
          <Fullscreen
            ref={this.onRefFullscreen}
            photos={this.__list}
          />

          {/* Add New Photo for JARVIS Learning */}
          <RegisterPhoto
            ref={this.onRefRegisterPhoto}
            reload={this.forceRead}
          />
        </Spin>
      </div>
    )
  }
}

export default AttendancePhotos