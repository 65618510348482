import React from 'react'
import { Button, Col, Form, Input, message, Modal, Row } from 'antd'

import { BaseComponent, BaseModal } from 'src/components'
import { lib, request } from 'src/Utils'

class DialogChangePassword extends BaseModal {
  constructor(props) {
    super(props)

    this.formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }

    // this.state = {
    //   visible: false,
    // }
  }

  getModalProps = () => ({
    destroyOnClose: true,
    title: 'Change Password',
    // visible={this.state.visible}
    closable: false,
    // onCancel={this.hide}
    footer: this.getFooter(),
  })

  // show = () => {
  //   this.setThisState({ visible: true })
  // }

  // hide = () => {
  //   if (!this.state.isSaving) {
  //     this.setThisState({ visible: false })
  //   }
  // }

  getFooter = () => {
    return (
      <Row justify='end' gutter={[12, 12]}>
        <Col>
          <Button onClick={this.hide}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button loading={this.state.isSaving} type='primary' onClick={this.onSave}>
            Change Password
          </Button>
        </Col>
      </Row>
    )
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.onSave()
    }
  }

  onSave = () => {
    this._form.validateFields()
      .then(values => {
        if (this.state.isSaving) return

        this.setThisState({ isSaving: true }, () => {
          const data = new FormData()
          data.set('old_password', values.old_password)
          data.set('password', values.new_password)

          request({
            method: 'patch',
            urlKey: 'read-myUser',
            data,
            onSuccess: this.saveSuccess,
            onFailed: this.saveFailed,
          })
        })
      }).catch(() => {})
  }

  saveSuccess = () => {
    message.success('New Password has been saved')
    this.setThisState({ isSaving: false }, this.hide)
  }

  saveFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.setThisState({ isSaving: false })
  }

  renderContent = () => {
    return (
      // <Modal
      //   destroyOnClose
      //   title='Change Password'
      //   visible={this.state.visible}
      //   closable={false}
      //   onCancel={this.hide}
      //   footer={this.getFooter()}
      // >
        <Form ref={ref => {this._form = ref}} {...this.formItemLayout}>
          <Form.Item
            name='old_password'
            label='Old Password'
            rules={[
              { required: true, message: 'Please input Old Password!' },
            ]}
          >
            <Input.Password
              autoFocus
              placeholder='Old Password'
              // onKeyDown={this.onKeyDown}
              onPressEnter={this.onSave}
            />
          </Form.Item>
          <Form.Item
            name='new_password'
            label='New Password'
            rules={[
              { required: true, message: 'Please input New Password!' },
            ]}
          >
            <Input.Password placeholder='New Password' onPressEnter={this.onSave} />
          </Form.Item>
          <Form.Item
            name='retype_new_password'
            label='Retype New Password'
            rules={[
              { required: true, message: 'Please input Retype New Password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
              })
            ]}
          >
            <Input.Password placeholder='Retype New Password' onPressEnter={this.onSave} />
          </Form.Item>
        </Form>
      // </Modal>
    )
  }
}

export default DialogChangePassword