import { Col, Row, Tooltip } from 'antd';
import { PieChartOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import moment from 'moment';

import _h from './';
// import helper from './internal/leave';
import { lib } from 'src/Utils'

function allowCancel(userLeave, user) {
  if (!user) user = lib.ls.getCurrentUser()

  return (
    get(userLeave, 'user_id') === user.id &&
    _h.isWaiting(userLeave) &&
    moment(userLeave.start_date).format(_h._s.F_DATE) >= moment().format(_h._s.F_DATE)
  )
}

function allowConfirm(userLeave, user) {
  if (!user) user = lib.ls.getCurrentUser()

  return (
    // Owner or admin can review
    (user.is_owner || user.permissions.includes('leave.view_approval_leave')) &&
    // Own user cannot review
    // Karena di api/dashboard/overtime/overtimes/ tidak ada user_id, maka sementara menggunakan Full Name
    // https://github.com/lewatmana/timetracking-web/issues/442
    (user.is_owner || (get(userLeave, 'user_id') !== user.id && lib.getFullName(userLeave.user) !== lib.getFullName(user))) &&
    // Only can confirm when pending submission
    _h.isWaiting(userLeave)
  )
}

function isExpSubmission(userLeave) {
  return (
    _h.isWaiting(userLeave) &&
    moment(userLeave.start_date).format(_h._s.F_DATE) < moment().format(_h._s.F_DATE)
  )
}

function allowSubmission(date, userLeaves) {
  return (
    // weekday
    !_h.isWeekend(date) &&
    // more than current date
    // moment().format(_h._s.F_DATE) < moment(date).format(_h._s.F_DATE) &&
    // no user leave on the current date
    !getLeaves(date, userLeaves).find(userLeave => !_h.isAborted(userLeave))
  )
}

function getLatestLeave(date, userLeaves) {
  return null
}

function getLeaveBalance(userBalance, estimateDate) {
  if (!userBalance) return null

  const { calculated_date, active_balance } = userBalance
  const diffMonth = moment(estimateDate).startOf('month').diff(moment(calculated_date).startOf('month'), 'month')

  if (diffMonth < 0) return null
  return Number(active_balance + (diffMonth * 1.25)).toFixed(2)
}

function getLeaves(date, userLeaves, dateFormat = _h._s.F_DATE) {
  return userLeaves.filter(userLeave =>
    moment(userLeave.start_date).format(dateFormat) <= moment(date).format(dateFormat)
    && moment(userLeave.finish_date).format(dateFormat) >= moment(date).format(dateFormat))
}

// get number of leaves
function nums(userLeave, month) {
  let totalLeave = 0
  const startDate = moment(userLeave.start_date)
  const endDate = moment(userLeave.finish_date).format(_h._s.F_DATE)

  do {
    if (!_h.isWeekend(startDate) && (!month ? true : startDate.format('YYYY-MM') === moment(month).format('YYYY-MM')))
      totalLeave += 1

    startDate.add(1, 'day')
  } while(startDate.format(_h._s.F_DATE) <= endDate)

  return totalLeave
  // return userLeave.day_type === 'half'
  //   ? totalLeave / (userLeave.day_count)
  //   : totalLeave
}

// function renderStatus(userLeave, opts) {
//   opts = { prefix: '', ...opts }

//   return (
//     <Tag
//       style={{ marginRight: 0 }}
//       color={_h.isAborted(userLeave)
//         ? 'error'
//         : _h.isApproved(userLeave)
//           ? 'success' : 'warning'}
//     >
//       {opts.prefix}
//       {userLeave.status.toUpperCase()}
//     </Tag>
//   )
// }

// function renderStatuses(userLeaves) {
//   const waitingLeaves = userLeaves.filter(userLeave => _h.isWaiting(userLeave))
//   const approvedLeaves = userLeaves.filter(userLeave => _h.isApproved(userLeave))
//   const cancelledLeaves = userLeaves.filter(userLeave => _h.isCancelled(userLeave))
//   const rejectedLeaves = userLeaves.filter(userLeave => _h.isRejected(userLeave))

//   return (
//     <span style={{ marginLeft: '12px' }}>
//       <Space>
//         {[waitingLeaves, approvedLeaves, cancelledLeaves, rejectedLeaves].filter(item => item.length).map(items => (
//           <span key={items[0].pk}>
//             {renderStatus(items[0], { prefix: `${items.length} ` })}
//           </span>
//         ))}
//       </Space>
//     </span>
//   )
// }

// function renderDuration(userLeave) {
//   const strDuration = userLeave.duration
//   const [strHours, strMinutes] = strDuration.split(':')
//   const totalMinutes = Number(strHours) * 60 + Number(strMinutes)
//   return _h._overtime.renderDuration(totalMinutes * 60 * 1000)
// }

function renderHoursDay(userLeave) {
  const totalDays = nums(userLeave)
  // const totalDays = Math.abs(userLeave.day_count)
  let strDays = '',
    strDuration = userLeave.duration
  if (userLeave.duration.includes(' '))
    [strDays, strDuration] = userLeave.duration.split(' ')
  const [strHours, strMinutes] = strDuration.split(':')
  const minutes = (Math.abs(Number(strDays)) || 0) * 24 * 60 +
    (Number(strHours) || 0) * 60 +
    (Number(strMinutes) || 0)

  // console.log('renderHoursDay', { strDays, strDuration, strHours, strMinutes, minutes, totalDays })
  return _h._overtime.renderDuration((minutes / totalDays) * 60 * 1000)
}

function renderSelect(userLeave) {
  return (
    <Row justify="space-between">
      <Col>
        <span>#{userLeave.pk}</span>
        <span style={{ marginLeft: '4px', fontWeight: 500 }}>
          {userLeave.leave_type.name}
        </span>
        <span style={{ marginLeft: '6px', fontSize: '11px' }}>
          ({moment(userLeave.start_date).format('DD MMM YYYY')} - {moment(userLeave.finish_date).format('DD MMM YYYY')})
        </span>
      </Col>
      {userLeave.day_type === 'half' && (
        <Col>
          <Tooltip title={userLeave.day_type_display} mouseLeaveDelay={0}>
            <PieChartOutlined className="half-indicator" />
          </Tooltip>
        </Col>
      )}
    </Row>
  )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  allowCancel,
  allowConfirm,
  allowSubmission,
  // getLatestLeave,
  getLeaveBalance,
  getLeaves,
  isExpSubmission,
  nums,
  // renderStatus,
  // renderStatuses,
  renderHoursDay,
  renderSelect,
  // ---------------
  // _internal: helper,
}