import React from 'react'
import { WindowsFilled } from '@ant-design/icons'

import BaseButton from './Base'

// Button to download app for windows
class ButtonWindows extends BaseButton {
  constructor(props) {
    super(props)

    this.ymlName = 'latest.yml'
  }

  getButtonProps = () => ({
    icon: <WindowsFilled />
  })

  renderText() {
    return (
      `Download for Windows ${this.__version}`
    )
  }
}

export default ButtonWindows