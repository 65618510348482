import PropTypes from 'prop-types';
import { Button, Row, Spin, Tooltip } from 'antd';
import { FieldTimeOutlined, GiftOutlined, HeartOutlined, InfoCircleOutlined, RestOutlined, ShoppingOutlined } from '@ant-design/icons';
import moment from 'moment';
import classNames from 'classnames';

import { BaseComponent } from 'src/components';
import { lib, request } from 'src/Utils';
import _h from 'src/pages/DashboardApp/Submission/helper';

class ViewRowActualOvertime extends BaseComponent {
  constructor(props) {
    super(props)

    this.initState({
      actualOvertime: null,
      actualMeal: null,
    })
  }

  readActualOvertime = () => {
    this.setState({ isLoading: true }, () => {
      request({
        urlKey: 'read-activities',
        data: {
          id: this.props.userOvertime.user_id,
          range_before: moment(this.props.userOvertime.date).startOf('day').format(),
          range_after: moment(this.props.userOvertime.date).endOf('day').format(),
        },
        onSuccess: this.readActivitySuccess,
        onFailed: this.readActivityFailed,
      })
    })
  }

  getMealAllowance = (ms) => {
    const minHours = this.props.userOvertime.is_holiday ? 3 : 2

    return (_h.isApproved(this.props.userOvertime) &&
      !this.props.userOvertime.is_converted_balance &&
      moment.duration(ms).hours() >= minHours)
        ? 1
        : 0
  }

  readActivitySuccess = (res) => {
    console.log('readActivitySuccess', res)
    const obj = _h._overtime.getDiffActivities(this.props.userOvertime, res.data[0].activities)
    console.log('obj', obj)
    this.setState({
      error: null,
      isLoading: false,
      actualOvertime: obj.ms,
      actualMeal: this.getMealAllowance(obj.ms),
    })
  }

  readActivityFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.setState({ error, isLoading: false })
  }

  didMount() {
    if (this.props.actualOvertime)
      this.setState({ actualOvertime: this.props.actualOvertime, actualMeal: this.props.actualMeal })
    // else if (!this.props.userOvertime.is_converted_balance && _h.isApproved(this.props.userOvertime))
    else
      this.readActualOvertime()
  }

  render() {
    return (
      <>
        <tr>
          <td className="cell-label">
            <Row align="middle">
              <FieldTimeOutlined style={{ marginRight: '12px' }} />
              Actual (TW)
              <Tooltip title="If a weekday, taken starting at 17.00" mouseLeaveDelay={0}>
                <InfoCircleOutlined style={{ fontSize: '12px', marginLeft: '6px' }} />
              </Tooltip>
            </Row>
          </td>
          <td>:</td>
          <td className="cell-value">
            <Spin spinning={this.state.isLoading}>
              {this.state.error
                ? <Button size="small" type="primary" onClick={this.readActualOvertime}>Reload</Button>
                : !this.state.actualOvertime
                  ? '-'
                  : (
                    <Tooltip title={this.props.userOvertime.is_converted_balance && 'Converted to Leave Balance, not calculated in summary'}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/report/timeline/?id=${this.props.userOvertime.user_id}&start_date=${this.props.userOvertime.date}&end_date=${this.props.userOvertime.date}`}
                        className={classNames("link", { 'converted-desc': this.props.userOvertime.is_converted_balance })}
                      >
                        {_h._overtime.renderDuration(this.state.actualOvertime)}
                      </a>
                    </Tooltip>
                  )}
            </Spin>
          </td>
        </tr>
        <tr>
          <td className="cell-label">
            <GiftOutlined style={{ marginRight: '12px' }} />
            Meal Allowance
          </td>
          <td>:</td>
          <td className="cell-value">
            <Spin spinning={this.state.isLoading}>
              {this.state.error
                ? <Button size="small" type="primary" onClick={this.readActualOvertime}>Reload</Button>
                : this.state.actualMeal || '-'}
            </Spin>
          </td>
        </tr>
      </>
    )
  }
}

ViewRowActualOvertime.propTypes = {
  ...ViewRowActualOvertime.propTypes,
  userOvertime: PropTypes.object.isRequired,
  actualOvertime: PropTypes.number,
  actualMeal: PropTypes.number,
}

ViewRowActualOvertime.defaultProps = {
  ...ViewRowActualOvertime.defaultProps,
  actualOvertime: null,
  actualMeal: null,
}

export default ViewRowActualOvertime