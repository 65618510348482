import PropTypes from 'prop-types';
import { Col, Row, Tooltip } from 'antd';
import moment from 'moment';
import classnames from 'classnames';

import { BarAnim } from 'src/components'
import HeaderAttendance from 'src/pages/DashboardApp/Attendance/Daily/Header';
import BaseHeaderLeave from 'src/pages/DashboardApp/Submission/AnnualLeave/Header';
import _h from 'src/pages/DashboardApp/Submission/helper';

class SubmissionOvertimeHeader extends BaseHeaderLeave {
  renderTitle() {
    return 'Overtime Summary'
  }

  // renderCardUser() {
  //   return null
  // }

  renderCardDetail() {
    const todayCheckin = false,
      todayCheckout = false,
      checkinAttendances = [],
      checkoutAttendances = [],
      totalTime = '-'
    
    const monthOvertimes = _h._overtime.getOvertimes(this.props.date, this.props.userOvertimes, 'YYYY-MM')
    const waitingOvertimes = monthOvertimes.filter(userOvertime => _h.isWaiting(userOvertime))
    const approvedOvertimes = monthOvertimes.filter(userOvertime => _h.isApproved(userOvertime))

    const waitingMs = _h._overtime.getTotal(waitingOvertimes)
    const approvedMs = _h._overtime.getTotal(approvedOvertimes)

    return (
      <div>
        <Row justify='space-between' style={{ textAlign: 'center' }}>
          <Col
            className={classnames('daily-checkin-summary', {
              'has-photos': !!todayCheckin,
              'unvalidated': todayCheckin && todayCheckin.status !== 'validated'
            })}
            onClick={() => this.showPhotos(todayCheckin, checkinAttendances)}
          >
            <div style={{ padding: '12px' }}>
              <div style={{ fontSize: '28px', fontWeight: 500 }}>
                {/* {this.getAttendanceTime(todayCheckin)} */}
                {/* {totalWaiting || '-'} */}
                {/* 6 hours */}
                {/* - */}
                {waitingMs
                  ? _h._overtime.renderDuration(waitingMs)
                  : '-'}
              </div>
              <div className='subtitle' style={{ fontSize: '16px' }}>
                Waiting Approval
              </div>
            </div>
          </Col>
          <Col
            className={classnames('daily-checkin-summary', {
              'has-photos': !!todayCheckout,
              'unvalidated': todayCheckout && todayCheckout.status !== 'validated',
              // 'unvalidated-overtime': 1 > 0,
            })}
            onClick={() => this.showPhotos(todayCheckout, checkoutAttendances)}
          >
            <div style={{ padding: '12px' }}>
              <div style={{ fontSize: '28px', fontWeight: 500 }}>
                {/* {this.getAttendanceTime(todayCheckout)} */}
                {/* {totalConfirmed || '-'} */}
                {/* 14 hours */}
                {/* - */}
                {approvedMs
                  ? _h._overtime.renderDuration(approvedMs)
                  : '-'}
              </div>
              <div className='subtitle' style={{ fontSize: '16px' }}>
                Approved
              </div>
            </div>
          </Col>
          <Col
            className={classnames('daily-checkin-summary', {
              // 'validated-overtime': 1 > 0,
            })}
          >
            <div style={{ padding: '12px' }}>
              <div style={{ fontSize: '28px', fontWeight: 500 }}>
                {/* {totalRemain || '-'} */}
                {/* 2 days */}
                {/* - */}
                {approvedOvertimes.length
                  ? `${approvedOvertimes.length} Days`
                  : '-'}
              </div>
              <div className='subtitle' style={{ fontSize: '16px' }}>
                Approved Days
              </div>
            </div>
          </Col>
        </Row>
        {/* {this.renderTotalBar((totalWaiting + totalConfirmed) / totalLeaveAllowance)} */}
        {this.renderTotalBar(0)}
      </div>
    )
  }
}

SubmissionOvertimeHeader.propTypes = {
  ...SubmissionOvertimeHeader.propTypes,
  userOvertimes: PropTypes.array,
}

SubmissionOvertimeHeader.defaultProps = {
  ...SubmissionOvertimeHeader.defaultProps,
  userOvertimes: [],
}

export default SubmissionOvertimeHeader