/*
  How to use this file?
  Import in your file, and use keys of object
  Note: Allow to you to change the value of object
*/

const screenOpts = {
  isMobile: false,
}

export default screenOpts