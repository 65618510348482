import React from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Col, Divider, Form, Row, Spin } from 'antd'
import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { orderBy } from 'lodash'

import { BaseList } from 'src/components'

class Roles extends BaseList {
  constructor(props) {
    super(props)

    this.urlKey = 'read-roles'
  }

  getGroupedRoles = (roles) => {
    const groupedRoles = []

    for (let i = 0; i < roles.length; i += 1) {
      const roleModule = (!roles[i].is_global && roles[i].modules[0]) || { name: 'General', order: -1 }

      let foundIndex = groupedRoles.findIndex(val => val.name === roleModule.name)
      if (foundIndex === -1) {
        foundIndex = groupedRoles.push({ ...roleModule, roles: [] }) - 1
      }

      groupedRoles[foundIndex] = {
        ...groupedRoles[foundIndex],
        roles: groupedRoles[foundIndex].roles.concat(roles[i])
      }
    }

    for (const group of groupedRoles)
      group.roles = orderBy(group.roles, 'order')

    return orderBy(groupedRoles, 'order')
  }

  ButtonAdmin = () => {
    const isAdmin = this.props._basicForm && this.props._basicForm.getFieldValue('is_admin')
    return (
      <Button
        className={isAdmin ? 'btn-as-admin' : 'btn-not-admin'}
        onClick={() => {
          this.props._basicForm.setFieldsValue({ is_admin: !isAdmin })
          this.props.forceUpdate()
        }}
      > </Button>
    )
  }

  ButtonRole = (formProps) => {
    const { checked, form, role } = formProps

    return (
      <Card
        hoverable
        bodyStyle={{
          padding: '12px',
          ...(checked && { backgroundColor: '#1890ff', color: 'white' })
        }}
        onClick={() => {
          form.setFieldsValue({
            [role.alias]: !checked
          })
        }}
      >
        <Row align='middle' gutter={[12, 6]}>
          <Col>
            {checked
              ? <UnlockOutlined style={{ fontSize: '21px' }} />
              : <LockOutlined style={{ fontSize: '21px' }} />
            }
          </Col>
          <Col className='text-truncate' style={{ fontSize: '18px', fontWeight: 500, flex: 1 }}>
            {role.name}
          </Col>
        </Row>
      </Card>
    )
  }

  render() {
    const { isLoading, errorResponse } = this.state

    if (isLoading) {
      return (
        <Row justify='center' align='middle' style={{ height: '100%' }}>
          <Col>
            <Spin />
          </Col>
        </Row>
      )
    }

    return errorResponse ? this.getErrorComp() : (
      <Form ref={ref => {this._permissionForm = ref}} name='permissionForm'>
        <Row justify='space-between'>
          <div style={{ fontSize: '21px', fontWeight: 500 }}>
            Roles
          </div>
          {this.props.isOwner && (
            <Col>
              <Form.Item shouldUpdate noStyle>
                {(form) => (
                  <Form.Item name='temp_is_admin' initialValue={false} noStyle>
                    <this.ButtonAdmin />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
          )}
        </Row>
        <div style={{ marginTop: '12px' }}>
          {this.getGroupedRoles(this.__list).map(group => (
            <div key={group.name}>
              <Divider style={{ margin: '12px 0' }}>
                {group.aka_name || group.name}
              </Divider>
              <Row type='flex' gutter={[12, 12]} style={{ marginBottom: '24px' }}>
                {group.roles.map(role => (
                  <Col key={role.alias} xs={24} sm={24} xl={12}>
                    <Form.Item noStyle shouldUpdate>
                      {(form) => (
                        <Form.Item noStyle name={role.alias} valuePropName='checked' initialValue={this.props.user.roles.find(r => r.alias === role.alias)}>
                          <this.ButtonRole form={form} role={role} />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </div>
      </Form>
    )
  }
}

Roles.propTypes = {
  // _basicForm: PropTypes.object.isRequired,
  forceUpdate: PropTypes.func,
}

Roles.defaultProps = {
  forceUpdate: () => {},
}

export default Roles