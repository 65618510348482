import React from 'react'
import { Modal } from 'antd'

async function dialogDiscardChanges(opts, force) {
  if (force === true)
    return true

  return new Promise(resolve => {
    Modal.confirm({
      title: 'You have unsaved changes, the data will be lose. Continue?',
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
      ...opts,
    })
  })
}

export default dialogDiscardChanges