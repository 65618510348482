import { get } from 'lodash'

import modules from './staticModules'
import permissions from './staticPermissions'
import { lib } from 'src/Utils'

// user is required param
function useGpsAttend(user) {
  if (!user) return null

  const company = lib.ls.getCurrentUser().company
  const companyAttendance = company.modules.find(mod => mod.codename === modules.ATTENDANCE)
  const hasCompanyGps = companyAttendance && companyAttendance.permissions.some(perm => perm.code === permissions.COMPANY_GPS_ATTEND)
  if (hasCompanyGps)
    return true

  let hasUserGps = get(user, 'permissions', []).includes(permissions.USER_GPS_ATTEND)
  // if (user.is_owner) {
  //   // karena owner memiliki semua permissions, jadi cek required gps di roles
  //   hasUserGps = user.roles.find(role => role.name === 'Attend GPS Required')
  // }

  return hasUserGps
}

export default useGpsAttend