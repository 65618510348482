import React from 'react'
import { message, Modal } from 'antd'
import { get } from 'lodash'

import { BaseModal } from 'src/components'
import ContentUsers from './ContentUsers'

class DialogSelectUser extends BaseModal {
  constructor(props) {
    super(props)

    this.staticModalProps = {
      closable: false,
      destroyOnClose: true,
      width: 720,
      title: 'Select User',
      bodyStyle: { height: 'calc(100vh - 170px)', overflowY: 'auto' },
      style: { top: 20 },
      onOk: this.onConfirm,
    }

    this.state = {
      ...this.state,
      excludeUsers: [], // [userAlias1, ...]
    }
  }

  onRefContent = (ref) => {
    this._content = ref
  }

  onConfirm = () => {
    const values = this._content.getValues()

    if (!values.length) {
      message.info('Please select the user')
      return
    }
    // Send selected values
    this.props.onConfirm({
      fieldKey: this.state.fieldKey,
      users: this._content.getValues(),
    })

    // Close modal
    this.hide()
  }

  renderContent = () => {
    return (
      <ContentUsers
        ref={this.onRefContent}
        {...this.state.contentProps} // controlling from props ref
        users={this.props.users.filter(user => this.state.excludeUsers.findIndex(userAlias => userAlias === user.alias) === -1)}
      />
    )
  }
}

export default DialogSelectUser