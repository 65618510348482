import React from 'react'
import { Button, Card, Col, Form, Input, Modal, Row, Spin } from 'antd'
import { ArrowLeftOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import { get, orderBy } from 'lodash'

import { BaseList } from 'src/components'
import { lib, request } from 'src/Utils'
import FieldManager from './FieldManager'
import FieldUsers from './FieldUsers'
import SelectUser from './SelectUser'

// import CardUser from './CardUser'
// Bug : tidak ada handle load more users
class FormTeam extends BaseList {
  constructor(props) {
    super(props)

    this.urlKey = 'read-companyUsers'

    this.state = {
      ...this.state,
      isSaving: false,
    }
  }

  onRefSelectUser = (ref) => {
    this._selectUser = ref
  }

  getInitialValues = () => {
    if (this.props.team) {
      return {
        name: this.props.team.name,
        manager: get(this.props.team.manager, 'alias'),
        users: this.props.team.users.map(user => user.alias),
      }
    }

    return {
      name: '',
      manager: undefined,
      users: [],
    }
  }

  convertResponseData = (responseData) => {
    responseData.results = orderBy(responseData.results, 'first_name')

    return responseData
  }

  onShowSelectUser = ({ fieldKey, ...props }) => {
    if (!fieldKey) {
      // message.error('Cannot read field name, please contact your admin!')
      return
    }

    this._selectUser.show({ ...props, fieldKey })
  }

  // users = [userAlias1, ...]
  onConfirmUser = ({ fieldKey, users }) => {
    // let value = null

    const prevUsersAlias = this._form.getFieldValue('users')
    const prevManagerAlias = this._form.getFieldValue('manager')

    if (fieldKey === 'manager') {
      const selectedManager = users[0]
      const foundIndex = prevUsersAlias.findIndex(userAlias => userAlias === selectedManager)

      if (foundIndex !== -1) {
        Modal.confirm({
          title: 'The user is already in the members section, do you want to continue and remove from the member list?',
          okText: 'Yes',
          onOk: () => {
            this._form.setFieldsValue({
              users: prevUsersAlias.filter(userAlias => userAlias !== selectedManager),
              manager: selectedManager,
            })
          },
        })
      } else {
        this._form.setFieldsValue({ manager: selectedManager })
      }
    } else {
      const foundIndex = users.findIndex(userAlias => userAlias === prevManagerAlias)
      const mergedUsers = prevUsersAlias.concat(users)

      if (foundIndex !== -1) {
        Modal.confirm({
          title: 'The user is already in the manager section, do you want to continue and remove from selected manager?',
          okText: 'Yes',
          onOk: () => {
            this._form.setFieldsValue({ manager: undefined, users: mergedUsers })
          },
        })
      } else {
        this._form.setFieldsValue({ users: mergedUsers })
      }
    }
  }

  onCloseForm = async () => {
    const isFieldsTouched = this._form && this._form.isFieldsTouched()
    const willClose = await lib.dialogDiscardChanges(null, !isFieldsTouched)

    if (willClose)
      this.props.onClose()
  }

  onSave = () => {
    if (this.state.isSaving)
      return

    this._form.validateFields().then(values => {
      this.setThisState({ isSaving: true }, () => {
        request({
          method: this.props.team ? 'patch' : 'post',
          urlKey: this.props.team ? 'change-companyTeams' : 'read-companyTeams',
          args: [get(this.props.team, 'alias')],
          data: values,
          onSuccess: this.saveSuccess,
          onFailed: this.saveFailed,
        })
      })
    })
  }

  saveSuccess = (response) => {
    this.props.updateTeam(response.data)
  }

  saveFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.setThisState({ isSaving: false })
  }

  render() {
    return (
      <Spin spinning={this.state.isLoading || this.state.isSaving}>
        <Row justify='space-between' gutter={[12, 12]} style={{ marginBottom: '24px' }}>
          <Col>
            <Row align='middle'>
              <Col>
                <div className='ant-page-header-back'>
                  <div className='ant-page-header-back-button' onClick={this.onCloseForm}>
                    <ArrowLeftOutlined />
                  </div>
                </div>
              </Col>
              <Col style={{ fontSize: '21px', fontWeight: 500 }}>
                {this.props.team ? 'Edit Team' : 'Add Team'}
              </Col>
            </Row>
          </Col>
          <Col>
            <Row gutter={[12, 12]}>
              <Col>
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  loading={this.state.isSaving}
                  onClick={this.onSave}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {this.state.errorResponse ? this.getErrorComp() : (
          <div>
            <Form ref={ref => {this._form = ref}} layout='vertical' initialValues={this.getInitialValues()}>
              <Card>
                {/* NAME */}
                <Form.Item
                  name='name'
                  label='Name'
                  rules={[
                    { required: true, message: 'Name is required!' },
                  ]}
                >
                  <Input placeholder='Name' onPressEnter={this.onSave} />
                </Form.Item>
              </Card>
              <div style={{ marginTop: '12px' }}>
                <Row gutter={[12, 12]}>
                  {/* MANAGER */}
                  <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
                    <div style={{ marginBottom: '6px' }}>
                      Manager :
                    </div>
                    <Form.Item
                      name='manager'
                      rules={[
                        { required: true, message: 'Manager is required!' },
                      ]}
                    >
                      <FieldManager users={this.__list} showSelectUser={this.onShowSelectUser} />
                    </Form.Item>
                  </Col>

                  {/* MEMBERS */}
                  <Col style={{ flex: 1 }}>
                    <div style={{ marginBottom: '6px' }}>
                      Members :
                    </div>
                    <Form.Item
                      name='users'
                      rules={[
                        { required: true, message: 'Members is required' },
                      ]}
                    >
                      <FieldUsers users={this.__list} showSelectUser={this.onShowSelectUser} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Form>
            <SelectUser
              ref={this.onRefSelectUser}
              users={this.__list}
              onConfirm={this.onConfirmUser}
            />
          </div>
        )}
      </Spin>
    )
  }
}

export default FormTeam