import React from 'react'
import { Button, Col, Collapse, DatePicker, Row, Table } from 'antd'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import { get } from 'lodash'
import moment from 'moment'
import classNames from 'classnames'

import { BaseReport } from '../components'
import { lib } from 'src/Utils'
import Bar from './Bar'

const { Panel } = Collapse

class TimelineReport extends BaseReport {
  constructor(props) {
    super(props)

    this.resetTime()

    this.timelines = []
    this.totalWorked = 0

    this.keysQueryParam.push('related')
    this.related = 'task,note'

    this.title = 'Timeline Daily Report'
    this.columns = [
      {
        dataIndex: 'start_time',
        title: 'Time Started',
        width: '100px',
        sorter: (a, b) => a.start_time > b.start_time ? 1 : -1,
        onCell: (record) => ({ title: moment(record.start_time).format('DD-MM-YYYY hh:mm:ss A') }),
        render: val => moment(val).format('hh:mm A'),
      },
      {
        dataIndex: 'finish_time',
        title: 'Time End',
        width: '100px',
        sorter: (a, b) => a.finish_time > b.finish_time ? 1 : -1,
        onCell: (record) => ({ title: moment(record.finish_time).format('DD-MM-YYYY hh:mm:ss A') }),
        render: val => moment(val).format('hh:mm A'),
      },
      {
        dataIndex: '_',
        title: 'Total Time',
        width: '200px',
        sorter: (a, b) => moment(a.finish_time).diff(moment(a.start_time)) > moment(b.finish_time).diff(moment(b.start_time)) ? 1 : -1,
        render: (_, record) => lib.time.getLabel(moment(record.finish_time).diff(moment(record.start_time)), true),
      },
      {
        dataIndex: ['task', 'name'],
        title: 'Task',
        width: '360px',
        sorter: (a, b) => a.task.name > b.task.name ? 1 : -1,
      },
      {
        dataIndex: ['note', 'note'],
        title: 'Note',
        width: '200px',
        sorter: (a, b) => get(a.note, 'note') > get(b.note, 'note') ? 1 : -1,
      },
    ]
  }

  resetTime = () => {
    this.totalWorked = 0
    this.timeStarted = null
    this.timeFinished = null
  }

  checkRange = (startDate, endDate) => moment(startDate).format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD')

  getFilterInput = () => (
    <Col className='only-xs-block'>
      <Row>
        <Col>
          <Button icon={<CaretLeftOutlined />} onClick={this.prevDays} />
        </Col>
        <Col style={{ margin: '0 -1px', flex: 1 }}>
          <DatePicker
            allowClear={false}
            format='YYYY-MM-DD'
            value={moment(this.range_before)}
            style={{ width: '100%' }}
            onChange={(selectedDate) => {
              this.onChangeFilter({
                start_date: selectedDate.format('YYYY-MM-DD'),
                end_date: selectedDate.format('YYYY-MM-DD'),
              })
            }}
          />
        </Col>
        <Col>
          <Button icon={<CaretRightOutlined />} onClick={this.nextDays} />
        </Col>
      </Row>
    </Col>
  )

  nextDays = () => {
    const date = moment(this.range_before).add(1, 'days').format('YYYY-MM-DD')
    this.onChangeFilter({ start_date: date, end_date: date })
  }

  prevDays = () => {
    const date = moment(this.range_before).subtract(1, 'days').format('YYYY-MM-DD')
    this.onChangeFilter({ start_date: date, end_date: date })
  }

  convertResponseData = (userActivities) => {
    // for (const i in userActivities) {
      // const { activities } = userActivities[i]

      const timelines = []

      // this.resetTime()
      this.timelines = []
      this.totalWorked = 0
      this.timeStarted = null
      this.timeFinished = null
      // userActivities[i].totalWorked = 0
      // userActivities[i].timeStarted = null
      // userActivities[i].timeFinished = null

      let newPart = null

      for (let j = 0; j < userActivities.length; j += 1) {
        if (j === 0) {
          this.timeStarted = userActivities[j].start_time
        }
        if (j === userActivities.length - 1) {
          this.timeFinished = userActivities[j].finish_time
        }

        newPart = true
        for (let k = 0; k < timelines.length; k += 1) {
          const diff = Math.abs(moment(userActivities[j].start_time).diff(moment(timelines[k].finish_time)))
          if (
            userActivities[j].is_manual_time === timelines[k].is_manual_time &&
            diff <= this.msTolerance &&
            timelines[k].task.alias === userActivities[j].task.alias &&
            get(timelines[k].note, 'note') === get(userActivities[j].note, 'note')
          ) {
            timelines[k].finish_time = userActivities[j].finish_time
            newPart = false
            break
          }
        }

        if (newPart) {
          timelines.push(userActivities[j])
        }

        this.timelines = timelines

        this.totalWorked += moment(userActivities[j].finish_time).diff(moment(userActivities[j].start_time))
      // }
    }
    return userActivities
  }

  // default filter is Today
  changeToDefaultFilter = () => {
    this.onChangeFilter({
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
    })
  }

  getBodyContent = () => {
    const selectedUsers = this.getSelectedUsers()
    return (
    <div>
      {selectedUsers.length > 0 && (
        <Collapse activeKey={selectedUsers.map(user => user.alias)}>
          {selectedUsers.map(user => (
            <Panel key={user.alias} header={this.getUserTitle(user)} className='panel-report'>
              <Row justify='space-between' gutter={[12, 12]} style={{ textAlign: 'center' }}>
                <Col>
                  <div style={{ padding: '12px' }}>
                    <div style={{ fontSize: '28px', fontWeight: 500 }}>
                      {lib.time.getLabel(this.totalWorked)}
                    </div>
                    <div className='subtitle' style={{ fontSize: '16px' }}>
                      Time Worked
                    </div>
                  </div>
                </Col>
                {!this.mutableScreen.isMobile && (
                  <>
                    {this.timeStarted && this.timeFinished && (
                      <Col>
                        <div style={{ padding: '12px' }}>
                          <div style={{ fontSize: '28px', fontWeight: 500 }}>
                            {moment(this.timeStarted).format('hh:mm A')} - {moment(this.timeFinished).format('hh:mm A')}
                          </div>
                          <div className='subtitle' style={{ fontSize: '16px' }}>
                            Working Hour Range
                          </div>
                        </div>
                      </Col>
                    )}
                    <Col>
                      <div style={{ padding: '12px' }}>
                        <div style={{ fontSize: '28px', fontWeight: 500 }}>
                          {moment(this.range_before).format('DD MMM YYYY')}
                        </div>
                        <div className='subtitle' style={{ fontSize: '16px' }}>
                          Selected Date
                        </div>
                      </div>
                    </Col>
                  </>
                )}
              </Row>
              {!this.mutableScreen.isMobile && (
                <div style={{ marginBottom: '12px', padding: '12px' }}>
                  <Bar timelines={this.timelines} />
                </div>
              )}
              <div>
                <Table
                  columns={this.columns}
                  dataSource={this.timelines}
                  pagination={false}
                  rowKey='alias'
                  scroll={this.scrollConfig}
                  // scroll={{ x: '640px' }}
                  onRow={(record) => ({
                    className: classNames({
                      'manual-time': !!record.is_manual_time,
                    }),
                  })}
                />
              </div>
            </Panel>
          ))}
        </Collapse>
      )}
    </div>
  )
  }
}

export default TimelineReport