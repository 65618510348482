import NumberFormat from "react-number-format"

function insertGradeColumns(columns, productionJobs) {
  columns[2].children = []
  columns[3].children = []

  const grades = []
  for (let i = 0; i < productionJobs.length; i += 1) {
    if (grades.findIndex(grade => grade === productionJobs[i].grade) === -1) {
      grades.push(productionJobs[i].grade)
    }
  }

  for (let i = 0; i < grades.length; i += 1) {
    // Number of jobs by Grade
    columns[2].children.push({
      title: grades[i],
      dataIndex: ['objGrade', grades[i]],
      align: 'center',
      width: '60px',
      // render: jobs => (jobs || []).length || '-',
      render: jobs => {
        const total = (jobs || []).length
        return !total ? '-' : (
          <NumberFormat
            thousandSeparator
            displayType='text'
            value={total}
          />
        )
      },
    })

    // Area m² by Grade
    columns[3].children.push({
      title: grades[i],
      dataIndex: ['objGrade', grades[i]],
      align: 'center',
      width: '60px',
      render: jobs => {
        const total = (jobs || []).reduce((acc, record) => acc + Number(record.area), 0)
        return !total ? '-' : (
          <NumberFormat
            thousandSeparator
            displayType='text'
            value={total}
          />
        )
      },
    })
  }

  columns[2].children.sort((a, b) => a.title > b.title ? 1 : -1)
  columns[3].children.sort((a, b) => a.title > b.title ? 1 : -1)

  const childLength = columns[2].children.length
  if (childLength) {
    columns[2].children[childLength - 1].onCell = () => ({ style: { borderRightColor: '#c3c3c3' } })
    columns[2].children[childLength - 1].onHeaderCell = () => ({ style: { borderRightColor: '#c3c3c3' } })
  }
}

export default insertGradeColumns