import React from 'react'
import { QuestionCircleOutlined, QuestionOutlined } from '@ant-design/icons'
import { Col, Row, Spin, Table, Tooltip } from 'antd'
import { get } from 'lodash'
import NumberFormat from 'react-number-format'
import classNames from 'classnames'

import { BaseComponent, PointBonus } from 'src/components'
import { lib, permissions } from 'src/Utils'
import typeCodes from 'src/Utils/static/typeCodes'
import staticProd from 'src/Utils/static/productionJobs'

class QAProductionDetail extends BaseComponent {
  constructor(props) {
    super(props)

    // ----- support for override -----
    this.KEY_TITLE_PRODUCTION = 'Productions'
    this.KEY_INDEX_AMOUNT = 'qs_value'
    // --------------------------------

    this.myProfile = lib.ls.getCurrentUser()
    const canViewPointReward = permissions.canViewPointReward(this.myProfile)

    // Jika ingin di beri scroll Y atau fixed columns, beri width di setiap kolom
    this.scrollOpts = { x: 912, y: 'calc(100vh - 135px)' }
    this.stickyOpt = { offsetHeader: 90 }
    this.columns = [
      {
        title: 'QA List',
        dataIndex: 'fullName',
        fixed: 'left',
        width: '140px',
        onCell: () => ({ style: { borderRightColor: '#c3c3c3' } }),
        onHeaderCell: () => ({ style: { borderRightColor: '#c3c3c3' } }),
        // render: user => lib.getFullName(user),
        render: fullName => fullName,
      },
      {
        title: this.KEY_TITLE_PRODUCTION,
        onHeaderCell: () => ({ style: { borderRightColor: '#c3c3c3' } }),
        children: [
          {
            title: 'Amount',
            // dataIndex: ['prod_summary', 'qs_value'],
            dataIndex: this.KEY_INDEX_AMOUNT,
            align: 'right',
            width: '90px',
            sortDirections: ['descend', 'ascend'],
            // sorter: (a, b) => a.qs_value - b.qs_value,
            // render: val => <NumberFormat thousandSeparator displayType='text' value={Number(val).toFixed(2)} />,
            render: () => 0,
            getSummary: () => 0,
          },
          {
            title: 'Jobs',
            // dataIndex: ['prod_summary', 'total_jobs'],
            dataIndex: 'total_jobs',
            align: 'right',
            width: '70px',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.total_jobs - b.total_jobs,
            // render: () => 0,
            getSummary: (currentData) => currentData.reduce((acc, val) => acc + val.total_jobs, 0),
          },
          {
            title: 'Area',
            // dataIndex: ['prod_summary', 'total_area'],
            dataIndex: 'total_area',
            align: 'right',
            width: '80px',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.total_area - b.total_area,
            onCell: () => !canViewPointReward && ({ style: { borderRightColor: '#c3c3c3' } }),
            onHeaderCell: () => !canViewPointReward && ({ style: { borderRightColor: '#c3c3c3' } }),
            render: val => <NumberFormat thousandSeparator displayType='text' value={Number(val)} />,
            // render: () => 0,
            getSummary: (currentData) => currentData.reduce((acc, val) => acc + val.total_area, 0),
          },
          ...(!canViewPointReward ? [] : [
            {
              title: 'Point',
              dataIndex: 'qa1_point',
              align: 'right',
              width: '80px',
              sortDirections: ['descend', 'ascend'],
              sorter: (a, b) => a.qa1_point - b.qa1_point,
              onCell: () => ({ style: { borderRightColor: '#c3c3c3' } }),
              onHeaderCell: () => ({ style: { borderRightColor: '#c3c3c3' } }),
              // render: val => Math.round(Number(val) * 100) / 100,
              render: (val, record) => !this.myProfile.is_owner ? Number(val).toFixed(2) : (
                <PointBonus
                  prodMonth={this.props.dateString}
                  point={Math.round(Number(val) * 100) / 100}
                  user={record.user}
                  typeExchangeFactor={record.qaExchangeFactor}
                  effectiveExchange={this.props.effectiveExchange}
                  {...this.getPointBonusProps()}
                />
              )
            },
          ])
        ]
      },
      {
        title: 'Number of jobs by Grade',
        onHeaderCell: () => ({ style: { borderRightColor: '#c3c3c3' } }),
        children: []
      },
      {
        title: 'Area m² by Grade',
        children: []
      }
    ]

    this.state = {
      data: this.getData(props.productionJobs),
      downloading: false,
      isLoading: false,
    }
  }

  getPointBonusProps = () => null
  getProductionJobsColumns = (productionJobs = this.props.productionJobs) => productionJobs
  getProductionJobsData = (productionJobs = this.props.productionJobs) => productionJobs

  getData = (productionJobs) => {
    // Set grade columns
    this.insertGradeColumns(this.getProductionJobsColumns(productionJobs))

    // Filter user QA only
    productionJobs = productionJobs.filter(job => job.user_qa)

    // GROUPING JOBS BY USER FULLNAME
    const groupedJobs = {}
    productionJobs = this.getProductionJobsData(productionJobs)
    for (let i = 0; i < productionJobs.length; i += 1) {
      const fullName = lib.getFullName(productionJobs[i].user_qa)
      if (!groupedJobs[fullName])
        groupedJobs[fullName] = { jobs: [], objGrade: {} }

      groupedJobs[fullName].jobs.push(productionJobs[i])
      groupedJobs[fullName].user_qa = productionJobs[i].user_qa

      if (!groupedJobs[fullName].objGrade[productionJobs[i].grade])
        groupedJobs[fullName].objGrade[productionJobs[i].grade] = []

      groupedJobs[fullName].objGrade[productionJobs[i].grade].push(productionJobs[i])
    }

    // SUM VALUE OF GROUPED JOBS
    const data = []
    for (const fullName in groupedJobs) {
      // initial record
      const record = { fullName, user_qa: groupedJobs[fullName].user_qa, objGrade: groupedJobs[fullName].objGrade, qs_value: 0, total_area: 0, total_jobs: 0, qa1_point: 0 }

      record.user = this.props.userTypes.find(user => user.id === groupedJobs[fullName].user_qa.id)
      if (!record.user) continue

      record.qaExchangeFactor = get(record.user.point_types.find(type => typeCodes.QA_CODES.includes(type.code)), 'exchange_factor', staticProd.DEFAULT_EXCHANGE_FACTOR)

      for (let i = 0; i < groupedJobs[fullName].jobs.length; i += 1) {
        const { qs_value, area, qa1_point } = groupedJobs[fullName].jobs[i]

        record.qs_value += Number(qs_value)
        record.total_area += Number(area)
        record.total_jobs += 1
        record.qa1_point += Number(qa1_point)
      }

      // Set trainer actual point, masalahnya jika trainer tidak mengerjakan sebagai QA, maka tidak muncul.
      // Apakah perlu tetap di munculkan di QA Production? dan nanti perlu handle jika lebih dari bulan ini, maka point harus 0
      const trainerPointType = record.user.point_types.find(type => typeCodes.TRAINER_CODES.includes(type.code))
      if (trainerPointType) {
        record.qa1_point = trainerPointType.denominator
        record.qaExchangeFactor = trainerPointType.exchange_factor
      }

      data.push(record)
    }

    data.sort((a, b) => a.fullName > b.fullName ? 1 : -1)

    return data
  }

  // access from props
  setData = (productionJobs) => {
    this.columns[2].children = []
    this.columns[3].children = []

    this.setThisState({ data: this.getData(productionJobs) })
  }

  insertGradeColumns = (productionJobs) => {
    const grades = []
    for (let i = 0; i < productionJobs.length; i += 1) {
      if (grades.findIndex(grade => grade === productionJobs[i].grade) === -1) {
        grades.push(productionJobs[i].grade)
      }
    }

    for (let i = 0; i < grades.length; i += 1) {
      // Number of jobs by Grade
      this.columns[2].children.push({
        title: grades[i],
        dataIndex: ['objGrade', grades[i]],
        align: 'center',
        width: '60px',
        render: grades => (grades || []).length || '-',
        // render: grades => (grades || []).reduce((acc, record) => acc + Number(record.prod_day), 0) || '-',
      })

      // Area m² by Grade
      this.columns[3].children.push({
        title: grades[i],
        dataIndex: ['objGrade', grades[i]],
        align: 'center',
        width: '60px',
        render: grades => {
          const area = (grades || []).reduce((acc, record) => acc + Number(record.area), 0)
          return !area ? '-' : <NumberFormat thousandSeparator displayType='text' value={Number(area)} />
        }
      })
    }

    this.columns[2].children.sort((a, b) => a.title > b.title ? 1 : -1)
    this.columns[3].children.sort((a, b) => a.title > b.title ? 1 : -1)

    const childLength = this.columns[2].children.length
    if (childLength) {
      this.columns[2].children[childLength - 1].onCell = () => ({ style: { borderRightColor: '#c3c3c3' } })
      this.columns[2].children[childLength - 1].onHeaderCell = () => ({ style: { borderRightColor: '#c3c3c3' } })
    }
  }

  // check and insert grade columns
  // insertGradeColumn = (gradeLabel) => {
  //   if (this.columns[2].children.findIndex(col => col.title === gradeLabel) !== -1)
  //     return

  //   // Number of jobs by Grade
  //   this.columns[2].children.push({
  //     title: gradeLabel,
  //     dataIndex: ['objGrade', gradeLabel],
  //     align: 'center',
  //     width: '60px',
  //     render: grades => (grades || []).length || '-',
  //     // render: grades => (grades || []).reduce((acc, record) => acc + Number(record.prod_day), 0) || '-',
  //   })

  //   // Area m² by Grade
  //   this.columns[3].children.push({
  //     title: gradeLabel,
  //     dataIndex: ['objGrade', gradeLabel],
  //     align: 'center',
  //     width: '60px',
  //     render: grades => (grades || []).reduce((acc, record) => acc + Number(record.area), 0) || '-',
  //   })
  // }

  summaryTable = (currentData) => {
    return (
      <Table.Summary.Row className='summary-production'>
        <Table.Summary.Cell index={0} className='cell-prod-border-right'>
          Total
        </Table.Summary.Cell>
        {/* <Table.Summary.Cell index={1} align='right'> */}
          {/* <NumberFormat
            thousandSeparator
            displayType='text'
            value={currentData.reduce((acc, val) => acc + val.qs_value, 0).toFixed(2)}
          /> */}
          {/* 0
        </Table.Summary.Cell> */}
        {/* <Table.Summary.Cell index={2} align='right'>
          <NumberFormat
            thousandSeparator
            displayType='text'
            value={currentData.reduce((acc, val) => acc + val.total_jobs, 0)}
          />
        </Table.Summary.Cell>
        <Table.Summary.Cell index={3} align='right'>
          <NumberFormat
            thousandSeparator
            displayType='text'
            value={currentData.reduce((acc, val) => acc + val.total_area, 0)}
          />
        </Table.Summary.Cell>
        <Table.Summary.Cell index={4} align='right' className='cell-prod-border-right'>
          <NumberFormat
            thousandSeparator
            displayType='text'
            value={currentData.reduce((acc, val) => acc + val.qa1_point, 0).toFixed(2)}
          />
        </Table.Summary.Cell> */}
        {this.columns[1].children.map((productionCol, i) => (
          <Table.Summary.Cell index={i + 1} align='right' className={classNames({ 'cell-prod-border-right': i === this.columns[1].children.length - 1 })}>
            <NumberFormat
              thousandSeparator
              displayType='text'
              value={productionCol.getSummary
                ? productionCol.getSummary(currentData)
                : currentData.reduce((acc, val) => acc + get(val, productionCol.dataIndex), 0).toFixed(2)}
            />
          </Table.Summary.Cell>
        ))}
        {this.columns[2].children.map((gradeCol, i) => (
          <Table.Summary.Cell key={gradeCol.title} index={i + 4} align='center' className={classNames({ 'cell-prod-border-right': i === this.columns[2].children.length - 1 })}>
            <NumberFormat
              thousandSeparator
              displayType='text'
              value={currentData.reduce((acc, val) => acc + get(val, gradeCol.dataIndex, []).length, 0)}
            />
          </Table.Summary.Cell>
        ))}
        {this.columns[3].children.map((gradeCol, i) => (
          <Table.Summary.Cell title={gradeCol.title} key={gradeCol.title} index={i + 4 + this.columns[2].children.length} align='center'>
            <NumberFormat
              thousandSeparator
              displayType='text'
              value={currentData.reduce((acc, val) => acc + get(val, gradeCol.dataIndex, []).reduce((acc2, job) => acc2 + Number(job.area), 0), 0)}
            />
          </Table.Summary.Cell>
        ))}
      </Table.Summary.Row>
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.productionJobs !== nextProps.productionJobs) {
      this.setData(nextProps.productionJobs)
    }
  }

  render() {
    return (
      <div>
        <Row justify='space-between' className='sticky-qs-production'>
          <Col>
            <h2 style={{ marginBottom: 0 }}>
              QA Production
              <Tooltip placement="right" title="Only calculating points for QA users, ignore the Total Jobs and Area">
                <QuestionCircleOutlined
                  style={{
                    cursor: 'pointer',
                    color: '#555555',
                    fontSize: '16px',
                    marginLeft: '8px',
                  }}
                />
              </Tooltip>
            </h2>
          </Col>
          <Col>
            <Row gutter={[6, 6]}>
              {/* <Col>
                <Button block icon={<ReloadOutlined />} loading={this.state.isLoading} onClick={() => this.read()}>
                  Refresh
                </Button>
              </Col> */}
              {/* <Col>
                <Dropdown
                  disabled={this.props.loading}
                  placement='bottomRight'
                  trigger={['click']}
                  overlay={this.getExportMenu()}
                >
                  <Button loading={this.state.downloading} icon={<DownOutlined />} style={{ minWidth: '100px' }}>
                    Export ({moment(this.props.dateString).format('MMMM YYYY')})
                  </Button>
                </Dropdown>
              </Col> */}
            </Row>
          </Col>
        </Row>
        <Spin spinning={this.props.loading}>
          <div className='app-card'>
            <Table
              bordered
              sticky={this.stickyOpt}
              size='small'
              rowKey={record => record.fullName}
              className='ant-table-qsProduction'
              columns={this.columns}
              dataSource={this.state.data}
              pagination={false}
              scroll={this.scrollOpts}
              onRow={(_, index) => index % 2 === 1 && ({ className: 'even' })}
              summary={this.summaryTable}
            />
          </div>
        </Spin>
      </div>
    )
  }
}

export default QAProductionDetail