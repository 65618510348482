import modules from './staticModules'
import permissions from './staticPermissions'
import hasPointRewardModule from './hasPointRewardModule'
import { lib } from 'src/Utils'

// internal function
function hasOwnProd(user) {
  const hasUserOwnProd = user.permissions.findIndex(perm => perm === permissions.VIEW_OWN_PRODUCTION) !== -1

  return hasUserOwnProd
}
// -------------------------

// prodUser can be Null
function canViewProdDashboard(currentUser, prodUser) {
  const user = currentUser || lib.ls.getCurrentUser()

  // This is required, no module no display
  // const hasCompanyPointReward = user.company.modules.findIndex(mod => mod.codename === modules.POINT_REWARD) !== -1
  const hasCompanyPointReward = hasPointRewardModule(user)

  const isMySelf = user.email === (prodUser && prodUser.email)

  const hasTeamPerm = user.permissions.findIndex(perm => perm === permissions.VIEW_TEAM_PRODUCTION || perm === permissions.VIEW_MANAGER_PRODUCTION) !== -1

  return hasCompanyPointReward && (
    (isMySelf && hasOwnProd(user)) || user.is_owner || hasTeamPerm
  )
}

export default canViewProdDashboard