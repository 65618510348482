import React from 'react'
import PropTypes from 'prop-types';
import { Row, Select, Spin } from 'antd'
import { pick } from 'lodash'

import BaseList from './BaseList'

class SelectQuery extends BaseList {
  constructor(props) {
    super(props)

    this.urlKey = props.urlKey

    this.search = ''

    this.keysQueryParam.push(...['search'])
    this.allowedInputProps = ['allowClear', 'autoFocus', 'defaultValue', 'disabled', 'fieldNames', 'placeholder', 'showSearch', 'size', 'value', 'onChange']

    for (const key in this.props.initialValues)
      this[key] = this.props.initialValues[key]

    if (this.props.params) {
      for (const key in this.props.params) {
        this[key] = this.props.params[key]
        this.keysQueryParam.push(key)
      }
    }

    this.state = {
      ...this.state,
      [this.keyIsLoading]: false,
    }
  }

  dropdownRender = (opt) => {
    return (
      <React.Fragment>
        {opt}
        <Row justify='center'>
          <Spin
            size="small"
            spinning={this.state[this.keyIsLoading] || this.state[this.keyIsLoadMore]}
          />
        </Row>
      </React.Fragment>
    );
  }

  onFocus = () => {
    if (this.__list.length) return

    this.read()
  }

  onSearch = (inputValue) => {
    clearTimeout(this._search)

    this.page = 1
    this.search = inputValue
    this._search = setTimeout(this.read, this.props.waitTime)
  }

  onPopupScroll = (e) => {
    if (
      e.target.scrollHeight - Math.round(e.target.scrollTop) <= (e.target.clientHeight + 10) &&
      this.next
    ) {
      this.loadMore()
    }
  }

  render() {
    const inputProps = pick(this.props, this.allowedInputProps)
    // const formProps = {}
    // for (const key in this.props) {
    //   if (allowedInputProps.includes(key)) {
    //     formProps[key] = this.props[key]
    //   }
    // }

    return (
      <Select
        labelInValue
        // showSearch
        filterOption={false}
        dropdownRender={this.dropdownRender}
        onFocus={this.onFocus}
        onSearch={this.props.showSearch && this.onSearch}
        onPopupScroll={this.onPopupScroll}
        loading={this.state[this.keyIsLoading]}
        style={{ width: '100%' }}

        {...inputProps}

        {...this.props.inputProps}
      >
        {this.__list.map(record => (
          <Select.Option key={record[this.props.valKey]} opt={record}>
            {this.props.renderLabel
              ? this.props.renderLabel(record)
              : record[this.props.valLabel]}
            {/* {record[this.props.valLabel]} */}
          </Select.Option>
        ))}
      </Select>
    )
  }
}

SelectQuery.propTypes = {
  ...SelectQuery.propTypes,
  valKey: PropTypes.string,
  valLabel: PropTypes.string,
  renderLabel: PropTypes.func,
}

SelectQuery.defaultProps = {
  ...SelectQuery.defaultProps,
  waitTime: 350,
  valKey: 'pk',
  valLabel: 'name',
  renderLabel: null,
}

export default SelectQuery