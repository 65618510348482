import { get } from 'lodash';
import moment from 'moment';

import _h from './';
import { lib } from 'src/Utils';

const FORMAT_TIME_API = 'HH:mm'
const FORMAT_TIME = 'HH:mm' // 'hh:mm A'

function allowCancel(userOvertime, selectedUserId) {
  // if (!user) user = lib.ls.getCurrentUser()

  return (
    userOvertime.user_id === lib.ls.getCurrentUser().id &&
    _h.isWaiting(userOvertime) &&
    moment(userOvertime.date).format(_h._s.F_DATE) >= moment().format(_h._s.F_DATE)
  )
}

function allowConfirm(userOvertime, user) {
  if (!user) user = lib.ls.getCurrentUser()

  return (
    // Owner or admin can review
    (user.is_owner || user.permissions.includes('overtime.view_approval_overtime')) &&
    // Own user cannot review
    // Karena di api/dashboard/overtime/overtimes/ tidak ada user_id, maka sementara menggunakan Full Name
    // https://github.com/lewatmana/timetracking-web/issues/442
    (user.is_owner || (get(userOvertime, 'user_id') !== user.id && lib.getFullName(userOvertime.user) !== lib.getFullName(user))) &&
    // Only can confirm when pending submission
    _h.isWaiting(userOvertime)
  )
}

function allowSubmission(date, userOvertimes, selectedUserId) {
  const currentUser = lib.ls.getCurrentUser()

  return (
    // another user cannot request the overtime
    (currentUser.is_owner || isOwn(selectedUserId, currentUser)) &&
    // more than current date
    // moment().format(_h._s.F_DATE) < moment(date).format(_h._s.F_DATE) &&
    // no user leave on the current date
    !getOvertimes(date, userOvertimes).find(userOvertime => !_h.isAborted(userOvertime))
  )
}

function isExpSubmission(userOvertime) {
  return (
    _h.isWaiting(userOvertime) &&
    moment(userOvertime.date).format(_h._s.F_DATE) < moment().format(_h._s.F_DATE)
  )
}

function isOwn(selectedUserId, loginUser) {
  if (!selectedUserId) return false
  if (!loginUser) loginUser = lib.ls.getCurrentUser()

  return String(selectedUserId) === String(loginUser.id)
}

function getOvertimes(date, userOvertimes, dateFormat = _h._s.F_DATE) {
  return userOvertimes.filter(userOvertime => 
    moment(date).format(dateFormat) === moment(userOvertime.date).format(dateFormat))
}

function getDiff(userOvertime, unit = 'ms') {
  return _time(userOvertime.finish_time).diff(_time(userOvertime.start_time), unit)
}

function getDiffActivities(userOvertime, activities = []) {
  const overtimeActivities = []

  for (const activity of activities) {
    const tzActivity = moment(activity.start_time).utcOffset(userOvertime.timezone.gmt_offset)
    if (tzActivity.format('YYYY-MM-DD') === userOvertime.date)
      if (tzActivity.hours() >= (userOvertime.is_holiday ? 0 : 17))
        overtimeActivities.push(activity)
  }

  const diffActivities = {
    activities: overtimeActivities,
    ms: overtimeActivities.reduce((prev, activity) =>
      prev + moment(activity.finish_time).diff(moment(activity.start_time)), 0)
  }

  return {
    ...diffActivities,
    // isValid: diffActivities.ms >= getDiff(userOvertime)
    isValid: diffActivities.ms >= (userOvertime.is_holiday
      ? _h._s.OVERTIME_MIN_HOURS_WEEKEND
      : _h._s.OVERTIME_MIN_HOURS_WEEKDAY)
  }
}

function getTotal(userOvertimes) {
  return userOvertimes.reduce((total, userOvertime) => total + getDiff(userOvertime), 0)
}

function _time(time) {
  return moment(time, FORMAT_TIME_API)
}

function renderTime(time) {
  return _time(time).format(FORMAT_TIME)
}

function renderRange(userOvertime) {
  return (
    renderTime(userOvertime.start_time) +
    ' - ' +
    renderTime(userOvertime.finish_time)
  )
}

function renderDurationByItem(userOvertime, opt) {
  return renderDuration(
    getDiff(userOvertime),
    opt
  )
}

function renderDuration(ms, opt) {
  opt = { lblHour: ' Hours', lblMinute: ' Minutes', ...opt }
  const isMinus = ms < 0
  const duration = moment.duration(Math.abs(ms))
  const hours = Math.floor(duration.asHours())

  return (
    (isMinus ? '- ' : '') +
    `${!hours ? '' : `${hours}${opt.lblHour}`}` +
    ((hours > 0 && duration.minutes() > 0) ? ', ' : '') +
    `${!duration.minutes() ? '' : `${duration.minutes()}${opt.lblMinute}`}`
  )
}

function renderDurationByItems(userOvertimes, opt) {
  return renderDuration(
    userOvertimes.reduce((acc, val) => acc + getDiff(val), 0),
    opt
  )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  _time,

  allowCancel,
  allowConfirm,
  allowSubmission,
  getDiffActivities,
  getOvertimes,
  getTotal,
  isExpSubmission,
  renderRange,
  renderDuration,
  renderDurationByItem,
  renderDurationByItems,
}