import TemplateCard from './TemplateCard'
import { lib } from 'src/Utils'

const UNKNOWN_WINDOW = {
  alias: "No App",
  name: "No App",
  title: "No App",
}

class TopUsageApps extends TemplateCard {
  constructor(props) {
    super(props)

    this.header = {
      title: 'Most Apps Usage',
      link: '/report/app-usage/',
      labelLink: 'Apps Usage Report',
    }
  }

  convertData = (activities) => {
    this.setThisState({ data: this.getTopApps(activities) })
  }

  getTopApps = (activities) => {
    const apps = []
    for (const i in activities) {
      let startTime = activities[i].start_time

      if (activities[i].windows.length) {
        // catatan, for in, itu string, bukan number
        // dan juga hati-hati, tidak boleh range time sampai minus
        for (let j = 0; j < activities[i].windows.length; j += 1) {
          if (!startTime)
            startTime = activities[i].windows[j].actual_created

          const endTime = activities[i].windows[j + 1] ? activities[i].windows[j + 1].actual_created : activities[i].finish_time
          apps.push({
            ...activities[i].windows[j],
            start_time: startTime,
            finish_time: endTime,
          })

          startTime = null
        }
      } else {
        // No windows in activity
        apps.push({
          ...UNKNOWN_WINDOW,
          start_time: startTime,
          finish_time: activities[i].finish_time,
        })
      }
    }

    const KEY = 'name'
    const objTopApps = {}
    for (let i = 0; i < apps.length; i += 1) {
      if (!objTopApps[apps[i][KEY]])
        objTopApps[apps[i][KEY]] = { ...apps[i], key: apps[i][KEY], total: 0 }

      objTopApps[apps[i][KEY]].total += lib.time.getDiff(apps[i])
    }

    const topApps = []
    for (const key in objTopApps) {
      topApps.push(objTopApps[key])
    }

    topApps.sort((a, b) => b.total - a.total)

    return topApps.filter((_, i) => i < 5)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.activities !== nextProps.activities) {
      this.convertData(nextProps.activities)
    }
  }

  didMount() {
    this.convertData(this.props.activities)
  }
}

export default TopUsageApps