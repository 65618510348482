import PropTypes from 'prop-types'
import React from 'react'
import { Spin, Tooltip } from 'antd'
import { ExperimentOutlined, HourglassOutlined } from '@ant-design/icons'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highcharts'
import { get } from 'lodash'
import moment from 'moment'

import { BaseComponent, PageError } from 'src/components'
import { CardHeader } from 'src/pages/DashboardApp/Components'
import { lib } from 'src/Utils'
import options from './options'

class AmountPct extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      errorDescription: undefined, // value must undefined or node
    }
  }

  getIsError = () => (
    !this.props.prodJobsCompany ||
    // !this.props.prevProdJobsCompany ||
    !this.props.userProductionJobs ||
    !this.props.prevUserProductionJobs ||
    this.state.errorDescription
  )

  getErrorComp = () => (
    <PageError
      className='hide-ant-result-icon'
      defaultTitle='Something went wrong'
      defaultSubTitle={this.state.errorDescription}
    />
  )

  convertData = (p = this.props) => {
    if (!p.target) {
      this.setThisState({ errorDescription: 'No data found for that month, try selecting another month' })
      return
    }

    // // set this month ---------------------------------------------------
    let totalCompany = 0
    for (const i in p.prodJobsCompany)
      totalCompany += lib.prod.getUserAmount(p.prodJobsCompany[i].qs_value)

    this.pctCompany = totalCompany / lib.prod.getUserAmount(p.target.amount) * 100
    // // ------------------------------------------------------------------
    // set last month ---------------------------------------------------
    let totalPrev = 0, totalPrevPoint = 0
    for (const finishedProductionJobs of get(p.prevUserProductionJobs, 'qs_production_jobs', []).filter(job => job.status === 'finished'))
      // totalPrev += lib.prod.getUserAmount(p.prevUserProductionJobs.qs_production_jobs[i].qs_value)
      totalPrevPoint += Number(finishedProductionJobs.qs_point)

    const targetPrevPoint = get(p.prevTarget, 'working_days') / 20 * (lib.prod.getUserAmount(get(p.prevUserTarget, 'amount')) / get(p.prevUserTypeQS, 'denominator'))
    options.series[0].data[0].y = totalPrevPoint / targetPrevPoint * 100
    // options.series[0].data[0].y = totalPrev / lib.prod.getUserAmount(get(p.prevUserTarget, 'amount')) * 100
    // ------------------------------------------------------------------
    // set this month ---------------------------------------------------
    let total = 0
    this.totalPoint = 0
    this.inProgressPoint = 0
    // old props
    // for (const i in get(p.userProductionJobs, 'qs_production_jobs')) {
    //   total += lib.prod.getUserAmount(p.userProductionJobs.qs_production_jobs[i].qs_value)
    //   this.totalPoint += Number(p.userProductionJobs.qs_production_jobs[i].qs_point)
    // }
    for (const finishedProductionJobs of get(p.userProductionJobs, 'qs_production_jobs', []).filter(job => job.status === 'finished')) {
      total += lib.prod.getUserAmount(finishedProductionJobs.qs_value)
      this.totalPoint += Number(finishedProductionJobs.qs_point)
    }
    for (const inProgressProductionJobs of (get(p.userProductionJobs, 'qs_production_jobs', [])).filter(job => job.status === 'in_progress'))
      this.inProgressPoint += Number(inProgressProductionJobs.qs_point)
    // new props
    // for (const productionJob in p.productionJobs)
    //   this.totalPoint += Number(productionJob.qs_point)

    // https://github.com/lewatmana/timetracking-web/issues/224
    this.targetPoint = p.target.working_days / 20 * (lib.prod.getUserAmount(get(p.userTarget, 'amount')) / get(p.userTypeQS, 'denominator'))
    options.series[0].data[1].y = this.totalPoint / this.targetPoint * 100
    // options.series[0].data[1].y = total / lib.prod.getUserAmount(get(p.userTarget, 'amount')) * 100
    // this.targetPoint = this.totalPoint / (options.series[0].data[1].y / 100)
    // ------------------------------------------------------------------
    // set prediction ---------------------------------------------------
    let totalDays = 0, prodDays = []
    // old props
    for (const i in get(p.userProductionJobs, 'qs_production_jobs')) {
      if (prodDays.indexOf(p.userProductionJobs.qs_production_jobs[i].prod_day) === -1) {
        totalDays += 1
        prodDays.push(p.userProductionJobs.qs_production_jobs[i].prod_day)
      }
    }
    for (const finishedProductionJobs of get(p.userProductionJobs, 'qs_production_jobs', []).filter(job => job.status === 'finished')) {
      if (prodDays.indexOf(finishedProductionJobs.prod_day) === -1) {
        totalDays += 1
        prodDays.push(finishedProductionJobs.prod_day)
      }
    }
    // new props
    // for (const productionJob in p.productionJobs)
    //   if (prodDays.indexOf(productionJob.prod_day) === -1)
    //     if (++totalDays)
    //       prodDays.push(productionJob.prod_day)

    // totalDays cannot be zero
    if (totalDays === 0)
      totalDays = 1

    window.konsole.log('totalDays', totalDays)
    // options.series[0].data[3].y = (total / totalDays * p.target.working_days) / Number(p.target.amount) * 100
    options.series[0].data[3].y = (this.totalPoint / totalDays * p.target.working_days) / this.targetPoint * 100
    // ------------------------------------------------------------------
    // Set month label --------------------------------------------------
    if (moment(p.date).format('YYYY-MM') === moment().format('YYYY-MM')) {
      options.xAxis.categories[0] = 'Last Month'
      options.xAxis.categories[1] = 'This Month'
    } else {
      options.xAxis.categories[0] = moment(p.date).subtract(1, 'month').format('MMMM YYYY')
      options.xAxis.categories[1] = moment(p.date).format('MMMM YYYY')
    }
    // ------------------------------------------------------------------


    this.state.errorDescription = undefined
    this.forceUpdate()
    // this.setThisState({
    //   errorDescription: undefined,
    // })
  }

  getCurrentPct = () => (this.targetPoint && this.totalPoint / this.targetPoint)
    ? `${(this.totalPoint / this.targetPoint * 100).toFixed(2)}%`
    : '-'

  // getCurrentPct = () => (
  //   (Number(options.series[0].data[1].y) && Number(options.series[0].data[1].y) !== Infinity)
  //     ? `${Number(options.series[0].data[1].y).toFixed(2)}%`
  //     : '-'
  // )

  getCompPct = () => (this.pctCompany && this.pctCompany !== Infinity)
    ? `${this.pctCompany.toFixed(2)}%`
    : '-'

  UNSAFE_componentWillReceiveProps(nextProps) {
    const keysForUpdate = ['target', 'userProductionJobs', 'prevUserProductionJobs']
    for (const key of keysForUpdate) {
      if (this.props[key] !== nextProps[key]) {
        this.convertData(nextProps)
        break
      }
    }
  }

  didMount() {
    this.convertData()
  }

  render() {
    window.konsole.log('Rendered', {options}, this)
    return this.getIsError() ? this.getErrorComp() : (
      <div>
        <CardHeader
          title={
            <span style={{ fontSize: '16px' }}>
              <span>Mine: {this.getCurrentPct()} </span>
              {!!this.targetPoint && (
                <Tooltip title="Accumulated Points" mouseLeaveDelay={0}>
                  <span>({this.totalPoint.toFixed(2)} / {this.targetPoint.toFixed(2)})</span>
                </Tooltip>
              )}
              {!!this.targetPoint && (
                <Tooltip title="In-Progress Points" mouseEnterDelay={0}>
                  <span style={{ marginLeft: '12px', fontSize: '12px', color: 'rgba(0, 0, 0, 0.5)' }}>
                    <ExperimentOutlined /> {this.inProgressPoint.toFixed(2)}
                  </span>
                </Tooltip>
              )}
            </span>
          }
          extra={<h3 style={{ fontSize: '16px' }}>All: {this.getCompPct()}</h3>}
        />
        <HighchartsReact
          highcharts={Highcharts}
          options={{ ...options }}
        />
      </div>
    )
  }
}

AmountPct.propTypes = {
  // productionJobs: PropTypes.array,
  // prevProductionJobs: PropTypes.array,
}

AmountPct.defaultProps = {
  prodJobsCompany: [],
  // prevProdJobsCompany: [],
  userProductionJobs: {},
  prevUserProductionJobs: {},
  // productionJobs: [],
  // prevProductionJobs: [],
  target: {},
  prevTarget: {},
}

export default AmountPct