import moment from 'moment'

import { lib } from 'src/Utils'

function getGroupedJobs(productionJobs) {
  const groupedJobs = {}
  for (let i = 0; i < productionJobs.length; i += 1) {
    const key1 = lib.getFullName(productionJobs[i].user)
    const key2 = moment(productionJobs[i].finished_date).format('YYYY-MM')
    if (!groupedJobs[key1])
      groupedJobs[key1] = { objGrade: {}, jobs: [] }

    groupedJobs[key1].user = productionJobs[i].user
    groupedJobs[key1].jobs.push(productionJobs[i])

    if (!groupedJobs[key1].objGrade[productionJobs[i].grade])
      groupedJobs[key1].objGrade[productionJobs[i].grade] = []

    groupedJobs[key1].objGrade[productionJobs[i].grade].push(productionJobs[i])

    if (!groupedJobs[key1].months)
      groupedJobs[key1].months = {}

    if (!groupedJobs[key1].months[key2])
      groupedJobs[key1].months[key2] = []

    groupedJobs[key1].months[key2].push(productionJobs[i])
  }

  return groupedJobs
}

export default getGroupedJobs