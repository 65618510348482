import PropTypes from 'prop-types'
import { Button, Checkbox, Col, Dropdown, Menu, message, Row, Spin, Table, Tag, Tooltip } from 'antd'
import { DownOutlined, DownloadOutlined, InfoCircleOutlined, SyncOutlined, ClusterOutlined, PartitionOutlined } from '@ant-design/icons'
import NumberFormat from 'react-number-format'
import FileSaver from 'file-saver'
import moment from 'moment'
import classNames from 'classnames'
import { get, groupBy, lowerCase, orderBy } from 'lodash'

import { BaseComponent } from 'src/components'
import { FieldInternalDue, FieldNotes } from './fields'
import { lib, request, requestHelper } from 'src/Utils'

const KEY_MSG_DOWNLOAD = 'downloading-detail'
const KEY_NO_GROUP = 'no_group'
const KEY_GROUP_TAKEOFF = 'takeoff'
const KEY_GROUP_QS = 'qs'
const KEY_GROUP_QS_DUE = 'qs_due'
const KEY_GROUP_RATING = 'rating'

// README !!!
// Class ini di extends oleh beberapa component, check component pengguna sebelum ini di ubah
class ProductionDetail extends BaseComponent {
  constructor(props) {
    super(props)

    // for child class
    this.title = 'Production Detail'
    this.headerClassName = 'sticky-production-detail'
    this.showExport = true
    this.resyncFeature = true
    // this.totalEmptyCols = 7
    // -----------------------------

    // this.scrollOpts = { x: 720, y: 'calc(100vh - 135px)' }
    this.scrollOpts = { x: 720 }
    this.stickyOpt = { offsetHeader: 90 }
    this.groupOpts = [
      { key: KEY_NO_GROUP, label: '-' },
      { key: KEY_GROUP_TAKEOFF, label: 'Takeoff', icon: <PartitionOutlined /> },
      { key: KEY_GROUP_QS, label: 'QS', icon: <PartitionOutlined /> },
      { key: KEY_GROUP_QS_DUE, label: 'QS Due', icon: <PartitionOutlined /> },
      { key: KEY_GROUP_RATING, label: 'Rating', icon: <PartitionOutlined /> },
    ]

    this.columnsTakeoff = [
      {
        title: 'Takeoff',
        dataIndex: 'abbr',
        sorter: (a, b) => a.abbr > b.abbr ? 1 : -1,
      },
      {
        title: 'Description',
        dataIndex: 'name',
        sorter: (a, b) => a.name > b.name ? 1 : -1,
      },
    ]

    // // column names
    // this.COL_NAMES = {
    //   DAY: 'Day',
    //   JOB_NO: 'Job No.',
    //   JOB_NAME: 'Job Name',
    //   TAKEOFF: 'Takeoff',
    //   STARTED: 'Started',
    //   QA1: 'QA1',
    //   QA2: 'QA2',
    //   INTERNAL_DUE: 'Internal Due',
    //   NOTES: 'Notes',
    //   MUST_FINISH: 'Must Finish',
    //   QS: 'QS',
    //   SIZE: 'Size',
    //   RATING: 'Rating',
    //   HI_LIGHT: 'Hi-Light',
    //   QS_TOTAL: 'QS Total',
    //   TOTAL: 'Total',
    // }

    // this.columns = [
    //   this.COL_NAMES.DAY,
    // ]

    this.initializeColumns()
    this.insertPerformanceIndicatorColumns()

    this.state = {
      keyGrouping: this.groupOpts[0].key,
      data: this.getData(props.productionJobs, this.groupOpts[0].key),
      downloading: false,
      syncing: false,
      selectedRowKeys: [],
    }
  }

  renderDateColumn = (val) => !val ? '-' : (
    <Tooltip title={moment(val).format('DD MMMM YYYY h:mm:ss A (Z)')} mouseLeaveDelay={0} overlayStyle={{ maxWidth: '272px' }}>
      <span>{moment(val).format('DD MMM YYYY')}</span>
    </Tooltip>
  )

  initializeColumns = (readOnly = true) => {
    this.columns = [
      {
        title: 'Day',
        dataIndex: 'prod_day',
        align: 'right',
        width: '50px',
        fixed: 'left',
        render: (value, record) => {
          return {
            children: !record.groupName ? value : <span style={{ fontWeight: 500 }}>{record.groupName}</span>,
            ...(!!record.groupName && { props:{colSpan: 3, style: {textAlign: 'left'}} }),
          }
        },
        // summary
        summaryProps: { align: 'right' },
        getSummary: (data) => {
          const prodDays = []
          let totalProdDay = 0
          for (const i in data) {
            if (prodDays.indexOf(data[i].prod_day) === -1) {
              totalProdDay += 1
              prodDays.push(data[i].prod_day)
            }
          }
          return totalProdDay
        },
      },
      {
        title: 'Job No.',
        dataIndex: 'job_id',
        width: '70px',
        fixed: 'left',
        sorter: (a, b) => lib.sorter.byItem(a, b, 'job_id'),
        render: (value, record) => {
          return {
            children: value,
            ...(!!record.groupName && { props:{colSpan: 0} }),
          }
        },
        // summary
        summaryProps: { align: 'right' },
        getSummary: (data) => {
          let total = 0
          for (const i in data)
            total += data[i].children
              ? data[i].children.length
              : 1

          return <span style={{ fontSize: '13px' }}>{total} jobs</span>
        },
      },
      {
        title: 'Job Name',
        dataIndex: 'name',
        // width: '300px',
        width: '260px',
        ellipsis: true,
        sorter: (a, b) => lib.sorter.byItem(a, b, 'name'),
        render: (value, record) => {
          return {
            children: value,
            ...(!!record.groupName && { props:{colSpan: 0} }),
          }
        }
      },
      {
        title: 'Takeoff',
        dataIndex: ['take_off_data', 'abbr'],
        width: '120px',
        ellipsis: true,
        sorter: (a, b) => get(a, ['take_off_data', 'abbr'], '') > get(b, ['take_off_data', 'abbr'], '') ? 1 : -1,
        // title: (
        //   <span>
        //     Takeoff
        //     <Tooltip title={this.getInfoTakeoff()}>
        //       <InfoCircleOutlined style={{ marginLeft: '6px', fontSize: '12px', color: '#aaa', cursor: 'pointer' }} />
        //     </Tooltip>
        //   </span>
        // )
      },
      {
        title: 'Started',
        dataIndex: 'started_date',
        width: '100px',
        sorter: (a, b) => lib.sorter.byItem(a, b, 'started_date'),
        render: this.renderDateColumn,
      },
      {
        title: 'QA1',
        dataIndex: 'qa1_date',
        width: '100px',
        sorter: (a, b) => lib.sorter.byItem(a, b, 'qa1_date'),
        render: this.renderDateColumn,
      },
      {
        title: 'QA2',
        dataIndex: 'qa2_date',
        width: '100px',
        sorter: (a, b) => lib.sorter.byItem(a, b, 'qa2_date'),
        render: this.renderDateColumn,
      },
      {
        title: 'QS Due',
        dataIndex: 'target_due_date',
        width: '124px',
        sorter: (a, b) => lib.sorter.byItem(a, b, 'target_due_date'),
        onCell: () => ({ style: {padding: '4px'} }),
        render: (val, prodJob) => <FieldInternalDue
          readOnly={readOnly}
          value={val}
          prodJobId={prodJob.id}
          finishedDate={prodJob.finished_date}
          onChangeItem={this.props.onChangeItem}
        />,
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        width: '180px',
        sorter: (a, b) => lib.sorter.byItem(a, b, 'notes'),
        onCell: () => ({ style: {padding: '4px'} }),
        render: (val, prodJob) => <FieldNotes
          readOnly={readOnly}
          value={val}
          prodJobId={prodJob.id}
          onChangeItem={this.props.onChangeItem}
        />,
      },
      {
        title: 'Must Finish',
        dataIndex: 'must_finish_date',
        width: '100px',
        sorter: (a, b) => lib.sorter.byItem(a, b, 'must_finish_date'),
        render: (val) => !val ? '-' : moment(val).format('DD MMM YYYY'),
      },
      /* {
        title: 'Duration',
        dataIndex: 'work_duration',
        align: 'right',
        width: '100px',
        sorter: (a, b) => lib.sorter.byItem(a, b, 'work_duration'),
        render: (duration) => {
          if (!duration) return '-'

          let days = 0
          if (duration.includes(' '))
            [days, duration] = duration.split(' ')

          const [hours, minutes, seconds] = duration.split(':').map(val => Number(val))

          const daysLabel = !days ? '' : `${days} Days`
          const hoursLabel = !hours ? '' : `${hours} Hours`
          const minutesLabel = !minutes ? '' : `${minutes} Minutes`
          const secondsLabel = `${Math.ceil(seconds || 1)} Seconds`

          return (
            <Tooltip mouseLeaveDelay={0} title={[daysLabel, hoursLabel, minutesLabel, secondsLabel].filter(v => v).join(', ')}>
              <span>
                {daysLabel || hoursLabel || minutesLabel || secondsLabel}
              </span>
            </Tooltip>
          )
        }
      }, */
      {
        title: 'QS',
        dataIndex: 'user',
        width: '170px',
        sorter: (a, b) => lib.sorter.byItem(lowerCase(lib.getFullName(a.user)), lowerCase(lib.getFullName(b.user))),
        render: user => lib.getFullName(user),
      },
      {
        title: 'Size',
        dataIndex: 'area',
        align: 'right',
        width: '80px',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.area - b.area,
        render: val => <NumberFormat thousandSeparator value={Math.round((Number(val) || 0) * 100) / 100} displayType='text' />,
        // summary
        summaryProps: { align: 'right' },
        getSummary: (data) => <NumberFormat thousandSeparator displayType='text' value={data.reduce((acc, val) => acc + Number(val.area), 0).toFixed(0)} />,
      },
      {
        title: 'Rating',
        dataIndex: 'grade',
        width: '140px',
        sorter: (a, b) => lib.sorter.byItem(a, b, 'grade'),
      },
      {
        title: 'Point',
        dataIndex: 'qs_point',
        align: 'right',
        width: '80px',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => lib.sorter.byItem(Number(a.qs_point), Number(b.qs_point)),
        render: val => <NumberFormat thousandSeparator value={Number(val).toFixed(2)} displayType='text' />,
        // summary
        summaryProps: { align: 'right' },
        getSummary: (data) => <NumberFormat thousandSeparator displayType='text' value={data.reduce((acc, val) => acc + Number(val.qs_point), 0).toFixed(2)} />,
      },
      {
        title: 'Hi-Light',
        dataIndex: 'highlighted_total_charge',
        align: 'right',
        width: '100px',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.highlighted_total_charge - b.highlighted_total_charge,
        render: val => <NumberFormat thousandSeparator value={Number(val).toFixed(2)} displayType='text' />,
        // summary
        summaryProps: { align: 'right' },
        getSummary: (data) => <NumberFormat thousandSeparator displayType='text' value={data.reduce((acc, val) => acc + Number(val.highlighted_total_charge), 0).toFixed(2)} />,
      },
      {
        title: 'QS Total',
        dataIndex: 'qs_value',
        align: 'right',
        width: '100px',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.qs_value - b.qs_value,
        render: val => <NumberFormat thousandSeparator value={Number(val).toFixed(2)} displayType='text' />,
        // summary
        summaryProps: { align: 'right' },
        getSummary: (data) => <NumberFormat thousandSeparator displayType='text' value={data.reduce((acc, val) => acc + Number(val.qs_value), 0).toFixed(2)} />,
      },
      {
        title: 'Total',
        dataIndex: 'total_qs_value',
        align: 'right',
        width: '120px',
        sortDirections: ['descend', 'ascend'],
        fixed: 'right',
        sorter: (a, b) => a.total_qs_value - b.total_qs_value,
        render: val => <NumberFormat thousandSeparator value={Number(val).toFixed(2)} displayType='text' />,
        // summary
        summaryProps: { align: 'right' },
        getSummary: (data) => <NumberFormat thousandSeparator displayType='text' value={data.reduce((acc, val) => acc + Number(val.total_qs_value), 0).toFixed(2)} />,
      },
    ]
    // this.columns = this.columns.slice(0, 6)
  }

  insertPerformanceIndicatorColumns = () => {
    const beforeIndex = this.columns.findIndex(column => column.dataIndex === 'qa2_date')
    if (beforeIndex !== -1) {
      this.columns.splice(beforeIndex + 1, 0,
        {
          title: 'QS Duration',
          dataIndex: 'qs_duration_seconds',
          align: 'right',
          // width: '110px',
          width: '120px',
          // width: '220px',
          sorter: (a, b) => lib.sorter.byItem(a, b, 'qs_duration_seconds'),
          render: (secDuration) => {
            if (!secDuration) return '-'

            const duration = moment.duration(secDuration * 1000)
            const hours = duration._data.hours + (duration._data.days * 24)
            const hoursLabel = hours && `${hours} Hours`
            const minutesLabel = `${duration._data.minutes} Minutes`

            return (
              <span>
                {hoursLabel || minutesLabel}
              </span>
            )
          },
          // summary
          summaryProps: { align: 'right' },
          getSummary: (data) => {
            const totalSeconds = data.reduce((acc, val) => acc + Number(val.qs_duration_seconds), 0)

            const duration = moment.duration(totalSeconds * 1000)
            const hours = duration._data.hours + (duration._data.days * 24)
            const hoursLabel = hours && `${hours} Hours`
            const minutesLabel = `${duration._data.minutes} Minutes`

            return (
              <span>
                {hoursLabel || minutesLabel}
              </span>
            )
          },
        },
        {
          title: 'KPI M²PH',
          dataIndex: 'kpi_m2ph',
          align: 'right',
          width: '100px',
          sorter: (a, b) => lib.sorter.byItem(a, b, 'kpi_m2ph'),
          render: (val) => val ? Number(val).toFixed(2) : '-',
          // render: (val, record) => record.grade === 'HOURLY'
          //   ? 'N/A'
          //   : !val
          //     ? '-'
          //     : Number(val).toFixed(2),
          // summary
          // summaryProps: { align: 'right' },
          // getSummary: (data) => data.reduce((acc, val) => acc + Number(val.grade === 'HOURLY' ? 0 : val.kpi_m2ph), 0).toFixed(2),
        },
      )
    }
  }

  setTitleTakeoff = (productionJobs) => {
    // dataIndex: ['take_off_data', 'abbr'],
    const takeoffColumn = this.columns.find(column => column.dataIndex[0] === 'take_off_data')
    if (takeoffColumn)
    takeoffColumn.title = (
      <span>
        Takeoff
        <Tooltip color='white' placement='rightTop' title={this.getInfoTakeoff(productionJobs)}>
          <InfoCircleOutlined style={{ marginLeft: '6px', fontSize: '12px', color: '#aaa', cursor: 'pointer' }} />
        </Tooltip>
      </span>
    )
  }

  getInfoTakeoff = (productionJobs) => {
    const takeoffList = []
    for (const i in productionJobs) {
      if (productionJobs[i].take_off_data) {
        if (takeoffList.findIndex(item => item.abbr === productionJobs[i].take_off_data.abbr) === -1) {
          takeoffList.push(productionJobs[i].take_off_data)
        }
      }
    }
    takeoffList.sort((a, b) => a.abbr > b.abbr ? 1 : -1)

    return (
      <div onClick={e => e.stopPropagation()}>
        <Table
          bordered
          size='small'
          columns={this.columnsTakeoff}
          dataSource={takeoffList}
          onRow={(_, i) => i % 2 === 1 && ({ className: 'even' })}
          pagination={false}
        />
      </div>
    )
  }

  getRowSelection = () => this.resyncFeature && ({
    selectedRowKeys: this.state.selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      this.setThisState({ selectedRowKeys })
    },
    getCheckboxProps: (record) => ({
      style: { display: record.groupName ? 'none' : 'inline-flex' },
    }),
    // renderCell: (checked, record, index, node) => !record.groupName && <Checkbox checked={checked} />,
  })

  getData = (productionJobs, keyGrouping = this.state.keyGrouping) => {
    let data = []

    let lastProdDay = 0, isEven = true
    for (const i in productionJobs) {
      if (lastProdDay !== productionJobs[i].prod_day) {
        isEven = !isEven
        lastProdDay = productionJobs[i].prod_day
      }

      data.push({ isEven, ...productionJobs[i] })
    }

    this.setTitleTakeoff(productionJobs)

    if (keyGrouping !== KEY_NO_GROUP) {
      data = this.getGroupedData(keyGrouping, data)
    }

    return data
  }

  setData = (productionJobs) => {
    this.setThisState({ data: this.getData(productionJobs), selectedRowKeys: [] })
  }

  summaryTableOld = (currentData) => {
    // window.konsole.log('summaryTable', currentData)
    const prodDays = []
    let totalProdDay = 0
    for (const i in currentData) {
      if (prodDays.indexOf(currentData[i].prod_day) === -1) {
        totalProdDay += 1
        prodDays.push(currentData[i].prod_day)
      }
    }

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} align='right' colSpan={this.resyncFeature ? 2 : 1}>
          {totalProdDay}
        </Table.Summary.Cell>
        {/* <Table.Summary.Cell index={2} /> */}
        <Table.Summary.Cell index={3} colSpan={this.totalEmptyCols} />
        <Table.Summary.Cell index={4} align='right'>
          {currentData.reduce((acc, val) => acc + Number(val.area), 0)}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={5} />
        <Table.Summary.Cell index={6} align='right'>
          <NumberFormat
            thousandSeparator
            displayType='text'
            value={currentData.reduce((acc, val) => acc + Number(val.highlighted_total_charge), 0).toFixed(2)}
          />
        </Table.Summary.Cell>
        <Table.Summary.Cell index={7} align='right'>
          <NumberFormat
            thousandSeparator
            displayType='text'
            value={currentData.reduce((acc, val) => acc + Number(val.qs_value), 0).toFixed(2)}
          />
        </Table.Summary.Cell>
        <Table.Summary.Cell index={8} align='right' className='col-summary-fixed-right'>
          <NumberFormat
            thousandSeparator
            displayType='text'
            value={currentData.reduce((acc, val) => acc + Number(val.total_qs_value), 0).toFixed(2)}
          />
        </Table.Summary.Cell>
      </Table.Summary.Row>
    )
  }

  // overrided
  getTableSummaryProps = () => null

  summaryTable = (currentData) => {
    // window.konsole.log('summaryTable', currentData)
    const firstIndex = this.resyncFeature ? 1 : 0
    return (
      <Table.Summary {...this.getTableSummaryProps()}>
        <Table.Summary.Row>
          {this.resyncFeature && (
            <Table.Summary.Cell index={0} />
          )}
          {this.columns.map((column, i) => (
            <Table.Summary.Cell key={column.title} index={i + firstIndex} {...column.summaryProps}>
              {column.getSummary && column.getSummary(currentData)}
            </Table.Summary.Cell>
          ))}
        </Table.Summary.Row>
      </Table.Summary>
    )
  }

  getGroupingMenu = () => (
    <Menu onClick={this.onGroupClick}>
      {this.groupOpts.map(group => (
        <Menu.Item key={group.key} icon={group.icon}>
          {group.label}
        </Menu.Item>
      ))}
    </Menu>
  )

  onGroupClick = ({ key }) => {
    this.setState({
      keyGrouping: key,
      data: this.getData(this.props.productionJobs, key),
    })
  }

  getGroupedData = (key, data = this.state.data) => {
    const getTakeoff = (job) => job.take_off_data.name
    const getUser = (job) => lib.getFullName(job.user)
    const getQsDue = (job) => (job.target_due_date ? moment(job.target_due_date).format('DD MMM YYYY') : '-')
    const getRating = (job) => job.grade
    const getGroupName = key === KEY_GROUP_TAKEOFF
      ? getTakeoff
      : key === KEY_GROUP_QS
        ? getUser
        : key === KEY_GROUP_RATING
          ? getRating
          : key === KEY_GROUP_QS_DUE
            ? getQsDue
            : () => 'error'

    const groupedData = []
    for (const job of data) {
      const groupName = getGroupName(job)
      let foundIndex = groupedData.findIndex(group => group.groupName === groupName)
      if (foundIndex === -1)
        // job_id untuk row key
        foundIndex = groupedData.push({
          groupName,
          job_id: groupName,
          children: [],
          // summary --------------------
          area: 0,
          highlighted_total_charge: 0,
          qs_value: 0,
          total_qs_value: 0,
          point: 0,
          qs_point: 0,
          // ----------------------------
        }) - 1

      groupedData[foundIndex].children.push(job)
      // summary
      groupedData[foundIndex].area += Number(job.area)
      groupedData[foundIndex].highlighted_total_charge += Number(job.highlighted_total_charge)
      groupedData[foundIndex].qs_value += Number(job.qs_value)
      groupedData[foundIndex].total_qs_value += Number(job.total_qs_value)
      groupedData[foundIndex].point += Number(job.point)
      groupedData[foundIndex].qs_point += Number(job.qs_point)
    }

    return orderBy(groupedData, 'groupName')
  }

  getExportMenu = () => {
    return (
      <Menu onClick={this.onExportClick}>
        <Menu.Item key='pdf' icon={<DownloadOutlined />}>
          Export to PDF
        </Menu.Item>
        <Menu.Item key='excel' icon={<DownloadOutlined />}>
          Export to EXCEL
        </Menu.Item>
        <Menu.Item key='csv' icon={<DownloadOutlined />}>
          Export to CSV
        </Menu.Item>
      </Menu>
    )
  }

  onExportClick = ({ key, ...props }) => {
    if (this.state.downloading)
      return message.info('Please wait until previous export to finish')

    this.setThisState({ downloading: true }, () => {
      message.loading({ key: KEY_MSG_DOWNLOAD, content: `Downloading Production Detail (${key}) ...`, duration: 0 })
      requestHelper({
        urlKey: 'export-productionJobs',
        config: { responseType: 'blob' },
        data: {
          finished_date_after: moment(this.props.dateString).startOf('month').format(),
          finished_date_before: moment(this.props.dateString).endOf('month').format(),
          export_to: key,
          status: 'finished',
        },
        onSuccess: this.exportSuccess,
        onFailed: this.exportFailed,
      })
    })
  }

  exportSuccess = (response) => {
    const filename = lib.getFilenameDisposition(response.headers)
    FileSaver.saveAs(response.data, filename)
    message.success({ key: KEY_MSG_DOWNLOAD, content: 'File downloaded' })
    this.setThisState({ downloading: false })
  }

  exportFailed = () => {
    message.error({ key: KEY_MSG_DOWNLOAD, content: 'Export failed!' })
    this.setThisState({ downloading: false })
  }

  resync = () => {
    if (this.state.syncing)
      return

    this.setThisState({ syncing: true }, () => {
      request({
        method: 'post',
        urlKey: 'resync-productionJobs',
        data: { ids: this.state.selectedRowKeys },
        onSuccess: this.resyncSuccess,
        onFailed: this.resyncFailed,
      })
    })
  }

  resyncSuccess = () => {
    this.setThisState({ syncing: false, selectedRowKeys: [] }, () => {
      setTimeout(() => {
        this.props.reload()
      }, 500)
    })
  }

  resyncFailed = (error) => {
    lib.responseMessage.error(error.response) 
    this.setThisState({ syncing: false })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.productionJobs !== nextProps.productionJobs) {
      this.setData(nextProps.productionJobs)
    }
  }

  renderHeader() {
    return (
      <Row justify='space-between' gutter={[6, 6]} className={this.headerClassName}>
        <Col>
          <h2 style={{ marginBottom: 0 }}>
            {this.title}
          </h2>
        </Col>
        <Col className='only-xs-block'>
          <Row gutter={[6, 6]}>
            {!!this.state.selectedRowKeys.length && (
              <Col className='only-xs-block'>
                <Button block type='primary' onClick={this.resync}>
                  <SyncOutlined spin={this.state.syncing} />
                  Resync {this.state.selectedRowKeys.length} Jobs
                </Button>
              </Col>
            )}
            <Col>
              <Dropdown
                disabled={this.props.loading}
                placement='bottomRight'
                trigger={['click']}
                overlay={this.getGroupingMenu()}
              >
                <Button block loading={this.state.downloading} icon={<DownOutlined />} style={{ minWidth: '180px', textAlign: 'left' }}>
                  Grouping by<b> {this.groupOpts.find(opt => opt.key === this.state.keyGrouping).label}</b>
                </Button>
              </Dropdown>
            </Col>
            {this.showExport && (
              <Col className='only-xs-block'>
                <Dropdown
                  disabled={this.props.loading}
                  placement='bottomRight'
                  trigger={['click']}
                  overlay={this.getExportMenu()}
                >
                  <Button block loading={this.state.downloading} icon={<DownOutlined />} style={{ minWidth: '100px' }}>
                    Export ({moment(this.props.dateString).format('MMMM YYYY')})
                  </Button>
                </Dropdown>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    )
  }

  render() {
    window.konsole.log('Rendered', this)

    return (
      <div>
        {this.renderHeader()}
        <Spin spinning={this.props.loading}>
          <div>
            <Table
              bordered
              showSorterTooltip={false}
              sticky={this.stickyOpt}
              size='small'
              rowKey='job_id'
              className='ant-table-productionDetail'
              columns={this.columns}
              dataSource={this.state.data}
              pagination={false}
              scroll={this.scrollOpts}
              summary={this.summaryTable}
              // onRow={record => record.isEven && ({ className: 'even' })}
              onRow={record => ({ className: classNames({'even': record.isEven, 'row-group': !!record.groupName}) })}
              rowSelection={this.getRowSelection()}
              expandable={{
                expandIcon: () => null,
                expandRowByClick: true,
              }}
            />
          </div>
        </Spin>
      </div>
    )
  }
}

ProductionDetail.propTypes = {
  ...ProductionDetail.propTypes,
  loading: PropTypes.bool,
  productionJobs: PropTypes.array,
}

ProductionDetail.defaultProps = {
  ...ProductionDetail.defaultProps,
  loading: false,
  productionJobs: [],
}

export default ProductionDetail