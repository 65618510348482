import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, Col, message, Modal, Row, Spin, Switch, Tooltip } from 'antd'
import { AuditOutlined, CameraOutlined, InteractionOutlined, PlaySquareOutlined, SolutionOutlined } from '@ant-design/icons'
import Webcam from 'react-webcam'
import moment from 'moment'

import { BaseModal } from 'src/components'
import { lib, permissions, request } from 'src/Utils'

class AttendDialog extends BaseModal {
  constructor(props) {
    super(props)

    this.myProfile = lib.ls.getCurrentUser()

    this.title = 'Proof of my presence'

    this.defaultState = {
      isLoading: false,
      srcPhoto: null,
      isCamera: true,
      failedCount: 0,
      errorMessage: null,
      coords: null,
      checkingLocation: false,
      facingMode: 'user',
    }

    this.state = {
      ...this.state,
      ...this.defaultState,
      devicesCamera: [],
    }
  }

  onRefWebcam = (ref) => {
    this._webcam = ref
  }

  getModalProps = () => ({
    title: `Presence of user (${lib.getFullName(this.props.user)})`,
    destroyOnClose: true,
    width: '1200px',
    // style: {top: 20},
    okText: 'Attend',
    okButtonProps: {
      loading: this.state.isLoading,
      disabled: !this.state.srcPhoto || this.state.errorMessage,
    },
    footer: this.getFooter(),
  })

  getFooter = () => (
    <Row justify='space-between' gutter={[6, 6]} style={{ padding: '0px 9px' }}>
      <Col>
      </Col>
      <Col>
        <Row gutter={[12, 12]}>
          <Col className='only-xs-block'>
            <Button
              block
              loading={this.state.isLoading}
              disabled={!this.state.srcPhoto || this.state.errorMessage}
              type='primary'
              icon={<AuditOutlined />}
              style={{ minWidth: '120px' }}
              onClick={this.onCheckin}
            >
              Check-In
            </Button>
          </Col>
          <Col className='only-xs-block'>
            <Button
              block
              danger
              type='primary'
              loading={this.state.isLoading}
              disabled={!this.state.srcPhoto || this.state.errorMessage}
              icon={<AuditOutlined />}
              style={{ minWidth: '120px' }}
              onClick={this.onCheckout}
            >
              Check-Out
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )

  onCapture = async () => {
    const imgSrc = this._webcam.getScreenshot()
    if (!imgSrc)
      return message.info('Camera not active, please allow camera on your browser')

    this.setThisState(
      { srcPhoto: imgSrc, isCamera: false },
      this.setLocation
    )
  }

  setLocation = () => {
    if (this.useLocation) {
      if (navigator.geolocation) {
        if (this.state.checkingLocation)
          return message.info('The system is looking for a location, please wait...')

        this.setThisState({ checkingLocation: true }, () => {
          navigator.geolocation.getCurrentPosition(
            this.getLocationSuccess,
            this.getLocationFailed,
            // { enableHighAccuracy: true, timeout: 5000 },
            { timeout: 5000 },
          )
        })
      } else {
        this.getLocationFailed({ message: 'Your browser does not support location, please use another location' })
      }
    }
  }

  getLocationSuccess = (res) => {
    this.setThisState({ coords: res.coords, errorMessage: null, checkingLocation: false })
  }

  getLocationFailed = (error) => {
    message.error(error.message)
    this.setThisState({ errorMessage: error.message, checkingLocation: false })
  }

  onActivateCamera = () => {
    this.setThisState({ isCamera: true, srcPhoto: null })
  }

  onCheckin = () => {
    this.attendType = 'checkin'
    this.onOk()
  }

  onCheckout = () => {
    this.attendType = 'checkout'
    this.onOk()
  }

  onOk = () => {
    if (this.state.checkingLocation)
      return message.info('The system is looking for a location, please wait...')

    this.setThisState({ isLoading: true }, async () => {
      const myProfile = lib.ls.getCurrentUser()
      const filename = `attendance_${lib.getFullName(myProfile)}_${moment().format('YYYYMMDD_HHmmss')}.jpg`
      const photo = await lib.toFile(this.state.srcPhoto, filename, 'image/jpeg')
      
      const data = new FormData()
      data.append('photo', photo)
      data.append('attend_type', this.attendType)

      if (this.props.user.email !== this.myProfile.email)
        data.append('user', this.props.user.id)

      if (this.state.coords) {
        data.append('latitude', this.state.coords.latitude)
        data.append('longitude', this.state.coords.longitude)
      }

      request({
        method: 'post',
        urlKey: 'create-userAttend',
        data,
        onSuccess: this.attendSuccess,
        onFailed: this.attendFailed,
      })
    })
  }

  warningSkipFace = () => {
    return new Promise(resolve => {
      Modal.confirm({
        title: `Continue Check in/out without face validation?`,
        icon: <SolutionOutlined
          style={{
            color: '#5e5e5e',
            fontSize: this.mutableScreen.isMobile ? '62px' : '112px'
          }} />,
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      })
    })
  }

  attendSuccess = async (response) => {
    this.props.reload()

    let skip = false

    if (response.data.status === 'unvalidated') {
      const failedCount = this.state.failedCount + 1
      if (failedCount > 2)
        skip = await this.warningSkipFace()

      if (!skip) {
        message.info('Face is not recognized, please read the guide on the side and try to capture again', 5)
        return this.setThisState({
          isLoading: false,
          isCamera: true,
          srcPhoto: null,
          failedCount,
        })
      }
    }

    if (skip)
      message.warning('Check in/out has been saved as unvalidated, please check later', 5)
    else
      message.success('Photo attendance has been registered, please check the status on the List')

    this.hide(null, undefined, true)
  }

  attendFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.setThisState({ isLoading: false, failedCount: this.state.failedCount + 1 })
  }

  switchCamera = () => {
    // const currentIndex = this.state.devicesCamera.findIndex(device => device.deviceId === this.state.selectedCamera)
    // const nextIndex = (currentIndex + 1) % this.state.devicesCamera.length
    // this.setThisState({ selectedCamera: this.state.devicesCamera[nextIndex].deviceId })
    this.setThisState({ facingMode: this.state.facingMode === 'user' ? 'environment' : 'user'})
  }

  async didMount() {
    let devicesCamera = []
    try {
      const devices = await navigator.mediaDevices.enumerateDevices()
      devicesCamera = devices.filter(device => device.kind === 'videoinput')
    } catch(error) {}

    this.setThisState({ devicesCamera })
  }

  renderContent = () => {
    this.useLocation = permissions.useGpsAttend(this.props.user)

    return (
      <Spin spinning={this.state.isLoading}>
        {this.state.devicesCamera.length > 1 && (
          <Row style={{ marginBottom: '6px' }}>
            <Button block icon={<InteractionOutlined />} onClick={this.switchCamera}>
              Switch Camera
            </Button>
          </Row>
        )}
        <Row>
          <Col xs={24} sm={24} lg={14}>
            <Card bodyStyle={{ padding: '6px 6px 0 6px' }}>
              {this.state.isCamera ? (
                <Webcam
                  ref={this.onRefWebcam}
                  mirrored
                  forceScreenshotSourceSize
                  screenshotFormat='image/jpeg'
                  width='100%'
                  height='100%'
                  videoConstraints={{
                    width: window.screen.width,
                    height: window.screen.height,
                    facingMode: this.state.facingMode,
                  }}
                />
              ) : (
                <img
                  src={this.state.srcPhoto}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    paddingBottom: '6px',
                  }}
                />
              )}
            </Card>
          </Col>
          <Col xs={24} sm={24} lg={10}>
            <div style={{ marginLeft: '12px', paddingBottom: '36px', height: '100%', position: 'relative' }}>
              <h2>
                Attention
              </h2>
              <ul>
                <li>Make sure your face is facing the camera</li>
                <li>Take off your mask, glasses and other face covering</li>
                <li>Position the camera in good lighting</li>
                <li>Make sure the face taken is the face of the user who is currently logged in</li>
                {this.useLocation && (
                  <>
                    <li>Your company require to record your location</li>
                    <li>When you need to get high accuracy, please attend with your phone or use high speed wifi</li>
                  </>
                )}
              </ul>

              <ul>
                {this.state.errorMessage && (
                  <li style={{ color: '#ff4d4f' }}>
                    <div>
                      {this.state.errorMessage}.
                      {this.state.errorMessage === 'User denied Geolocation' && (
                        <span>
                          {' '}
                          Please allow location to attend today.
                          <br />
                        </span>
                      )}
                      {this.state.errorMessage && (
                        <span
                          onClick={this.setLocation}
                          style={{
                            color: '#1890ff',
                            cursor: 'pointer',
                            display: 'inline-block',
                            marginLeft: '4px',
                          }}
                        >
                          Retry
                        </span>
                      )}
                    </div>
                  </li>
                )}
                {this.state.failedCount > 2 && (
                  <li style={{ color: '#ff4d4f' }}>
                    <span style={{ display: 'inline-block', marginRight: '4px' }}>
                      Is your photo already registered in <b>Facial Photo</b>?
                    </span>
                    <Link to='/attendance/facial-photo/'>
                      Open Facial Photo
                    </Link>
                  </li>
                )}
              </ul>

              <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                {this.state.isCamera ? (
                  <Button block loading={this.state.checkingLocation} type='primary' icon={<PlaySquareOutlined />} onClick={this.onCapture}>
                    Capture
                  </Button>
                ) : (
                  <Button block loading={this.state.checkingLocation} icon={<CameraOutlined />} onClick={this.onActivateCamera}>
                    Recapture
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Spin>
    )
  }
}

export default AttendDialog