import { get } from 'lodash'

import ManagerQAProductionDetail from 'src/pages/DashboardApp/DashboardManager/QAProductionDetail'

class QAProductionDetail extends ManagerQAProductionDetail {
  constructor(props) {
    super(props)

    this.allowExport = false

    const productionColumn = this.columns.find(column => column.title === this.KEY_TITLE_PRODUCTION)
    productionColumn.children = productionColumn.children.filter(column => column.dataIndex !== this.KEY_INDEX_AMOUNT)

    this._userIds = props.userIds

    this.columns[2].children = []
    this.columns[3].children = []
    this.initState({
      // data: this.getData(props.productionJobs.filter(job => props.userIds.includes(get(job.user_qa, 'id')))),
      data: this.getData(props.productionJobs),
    })

    // this.columns[2].children = []
    // this.columns[3].children = []
    // this.insertGradeColumns(props.productionJobs)
  }

  getPointBonusProps = () => ({ allowBonus: false })
  getProductionJobsData = (productionJobs) => productionJobs.filter(job => this._userIds.includes(get(job.user_qa, 'id')))

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.userIds !== nextProps.userIds) {
      this._userIds = nextProps.userIds
      this.setData(nextProps.productionJobs)
    } else if (this.props.productionJobs !== nextProps.productionJobs) {
      this.setData(nextProps.productionJobs)
    }
  }
}

export default QAProductionDetail