import React from 'react'
import { Collapse, Row, Table, Tooltip } from 'antd'
import { BookOutlined } from '@ant-design/icons'
import { groupBy, get } from 'lodash';

import { BaseReport } from '../components'
import { BarAnim } from 'src/components'
import { lib } from 'src/Utils'

const { Panel } = Collapse

class TimeUseReport extends BaseReport {
  constructor(props) {
    super(props)

    this.title = 'Time Use Report'
    this.topTasks = []
    this.totalWorked = 0

    this.keysQueryParam.push('related')
    this.related = 'task,note'

    this.columns = [
      {
        dataIndex: '.',
        title: 'No.',
        width: '40px',
        align: 'center',
        render: (_, __, i) => {
          if(!__.notes) return <BookOutlined />
          return i + 1
        },
      },
      {
        dataIndex: 'name',
        title: 'Task Name',
        width: '200px',
        ellipsis: true,
        sorter: (a, b) => a.name > b.name ? 1 : -1,
        onCell: (record) => ({ title: (record.notes ? '' : 'Note: ') + record.name })
      },
      {
        dataIndex: 'total',
        title: 'Total Time',
        width: '110px',
        align: 'right',
        sorter: (a, b) => a.total - b.total,
        render: (val) => lib.time.getLabel(val, true),
      },
      {
        dataIndex: '_',
        title: '',
        width: '150px',
        render: (_, record) => {
          return (
            <Tooltip
              placement='left'
              overlay={`${record.name} - ${lib.time.getNumberTime(record.total)}`}
            >
              <div>
                <BarAnim
                  className=''
                  width={this.getBarWidth(record.total)}
                />
              </div>
            </Tooltip>
          )
        },
      }
    ]
  }

  convertResponseData = (userActivities)=>{
    this.setDefaultMaxTotal()

    // for(const j of userActivities ){
      const data = []
      let totalWorked = 0
      const taskGroup = groupBy(userActivities, (e)=>e.task.name)
      for(const i in taskGroup){
        let totalTasktime = 0
        const groupNote = groupBy(taskGroup[i], (r)=>{
          if(r.note) return r.note.note
          return 'No note'
        })
        const notes = []
        for(const w in groupNote){
          let total = 0
          for(const t in groupNote[w]){
            total += lib.time.getDiff(groupNote[w][t])
          }
          totalTasktime+=total
          notes.push({ name: w, alias: get(groupNote[w], '[0].note.alias', 'No Note') , total })
        }
        notes.sort((a, b) => b.total - a.total)
        data.push(
          { 
            name: get(taskGroup[i], '[0].task.name'), 
            alias: i,
            total: totalTasktime,
            notes
          }
        )
        totalWorked+=totalTasktime
      }
      data.sort((a, b) => b.total - a.total)
      // j.topTasks = data
      // j.totalWorked = totalWorked

      this.topTasks = data
      this.totalWorked = totalWorked

      this.updateMaxTotal(data, false)
    // }
    return userActivities
  }


  getBodyContent = () => {
    const selectedUsers = this.getSelectedUsers()

    return (
      <div>
        {selectedUsers.length > 0 && (
          <Collapse activeKey={selectedUsers.map(user => user.alias)}>
            {selectedUsers.map(user => (
              <Panel key={user.alias} header={this.getUserTitle(user)} className='panel-report'>
                {/* <div style={{ fontSize: '21px', padding: '12px' }}>
                  Total Worked: <b>{lib.time.getLabel(user.totalWorked, true)}</b>
                </div> */}
                <Row justify='space-between'>
                  <div style={{ fontSize: '20px', padding: '12px' }}>
                    Total Worked: <b>{lib.time.getLabel(this.totalWorked, true)}</b>
                  </div>
                  {!this.mutableScreen.isMobile && this.getDateRangeContent()}
                </Row>
                <Table
                  bordered
                  size='small'
                  rowKey={(e)=>e.alias}
                  columns={this.columns}
                  expandRowByClick={true}
                  // rowClassName='row-tw-use'
                  childrenColumnName='notes'
                  // dataSource={user.topTasks}
                  dataSource={this.topTasks}
                  pagination={false}
                  scroll={this.scrollConfig}
                  onRow={(record) => record.notes ? { style: {cursor: 'pointer', fontWeight: 500, backgroundColor: '#fbfbfb'} } : null}
                />
              </Panel>
            ))}
          </Collapse>
        )}
      </div>
    )
  }
}

export default TimeUseReport
