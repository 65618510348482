const dummy = 'api/profile/company/companies/'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  'oauth-token': 'o/token/',
  'oauth-revoke': 'o/revoke_token/',
  'read-displayModule': 'api/display/role/module/',
  'read-companies': 'api/profile/company/companies/',
  'change-companies': 'api/profile/company/companies/{}/',
  'read-myAccount': 'api/profile/account/user-profile/',         // GET, PUT, PATCH
  'read-myUser': 'api/dashboard/company/user-profile/',          // GET, PUT, PATCH
  'read-projects': 'api/dashboard/project/projects/',            // GET, POST
  'change-projects': 'api/dashboard/project/projects/{}/',       // PUT, PATCH, DELETE
  'read-projects-autocomplete': 'api/dashboard/project/projects/autocomplete/',
  'read-permanentTasks': 'api/dashboard/project/tasks/',         // GET, POST
  'change-permanentTasks': 'api/dashboard/project/tasks/{}/',    // PUT, PATCH, DELETE
  'read-companyUsers': 'api/dashboard/company/users/',           // GET
  'change-companyUsers': 'api/dashboard/company/users/{}/',      // PUT, PATCH
  'read-companyRoles': 'api/dashboard/company/roles/',           // GET
  'read-usersManagedMe': 'api/dashboard/company/managed/',       // GET
  'read-readableUsers': 'api/dashboard/company/managed/readable-users/',                      // GET
  'read-readableUsersScreenshot': 'api/dashboard/company/managed/readable-users-screenshot/', // GET
  'read-roles': 'api/dashboard/role/roles/',                     // GET
  'read-activities': 'api/dashboard/activity/activities/',       // GET
  'create-manualActivities': 'api/dashboard/activity/manual-activities/',                // POST
  'read-userRequestActivities': 'api/dashboard/activity/user-request-activities/',
  'read-requestActivities': 'api/dashboard/activity/request-activities/',                // GET
  'accept-requestActivities': 'api/dashboard/activity/request-activities/{}/accept/',    // POST
  'reject-requestActivities': 'api/dashboard/activity/request-activities/{}/reject/',    // POST
  'cancel-requestActivities': 'api/dashboard/activity/request-activities/{}/cancel/',    // POST

  // v2
  'v2-read-activities': 'api/v2/dashboard/activity/activities/',

  // 'read-dashboardSummary': 'api/report/dashboard-summary/',
  // 'read-timesheet': 'api/report/timesheet/',
  // 'read-mostTaskUsage': 'api/report/top-task-usage/',
  // 'read-mostAppsUsage': 'api/report/top-apps-usage/',

  'read-productionTargets': 'api/dashboard/reward/production-targets/', // ?date=2021-01-01
  'change-productionTargets': 'api/dashboard/reward/production-targets/{}/', // GET, PUT, PATCH, DELETE
  'read-productionTargets-byDate': 'api/dashboard/reward/production-targets/date/', // ?date=2021-01-01
  'sync-productionTargets': 'api/dashboard/reward/production-targets/{}/sync/',
  'read-productionTargetsUsers': 'api/dashboard/reward/user-targets/',

  // allow user read point another user
  'read-productionTargetsDashboard': 'api/dashboard/reward/dash-production-targets/',
  'read-productionJobsDashboard': 'api/dashboard/reward/dash-production-jobs/',
  'change-productionJobsDashboard': 'api/dashboard/reward/dash-production-jobs/{}/',

  'read-usersProductionJobs': 'api/dashboard/reward/user-production-jobs/',
  'read-productionJobs': 'api/dashboard/reward/production-jobs/', // finished_date_after, finished_date_before
  // 'retrieve-productionJobs': 'api/dashboard/reward/production-jobs/{}/',
  'export-productionJobs': 'api/dashboard/reward/production-jobs/export/',
  'resync-productionJobs': 'api/dashboard/reward/production-jobs/resync/',

  'read-productionJobsQS': 'api/dashboard/reward/production-jobs-qs/',
  'export-productionJobsQS': 'api/dashboard/reward/production-jobs-qs/export/',

  'read-userPointTypes': 'api/dashboard/reward/user-point-types/',
  'read-effectiveExchange': 'api/dashboard/reward/point-exchanges/effective/',

  'read-companyTeams': 'api/dashboard/company/teams/',
  'change-companyTeams': 'api/dashboard/company/teams/{}/',
  'read-userTeams': 'api/dashboard/company/teams/user-teams/',

  'read-attendancePhotos': 'api/dashboard/attendance/user-attendance-photos/',
  'bulk-create-attendancePhotos': 'api/dashboard/attendance/user-attendance-photos/register/',
  'read-userAttendance': 'api/dashboard/attendance/user-attendances/',
  'create-userAttend': 'api/dashboard/attendance/user-attendances/attend/',

  // Leave
  'leave-balances': 'api/dashboard/leave/balances/',
  'leave-user-balances': 'api/dashboard/leave/user-balances/',
  'leave-types-autocomplete': 'api/dashboard/leave/leave-types/autocomplete/',
  'leave-user-leaves': 'api/dashboard/leave/user-leaves/',
  'leave-user-leaves-autocomplete': 'api/dashboard/leave/user-leaves/autocomplete/',
  'leave-user-leaves-detail': 'api/dashboard/leave/user-leaves/{}/',
  'leave-user-leaves-detail-cancel': 'api/dashboard/leave/user-leaves/{}/cancel/',
  'leave-leaves': 'api/dashboard/leave/leaves/',
  'leave-leaves-detail': 'api/dashboard/leave/leaves/{}/',
  'leave-leaves-detail-confirm': 'api/dashboard/leave/leaves/{}/confirm/',
  'leave-leaves-detail-revert-confirm': 'api/dashboard/leave/leaves/{}/revert-confirm/',
  'leave-balance-histories': 'api/dashboard/leave/balance-histories/',

  // Overtime
  'overtime-user-overtimes': 'api/dashboard/overtime/user-overtimes/',
  'overtime-user-overtimes-detail': 'api/dashboard/overtime/user-overtimes/{}/',
  'overtime-user-overtimes-cancel': 'api/dashboard/overtime/user-overtimes/{}/cancel/',
  'overtime-overtimes': 'api/dashboard/overtime/overtimes/',
  'overtime-overtimes-detail': 'api/dashboard/overtime/overtimes/{}/',
  'overtime-overtimes-detail-confirm': 'api/dashboard/overtime/overtimes/{}/confirm/',
  'overtime-overtimes-detail-revert-confirm': 'api/dashboard/overtime/overtimes/{}/revert-confirm/',
  'overtime-overtimes-detail-validate': 'api/dashboard/overtime/overtimes/{}/work-validate/',
  'overtime-overtimes-detail-revert-validate': 'api/dashboard/overtime/overtimes/{}/revert-work-validate/',
  'overtime-overtimes-detail-convert': 'api/dashboard/overtime/overtimes/{}/convert-balance/',
  'overtime-overtimes-detail-revert-convert': 'api/dashboard/overtime/overtimes/{}/revert-convert-balance/',

  // working sheet
  'office-woking-sheet': 'api/dashboard/office/monthly-work-days/',
  'office-working-duration': 'api/dashboard/office/user-work-durations/',
  'office-working-duration-detail': 'api/dashboard/office/user-work-durations/{}/',

  'timezones': 'api/dashboard/zone/timezones/',

  'change-myUser': dummy,
  'change-myUserPassword': dummy,
  'read-dashboardSummary': dummy,
  'read-timesheet': dummy,
  'read-mostTaskUsage': dummy,
  'read-mostAppsUsage': dummy,
  // 'read-permanentTasks': dummy,
}