import React from 'react'
import propTypes from 'prop-types'
import { Col, Empty, Input, Row, Spin, Tooltip } from 'antd'
import { Pie } from 'ant-design-pro/lib/Charts'
import { get } from 'lodash'

import { BarAnim, BaseComponent } from 'src/components'
import { CardHeader } from 'src/pages/DashboardApp/Components'
import { lib } from 'src/Utils'

const colors = ['#3aa1ff', '#36cbcb', '#4ecb73', '#fbd437', '#f2637b']

class BasePoint extends BaseComponent {
  constructor(props) {
    super(props)

    // Override this properties
    this.title = ''
    this.columns = []
    this.a = ''
    this.b = ''
    this.averagePerDay = 0
    this.averagePerJob = 0
    // ------------------------
    this.childComponentWillReceiveProps = this.childComponentWillReceiveProps.bind(this)
    this.childDidMount = this.childDidMount.bind(this)
    // ------------------------

    this.totalValue = 0

    this.state = {
      localLoading: false,
      localTarget: props.target,
      dataSource: [],
    }
  }

  // Override this function
  mappingXY = (record) => ({})
  convertData = () => {}
  getTitle = () => <>{this.title}: {this.getTotalValue()}</>
  getTotalValue = () => this.totalValue
  // ----------------------

  getExtra = () => `${this.props.target}`

  getProgressPct = () => {
    let progressPct = this.props.target ? this.totalValue / this.props.target : 0
    if (progressPct > 1)
      progressPct = 1

    return progressPct
  }

  getLabelPct = (pct) => `${Number(pct * 100).toFixed(2)} %`

  onChangeLocalTarget = (e) => {
    this.setThisState({ localTarget: e.target.value })
  }

  onSaveTarget = (event) => {
    const numberLocalTarget = isNaN(Number(this.state.localTarget)) ? this.props.target : Number(this.state.localTarget)

    this.setThisState({ localTarget: numberLocalTarget }, () => {
      if (this.state.localTarget === this.props.target)
        return this.onTargetSaved(event)

      // this.saveTarget(event)

      this.saveTarget(event)
      // this.blurTarget(event)
      // this.setThisState({ localLoading: true }, () => {
      //   this.props.patchTarget(
      //     { [this.props.fieldNamePoint]: this.state.localTarget },
      //     () => {
      //       // this.onTargetSaved(event)
      //       this.setThisState({ localLoading: false })
      //     }
      //   )
      // })
    })
  }

  saveTarget = (event) => {
    if (this.state.localLoading)
      return

    this.setThisState({ localLoading: true }, () => {
      this.blurTarget(event)
      this.props.patchTarget(
        { [this.props.fieldNamePoint]: this.state.localTarget },
        () => {
          // this.onTargetSaved(event)
          this.setThisState({ localLoading: false })
        }
      )
    })




    // this.setThisState({ localLoading: true }, () => {
    //   request({
    //     method: 'patch',
    //     urlKey: 'change-productionTargets',
    //     args: [this.props.targetAlias],
    //     data: {
    //       [this.props.fieldNamePoint]: this.state.localTarget,
    //     },
    //     onSuccess: this.saveTargetSuccess,
    //     onFailed: this.saveTargetFailed,
    //     extra: { event },
    //   })
    // })
  }

  saveTargetSuccess = (response, extra) => {
    this.onTargetSaved(extra.event, this.state.localTarget)
  }

  saveTargetFailed = (error) => {
    lib.responseMessage.error(error.response)
  }

  onTargetSaved = (event, newTarget) => {
    if (typeof get(event, ['target', 'blur']) === 'function')
      event.target.blur()

    this.setThisState({ localLoading: false })
    // if (this.state.localTarget !== newTarget)
    //   this.props.changeTarget(newTarget)
  }

  blurTarget = (event) => {
    if (typeof get(event, ['target', 'blur']) === 'function')
      event.target.blur()
  }

  onKeyDownLocalTarget = (event) => {
    if (event.keyCode === 27) {
      this.setThisState({ localTarget: this.props.target }, () => {
        this.blurTarget(event)
      })
    }
  }

  childComponentWillReceiveProps() {}
  childDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.productionJobs !== nextProps.productionJobs) {
      this.convertData(nextProps.productionJobs, nextProps)
    }

    if (this.props.target !== nextProps.target) {
      this.setThisState({ localTarget: nextProps.target })
    }

    this.childComponentWillReceiveProps(nextProps)
  }

  didMount() {
    if (this.props.productionJobs)
      this.convertData(this.props.productionJobs)

    this.childDidMount()
  }

  render() {
    const { dataSource } = this.state

    const progressPct = this.getProgressPct()

    return (
      <Spin spinning={this.state.localLoading}>
        <CardHeader
          // title={`${this.title}: ${this.totalValue}`}
          className={this.headerClassName}
          title={this.getTitle()}
          extra={
            <div title={this.props.labelTarget}>
              {this.props.readOnly ? (
                <span>
                  {this.props.prefixTarget}
                  {this.props.target}
                </span>
              ) :
                <Tooltip title={get(this.inputTargetProps, 'title')} trigger={['focus']}>
                <Input
                  size='small'
                  type='number'
                  value={this.state.localTarget}
                  onChange={this.onChangeLocalTarget}
                  onBlur={this.onSaveTarget}
                  onPressEnter={this.onSaveTarget}
                  onKeyDown={this.onKeyDownLocalTarget}
                  {...this.inputTargetProps}
                  style={{
                    border: 'none',
                    width: '80px',
                    textAlign: 'right',
                    // paddingRight: '2px',
                    padding: '2px',
                    // boxShadow: '0 0 0 2px rgb(255 77 79 / 20%)',
                    ...get(this.inputTargetProps, 'style')
                  }}
                />
                </Tooltip>
              }
            </div>
          }
        />
        <Row>
          <Col className='container-pie-chart only-xs-block'>
            <Pie
              colors={colors}
              width={130}
              height={140}
              data={dataSource.map(this.mappingXY)}
            />
          </Col>
          <Col style={{ flex: 1 }}>
            <div className='ant-table-wrapper table-small' style={{ maxHeight: '150px', overflowY: 'auto' }}>
              <div className='ant-table ant-table-bordered' style={{ overflowX: 'auto' }}>
                <div className='ant-table-container'>
                  <div className='ant-table-content'>
                    <table>
                      {dataSource.length > 0 && (
                        <tbody className='ant-table-tbody'>
                          {dataSource.map((record) => (
                            <tr key={record.grade} className='ant-table-row'>
                              {this.columns.map((column) => (
                                <td key={column.dataIndex} style={{ padding: '2px 4px', fontSize: '11px', ...column.style }}>
                                  {column.render ? column.render(record[column.dataIndex], record) : record[column.dataIndex]}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                    {!dataSource.length && (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Tooltip title={this.getLabelPct(progressPct)} placement='left'>
          <div style={{ margin: '8px 0' }}>
            <BarAnim width={`${progressPct * 100}%`} className='bar-outside bar-outside-sm' />
          </div>
        </Tooltip>
        <Row justify='space-between'>
          <Col>
            {this.a} :
            &nbsp;
            <span style={{ fontWeight: 500, fontSize: '18px', lineHeight: '14px' }}>
              {isNaN(this.averagePerDay) ? '-' : this.averagePerDay}
            </span>
          </Col>
          <Col>
            {this.b} :
            &nbsp;
            <span style={{ fontWeight: 500, fontSize: '18px', lineHeight: '14px' }}>
              {isNaN(this.averagePerJob) ? '-' : this.averagePerJob}
            </span>
          </Col>
        </Row>
      </Spin>
    )
  }
}

BasePoint.propTypes = {
  fieldNamePoint: propTypes.string,
}

BasePoint.defaultProps = {
  labelTarget: 'Target',
  changeTarget: () => {},
}

export default BasePoint