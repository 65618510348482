import PropTypes from 'prop-types'

import BaseComponent from './BaseComponent'

class VisibilityContainer extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      mounted: props.visible || props.forceRender,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.destroyOnClose || (!this.props.visible && nextProps.visible)) {
      this.setState({ mounted: nextProps.visible })
    }
  }

  render() {
    const { visible, forceRender, destroyOnClose, ...p } = this.props

    return !!this.state.mounted && (
      <div
        {...p}
        style={{
          ...this.props.style,
          // display: visible ? undefined : 'none',
          ...(!visible && { display: 'none' }),
        }}
      />
    )
  }
}

VisibilityContainer.propTypes = {
  visible: PropTypes.bool,
  forceRender: PropTypes.bool,
  destroyOnClose: PropTypes.bool,
}

VisibilityContainer.defaultProps = {
  visible: false,
  forceRender: false,
  destroyOnClose: false,
}

export default VisibilityContainer