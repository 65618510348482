import PropTypes from 'prop-types'

import DashboardProductionDetailFinished from './Finished'
// import ManagerProductionDetailInProgress from 'src/pages/DashboardApp/DashboardManager/JobProgressDetail'

class ProductionDetailInProgress extends DashboardProductionDetailFinished {
  constructor(props) {
    super(props)

    this.title = 'In-Progress'
    this.showExport = false
    this.resyncFeature = false

    this.initializeColumns(false)
    this.setFilteredColumns()

    const mustFinishColumn = this.columns.find(column => column.dataIndex === 'must_finish_date')
    if (mustFinishColumn)
      mustFinishColumn.defaultSortOrder = 'ascend'
  }
}

ProductionDetailInProgress.propTypes = {
  ...ProductionDetailInProgress.propTypes
}

ProductionDetailInProgress.defaultProps = {
  ...ProductionDetailInProgress.defaultProps,
}

export default ProductionDetailInProgress