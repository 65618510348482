import React from 'react'
import { BackTop } from 'antd'
import { VerticalAlignBottomOutlined } from '@ant-design/icons'

import { BaseComponent } from 'src/components'

const MAX_HEIGHT = 1000

class ScrollEnd extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      showButton: true, // this.getShowButton(),
    }
  }

  getShowButton = () => {
    return document.body.scrollHeight - (window.scrollY + window.innerHeight) > MAX_HEIGHT
  }

  onScroll = () => {
    this.setThisState({
      showButton: this.getShowButton(),
    })
  }

  onScrollDown = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    this.onScroll()
  }

  didMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  unmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  render() {
    return this.state.showButton && (
      <div className='ant-back-top' style={{ right: '100px', bottom: '80px' }} onClick={this.onScrollDown}>
        <div>
          <div className='fade-enter fade-enter-active fade ant-back-top-content ant-back-top-content-hovered'>
            <div className='ant-back-top-icon'>
              <VerticalAlignBottomOutlined />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ScrollEnd