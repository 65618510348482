import React from 'react'
import { Button, Col, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { BaseComponent } from 'src/components'
import CardUser from './CardUser'

class FieldUsers extends BaseComponent {
  getValue = () => this.props.value || []

  onRemoveUser = (user) => {
    // array of alias
    const remainingSelectedUsers = this.getValue().filter(userAlias => userAlias !== user.alias)
    this.props.onChange(remainingSelectedUsers)
  }

  onEdit = (users) => {
    this.props.showSelectUser({
      fieldKey: 'users',
      excludeUsers: users.map(user => user.alias),
      contentProps: {
        multiple: true,
        isManager: false,
      },
    })
  }

  render() {
    const { users } = this.props
    const selectedUsers = users.filter(user => this.getValue().findIndex(userAlias => userAlias === user.alias) !== -1)

    return (
      <Row gutter={[12, 12]}>
        {selectedUsers.map(user => (
          <Col key={user.alias} xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <CardUser
              user={user}
              onRemove={this.onRemoveUser}
            />
          </Col>
        ))}
        {selectedUsers.length < users.length && (
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <Button
              ghost
              type='dashed'
              size='large'
              icon={<PlusOutlined style={{ fontSize: '20px' }} />}
              style={{ borderColor: '#9c9c9c', color: '#9c9c9c', width: '100%', height: '160px' }}
              onClick={() => this.onEdit(selectedUsers)}
            />
          </Col>
        )}
      </Row>
    )
  }
}

export default FieldUsers