import PropTypes from 'prop-types';
import { Button, Col, Collapse, DatePicker, Radio, Row, Segmented, Select, Spin, Table } from 'antd';
import { CalendarOutlined, CheckOutlined, ChromeOutlined, CloseCircleFilled, CloseOutlined, FilterOutlined, HistoryOutlined, PieChartOutlined, UnorderedListOutlined } from '@ant-design/icons';
import moment from 'moment';
import classNames from 'classnames';

import { BaseReport } from 'src/pages/DashboardApp/Reports/components';
import { VisibilityContainer } from 'src/components';
import StaffLeaveCalendar from './Calendar';
import UserBalance from './UserBalance';
import DialogLeaveDetail from './DialogDetail';
import DialogOvertimeDetail from 'src/pages/DashboardApp/Submission/OvertimeTimesheet/DialogDetail';
import { lib, request } from 'src/Utils';
import _h from 'src/pages/DashboardApp/Submission/helper';

const { RangePicker } = DatePicker

class YearlyStaffLeave extends BaseReport {
  constructor(props) {
    super(props)

    this.DEFAULT_RANGE_TYPE = 'year'
    this.DEFAULT_RANGE_FORMAT = 'YYYY-MM'

    this.title = 'Staff Leave'
    this.urlKey = 'leave-user-leaves'
    // this.keysQueryParam = ['id', 'date_after', 'date_before', 'view_mode', 'status']
    this.keysQueryParam.push('view_mode', 'status', 'related')
    this.scrollConfig = { x: '720px' }
    this.stickyConfig = this.mutableScreen.isMobile ? false : { offsetHeader: '45px' }
    this.myProfile = lib.ls.getCurrentUser()

    this.setRangeDate({
      start_date: this.objParam.start_date || moment().startOf('year').format(),
      end_date: this.objParam.end_date || moment().endOf('year').format(),
    })

    this.related = 'user'
    this.view_mode = 'calendar'
    this.status = 'approved,pending'
    this.statuses = [
      { key: '', label: 'All' },
      { key: 'approved,pending', label: 'Apprv+Pend' },
      { key: 'approved', label: 'Approved' },
      { key: 'rejected', label: 'Rejected' },
      { key: 'pending', label: 'Pending' },
      { key: 'cancelled', label: 'Cancelled' },
    ]

    // const getTaken = (userLeave) => _h.isAborted(userLeave) ? 0 : _h._leave.nums(userLeave)
    const getTaken = (userLeave) => _h.isAborted(userLeave) ? 0 : userLeave.day_count
    this.columns = [
      {
        title: 'Taken',
        // dataIndex: ['_taken'],
        dataIndex: ['day_count'],
        align: 'right',
        width: '60px',
        sorter: (a, b) => lib.sorter.byItem(getTaken(a), getTaken(b)),
        render: (val, record) => {
          const taken = getTaken(record)
          return (
            <span className={classNames({ 'approved-note': taken > 0, 'c-error': taken < 0 })}>
              {taken > 0 && '+'}
              {taken ?
                (Math.floor(taken * 100) / 100).toFixed(2)
                : '-'}
            </span>
          )
        }
      },
      {
        title: 'Start Date',
        dataIndex: ['start_date'],
        sorter: (a, b) => lib.sorter.byItem(a, b, 'start_date'),
        render: (val) => moment(val).format('DD MMM YYYY'),
        // render: (val, record) => {
        //   const formattedDate = moment(val).format('DD MMM YYYY')

        //   return (!this.myProfile.is_owner || !this.isWorkingSheet(record)) ? formattedDate : (
        //     <a
        //       href={`/report/working-sheet/?id=${record.user_id}&start_date=${record.start_date}&end_date=${record.start_date}`}
        //       target='_blank'
        //       rel='noopener noreferrer'
        //       className='link link-blue'
        //     >
        //       {formattedDate}
        //     </a>
        //   )
        // },
      },
      {
        title: 'Finish Date',
        dataIndex: ['finish_date'],
        sorter: (a, b) => lib.sorter.byItem(a, b, 'finish_date'),
        render: (val) => moment(val).format('DD MMM YYYY'),
      },
      {
        title: 'Type',
        dataIndex: ['leave_type', 'name'],
        sorter: (a, b) => lib.sorter.byItem(a, b, ['leave_type', 'name']),
        render: (val, record) => {
          if (this.isWorkingSheet(record)) {
            return !this.myProfile.is_owner ? val : (
              <a
                href={`/report/working-sheet/?id=${record.user_id}&start_date=${record.start_date}&end_date=${record.start_date}`}
                target='_blank'
                rel='noopener noreferrer'
                className='link link-blue'
                title='Open the Working Sheet'
              >
                {val}
              </a>
            )
          }

          return (
            <span>
              <span>{val}</span>
              {record.day_type === 'half' && (
                <span style={{ color: 'rgba(0, 0, 0, 0.3)', fontSize: '12px', fontWeight: 500, marginLeft: '12px' }}>
                  <span style={{ marginRight: '4px' }}>
                    {record.day_type === 'half' ? <PieChartOutlined /> : <ChromeOutlined />}
                  </span>
                  <span>
                    {record.day_type_display}
                  </span>
                </span>
              )}
            </span>
          )
        }
      },
      // {
      //   title: 'Day Type',
      //   dataIndex: ['day_type_display'],
      //   sorter: (a, b) => lib.sorter.byItem(a, b, 'day_type_display'),
      //   render: (val, record) => {
      //     return (
      //       <span style={{ color: record.day_type === 'half' ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.7)', fontSize: '12px', fontWeight: 500 }}>
      //         <span style={{ marginRight: '4px' }}>
      //           {record.day_type === 'half' ? <PieChartOutlined /> : <ChromeOutlined />}
      //         </span>
      //         <span>
      //           {val}
      //         </span>
      //       </span>
      //     )
      //   }
      // },
      // {
      //   title: 'Reason',
      //   dataIndex: ['note'],
      // },
      {
        title: 'Status',
        dataIndex: ['status_display'],
        sorter: (a, b) => lib.sorter.byItem(a, b, 'status_display'),
        render: (val, record) => this.isWorkingSheet(record) ? (
          <span className='font-weight-semibold approved-note'>
            Converted
          </span>
        ) : (
          <span
            className={classNames('font-weight-semibold', {
              'c-error': _h.isRejected(record),
              'c-warning': _h.isCancelled(record),
              'approved-note': _h.isApproved(record),
              'c-gray': _h.isWaiting(record),
            })}
          >
            {val}
          </span>
        ),
      },
      {
        title: 'Confirmed by',
        dataIndex: ['confirmed_by'],
        render: (value) => lib.getFullName(value),
      },
      // {
      //   title: 'Confirm Note',
      //   dataIndex: ['confirmed_note'],
      //   render: (value) => value || '-',
      // },
      {
        title: 'Detail',
        dataIndex: '_detail',
        width: '80px',
        render: (_, record) => record.source_type === 'user_apply' && (
          <span
            className='link-blue'
            onClick={() => this.showDetail(record)}
            // jika expandRowByClick
            // onClick={(e) => {
            //   e.stopPropagation()
            //   this.showDetail(record)
            // }}
          >
            Show
          </span>
        ),
      },
    ]

    this.state = {
      ...this.state,
      viewType: 'list',
      isLoadingOvertime: false,
    }
  }

  onRefCalendar = (ref) => this._calendar = ref
  onRefDialogDetail = (ref) => this._dialogDetail = ref
  onRefDialogDetailOvertime = (ref) => this._dialogDetailOvertime = ref

  isWorkingSheet = (leave) => leave.source_type === 'work_duration'

  checkRange = () => true

  getKey = (key) => key === 'range_after' ? 'date_after'
    : key === 'range_before' ? 'date_before'
    // : key === 'id' ? 'user'
    : key

  getParam = (key, val) => {
    if (key === 'range_before')
      return moment(this.range_before).startOf('month').format('YYYY-MM-DD')
    if (key === 'range_after')
      return moment(this.range_after).endOf('month').format('YYYY-MM-DD')
    // if (key === 'id')
    //   return this.users.map(user => user.id).join(',')
    if (key === 'user')
      return this.id

    return val
  }

  isViewList = () => this.state.viewType === 'list'
  // isViewCalendar = () => !this.isViewList()

  showDetail = (userLeave) => {
    this._dialogDetail.show({ userLeave })
  }

  showDetailOvertime = (overtimeId) => {
    this.setState({ isLoadingOvertime: true }, () => {
      request({
        urlKey: 'overtime-user-overtimes-detail',
        args: [overtimeId],
        onSuccess: this.readOvertimeSuccess,
        onFailed: this.readOvertimeFailed,
      })
    })
  }

  readOvertimeSuccess = (res) => {
    this._dialogDetailOvertime.show({ userOvertime: res.data })
    this.setState({ isLoadingOvertime: false })
  }

  readOvertimeFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.setState({ isLoadingOvertime: false })
  }

  reload = (keyLoading, callback, force) => {
    this.page = 1
    this.read(keyLoading, callback, force)
    if (this._calendar) {
      this._calendar.reload()
      this._calendar._dialogListUser.hide() // di hide karena reload tidak merubah data di dialog
    }
  }

  onChangeStatus = (status) => {
    this.status = status
    this.doRead(this.keyIsLoading)
  }

  getFilterInput = () => (
    <Col className='only-xs-block'>
      <Row gutter={[6, 6]}>
        <Col className='only-xs-block'>
          {/* <Segmented
            block
            value={this.state.viewType}
            options={[
              { value: 'calendar', label: 'Calendar', icon: <CalendarOutlined /> },
              { value: 'list', label: 'List', icon: <UnorderedListOutlined /> },
            ]}
            style={{ minWidth: '200px' }}
            onChange={viewType => this.setState({ viewType })}
          /> */}
          <Radio.Group value={this.state.viewType} buttonStyle='solid' onChange={e => this.setState({ viewType: e.target.value })}>
            <Radio.Button value='calendar' icon={<CalendarOutlined />}>
              Calendar
            </Radio.Button>
            <Radio.Button value='list' icon={<UnorderedListOutlined />}>
              List
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col className="only-xs-block">
          <Select
            defaultValue={this.status}
            style={{ minWidth: '120px', width: '100%' }}
            suffixIcon={<FilterOutlined />}
            menuItemSelectedIcon={<FilterOutlined />}
            onChange={this.onChangeStatus}
          >
            {this.statuses.map(status => (
              <Select.Option key={status.label} value={status.key}>
                {status.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col className='only-xs-block'>
          <VisibilityContainer visible={this.isViewList()}>
            <RangePicker
              allowClear={false}
              picker='month'
              ranges={{
                'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
                'This Year': [moment().startOf('year'), moment().endOf('year')],
              }}
              style={{ width: '100%' }}
              defaultValue={[moment(this.range_before), moment(this.range_after)]}
              onChange={(dates, dateStrings) => {
                this.tempDateStrings = dateStrings
              }}
              onOpenChange={(opened) => {
                if (!opened) {
                  if (this.tempDateStrings[0] !== this.range_before || this.tempDateStrings[1] !== this.range_after)
                    this.onChangeFilter({ start_date: this.tempDateStrings[0], end_date: this.tempDateStrings[1] })
                } else {
                  this.tempDateStrings = [this.range_before, this.range_after]
                }
              }}
            />
          </VisibilityContainer>
        </Col>
      </Row>
    </Col>
  )

  getSelectedUsers = () => this.users.filter(user => String(this.id) === String(user.id))

  getDateRangeContent = () => (
    <Col style={{ padding: '6px 12px' }}>
      <div style={{ fontSize: '12px', color: '#cdcdcd' }}>
        Selected Range
      </div>
      <div style={{ fontSize: '18px', fontWeight: 500 }}>
        {moment(this.range_before).startOf('month').format('MMMM')}
        {' – '}
        {moment(this.range_after).endOf('month').format('MMMM')}
      </div>
    </Col>
  )

  renderAnotherContent = () => {
    return (
      <div className='report-dialogs'>
        <DialogLeaveDetail
          ref={this.onRefDialogDetail}
          reload={this.reload}
        />
        <DialogOvertimeDetail ref={this.onRefDialogDetailOvertime} />
      </div>
    )
  }

  renderUsersContainer = () => (
    <VisibilityContainer visible={this.isViewList()}>
      {this.renderUsers()}
    </VisibilityContainer>
  )

  renderConvertedOvertime = (staffLeave, index, indent, expanded) => (
    <Spin spinning={this.state.isLoadingOvertime}>
      <div style={{ fontSize: '16px', fontWeight: 500, marginBottom: '12px', textAlign: 'center' }}>
        Converted Overtimes to Leave Balance
      </div>
      <Table
        bordered
        showSorterTooltip={false}
        size='small'
        rowKey='pk'
        pagination={false}
        columns={[
          { title: 'Balance', dataIndex: ['balance_history', 'day_count'], align: 'right', className: 'approved-note', render: val => '+ ' + val },
          { title: 'Date', dataIndex: ['date'], render: (val, record) => <span className='link link-blue' onClick={() => this.showDetailOvertime(record.pk)}>{val}</span> },
          { title: 'Time Schedule', dataIndex: ['_schedule'], render: (_, record) => _h._overtime.renderRange(record) },
          { title: 'Holiday', dataIndex: ['is_holiday'], align: 'center', render: (val) => val
            ? <CheckOutlined className='approved-note' />
            : <CloseOutlined className='c-error' /> },
        ]}
        dataSource={staffLeave.converted_overtimes}
        summary={(currentData) => {
          return (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell key='Balance' align='right' className='approved-note'>
                  + {currentData.reduce((prev, val) => prev + val.balance_history.day_count, 0)}
                </Table.Summary.Cell>
                <Table.Summary.Cell key='Date' />
                <Table.Summary.Cell key='Time Schedule' />
                <Table.Summary.Cell key='Holiday' />
              </Table.Summary.Row>
            </Table.Summary>
          )
        }}
      />
    </Spin>
  )

  getBodyContent = () => {
    const selectedUsers = this.getSelectedUsers()
    return (
      <div>
        <VisibilityContainer visible={this.isViewList()}>
        {this.users.length > 0 && (
          <Collapse activeKey={selectedUsers.map(user => user.alias)}>
            {selectedUsers.map(user => (
              <Collapse.Panel key={user.alias} header={this.getUserTitle(user)} className='panel-report'>
                <Row justify='space-between'>
                  <Col>
                    <UserBalance
                      userId={this.id}
                    />
                  </Col>
                  <div style={{ fontSize: '20px', padding: '12px' }}>
                    {/* Total Worked: <b>{lib.time.getLabel(user.totalWorked, true)}</b> */}
                  </div>
                  {!this.mutableScreen.isMobile && this.getDateRangeContent()}
                </Row>
                <Table
                  bordered
                  showSorterTooltip={false}
                  size='small'
                  rowKey='pk'
                  columns={this.columns}
                  // dataSource={user.topApps}
                  dataSource={this.__list}
                  pagination={false}
                  scroll={this.scrollConfig}
                  sticky={this.stickyConfig}
                  expandable={{
                    // showExpandColumn: false,
                    // expandRowByClick: true,
                    defaultExpandAllRows: true,
                    rowExpandable: (record) => record.converted_overtimes.length > 0,
                    expandedRowRender: this.renderConvertedOvertime,
                  }}
                />
              </Collapse.Panel>
            ))}
          </Collapse>
        )}
        </VisibilityContainer>
        <VisibilityContainer visible={!this.isViewList()}>
          <StaffLeaveCalendar
            ref={this.onRefCalendar}
            urlKey={this.urlKey}
            users={this.users}
            status={this.status}
            showDetail={this.showDetail}
          />
        </VisibilityContainer>
    </div>
    )
  }
}

YearlyStaffLeave.propTypes = {
  ...YearlyStaffLeave.propTypes,
}

YearlyStaffLeave.defaultProps = {
  ...YearlyStaffLeave.defaultProps,
}

export default YearlyStaffLeave