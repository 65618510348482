import PropTypes from 'prop-types'
import { Avatar, Dropdown, Menu, Popover } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { get } from 'lodash';

import { BaseComponent } from 'src/components'
import { lib } from 'src/Utils'

class ProfileContainer extends BaseComponent {
  constructor(props) {
    super(props)

    this.myProfile = lib.ls.getCurrentUser()

    this.state = {
      visible: false,
    }
  }

  onSelectMenu = ({ item }) => {
    this.props.onSelectUser(item.props.user)
    this.onVisibleChange(false)
  }

  getUserList = () => (
    <Menu selectedKeys={[get(this.props.selectedUser, 'email')]}>
      {this.props.users.map(user => (
        <Menu.Item
          key={user.email}
          user={user}
          onClick={this.onSelectMenu}
        >
          <Avatar
            size={24}
            src={user.photo}
            icon={<UserOutlined style={{ fontSize: '14px', margin: 0 }} />}
            style={{ marginRight: '6px' }}
          />
          <span style={{ fontWeight: 500, display: 'inline-block', maxWidth: '200px' }}>
            {lib.getFullName(user)}
          </span>
          {this.myProfile.email === user.email && ' (you)'}
        </Menu.Item>
      ))}
    </Menu>
  )

  onVisibleChange = (visible) => {
    this.setThisState({ visible })
  }

  render() {
    const { children, users } = this.props

    return users.length < 2 ? children : (
      <Popover
        visible={this.state.visible}
        placement='rightTop'
        trigger={['click']}
        content={this.getUserList()}
        overlayClassName='popover-user-attendance'
        onVisibleChange={this.onVisibleChange}
      >
        {children}
      </Popover>
    )
  }
}

ProfileContainer.propTypes = {
  selectedUser: PropTypes.object,
  onSelectUser: PropTypes.func,
}

ProfileContainer.defaultProps = {
  selectedUser: null,
  onSelectUser: () => {},
}

export default ProfileContainer