import { BaseComponent } from 'src/components'

class LoadMoreListener extends BaseComponent {
  onScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop + 70 >= document.scrollingElement.scrollHeight) {
      this.props.loadMore()
    }
  }

  didMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  unmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  render() {return null}
}

export default LoadMoreListener