import NumberFormat from 'react-number-format'
import { get } from 'lodash'

import BasePoint from './BasePoint'
import { lib } from 'src/Utils'

class JobByGrade extends BasePoint {
  constructor(props) {
    super(props)

    this.title = 'Job by Grade'
    this.a = 'Average Job / day'
    this.b = 'Average Job / QS'
    this.inputTargetProps = {
      title: 'Target Total Job',
    }
    this.columns = [
      { dataIndex: 'grade' },
      { dataIndex: 'total_jobs', style: {textAlign: 'right'}, },
      {
        dataIndex: 'pct',
        style: {textAlign: 'right'},
        render: val => this.getLabelPct(val)
      },
    ]
  }

  getTotalValue = () => (
    <span>
      <NumberFormat
        thousandSeparator
        displayType='text'
        value={Number(this.totalValue)}
      />
    </span>
  )

  mappingXY = (record) => ({ x: record.grade, y: record.total_jobs })

  convertData = (productionJobs, nextProps) => {
    const dataSource = []

    this.totalValue = 0
    for (let i = 0; i < productionJobs.length; i += 1) {
      this.totalValue += 1
      const foundIndex = dataSource.findIndex(item => item.grade === productionJobs[i].grade)

      const prevDataSource = dataSource[foundIndex]

      dataSource.splice(
        !prevDataSource ? dataSource.length : foundIndex,
        !prevDataSource ? 0 : 1,
        {
          grade: productionJobs[i].grade,
          total_jobs: Number(get(prevDataSource, 'total_jobs', 0)) + 1,
        }
      )
    }

    for (let i = 0; i < dataSource.length; i += 1)
      dataSource[i].pct = this.totalValue ? dataSource[i].total_jobs / this.totalValue : dataSource[i].total_jobs

    dataSource.sort((a, b) => b.pct - a.pct)

    this.setAverage(productionJobs, nextProps)

    this.setThisState({ dataSource })
  }

  setAverage = (productionJobs) => {
    const prodDays = [], qs = []
    let totalDays = 0, totalQS = 0

    for (const i in productionJobs) {
      if (prodDays.indexOf(productionJobs[i].prod_day) === -1) {
        totalDays += 1
        prodDays.push(productionJobs[i].prod_day)
      }

      if (qs.indexOf(lib.getFullName(productionJobs[i].user)) === -1) {
        totalQS += 1
        qs.push(lib.getFullName(productionJobs[i].user))
      }
    }

    this.averagePerDay = Math.round(this.totalValue / totalDays)
    this.averagePerJob = Math.round(this.totalValue / totalQS)
  }
}

export default JobByGrade