import moment from 'moment'

import { request } from 'src/Utils'
import getMonths from './getMonths'

async function readPointTypes(startDate, endDate, body) {
  return new Promise((resolve) => {
    const objPointTypes = {}

    const months = getMonths(startDate, endDate)
    let requestCount = 0, errorResponse = null

    const returnWhenFinish = () => {
      if (requestCount === months.length) {
        resolve({ objPointTypes, errorResponse })
      }
    }

    for (let i = 0; i < months.length; i += 1) {
      request({
        urlKey: 'read-userPointTypes',
        data: { date: moment(months[i]).startOf('month').format('YYYY-MM-DD'), ...body },
        onSuccess: response => {
          requestCount += 1
          objPointTypes[months[i]] = response.data

          returnWhenFinish()
        },
        onFailed: error => {
          requestCount += 1
          errorResponse = error.response

          returnWhenFinish()
        }
      })
    }
  })
}

export default readPointTypes