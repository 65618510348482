import React from 'react'
import { Col, Row, Tabs } from 'antd'

import { BaseComponent } from 'src/components'
import { lib, permissions } from 'src/Utils'
import ContentProjects from './Projects'

const { TabPane } = Tabs

class ProjectTasks extends BaseComponent {
  constructor(props) {
    super(props)

    this.myProfile = lib.ls.getCurrentUser()
    this.readOnly = !permissions.canManageProject(this.myProfile)
  }

  onRefActive = (ref) => {
    this._active = ref
  }

  onRefArchived = (ref) => {
    this._archived = ref
  }

  switchArchived = (project, prevArchived) => {
    if (prevArchived) this.activate(project)
    else this.archive(project)
  }

  archive = (project) => {
    if (this._archived)
      this._archived.patchList([project])
  }

  activate = (project) => {
    if (this._active)
      this._active.patchList([project])
  }

  render() {
    return (
      <div style={{ margin: '24px' }}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Row justify='space-between'>
              <Col>
                <h1>
                  Projects and Permanent Tasks
                </h1>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Tabs type='card' className='tabs-pnt'>
              <TabPane key='active' tab='Active'>
                <ContentProjects
                  ref={this.onRefActive}
                  readOnly={this.readOnly}
                  archived={false}
                  // archive={this.archive}
                  switchArchived={this.switchArchived}
                />
              </TabPane>
              <TabPane key='archived' tab='Archived'>
                <ContentProjects
                  ref={this.onRefArchived}
                  readOnly={this.readOnly}
                  archived
                  // activate={this.activate}
                  switchArchived={this.switchArchived}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
    )
  }
}

export default ProjectTasks