import React from 'react'
import { Avatar, Button, Card, Col, DatePicker, Dropdown, Input, Menu, Row, Spin } from 'antd'
import { ArrowLeftOutlined, CaretDownOutlined, CarryOutOutlined, DashboardOutlined, FieldTimeOutlined, FileDoneOutlined, FileOutlined, HistoryOutlined, ReloadOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons'
import moment from 'moment'
import classNames from 'classnames'

import { BaseListActivity } from 'src/components'
import { lib, request } from 'src/Utils'

const { RangePicker } = DatePicker

class BaseReport extends BaseListActivity {
  constructor(props) {
    super(props)

    // user config
    this.users = []
    this.pageUser = 1
    this.limitUser = 100
    this.nextPageUser = null

    // activity config
    // this.id = undefined (must undefined, don't declare or initial with another value)
    this.urlKey = 'v2-read-activities'
    this.keysQueryParam = ['range_before', 'range_after', 'user']
    this.id = this.objParam.id
    this.MIN_TOTAL = 1
    this.totalWorked = 0
    this.msTolerance = 1500
  
    this.title = ''
    this.columns = []
    this.scrollConfig = { x: '860px' }
    this.containerProps = null
    this.myProfile = lib.ls.getCurrentUser()
    this.showSearchUser = false

    this.state = {
      ...this.state,
      searchValue: '',
    }
  }

  getParam = (key, val) => key === 'user' ? this.id : val

  getSelectedUsers = () => this.users.filter(user => String(this.id) === String(user.id))

  reload = (keyLoading, callback, force) => {
    this.page = 1
    this.read(null, callback, force)
  }

  beforeRead = async (keyLoading, callback) => {
    return new Promise((resolve) => {
      this.pageUser = 1
      this.users = []
      // this.readUsers(keyLoading, resolve, callback)
      this.teams = []
      this.readTeams(keyLoading, resolve, callback)
    })
  }

  readTeams = (keyLoading = 'isLoading', resolve = () => {}, callback = () => {}) => {
    this.setThisState({ [keyLoading]: true }, async () => {
      const res = await lib.loadAllMembers()
      if (res.error) {
        this.readTeamsFailed(res.error, { resolve, callback })
      } else {
        this.users = lib.sorter.byArray(res.members, 'first_name')
        const myUserIndex = this.users.findIndex(user => user.id === this.myProfile.id)
        if (myUserIndex !== -1) {
          const myUser = this.users.splice(myUserIndex, 1)[0]
          this.users.unshift(myUser)
        }

        let user = null
        if (!this.id) {
          user = this.users.find(user => user.email === this.myProfile.email)
        } else {
          user = this.users.find(user => `${user.id}` === `${this.id}`)
        }

        if (user) this.id = user.id

        resolve()
      }
    })
  }

  readTeamsFailed = (error, extra) => {
    lib.responseMessage.error(error.response)

    this.setThisState({ isLoading: false, errorResponse: error.response })
    extra.resolve(true)
    if (extra.callback) extra.callback()
  }

  readUsers = (keyLoading = 'isLoading', resolve = () => {}, callback = () => {}) => {
    // Cancel request users and user activities
    this.cancelUsers()
    this.cancel()

    this.setThisState({ [keyLoading]: true }, () => {
      request({
        urlKey: 'read-usersManagedMe',
        data: { page: this.pageUser, limit: this.limitUser },
        extra: { resolve, callback },
        getCancel: this.setCancelUsers,
        onSuccess: this.readUsersSuccess,
        onFailed: this.readUsersFailed,
      })
    })
  }

  setCancelUsers = (cancel) => {
    this.cancelTokenUsers = cancel
  }

  cancelUsers = () => {
    if (typeof this.cancelTokenUsers === 'function') {
      this.cancelTokenUsers()
    }
  }

  readUsersSuccess = (response, extra) => {
    this.nextPageUser = response.data.next

    this.users = this.users.concat(response.data.results)
    if (response.data.next) {
      this.pageUser += 1
      this.readUsers(undefined, extra.resolve)
    } else {
      let user = null
      if (!this.id) {
        user = this.users.find(user => user.email === this.myProfile.email)
      } else {
        user = this.users.find(user => `${user.id}` === `${this.id}`)
      }

      // this.setThisState({ selectedUser: user ? user.id : response.data.results[0].id }, () => {
        if (user) this.id = user.id
        else if (response.data.results.length) {
          this.id = response.data.results[0].id
        }

        extra.resolve()
      // })
    }
  }

  readUsersFailed = (error, extra) => {
    lib.responseMessage.error(error.response)

    this.setThisState({ isLoading: false, errorResponse: error.response })
    extra.resolve(true)
    if (extra.callback) extra.callback()
  }

  getBarWidth = (totaltime = 0) => {
    let pct = totaltime / this.maxTotal

    if (pct > 1) pct = 1
    else if (pct && pct < 0.01) pct = 0.01

    return `${pct * 100}%`
  }

  goHome = () => {
    this.props.history.push(
      '/' +
      lib.getStrParam({
        start_date: moment(this.range_before).format('YYYY-MM-DD'),
        end_date: moment(this.range_after).format('YYYY-MM-DD'),
        id: this.id,
      })
    )
  }

  onChangeSearch = (e) => {
    clearTimeout(this._timeoutSearch)

    this._timeoutSearch = setTimeout(() => {
      this.setThisState({ searchValue: e.target.value })
    }, 350)
  }

  getReportList = () => {
    return (
      <Menu
        selectedKeys={[this.props.location.pathname]}
        onClick={this.onMenuClick}
      >
        <Menu.ItemGroup key='activity' title='Activity'>
          <Menu.Item key='/report/notes/' icon={<FileOutlined />}>
            Activity Notes Report
          </Menu.Item>
          <Menu.Item key='/report/app-usage/' icon={<FileOutlined />}>
            App Usage Report
          </Menu.Item>
          <Menu.Item key='/report/time-use/' icon={<FileOutlined />}>
            Time Use Report
          </Menu.Item>
          <Menu.Item key='/report/timeline/' icon={<FileOutlined />}>
            Timeline Daily Report
          </Menu.Item>
          {this.myProfile.is_owner && (
            <Menu.Item key='/report/working-sheet/' icon={<FileOutlined />}>
              Working Sheet Report
            </Menu.Item>
          )}
          <Menu.Item key='/report/yearly-report/' icon={<FileOutlined />}>
            Yearly Report
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup key='overtime' title='Overtime'>
          <Menu.Item key='/report/monthly-timesheet/' icon={<CarryOutOutlined />}>
            Monthly Timesheet Report
          </Menu.Item>
          <Menu.Item key='/report/overtime-timesheet/' icon={<FieldTimeOutlined />}>
            Overtime Sheet
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup key='leave' title='Staff Leave'>
          <Menu.Item key='/report/yearly-staff-leave/' icon={<FileDoneOutlined />}>
            Staff Leave
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    )
  }

  onMenuClick = ({ key }) => {
    this.props.history.push(
      key +
      lib.getStrParam({
        start_date: this.objParam.start_date,
        end_date: this.objParam.end_date,
        id: this.id,
      })
    )
  }

  renderHeader = () => (
    <Row className={!this.mutableScreen.isMobile && 'sticky-report-header'} justify='space-between' gutter={[12, 12]} style={{ marginBottom: '12px' }}>
      <Col>
        <Row align='middle'>
          <Col>
            <div className='ant-page-header-back'>
              <div className='ant-page-header-back-button' onClick={this.goHome} title='Go to Dashboard'>
                <ArrowLeftOutlined />
              </div>
            </div>
          </Col>
          <Col>
            <Dropdown trigger={['click']} overlay={this.getReportList()}>
              <h1 style={{ cursor: 'pointer', marginBottom: 0 }}>
                {this.title}
                <CaretDownOutlined style={{ fontSize: '14px', marginLeft: '6px' }} />
              </h1>
            </Dropdown>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row align='middle' gutter={[6, 6]}>
          {this.users.length > 1 && this.showSearchUser && (
            <Col style={{ flex: 1 }}>
              <Input
                placeholder='Search user...'
                prefix={<SearchOutlined className='site-form-item-icon' />}
                onChange={this.onChangeSearch}
              />
            </Col>
          )}
          <Col className='only-xs-block'>
            <Button block loading={this.state.isLoading} icon={<ReloadOutlined />} onClick={this.reload}>
              Refresh
            </Button>
          </Col>
          {this.getFilterInput()}
        </Row>
      </Col>
    </Row>
  )

  getPrevParams = () => lib.getObjParam(this.props.location.search)

  getFilterInput = () => (
    <Col className='only-xs-block'>
      <RangePicker
        allowClear={false}
        format='YYYY-MM-DD'
        ranges={{
          'Today': [moment(), moment()],
          'This Week': [moment().startOf('week'), moment().endOf('week')],
          'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
        }}
        style={{ width: '100%' }}
        value={[moment(this.range_before), moment(this.range_after)]}
        onChange={(dates, dateStrings) => {
          this.tempDateStrings = dateStrings
        }}
        onOpenChange={(opened) => {
          if (!opened) {
            if (this.tempDateStrings[0] !== this.range_before || this.tempDateStrings[1] !== this.range_after)
              this.onChangeFilter({ start_date: this.tempDateStrings[0], end_date: this.tempDateStrings[1] })
          } else {
            this.tempDateStrings = [this.range_before, this.range_after]
          }
        }}
      />
    </Col>
  )

  gerUserDisplay = (user) => {
    if (lib.getFullName(user).toLowerCase().indexOf(this.state.searchValue.trim().toLowerCase()) !== -1)
      return 'block'

    if (user.email.toLowerCase().indexOf(this.state.searchValue.trim().toLowerCase()) !== -1)
      return 'block'

    return 'none'
  }

  onChangeSelectedUser = (user) => {
    // break when current selected user equal with clicked user
    if (`${this.id}` === `${user.id}`)
      return

    this.cancel()
    this.onChangeFilterNew({ id: user.id })
    // this.id = user.id
    // this.setThisState({ selectedUser: user.id })
    // this.doRead(this.keyIsLoading, () => {})
  }

  onChangeFilterNew = (objParams) => {
    const mergeObjParams = { ...this.objParam, ...objParams }
    const filteredObjParams = {}
    for (const key in mergeObjParams) {
      if (this.allowedParams.findIndex(param => param === key) !== -1)
        filteredObjParams[key] = mergeObjParams[key]
    }

    this.props.history.replace(this.props.location.pathname + lib.getStrParam(filteredObjParams))
  }

  onChangeId = (id) => {
    this.id = id
    this.doRead(this.keyIsLoading, () => {})
  }

  renderUsersContainer = () => this.renderUsers()

  renderUsers = () => (
    this.users.length > 1 && (
      <div style={{ paddingBottom: '12px', backgroundColor: '#f0f2f5' }}>
        <Row gutter={[12, 12]} style={{ flexFlow: 'nowrap', overflowX: 'auto' }}>
          {this.users.map((user, i) => (
            <Col key={i} style={{ display: this.gerUserDisplay(user) }}>
              <Card
                className={classNames(
                  'app-card app-card-selection',
                  {active: `${this.id}` === `${user.id}`}
                )}
                bodyStyle={{ padding: '12px' }}
                style={{ cursor: 'pointer', width: '250px' }}
                onClick={() => this.onChangeSelectedUser(user)}
              >
                <Row justify='center'>
                  <Avatar
                    src={user.photo}
                    icon={<UserOutlined />}
                    size={72}
                    style={{
                      color: '#fff',
                      // border: '1px solid #e8e8e8',
                      boxShadow: '0 6px 16px -8px RGB(0 0 0/8%)',
                    }}
                  />
                </Row>
                <div
                  className='webkit-box two-lines'
                  style={{ height: '44px', textAlign: 'center', marginTop: '12px' }}
                >
                  {lib.getFullName(user)}
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    )
  )

  getUserTitle = (user) => {
    return (
      <Row align='middle'>
        <Col>
          <Avatar
            icon={<UserOutlined />}
            src={user.photo}
            size={28}
            style={{ marginRight: '8px' }}
          />
        </Col>
        <Col style={{ fontSize: '18px', fontWeight: 500  }}>
          {lib.getFullName(user)}
        </Col>
      </Row>
    )
  }

  renderContent = () => (
    <Card className='app-card' bodyStyle={{ padding: 0 }}>
      {(!this.state.errorResponse || this.state.isLoading)
        ? this.getBodyContent()
        : this.getErrorComp()
      }
    </Card>
  )

  getBodyContent = () => null

  renderAnotherContent = () => {}

  getDateRangeContent = () => (
    <Col style={{ padding: '6px 12px' }}>
      <div style={{ fontSize: '12px', color: '#cdcdcd' }}>
        Selected Range
      </div>
      <div style={{ fontSize: '18px', fontWeight: 500 }}>
        {moment(this.range_before).format('MMM DD')}
        {' – '}
        {moment(this.range_after).format('MMM DD')}
      </div>
    </Col>
  )

  unmount() {
    this.cancel()
    this.cancelUsers()
  }

  render() {
    window.konsole.log('Rendered', this)
    return (
      <div style={{ padding: '24px' }} {...this.containerProps}>
        <Spin spinning={this.state.isLoading}>
          {this.renderHeader()}
          {this.renderUsersContainer()}
          {this.renderContent()}
          {this.renderAnotherContent()}
        </Spin>
      </div>
    )
  }
}

export default BaseReport