import React from 'react'
import { Button, Card, Col, Row, Select, Tabs } from 'antd'
import { ArrowLeftOutlined, FilterOutlined, ReloadOutlined } from '@ant-design/icons'
import { set } from 'lodash';
import classNames from 'classnames'

import { BaseList } from 'src/components'
import { lib, request } from 'src/Utils'

// List by filter
import ListAll from './All'
import ListReview from './Review'
import ListAccepted from './Accepted'
import ListRejected from './Rejected'
// ------------------------------

const { TabPane } = Tabs

class RequestTimeReview extends BaseList {
  constructor(props) {
    super(props)

    this.urlKey = 'read-requestActivities'
    this.limit = 25
    this.ordering = '-start_time'
    this.status = 'request_review'
    this.statuses = [
      { key: '', label: 'All' },
      { key: 'accepted', label: 'Accepted' },
      { key: 'rejected', label: 'Rejected' },
      { key: 'request_review', label: 'Review' },
      // { key: 'cancelled', label: 'Cancelled' },
    ]

    this.keysQueryParam.push('state', 'ordering')
  }

  onRefContent = (ref) => this._content = ref

  getParam = (key, val) => key === 'state'
    ? this.status
    : val

  reload = (keyLoading, callback, force) => {
    set(this._content, 'scrollTop', 0)

    this.page = 1
    this.read(this.keyIsLoading, undefined, true)
  }

  readAnotherPages = (parentResolve, parentData = []) => {
    return new Promise((resolve) => {
      if (!parentResolve) parentResolve = resolve

      request({
        urlKey: this.urlKey,
        data: this.getParams(),
        extra: { callback: () => {
          this.setState({
            [this.keyIsLoadMore]: false,
            [this.keyIsLoading]: false,
          })
        }},
        getCancel: this.setCancel,
        onSuccess: (response) => {
          this.setCancel(null)
          this.next = response.data.next

          if (this.next) {
            this.page += 1
            this.readAnotherPages(parentResolve, parentData.concat(response.data.results))
          } else
            parentResolve(parentData.concat(response.data.results))
        },
        onFailed: this.readFailed,
      })
    })
  }

  // convertResponseData = async (responseData) => {
  //   const list = [...responseData.results]

  //   if (this.next) {
  //     this.page += 1
  //     list.push(...await this.readAnotherPages())
  //     responseData.results = list
  //   }

  //   this.props.setCountReview(
  //     list.filter(activity => activity.state === 'request_review').length
  //   )

  //   return responseData
  // }

  onAccept = async (requestActivity) => {
    return new Promise(resolve => {
      this.feedbackRequest(requestActivity, true, { resolve })
    })
  }

  onReject = async (requestActivity) => {
    return new Promise(resolve => {
      this.feedbackRequest(requestActivity, false, { resolve })
    })
  }

  feedbackRequest = (requestActivity, isAccept, extra) => {
    this.setThisState({ isLoading: true }, () => {
      request({
        method: 'post',
        urlKey: isAccept ? 'accept-requestActivities' : 'reject-requestActivities',
        args: [requestActivity.alias],
        onSuccess: this.feedbackRequestSuccess,
        onFailed: this.feedbackRequestFailed,
        extra: { isAccept, ...extra }
      })
    })
  }

  feedbackRequestSuccess = (response, extra) => {
    if (extra && typeof extra.resolve === 'function') extra.resolve(true)
    this.reload()
    this.props.reload()
  }

  feedbackRequestFailed = (error, extra) => {
    if (extra && typeof extra.resolve === 'function') extra.resolve(false)
    lib.responseMessage.error(error.response)
    this.setThisState({ isLoading: false })
  }

  onChangeStatus = (status) => {
    this.status = status
    // this.forceRead()
    this.reload()
  }

  onScroll = (e) => {
    if (!this.state.isLoading)
      if ((e.target.scrollTop + e.target.clientHeight) >= e.target.scrollHeight)
        this.loadMore()
  }

  render() {
    window.konsole.log('Rendered', this)
    const cardBodyStyle = {
      padding: 0,
      overflowY: 'auto',
      height: this.mutableScreen.isMobile ? 'calc(100vh - 225px)' : 'calc(100vh - 145px)',
    }
    // const bodyHeight = this.mutableScreen.isMobile ? 'calc(100vh - 225px)' : 'calc(100vh - 145px)'

    return (
      <div>
        {/* Header */}
        <div>
          <Row justify='space-between' gutter={[12, 12]} style={{ marginBottom: '12px' }}>
            <Col>
              <Row align='middle'>
                <Col>
                  <div className='ant-page-header-back'>
                    <div className='ant-page-header-back-button' onClick={this.props.onClose} title='Go to Dashboard'>
                      <ArrowLeftOutlined />
                    </div>
                  </div>
                </Col>
                <Col>
                  <h1 style={{ marginBottom: 0 }}>
                    Review Request
                  </h1>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row align='middle' gutter={[12, 12]}>
                <Col className='only-xs-block'>
                  <Row align='middle' gutter={[6, 6]}>
                    <Col className='only-xs-block'>
                      <Button block loading={this.state.isLoading} icon={<ReloadOutlined />} onClick={() => this.reload()}>
                        Refresh
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Select
                    defaultValue={this.status}
                    style={{ width: '120px' }}
                    suffixIcon={<FilterOutlined />}
                    menuItemSelectedIcon={<FilterOutlined />}
                    onChange={this.onChangeStatus}
                  >
                    {this.statuses.map(status => (
                      <Select.Option key={status.label} value={status.key}>
                        {status.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        {/* Content */}
        {(this.state.errorResponse && !this.state.isLoading) ? this.getErrorComp() : (
          <div>
            <Tabs type='card' className='tabs-pnt' defaultActiveKey='review'>
              <TabPane key='all' tab={this.statuses.find(status => status.key === this.status).label}>
                <Card
                  ref={this.onRefContent}
                  className={classNames('card-all-submission', {mobile: this.mutableScreen.isMobile})}
                  onScroll={this.onScroll}
                >
                  <ListAll
                    list={this.__list}
                    isLoading={this.state.isLoading}
                    onAccept={this.onAccept}
                    onReject={this.onReject}
                    showDeleteActivity={this.props.showDeleteActivity}
                  />
                </Card>
              </TabPane>
              {/* <TabPane key='all' tab='All'>
                <Card bodyStyle={cardBodyStyle}>
                  <ListAll
                    list={this.__list}
                    isLoading={this.state.isLoading}
                    onAccept={this.onAccept}
                    onReject={this.onReject}
                    showDeleteActivity={this.props.showDeleteActivity}
                  />
                </Card>
              </TabPane>
              <TabPane key='review' tab='Review'>
                <Card bodyStyle={cardBodyStyle}>
                  <ListReview
                    list={this.__list}
                    isLoading={this.state.isLoading}
                    onAccept={this.onAccept}
                    onReject={this.onReject}
                    showDeleteActivity={this.props.showDeleteActivity}
                  />
                </Card>
              </TabPane>
              <TabPane key='accepted' tab='Accepted'>
                <Card bodyStyle={cardBodyStyle}>
                  <ListAccepted
                    list={this.__list}
                    isLoading={this.state.isLoading}
                    showDeleteActivity={this.props.showDeleteActivity}
                  />
                </Card>
              </TabPane>
              <TabPane key='rejected' tab='Rejected'>
                <Card bodyStyle={cardBodyStyle}>
                  <ListRejected
                    list={this.__list}
                    isLoading={this.state.isLoading}
                    showDeleteActivity={this.props.showDeleteActivity}
                  />
                </Card>
              </TabPane> */}
            </Tabs>
          </div>
        )}
      </div>
    )
  }
}

export default RequestTimeReview