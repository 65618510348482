import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import moment from 'moment';

import { BaseModal } from 'src/components';
import { lib, request } from 'src/Utils';
import _h from 'src/pages/DashboardApp/Submission/helper';
import OvertimeView from '../Overtime/DialogOvertimeDetail/View';

class DialogConvertBalance extends BaseModal {
  constructor(props) {
    super(props)

    this.title = 'Convert Overtime'

    this.state = {
      ...this.state,
      userOvertime: null,
      hours: null,
    }
  }

  getModalProps = () => ({
    centered: true,
    destroyOnClose: true,
    bodyStyle: { padding: 0 },
    width: 640,
    footer: (
      <Row justify="end" gutter={[12, 12]}>
        <Col>
          <Button type="primary" loading={this.state.isLoading} onClick={this.convert}>
            Convert
          </Button>
        </Col>
      </Row>
    ),
  })

  convert = () => {
    this.setState({ isLoading: true }, () => {
      request({
        method: 'post',
        urlKey: 'overtime-overtimes-detail-convert',
        args: [this.state.userOvertime.pk],
        data: { convert_duration: lib.time.getNumberTime(this.state.hours.ms).slice(0, 6) + '00' },
        onSuccess: this.convertSuccess,
        onFailed: this.convertFailed,
      })
    })
  }

  convertSuccess = (res) => {
    lib.responseMessage.success('Overtime has converted to Leave Balance')
    this.hide(null, this.props.reload, true)
  }

  convertFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.setState({ isLoading: false})
  }

  renderContent = () => {
    return this.state.hours && this.state.userOvertime && (
      <div>
        <OvertimeView
          readOnly
          // user={this.state.user}
          userOvertime={this.state.userOvertime}
        />
        <div className="ant-modal-body" style={{ paddingTop: 0 }}>
          <div className="approved-note" style={{ fontSize: '24px', textAlign: 'center' }}>
            {_h._overtime.renderDuration(this.state.hours.ms)}
          </div>
          <div style={{ color: 'rgba(0, 0, 0, 0.5)', fontWeight: 500, marginTop: '12px', textAlign: 'center' }}>
            The time above will be converted to <b>Leave Balance</b>.
          </div>
        </div>
      </div>
    )
  }
}

DialogConvertBalance.propTypes = {
  ...DialogConvertBalance.propTypes,
  reload: PropTypes.func,
}

DialogConvertBalance.defaultProps = {
  ...DialogConvertBalance.defaultProps,
  reload: () => {},
}

export default DialogConvertBalance