import React from 'react'
import { Card, Col, Row, Tooltip } from 'antd'
import { ArrowsAltOutlined, CalendarOutlined, CheckCircleFilled, CheckOutlined } from '@ant-design/icons'
import moment from 'moment'

import { BaseComponent } from 'src/components'

class Photo extends BaseComponent {
  onSelect = (event) => {
    event.stopPropagation()
    this.props.onSelect(this.props.photo)
  }

  onZoom = (event) => {
    event.stopPropagation()
    this.props.onZoom(this.props.photo)
  }

  render() {
    const { photo, selected, selectable } = this.props

    return (
      <Card
        // hoverable
        className='card-hovered card-attendance-photo'
        bodyStyle={{ padding: 0 }}
        style={{
          position: 'relative',
          border: selected ? '2px solid #52c41a' : '',
        }}
      >
        <img
          src={photo.photo}
          style={{
            cursor: 'zoom-in',
            width: '100%',
            height: '280px',
            objectFit: 'cover',
          }}
          onClick={this.onZoom}
        />

        <div
          style={{ padding: '12px' }}
          // onClick={this.onSelect}
        >
          <Row justify='space-between' align='middle' gutter={[6, 6]}>
            <Col>
              <div style={{ fontSize: '16px', fontWeight: 500 }}>
                {photo.is_register ? 'Registered' : 'Unregistered'}
              </div>
            </Col>
            {photo.is_verified && (
              <Col>
                <Tooltip placement='right' title='Photo has been verified'>
                  <Row align='middle' style={{ fontSize: '11px', color: 'green', cursor: 'default' }}>
                    <CheckOutlined style={{ marginRight: '6px' }} />
                    Verified
                  </Row>
                </Tooltip>
              </Col>
            )}
            {/* {photo.register_state_display === 'registered' && (
              <Col>
                <Row align='middle' style={{ fontSize: '11px', color: 'green' }}>
                  <CalendarOutlined style={{ marginRight: '6px' }} />
                  {moment(photo.registered_at).format('D MMM YYYY')}
                </Row>
              </Col>
            )} */}
          </Row>
        </div>

        {selectable && (
          <div
            onClick={this.onSelect}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: '12px 12px 0 0',
            }}
          >
            {selected ? (
              <CheckCircleFilled
                style={{
                  fontSize: '28px',
                  color: '#52c41a',
                  background: 'white',
                  borderRadius: '50%',
                }}
              />
            ) : (
              <span
                style={{
                  border: '1px solid #cccccc',
                  borderRadius: '50%',
                  width: '28px',
                  height: '28px',
                  background: 'rgba(255, 255, 255, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CheckOutlined
                  style={{
                    fontSize: '14px',
                    color: 'rgba(0, 0, 0, 0.2)',
                    // background: 'rgba(255, 255, 255, 0.5)',
                  }}
                />
              </span>
            )}
          </div>
        )}

        {/* <ArrowsAltOutlined
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
          }}
        /> */}
      </Card>
    )
  }
}

export default Photo