import React from 'react'
import { Menu } from 'antd'
import { MenuUnfoldOutlined } from '@ant-design/icons'

import menuRoutes from '../routesMenu'
import { lib } from 'src/Utils'

class MobileMenu extends React.PureComponent {
  constructor(props) {
    super(props)

    this.myProfile = lib.ls.getCurrentUser()
  }

  renderMenus = (menus) => {
    return menus
      .filter((menu) => menu.name && (menu.auth ? menu.auth(this.myProfile) : true))
      .filter((_, i) => i < 3)
      .map(this.renderMenu)
    // return menus.filter((_, i) => i < 3).map(this.renderMenu)
  }

  renderMenu = (menu) => {
    if (menu.menu) {
      return (
        <Menu.SubMenu key={menu.name} title={menu.name} icon={menu.icon}>
          {this.renderMenus(menu.menu)}
        </Menu.SubMenu>
      )
    }

    return (
      <Menu.Item key={menu.path} icon={menu.icon} onClick={this.props.onMenuClick}>
        {menu.name}
      </Menu.Item>

      // <Menu.Item key={menu.path} icon={menu.icon} />

      // <Menu.Item key={menu.path} icon={menu.icon}>
      //   <Link to={menu.path}>
      //     {menu.name}
      //   </Link>
      // </Menu.Item>
    )
  }

  render() {
    return (
      <div className='mobile-menu'>
        <Menu
          className='menu-icon-large menu-center'
          mode='horizontal'
          theme='dark'
          selectedKeys={[this.props.selectedKey]}
          // onClick={this.props.onMenuClick}
        >
          <Menu.Item key='more' icon={<MenuUnfoldOutlined />} onClick={this.props.toggleSider}>
            More
          </Menu.Item>
          {this.renderMenus(menuRoutes)}
          {/* {menuRoutes.map(menu => (
            <Menu.Item
              key={menu.path}
              icon={menu.icon}
            />
          ))} */}
        </Menu>
      </div>
    )
  }
}

export default MobileMenu