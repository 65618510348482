import React from 'react'
import { Avatar, Button, Card, Col, Form, Input, message, Row, Tag, Upload } from 'antd'
import { AuditOutlined, CameraFilled, CheckOutlined, KeyOutlined, RobotOutlined, RollbackOutlined, UserOutlined } from '@ant-design/icons'
import { get } from 'lodash'

import { BaseComponent } from 'src/components'
import { lib, request } from 'src/Utils'
import fields from './fields'
import ChangePassword from './ChangePassword'
import ManageUsers from './ManageUsers'
import Roles from './Roles'

class UserSettings extends BaseComponent {
  constructor(props) {
    super(props)

    this.reader = null

    this.formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    }

    this.myProfile = lib.ls.getCurrentUser()

    this.state = {
      data: this.myProfile,
      isSaving: false,
      thumbnail: this.myProfile.photo,
    }
  }

  getUserStatus = () => {
    if (!this.myProfile.is_owner && !this.myProfile.is_admin)
      return null

    return (
      <Col span={24} style={{ textAlign: 'center', fontStyle: 'normal' }}>
        {lib.getLevel(this.myProfile)}
      </Col>
    )
  }

  getInitialValue = (field) => {
    switch(field.type) {
      case 'upload': {
        return undefined
      }
      default: {
        return this.state.data[field.name] || field.initialValue
      }
    }
  }

  getInput = (field) => {
    switch(field.type) {
      case 'input': {
        return (
          <Input
            placeholder={field.placeholder || field.label}
            onPressEnter={this.onSave}
            {...field.inputProps}
          />
        )
      }
      case 'password': {
        return (
          <Input.Password
            placeholder={field.label}
            onPressEnter={this.onSave}
            {...field.inputProps}
          />
        )
      }
      default: {
        return null
      }
    }
  }

  onLoadReader = () => {
    this.setThisState({ thumbnail: get(this.reader, 'result') })
  }

  getInitialDataPhoto = () => {
    if (!this.state.data.photo) return undefined

    return [{
      uid: new Date().getTime(),
      name: this.state.data.photo,
      status: 'done',
      url: this.state.data.photo,
    }]
  }

  onChangeThumbnail = (e) => {
    this.reader = new FileReader()
    this.reader.onloadend = this.onLoadReader
    const file = get(e.fileList[e.fileList.length - 1], 'originFileObj')
    if (file) this.reader.readAsDataURL(file)
    else {
      this.reader = null
      this.onLoadReader()
    }

    return e && e.fileList
  }

  onFieldsTouched = () => {
    this.setFieldsTouched(true)
  }

  setFieldsTouched = (isFieldsTouched, state) => {
    this.setThisState({ ...state, isFieldsTouched })
  }

  revertToSaved = () => {
    this._form.resetFields()
    this.setFieldsTouched(false, { thumbnail: this.myProfile.photo })
  }

  onSave = () => {
    this._form.validateFields()
      .then(values => {
        if (this.state.isSaving) return

        this.setThisState({ isSaving: true }, () => {
          const data = new FormData()
          data.set('first_name', values.first_name)
          data.set('last_name', values.last_name)
          data.set('employee_id', values.employee_id)
          data.set('address1', values.address1)
          data.set('address2', values.address2)
          if (Array.isArray(values.thumbnail) && values.thumbnail.length > 0 && values.thumbnail[0].originFileObj) {
            data.set('photo', values.thumbnail[0].originFileObj)
          }

          request({
            method: 'patch',
            urlKey: 'read-myUser',
            data,
            extra: { data },
            onSuccess: this.saveSuccess,
            onFailed: this.saveFailed,
          })
        })
      })
  }

  saveSuccess = (response, extra) => {
    message.success('Your profile has been saved')
    lib.ls.setCurrentUser(response.data)
    this.myProfile = lib.ls.getCurrentUser()
    this.setFieldsTouched(false, {
      isSaving: false,
      data: response.data
    })
  }

  saveFailed = (error, extra) => {
    lib.responseMessage.error(error.response)
    this.setThisState({ isSaving: false })
  }

  render() {
    return (
      <div style={{ margin: '24px' }}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Row justify='space-between'>
              <Col>
                <h1>
                  User Settings
                </h1>
              </Col>
              {/* <Col>
                <Button type='primary' onClick={this.onSave}>
                  Save Settings
                </Button>
              </Col> */}
            </Row>
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <Card className='app-card' style={{ height: '100%' }}>
              <div>
                <Col span={24}>
                  <Row justify='space-between' gutter={[12, 12]}>
                    {/* <UserOutlined style={{ fontSize: '21px', marginRight: '8px' }} /> */}
                    <Col style={{ fontSize: '21px', fontWeight: 500 }}>
                      My Profile
                    </Col>
                    <Col style={{ flex: 1 }}>
                      <Row justify='end' gutter={[12, 12]}>
                        <Col className='only-xs-block'>
                          <Button block icon={<KeyOutlined />} onClick={() => this._changePassword.show()}>
                            Change Password
                          </Button>
                        </Col>
                        <Col className='only-xs-block'>
                          <Row gutter={[6, 6]}>
                            {this.state.isFieldsTouched && (
                              <Col style={{ flex: 1 }}>
                                <Button block danger icon={<RollbackOutlined />} onClick={this.revertToSaved}>
                                  Revert
                                </Button>
                              </Col>
                            )}
                            <Col style={{ flex: 1 }}>
                              <Button block loading={this.state.isSaving} type='primary' icon={<CheckOutlined />} onClick={this.onSave}>
                                Save
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <div style={{ marginTop: '12px' }}>
                  <Form ref={ref => {this._form = ref}} {...this.formItemLayout} onValuesChange={this.onFieldsTouched}>
                    {/* <Row>
                      <Col xs={24} sm={24} md={5} className='ant-form-item-label'>
                        <Avatar
                          icon={<UserOutlined />}
                          // src='https://s3.amazonaws.com/tds3_avatars/1773145.jpg?69'
                          src={this.state.thumbnail}
                          size={48}
                          style={{ border: '1px solid #e8e8e8', marginRight: '12px'  }}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={19}>
                        <Form.Item name='thumbnail' getValueFromEvent={this.onChangeThumbnail}>
                          <Upload className='w-100' beforeUpload={() => false} maxCount={1}>
                            <Button block type='primary'>Browse</Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row> */}

                    <Row justify='center' style={{ marginBottom: '24px', padding: '12px' }}>
                      <Col style={{ position: 'relative' }}>
                        <Avatar
                          icon={<UserOutlined />}
                          src={this.state.thumbnail}// || 'https://i.pinimg.com/originals/9d/06/87/9d0687e6f4f0ebaf3e6595e0ab070051.png'}
                          // size={128}
                          size={154}
                          style={{ border: '1px solid #e8e8e8'  }}
                        />
                        <Form.Item
                          name='thumbnail'
                          className='form-item-photo'
                          valuePropName='fileList'
                          getValueFromEvent={this.onChangeThumbnail}
                          initialValue={this.getInitialDataPhoto()}
                        >
                          <Upload beforeUpload={() => false} maxCount={1} itemRender={() => null} accept='image/*'>
                            <Row justify='center' align='middle' className='btn-take-photo'>
                              <CameraFilled />
                            </Row>
                          </Upload>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Row justify='center' style={{ fontSize: '18px', fontWeight: 500, fontStyle: 'italic', marginTop: '12px' }} title='My E-Mail account'>
                          {this.myProfile.email}
                          {this.getUserStatus()}
                        </Row>
                      </Col>
                    </Row>

                    {fields.map(field => (
                      <Form.Item
                        key={field.name}
                        name={field.name}
                        label={field.label}
                        {...field.formProps}
                        initialValue={this.getInitialValue(field)}
                      >
                        {this.getInput(field)}
                      </Form.Item>
                    ))}
                  </Form>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <Card className='app-card' style={{ height: '100%' }}>
              <div style={{ minHeight: '100px' }}>
                <Roles
                  roles={this.myProfile.roles}
                />
              </div>
            </Card>
          </Col>
          {this.myProfile.is_owner && (
            <Col span={24}>
              <Card className='app-card'>
                <ManageUsers />
              </Card>
            </Col>
          )}
        </Row>

        <ChangePassword
          ref={ref => {this._changePassword = ref}}
        />
      </div>
    )
  }
}

export default UserSettings