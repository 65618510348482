import PropTypes from 'prop-types';
import { Button, Col, Collapse, DatePicker, Row, Table, Tooltip } from 'antd';
import { InfoCircleOutlined, PullRequestOutlined, SaveOutlined } from '@ant-design/icons';
import { cloneDeep, get, groupBy, set } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import classNames from 'classnames';

import { BaseReport } from '../components';
import { lib, request } from 'src/Utils';
import FieldHoursTimewise from './FieldHours';
import DialogConvertWorking from './DialogConvert';
import DialogRevertConvert from './DialogRevertConvert';
import _h from 'src/pages/DashboardApp/Submission/helper';

class WorkingSheetReport extends BaseReport {
  constructor(props) {
    super(props)

    this.title = 'Working Sheets'
    this.urlKey = 'office-woking-sheet'
    this.rangeMessageError = 'Cannot display for more than 1 year'

    this.setRangeDate({
      start_date: this.objParam.start_date || moment().startOf('year').format(),
      end_date: this.objParam.end_date || moment().endOf('year').format(),
    })

    this.columns = [
      {
        title: 'Year',
        dataIndex: ['date'],
        render: (val) => moment(val).format('YYYY'),
      },
      {
        title: 'Month',
        dataIndex: ['date'],
        render: (val) => moment(val).format('MMMM'),
      },
      {
        title: 'Days',
        dataIndex: ['total_day'],
        align: 'right',
      },
      {
        title: 'Min. Hours',
        dataIndex: ['_minMs'],
        // render: (_, record) => record.total_day * record.hour_in_day,
        // render: (val) => lib.time.getLabel(val),
        render: (_, record) => {
          const deltaMs = this.getDelta(record)
          const leaveDays = this.getLeaveDays(record.date)
          const linkProperties = leaveDays !== 0 && {
            className: 'link link-blue',
            title: 'Click to open Staff Leave',
            onClick: () => {
              window.open(`/report/yearly-staff-leave/` + lib.getStrParam({
                id: this.id,
                start_date: moment(record.date).format('YYYY-MM'),
                end_date: moment(record.date).format('YYYY-MM'),
              }))
            }
          }

          return (
            <span {...linkProperties}>
              <span className={deltaMs < 0 && 'font-weight-bold'}>
                {_h._overtime.renderDuration(this.getMinHours(record))}
              </span>
              {' / '}
              <span className={deltaMs > 0 && 'font-weight-bold'}>
                {_h._overtime.renderDuration(this.getMinHoursExtra(record))}
              </span>
              {leaveDays !== 0 && (
                <Tooltip mouseLeaveDelay={0} title={`Have ${Math.abs(leaveDays)} days leave`}>
                  <InfoCircleOutlined
                    className='link link-blue'
                    style={{ marginLeft: '12px' }}
                  />
                </Tooltip>
              )}
            </span>
          )
        },
      },
      {
        title: 'Hours (TW)',
        dataIndex: ['_working'],
        render: (_, record) => (
          <FieldHoursTimewise
            identifier={record.identifier}
            userId={this.id}
            date={record.date}
            ms={get(this.state.objMs, [record.date], 0)}
            setTotalMs={this.setTotalMs}
          />
        ),
      },
      {
        title: 'Delta',
        dataIndex: ['_delta'],
        render: (_, record) => {
          // const deltaMs = get(this.state.objMs, [record.date], 0) - this.getMinHours(record)
          const deltaMs = this.getDelta(record)

          return (
            <span
              className={classNames('font-weight-semibold', {
                'c-error': deltaMs < 0,
                'c-limegreen': deltaMs > 0,
              })}
            >
              {deltaMs
                ? lib.time.getNumberTime(deltaMs)
                : '-'}
            </span>
          )
        },
      },
      {
        title: 'Action',
        dataIndex: ['_action'],
        align: 'center',
        render: (_, record) => {
          const convertedWorking = this.state.convertedWorkings.find(item => item.date === record.date)
          const msWorking = get(this.state.objMs, [record.date], 0)

          if (convertedWorking) {
            return (
              <Tooltip title="Revert Convert" mouseLeaveDelay={0}>
                <Button
                  danger
                  size="small"
                  type="primary"
                  icon={<PullRequestOutlined />}
                  onClick={() => {
                    this._dialogRevertConvert.show({
                      convertedWorking,
                    })
                  }}
                />
              </Tooltip>
            )
          }

          const delta = this.getDelta(record)
          return msWorking > 0 && delta !== 0 && (
            <Tooltip title="Convert Working" mouseLeaveDelay={0}>
              <Button
                size="small"
                type="primary"
                icon={<SaveOutlined />}
                onClick={() => {
                  this._dialogConvert.show({
                    date: record.date,
                    user: this.id,
                    msMin: delta > 0
                      ? this.getMinHoursExtra(record)
                      : this.getMinHours(record),
                    // msMinExtra: this.getMinHoursExtra(record),
                    msWorking,
                  })
                }}
              />
            </Tooltip>
          )
        }
      },
    ]

    this.state = {
      ...this.state,
      // objActivities: {},
      convertedWorkings: [],
      leaveByMonth: null,
      objMs: null,
    }
  }

  onRefDialogConvertWorking = (ref) => this._dialogConvert = ref
  onRefDialogRevertConvert = (ref) => this._dialogRevertConvert = ref

  checkRange = (startDate, endDate) => (
    moment(endDate).diff(moment(startDate), 'month') <= 11
  )

  getKey = (key) => key === 'range_after' ? 'date_after'
    : key === 'range_before' ? 'date_before'
    : key

  getParam = (key, val) => {
    if (key === 'range_before')
      return moment(this.range_before).date(1).format('YYYY-MM-DD')
    if (key === 'range_after')
      return moment(this.range_after).endOf('month').format('YYYY-MM-DD')

    return val
  }

  getLeaveDays = (date) => get(this.state.leaveByMonth, [moment(date).format('YYYY-MM'), 'totalDays'], 0)

  // unit = ms
  getMinHours = (workingSheet) => {
    console.log('getMinHours', workingSheet.hour_in_day)
    const leaveDays = this.getLeaveDays(workingSheet.date)

    return (
      (workingSheet.total_day * workingSheet.hour_in_day * (60 * 60 * 1000)) -
      Math.abs(leaveDays * workingSheet.hour_in_day * (60 * 60 * 1000))
    )
  }

  getMinHoursExtra = (workingSheet) => (
    this.getMinHours({
      ...workingSheet,
      hour_in_day: workingSheet.hour_in_day + (workingSheet.extra_hour || 0),
    })
  )

  getDelta = (workingSheet) => {
    const minHours = this.getMinHours(workingSheet)
    const minHoursExtra = this.getMinHoursExtra(workingSheet)
    const twHours = get(this.state.objMs, [workingSheet.date], 0)

    if (minHours > twHours)
      return twHours - minHours
    if (minHoursExtra < twHours)
      return twHours - minHoursExtra
    return 0
  }

  readApprovedLeaves = async () => {
    return new Promise(resolve => {
      request({
        urlKey: 'leave-user-leaves',
        data: {
          user: this.id,
          source_type: 'user_apply',
          view_mode: 'calendar',
          status: 'approved',
          date_after: this.getParam('range_before'),
          date_before: this.getParam('range_after'),
        },
        onSuccess: res => {
          console.log('user leaves', res.data)
          const groupedLeave = groupBy(res.data, item => moment(item.start_date).format('YYYY-MM'))
          const leaveByMonth = {}
          for (const month in groupedLeave) {
            leaveByMonth[month] = {
              userLeaves: groupedLeave[month],
              totalDays: groupedLeave[month].reduce((acc, val) => acc + val.day_count, 0),
            }
          }

          resolve(leaveByMonth)
        },
        onFailed: this.readFailed,
      })
    })
  }

  readConvertedWorking = () => {
    return new Promise(resolve => {
      request({
        urlKey: 'office-working-duration',
        data: {
          user: this.id,
          date_after: this.getParam('range_before'),
          date_before: this.getParam('range_after'),
        },
        onSuccess: res => resolve(res.data),
        onFailed: this.readFailed,
      })
    })
  }

  convertResponseData = (responseData) => {
    return new Promise(async (resolve) => {
      const leaveByMonth = await this.readApprovedLeaves()
      console.log('leaveByMonth', leaveByMonth)
      this.readConvertedWorking().then(convertedWorkings => {
        this.setState({ convertedWorkings, leaveByMonth }, () => {
          resolve(responseData.map(item => ({
            ...item,
            identifier: uuidv4(),
            _minMs: item.total_day * item.hour_in_day * (60 * 60 * 1000),
          })))
        })
      })
    })
  }

  setTotalMs = (month, ms) => {
    console.log('setTotalMs', month, ms)
    this.setState({
      responseData: cloneDeep(this.state.responseData), // render table
      objMs: { ...this.state.objMs, [month]: ms },
    })
  }

  onChangeId = (id = this.id) => {
    this.id = id
    this.doRead(
      this.keyIsLoading,
      () => {},
      { responseData: [], objMs: null }, // jika tidak di clear, maka record akan request activities, karena terjadi unmount karena card user sendiri2
    )
  }

  onConverted = () => {
    this.onChangeId()
  }

  getFilterInput = () => (
    <Col className='only-xs-block'>
      <Row gutter={[6, 6]}>
        <Col className='only-xs-block'>
          <DatePicker.RangePicker
            allowClear={false}
            picker='month'
            ranges={{
              'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
              'This Year': [moment().startOf('year'), moment().endOf('year')],
            }}
            style={{ width: '100%' }}
            defaultValue={[moment(this.range_before), moment(this.range_after)]}
            onChange={(dates, dateStrings) => {
              this.tempDateStrings = dateStrings
            }}
            onOpenChange={(opened) => {
              if (!opened) {
                if (this.tempDateStrings[0] !== this.range_before || this.tempDateStrings[1] !== this.range_after)
                  this.onChangeFilter({ start_date: this.tempDateStrings[0], end_date: this.tempDateStrings[1] })
              } else {
                this.tempDateStrings = [this.range_before, this.range_after]
              }
            }}
            />
        </Col>
      </Row>
    </Col>
  )

  getDateRangeContent = () => (
    <Col style={{ padding: '6px 12px' }}>
      <div style={{ fontSize: '12px', color: '#cdcdcd' }}>
        Selected Range
      </div>
      <div style={{ fontSize: '18px', fontWeight: 500 }}>
        {moment(this.range_before).format('MMM')}
        {' – '}
        {moment(this.range_after).format('MMM YYYY')}
      </div>
    </Col>
  )

  getRowClassName = (record, index) => moment(record.date).format('YYYY-MM') === moment().format('YYYY-MM')
    ? 'working-sheet-current-month'
    : ''

  getBodyContent = () => {
    const selectedUsers = this.getSelectedUsers()

    return (
      <div>
        <div>
          {selectedUsers.length > 0 && (
            <Collapse activeKey={selectedUsers.map(user => user.alias)}>
              {selectedUsers.map(user => (
                <Collapse.Panel key={user.alias} header={this.getUserTitle(user)} className='panel-report'>
                  <Row justify='space-between'>
                    <div style={{ fontSize: '20px', padding: '12px' }}>
                      {/* Total Worked: <b>{lib.time.getLabel(user.totalWorked, true)}</b> */}
                    </div>
                    {!this.mutableScreen.isMobile && this.getDateRangeContent()}
                  </Row>
                  <Table
                    bordered
                    size='small'
                    columns={this.columns}
                    dataSource={this.__list}
                    pagination={false}
                    rowKey='identifier'
                    scroll={this.scrollConfig}
                    rowClassName={this.getRowClassName}
                  />
                </Collapse.Panel>
              ))}
            </Collapse>
          )}
        </div>
      </div>
    )
  }

  renderAnotherContent = () => {
    return (
      <div>
        <DialogConvertWorking
          ref={this.onRefDialogConvertWorking}
          onConverted={this.onConverted}
        />
        <DialogRevertConvert
          ref={this.onRefDialogRevertConvert}
          onReverted={this.onConverted}
        />
      </div>
    )
  }
}

WorkingSheetReport.propTypes = {
  ...WorkingSheetReport.propTypes,
}

WorkingSheetReport.defaultProps = {
  ...WorkingSheetReport.defaultProps,
}

export default WorkingSheetReport