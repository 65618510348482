// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userReducer(state = {}, action) {
    switch (action.type) {
      case 'createSession': {
        return action.payload
      }
      case 'createProfile': {
        return {
          ...state,
          profile: action.payload
        }
      }
      default: {
        return state
      }
    }
  }
}