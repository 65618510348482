import React from 'react'
import { mutableScreen } from 'src/mutable'

class BaseComponent extends React.PureComponent {
  constructor(props) {
    super(props)

    this._mounted = false // don't setState before componentDidMount

    this.mutableScreen = mutableScreen

    this.didMount = this.didMount.bind(this)
    this.unmount = this.unmount.bind(this)

    this.initDefaultState()
    this.initState()
  }

  getMounted = () => this._mounted

  initDefaultState = (state) => {
    this.defaultState = { isLoading: false, ...state }
  }

  initState = (state) => {
    this.state = {
      ...this.state,
      ...this.defaultState,
      ...state,
    }
  }

  // Please don't override this function
  setMounted = (mounted = true) => {
    this._mounted = mounted

    if (mounted) this.didMount()
    else this.unmount()
  }

  didMount() {}

  unmount() {}

  setThisState = (state, callback) => {
    if (this._mounted) {
      this.setState(state, callback)
    }
  }

  componentDidMount() {
    this.setMounted()
  }

  componentWillUnmount() {
    this.setMounted(false)
  }
}

export default BaseComponent