import PropTypes from 'prop-types';
import { Button, Calendar, Checkbox, Col, Collapse, DatePicker, Divider, Popconfirm, Radio, Row, Segmented, Select, Table, Tooltip } from 'antd';
import { CalendarOutlined, CaretLeftOutlined, CaretRightOutlined, FilterOutlined, InfoCircleOutlined, InfoOutlined, RollbackOutlined, SaveOutlined, ScissorOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { get, groupBy, orderBy, set } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';

import { BaseReport } from 'src/pages/DashboardApp/Reports/components';
import { VisibilityContainer } from 'src/components';
import OvertimeCalendar from './Calendar';
import DialogOvertimeDetail from './DialogDetail';
import DialogConvertBalance from './DialogConvert';
import { lib, request } from 'src/Utils';
import _h from 'src/pages/DashboardApp/Submission/helper';

class OvertimeTimesheet extends BaseReport {
  constructor(props) {
    super(props)

    this.DEFAULT_RANGE_TYPE = 'month'
    this.DEFAULT_RANGE_FORMAT = 'YYYY-MM'

    this.title = 'Overtime Sheet'
    this.urlKey = 'overtime-user-overtimes'
    this.keysQueryParam.push('view_mode', 'status', 'related')
    this.scrollConfig = { x: '1248px' }
    this.stickyConfig = this.mutableScreen.isMobile ? false : { offsetHeader: '45px' }

    this.setRangeDate({
      start_date: this.objParam.start_date || moment().startOf('month').format(),
      end_date: this.objParam.end_date || moment().endOf('month').format(),
    })

    // this.ordering = 'created'
    this.isShowAll = false
    this.related = 'user'
    this.view_mode = 'calendar'
    this.status = 'approved,pending'
    this.statuses = [
      { key: '', label: 'All' },
      { key: 'approved,pending', label: 'Apprv+Pend' },
      { key: 'approved', label: 'Approved' },
      { key: 'rejected', label: 'Rejected' },
      { key: 'pending', label: 'Pending' },
      { key: 'cancelled', label: 'Cancelled' },
    ]

    this.columns = [
      // {
      //   fixed: 'left',
      //   title: 'Timezone',
      //   dataIndex: ['timezone', 'gmt_repr'],
      //   width: '100px',
      // },
      {
        fixed: 'left',
        title: 'Date',
        dataIndex: 'date',
        width: '96px',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => lib.sorter.byItem(a, b, 'date'),
        render: (val, record) => ({
          ...(!!record.children && { props:{colSpan: 0} }),
          children: (
            <span className='link link-blue' onClick={() => this.showDetail(record)}>
              {moment(val).format('DD MMM YYYY')}
            </span>
          ),
        }),
      },
      {
        fixed: 'left',
        title: 'Time Schedule',
        dataIndex: '_schedule',
        width: '100px',
        render: (_, record) => ({
          ...(!!record.children && { props:{colSpan: 0} }),
          children: _h._overtime.renderRange(record),
        }),
      },
      {
        title: 'Leave Compensation',
        dataIndex: 'refer_leave',
        render: (val) => val
          ? _h._leave.renderSelect(val)
          : '-',
      },
      {
        title: 'Confirmed by',
        dataIndex: ['confirmed_by'],
        width: '170px',
        render: (value) => lib.getFullName(value),
      },
      {
        title: 'Requested',
        dataIndex: '_requested',
        width: '160px',
        // sorter: (a, b) => lib.sorter.byItem(_h._overtime.getTotal([a]), _h._overtime.getTotal([b])),
        sorter: (a, b) => lib.sorter.byItem(a, b, '_requested'),
        // render: (_, record) => _h._overtime.renderDurationByItem(record),
        render: (val) => _h._overtime.renderDuration(val),
        // getSummary: (data) => _h._overtime.renderDurationByItems(data),
        // getSummary: (data) => _h._overtime.renderDuration(data.reduce((acc, val) => acc + val._requested, 0)),
        getSummary: (data) => _h._overtime.renderDuration(data.filter(record => !record.is_converted_balance).reduce((acc, val) => acc + val._requested, 0)),
      },
      {
        title: 'Hours (TW)',
        dataIndex: '_working',
        width: '160px',
        sorter: (a, b) => lib.sorter.byItem(a, b, '_working'),
        render: (val, record) => (!val || (!_h.isApproved(record) && !record.children))
          ? '-'
          : record.children
            ? _h._overtime.renderDuration(val)
            : (<Tooltip title={record.is_converted_balance && 'Converted to Leave Balance, not calculated in summary'}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/report/timeline/?id=${record.user_id}&start_date=${record.date}&end_date=${record.date}`}
                  className={classNames("link", { 'converted-desc': record.is_converted_balance })}
                >
                  {_h._overtime.renderDuration(val)}
                </a>
              </Tooltip>
            ),
        getSummary: (data) => {
          let total = 0
          for (const record of data)
            total += (!record.children && (!_h.isApproved(record) || record.is_converted_balance))
              ? 0
              : record._working

          return _h._overtime.renderDuration(total)
        }
      },
      {
        title: 'Meal Allowance',
        dataIndex: '_meal',
        width: '90px',
        sorter: (a, b) => lib.sorter.byItem(a, b, '_meal'),
        // render: (_, record) => this.getMealAllowance(record) || '-',
        render: (val) => val || '-',
        // getSummary: (data) => data.reduce((acc, val) => acc + this.getMealAllowance(val), 0) || '-',
        getSummary: (data) => data.reduce((acc, val) => acc + val._meal, 0),
      },
      {
        title: 'Status',
        dataIndex: ['status_display'],
        width: '100px',
        sorter: (a, b) => lib.sorter.byItem(a, b, 'status_display'),
        render: (val, record) => (
          <span
            className={classNames('font-weight-semibold', {
              'c-error': _h.isRejected(record),
              'c-warning': _h.isCancelled(record),
              'approved-note': _h.isApproved(record),
              'c-gray': _h.isWaiting(record),
            })}
          >
            {val}
          </span>
        ),
      },
      {
        title: 'Action',
        dataIndex: '_action',
        width: '60px',
        align: 'center',
        render: (_, record) => (
          <Row justify="center" gutter={[6, 6]}>
            {(this.myProfile.is_owner || (this.myProfile.id === record.user_id)) &&
            // sementara hanya owner yang bisa convert https://github.com/lewatmana/timetracking-web/issues/457
            this.myProfile.is_owner &&
            _h.isApproved(record) &&
            record.refer_leave &&
            !record.is_converted_balance &&
            !!get(this.state.objUserActivities, [record.user_id, record.date, 'ms']) && (
              <Col>
                <Tooltip placement="topRight" mouseLeaveDelay={0} title="Convert overtime to leave balance">
                  <Button
                    size="small"
                    type="primary"
                    icon={<SaveOutlined />}
                    onClick={() => this._dialogConvert.show({ userOvertime: record, hours: this.state.objUserActivities[record.user_id][record.date] })}
                  />
                </Tooltip>
              </Col>
            )}
            {this.myProfile.is_owner && record.is_converted_balance && (
              <Col>
                <Popconfirm
                  icon={<ScissorOutlined className="c-error"  />}
                  placement="topRight"
                  okType="danger"
                  okText="Yes"
                  title="Are you sure revert the converted overtime?"
                  onConfirm={() => this.revertConvert(record)}
                >
                  <Button
                    danger
                    size="small"
                    type="primary"
                    icon={<RollbackOutlined />}
                  />
                </Popconfirm>
              </Col>
            )}
          </Row>
        ),
      },
    ]

    this.state = {
      ...this.state,
      viewType: 'list',
      objUserActivities: {},
    }
  }

  onRefCalendar = (ref) => this._calendar = ref
  onRefDialogDetail = (ref) => this._dialogDetail = ref
  onRefDialogConvert = (ref) => this._dialogConvert = ref

  getKey = (key) => key === 'range_after' ? 'date_after'
    : key === 'range_before' ? 'date_before'
    // : key === 'id' ? 'user'
    : key

  getParam = (key, val) => {
    if (key === 'range_before')
      // return moment(this.range_before).startOf('month').format('YYYY-MM-DD')
      return moment(this.range_before).subtract(1, 'month').date(21).format('YYYY-MM-DD')
    if (key === 'range_after')
      // return moment(this.range_before).endOf('month').format('YYYY-MM-DD')
      return moment(this.range_before).date(20).format('YYYY-MM-DD')
    // if (key === 'id')
    //   return this.isShowAll ? this.users.map(user => user.id).join(',') : val
    if (key === 'user')
      return this.isShowAll ? this.users.map(user => user.id).join(',') : this.id

    return val
  }

  isViewList = () => this.state.viewType === 'list'

  showDetail = (userOvertime) => {
    this._dialogDetail.show({ userOvertime })
  }

  getMealAllowance = (userOvertime) => {
    const objActivity = get(this.state.objUserActivities, [userOvertime.user_id, userOvertime.date])
    const minHours = userOvertime.is_holiday
      ? _h._s.OVERTIME_MIN_HOURS_WEEKEND
      : _h._s.OVERTIME_MIN_HOURS_WEEKDAY

    return (_h.isApproved(userOvertime) && !userOvertime.is_converted_balance && moment.duration(get(objActivity, 'ms', 0)).hours() >= minHours)
      ? 1
      : 0
  }

  getColumns = () => {
    return !this.isShowAll ? this.columns : [{
      fixed: 'left',
      title: 'User',
      dataIndex: ['user'],
      width: '170px',
      sorter: (a, b) => lib.sorter.byItem(a, b, ['user', 'first_name']),
      render: (value, record) => ({
        ...(!!record.children && { props:{colSpan: 3} }),
        children: lib.getFullName(value),
      })
    }].concat(this.columns)
  }

  readActivities = async (userOvertimes = [], params) => {
    return new Promise(resolve => {request({
        urlKey: 'read-activities',
        data: params,
        onFailed: this.readFailed,
        onSuccess: (res) => {
          window.konsole.log('readActivities success', res)
          const objUserActivities = {}
          const acitivitesById = groupBy(res.data, 'id')
          for (const userOvertime of userOvertimes)
            set(
              objUserActivities,
              [userOvertime.user_id, userOvertime.date],
              _h._overtime.getDiffActivities(userOvertime, get(acitivitesById[userOvertime.user_id], [0, 'activities'], []))
            )
            // objUserActivities[userOvertime.date] = _h._overtime.getDiffActivities(userOvertime, get(acitivitesById[userOvertime.user_id], [0, 'activities'], []))

          this.setState({ objUserActivities }, () => resolve())
        },
      })
    })
  }

  convertResponseData = async (responseData) => {
    const dates = orderBy(responseData, 'date').map(item => item.date)
    const userIds = responseData.map(userOvertime => userOvertime.user_id).filter((val, i, arr) => arr.indexOf(val) === i)
    // only request activities in overtime dates
    if (dates.length) {
      await this.readActivities(responseData, {
        id: this.isShowAll ? userIds.join(',') : this.id,
        range_before: moment(dates[0]).startOf('day').format(),
        range_after: moment(dates[dates.length - 1]).endOf('day').format(),
      })
    }

    responseData = responseData.map(userOvertime => ({
      ...userOvertime,
      _requested: _h._overtime.getTotal([userOvertime]),
      _working: get(this.state.objUserActivities, [userOvertime.user_id, userOvertime.date, 'ms'], 0),
      _meal: this.getMealAllowance(userOvertime),
    }))

    let userOvertimes = responseData
    if (this.isShowAll) {
      userOvertimes = []
      for (const userOvertime of responseData) {
        const fullName = lib.getFullName(userOvertime.user)
        let index = userOvertimes.findIndex(item => item.pk === fullName)
        if (index === -1)
          index = userOvertimes.push({
            pk: fullName, // rowKey
            // groupName: fullName,
            user: userOvertime.user,
            children: [],
            // summary --------------------
            _requested: 0,
            _working: 0,
            _meal: 0,
            // ----------------------------
          }) - 1

        userOvertimes[index].children.push(userOvertime)
        userOvertimes[index]._requested += userOvertime._requested
        userOvertimes[index]._meal += userOvertime._meal
        if (_h.isApproved(userOvertime) && !userOvertime.is_converted_balance)
          userOvertimes[index]._working += userOvertime._working
      }

      // ordering by user
      userOvertimes = orderBy(userOvertimes, item => lib.getFullName(item.user))
    }

    return userOvertimes
  }

  onChangeStatus = (status) => {
    this.status = status
    this.doRead(this.keyIsLoading)
  }

  reload = (keyLoading, callback, force) => {
    this.page = 1
    this.read(keyLoading, callback, force)
    if (this._calendar) {
      this._calendar.reload()
      this._calendar._dialogListUser.hide() // di hide karena reload tidak merubah data di dialog
    }
  }

  nextMonth = () => {
    this.range_before = moment(this.range_before).add(1, 'month').format('YYYY-MM')
    this.doRead(this.keyIsLoading)
  }

  prevMonth = () => {
    // const date = moment(this.range_before).subtract(1, 'month').format('YYYY-MM')
    // this.onChangeFilter({ start_date: date, end_date: date })
    this.range_before = moment(this.range_before).subtract(1, 'month').format('YYYY-MM')
    this.doRead(this.keyIsLoading)
  }

  revertConvert = (userOvertime) => {
    this.setThisState({ [this.keyIsLoading]: false }, () => {
      request({
        method: 'post',
        urlKey: 'overtime-overtimes-detail-revert-convert',
        args: [userOvertime.pk],
        onSuccess: this.revertSuccess,
        onFailed: this.revertFailed,
      })
    })
  }

  revertSuccess = (res) => {
    this.doRead(this.keyIsLoading)
  }

  revertFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.setThisState({ [this.keyIsLoading]: false })

  }

  getFilterInput = () => (
    <Col className='only-xs-block'>
      <Row gutter={[6, 6]}>
        <Col className="only-xs-block">
          {/* <Segmented
            block
            value={this.state.viewType}
            options={[
              { value: 'calendar', label: 'Calendar', icon: <CalendarOutlined /> },
              { value: 'list', label: 'List', icon: <UnorderedListOutlined /> },
            ]}
            style={{ minWidth: '200px' }}
            onChange={viewType => this.setState({ viewType })}
          /> */}
          <Radio.Group value={this.state.viewType} buttonStyle='solid' onChange={e => this.setState({ viewType: e.target.value })}>
            <Radio.Button value='calendar' icon={<CalendarOutlined />}>
              Calendar
            </Radio.Button>
            <Radio.Button value='list' icon={<UnorderedListOutlined />}>
              List
            </Radio.Button>
          </Radio.Group>
        </Col>
        {this.isViewList() && (
          <Col>
            <Checkbox
              className={classNames('btn-checkbox', {selected: this.isShowAll})}
              checked={this.isShowAll}
              onChange={e => {
                this.isShowAll = e.target.checked
                this.doRead(this.keyIsLoading)
              }}
            >
              Show All
            </Checkbox>
          </Col>
        )}
        <Col className="only-xs-block">
          <Select
            defaultValue={this.status}
            style={{ minWidth: '120px', width: '100%' }}
            suffixIcon={<FilterOutlined />}
            menuItemSelectedIcon={<FilterOutlined />}
            onChange={this.onChangeStatus}
          >
            {this.statuses.map(status => (
              <Select.Option key={status.label} value={status.key}>
                {status.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col className='only-xs-block'>
          <VisibilityContainer visible={this.isViewList()}>
            <Row>
              <Col>
                <Button icon={<CaretLeftOutlined />} onClick={this.prevMonth} />
              </Col>
              <Col style={{ margin: '0 -1px', flex: 1 }}>
                <DatePicker
                  allowClear={false}
                  picker='month'
                  ranges={{
                    'Last Month': moment().subtract(1, 'month'),
                    'This Month': moment(),
                  }}
                  style={{ width: '100%' }}
                  value={moment(this.range_before)}
                  onChange={(selectedDate) => {
                    this.onChangeFilter({
                      start_date: selectedDate.format('YYYY-MM'),
                      end_date: selectedDate.format('YYYY-MM'),
                    })
                  }}
                />
              </Col>
              <Col>
                <Button icon={<CaretRightOutlined />} onClick={this.nextMonth} />
              </Col>
            </Row>
          </VisibilityContainer>
        </Col>
      </Row>
    </Col>
  )

  getSelectedUsers = () => this.users.filter(user => String(this.id) === String(user.id))

  getDateRangeContent = () => (
    <Col style={{ padding: '6px 12px' }}>
      <div style={{ fontSize: '12px', color: '#cdcdcd' }}>
        Selected Month
      </div>
      <div style={{ fontSize: '18px', fontWeight: 500 }}>
        {/* {moment(this.range_before).startOf('month').format('MMMM YYYY')} */}
        {moment(this.range_before).subtract(1, 'month').date(21).format('DD MMM')}
        {' - '}
        {moment(this.range_before).date(20).format('DD MMM YYYY')}
      </div>
    </Col>
  )

  getTableSummaryProps = () => null
  summaryTable = (currentData) => {
    // window.konsole.log('summaryTable', currentData)
    return (
      <Table.Summary {...this.getTableSummaryProps()}>
        <Table.Summary.Row>
          {this.getColumns().map((column, i) => (
            <Table.Summary.Cell key={column.title} index={i} {...column.summaryProps}>
              {column.getSummary && column.getSummary(currentData)}
            </Table.Summary.Cell>
          ))}
        </Table.Summary.Row>
      </Table.Summary>
    )
  }

  renderAnotherContent = () => {
    return (
      <div>
        <DialogOvertimeDetail ref={this.onRefDialogDetail} reload={this.reload} />
        <DialogConvertBalance ref={this.onRefDialogConvert} reload={this.reload} />
      </div>
    )
  }

  renderUsersContainer = () => (
    <VisibilityContainer visible={this.isViewList() && !this.isShowAll}>
      {this.renderUsers()}
    </VisibilityContainer>
  )

  getBodyContent = () => {
    const selectedUsers = this.getSelectedUsers()
    return (
      <div>
        <VisibilityContainer visible={this.isViewList()}>
          {this.users.length > 0 && (
            <Collapse activeKey={selectedUsers.map(user => user.alias)}>
              {selectedUsers.map(user => (
                <Collapse.Panel
                  key={user.alias}
                  className='panel-report'
                  header={this.getUserTitle(this.isShowAll ? {first_name: 'All Managed Users'} : user)}
                >
                  <Row justify='space-between'>
                    <Col>
                      {/* <UserBalance
                        userId={this.id}
                      /> */}
                    </Col>
                    <div style={{ fontSize: '20px', padding: '12px' }}>
                      {/* Total Worked: <b>{lib.time.getLabel(user.totalWorked, true)}</b> */}
                    </div>
                    {!this.mutableScreen.isMobile && this.getDateRangeContent()}
                  </Row>
                  <Table
                    bordered
                    showSorterTooltip={false}
                    size='small'
                    rowKey='pk'
                    columns={this.getColumns()}
                    dataSource={this.__list}
                    pagination={false}
                    scroll={this.scrollConfig}
                    sticky={this.stickyConfig}
                    onRow={(record, i) => ({ className: classNames({'row-group': !!record.children}) })}
                    expandable={{ expandIcon: () => null, expandRowByClick: true }}
                    summary={this.summaryTable}
                  />
                </Collapse.Panel>
              ))}
            </Collapse>
          )}
        </VisibilityContainer>
        <VisibilityContainer visible={!this.isViewList()}>
          <OvertimeCalendar
            ref={this.onRefCalendar}
            urlKey={this.urlKey}
            users={this.users}
            status={this.status}
            showDetail={this.showDetail}
          />
        </VisibilityContainer>
    </div>
    )
  }
}

OvertimeTimesheet.propTypes = {
  ...OvertimeTimesheet.propTypes,
}

OvertimeTimesheet.defaultProps = {
  ...OvertimeTimesheet.defaultProps,
}

export default OvertimeTimesheet