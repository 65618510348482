import { get } from 'lodash'

function getFilenameDisposition(headers, defaultFilename) {
  let filename = defaultFilename
  const disposition = get(headers, 'content-disposition')
  if (typeof disposition === 'string' && disposition.indexOf('filename') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches !== null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename
}

export default getFilenameDisposition