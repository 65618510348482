import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './redux'

import App from './App';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import './index.css';
import './App.scss';
import './screen.css'; // media-screen style harus di bawah
import 'react-image-lightbox/style.css';

const appConfig = { debug: true };
const konsoleObj = {};
for (const k in window.console) {
  if (appConfig.debug) konsoleObj[k] = window.console[k];
  else konsoleObj[k] = () => {};
}

Object.defineProperties(window, {
  konsole: {
    get() {
      return konsoleObj;
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
