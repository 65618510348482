import PropTypes from 'prop-types';

import ManagerProductionDetailTable from 'src/pages/DashboardApp/DashboardManager/ProductionDetail';

// https://github.com/lewatmana/timetracking-web/issues/373
class ProductionDetailReportTable extends ManagerProductionDetailTable {
  constructor(props) {
    super(props)

    this.stickyOpt = false // { offsetHeader: 45 }
    this.scrollOpts = { x: 720, y: 'calc(100vh - 200px)' }
    this.resyncFeature = false

    // exclude columns
    const exludeColumns = ['highlighted_total_charge', 'qs_value', 'total_qs_value']
    this.columns = this.columns.filter(column => !exludeColumns.includes(column.dataIndex))

    // set point column to fixed
    const pointColumn = this.columns.find(column => column.dataIndex === 'qs_point')
    if (pointColumn) pointColumn.fixed = 'right'
  }

  // called by ref component
  setGroupedJobs = (key) => {
    this.setState({
      data: this.getData(this.props.productionJobs, key),
    })
  }

  getTableSummaryProps = () => ({ fixed: true })

  renderHeader() {
    return null
  }
}

ProductionDetailReportTable.propTypes = {
  ...ProductionDetailReportTable.propTypes,
}

ProductionDetailReportTable.defaultProps = {
  ...ProductionDetailReportTable.defaultProps,
}

export default ProductionDetailReportTable