import { cloneDeep } from 'lodash'

import BaseProductionDetail from 'src/pages/DashboardApp/DashboardManager/ProductionDetail'

class UserProductionDetail extends BaseProductionDetail {
  constructor(props) {
    super(props)

    this.showExport = false
    this.resyncFeature = false

    this.setFilteredColumns()
  }

  setFilteredColumns = (columns = this.columns) => {
    this.columns = cloneDeep(columns)

    const excludeColumns = ['highlighted_total_charge', 'qs_value', 'total_qs_value']
    this.columns = this.columns.filter(column => !excludeColumns.includes(column.dataIndex))
    const colQsPoint = this.columns.find(column => column.dataIndex === 'qs_point')
    if (colQsPoint) colQsPoint.fixed = 'right'
  }
}

UserProductionDetail.propTypes = {
  ...UserProductionDetail.propTypes,
}

UserProductionDetail.defaultProps = {
  ...UserProductionDetail.defaultProps,
}

export default UserProductionDetail