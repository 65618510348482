function getUserAmount(encodedAmount) {
  if (!encodedAmount) return 0

  const amount = atob(encodedAmount)
    .split(':')
    .map(val => Number(val) / 807)
    .join('.')

  return Number(amount)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getUserAmount,
}