import React from 'react'
import PropTypes from 'prop-types'
import { Card, Col, Empty, Row, Spin } from 'antd'
import { RiseOutlined } from '@ant-design/icons'
import { Pie } from 'ant-design-pro/lib/Charts'
import moment from 'moment'

import { BaseComponent, PageError } from 'src/components'
import { CardHeader } from 'src/pages/DashboardApp/Components'
import { lib } from 'src/Utils'

const colors = ['#3aa1ff', '#36cbcb', '#4ecb73', '#fbd437', '#f2637b']

class TemplateCard extends BaseComponent {
  constructor(props) {
    super(props)

    this.header = {} // CardHeader props
    this.urlKey = ''
    this.state = {
      isLoading: true,
      data: [],
    }
  }

  getObjParam = () => ({ start_date: this.props.startDate, end_date: this.props.endDate })

  render() {
    return (
      <Card className='app-card top-usage' bodyStyle={{ height: '100%', paddingTop: '12px' }}>
        <Spin spinning={this.props.isLoading}>
          <CardHeader
            iconLink={<RiseOutlined />}
            // params={{ start_date: this.props.startDate, end_date: this.props.endDate }}
            // params={`?start_date=${moment(this.props.startDate).format('YYYY-MM-DD')}&end_date=${moment(this.props.endDate).format('YYYY-MM-DD')}`}
            params={lib.getStrParam({
              start_date: moment(this.props.startDate).format('YYYY-MM-DD'),
              end_date: moment(this.props.endDate).format('YYYY-MM-DD'),
              id: this.props.userId,
            })}
            {...this.header}
            link={this.props.isManaged && this.header.link}
          />
          {(this.props.isLoading || !this.props.errorResponse) && (
            <Row>
              <Col xs={24} sm={24} className='chart-section' style={{ height: this.state.data.length ? 'auto' : '0' }}>
                <Row justify='center'>
                  <Col>
                    <Pie
                      colors={colors}
                      width={200}
                      height={200}
                      data={this.state.data.map(val => ({
                        x: val.name,
                        y: Number(val.total),
                      }))}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} className='table-section'>
                <div className='ant-table-wrapper table-small'>
                  <div className='ant-table ant-table-bordered' style={{ overflowX: 'auto' }}>
                    <div className='ant-table-container'>
                      <div className='ant-table-content'>
                        <table>
                          <thead className='ant-table-thead'>
                            <tr>
                              <th />
                              <th>Name</th>
                              <th>Time</th>
                            </tr>
                          </thead>
                          {this.state.data.length > 0 && (
                            <tbody className='ant-table-tbody'>
                              {this.state.data.map((time, i) => (
                                <tr key={time.key}>
                                  <td style={{ maxWidth: '35px' }}>
                                    <div
                                      style={{
                                        background: colors[i],
                                        minWidth: '12px',
                                        height: '12px',
                                      }}
                                    />
                                  </td>
                                  <td className='text-truncate' style={{ maxWidth: '225px' }} title={time.name}>
                                    {time.name}
                                  </td>
                                  <td style={{ minWidth: '95px' }}>
                                    {lib.time.getLabel(time.total, true)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </table>
                        {!this.state.data.length && (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          {this.props.errorResponse && (
            <PageError
              errorResponse={this.props.errorResponse}
              onReload={this.reloadError}
            />
          )}
        </Spin>
      </Card>
    )
  }
}

TemplateCard.propTypes = {
  isLoading: PropTypes.bool,
  errorResponse: PropTypes.object,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default TemplateCard