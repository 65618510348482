import React from 'react'
import { Button } from 'antd'

import ButtonStatus from './ButtonStatus'

class ButtonDeactivated extends ButtonStatus {
  renderInputOld = () => {
    return this.state.hovered ? (
      <Button size='small' {...this.buttonProps} onClick={this.props.activate}>
        Activate
      </Button>
    ) : (
      <Button disabled size='small' {...this.buttonProps}>
        Deactivated
      </Button>
    )
  }

  renderInput = () => {
    return (
      <Button.Group>
        <Button size='small' style={this.buttonStyle} onClick={this.props.activate}>
          Activate
        </Button>
        <Button disabled size='small' style={{ ...this.buttonStyle, cursor: 'default' }}>
          Deactivated
        </Button>  
      </Button.Group>
    )
  }
}

export default ButtonDeactivated