import { groupBy } from 'lodash';
import moment from 'moment';

import { request } from 'src/Utils';

export default async function readProductionJobs({ id, date_after, date_before }) {
  return new Promise((resolve, reject) => {
    request({
      urlKey: 'read-usersProductionJobs',
      data: {
        finished_date_after: moment(date_after).startOf('month').format(),
        finished_date_before: moment(date_before).endOf('month').format(),
        id: id,
        status: 'finished',
      },
      onFailed: reject,
      onSuccess: res => {
        resolve({
          ...res.data[0],
          objQs: groupBy(res.data[0].qs_production_jobs, job => moment(job.finished_date).format('YYYY-MM'))
        })
      },
    })
  })
}