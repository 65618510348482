import appConfig from 'src/config/app'
import request from './request'
import lib from './lib'

async function refreshToken() {
  const currentSession = lib.ls.getUserToken()
  const data = new FormData()
  data.set('refresh_token', currentSession.refresh_token)
  data.set('grant_type', 'refresh_token')
  data.set('client_id', appConfig.oauth.dashboard.client_id)
  data.set('client_secret', appConfig.oauth.dashboard.client_secret)

  const response = await request({
      urlKey: 'oauth-token',
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data,
    });


  if (response) {
    lib.ls.updateUserToken(response.data)
    return true;
  } else return false;
}

export default refreshToken