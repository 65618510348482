import React from 'react'
import { get, isEmpty, noop, set } from 'lodash'

import BaseComponent from './BaseComponent'
import { lib, request } from 'src/Utils'
import PageError from './PageError'

// BaseList is class with handle GET request list and load more
class BaseList extends BaseComponent {
  constructor(props) {
    super(props)

    this.keyIsLoading = 'isLoading'
    this.keyIsLoadMore = 'isLoadMore'

    this.page = 1
    this.limit = 100

    this.keysQueryParam = ['page', 'limit'] // this value must be array

    this.urlKey = '' // must be overridden
    this.next = null
    this.pagination = true

    this.pageSizeOptions = [25, 50, 75, 100]

    const self = this
    Object.defineProperties(this, {
      __list: {
        get() {
          return self.getList()
        }
      }
    })

    this.state = {
      [this.keyIsLoading]: true,
      [this.keyIsLoadMore]: false,
      errorResponse: null,
      responseData: null,
    }
  }

  getParams = () => {
    const params = {}
    for (const key of this.keysQueryParam) {
      if (this.isValidParam(key)) {
        params[this.getKey(key)] = this.getParam(key, this[key])
      }
    }

    return params
  }

  isValidParam = (key) => this.getParam(key, this[key]) !== undefined

  getKey = (key) => key
  getParam = (key, val) => val

  beforeRead = (keyLoading) => {
    // When reload/refresh, set page to 1
    // if (keyLoading === this.keyIsLoading)
    //   this.page = 1
  }

  reload = (keyLoading, callback, force) => {
    this.page = 1
    this.read(keyLoading, callback, force)
  }

  forceRead = () => {
    this.read(undefined, undefined, true)
  }

  read = async (keyLoading, callback, force) => {
    if (!force && (this.state[this.keyIsLoading] || this.state[this.keyIsLoadMore])) {
      this.decrementPage(keyLoading)
      return
    }

    if (typeof keyLoading !== 'string' || !keyLoading)
      keyLoading = this.keyIsLoading

    const isBreak = await this.beforeRead(keyLoading, callback)

    if (isBreak)
      return

    if (!callback || typeof callback !== 'function')
      callback = () => {}

    this.doRead(keyLoading, callback)
    // this.setThisState({ [keyLoading]: true }, () => {
    //   request({
    //     urlKey: this.urlKey,
    //     data: this.getParams(),
    //     extra: { keyLoading, callback },
    //     getCancel: this.setCancel,
    //     onSuccess: this.readSuccess,
    //     onFailed: this.readFailed,
    //   })
    // })
  }

  doReadAnother = async () => null

  doRead = (keyLoading, callback, state) => {
    this.setThisState({ [keyLoading]: true, ...state }, async () => {
      await this.doReadAnother()

      request({
        urlKey: this.urlKey,
        data: this.getParams(),
        extra: { keyLoading, callback },
        getCancel: this.setCancel,
        onSuccess: this.readSuccess,
        onFailed: this.readFailed,
      })
    })
  }

  setCancel = (cancel) => {
    this.cancelToken = cancel
  }

  cancel = () => {
    if (typeof this.cancelToken === 'function') {
      this.cancelToken()
    }
  }

  getResults = (responseData) => {
    return this.pagination ? get(responseData, 'results') : responseData
  }

  convertResponseData = (responseData) => responseData

  readSuccess = async (response, extra) => {
    this.setCancel(null)
    this.next = response.data.next

    response.data = await this.convertResponseData(response.data)

    if (extra.keyLoading === this.keyIsLoadMore)
      response.data.results.unshift(...this.state.responseData.results)

    this.setThisState({
      [extra.keyLoading]: false,
      [this.keyIsLoading]: false,
      [this.keyIsLoadMore]: false,
      responseData: response.data,
      errorResponse: null,
    }, extra.callback)
  }

  readFailed = (error, extra) => {
    const { keyLoading = this.keyIsLoading, callback = noop } = (extra || {})

    this.setCancel(null)
    this.decrementPage(keyLoading)

    if (get(error.response, ['data', 'detail']) !== 'Operation canceled!')
      lib.responseMessage.error(error.response)

    this.setThisState({
      [keyLoading]: false,
      errorResponse: error.response,
    }, callback)
  }

  decrementPage = (keyLoading) => {
    if (keyLoading === this.keyIsLoadMore)
      this.page -= 1
  }

  loadMore = () => {
    if (this.next) {
      this.page += 1
      this.read(this.keyIsLoadMore)
    }
  }

  getList = () => {
    const list = this.getResults(this.state.responseData)

    if (isEmpty(list) || !Array.isArray(list)) return []
    return list
  }

  getIndexNewRecord = (record, list) => list.length

  // use in parent class or ref
  // newList: Array
  patchList = (newList, key = 'alias') => {
    const tempList = this.__list
    const list = [...tempList]
    for (let i = 0; i < newList.length; i += 1) {
      const idx = tempList.findIndex(record => record[key] === newList[i][key])
      if (idx === -1) {
        // new record
        list.splice(
          this.getIndexNewRecord(newList[i], list),
          0,
          newList[i]
        )
      } else {
        list[idx] = newList[i]
      }
    }
    this.updateList(list)
  }

  updateList = (list, state) => {
    if (this.pagination) {
      this.setThisState({ ...state, responseData: {...this.state.responseData, results: list} })
    } else {
      this.setThisState({ ...state, responseData: list })
    }
  }

  getErrorComp = () => {
    return (
      <PageError
        errorResponse={this.state.errorResponse}
        onReload={this.reload}
      />
    )
  }

  getPaginationConfig = () => ({
    current: this.page,
    pageSize: this.limit,
    pageSizeOptions: this.pageSizeOptions,
    showSizeChanger: true,
    total: get(this.state.responseData, 'count', 0),
    onChange: this.onChangePagination,
  })

  onChangePagination = (page, pageSize) => {
    this.page = page
    this.limit = pageSize
    this.read()
  }

  didMount() {
    if (this.state[this.keyIsLoading] && this.urlKey) {
      this.forceRead()
    }
  }

  unmount() {
    this.cancel()
  }
}

export default BaseList