import React from 'react'
import { Card, Col, Divider, Form, Row, Spin } from 'antd'
import { LockOutlined, UnlockOutlined } from '@ant-design/icons'

import { BaseList } from 'src/components'
import { lib } from 'src/Utils'

class CompanyPermissions extends BaseList {
  constructor(props) {
    super(props)

    this.urlKey = 'read-displayModule'
    this.pagination = false
    this.keysQueryParam = []

    this.myProfile = lib.ls.getCurrentUser()

    const self = this
    Object.defineProperties(this, {
      __modules: {
        get() {
          const filteredModules = self.__list.filter(mod => mod.sets.filter(set => set.allow_company_view).length > 0)
          return (
            filteredModules.map(mod => ({ ...mod, sets: mod.sets.filter(set => set.allow_company_view) }))
          )
        }
      }
    })
  }

  onRefForm = (ref) => {
    this._form = ref
  }

  isChecked = (permission) => {
    for (const mod of this.myProfile.company.modules) {
      for (const perm of mod.permissions) {
        if (perm.pk === permission.pk) {
          return true
        }
      }
    }

    return false
  }

  ButtonRole = (formProps) => {
    const { checked, disabled, form, permission } = formProps

    return (
      <Card
        hoverable
        bodyStyle={{
          padding: '12px',
          ...(disabled && { cursor: 'not-allowed' }),
          ...(checked && { backgroundColor: '#1890ff', color: 'white' })
        }}
        onClick={() => {
          if (!disabled) {
            form.setFieldsValue({
              [permission.pk]: !checked
            })
          }
        }}
      >
        <Row align='middle' gutter={[12, 6]}>
          <Col>
            {checked
              ? <UnlockOutlined style={{ fontSize: '21px' }} />
              : <LockOutlined style={{ fontSize: '21px' }} />
            }
          </Col>
          <Col className='text-truncate' style={{ fontSize: '18px', fontWeight: 500, flex: 1 }}>
            {permission.name}
          </Col>
        </Row>
      </Card>
    )
  }

  getFieldsValue = () => {
    return new Promise((resolve, reject) => {
      this._form.validateFields().then(values => {
        const selectedKeys = Object.keys(values).filter(key => values[key])
        const modules = this.__modules.map(mod => ({ alias: mod.alias, permissions: [] }))

        for (const mod of this.__modules) {
          for (const set of mod.sets) {
            for (const perm of set.permissions) {
              if (selectedKeys.includes(String(perm.pk))) {
                let currentIndex = modules.findIndex(val => val.alias === mod.alias)
                if (currentIndex === -1)
                  currentIndex = modules.push({ alias: mod.alias, permissions: [] }) - 1

                if (set.allow_company_manage)
                  modules[currentIndex].permissions.push(perm.pk)
              }
            }
          }
        }

        resolve(modules)
      }).catch(error => {
        reject(error)
      })
    })
  }

  render() {
    return (
      <Spin spinning={this.state.isLoading}>
        <Form ref={this.onRefForm}>
          <Row justify='space-between'>
            <Col style={{ fontSize: '21px', fontWeight: 500 }}>
              Permissions
            </Col>
          </Row>
          <div>
            {this.__modules.map(mod => (
              <div key={mod.alias}>
                <Divider>
                  {mod.aka_name}
                </Divider>
                <Row gutter={[12, 12]} style={{ marginBottom: '24px' }}>
                  {mod.sets.map(set => (
                    set.permissions.map(permission => (
                      <Col key={permission.code} xs={24} sm={24} xl={12}>
                        <Form.Item noStyle shouldUpdate>
                          {(form) => (
                            <Form.Item noStyle name={permission.pk} valuePropName='checked' initialValue={this.isChecked(permission)}>
                              <this.ButtonRole
                                disabled={!set.allow_company_manage}
                                form={form}
                                permission={permission}
                              />
                            </Form.Item>
                          )}
                        </Form.Item>
                      </Col>
                    ))
                  ))}
                </Row>
              </div>
            ))}
          </div>
        </Form>
      </Spin>
    )
  }
}

export default CompanyPermissions