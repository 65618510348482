import React from 'react'

import { BaseComponent } from 'src/components'
import ImgArchived from 'src/assets/archived.jpg'

// Untuk handle should rendered records/columns
class Image extends BaseComponent {
  constructor(props) {
    super(props)

    this.isError = false
  }

  render() {
    return (
      <img
        {...this.props}
        alt='screenshot'
        onError={(event) => {
          if (this.isError) return

          event.target.src = ImgArchived
          event.target.style.opacity = 0.3
          event.target.onError = null
          this.isError = true
        }}
      />
    )
  }
}

export default Image