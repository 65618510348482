import React from 'react'
import { Spin } from 'antd'
import { SaveOutlined } from '@ant-design/icons'

import { BaseModal } from 'src/components'
import Content from './Content'
import { get } from 'lodash'

class EditUser extends BaseModal {
  constructor(props) {
    super(props)

    this.title = 'Edit User'

    this.staticModalProps = {
      destroyOnClose: true,
      width: '720px',
      style: { maxWidth: '90%' },
    }

    this.defaultState = { user: null, isLoading: false }
  }

  getModalProps = () => ({
    destroyOnClose: true,
    width: '720px',
    style: { maxWidth: '90%' },
    okText: 'Save',
    onOk: this.onSave,
    okButtonProps: {
      loading: this.state.isLoading,
      icon: <SaveOutlined />,
    }
  })

  onRefContent = (ref) => {
    this._content = ref
  }

  renderContent = () => {
    return this.state.user ? (
      <Spin spinning={this.state.isLoading}>
        <Content
          ref={this.onRefContent}
          user={this.state.user}
          onSave={this.onSave}
        />
      </Spin>
    ) : null
  }

  onSave = () => {
    if (this.state.isLoading) return

    this._content._form.validateFields()
      .then(values => {
        this.setThisState({ isLoading: true }, () => {
          const data = new FormData()
          data.set('first_name', values.first_name || '')
          data.set('last_name', values.last_name || '')
          data.set('employee_id', values.employee_id || '')
          if (get(values.thumbnail, [0, 'originFileObj'])) {
            data.set('photo', values.thumbnail[0].originFileObj)
          }

          this.props.patch(
            data,
            this.state.user.alias,
            success => {
              this.setThisState({ visible: !success, isLoading: false })
            }
          )
        })
      })
      .catch((e) => {})
  }
}

export default EditUser