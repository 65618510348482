import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import users from './users';

const userActivities = [
  {
    user: users.objUsers['02.Wilhelmina'],
    "task": {
      "alias": "ZjQxMGZiMTUtNTU2Ny00YTljLWJlNjMtMzgzM2MxZThhOWRhLVQxNjgzMjU3MjU3LjYzNDM1My1TN2ZkcVVYek9jSU1xUEp1Y3dVRXNnVGZaMTNhblByMkg4bURD",
      "name": "Test 2",
      "is_permanent": true
    },
    "note": null,
    // "screenshots": [],
    // "windows": [],
    "start_time": "2023-05-27T01:00:00Z",
    "finish_time": "2023-05-27T05:00:00Z",
    "is_manual_time": true,
    // "click_count": 0,
    // "type_count": 0
  },
  {
    user: users.objUsers['02.Wilhelmina'],
    "task": {
      "alias": "ZjQxMGZiMTUtNTU2Ny00YTljLWJlNjMtMzgzM2MxZThhOWRhLVQxNjgzMjU3MjU3LjYzNDM1My1TN2ZkcVVYek9jSU1xUEp1Y3dVRXNnVGZaMTNhblByMkg4bURD",
      "name": "Test 2",
      "is_permanent": true
    },
    "note": null,
    "start_time": "2023-05-28T01:00:00Z",
    "finish_time": "2023-05-28T05:00:00Z",
    "is_manual_time": true,
  },
].map(item => ({ ...item, alias: uuidv4() }))

function getUserActivities(userId, strDate) {
  const monthFormat = 'YYYY-MM'

  return userActivities.filter(userActivity => (
    userActivity.user.id === userId &&
    moment(strDate).format(monthFormat) === moment(userActivity.start_time).format(monthFormat)
  ))
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userActivities,
  getUserActivities,
}