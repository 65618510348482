import PropTypes from 'prop-types';
import { Button, Col, Modal, Row, Spin, message } from 'antd';
import { AuditOutlined, ReloadOutlined } from '@ant-design/icons';
import { get, orderBy } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { VisibilityContainer } from 'src/components';
import AttendanceDaily from '../../Attendance/Daily';
import BaseUserLeave from '../AnnualLeave';
import SubmissionOvertimeHeader from './Header';
import SubmissionOvertimeContent from './Content';
import AllSubmissions from './AllSubmissions';
import DialogOvertimeDetail from './DialogOvertimeDetail';
import { lib, permissions } from 'src/Utils';
import example from '../example';
import helper from '../helper';

class SubmissionOvertime extends BaseUserLeave {
  constructor(props) {
    super(props)

    this.urlKey = 'overtime-user-overtimes'
    this.title = 'Apply Overtime'
    this.source_type = null
    window._example = example

    this.state = {
      ...this.state,
      userActivities: [],
    }
  }

  getIsApproval = () => permissions.canApproveOvertime()

  onRefDialogOvertimeDetail = (ref) => this._dialogOvertimeDetail = ref

  setDate = (date) => {
    const prevMonth = moment(this.date).format('YYYY-MM')
    this.date = moment(date).format()

    if (moment(date).format('YYYY-MM') !== prevMonth)
      this.forceRead()
    else {
      this.forceUpdate()

      const userOvertimes = helper._overtime.getOvertimes(date, this.__list)
      const isAllowSubmission = helper._overtime.allowSubmission(date, userOvertimes, this.user)

      if (isAllowSubmission || userOvertimes.length) {
        // if (this.state.exampleProfileCurrentMonth.allowance_leave > this.__list.length)
          this._dialogOvertimeDetail.show({ defaultDate: date, userOvertimes })
        // else
        //   message.info('Your leave entitlement for this month is vacant')
      } else {
      }
    }
  }

  convertResponseData = (userOvertimes) => {
    return orderBy(userOvertimes, 'created')
  }

  // convertResponseData = () => {
  //   console.log('convertResponseData', this._selectedUser)
  //   // window._helper = helper
  //   this.setState({ userActivities: example.getUserActivities(this.user, this.date) })
  //   return orderBy(example.getUserOvertimes(this.user, this.date), 'start_date')
  // }

  patchUserLeave = (userLeave, patchData) => {
    const foundIndex = this.__list.findIndex(item => item.alias === userLeave.alias)
    if (foundIndex !== -1) {
      this.patchList([{ ...this.__list[foundIndex], ...patchData }])
      if (this._dialogOvertimeDetail) this._dialogOvertimeDetail.hide(null, undefined, true)

      // this.state.exampleUserLeaves[foundIndex] = { ...this.state.exampleUserLeaves[foundIndex], ...patchData }
      // this.setState({ exampleUserLeaves: [...this.state.exampleUserLeaves] }, () => {
      //   if (this._dialogLeaveDetail) this._dialogLeaveDetail.hide(null, undefined, true)
      // })
    }
  }

  savedOvertimeHandler = (userOvertime) => {
    this.patchList([userOvertime], 'pk')
  }

  renderHeader() {
    return (
      <SubmissionOvertimeHeader
        user={this._selectedUser}
        users={this.users} // this value for listening read activity, be careful
        date={this.date}

        userOvertimes={this.__list}
        // userActivities={this.state.userActivities}
        onSelectUser={this.onChangeUser}
      />
    )
  }

  renderDialogs = () => (
    <DialogOvertimeDetail
      ref={this.onRefDialogOvertimeDetail}
      user={this._selectedUser}
      // onSaveLeave={this.saveLeaveHandler}
      onSavedOvertime={this.savedOvertimeHandler}
      // onAccept={this.acceptHandler}
      // onReject={this.rejectHandler}
    />
  )

  renderContent() {
    return (
      <div>
        {this.renderDialogs()}

        <SubmissionOvertimeContent
          user={this._selectedUser}
          // userAttendances={this.__list}
          date={this.date}
          // userLeaves={this.state.exampleUserLeaves.filter(item => moment(item.date).format('YYYY-MM') === moment(this.date).format('YYYY-MM'))}
          // userLeaves={this.__list}
          userOvertimes={this.__list}
          userActivities={this.state.userActivities}
          setDate={this.setDate}
        />
      </div>
    )
  }

  renderAnotherContainers() {
    return (
      <div>
        <VisibilityContainer visible={get(lib.getObjParam(this.props.location.search), 'type') === 'all'}>
          <AllSubmissions
            onClose={() => {
              this.forceRead()
              this.pushParams({ type: 'calendar' })
            }}
          />
        </VisibilityContainer>
      </div>
    )
  }
}

SubmissionOvertime.propTypes = {
  ...SubmissionOvertime.propTypes,
}

SubmissionOvertime.defaultProps = {
  ...SubmissionOvertime.defaultProps,
}

export default SubmissionOvertime