import modules from './staticModules'
import permissions from './staticPermissions'
import { lib } from 'src/Utils'

function hasManagerProd(currentUser) {
  const user = currentUser || lib.ls.getCurrentUser()

  const hasCompanyPointReward = user.company.modules.findIndex(mod => mod.codename === modules.POINT_REWARD) !== -1
  const hasUserManagerProd = user.permissions.findIndex(perm => perm === permissions.VIEW_MANAGER_PRODUCTION) !== -1

  return hasCompanyPointReward && (hasUserManagerProd || user.is_owner)
}

export default hasManagerProd