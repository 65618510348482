import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Col, Row, Spin } from 'antd'
import { RiseOutlined } from '@ant-design/icons'
import moment from 'moment'

import { BaseComponent, PageError } from 'src/components'
import { lib } from 'src/Utils'
import { CardHeader } from 'src/pages/DashboardApp/Components'

const MIN_TOTAL = 28800000 // 8 hours

class Timeline extends BaseComponent {
  constructor(props) {
    super(props)

    this.totalWorked = 0
    this.totalEdited = 0
    this.total = this.totalWorked + this.totalEdited

    this.state = {
      errorResponse: null,
      isLoading: false,
      timelines: [],
    }
  }

  convertData = (activities) => {
    this.setThisState({ timelines: this.getTimelines(activities) })
  }

  getTimelines = (activities) => {
    const timeline = {}
    for (const i in activities) {
      const date = moment(activities[i].start_time).format('YYYY-MM-DD')

      if (!timeline[date]) timeline[date] = 0
      timeline[date] += moment(activities[i].finish_time).diff(moment(activities[i].start_time))
    }

    this.maxTotal = MIN_TOTAL

    const timelines = []
    const endTime = moment(this.props.endDate).format('YYYY-MM-DD')
    for (let date = moment(this.props.startDate); date.format('YYYY-MM-DD') <= endTime; date.add(1, 'days')) {
      const dateStr = date.format('YYYY-MM-DD')
      if (this.maxTotal < timeline[dateStr])
        this.maxTotal = timeline[dateStr]

      timelines.push({
        date: dateStr,
        total: timeline[dateStr] || 0,
      })
    }

    this.totalWorked = 0
    this.totalEdited = 0
    for (let i = 0; i < activities.length; i += 1) {
      if (activities[i].is_manual_time)
        this.totalEdited += lib.time.getDiff(activities[i])
      else
        this.totalWorked += lib.time.getDiff(activities[i])
    }

    this.total = this.totalWorked + this.totalEdited

    return timelines
  }

  getBarWidth = (totaltime) => {
    let pct = totaltime / this.maxTotal
    if (pct > 1) pct = 1

    return `${Math.round(pct * 100)}%`
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.activities !== nextProps.activities) {
      this.convertData(nextProps.activities)
    }
  }

  componentDidMount() {
    this.setMounted()
    this.convertData(this.props.activities)
  }

  render() {
    const { isManaged } = this.props

    return (
      <Card className='app-card' bodyStyle={{ padding: 0 }} style={{ width: '100%' }}>
        <Spin spinning={this.props.isLoading}>
          {this.state.errorResponse ? (
            <PageError
              errorResponse={this.state.errorResponse}
            />
          ) : (
            <>
              <div style={{ padding: '12px' }}>
                <CardHeader
                  title='Timeline'
                  iconLink={<RiseOutlined />}
                  link={isManaged && '/report/timeline/'}
                  params={lib.getStrParam({
                    start_date: moment().format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD'),
                    id: this.props.userId,
                  })}
                  labelLink='Timeline Daily Report'
                />
                <Row justify='space-between' gutter={[0, 12]}>
                  <Col>
                    <div style={{ fontSize: '24px', fontWeight: 500 }}>
                      {lib.time.getLabel(this.totalWorked)}
                    </div>
                    <div style={{ color: '#9c9c9c', fontSize: '16px', lineHeight: '20px' }}>
                      <span className='timeline-identity-color identity-worked-time' />
                      Total Time Worked
                    </div>
                  </Col>
                  <Col>
                    <div style={{ fontSize: '24px', fontWeight: 500 }}>
                      {lib.time.getLabel(this.totalEdited)}
                    </div>
                    <div style={{ color: '#9c9c9c', fontSize: '16px', lineHeight: '20px' }}>
                      <span className='timeline-identity-color identity-edited-time' />
                      Edited Work Time
                    </div>
                  </Col>
                  <Col>
                    <div style={{ fontSize: '24px', fontWeight: 500 }}>
                      {lib.time.getLabel(this.total)}
                    </div>
                    <div style={{ color: '#9c9c9c', fontSize: '16px', lineHeight: '20px' }}>
                      Total Time
                    </div>
                  </Col>
                </Row>
              </div>
              <Row style={{ width: '100%', overflowX: 'auto', flexFlow: 'nowrap', whiteSpace: 'nowrap' }}>
                {this.state.timelines.map(timeline => (
                  <div key={timeline.date} className='timeline-item' title={`Click to open Timeline Daily Report on ${moment(timeline.date).format('DD MMMM YYYY')}`}>
                    <Link
                      to={!isManaged ? '/' :
                        `/report/timeline/` +
                        lib.getStrParam({
                          start_date: moment(timeline.date).format('YYYY-MM-DD'),
                          end_date: moment(timeline.date).format('YYYY-MM-DD'),
                          id: this.props.userId,
                        })
                      }
                      style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                      onClick={e => {
                        if (!isManaged) {
                          e.stopPropagation()
                          e.preventDefault()
                        }
                      }}
                    >
                      <div style={{ fontWeight: 500, padding: '12px', textAlign: 'center' }}>
                        {/* {moment(time.date).format('ddd, MMM DD, YYYY')} */}
                        <div>{moment(timeline.date).format('dddd')}</div>
                        <div>{moment(timeline.date).format('MMM DD, YYYY')}</div>
                      </div>
                      <div style={{ borderTop: '1px solid #e8e8e8', padding: '12px' }}>
                        {/* {moment.duration({ seconds: time.totaltime })._data.hours}h {moment.duration({ seconds: time.totaltime })._data.minutes}m */}
                        {/* <Tooltip overlay={`${moment.duration(timeline.total)._data.hours}h ${moment.duration(timeline.total)._data.minutes}m`}> */}
                        {/* <Tooltip overlay={lib.time.getLabel(timeline.total)}> */}
                          <div style={{ borderRadius: '8px', backgroundColor: '#e8e8e8', overflow: 'hidden' }}>
                            <div
                              style={{
                                backgroundColor: '#1890ff',
                                borderRadius: '8px',
                                // border: '1px solid #0b78de',
                                width: this.getBarWidth(timeline.total),
                                height: '16px',
                              }}
                            />
                          </div>
                        {/* </Tooltip> */}
                        <div style={{ textAlign: 'center' }}>
                          {lib.time.getLabel(timeline.total)}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}



                {/* {this.state.timeline.map((time, i) => (
                  <div key={i} className='timeline-item'>
                    <div style={{ fontWeight: 500, padding: '12px', textAlign: 'center' }}>
                      <div>{moment(time.date).format('dddd')}</div>
                      <div>{moment(time.date).format('MMM DD, YYYY')}</div>
                    </div>
                    <div style={{ borderTop: '1px solid #e8e8e8', padding: '12px' }}>
                      <Tooltip overlay={`${moment.duration({ seconds: time.totaltime })._data.hours}h ${moment.duration({ seconds: time.totaltime })._data.minutes}m`}>
                        <div style={{ borderRadius: '8px', backgroundColor: '#e8e8e8' }}>
                          <div
                            style={{
                              backgroundColor: '#1890ff',
                              borderRadius: '8px',
                              border: '1px solid #0b78de',
                              width: this.getBarWidth(time.totaltime),
                              height: '16px',
                            }}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                ))} */}
              </Row>
            </>
          )}
        </Spin>
      </Card>
    )
  }
}

export default Timeline