import React from 'react'
import PropTypes from 'prop-types'
import { Popover, Spin, Tooltip } from 'antd'
import { GiftOutlined, LoadingOutlined, TagOutlined } from '@ant-design/icons'
import NumberFormat from 'react-number-format'
import moment from 'moment'

import BaseComponent from '../BaseComponent'
import { lib, permissions, request } from 'src/Utils'
import typeCodes from 'src/Utils/static/typeCodes'

class PointUser extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      visible: false,
      user: props.user, // user type
    }
  }

  readType = () => {
    if (this.state.isLoading)
      return

    this.setThisState({ isLoading: true }, () => {
      request({
        urlKey: 'read-userPointTypes',
        data: { id: this.props.userId, date: moment(this.props.prodMonth).startOf('month').format('YYYY-MM-DD') },
        onSuccess: this.readTypeSuccess,
        onFailed: this.readTypeFailed,
      })
    })
  }

  readTypeSuccess = (response) => {
    const user = response.data.find(user => user.id === this.props.userId)

    setTimeout(() => {
      this.setThisState({ isLoading: false, user })
    }, 500)
  }

  readTypeFailed = (error) => {
    this.setThisState({ isLoading: false })
  }

  renderContent = () => {
    const { user } = this.state

    if (!user)
      return undefined

    return (
      <div>
        <div style={{ fontWeight: 500 }}>
          {lib.getFullName(user)} ({user.point_types.map(type => type.code_display).join(', ') || 'No Point Type'})
        </div>
        {lib.getHasBonus(user, this.props.prodMonth) ? (
          this.getBonusElem()
        ) : '(No Reward)'}
      </div>
    )
  }

  getBonusElem = () => {
    let value = 0
    // const trainerType = this.state.user.point_types.find(type => typeCodes.TRAINER_CODES.includes(type.code))
    const staticCodes = [...typeCodes.TRAINER_CODES, ...typeCodes.MANAGER_CODES]
    const staticType = this.state.user.point_types.find(type => staticCodes.includes(type.code))

    if (staticType) {
      value = (staticType.denominator) * this.props.effectiveExchange.rate
    } else {
      value = this.props.point * this.props.effectiveExchange.rate
    }

    return (
      <div>
        <GiftOutlined /> Reward :
        <NumberFormat
          thousandSeparator
          displayType='text'
          prefix=' Rp '
          value={Math.round(value * this.props.typeExchangeFactor)}
        />
      </div>
    )
  }

  onVisibleChange = (visible) => {
    if (this.props.allowBonus || !visible)
    this.setThisState({ visible }, () => visible && !this.state.user && this.readType())
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.userId !== nextProps.userId) {
      this.setThisState({ visible: false, user: null })
    }

    if (this.props.user !== nextProps.user) {
      this.setThisState({ user: nextProps.user })
    }
  }

  render() {
    const { placement, point } = this.props

    return (
      <Tooltip
        color='blue'
        placement={placement}
        trigger={['click']}
        visible={this.state.visible}
        onVisibleChange={this.onVisibleChange}
        overlayStyle={{ maxWidth: '300px' }}
        title={permissions.canViewReward(this.props.user) && (
          <Spin
            spinning={this.state.isLoading}
            indicator={<LoadingOutlined style={{ color: 'white' }} />}
          >
            {this.renderContent()}
          </Spin>
        )}
      >
        <span style={{ cursor: this.props.allowBonus && 'pointer' }}>
          {/* Jika point pasti ada dan pasti number, hapus fungsi Number */}
          {Number(point).toFixed(2)}
        </span>
      </Tooltip>
    )
  }
}

PointUser.propTypes = {
  prodMonth: PropTypes.string.isRequired, // ex: 2021-09-01 (month of current parameter)
}

PointUser.defaultProps = {
  allowBonus: true,
  point: 0, // required
  effectiveExchange: null, // object
  userId: null, // for retrieve user with point_types
  placement: 'right',
  typeExchangeFactor: 1, // user_point_type.exchange_factor

  // Jika sudah disiapkan user dengan format dibawah ini, maka tidak perlu mengirim "userId"
  user: null, // { first_name, last_name, is_bonus, point_types }
}

export default PointUser