import getObjParam from './getObjParam'
import getStrParam from './getStrParam'

export default function getHref(href, keys) {
  if (!window || !window.location) return href

  if (!Array.isArray(keys)) keys = []

  const temp = getObjParam(window.location.search)

  const objParam = {}
  for (const key in temp) {
    let allowKey = true

    if (keys.length) {
      allowKey = keys.indexOf(key) !== -1
    }

    if (allowKey) {
      objParam[key] = temp[key]
    }
  }

  return `${href}${getStrParam(objParam)}`
}