import BaseAreaByGrade from 'src/pages/DashboardApp/Dashboard/Report/UserProductionJobs/AreaByGrade'

class AreaByGrade extends BaseAreaByGrade {
  constructor(props) {
    super(props)

    this.a = 'Average Point / m²'
  }

  setAverage = (productionJobs, nextProps = this.props) => {
    let totalJobs = productionJobs.length,
      totalPoint = productionJobs.reduce((acc, val) => acc + Number(val.qs_point), 0)

    this.averagePerDay = nextProps.totalWorkingDays
      ? (totalPoint / this.totalValue).toFixed(3)
      : 'N/A'
    this.averagePerJob = Math.round(this.totalValue / totalJobs)
    console.log('here setAverage', nextProps.totalWorkingDays, totalPoint, this.totalValue, Math.round(totalPoint / this.totalValue), productionJobs, this)
  }
}

export default AreaByGrade