import PropTypes from 'prop-types';
import { Avatar, Badge, Button, Calendar, Col, DatePicker, Popover, Row, Spin } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined, EllipsisOutlined, UserOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';

import { BaseComponent } from 'src/components';
import { lib, request } from 'src/Utils';
import PopoverContentUser from './PopoverContentUser'
import DialogListUser from './DialogListUser';
import UserPhotoStatus from '../Base/UserPhoto';

const MAX_USER_ICON = 3
const MONTH_FORMAT = 'YYYY-MM'

class StaffLeaveCalendar extends BaseComponent {
  constructor(props) {
    super(props)

    this.setSelectedMonth()

    this.state = {
      ...this.state,
      isLoading: false,
      calendarData: {},
    }
  }

  onRefDialogListUser = (ref) => this._dialogListUser = ref

  setSelectedMonth = (date) => {
    // yang merubah variable "selectedMonth" hanya di fungsi ini
    this.selectedMonth = moment(date).format() // (MONTH_FORMAT)
  }

  prevMonth = () => {
    this.onChangeDate(moment(this.selectedMonth).subtract(1, 'month'))
  }

  nextMonth = () => {
    this.onChangeDate(moment(this.selectedMonth).add(1, 'month'))
  }

  onChangeDate = (date) => {
    if (date.format(MONTH_FORMAT) !== moment(this.selectedMonth).format(MONTH_FORMAT)) {
      this.setSelectedMonth(date)
      this.reload()
    }
  }

  reload = () => {
    this.readCalendar()
  }

  readCalendar = (nextProps = this.props) => {
    this.setState({ isLoading: true }, () => {
      request({
        urlKey: this.props.urlKey,
        data: {
          source_type: 'user_apply',
          related: 'user',
          view_mode: 'calendar',
          status: nextProps.status,
          user: nextProps.users.map(user => user.id).join(','),
          date_before: moment(this.selectedMonth).startOf('month').startOf('week').format('YYYY-MM-DD'),
          date_after: moment(this.selectedMonth).endOf('month').add(1, 'week').endOf('week').format('YYYY-MM-DD'),
        },
        onSuccess: this.readCalendarSuccess,
        onFailed: this.readCalendarFailed,
      })
    })
  }

  readCalendarSuccess = (res) => {
    const calendarData = {}
    for (const userLeave of res.data) {
      const diffDays = moment(userLeave.finish_date).diff(moment(userLeave.start_date), 'days')
      for (let i = 0; i <= diffDays; i += 1) {
        const date = moment(userLeave.start_date).add(i, 'days').format('YYYY-MM-DD')

        // ----- manual skip saturday and sunday -----
        if ([0, 6].includes(moment(date).day())) continue

        // ----- split userLeave from range to dates -----
        if (!calendarData[date]) calendarData[date] = []
        calendarData[date].push(userLeave)
      }
    }

    this.setState({ isLoading: false, calendarData })
  }

  readCalendarFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.setState({ isLoading: false })
  }

  showList = (date) => {
    const usersLeave = get(this.state.calendarData, moment(date).format('YYYY-MM-DD'), [])
    if (usersLeave.length)
      this._dialogListUser.show({ date, usersLeave })
  }

  didMount() {
    this.reload()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.status !== nextProps.status) {
      this.readCalendar(nextProps)
    }
  }

  renderHeader = () => {
    return (
      <div className='ant-picker-calendar-header'>
        <Row justify='space-between' gutter={[12, 12]} style={{ width: '100%', padding: '0 12px 12px 12px', borderBottom: '1px solid #e8e8e8' }}>
          <Col>
            <div style={{ fontSize: '20px', fontWeight: 'bold', letterSpacing: '1px' }}>
              {moment(this.selectedMonth).format('MMMM YYYY')}
            </div>
            <div style={{ color: '#aaa', fontStyle: 'italic' }}>
              {this.note}
            </div>
          </Col>
          <Col>
            <Row gutter={[6, 6]}>
              <Col>
                <Button
                  icon={<CaretLeftOutlined />}
                  onClick={this.prevMonth}
                  style={{ marginRight: '-1px' }}
                />
                <DatePicker
                  picker='month'
                  className='calendar-group-center'
                  allowClear={false}
                  value={moment(this.selectedMonth)}
                  onChange={this.onChangeDate}
                />
                <Button
                  icon={<CaretRightOutlined />}
                  onClick={this.nextMonth}
                  style={{ marginLeft: '-1px' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }

  renderPopoverContent = (userLeave) => <PopoverContentUser userLeave={userLeave} />

  renderPopover = (userLeave) => {
    return (
      <Popover
        overlayClassName='ant-popover-profile'
        placement='left'
        mouseLeaveDelay={0}
        content={this.renderPopoverContent(userLeave)}
      >
        <UserPhotoStatus
          allowText
          size={42}
          user={userLeave.user}
          status={userLeave.status}
          onClick={(e) => {
            e.stopPropagation()
            this.props.showDetail(userLeave)
          }}
        />
      </Popover>
    )
  }

  renderCellDate = (date) => {
    const dateStr = date.format('YYYY-MM-DD')
    const isCurrentMonth = date.format('YYYY-MM') === moment(this.selectedMonth).format('YYYY-MM')
    const usersLeave = get(this.state.calendarData, dateStr, [])

    return (
      <div
        className={classNames('ant-picker-cell-inner ant-picker-calendar-date', {
          'ant-picker-calendar-date-today': isCurrentMonth && date.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'),
        })}
        onClick={() => this.showList(dateStr, usersLeave)}
      >
        <div className='ant-picker-calendar-date-value'>
          <Row justify="space-between">
            <Col>
            </Col>
            <Col>
              {date.format('DD')}
            </Col>
          </Row>
        </div>
        <div className='ant-picker-calendar-date-content' style={{ overflowY: 'hidden' }}>
          <div className='w-100 webkit-box' style={{ padding: '0 12px' }}>
            {usersLeave.map(userLeave => (
              <span key={userLeave.pk}>
                {this.renderPopover(userLeave)}
              </span>
            ))}
          </div>
        </div>
      </div>
    )
  }

  renderDialogs = () => (
    <div>
      <DialogListUser ref={this.onRefDialogListUser} showDetail={this.props.showDetail} />
    </div>
  )

  render() {
    return (
      <Spin spinning={this.state.isLoading}>
        {this.renderDialogs()}

        <Calendar
          className='calendar-attendance'
          value={moment(this.selectedMonth)}
          headerRender={this.renderHeader}
          dateFullCellRender={this.renderCellDate}
          onSelect={this.onChangeDate}
        />
      </Spin>
    )
  }
}

StaffLeaveCalendar.propTypes = {
  ...StaffLeaveCalendar.propTypes,
  urlKey: PropTypes.string.isRequired,
  users: PropTypes.array,
  status: PropTypes.string,
  showDetail: PropTypes.func,
}

StaffLeaveCalendar.defaultProps = {
  ...StaffLeaveCalendar.defaultProps,
  users: [],
  status: 'approved',
  showDetail: () => {},
}

export default StaffLeaveCalendar