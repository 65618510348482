import PropTypes from 'prop-types';
import { Form, Input, Spin } from 'antd';
import { get } from 'lodash';

import { BaseComponent } from 'src/components';
import { lib, request } from 'src/Utils';

class BaseField extends BaseComponent {
  constructor(props) {
    super(props)

    this.fieldKey = '' // required when no override getDefaultValue
    this.wrapperClassName = 'container-production-input webkit-box two-lines'
    if (props.readOnly) this.wrapperClassName += ' readonly'
    else this.wrapperClassName += ' no-readonly'

    this.state = {
      isLoading: false,
      isEditing: false,
      value: this.getDefaultValue(),
    }
  }

  getFieldKey = () => this.fieldKey

  // onRefInput = (ref) => {
  //   if (typeof get(ref, 'focus') === 'function') ref.focus()
  // }

  onClickWrapper = () => {
    if (!this.props.readOnly && !this.state.isEditing) {
      this.setEditing(true)
    }
  }

  onChangeInput = (event) => {
    this.setState({ value: this.getValueFromEvent(event) }, () => {
      this.changedInputValue(this.getValueFromEvent(event))
    })
  }

  isTouched = () => this.getUniqueValue(this.state.value) !== this.getUniqueValue(this.getDefaultValue())

  onBlurInput = async () => {
    if (this.isTouched())
      this.patch()
    else
      this.setEditing(false)
  }

  onKeyDownInput = (e) => {
    if (e.key === 'Escape') {
      this.onEscape()
    } else if (!e.shiftKey && e.key === 'Enter') {
      e.preventDefault()
      this.onBlurInput()
    }
  }
  
  onEscape = () => {
    this.setEditing(false)
  }

  // don't override
  setEditing = (isEditing) => {
    this.setState({ isEditing, isLoading: false, value: this.getDefaultValue() })
  }

  getDefaultInputProps = () => ({
    autoFocus: true,
    size: 'small',
    value: this.state.value,
    onChange: this.onChangeInput,
    onBlur: this.onBlurInput,
    // onPressEnter: this.onBlurInput, // 
    onKeyDown: this.onKeyDownInput,
  })

  patch = () => {
    this.setState({ isLoading: true }, () => {
      request({
        method: 'patch',
        urlKey: 'change-productionJobsDashboard',
        args: [this.props.prodJobId],
        data: this.getPatchData(),
        onSuccess: this.patchSuccess,
        onFailed: this.patchFailed,
      })
    })
  }

  patchSuccess = (res) => {
    this.setEditing(false)
    this.props.onChangeItem(res.data)
  }

  patchFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.setState({ isLoading: false })
    // this.setEditing(false)
  }

  renderTextWrapper = () => <div style={{ padding: '0 7px' }}>{this.renderText()}</div>
  renderInputWrapper = () => this.renderInput()

  // Need to Override
  getDefaultValue = (props = this.props) => props.value
  getUniqueValue = (value) => value
  getValueFromEvent = (event) => event.target.value
  changedInputValue = () => {}
  getPatchData = () => null
  renderText = () => this.state.value || this.props.emptyText
  renderInput = () => <Input ref={this.onRefInput} {...this.getDefaultInputProps()} />

  renderWrapper = () => this.renderContent()
  renderContent = () => !this.state.isEditing
    ? this.renderTextWrapper()
    : this.renderInputWrapper()

  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log('CWRP', this.props.value !== nextProps.value, this.props.value, nextProps.value, this)
    if (this.props.value !== nextProps.value) {
      this.setState({ value: this.getDefaultValue(nextProps) })
    }
  }

  render() {
    // if (this.fieldKey === 'subhead')
    // console.log('Rendered', this.state.value, this.props.value, this)
    const title = this.renderText()

    return (
      <Spin spinning={this.state.isLoading}>
        <div
          className={this.wrapperClassName}
          style={{ minHeight: '18px' }}
          title={typeof title === 'string' && title}
          onClick={this.onClickWrapper}
        >
          {this.renderWrapper()}
        </div>
      </Spin>
    )
  }
}

BaseField.propTypes = {
  ...BaseField.propTypes,
  prodJobId: PropTypes.number.isRequired,
  value: PropTypes.any,
  emptyText: PropTypes.node,
  // subID: PropTypes.string.isRequired,
  // jobItem: PropTypes.object.isRequired,
  onChangeItem: PropTypes.func,
}

BaseField.defaultProps = {
  ...BaseField.defaultProps,
  value: null,
  emptyText: '-',
  onChangeItem: () => {},
}

export default BaseField