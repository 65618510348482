import React from 'react'
import { Button, Card, Col, DatePicker, Popover, Row, Spin } from 'antd'
import { CaretLeftOutlined, CaretRightOutlined, InfoCircleOutlined, ReloadOutlined, SyncOutlined } from '@ant-design/icons'
import { get, startCase } from 'lodash'
import moment from 'moment'

import { lib, request } from 'src/Utils'
import { BaseComponent, PageError } from 'src/components'
import Point from './Point'
import AmountByGrade from './AmountByGrade'
import AreaByGrade from './AreaByGrade'
import JobByGrade from './JobByGrade'
import { mutableScreen } from 'src/mutable'

const { MonthPicker } = DatePicker

class UserProduction extends BaseComponent {
  constructor(props) {
    super(props)

    this.title = 'Performance'
    this.readOnly = false

    this.selectedDate = props.dateString

    this.state = {
      isLoading: false,
      errorResponse: null,
    }
  }

  onChangeFilter = (e) => {
    this.read()
  }

  patchTarget = (data, callback) => {
    request({
      method: 'patch',
      urlKey: 'change-productionTargets',
      args: [this.props.targets.alias],
      data,
      onSuccess: this.patchTargetSuccess,
      onFailed: this.patchTargetFailed,
      extra: { callback },
    })
  }

  patchTargetSuccess = (response, extra) => {
    this.props.setTargets(response.data)
    // this.setThisState({ target: response.data })
    extra.callback()
  }

  patchTargetFailed = (error, extra) => {
    lib.responseMessage.error(error.response)
    extra.callback()
  }

  syncTargets = async (targetAlias) => {
    return await request({
      method: 'post',
      urlKey: 'sync-productionTargets',
      args: [targetAlias],
    })
  }

  getAllowSync = () => {
    const allowedState = ['new', 'failed', 'synced']

    return !this.props.loading && allowedState.includes(get(this.props.targets, 'sync_state'))
  }

  manualSync = async () => {
    if (this.state.syncing)
      return

    this.setThisState({ syncing: true }, () => {
      request({
        method: 'post',
        urlKey: 'sync-productionTargets',
        args: [this.props.targets.alias],
        onSuccess: this.syncSuccess,
        onFailed: this.syncFailed,
      })
    })
  }

  syncSuccess = (response) => {
    this.props.setTargets({ ...this.props.targets, ...response.data })
    setTimeout(() => {
      this.setThisState({ syncing: false })
      this.props.reload()
    }, 500)
  }

  syncFailed = (error) => {
    lib.responseMessage.error(error.response)
    this.setThisState({ syncing: false })
  }

  getSyncTitle = () => {
    if (!this.props.targets)
      return null

    const { sync_state } = this.props.targets
    const colors = { new: '#1890ff', syncing: '#fcda55', failed: '#ff4d4f', synced: '#10bd10' }

    return (
      <div style={{ minWidth: '240px', }} onClick={e => {e.stopPropagation()}}>
        <Row justify='space-between'>
          <Col>Sync Status:</Col>
          <Col style={{ color: colors[sync_state] || 'black' }}>
            {startCase(sync_state)}
          </Col>
        </Row>
      </div>
    )
  }

  getSyncInfoContent = () => {
    const { targets } = this.props

    if (!targets)
      return null

    // Auto last sync time
    if (targets.last_sync_time) {
      let useDailySync = true
      if (targets.last_manual_sync_time) {
        // range from end time to start time (not absolute)
        useDailySync = moment(targets.last_manual_sync_time).diff(moment(targets.last_sync_time)) < -(1000 * 60) // 1 minute
      }

      if (useDailySync) {
        return (
          <div>
            Last sync on {moment(targets.last_sync_time).format('DD MMM YYYY hh:mm:ss A')}
          </div>
        )
      }
    }

    // Manual sync time
    if (targets.last_manual_sync_time) {
      return (
        <div>
          Last sync by <b>{lib.getFullName(targets.last_manual_sync_by)}</b> on {moment(targets.last_manual_sync_time).format('DD MMM YYYY hh:mm:ss A')}
        </div>
      )
    }

    // No sync for the month
    return (
      <div>
        No sync yet for the month
      </div>
    )
  }

  render() {
    return (
      <>
        <Row justify='space-between' gutter={[12, 6]} className='sticky-toolbar'>
          <Col>
            <h2 style={{ marginBottom: 0 }}>
              {this.title}
            </h2>
          </Col>
          
          <Col>
            <Row gutter={[6, 6]}>
              <Col className='only-xs-block'>
                <Button block icon={<ReloadOutlined />} loading={this.props.loading} onClick={this.props.reload}>
                  Refresh
                </Button>
              </Col>
              <Col style={{ flex: 1, minWidth: '175px' }}>
                <Row>
                  <Col>
                    <Button
                      disabled={this.props.loading}
                      icon={<CaretLeftOutlined />}
                      onClick={this.props.prevMonth}
                    />
                  </Col>
                  <Col style={{ flex: 1 }}>
                    <MonthPicker
                      disabled={this.props.loading}
                      allowClear={false}
                      value={moment(this.props.dateString)}
                      style={{ margin: '0 -1px', width: 'calc(100% + 2px)' }}
                      // onChange={(date, dateString) => {this.selectedDate = dateString; this.read();}}
                      onChange={(date, dateString) => {
                        if (this.props.onChangeDate)
                          this.props.onChangeDate(dateString)
                        else {
                          this.selectedDate = dateString
                          this.read()
                        }
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      disabled={this.props.loading}
                      icon={<CaretRightOutlined />}
                      onClick={this.props.nextMonth}
                    />
                  </Col>
                </Row>
              </Col>
              {(!this.state.errorResponse || this.state.isLoading) && (
                <Col style={mutableScreen.isMobile && { flex: 1 }}>
                  <Button.Group style={{ width: '100%' }}>
                    {!this.readOnly && (
                      <Button block disabled={!this.getAllowSync()} onClick={this.manualSync}>
                        <SyncOutlined spin={this.state.syncing} />
                        Manual Sync
                      </Button>
                    )}
                    <Popover
                      placement='bottomRight'
                      trigger='click'
                      title={this.getSyncTitle()}
                      content={this.getSyncInfoContent()}
                    >
                      <Button
                        style={{ width: '36px' }}
                        icon={<InfoCircleOutlined />}
                      />
                    </Popover>
                  </Button.Group>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <div>
        {true ? (
          <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} lg={12} xxl={6}>
              <Card className='app-card' bodyStyle={{ padding: '12px' }} style={{ height: '100%' }}>
                <Spin spinning={this.props.loading}>
                  <Point
                    ref={this.onRefPoint}
                    readOnly={this.readOnly}
                    prevTarget={Number(get(this.props.prevTargets, 'amount', 0))}
                    target={Number(get(this.props.targets, 'amount', 0))}
                    workingDays={get(this.props.targets, 'working_days', 0)}
                    fieldNamePoint='amount'
                    productionJobs={this.props.productionJobs}
                    allProductionJobs={this.props.allProductionJobs}
                    patchTarget={this.patchTarget}
                    // date={this.selectedDate}
                    date={this.props.dateString}

                    prevProductionJobs={this.props.prevProductionJobs}
                  />
                </Spin>
              </Card>
            </Col>
            <Col xs={24} sm={24} lg={12} xxl={6}>
              <Card className='app-card' bodyStyle={{ padding: '12px' }} style={{ height: '100%' }}>
                <Spin spinning={this.props.loading}>
                  <AmountByGrade
                    readOnly={this.readOnly}
                    // target={Number(get(this.props.targets, 'amount', 0))}
                    // fieldNamePoint='amount'
                    target={get(this.props.targets, 'working_days', 0)}
                    fieldNamePoint='working_days'
                    targetAmount={Number(get(this.props.targets, 'amount', 0))}
                    productionJobs={this.props.productionJobs}
                    patchTarget={this.patchTarget}
                  />
                </Spin>
              </Card>
            </Col>
            <Col xs={24} sm={24} lg={12} xxl={6}>
              <Card className='app-card' bodyStyle={{ padding: '12px' }} style={{ height: '100%' }}>
                <Spin spinning={this.props.loading}>
                  <AreaByGrade
                    readOnly={this.readOnly}
                    target={Number(get(this.props.targets, 'area', 0))}
                    fieldNamePoint='area'
                    productionJobs={this.props.productionJobs}
                    patchTarget={this.patchTarget}
                  />
                </Spin>
              </Card>
            </Col>
            <Col xs={24} sm={24} lg={12} xxl={6}>
              <Card className='app-card' bodyStyle={{ padding: '12px' }} style={{ height: '100%' }}>
                <Spin spinning={this.props.loading}>
                  <JobByGrade
                    readOnly={this.readOnly}
                    target={Number(get(this.props.targets, 'total_job', 0))}
                    fieldNamePoint='total_job'
                    productionJobs={this.props.productionJobs}
                    patchTarget={this.patchTarget}
                  />
                </Spin>
              </Card>
            </Col>
          </Row>
        ) : (
          <PageError
            errorResponse={this.state.errorResponse}
            onReload={this.read}
          />
        )}
        </div>
      </>
    )
  }
}

UserProduction.defaultProps = {
  setProductionJobs: () => {},
  setDate: () => {},
}

export default UserProduction