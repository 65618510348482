import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import users from './users';

const userOvertimes = [
  // {
  //   user: users.objUsers['02.Wilhelmina'],
  //   created: '2023-06-18T01:00:00Z',
  //   start_time: '2023-06-20T01:00:00Z',
  //   finish_time: '2023-06-20T07:00:00Z',
  //   note: 'Test',
  //   status: 'approved',
  //   confirmed_date: '2023-04-19T06:12:00Z',
  //   confirmed_by: users.objUsers['99.Developer'],
  // },
  {
    user: users.objUsers['02.Wilhelmina'],
    created: '2023-04-16T01:00:00Z',
    start_time: '2023-06-17T01:00:00Z',
    finish_time: '2023-06-17T07:00:00Z',
    note: 'Make up for the lack of working hours',
    status: 'approved',
    confirmed_date: '2023-04-12T06:12:00Z',
    confirmed_by: users.objUsers['99.Developer'],
  },
  {
    user: users.objUsers['02.Wilhelmina'],
    created: '2023-04-15T01:00:00Z',
    start_time: '2023-06-24T01:00:00Z',
    finish_time: '2023-06-24T07:00:00Z',
    note: 'Make up for the lack of working hours',
    status: 'waiting',
    confirmed_date: null,
    confirmed_by: null,
  },
  {
    user: users.objUsers['02.Wilhelmina'],
    created: '2023-04-14T00:30:00Z',
    start_time: '2023-06-14T01:00:00Z',
    finish_time: '2023-06-14T05:00:00Z',
    note: 'Test only',
    status: 'rejected',
    confirmed_date: '2023-06-25T07:30:00Z',
    confirmed_by: users.objUsers['99.Developer'],
  },
  {
    user: users.objUsers['02.Wilhelmina'],
    created: '2023-04-09T01:30:00Z',
    start_time: '2023-06-10T01:00:00Z',
    finish_time: '2023-06-10T09:00:00Z',
    note: 'Make up for the lack of working hours',
    status: 'approved',
    confirmed_date: '2023-06-25T07:30:00Z',
    confirmed_by: users.objUsers['99.Developer'],
  },
].map(item => ({ ...item, alias: uuidv4() }))

function getUserOvertimes(userId, strDate) {
  const monthFormat = 'YYYY-MM'

  return userOvertimes.filter(userOvertime => (
    userOvertime.user.id === userId &&
    moment(strDate).format(monthFormat) === moment(userOvertime.start_time).format(monthFormat)
  ))
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userOvertimes,
  getUserOvertimes,
}