import React from 'react'
import { Button, Col, Input, message, Row } from 'antd'
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons'

import { BaseComponent } from 'src/components'

class TaskItem extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      isEditing: !props.task.name,
      isTouched: !props.task.name,
      taskName: props.task.name,
      tempTaskName: props.task.name,
    }
  }

  onEdit = () => {
    if (!this.props.readOnly)
      this.setThisState({ isEditing: true })
  }

  onChangeName = (e) => {
    this.setThisState({
      taskName: e.target.value,
      isTouched: true,
    }, this.props.onFieldTouched)
  }

  onKeyDown = (e) => {
    if (e.keyCode === 27) {
      this.onCancel()
    }
  }

  onCancel = () => {
    if (this.props.task.alias) {
      // this.setThisState({
      //   isEditing: false,
      //   taskName: this.state.tempTaskName,
      // })
      this.reset()
    } else {
      this.props.onDelete(this.props.task)
      setTimeout(this.props.onReset, 250)
    }
  }

  onSave = () => {
    const taskName = this.state.taskName.trim()
    if (!taskName) {
      message.info('Please input the Task Name!')
      return
    }

    this.setThisState({
      isEditing: false,
      taskName: taskName.trim(),
      tempTaskName: taskName.trim(),
    }, () => this.props.onSave(this.validateFields()))
  }

  reset = (task = this.props.task) => {
    this.setThisState({
      taskName: task.name,
      tempTaskName: task.name,
      isEditing: false,
      isTouched: false,
    }, this.props.onReset)
  }

  validateFields = () => {
    return {
      ...this.props.task,
      name: this.state.taskName.trim(),
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.task !== nextProps.task) {
      this.reset(nextProps.task)
    }
  }

  didMount() {
    this.props.onRef(this, this.props.task)
  }

  unmount() {
    this.props.onRef(null, this.props.task)
  }

  render() {
    const { taskName } = this.state

    return (
      <div className='task-item' title={!this.state.isTouched ? '' : 'Task has unsaved changes'}>
        <div style={{ margin: '0 12px' }}>
          <Row style={{ borderBottom: '1px solid #e8e8e8' }}>
            <Col
              xs={24} sm={10} lg={8} xl={6}
              className='task-name text-truncate'
              title={this.props.task.name}
              style={{ minHeight: '36px', borderColor: this.state.isTouched ? '#1890ff' : '#c3c3c3' }}
              onClick={this.onEdit}
            >
              {this.state.isEditing ? (
                <Input
                  ref={ref => ref && ref.focus()}
                  value={this.state.taskName}
                  placeholder='Task Name'
                  onChange={this.onChangeName}
                  // onBlur={this.onSave}
                  onPressEnter={this.onSave}
                  onKeyDown={this.onKeyDown}
                  style={{
                    border: 'none',
                    padding: '0 2px',
                  }}
                />
              ) : taskName}
            </Col>
            <Col
              xs={24} sm={14} lg={16} xl={18}
              style={{
                background: '#e8e8e8',
                border: '1px solid #c3c3c3',
                borderColor: this.state.isTouched ? '#1890ff' : '#c3c3c3',
                borderTopRightRadius: '4px',
                borderBottomRightRadius: '4px',
                padding: '6px 12px',
                marginLeft: '-1px'
              }}
            >
              {/* Komen di bawah sementara jangan dihapus dulu, coba aktifkan dan komen <Row dibawahnya untuk melihat desain progress bar */}
              {/* <Row align='middle' style={{ height: '100%' }}>
                <div className='label-total-worked'>
                  {moment.duration({ seconds: 81000 })._data.hours} Hours {moment.duration({ seconds: 81000 })._data.minutes} Minutes
                </div>
                <div className='bar-total-worked' style={{ backgroundColor: '#fff', border: '1px solid #fff', borderRadius: '4px', width: '100%' }}>
                  <Bar width={`${Math.round(Math.random() * 100)}%`} />
                </div>
              </Row> */}
              <Row align='middle' gutter={[6, 6]}>
                {!this.props.readOnly && this.props.task.alias && (
                  <Col>
                    <Button danger size='small' icon={<DeleteOutlined />} onClick={() => this.props.onDelete(this.props.task)}>
                      Delete
                    </Button>
                  </Col>
                )}
                {/* {this.state.isTouched && this.props.task.alias && (
                  <Col>
                    <Button size='small' icon={<RollbackOutlined />} onClick={() => this.reset()}>
                      Revert
                    </Button>
                  </Col>
                )} */}
                {this.state.isEditing && (
                  <>
                    <Col>
                      <Button size='small' icon={<ArrowLeftOutlined />} onClick={this.onCancel}>
                        Cancel
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default TaskItem