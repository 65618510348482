import PropTypes from 'prop-types'
import { Table, Tooltip } from 'antd'
import { WarningOutlined } from '@ant-design/icons'
import { cloneDeep } from 'lodash'
import NumberFormat from 'react-number-format'

import BaseProductionDetail from 'src/pages/DashboardApp/DashboardManager/ProductionDetail'
import { lib } from 'src/Utils'

class UserProductionDetail extends BaseProductionDetail {
  constructor(props) {
    super(props)

    this.title = 'User Production Detail'
    this.headerClassName = 'header-user-production-detail'
    this.showExport = false
    this.resyncFeature = false
    // this.totalEmptyCols = 9

    this.groupOpts = this.groupOpts.filter(group => group.key !== 'qs')
    this.setFilteredColumns()
    this.setPointHover()
  }

  setFilteredColumns = (columns = this.columns) => {
    this.columns = cloneDeep(columns)

    const excludeColumns = ['user', 'highlighted_total_charge', 'qs_value', 'total_qs_value']
    this.columns = this.columns.filter(column => !excludeColumns.includes(column.dataIndex))
    const colQsPoint = this.columns.find(column => column.dataIndex === 'qs_point')
    // if (colQsPoint) colQsPoint.dataIndex = 'point'
    if (colQsPoint) this.columns.splice(this.columns.indexOf(colQsPoint), 1)
    // this.columns.push({
    //   title: 'Point',
    //   dataIndex: 'point',
    //   align: 'right',
    //   width: '80px',
    //   sortDirections: ['descend', 'ascend'],
    //   sorter: (a, b) => lib.sorter.byItem(a, b, 'point'),
    //   render: val => <NumberFormat thousandSeparator value={Number(val).toFixed(2)} displayType='text' />,
    //   // summary
    //   summaryProps: { align: 'right' },
    //   getSummary: (data) => <NumberFormat thousandSeparator displayType='text' value={data.reduce((acc, val) => acc + Number(val.point), 0).toFixed(2)} />,
    // })
  }

  // getFinishedTitle = (prodJob) => {
  //   const isOverdue = prodJob.finished_date > prodJob.target_due_date
  //   if (!isOverdue && !prodJob.notes) return null

  //   return (
  //     <div>
  //       {isOverdue && <div className="c-warning font-weight-semibold"><WarningOutlined /> Overdue Job</div>}
  //       {prodJob.notes && <div className='webkit-box two-lines'>Notes : {prodJob.notes}</div>}
  //     </div>
  //   )
  // }

  // summaryTable = (currentData) => {
  //   // window.konsole.log('summaryTable', currentData)
  //   return (
  //     <Table.Summary.Row>
  //       {this.columns.map((column, i) => (
  //         <Table.Summary.Cell key={column.title} index={i} {...column.summaryProps}>
  //           {column.getSummary && column.getSummary(currentData)}
  //         </Table.Summary.Cell>
  //       ))}
  //     </Table.Summary.Row>
  //   )
  // }

  setPointHover = () => {
    const colJobNo = this.columns.find(column => column.dataIndex === 'job_id')
    if (colJobNo) colJobNo.render = (value, record) => {
      return {
        ...(!!record.groupName && { props:{colSpan: 0} }),
        children: (
          <Tooltip title={Number(record.point) && `Point : ${Number(record.point).toFixed(2)}`} mouseLeaveDelay={0}>
            <span style={{ cursor: 'default' }}>{value}</span>
          </Tooltip>
        ),
      }
    }
  }
}

UserProductionDetail.propTypes = {
  ...UserProductionDetail.propTypes,
}

UserProductionDetail.defaultProps = {
  ...UserProductionDetail.defaultProps,
}

export default UserProductionDetail