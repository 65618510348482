import PropTypes from 'prop-types';
import { Col, Collapse, DatePicker, Row, Table, Tooltip } from 'antd';
import { get, groupBy } from 'lodash';
import moment from 'moment';

import { BaseReport } from '../components';
import NumberColor from './NumberColor';
import { lib, request } from 'src/Utils';
import readProductionJobs from './readProductionJobs';
import typeCodes from 'src/Utils/static/typeCodes';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

class YearlyReport extends BaseReport {
  constructor(props) {
    super(props)

    this.title = 'Yearly Report'
    // this.urlKey = 'office-woking-sheet'
    this.rangeMessageError = 'Cannot display for more than 1 year'

    console.log('constructor this.objParam', this.objParam, this.range_before, this.range_after)

    this.setRangeDate({
      start_date: this.objParam.start_date || moment().startOf('year').format(),
      end_date: this.objParam.end_date || moment().endOf('year').format(),
    })

    console.log('constructor 2', this.range_before, this.range_after)

    this.columns = [
      {
        title: 'Year',
        dataIndex: ['date'],
        width: '60px',
        render: (val) => moment(val).format('YYYY'),
      },
      {
        title: 'Month',
        dataIndex: ['date'],
        width: '80px',
        render: (val) => moment(val).format('MMMM'),
      },
      {
        title: 'Days',
        dataIndex: ['days'],
        width: '60px',
        align: 'right',
        render: (val) => val || '-',
      },
      {
        title: 'TW Hours',
        children: [
          {
            title: 'Target',
            dataIndex: ['target_hour'],
            align: 'right',
            render: (val) => val || '-',
          },
          {
            title: 'Realization',
            dataIndex: ['ms'],
            align: 'right',
            render: (val, record) => (
              <NumberColor
                target={record.target_hour}
                realization={Number(lib.time.getFloat(val))}
              />
            ),
          },
        ],
      },
      {
        title: 'KPI',
        children: [
          {
            title: 'Target',
            dataIndex: ['target_kpi'],
            align: 'right',
          },
          {
            title: 'Realization',
            dataIndex: ['kpi'],
            align: 'right',
            render: (val, record) => (
              <NumberColor
                target={record.target_kpi}
                realization={val}
              />
            ),
          },
        ],
      },
      {
        title: 'Point',
        children: [
          {
            title: 'Target',
            dataIndex: ['target_point'],
            align: 'right',
            render: (val) => val ? val.toFixed(2) : '-',
          },
          {
            title: 'Realization',
            dataIndex: ['point'],
            align: 'right',
            render: (val, record) => (
              <NumberColor
                target={record.target_point}
                realization={val}
              />
            ),
          },
        ],
      },
      {
        title: 'Area',
        children: [
          {
            title: 'Target',
            dataIndex: ['target_area'],
            align: 'right',
            render: (val) => <NumberColor realization={val} />
          },
          {
            title: 'Realization',
            dataIndex: ['area'],
            align: 'right',
            render: (val, record) => (
              <NumberColor
                target={record.target_area}
                realization={val}
              />
            )
          },
        ],
      },
    ]
  }

  checkRange = (startDate, endDate) => (
    moment(endDate).diff(moment(startDate), 'month') <= 11
  )

  // getKey = (key) => key === 'range_after' ? 'date_after'
  //   : key === 'range_before' ? 'date_before'
  //   : key

  getParam = (key, val) => {
    if (key === 'range_before')
      return moment(this.range_before).startOf('month').format()
    if (key === 'range_after')
      return moment(this.range_after).endOf('month').format()
    if (key === 'user')
      return this.id

    return val
  }

  _readProductionJobs = async () => {
    return new Promise((resolve, reject) => {
      request({
        urlKey: 'read-usersProductionJobs',
        data: {
          finished_date_after: moment(this.range_before).startOf('month').format(),
          finished_date_before: moment(this.range_after).endOf('month').format(),
          id: this.id,
          status: 'finished',
        },
        onSuccess: res => resolve(res.data),
        onFailed: reject,
      })
    })
  }

  readProductionTargetsCompany = async () => {
    return new Promise(async (resolve) => {
      const productionTargets = {}
      for (const dateMonth of this._dateMonths) {
        await request({
          urlKey: 'read-productionTargetsDashboard',
          data: { date: dateMonth },
          onSuccess: res => productionTargets[dateMonth] = res.data,
        })
      }

      resolve(productionTargets)
    })
  }

  readUserTypes = () => {
    return new Promise(async (resolve) => {
      const userTypes = {}
      for (const dateMonth of this._dateMonths) {
        await request({
          urlKey: 'read-userPointTypes',
          data: { id: this.id, date: dateMonth },
          onSuccess: res => userTypes[dateMonth] = res.data,
        })
      }

      resolve(userTypes)
    })
  }

  readUserTargets = async (date) => {
    return new Promise(async (resolve) => {
      const userTargets = {}
      for (const dateMonth of this._dateMonths) {
        await request({
          urlKey: 'read-productionTargetsUsers',
          data: { id: this.id, date: dateMonth },
          onSuccess: res => userTargets[dateMonth] = res.data,
        })
      }

      resolve(userTargets)
    })
  }

  doReadAnother = async () => {
    return new Promise(async (resolve) => {
      const date = moment(this.range_before).startOf('month')
      const totalMonths = moment(this.range_after).startOf('month').add(1, 'month').diff(date, 'month')
      console.log('totalMonths', totalMonths)
      this._dateMonths = []
      for (let i = 0; i < totalMonths; i += 1)
        this._dateMonths.push(moment(date).add(i, 'month').format('YYYY-MM-DD'))

      const productionJobs = await readProductionJobs({ id: this.id, date_after: this.range_before, date_before: this.range_after }).catch(error => null)
      const productionTargets = await this.readProductionTargetsCompany()
      const userTypes = await this.readUserTypes()
      const userTargets = await this.readUserTargets()

      console.log({ productionJobs, productionTargets, userTypes, userTargets })
      this._productionJobs = productionJobs
      this._productionTargets = productionTargets
      this._userTypes = userTypes
      this._userTargets = userTargets

      resolve()
    })
  }

  convertResponseData = (responseData) => {
    console.log('convertResponseData', responseData)

    const objActivities = groupBy(responseData, item => moment(item.start_time).format('YYYY-MM'))

    return this._dateMonths.map(dateMonth => {
      const month = moment(dateMonth).format('YYYY-MM')
      const days = get(this._productionTargets, [dateMonth, 'working_days'], 0)
      const activities = get(objActivities, [month], [])
      const ms = activities.reduce((acc, activity) => acc + lib.time.getDiff(activity), 0)
      const productionJobs = get(this._productionJobs, ['objQs', month], [])
      const point = productionJobs.reduce((acc, val) => acc + Number(val.point), 0)
      const area = productionJobs.reduce((acc, val) => acc + Number(val.area), 0)
      const userTarget = this._userTargets[dateMonth]
      const pointTypes = get(this._userTypes[dateMonth], [0, 'point_types'], [])
      const pointTypeQS = pointTypes.find(type => typeCodes.QS_CODES.includes(type.code))

      return ({
        date: dateMonth,
        days,
        target_hour: days * 8 || '-',
        activities,
        ms,
        target_area: days * 8 * 40,
        area,
        target_kpi: 40, // static for now
        kpi: !ms ? 0 : Math.round(area / Number(lib.time.getFloat(ms))),
        target_point: (days && pointTypeQS && get(userTarget, [0, 'target', 'amount']))
          ? days / 20 * (lib.prod.getUserAmount(userTarget[0].target.amount) / pointTypeQS.denominator)
          : 0,
        point,
      })
    })
  }

  getFilterInput = () => (
    <Col className='only-xs-block'>
      <Row gutter={[6, 6]}>
        <Col className='only-xs-block'>
          <DatePicker.RangePicker
            allowClear={false}
            picker='month'
            ranges={{
              'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
              'This Year': [moment().startOf('year'), moment().endOf('year')],
            }}
            style={{ width: '100%' }}
            defaultValue={[moment(this.range_before), moment(this.range_after)]}
            onChange={(dates, dateStrings) => {
              this.tempDateStrings = dateStrings
            }}
            onOpenChange={(opened) => {
              if (!opened) {
                if (this.tempDateStrings[0] !== this.range_before || this.tempDateStrings[1] !== this.range_after)
                  this.onChangeFilter({ start_date: this.tempDateStrings[0], end_date: this.tempDateStrings[1] })
              } else {
                this.tempDateStrings = [this.range_before, this.range_after]
              }
            }}
            />
        </Col>
      </Row>
    </Col>
  )

  getDateRangeContent = () => (
    <Col style={{ padding: '6px 12px' }}>
      <div style={{ fontSize: '12px', color: '#cdcdcd' }}>
        Selected Range
      </div>
      <div style={{ fontSize: '18px', fontWeight: 500 }}>
        {moment(this.range_before).format('MMM')}
        {' – '}
        {moment(this.range_after).format('MMM YYYY')}
      </div>
    </Col>
  )

  getBodyContent = () => {
    const selectedUsers = this.getSelectedUsers()

    return (
      <div>
        <div>
          {selectedUsers.length > 0 && (
            <Collapse activeKey={selectedUsers.map(user => user.alias)}>
              {selectedUsers.map(user => (
                <Collapse.Panel key={user.alias} header={this.getUserTitle(user)} className='panel-report'>
                  <Row justify='space-between'>
                    <div style={{ fontSize: '20px', padding: '12px' }}>
                      Average KPI / Year:
                      <Tooltip placement="right" mouseLeaveDelay={0} title="Total KPI / Total Months in parameter" overlayStyle={{ maxWidth: '280px' }}>
                        <b style={{ marginLeft: '4px' }}>
                          {(this.__list.reduce((acc, val) => acc + val.kpi, 0) / this.__list.length).toFixed(2)}
                        </b>
                      </Tooltip>
                    </div>
                    {!this.mutableScreen.isMobile && this.getDateRangeContent()}
                  </Row>
                  <Table
                    bordered
                    size='small'
                    columns={this.columns}
                    dataSource={this.__list}
                    pagination={false}
                    rowKey='date'
                    // scroll={this.scrollConfig}
                    className="table-even-odd"
                  />
                </Collapse.Panel>
              ))}
            </Collapse>
          )}
        </div>
      </div>
    )
  }
}

YearlyReport.propTypes = {
  ...YearlyReport.propTypes,
}

YearlyReport.defaultProps = {
  ...YearlyReport.defaultProps,
}

export default YearlyReport