import PropTypes from 'prop-types';
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Segmented, Select, Slider, Spin, TimePicker, Tooltip } from 'antd';
import { InfoCircleOutlined, PieChartOutlined, SaveOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import moment from 'moment';

import { BaseModal, SelectQuery } from 'src/components';
import { lib, request } from 'src/Utils';
import example from '../../example';
import _h from 'src/pages/DashboardApp/Submission/helper';

import { BaseComponent } from 'src/components';

class LeaveForm extends BaseComponent {
  onChangeTime = (times) => {
    // if (!times) return [0, 0]

    const [startHours, startMinutes] = times[0].format('HH:mm').split(':')
    const [endHours, endMinutes] = times[1].format('HH:mm').split(':')
    return [
      (Number(startHours) * 60) + Number(startMinutes),
      (Number(endHours) * 60) + Number(endMinutes)
    ]
  }

  getSliderValue = (times) => {
    if (!times) return [0, 0]

    const [startHours, startMinutes] = times[0].format('HH:mm').split(':')
    const [endHours, endMinutes] = times[1].format('HH:mm').split(':')
    return [
      (Number(startHours) * 60) + Number(startMinutes),
      (Number(endHours) * 60) + Number(endMinutes)
    ]
  }

  didMount() {
    request({
      urlKey: 'timezones',
      onSuccess: res => {
        const gmtOffset = new Date().getTimezoneOffset() * -1
        const localTimezone = res.data.find(tz => tz.gmt_offset === gmtOffset)
        if (localTimezone) {
          const form = this.props.getForm()
          if (!form.getFieldValue('timezone'))
            form.setFields([{ name: 'timezone', value: lib.getSelectQueryValue(localTimezone, { valKey: 'id', valLabel: ['gmt_repr', 'name'] }) }])
        }
      }
    })
  }

  renderFooter = () => {
    return (
      <Row justify="space-between">
        <Col />
        <Col>
          <Row gutter={[6, 6]}>
            <Col>
              <Button onClick={this.props.hide}>
                Cancel
              </Button>
            </Col>
            {this.form}
            <Col>
              <Form.Item noStyle shouldUpdate>
                {(form) => (
                  <Button type="primary" icon={<SaveOutlined />} onClick={this.props.onSave}>
                    Submit
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <div>
        <div className="ant-modal-body">
          <Form.Item name="timezone" label="Timezone" rules={[{ required: true }]}>
            <SelectQuery
              initialValues={{ pagination: false }}
              placeholder="Timezone"
              urlKey="timezones"
              valKey="id"
              renderLabel={tz => tz.gmt_repr + ' ' + tz.name}
            />
          </Form.Item>
          <Row gutter={[12, 12]}>
            <Col span={8}>
              <Form.Item name="date" label="Date" rules={[{ required: true }]}>
                <DatePicker
                  disabled
                  allowClear={false}
                  style={{ width: '100%' }}
                  disabledDate={currentDate => currentDate.format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')}
                />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item noStyle shouldUpdate>
                {(form) => (
                  <Form.Item name="range_time" label="Time" rules={[{ required: true }]}>
                    <TimePicker.RangePicker
                      // disabled
                      allowClear={false}
                      use12Hours={false}
                      format="hh:mm A"
                      popupClassName="custom-time-picker"
                      style={{ width: '100%' }}
                      minuteStep={15}
                      disabledTime={() => {
                        const disabledHours = [0, 1, 2, 3, 4, 5, 6, 7, 22, 23, 24]
                        if (!form.getFieldValue('is_holiday'))
                          disabledHours.push(8, 9, 10, 11, 12, 13, 14, 15, 16)

                        return {
                          disabledHours: () => disabledHours,
                        }
                      }}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" gutter={[12, 12]} style={{ marginBottom: '24px' }}>
            <Col span={8}>
              <Form.Item
                name="is_holiday"
                style={{ marginBottom: 0 }}
                getValueFromEvent={e => {
                  this.props.getForm().setFields([{ name: 'range_time', value: undefined }])
                  return e
                }}
              >
                <Segmented
                  block
                  options={[
                    { value: false, label: 'Working Day', disabled: _h.isWeekend(this.props.defaultDate) },
                    { value: true, label: 'Holiday' },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item noStyle shouldUpdate>
                {(form) => {
                  const rangeTimes = form.getFieldValue('range_time')
                  const isHoliday = form.getFieldValue('is_holiday')
                  const minStartTime = (isHoliday ? 8 : 17) * 60

                  return (
                    // <div style={{ marginTop: '-12px', marginBottom: '12px' }}>
                    <div>
                      <div style={{ marginBottom: '6px' }}>
                        <Tooltip title="You can request overtime on weekdays starting at 17.00 PM">
                          <InfoCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.4)', marginRight: '8px' }} />
                        </Tooltip>
                        Requested :
                        <span style={{ fontWeight: 700, marginLeft: '4px' }}>
                          {rangeTimes
                            ? _h._overtime.renderDurationByItem({ finish_time: rangeTimes[1], start_time: rangeTimes[0] })
                            : '-'}
                        </span>
                      </div>
                      <div style={{ padding: '0 4px' }}>
                        <Slider
                          range
                          step={15}
                          min={minStartTime}
                          max={22 * 60}
                          style={{ margin: 0 }}
                          value={this.getSliderValue(form.getFieldValue('range_time'))}
                          tooltip={{
                            formatter: (value) => {
                              const hours = Math.floor(value / 60)
                              const minutes = value - (hours * 60)
                              return moment().hours(hours).minutes(minutes).format('hh:mm A')
                            }
                          }}
                          onChange={(values) => {
                            clearTimeout(this._timeout)
                            // this._timeout = setTimeout(() => {
                            const startHours = Math.floor(values[0] / 60)
                            const startMinutes = values[0] - (startHours * 60)
                            const endHours = Math.floor(values[1] / 60)
                            const endMinutes = values[1] - (endHours * 60)

                            this.props.getForm().setFields([
                              {
                                name: 'range_time',
                                value: [
                                  moment().hours(startHours).minutes(startMinutes),
                                  moment().hours(endHours).minutes(endMinutes),
                                ],
                                touched: true,
                                errors: [],
                              },
                            ])
                          }}
                        />
                      </div>
                    </div>
                  )
                }}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="note" label="Note" rules={[{ required: true }]}>
            <Input.TextArea
              showCount
              maxLength={200}
              placeholder="Plan for overtime :
                1. Job #200005
                2. Review schedule of #200003, #200004
                3. Continuing the job yesterday because the PC has a problem"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
          <Form.Item name="staff_leave" label="Reference Leave">
            <SelectQuery
              allowClear
              urlKey="leave-user-leaves-autocomplete"
              placeholder="Reference Leave"
              params={{
                source_type: 'user_apply',
                status: 'approved',
                user: get(this.props.user, 'id'),
                date_after: moment(this.props.defaultDate).startOf('month').format('YYYY-MM-DD'),
                date_before: moment(this.props.defaultDate).endOf('month').format('YYYY-MM-DD'),
              }}
              renderLabel={userLeave => _h._leave.renderSelect(userLeave)}
            />
          </Form.Item>
        </div>
        <div>
          <div className="ant-modal-footer">
            {this.renderFooter()}
          </div>
        </div>
      </div>
    )
  }
}

LeaveForm.propTypes = {
  ...LeaveForm.propTypes,
  defaultDate: PropTypes.string,
  leaveTypes: PropTypes.array,
  hide: PropTypes.func,
  // getLeaveTypeLabel: PropTypes.func,
  onSave: PropTypes.func,
}

LeaveForm.defaultProps = {
  ...LeaveForm.defaultProps,
  defaultDate: undefined, // cannot be null
  leaveTypes: [],
  hide: () => { },
  // getLeaveTypeLabel: () => {},
  onSave: () => { },
}

export default LeaveForm