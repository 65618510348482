import React from 'react'
import { Button, Col, DatePicker, Radio, Row, Spin, Table, Tooltip } from 'antd'
import { CaretLeftOutlined, CaretRightOutlined, QuestionCircleOutlined, ReloadOutlined } from '@ant-design/icons'
import { cloneDeep, get } from 'lodash'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import classNames from 'classnames'

import { BaseComponent, BaseList } from 'src/components'
import { lib, permissions, request } from 'src/Utils'
import helper from './helper'
import typeCodes from 'src/Utils/static/typeCodes'
import staticProd from 'src/Utils/static/productionJobs'

const DEFAULT_DATA_SOURCE = {
  amount: 0,
  total_jobs: 0,
  area: 0,
  point: 0,
  reward: 0,
  objGrade: {},
}

class MonthlyProductionReport extends BaseList {
  constructor(props) {
    super(props)

    this.availablePickerMode = ['month', 'year']

    this.urlKey = 'read-productionJobs' // 'read-productionJobsQS'
    this.pagination = false

    this.myProfile = lib.ls.getCurrentUser()
    this.selectedDate = moment().format()
    this.status = 'finished'

    this.keysQueryParam = ['finished_date_after', 'finished_date_before', 'status']

    this.canSeeReward = permissions.canViewRewardReport(this.myProfile)
    this.scrollOpts = { x: 912 }
    this.columns = [
      {
        title: 'QS List',
        dataIndex: 'fullName',
        fixed: 'left',
        width: '140px',
        onCell: () => ({ style: { borderRightColor: '#c3c3c3' } }),
        onHeaderCell: () => ({ style: { borderRightColor: '#c3c3c3' } }),
        render: (val, record) => (
          <Tooltip
            placement='topLeft'
            mouseLeaveDelay={0}
            title={`Type : ${record.user.point_types.map(type => type.code_display).join(', ') || 'No Point Type'}`}
          >
            {val}
          </Tooltip>
        )
      },
      {
        title: 'Productions',
        onHeaderCell: () => ({ style: { borderRightColor: '#c3c3c3' } }),
        children: [
          {
            title: 'Amount',
            // dataIndex: ['prod_summary', 'qs_value'],
            // dataIndex: 'qs_value',
            dataIndex: 'amount',
            align: 'right',
            width: '90px',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.amount - b.amount,
            render: val => <NumberFormat thousandSeparator displayType='text' value={Number(val).toFixed(2)} />
          },
          {
            title: 'Jobs',
            // dataIndex: ['prod_summary', 'total_jobs'],
            dataIndex: 'total_jobs',
            align: 'right',
            width: '70px',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.total_jobs - b.total_jobs,
          },
          {
            title: 'Area',
            // dataIndex: ['prod_summary', 'total_area'],
            // dataIndex: 'total_area',
            dataIndex: 'area',
            align: 'right',
            width: '80px',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.total_area - b.total_area,
            render: val => <NumberFormat thousandSeparator displayType='text' value={Number(val).toFixed(0)} />
          },
          {
            title: 'Point',
            // dataIndex: ['prod_summary', 'qs_point'],
            // dataIndex: 'actualPoint',
            dataIndex: 'point',
            align: 'right',
            width: '80px',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.point - b.point,
            onCell: () => ({ style: !this.canSeeReward && { borderRightColor: '#c3c3c3' } }),
            onHeaderCell: () => ({ style: !this.canSeeReward && { borderRightColor: '#c3c3c3' } }),
            render: val => <NumberFormat thousandSeparator displayType='text' value={Number(val).toFixed(2)} />
          },
          ...(!this.canSeeReward ? [] : [{
            title: 'Reward',
            dataIndex: 'reward',
            align: 'right',
            width: '90px',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.reward - b.reward,
            onCell: () => ({ style: { borderRightColor: '#c3c3c3' } }),
            onHeaderCell: () => ({ style: { borderRightColor: '#c3c3c3' } }),
            render: (val, record) => {
              // jika if bonus ada disini, untuk menampilkan 1 tahun bagaimana
              if (!record.user.is_bonus) return 'N/A'
              if (!val) return '-'
              return (
                <NumberFormat
                  thousandSeparator
                  displayType='text'
                  value={val.toFixed(0)}
                />
              )
            }
          }]),
        ],
      },
      {
        title: 'Number of jobs by Grade',
        onHeaderCell: () => ({ style: { borderRightColor: '#c3c3c3' } }),
        children: [],
      },
      {
        title: 'Area m² by Grade',
        children: [],
      },
    ]

    this.state = {
      ...this.state,
      pickerMode: this.availablePickerMode[0],
    }
  }

  isValidParam = (key) => true

  getParam = (key, val) => {
    if (key === 'finished_date_after')
      return moment(this.selectedDate).startOf(this.state.pickerMode).format()

    if (key === 'finished_date_before')
      return moment(this.selectedDate).endOf(this.state.pickerMode).format()

    return val
  }

  readPointTypes = async () => {
    return request({
      urlKey: 'read-userPointTypes',
      onSuccess: (response) => {},
      onFailed: (error) => {
        this.setThisState({ isLoading: false, isReloading: false, errorResponse: error.response })
      },
    })
  }

  // resolve true is break
  beforeRead = async (keyLoading) => {
    return new Promise((resolve) => {
      this.setThisState({ [keyLoading]: true }, async () => {
        const resPointTypes = await helper.readPointTypes(
          this.getParam('finished_date_after'),
          this.getParam('finished_date_before'),
          { is_active: true },
        )

        if (resPointTypes.errorResponse)
          this.pointTypes = null
        else
          this.pointTypes = resPointTypes.objPointTypes

        // const resPointTypes2 = await request({ urlKey: 'read-userPointTypes', data: {date: moment(this.selectedDate).startOf('month').format('YYYY-MM-DD')} })
        // this.pointTypes = resPointTypes2 && resPointTypes2.data

        const resPointExchange = await helper.readPointExchange(
          this.getParam('finished_date_after'),
          this.getParam('finished_date_before')
        )

        if (resPointExchange.errorResponse)
          this.pointExchange = null
        else
          this.pointExchange = resPointExchange.pointExchange

        if (!this.pointTypes || !this.pointExchange) {
          this.setThisState({ isLoading: false, isReloading: false, errorResponse: resPointTypes.errorResponse || resPointExchange.errorResponse })
          resolve(true)
        } else {
          resolve()
        }
      })
    })
  }

  getMergedObjGrade = (objGrade1, objGrade2) => {
    objGrade1 = cloneDeep(objGrade1)

    for (const grade in objGrade2) {
      if (!objGrade1[grade])
        objGrade1[grade] = []

      objGrade1[grade].push(...objGrade2[grade])
    }

    return objGrade1
  }

  convertResponseData = (productionJobs) => {
    window.konsole.log('convertResponseData pointTypes', this.pointTypes)
    window.konsole.log('convertResponseData productionJobs', productionJobs)
    // Set grade columns
    helper.insertGradeColumns(this.columns, productionJobs)

    const dataSource = []
    // can use "pointExchange" or "pointTypes" for each strMonth
    // for (const month in this.pointExchange)
    for (const strMonth in this.pointTypes) {
      // const users = this.pointTypes[strMonth].filter(user => user.point_types.length > 0)
      const users = this.pointTypes[strMonth]//.filter(user => user.point_types.length > 0)
      const currentProdJobs = productionJobs.filter(job => moment(job.finished_date).format('YYYY-MM') === strMonth)
      window.konsole.log('convertResponseData currentProdJobs', strMonth, currentProdJobs)
      // const currentObjTypes = {}
      // for (const user of this.pointTypes[strMonth])
      //   for (const pointType of user.point_types)
      //     currentObjTypes[pointType.code] = pointType

      for (const user of users) {
        const qsExchangeFactor = get(user.point_types.find(type => typeCodes.QS_CODES.includes(type.code)), 'exchange_factor', staticProd.DEFAULT_EXCHANGE_FACTOR)
        const qaExchangeFactor = get(user.point_types.find(type => typeCodes.QA_CODES.includes(type.code)), 'exchange_factor', staticProd.DEFAULT_EXCHANGE_FACTOR)
        const trainerExchangeFactor = get(user.point_types.find(type => typeCodes.TRAINER_CODES.includes(type.code)), 'exchange_factor', staticProd.DEFAULT_EXCHANGE_FACTOR)
        const managerExchangeFactor = get(user.point_types.find(type => typeCodes.MANAGER_CODES.includes(type.code)), 'exchange_factor', staticProd.DEFAULT_EXCHANGE_FACTOR)

        const currentProdJobsQs = currentProdJobs.filter(job => job.user.id === user.id)
        const currentProdJobsQa = currentProdJobs.filter(job => job.user_qa && job.user_qa.id === user.id)
        window.konsole.log('convertResponseData user', strMonth, user, { qs: currentProdJobsQs, qa: currentProdJobsQa })

        // console.log(strMonth, lib.getFullName(user), '(QS)', currentProdJobs.length, currentProdJobsQs)
        // console.log(strMonth, lib.getFullName(user), '(QA)', currentProdJobsQa)
        const objGrade = {}
        const setObjGrade = (job) => {
          if (!objGrade[job.grade])
            objGrade[job.grade] = []

          objGrade[job.grade].push(job)
        }

        let amount = 0, area = 0, point = 0, pointQS = 0, pointQA = 0, pointTrainer = 0, pointManager = 0, reward = 0, totalJobs = 0
        // if (qsExchangeFactor) {
        for (const job of currentProdJobsQs) {
          totalJobs += 1
          amount += Number(job.qs_value)
          area += Number(job.area)
          // point += Number(job.qs_point)
          if (qsExchangeFactor) {
            pointQS += Number(job.qs_point)
          }
          setObjGrade(job)
        }
        // }

        // if (qaExchangeFactor) {
        for (const job of currentProdJobsQa) {
          // totalJobs += 1
          // amount += Number(job.qs_value)
          // area += Number(job.area)
          if (qaExchangeFactor) {
            pointQA += Number(job.qa1_point)
          }
          // setObjGrade(job)
        }
        // }

        const trainerType = user.point_types.find(val => typeCodes.TRAINER_CODES.includes(val.code))
        if (trainerType)
          // point += trainerType.denominator // trainer still have total point, but for reward is static from denominator
          // Hanya boleh menghitung point dan reward trainer bulan ini ke belakang
          if (moment().format('YYYY-MM') >= strMonth)
            pointTrainer = trainerType.denominator

        const managerType = user.point_types.find(val => typeCodes.MANAGER_CODES.includes(val.code))
        if (managerType)
          if (moment().format('YYYY-MM') >= strMonth)
            pointManager = managerType.denominator

        // const pointForReward = trainerType ? trainerType.denominator : point
        if (lib.getHasBonus(user, strMonth)) {
          // reward = pointForReward * this.pointExchange[strMonth].rate
          reward = (
            (pointQS * qsExchangeFactor) +
            (pointQA * qaExchangeFactor) +
            (pointTrainer * trainerExchangeFactor) +
            (pointManager * managerExchangeFactor)
          ) * this.pointExchange[strMonth].rate
        }
        // console.log(user.first_name, { pointQS, pointQA, pointTrainer, qsExchangeFactor, qaExchangeFactor, trainerExchangeFactor, rate: this.pointExchange[strMonth].rate, reward, user })

        let sourceIndex = dataSource.findIndex(val => val.user.id === user.id)
        if (sourceIndex === -1)
          sourceIndex = dataSource.push({ user, fullName: lib.getFullName(user), ...DEFAULT_DATA_SOURCE }) - 1

        window.konsole.log('convertResponseData', lib.getFullName(user), {pointQS, pointQA, pointTrainer})

        // window.konsole.log('convertResponseData data', lib.getFullName(user), {
        //   ...dataSource[sourceIndex],
        //   amount: dataSource[sourceIndex].amount + amount,
        //   // total_jobs: dataSource[sourceIndex].total_jobs + currentProdJobsQs.length + currentProdJobsQa.length,
        //   total_jobs: dataSource[sourceIndex].total_jobs + totalJobs,
        //   area: dataSource[sourceIndex].area + area,
        //   // point: dataSource[sourceIndex].point + point,
        //   point: dataSource[sourceIndex].point + (pointQS + pointQA + pointTrainer),
        //   reward: dataSource[sourceIndex].reward + reward,
        //   objGrade: this.getMergedObjGrade(dataSource[sourceIndex].objGrade, objGrade),
        // })

        dataSource[sourceIndex] = {
          ...dataSource[sourceIndex],
          user: {
            ...dataSource[sourceIndex].user,
            is_bonus: dataSource[sourceIndex].user.is_bonus || user.is_bonus,
          },
          amount: dataSource[sourceIndex].amount + amount,
          // total_jobs: dataSource[sourceIndex].total_jobs + currentProdJobsQs.length + currentProdJobsQa.length,
          total_jobs: dataSource[sourceIndex].total_jobs + totalJobs,
          area: dataSource[sourceIndex].area + area,
          // point: dataSource[sourceIndex].point + point,
          point: dataSource[sourceIndex].point + (pointQS + pointQA + pointTrainer + pointManager),
          reward: dataSource[sourceIndex].reward + reward,
          objGrade: this.getMergedObjGrade(dataSource[sourceIndex].objGrade, objGrade),
        }

        window.konsole.log(
          'convertResponseData data',
          lib.getFullName(user),
          { ...dataSource[sourceIndex] },
        )
      }
    }

    dataSource.sort((a, b) => a.fullName > b.fullName ? 1 : -1)

    window.konsole.log('convertResponseData dataSource', dataSource)

    return dataSource.filter(item => item.total_jobs > 0 || item.user.point_types.length > 0)
  }

  onRowKeyTable = (record) => lib.getFullName(record.user)

  onRowTable = (_, index) => index % 2 === 1 && ({ className: 'even' })

  summaryTable = (currentData) => {
    return (
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} className='production-border-right'>
          Total
        </Table.Summary.Cell>
        <Table.Summary.Cell align='right'>
          <NumberFormat
            thousandSeparator
            displayType='text'
            value={currentData.reduce((acc, val) => acc + val.amount, 0).toFixed(2)}
          />
        </Table.Summary.Cell>
        <Table.Summary.Cell align='right'>
          <NumberFormat
            thousandSeparator
            displayType='text'
            value={currentData.reduce((acc, val) => acc + val.total_jobs, 0)}
          />
        </Table.Summary.Cell>
        <Table.Summary.Cell align='right'>
          <NumberFormat
            thousandSeparator
            displayType='text'
            value={currentData.reduce((acc, val) => acc + val.area, 0)}
          />
        </Table.Summary.Cell>
        <Table.Summary.Cell align='right' className={classNames({'production-border-right': !this.canSeeReward })}>
          <NumberFormat
            thousandSeparator
            displayType='text'
            value={currentData.reduce((acc, val) => acc + val.point, 0).toFixed(2)}
          />
        </Table.Summary.Cell>
        {this.canSeeReward && (
          <Table.Summary.Cell align='right' className='production-border-right'>
            <NumberFormat
              thousandSeparator
              displayType='text'
              value={currentData.reduce((acc, val) => acc + val.reward, 0).toFixed(0)}
            />
          </Table.Summary.Cell>
        )}
        {this.columns[2].children.map((col, i) => (
          <Table.Summary.Cell key={col.title} align='center' className={i === this.columns[2].children.length - 1 && 'production-border-right'}>
            {currentData.reduce((acc, val) => acc + get(val, col.dataIndex, []).length, 0)}
          </Table.Summary.Cell>
        ))}
        {this.columns[3].children.map(col => {
          const value = currentData.reduce((acc1, val1) => acc1 + get(val1, col.dataIndex, []).reduce((acc2, record) => acc2 + Number(record.area), 0), 0)
          return (
            <Table.Summary.Cell key={col.title} align='center'>
              <NumberFormat
                thousandSeparator
                displayType='text'
                value={value}
              />
            </Table.Summary.Cell>
          )
        })}
      </Table.Summary.Row>
    )
  }

  // isValidDate = (date) => {
  //   return moment(date, ['YYYY-MM', moment.ISO_8601])._isValid
  // }

  // isValidURLParam = (strUrlParam = this.props.location.search) => {
  //   this.objParam = lib.getObjParam(strUrlParam)

  //   return this.isValidDate(this.objParam.date)
  // }

  // didMount() {
  //   this.isValidURLParam()
  //   this.props.history.replace(this.props.location.pathname + lib.getStrParam({ date: moment().format('YYYY-MM-DD'), type: this.availablePickerMode[0] }))
  // }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   const currentParam = lib.getObjParam(this.props.location.search)
  //   const nextParam = lib.getObjParam(nextProps.location.search)
  //   if (currentParam.date !== nextParam.date || currentParam.type !== nextParam.type) {
  //     this.forceRead()
  //   }
  // }

  render() {
    return this.state.errorResponse ? this.getErrorComp() : (
      <div style={{ padding: '24px' }}>
        <Row justify='space-between' gutter={[6, 6]} style={{ marginBottom: '12px' }}>
          <Col>
            <h2 style={{ marginBottom: 0 }}>
              Monthly Production Report
              <Tooltip placement="right" title="Amount, Number Jobs and Number Area are only calculated from QS production" mouseLeaveDelay={0}>
                <QuestionCircleOutlined
                  style={{
                    cursor: 'pointer',
                    color: '#555555',
                    fontSize: '16px',
                    marginLeft: '8px',
                  }}
                />
              </Tooltip>
            </h2>
          </Col>
          <Col>
            <Row gutter={[6, 6]}>
              <Col>
                <Button loading={this.state.isLoading} icon={<ReloadOutlined />} onClick={this.forceRead}>
                  Refresh
                </Button>
              </Col>
              <Col style={{ flex: 1, minWidth: '175px' }}>
                <Row>
                  <Col>
                    <Button
                      disabled={this.state.isLoading}
                      icon={<CaretLeftOutlined />}
                      onClick={() => {
                        this.selectedDate = moment(this.selectedDate).subtract(1, this.state.pickerMode).format()
                        this.read()
                      }}
                    />
                  </Col>
                  <Col style={{ flex: 1 }}>
                    <DatePicker
                      disabled={this.state.isLoading}
                      picker={this.state.pickerMode}
                      allowClear={false}
                      value={moment(this.selectedDate)}
                      style={{ margin: '0 -1px', width: 'calc(100% + 2px)' }}
                      onChange={(date, dateString) => {
                        this.selectedDate = dateString
                        this.read()
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      disabled={this.state.isLoading}
                      icon={<CaretRightOutlined />}
                      onClick={() => {
                        this.selectedDate = moment(this.selectedDate).add(1, this.state.pickerMode).format()
                        this.read()
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Radio.Group
                  buttonStyle='solid'
                  value={this.state.pickerMode}
                  onChange={e => {
                    this.setThisState({ pickerMode: e.target.value }, () => {
                      this.read()
                    })
                  }}
                >
                  {this.availablePickerMode.map(mode => (
                    <Radio.Button key={mode} value={mode} disabled={this.state.isLoading}>
                      {mode.toUpperCase()}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Spin spinning={this.state.isLoading}>
          <div className='app-card'>
            <Table
              bordered
              sticky
              size='small'
              // rowKey={this.onRowKeyTable}
              rowKey='fullName'
              columns={this.columns}
              dataSource={this.__list}
              pagination={false}
              scroll={this.scrollOpts}
              onRow={this.onRowTable}
              summary={this.summaryTable}
            />
          </div>
        </Spin>
      </div>
    )
  }
}

export default MonthlyProductionReport
