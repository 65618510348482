import React from 'react'
import { Button, Calendar, Col, DatePicker, Radio, Row, Tooltip } from 'antd'
import { CheckCircleFilled, CheckCircleOutlined, LeftOutlined, RightOutlined, WarningOutlined } from '@ant-design/icons'
import moment from 'moment'
import classnames from 'classnames'

import { BaseComponent } from 'src/components'
import ListDailyDialog from './ListDaily'

class ContentCalendar extends BaseComponent {
  constructor(props) {
    super(props)

    this.note = 'Click the date to change header date, and double click the date to show detail time check in/out'
    
    this.state = {
    }
  }

  onRefListDialog = (ref) => {
    this._listDialog = ref
  }

  prevMonth = () => {
    this.onChangeDate(moment(this.props.date).subtract(1, 'month'))
  }

  nextMonth = () => {
    this.onChangeDate(moment(this.props.date).add(1, 'month'))
  }

  onChangeDate = (date) => {
    this.props.setDate(date)
  }

  renderHeader = (options) => {
    const { date } = this.props

    return (
      <div className='ant-picker-calendar-header'>
        <Row justify='space-between' gutter={[12, 12]} style={{ width: '100%', padding: '0 12px 12px 12px', borderBottom: '1px solid #e8e8e8' }}>
          <Col>
            <div style={{ fontSize: '20px', fontWeight: 'bold', letterSpacing: '1px' }}>
              {moment(date).format('MMMM YYYY')}
            </div>
            <div style={{ color: '#aaa', fontStyle: 'italic' }}>
              {this.note}
            </div>
          </Col>
          <Col>
            <Row gutter={[6, 6]}>
              <Col>
                <Button icon={<LeftOutlined />} onClick={this.prevMonth} style={{ marginRight: '-1px' }}>
                  {!this.mutableScreen.isMobile && 'Prev'}
                </Button>
                <DatePicker
                  picker='month'
                  className='calendar-group-center'
                  allowClear={false}
                  value={moment(date)}
                  onChange={this.onChangeDate}
                />
                <Button
                  className={classnames('group-right', { 'ant-btn-icon-only': this.mutableScreen.isMobile })}
                  onClick={this.nextMonth}
                  style={{ marginLeft: '-1px' }}
                >
                  {!this.mutableScreen.isMobile && 'Next'}
                  <RightOutlined />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }

  getStatusIcon = (isPresent) => (
    isPresent
      ? <CheckCircleOutlined style={{ fontSize: '18px' }} />
      : <WarningOutlined style={{ fontSize: '18px' }} />
  )

  onClickDate = (date) => {
    this._listDialog.show({ date })
  }

  renderCellDate = (date) => {
    const { userAttendances } = this.props
    const currentAttendances = userAttendances
      // .filter(att => att.status === 'validated')
      .filter(att => moment(att.attendance_date).format('YYYY-MM-DD') === date.format('YYYY-MM-DD'))

    const isPresent = currentAttendances.length > 0

    const checkinAttendances = currentAttendances.filter(att => att.attend_type === 'checkin')
    const checkoutAttendances = currentAttendances.filter(att => att.attend_type === 'checkout')

    const isPresentIn = checkinAttendances.find(att => att.status === 'validated')
    const isPresentOut = checkoutAttendances.find(att => att.status === 'validated')

    const currentMonth = date.format('YYYY-MM') === moment(this.props.date).format('YYYY-MM')

    return (
      <div
        className={classnames('ant-picker-cell-inner ant-picker-calendar-date', {
          'ant-picker-calendar-date-today': currentMonth && date.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'),
          // 'date-present-warning': true,
          // 'date-present-success': isPresent,
        })}
        // onClick={() => this.onClickDate(date)}
        onDoubleClick={() => {
          this.onClickDate(date)
        }}
        style={(this.mutableScreen.isMobile && currentAttendances.length > 0)
          ? {backgroundColor: (isPresentIn && isPresentOut) ? '#52c41a99' : '#faad1499'}
          : {}
        }
      >
        <div className='ant-picker-calendar-date-value'>
          {date.format('DD')}
        </div>
        <div className='ant-picker-calendar-date-content'>
          {/* {isPresent && ( */}
            <div style={{ opacity: currentMonth ? 1 : 0.5 }}>
              {!this.mutableScreen.isMobile && currentAttendances.length > 0 && (
                <Row justify='space-around' style={{ textAlign: 'center' }}>
                  <Col style={{ color: isPresentIn ? '#52c41a' : '#faad14' }}>
                    {checkinAttendances.length > 0 && (
                      <div>
                        {this.getStatusIcon(isPresentIn)}
                        <div>in</div>
                      </div>
                    )}
                  </Col>
                  <Col style={{ color: isPresentOut ? '#52c41a' : '#faad14' }}>
                    {checkoutAttendances.length > 0 && (
                      <div>
                        {this.getStatusIcon(isPresentOut)}
                        <div>out</div>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
            </div>
          {/* )} */}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div style={{ userSelect: 'none' }}>
        <Calendar
          mode='month'
          className='calendar-attendance'
          value={moment(this.props.date)}
          headerRender={this.renderHeader}
          dateFullCellRender={this.renderCellDate}
          onSelect={this.onChangeDate}
        />

        <ListDailyDialog
          ref={this.onRefListDialog}
          userAttendances={this.props.userAttendances}
        />
      </div>
    )
  }
}

ContentCalendar.defaultProps = {
  userAttendances: [],
}

export default ContentCalendar