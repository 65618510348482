import { Tooltip } from 'antd'
import { ExperimentOutlined } from '@ant-design/icons'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highcharts'
import moment from 'moment'
import { get, uniqBy } from 'lodash'

import AmountPct from 'src/pages/DashboardApp/Dashboard/Report/UserProductionJobs/AmountPct'
import options from 'src/pages/DashboardApp/Dashboard/Report/UserProductionJobs/AmountPct/options'
import { CardHeader } from 'src/pages/DashboardApp/Components'

class PointPrediction extends AmountPct {
  convertData = (p = this.props) => {
    this.prevTargetPoint = p.prevTargetAmount / 45.33
    this.targetPoint = p.targetAmount / 45.33

    this.prevTotalPoint = get(p.prevUserProductionJobs, 'qs_production_jobs', []).reduce((acc, val) =>
      acc + Number(val.qs_point), 0)
    this.totalPoint = get(p.userProductionJobs, 'qs_production_jobs', []).reduce((acc, val) =>
      acc + Number(val.qs_point), 0)

    const totalDays = uniqBy(get(p.userProductionJobs, 'qs_production_jobs', []), 'prod_day').length || 1
    options.series[0].data[0].y = this.prevTotalPoint / this.prevTargetPoint * 100
    options.series[0].data[1].y = this.totalPoint / this.targetPoint * 100
    options.series[0].data[3].y = (this.totalPoint / totalDays * p.targetWorkingDays) / this.targetPoint * 100

    // --------- Set month label ---------
    if (moment(p.date).format('YYYY-MM') === moment().format('YYYY-MM')) {
      options.xAxis.categories[0] = 'Last Month'
      options.xAxis.categories[1] = 'This Month'
    } else {
      options.xAxis.categories[0] = moment(p.date).subtract(1, 'month').format('MMMM YYYY')
      options.xAxis.categories[1] = moment(p.date).format('MMMM YYYY')
    }
    // -----------------------------------

    this.state.errorDescription = undefined
    this.forceUpdate()
  }

  renderTotalPoint() {
    return this.totalPoint
      ? Number(this.totalPoint).toFixed(2)
      : '-'
  }

  render() {
    console.log('Rendered PointPrediction', this)
    return this.getIsError() ? this.getErrorComp() : (
      <div>
        <CardHeader
          title={
            <span style={{ fontSize: '16px' }}>
              <span>Member Points: {(this.renderTotalPoint())} </span>
              {/* {!!this.targetPoint && (
                <Tooltip title="Accumulated Points" mouseLeaveDelay={0}>
                  <span>({this.totalPoint.toFixed(2)} / {this.targetPoint.toFixed(2)})</span>
                </Tooltip>
              )} */}
              {/* {!!this.targetPoint && (
                <Tooltip title="In-Progress Points" mouseEnterDelay={0}>
                  <span style={{ marginLeft: '12px', fontSize: '12px', color: 'rgba(0, 0, 0, 0.5)' }}>
                    <ExperimentOutlined /> {this.inProgressPoint.toFixed(2)}
                  </span>
                </Tooltip>
              )} */}
            </span>
          }
          extra={<div>
            <Tooltip title="Company Target Point" mouseLeaveDelay={0}>
              {Number(this.targetPoint).toFixed(2) || '-'}
            </Tooltip>
          </div>}
        />
        <HighchartsReact
          highcharts={Highcharts}
          options={{ ...options }}
        />
      </div>
    )
  }
}

export default PointPrediction