import React from 'react'
import { Button, Card, Col, Divider, Pagination, Row, Spin, Table, Tag } from 'antd'
import { AuditOutlined, EditOutlined, PlusOutlined, ReloadOutlined, RobotOutlined } from '@ant-design/icons'
import { get } from 'lodash'
import moment from 'moment'

import { BaseList } from 'src/components'
import { lib, request } from 'src/Utils'
// import columns from './columns'
import { CellStatus } from './Cells'
import DeactivateWarn from './DeactivateWarn'
import EditUser from './EditUser'
import UserDetail from './UserDetail'

class ManageUsers extends BaseList {
  constructor(props) {
    super(props)

    this.urlKey = 'read-companyUsers'

    this.columns = [
      {
        dataIndex: '_1',
        title: 'Name',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => lib.sorter.byItem(lib.getFullName(a), lib.getFullName(b)),
        render: (_, record) => (
          <div className='clickable' onClick={() => this.selectUser(record)}>
            {lib.getFullName(record)}
          </div>
        ),
      },
      {
        dataIndex: 'email',
        title: 'E-Mail',
      },
      {
        dataIndex: ['role', 'name'],
        title: 'Level',
        width: '200px',
        render: (value, record) => {
          return lib.getLevel(record) || (
            <div title='Click the name for detail roles'>
              -
            </div>
          )
    
          // return (
          //   <SelectQuery
          //     urlKey='read-roles'
          //     valKey='alias'
          //     valLabel='name'
          //     inputProps={{
          //       value,
          //       size: 'small',
          //       style: { width: '175px' },
          //       onChange: (roleId) => this.onChangeRole(roleId, record),
          //     }}
          //   />
          // )
        }
      },
      {
        dataIndex: '_3',
        title: 'Status',
        align: 'center',
        render: (_, record) => {
          return (
            <CellStatus
              user={record}
              alias={record.alias}
              isOwner={record.is_owner}
              isRegistered={record.is_registered}
              isActive={record.is_active}
              activate={this.activate}
              deactivate={this.showWarnDeactivate}
            />
          )
        }
      },
      {
        dataIndex: 'date_joined',
        title: 'Joined',
        render: val => moment(val).format('ddd, DD MMM YYYY'),
      },
      {
        dataIndex: '_4',
        title: 'Actions',
        render: (_, record) => (
          <Row gutter={[12, 12]}>
            <Col>
              <Button size='small' icon={<EditOutlined />} onClick={() => this.selectUser(record)}>
                Edit
              </Button>
            </Col>
            {/* {!record.is_owner && (
              <Col>
                <Button danger size='small' type='primary' icon={<DeleteOutlined />} onClick={() => this.onDelete(record)}>
                  Delete
                </Button>
              </Col>
            )} */}
          </Row>
        )
      },
    ]

    this.state = {
      ...this.state,
      selectedUser: null,
    }
  }

  onRefDeactivateWarn = (ref) => {
    this._deactivateWarn = ref
  }

  onRefEditUser = (ref) => {
    this._editUser = ref
  }

  showWarnDeactivate = (alias, user) => {
    this._deactivateWarn.show({ user })
  }

  showEdit = (user) => {
    this._editUser.show({ user })
  }

  selectUser = (selectedUser) => {
    this.setThisState({ selectedUser })
  }

  updateUser = (user, alias) => {
    if (!alias)
      alias = user && user.alias

    if (!user)
      return

    const users = [...this.__list]
    for (let i = 0; i < users.length; i += 1) {
      if (users[i].alias === alias) {
        if (user)
          users[i] = user
        else if
          (!user)user.splice(i, 1)

        this.updateList(users)
        break
      }
    }
  }

  activate = (alias) => {
    this.patch({ alias, is_active: true })
  }

  deactivate = (alias, user, message) => {
    this.patch({ alias, is_active: false, message })
  }

  onChangeRole = (roleId, user) => {
    this.patch({ role: roleId }, user.alias)
  }

  patch = (data, id, callback = () => null) => {
    if (!id) id = data.alias

    this.setThisState({ isLoading: true }, () => {
      request({
        method: 'patch',
        urlKey: 'change-companyUsers',
        data,
        args: [id],
        extra: { callback },
        onSuccess: this.patchSuccess,
        onFailed: this.patchFailed,
      })
    })
  }

  patchSuccess = (response, extra) => {
    this.patchList([response.data])

    this.setThisState({ isLoading: false }, () => {
      extra.callback(true)
    })
  }

  patchFailed = (error, extra) => {
    lib.responseMessage.error(error.response)
    this.setThisState({ isLoading: false }, () => {
      extra.callback(false)
    })
  }

  render() {
    return !this.state.selectedUser ? (
      <div style={{ padding: '24px' }}>
        <Row justify='space-between' style={{ marginBottom: '24px' }}>
          <Col>
            <Row align='middle'>
              <Col>
                <h1 style={{ marginBottom: 0, marginRight: '8px' }}>
                  Manage Users
                </h1>
              </Col>
              <Col>
                <Divider type='vertical' style={{ height: '28px' }} />
              </Col>
              <Col>
                <Button disabled type='link' icon={<PlusOutlined />} style={{ padding: '4px' }} title='Still working...'>
                  Add More People
                </Button>
              </Col>
            </Row>
          </Col>
          <Col>
            <Button loading={this.state.isLoading} icon={<ReloadOutlined />} onClick={this.read}>
              Refresh
            </Button>
          </Col>
        </Row>
        <Spin spinning={this.state.isLoading}>
          <Card className='app-card' bodyStyle={{ padding: 0 }}>
            <Table
              bordered
              size='small'
              rowKey='alias'
              columns={this.columns}
              dataSource={this.__list}
              pagination={false}
              scroll={{ x: 'auto' }}
            />
            <Row justify='space-between' style={{ margin: '16px 0', padding: '0 12px' }}>
              <Col />
              <Col>
                <Pagination
                  {...this.getPaginationConfig()}
                />
              </Col>
            </Row>
          </Card>
        </Spin>
        {/* Dialog 1 : Deactivate Warning */}
        <DeactivateWarn
          ref={this.onRefDeactivateWarn}
          deactivate={this.deactivate}
        />

        {/* Dialog 2 : Edit User */}
        <EditUser
          ref={this.onRefEditUser}
          patch={this.patch}
        />
      </div>
    ) : (
      <UserDetail
        user={this.state.selectedUser}
        onClose={this.selectUser} // send Null
        updateUser={this.updateUser}
      />
    )
  }
}

export default ManageUsers