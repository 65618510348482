import PropTypes from 'prop-types';
import { Button, Col, DatePicker, Form, Input, Row, Select, Spin } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import moment from 'moment';

import { BaseModal, VisibilityContainer } from 'src/components';
import { lib, request } from 'src/Utils';
// import leaveHelper from '../helper';
import helper from '../../helper';
import LeaveList from './List';
import LeaveForm from './Form';
import LeaveView from './View';

class DialogLeaveDetail extends BaseModal {
  constructor(props) {
    super(props)

    this.myProfile = lib.ls.getCurrentUser()
    this.title = 'Staff Leave'

    Object.defineProperties(this, {
      _canAdd: {
        get() {
          return get(this.props.user, 'id') === this.myProfile.id && this.allowSubmission()
        }
      }
    })

    this.state = {
      ...this.state,
      defaultDate: null,
      userLeaves: [],
      selectedLeave: null,
      viewType: 'view',
    }
  }

  onRefForm = (ref) => this._form = ref
  getForm = () => this._form
  getLeaveOvertime = () => this.state.selectedLeave
  // getLeaveOvertimes = () => this.state.userLeaves
  allowSubmission = () => helper._leave.allowSubmission(this.state.defaultDate, this.state.userLeaves)

  getModalProps = () => ({
    centered: true,
    destroyOnClose: true,
    bodyStyle: { padding: 0 },
    width: 720,
    footer: null,
  })

  onVisibleChanged = (visible) => {
    if (!visible && this._form)
      this._form.resetFields()

    // if (visible)
    this.setState({
      viewType: !this.state.userLeaves.length ? 'form' : 'view',
      selectedLeave: this.state.userLeaves.length === 1 ? this.state.userLeaves[0] : null,
    })
  }

  selectUserLeave = (userLeave) => {
    this.setState({ selectedLeave: userLeave })
  }

  setLoading = (isLoading, callback) => {
    this.setState({ isLoading }, callback)
  }

  renderList = () => {
    return (
      <LeaveList
        date={this.state.defaultDate}
        userLeaves={this.state.userLeaves}
        canAddAnother={this.canAddAnother}
        // getLeaveTypeLabel={leaveHelper.getTypeLabel}
        onSelect={this.selectUserLeave}
        onAddAnother={() => this.setState({ viewType: 'form', selectedLeave: null })}
      />
    )
  }

  renderForm = () => {
    return (
      <LeaveForm
        onRefForm={this.onRefForm}
        userId={get(this.props.user, 'id')}
        defaultDate={this.state.defaultDate}
        selectedLeave={this.state.selectedLeave}
        getForm={this.getForm}
        setLoading={this.setLoading}
        hide={this.hide}
        onSavedLeave={this.props.onSavedLeave}
      />
    )
  }

  renderView = () => {
    return (
      <LeaveView
        user={this.props.user}
        userLeave={this.getLeaveOvertime()}
        // getLeaveTypeLabel={leaveHelper.getTypeLabel}
        canAddAnother={this.canAddAnother}
        // onAccept={this.props.onAccept}
        // onReject={this.props.onReject}
        onAddAnother={() => this.setState({ viewType: 'form' })}
        goBack={() => this.setState({ viewType: 'view', selectedLeave: null })}
        setLoading={this.setLoading}
        hide={this.hide}
        onChangedLeave={this.props.onSavedLeave}
      />
    )
  }

  renderContent = () => {
    this.canAddAnother = (this.myProfile.is_owner || get(this.props.user, 'id') === this.myProfile.id) && this.allowSubmission()
      // helper._leave.allowSubmission(this.state.defaultDate, this.getLeaveOvertimes())

    return (
      <div>
        <Spin spinning={this.state.isLoading}>
          <VisibilityContainer visible={this.state.viewType === 'view' && !this.getLeaveOvertime()}>
            {this.renderList()}
          </VisibilityContainer>
          <VisibilityContainer visible={this.state.viewType === 'form'}>
            {this.renderForm()}
          </VisibilityContainer>
          <VisibilityContainer visible={this.state.viewType === 'view' && !!this.getLeaveOvertime()}>
            {this.renderView()}
          </VisibilityContainer>
        </Spin>
      </div>
    )
  }
}

DialogLeaveDetail.propTypes = {
  ...DialogLeaveDetail.propTypes,
  user: PropTypes.object.isRequired,
  onSavedLeave: PropTypes.func,
}

DialogLeaveDetail.defaultProps = {
  ...DialogLeaveDetail.defaultProps,
  onSavedLeave: () => {},
}

export default DialogLeaveDetail