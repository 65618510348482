import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';

import StaffLeaveCalendar from '../YearlyStaffLeave/Calendar';
import PopoverContentUser from './PopoverContentUser';
import DialogListUser from './DialogListUser';

class OvertimeCalendar extends StaffLeaveCalendar {
  readCalendarSuccess = (res) => {
    const calendarData = {}
    for (const userOvertime of res.data) {
      if (!calendarData[userOvertime.date]) calendarData[userOvertime.date] = []
      calendarData[userOvertime.date].push(userOvertime)
    }

    this.setState({ isLoading: false, calendarData })
  }

  showList = (date) => {
    const usersOvertime = get(this.state.calendarData, moment(date).format('YYYY-MM-DD'), [])
    if (usersOvertime.length)
      this._dialogListUser.show({ date, usersOvertime })
  }

  renderPopoverContent = (userOvertime) => <PopoverContentUser userOvertime={userOvertime} />

  renderDialogs = () => (
    <div>
      <DialogListUser ref={this.onRefDialogListUser} showDetail={this.props.showDetail} />
    </div>
  )
}

OvertimeCalendar.propTypes = {
  ...OvertimeCalendar.propTypes,
}

OvertimeCalendar.defaultProps = {
  ...OvertimeCalendar.defaultProps,
}

export default OvertimeCalendar