import React from 'react'
import { Button, Card, Col, DatePicker, Row, Spin } from 'antd'
import { ArrowLeftOutlined, CaretLeftOutlined, CaretRightOutlined, ReloadOutlined } from '@ant-design/icons'
import moment from 'moment'

import { BaseListActivity } from 'src/components'
import { TimelineSection } from './Sections'
import { lib, request } from 'src/Utils'

const { RangePicker } = DatePicker

class DashboardCompany extends BaseListActivity {
  constructor(props) {
    super(props)

    this.keysQueryParam.push(...['id'])

    // param users
    this.pageUser = 1
    this.limitUser = 25
    this.nextUser = null
    this.users = []

    this.rangeMessageError = 'Cannot display for more than 7 days'

    this.myProfile = lib.ls.getCurrentUser()
  }

  changeToDefaultFilter = () => {
    this.onChangeFilter({
      start_date: moment().startOf('day').format('YYYY-MM-DD'),
      end_date: moment().endOf('day').format('YYYY-MM-DD'),
    })
  }

  cancelAllRequest = () => {
    this.cancel()
    this.cancelUsers()
  }

  // Hanya dibutuhkan id untuk request activities
  beforeRead = async (keyLoading, callback) => {
    this.resetReadInterval()

    return new Promise((resolve) => {
      this.setThisState({ [keyLoading]: true }, async () => {
        const res = await lib.loadAllMembers() // { members, error }

        if (res.error) {
          this.setThisState({ [keyLoading]: false, errorResponse: res.error.response })

          resolve(true)
          if (typeof callback === 'function')
            callback()
        } else {
          this.id = res.members.map(member => member.id).join(',')

          resolve()
        }

        // this.cancelAllRequest()
        // request({
        //   urlKey: 'read-usersManagedMe',
        //   data: {
        //     include_inactive: true,
        //     page: this.pageUser,
        //     limit: this.limitUser,
        //   },
        //   extra: { resolve },
        //   getCancel: this.setCancelUsers,
        //   onSuccess: this.readUsersSuccess,
        //   onFailed: this.readUsersFailed,
        // })
      })
    })
  }

  setCancelUsers = (cancel) => {
    this.cancelTokenUsers = cancel
  }

  cancelUsers = () => {
    if (typeof this.cancelTokenUsers === 'function') {
      this.cancelTokenUsers()
    }
  }

  readUsersSuccess = (response, extra) => {
    this.usersData = response.data
    this.nextUser = response.data.next
    const { results } = response.data
    this.id = ''

    for (const i in results) {
      if (this.id) this.id += ','

      this.id += results[i].id
    }

    extra.resolve()
  }

  readUsersFailed = (error, extra) => {
    lib.responseMessage.error(error.response)
    extra.resolve(true)

    this.setThisState({ isLoading: false })
  }

  checkRange = (startDate, endDate) => (
    moment(endDate).diff(moment(startDate)) < 1000 * 60 * 60 * 24 * 7 // max range 7 days
  )

  onPageChange = (page, pageSize) => {
    this.pageUser = page
    this.limitUser = pageSize
    this.read()
  }

  goHome = () => {
    this.props.history.push(
      '/' +
      lib.getStrParam({
        start_date: moment(this.range_before).format('YYYY-MM-DD'),
        end_date: moment(this.range_after).format('YYYY-MM-DD'),
      })
    )
  }

  prevDays = () => {
    if (moment(this.range_before).subtract(1, 'day').format('YYYY-MM-DD') === moment(this.range_before).subtract(1, 'day').endOf('week').format('YYYY-MM-DD')) {
      const startDate = moment(this.range_before).subtract(1, 'weeks').format('YYYY-MM-DD')
      const endDate = moment(this.range_after).subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')
      this.onChangeFilter({ start_date: startDate, end_date: endDate })
    } else {
      const startDate = moment(this.range_before).startOf('week').format('YYYY-MM-DD')
      const endDate = moment(this.range_before).subtract(1, 'day').format('YYYY-MM-DD')
      this.onChangeFilter({ start_date: startDate, end_date: endDate })
    }
  }

  nextDays = () => {
    if (moment(this.range_after).add(1, 'day').format('YYYY-DD-MM') === moment(this.range_after).add(1, 'day').startOf('week').format('YYYY-DD-MM')) {
      const startDate = moment(this.range_before).add(1, 'weeks').startOf('week').format('YYYY-MM-DD')
      const endDate = moment(this.range_after).add(1, 'weeks').format('YYYY-MM-DD')
      this.onChangeFilter({ start_date: startDate, end_date: endDate })
    } else {
      const startDate = moment(this.range_after).add(1, 'day').format('YYYY-MM-DD')
      const endDate = moment(this.range_after).endOf('week').format('YYYY-MM-DD')
      this.onChangeFilter({ start_date: startDate, end_date: endDate })
    }
  }

  resetReadInterval = () => {
    clearInterval(this._readInterval)

    this._readInterval = setInterval(this.forceRead, 1000 * 60 * 3) // 3 minutes
  }

  didMountStatements() {
    this.resetReadInterval()
  }

  unmount = () => {
    this.cancelAllRequest()

    clearInterval(this._readInterval)
  }

  render() {
    return (
      <div>
        <div style={{ padding: '24px' }}>
          <Row justify='space-between' gutter={[12, 12]} style={{ marginBottom: '12px' }}>
            <Col>
              <Row align='middle'>
                <Col>
                  <div className='ant-page-header-back'>
                    <div className='ant-page-header-back-button' onClick={this.goHome}>
                      <ArrowLeftOutlined />
                    </div>
                  </div>
                </Col>
                <Col style={{ maxWidth: 'calc(100% - 32px)' }}>
                  <h1 className='text-truncate' style={{ marginBottom: 0 }} title={this.myProfile.company.name}>
                    {this.myProfile.company.name}
                  </h1>
                </Col>
              </Row>
            </Col>
            {/* Be careful using minWidth */}
            <Col style={{ flex: 1, minWidth: '280px' }}>
              <Row justify='end' align='middle' gutter={[6, 6]}>
                <Col className='only-xs-block'>
                  <Button block loading={this.state.isLoading} icon={<ReloadOutlined />} onClick={this.read}>
                    Refresh
                  </Button>
                </Col>
                <Col className='only-xs-block'>
                  <Row>
                    <Col>
                      <Button icon={<CaretLeftOutlined />} onClick={this.prevDays} />
                    </Col>
                    <Col style={{ flex: 1, margin: '0 -1px', maxWidth: 'calc(100% - 32px - 32px)' }}>
                      <RangePicker
                        allowClear={false}
                        format='YYYY-MM-DD'
                        ranges={{
                          'Today': [moment(), moment()],
                          'This Week': [moment().startOf('week'), moment().endOf('week')],
                          'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                        }}
                        value={[moment(this.range_before), moment(this.range_after)]}
                        style={{ width: '100%' }}
                        onChange={(dates, dateStrings) => {
                          this.tempDateStrings = dateStrings
                        }}
                        onOpenChange={(opened) => {
                          if (!opened) {
                            if (this.tempDateStrings[0] !== this.range_before || this.tempDateStrings[1] !== this.range_after)
                              this.onChangeFilter({ start_date: this.tempDateStrings[0], end_date: this.tempDateStrings[1] })
                          } else {
                            this.tempDateStrings = [this.range_before, this.range_after]
                          }
                        }}
                      />
                    </Col>
                    <Col>
                      <Button icon={<CaretRightOutlined />} onClick={this.nextDays} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Spin spinning={this.state.isLoading}>
            {this.state.errorResponse ? this.getErrorComp() : (
              <Card className='app-card' bodyStyle={{ padding: 0 }}>
                <TimelineSection
                  activities={this.__list}
                  startTime={this.range_before}
                  endTime={this.range_after}
                  usersData={this.usersData}
                  page={this.pageUser}
                  limit={this.limitUser}
                  next={this.nextUser}
                  onPageChange={this.onPageChange}
                />
              </Card>
            )}
          </Spin>
        </div>
      </div>
    )
  }
}

export default DashboardCompany