import PropTypes from 'prop-types';
import { Avatar, Col, Row } from 'antd'
import { CoffeeOutlined, CommentOutlined, IdcardOutlined, UserOutlined } from '@ant-design/icons'
import classNames from 'classnames';

import { BaseComponent } from 'src/components'
import UserPhotoStatus from '../Base/UserPhoto';
import { lib } from 'src/Utils'
import _h from 'src/pages/DashboardApp/Submission/helper';

class PopoverContentUser extends BaseComponent {
  getInitialName = (user) => {
    return lib.getFullName(user).split(' ').map(str => str.slice(0, 1)).join('')
  }

  render() {
    const { userLeave } = this.props

    return (
      <div>
        <div>
          <Row wrap={false} gutter={[12, 12]} style={{ padding: '8px 16px 12px 12px' }}>
            <Col flex="none">
              <UserPhotoStatus
                user={userLeave.user}
                status={userLeave.status}
              />
              {/* <Avatar
                size={64}
                // icon={<UserOutlined />}
                src={userLeave.user.photo}
                style={{ border: '1px solid #d5d5d5' }}
              >{userLeave.user.first_name}</Avatar> */}
            </Col>
            <Col flex="auto">
              <Row wrap={false} style={{ fontWeight: 500 }}>
                <Col flex='auto' className='text-truncate' title={lib.getFullName(userLeave.user)}>
                  {lib.getFullName(userLeave.user)}
                </Col>
                <Col flex='none'>
                  <span
                    style={{ marginLeft: '6px' }}
                    className={classNames({
                      'approved-note': _h.isApproved(userLeave),
                      'c-error': _h.isAborted(userLeave),
                      'c-gray': _h.isWaiting(userLeave),
                    })}
                  >
                    ({userLeave.status_display})
                  </span>
                </Col>
              </Row>
              <div style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                <div style={{ fontWeight: 500 }}>
                  <CoffeeOutlined style={{ marginRight: '6px' }} />
                  {userLeave.leave_type.name}
                </div>
                <div className='webkit-box two-lines'>
                  <CommentOutlined style={{ marginRight: '6px' }} />
                  {userLeave.note}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

PopoverContentUser.propTypes = {
  ...PopoverContentUser.propTypes,
  userLeave: PropTypes.object.isRequired,
}

PopoverContentUser.defaultProps = {
  ...PopoverContentUser.defaultProps,
}

export default PopoverContentUser