import { get } from 'lodash';

import BasePointByGrade from 'src/pages/DashboardApp/Dashboard/Report/UserProductionJobs/PointByGrade'

class PointByGrade extends BasePointByGrade {
  convertData = (userProductionJobs, nextProps = this.props) => {
    console.log('TeamProduction PointByGrade convertData', userProductionJobs, this)
    if (!userProductionJobs) {
      this.setState({ dataSource: [] })
      return
    }

    const { qs_production_jobs } = userProductionJobs
    let pointQS = 0, jobs = [] // jobs = qa1_production_jobs.concat(qs_production_jobs)

    for (const job of qs_production_jobs) {
      pointQS += Number(job.qs_point)
      // jika QS dan QA user yang sama, di ambil salah satu atau dihitung 2 ?
      if (!jobs.find(item => item.job_id === job.job_id))
        jobs.push(job)
    }

    this.totalValue = pointQS

    const dataSource = []
    for (const job of jobs) {
      const foundIndex = dataSource.findIndex(item => item.grade === job.grade)
      const prevDataSource = dataSource[foundIndex]
      dataSource.splice(
        !prevDataSource ? dataSource.length : foundIndex,
        !prevDataSource ? 0 : 1,
        {
          grade: job.grade,
          point: Number(get(prevDataSource, 'point', 0)) + Number(job.qs_point)
        }
      )
    }

    for (const data of dataSource)
      data.pct = this.totalValue ? data.point / this.totalValue : data.point

    dataSource.sort((a, b) => b.pct - a.pct)

    this.setAverage(jobs, nextProps)

    console.log('dataSource', dataSource, jobs, this)
    this.setThisState({ dataSource })
  }

  // render() {
  //   return null
  // }
}

export default PointByGrade