import users from './users';
import userLeaves from './leaves';
import userOvertimes from './overtimes';
import userActivities from './activities';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...users,
  ...userLeaves,
  ...userOvertimes,
  ...userActivities,
}