function uuidV4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

function tokenUrlSafe(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function generateUuid(prefix = null) {
  let generatedId = `${uuidV4()}-T${(new Date().getTime() / 1000).toFixed(6)}-S${tokenUrlSafe(36)}`;
  if (prefix) generatedId = (`     ${prefix}-`).slice(-6) + generatedId;
  return btoa(generatedId);
}

export default generateUuid