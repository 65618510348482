import { message } from 'antd'

function openMaps(latitude, longitude) {
  // NaN always false when campared with number
  latitude = Number(latitude)
  longitude = Number(longitude)

  const validLatitude = latitude >= -90 && latitude <= 90
  const validLongitude = longitude >= -180 && longitude <= 180
  if (validLatitude && validLongitude) {
    window.open(`https://maps.google.com/?q=${latitude},${longitude}`)
  } else {
    message.info(`"${latitude}, ${longitude}" is not valid coordinate`)
  }
}

export default openMaps