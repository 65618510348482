import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import { CalendarOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import { BaseComponent } from 'src/components';
import ListItem from './Item';

class LeaveList extends BaseComponent {
  renderList = () => {
    return (
      this.props.userLeaves.map(userLeave => (
        <ListItem
          key={userLeave.pk}
          userLeave={userLeave}
          onSelect={this.props.onSelect}
          getLeaveTypeLabel={this.props.getLeaveTypeLabel}
        />
      ))
    )
  }

  render() {
    return (
      <div>
        <div className="ant-modal-body" style={{ maxHeight: 'calc(100vh - 140px)', overflowY: 'auto' }}>
          <div style={{ fontSize: '18px', fontWeight: 500, textAlign: 'center', marginBottom: '12px' }}>
            <CalendarOutlined style={{ marginRight: '12px' }} />
            {moment(this.props.date).format('DD MMMM YYYY')}
          </div>
          <div style={{ borderTop: '1px solid #e8e8e8' }}>
            {this.renderList()}
          </div>
        </div>
        {this.props.canAddAnother && (
          <div className="ant-modal-footer">
            <div>
              <Row justify="center" gutter={[12, 12]}>
                <Col>
                  <Button type="primary" icon={<PlusOutlined />} onClick={this.props.onAddAnother}>
                    New Request
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    )
  }
}

LeaveList.propTypes = {
  ...LeaveList.propTypes,
  userLeaves: PropTypes.array,
  canAddAnother: PropTypes.bool,
  onAddAnother: PropTypes.func,
}

LeaveList.defaultProps = {
  ...LeaveList.defaultProps,
  userLeaves: [],
  canAddAnother: false,
  onAddAnother: () => {},
}

export default LeaveList